import React, { Component } from 'react';
import { Layout, Col, Row, Divider } from 'antd';
import history from '../../helpers/history'

import { FormattedMessage } from 'react-intl';

const { Footer } = Layout;

const locale = window.appLocale.locale;
class FooterPage extends Component {
    OnSolution = e => {
        e.preventDefault();
        history.push("/solution");
    }

    OnRobotSolution = e => {
        e.preventDefault();
        history.push("/robotSolution");
    }

    OnFactorSolution = e => {
        e.preventDefault();
        history.push("/factorSolution");
    }

    render() {
        var now = new Date();
        var year = now.getFullYear();

        return (
            <Footer>
                <Row style={{ padding:'0 50px' }}>
                    <Col span={22}>
                        <div style={{ padding: '16px 0', color: '#efefef'}}>
                            <a href="/about"><FormattedMessage id='about' /></a>
                            <Divider type="vertical" />
                            <a href="/about#contact"><FormattedMessage id='contact' /></a>
                            {locale.toLowerCase() === 'en-us' ? '' : <span><Divider type="vertical" />
                                <a href="/help"><FormattedMessage id='intl.menu.help' /></a></span>}
                            
                            <Divider type="vertical" />
                            <a href="/download"><FormattedMessage id='intl.menu.download' /></a>
                        </div>
                        <div style={{ padding: '16px 0', color: '#dedede' }}>
                            <FormattedMessage id='company' values={{ year: year }} />
                            <a href="https://beian.miit.gov.cn/" target="_blank">
                                {locale.toLowerCase() === 'en-us' ? '' : '闽ICP备2021012051号-1'}
                            </a>
                        </div>
                    </Col>
                    <Col span={2} style={{ textAlign: "right" }}>
                        <div style={{ color: '#dedede', textAlign: 'center' }}>
                            <p><img src="static/images/weixin001.jpg" width="60"></img></p>
                            <FormattedMessage id='WeChat.public.number' />
                        </div>
                    </Col>
                </Row>
            </Footer>
        )
    }
}

export { FooterPage as default }