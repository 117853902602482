import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../helpers/history';

import { Layout, PageHeader, Descriptions, Table, Select, Button, Divider, Spin, Menu, Icon, Dropdown, message } from 'antd';

import emitter from '../Evt.js';

const { Content } = Layout;
const { Option } = Select;


var languageMessages = window.appLocale.messages;
class UpdatePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            spinLoadding: true,

            dataSource: [],

            currency: 'usd',

            months: 0,
            amount: 0,
            couponId: 0,

            isVip: false,

            subscriptService: '',

            hisSubscriptions: [],
        }
    }
    
    componentDidMount() {
        emitter.addListener('initPage', () => {
            this.init();
        });
    }

    componentWillMount() {
        this.init();
    }

    init() {
        var user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        if (user.username === undefined) {
            this.setState({ spinLoadding: false }, () => {
                this.props.dispatch({ type: "action_non_user" });
            });
        } else {
            if (history.location.state !== undefined) {
                let subscriptions = history.location.state.subscriptions;
                
                this.props.dispatch({ type: "action_subscription", payload: { u: user.username, i: subscriptions } });
            }
        }
    }

    componentWillUnmount() {
        
    }

    componentWillReceiveProps(props) {
        var { status, state, subscription, isVip, vService, hisSubscription } = props;

        switch (status) {
            case 'subscription_success':
                isVip = isVip === undefined ? false : isVip;
                
                vService = vService === undefined || vService === null ? [] : vService;
                let data = vService.filter((n) => { return n.type > 0 });
                let subscriptService = <Menu onClick={this.appendSubscriptionService}>
                    {
                        Object.keys(data).map((index) => {
                            let items = data[index];
                            return <Menu.Item key={items.type}><Icon type="tag" />{items.title}</Menu.Item>
                        })
                    }
                </Menu>;

                let months = 12;
                let amount = 0;
                let couponId = 0;

                let dataSource = [];
                if (subscription !== undefined) {
                    months = subscription.months;
                    amount = subscription.amount;
                    couponId = subscription.couponId;
                    
                    if (subscription.intro !== undefined) {
                        let intro = JSON.parse(subscription.intro);
                        let item = intro.item;
                        for (let i in item) {
                            let data = vService.filter((n) => { return n.type === item[i].i });
                            if (data.length > 0) {
                                let total = this.formatPrice(this.multiply(data[0].price, months));
                                if (isVip && data[0].specialPrice > 0) total = this.formatPrice(this.multiply(data[0].specialPrice, months));

                                dataSource.push({
                                    key: item[i].i,
                                    title: item[i].t,
                                    unitPrice: this.formatPrice(data[0].price),
                                    specialOffer: data[0].specialPrice === 0 ? '' : this.formatPrice(data[0].specialPrice),
                                    months: months,
                                    total: total,
                                });
                            }
                        }
                    }
                }

                let hisSubscriptions = [];
                if (hisSubscription !== undefined) {
                    for (let i in hisSubscription) {
                        for (let j in hisSubscription[i]) {
                            hisSubscriptions.push(hisSubscription[i][j].i);
                        }
                    }
                }

                this.setState({ dataSource, isVip, months, amount, subscriptService, couponId, hisSubscriptions, spinLoadding: false });
                break;
            case 'subscription_failed':
                var error = 'Get subscription failed';
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = languageMessages['user.exception'];
                        break;
                    default:
                        break;
                }
                message.error(error);
                break;
            case 'update_subscription_success':
                message.success('update subscription success');
                break;
            case 'update_subscription_failed':
                message.error('update subscription failed');
                break;
        }
    }

    add = (a, b) => {
        var r1, r2, m;
        try { r1 = a.toString().split(".")[1].length } catch (e) { r1 = 0 }
        try { r2 = b.toString().split(".")[1].length } catch (e) { r2 = 0 }
        m = Math.pow(10, Math.max(r1, r2));
        return (a * m + b * m) / m;
    }

    multiply = (a, b) => {
        let m = 0, c = a.toString(), d = b.toString();

        try {
            m += c.split(".")[1].length
        } catch (e) { }

        try {
            m += d.split(".")[1].length
        } catch (e) { }
        return Number(c.replace(".", "")) * Number(d.replace(".", "")) / Math.pow(10, m);
    }

    formatPrice(amount) {
        let price = amount;//(amount / 100).toFixed(2);
        let numberFormat = new Intl.NumberFormat(['en-US'], {
            style: 'currency',
            currency: this.state.currency,
            currencyDisplay: 'symbol',
        });
        return numberFormat.format(price);
    }

    appendSubscriptionService = (e) => {
        let { dataSource, isVip, months, hisSubscriptions } = this.state;

        let id = parseInt(e.key);
        let tmp = hisSubscriptions.filter((n) => { return n === id });
        if (tmp.length > 0) {
            message.info('This service is already subscribed');
        } else {
            let data = this.props.vService.filter((n) => { return n.type === id });
            if (data.length > 0) {
                let total = this.multiply(data[0].price, months);
                if (isVip && data[0].specialPrice > 0) total = this.multiply(data[0].specialPrice, months);

                dataSource.push({
                    key: data[0].type,
                    title: data[0].title,
                    unitPrice: this.formatPrice(data[0].price),
                    specialOffer: data[0].specialPrice === 0 ? '' : this.formatPrice(data[0].specialPrice),
                    months: months,
                    total: this.formatPrice(total)
                });

                hisSubscriptions.push(data[0].type);

                this.setState({ dataSource, hisSubscriptions }, () => {
                    this.changeAmount(months, this.state.couponId);
                });
            }
        }
    }

    handleUpdateSubscription = () => {
        var user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        if (user.username === undefined) {
            this.setState({ spinLoadding: false }, () => {
                this.props.dispatch({ type: "action_non_user" });
            });
        } else {
            let { dataSource, amount, months, currency, couponId } = this.state;

            const product = [];
            for (let kk in dataSource) {
                product.push({ t: dataSource[kk].key });
            }

            this.props.dispatch({
                type: "action_update_subscription",
                payload: {
                    i: this.props.subscription.id,
                    a: this.multiply(amount, 100),
                    c: currency,
                    d: product,
                    m: months,
                    n: couponId,
                    u: user.username,
                }
            });
        }
    }

    onSelectMon = (months) => {
        this.setState({ months }, () => {
            this.changeAmount(months, this.state.couponId);
        });
    }

    onSelectCoupon = couponId => {
        this.changeAmount(this.state.months, couponId);
    }

    onClickClose = e => {
        let tags = e.currentTarget.getAttribute('data-value');
        let type = parseInt(tags);

        let { dataSource, hisSubscriptions } = this.state;

        if (dataSource.length > 0) {
            dataSource = dataSource.filter(function (item) { return item.key !== type });
            hisSubscriptions = hisSubscriptions.filter(function (item) { return item !== type });
            this.setState({ dataSource, hisSubscriptions }, () => {
                this.changeAmount(this.state.months, this.state.couponId);
            });
        }
    }

    getOrderTotalPrice(discount, months) {
        var total = 0;
        if (discount.length > 0) {
            for (var kk in discount) {
                var d = discount[kk].split(',');
                if (d.length > 0) {
                    if (d[0].toString() === months.toString()) {
                        total = parseFloat(d[1]);
                        break;
                    }
                }
            }
        }
        return total;
    }

    getMonthsByAmount(isVip, type, months) {
        let amount = 0;

        let vService = this.props.vService.filter(function (item) { return item.type === type });
        if (vService.length > 0) {
            let price = vService[0].price;
            let specialPrice = vService[0].specialPrice;

            let discounts = vService[0].discount.split('|');

            //TODO：
            if (isVip) {
                let discount = 0;
                if (discounts.length > 1) {
                    discount = discounts[1].split(';');
                } else {
                    discount = discounts[0].split(';');
                }
                amount = this.getOrderTotalPrice(discount, months);
            } else {
                var discount = discounts[0].split(';');
                amount = this.getOrderTotalPrice(discount, months);
            }

            if (amount === 0) {
                if (isVip && specialPrice > 0) {
                    amount = this.multiply(specialPrice, months);
                } else {
                    amount = this.multiply(price, months);
                }
            }
        }

        return amount;
    }

    changeAmount(months, couponId) {
        let { isVip, dataSource } = this.state;

        let amount = 0;
        for (let kk in dataSource) {
            let type = dataSource[kk].key;

            let total = this.getMonthsByAmount(isVip, type, months);

            if (couponId > 0) {
                var couponSource = [];
                var coupons = JSON.parse(sessionStorage.getItem("CouponCache") || '{}') || {};
                if (coupons.dataSource !== undefined) {
                    couponSource = coupons.dataSource;
                }

                var coupon = couponSource.filter((e) => { return e.id === couponId && e.type === type });
                if (coupon.length > 0) total = this.multiply(total, coupon[0].discount);
            }

            amount = this.add(amount, total);

            dataSource[kk].months = months;
            dataSource[kk].total = this.formatPrice(total);
        }

        this.setState({ amount, dataSource, couponId });
    }

    SetCoupon() {
        var couponSource = [];
        var coupon = JSON.parse(sessionStorage.getItem("CouponCache") || '{}') || {};
        if (coupon.dataSource !== undefined) {
            couponSource = coupon.dataSource;
        }
        //let type = 0;
        //var coupons = couponSource.filter((n) => n.type === type || n.type === 0);
        return (<Select size='small' value={this.state.couponId} style={{ width: 180, marginLeft: 10 }}
            onSelect={this.onSelectCoupon}
            dropdownStyle={{ zIndex: '1' }}>
            {couponSource.map(item => {
                return (<Option key={item.id} value={item.id}>{item.name}</Option>)
            })}
            {couponSource.length > 0 ? (<Option key='0' value={0}>{languageMessages['do.not.use']}</Option>) : (<Option key='0' value={0}>{languageMessages['no.available']}</Option>)}
        </Select>);
    }

    renderMonths() {
        return <Select size='small' value={this.state.months} style={{ width: 180, marginLeft: 10 }}
                onSelect={this.onSelectMon}
                dropdownStyle={{ zIndex: '1' }}>
                <Option value={1}>1</Option>
                <Option value={2}>2</Option>
                <Option value={3}>3</Option>
                <Option value={4}>4</Option>
                <Option value={5}>5</Option>
                <Option value={6}>6</Option>
                <Option value={7}>7</Option>
                <Option value={8}>8</Option>
                <Option value={9}>9</Option>
                <Option value={10}>10</Option>
                <Option value={11}>11</Option>
                <Option value={12}>{languageMessages['1year.discount']}</Option>
                {
                    //   <Option value={24}>{languageMessages['2year.discount']}</Option>
                }
            </Select>
    }

    renderSubscription(subscription) {
        if (subscription === undefined) return '';
        else return <Descriptions title="" bordered>
            <Descriptions.Item label="Subscription no.">{subscription.subscriptionNo}</Descriptions.Item>
            <Descriptions.Item label="Amount" span={2}>
                <span className='common-sub-total'>{this.formatPrice(this.state.amount)}</span>
            </Descriptions.Item>
            <Descriptions.Item label="Created" span={3}>{subscription.created}</Descriptions.Item>
        </Descriptions>
    }

    renderProduct() {
        const columns = [
            {
                title: '',
                key: 'Delete',
                width: 50,
                align: 'center',
                render: (text, record) => (
                    <span>
                        <Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value={record.key} />
                    </span>
                ),
            }, {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
            }, {
                title: 'Unit price',
                dataIndex: 'unitPrice',
                key: 'unitPrice',
                align: 'right',
                render: (text, record) => (
                    <b>{record.unitPrice}</b>
                ),
            }, {
                title: 'Special offer',
                dataIndex: 'specialOffer',
                key: 'specialOffer',
                align: 'right',
                render: (text, record) => (
                    record.specialOffer === '' ? '--' : <b>{record.specialOffer}</b>
                ),
            }, {
                title: 'Months',
                key: 'months',
                dataIndex: 'months',
                align: 'right',
            }, {
                title: 'Total',
                key: 'total',
                dataIndex: 'total',
                align: 'right',
                render: (text, record) => (
                    <b>{record.total}</b>
                ),
            },
        ];

        return <Table size='small' bordered columns={columns} dataSource={this.state.dataSource} pagination={false} />;
    }

    render() {
        let { subscription } = this.props;

        return (<Content className='main-container total-content'>
            <Spin spinning={this.state.spinLoadding}>
                <div className='common-page-header'>
                    <PageHeader title={'Update subscript'} />
                </div>
                <Content style={{ padding: '0px 50px' }}>
                    <div className='common-title'><Divider type="vertical" />{'Subscription information'}</div>
                    {this.renderSubscription(subscription)}
                    <div className='common-title'><Divider type="vertical" />{'Service information'}
                        <span style={{ float: 'right', marginTop: '-5px' }}><Dropdown overlay={this.state.subscriptService}>
                            <Button icon="plus"> Append subscript service </Button>
                        </Dropdown>
                        </span>
                    </div>
                    {this.renderProduct()}
                    <div className='common-selected-coupon'>
                        <span>Select months</span> {this.renderMonths()}
                    </div>
                    <div className='common-selected-coupon'>
                        <span>Select coupon</span> {this.SetCoupon()}
                    </div>
                    <div className='common-total'>{languageMessages['subtotal']}： {this.formatPrice(this.state.amount)}</div>
                    <Button className="ant-btn ant-btn-sm pay-btn" onClick={this.handleUpdateSubscription} loading={this.props.status === 'subscription_processing_commit'} disabled={this.props.status === 'subscription_processing_commit'}>
                        {'Update subscription'}
                    </Button>
                </Content>
            </Spin>
        </Content>)
    }
}

function mapStateToProps(state) {
    return state.charge;
}

const page = connect(mapStateToProps)(UpdatePage);
export { page as default }