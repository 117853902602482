export default function option(state = {}, action) {
    switch (action.type) {
        case "get_option_success":
            return { ...state, status: "get_option_success", underlyingList: action.underlyingList, permission: action.permission }
        case "get_option_fail":
            return { ...state, status: "get_option_fail", state: action.status }

        case "option_scan_commit":
            return { ...state, status: "option_scan_commit" };
        case "option_scan_success":
            return { ...state, status: "option_scan_success", scanResponse: action.scanResponse, permission: action.permission, lastTimes: action.lastTimes }
        case "option_scan_fail":
            return { ...state, status: "option_scan_fail", state: action.status }

        default:
            return state;
    }
}