export default function charge(state = {}, action) {
    switch (action.type) {
        case "service_success":
            return { ...state, status: 'service_success', permission: action.p, isVip: action.Vp, vServices: action.vas };
            break;
        case "service_failed":
            return { ...state, status: 'service_failed' };
            break;
        case "strioe_success":
            return { ...state, status: 'strioe_success', isVip: action.Vp, vService: action.vas, customers: action.c, hisSubscription: action.scs };
        case "strioe_failed":
            return { ...state, status: 'strioe_failed' };

        case "pay_processing":
            return { ...state, status: "pay_processing", stepStatus: action.stepStatus, stepIndex: action.stepIndex };

        case "next_processing":
            return { ...state, status: "next_processing", stepStatus: action.stepStatus, stepIndex: action.stepIndex };

        case "payMethod_success":
            return { ...state, status: 'payment_method_success', clientSecret: action.clientSecret, permission: action.p, coupons: action.c, account: action.a };
        case "payment_failed":
            return { ...state, status: 'payment_method_failed', permission: action.p, coupons: action.c, account: action.a };

        case "payIntent_success":
            return { ...state, status: 'payment_intent_success', permission: action.p, coupons: action.c, account: action.a };

        case "payIntent_failed":
            return { ...state, status: 'payment_intent_failed', account: action.a };

        case "create_subscription_success":
            return { ...state, status: 'create_subscription_success', subscription: action.sc, detailed: action.d, coupons: action.c, permission: action.p, months: action.m };

        case "create_subscription_failed":
            return { ...state, status: 'create_subscription_failed', coupons: action.c };

        case "subscription_success":
            return { ...state, status: 'subscription_success', subscription: action.sc, vService: action.vas, isVip: action.Vp, hisSubscription: action.scs };

        case "subscription_failed":
            return { ...state, status: 'subscription_failed' };

        case "subscription_processing_commit":
            return { ...state, status: 'subscription_processing_commit' };
        case "update_subscription_success":
            return { ...state, status: 'update_subscription_success' };

        case "update_subscription_failed":
            return { ...state, status: 'update_subscription_failed', coupons: action.c };

        case "retry_invoice_success":
            return { ...state, status: 'retry_invoice_success', invoice: action.i };

        case "retry_invoice_failed":
            return { ...state, status: 'retry_invoice_failed' };

        case "email_captcha_commit":
            return { ...state, status: 'email_captcha_commit' };
        case "email_captcha_success":
            return { ...state, status: 'email_captcha_success' };
        case "email_captcha_failed":
            return { ...state, status: 'email_captcha_failed' };

        case "check_email_commit":
            return { ...state, status: 'check_email_commit' };
        case "check_email_success":
            return { ...state, status: 'check_email_success', customers: action.c };
        case "check_email_failed":
            return { ...state, status: 'check_email_failed' };

        case "alipay_success":
            return { ...state, status: 'alipay_success', clientSecret: action.clientSecret };
        case "alipay_failed":
            return { ...state, status: 'alipay_failed' };

        default:
            return state;
    }
}