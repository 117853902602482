import React, { Component } from 'react';
import { Radio, Select, Divider, Alert, Icon } from 'antd';

import { FormattedMessage } from 'react-intl';

const Option = Select.Option;
const RadioGroup = Radio.Group;

const MaxTagTextLength = 5;
const SelectWidth = 130;

var languageMessages = window.appLocale.messages;

class SymbolGroupPage extends Component {
    constructor(props) {
        super(props);

        this.stockData = [];
        this.conceptData = [];
        this.srcData = [];
        this.indexData = [];

        this.customGroupsData = [];

        this.state = {
            isRefresh: false,
            stockPoolKey: 'STOCK',

            stockCount: 0,

            stock: '3180',
            concept: ['2'],
            src: ['5'],
            index: ['7'],

            st: '1',

            generalIndex: '8001,8002,8003,8004,8005',

            fund: '4006',

            customGroups: '-',

            maxTagPlaceholder: '...',

            isSymbolGroup: true,
        };
    }

    componentDidUpdate() {
        var { isRefresh, stock, concept, src, index, st, generalIndex, fund, customGroups } = this.state;
        if (!isRefresh) {
            var { defaultSymbolGroups } = this.props;
            var sectors = defaultSymbolGroups.sectors;
            if (sectors !== undefined && sectors !== null) {
                //概念
                this.conceptData = [];
                this.conceptData.push(<Option key='2'>{languageMessages['select.all']}</Option>);
                if (sectors[2] !== undefined) {
                    for (let key in sectors[2].items) {
                        var subGroupName = sectors[2].items[key].cn;

                        this.conceptData.push(<Option key={key}>{subGroupName}</Option>);
                    }
                }

                //证监会
                this.srcData = [];
                this.srcData.push(<Option key='5'>{languageMessages['select.all']}</Option>);
                if (sectors[5] !== undefined) {
                    for (let key in sectors[5].items) {
                        var subGroupName = sectors[5].items[key].cn;

                        this.srcData.push(<Option key={key}>{subGroupName}</Option>);
                    }
                }

                //指数
                this.indexData = [];
                this.indexData.push(<Option key='7'>{languageMessages['select.all']}</Option>);
                if (sectors[7] !== undefined) {
                    for (let key in sectors[7].items) {
                        var subGroupName = sectors[7].items[key].cn;

                        this.indexData.push(<Option key={key}>{subGroupName}</Option>);
                    }
                }

                isRefresh = true;
            } else {
                this.conceptData = [];
                this.conceptData.push(<Option key='2'> -- </Option>);

                this.srcData = [];
                this.srcData.push(<Option key='5'> -- </Option>);

                this.indexData = [];
                this.indexData.push(<Option key='7'> -- </Option>);
            }

            var customGroupsItems = JSON.parse(sessionStorage.getItem("CustomStockGroupsCache") || '[]') || [];
            if (customGroupsItems !== null) {
                //自定义自选股
                this.customGroupsData = [];
                if (customGroupsItems.length > 0) {
                    for (let key in customGroupsItems) {
                        if (customGroups === '-') {
                            customGroups = customGroupsItems[key].groupId.toString();
                        }
                        this.customGroupsData.push(<Option key={customGroupsItems[key].groupId}>{customGroupsItems[key].groupName}</Option>);
                    }
                }
            }

            var stockCount = defaultSymbolGroups.stockCount;
            var stockPoolKey = defaultSymbolGroups.stockPoolKey;

            //设置默认值
            var stockGroups = defaultSymbolGroups.stockGroups;
            if (stockGroups != null) {
                switch (stockPoolKey) {
                    case 'STOCK':
                        var num = stockGroups.indexOf('-');
                        if (num > -1) {
                            var tmpSt = stockGroups.slice(num, stockGroups.length);
                            if (tmpSt !== '') st = tmpSt;

                            stockGroups = stockGroups.slice(0, num);
                        }
                        var sg = stockGroups.split(';');
                        if (sg.length > 0) {
                            for (var ks in sg) {
                                if (ks == 0) stock = sg[ks];
                                else {
                                    var sh = sg[ks].substr(0, 1);
                                    switch (sh) {
                                        case '2':
                                            concept = sg[ks].split(',');
                                            break;
                                        case '5':
                                            src = sg[ks].split(',');
                                            break;
                                        case '7':
                                            index = sg[ks].split(',');
                                            break;
                                    }
                                }
                            }
                        }
                        break;
                    case 'GENERAL_INDEX':
                        generalIndex = stockGroups;
                        break;
                    case 'FUND':
                        fund = stockGroups;
                        break;
                    case 'CUSTOM_GROUPS':
                        stockCount = 0;
                        customGroups = stockGroups;
                        if (customGroups !== '-') {
                            var customGroupsItems = JSON.parse(sessionStorage.getItem("CustomStockGroupsCache") || '[]') || [];
                            var tmpSymbol = customGroupsItems.filter(function (e) { return e.groupId == customGroups; });
                            if (tmpSymbol.length > 0) {
                                var ss = tmpSymbol[0].symbolList.replace(/(^\s*)|(\s*$)/g, "");
                                if (ss !== '') {
                                    var tmpCount = ss.split(',');
                                    stockCount = tmpCount.length;
                                }
                            }
                        }
                        break;
                    default:
                        break;
                }
            }

            if (isRefresh) {
                this.setState({
                    isRefresh,
                    stockPoolKey: stockPoolKey,

                    stockCount: stockCount,

                    stock: stock,
                    concept: concept,
                    src: src,
                    index: index,

                    st: st,

                    generalIndex: generalIndex,

                    fund: fund,

                    customGroups: customGroups
                });
            }
        }
    }

    componentWillReceiveProps(props) {
        var { stock, generalIndex, fund, customGroups } = this.state;
        var { defaultSymbolGroups } = props;

        var concept = ['2'], src = ['5'], index = ['7'], st = '1';

        var stockCount = defaultSymbolGroups.stockCount;

        var stockPoolKey = defaultSymbolGroups.stockPoolKey;

        var stockGroups = defaultSymbolGroups.stockGroups;
        if (stockGroups != null) {
            switch (stockPoolKey) {
                case 'STOCK':
                    var num = stockGroups.indexOf('-');
                    if (num > -1) {
                        var tmpSt = stockGroups.slice(num, stockGroups.length);
                        if (tmpSt !== '') st = tmpSt;

                        stockGroups = stockGroups.slice(0, num);
                    }
                    var sg = stockGroups.split(';');
                    if (sg.length > 0) {
                        for (var ks in sg) {
                            if (ks == 0) stock = sg[ks];
                            else {
                                var sh = sg[ks].substr(0, 1);
                                switch (sh) {
                                    case '2':
                                        concept = sg[ks].split(',');
                                        break;
                                    case '5':
                                        src = sg[ks].split(',');
                                        break;
                                    case '7':
                                        index = sg[ks].split(',');
                                        break;
                                }
                            }
                        }
                    }
                    break;
                case 'GENERAL_INDEX':
                    generalIndex = stockGroups;
                    break;
                case 'FUND':
                    fund = stockGroups;
                    break;
                case 'CUSTOM_GROUPS':
                    stockCount = 0;
                    customGroups = stockGroups;
                    if (customGroups !== '-') {
                        var customGroupsItems = JSON.parse(sessionStorage.getItem("CustomStockGroupsCache") || '[]') || [];
                        var tmpSymbol = customGroupsItems.filter(function (e) { return e.groupId == customGroups; });
                        if (tmpSymbol.length > 0) {
                            var ss = tmpSymbol[0].symbolList.replace(/(^\s*)|(\s*$)/g, "");
                            if (ss !== '') {
                                var tmpCount = ss.split(',');
                                stockCount = tmpCount.length;
                            }
                        }
                    }
                    break;
                default:
                    break;
            }
        }

        this.setState({
            stockPoolKey: stockPoolKey,

            stockCount: stockCount,

            stock: stock,
            concept: concept,
            src: src,
            index: index,

            st: st,

            generalIndex: generalIndex,

            fund: fund,

            customGroups: customGroups
        });
    }

    onSelectStockPool = e => {
        this.setStockGroup('stockPoolKey', e.target.value);
    };

    handleStockChange = value => {
        this.setStockGroup('stock', value.key);
    }

    ///
    // a 已选中，b 当前选中， key 全部， items 当前列表
    ///
    ///setSelectStockGroup(a, b, items, key) {
    setSelectStockGroup(a, b, key) {
        var data = [];
        if (a.length > b.length) {
            if (b.length > 0) {
                var tmp = a.filter(function (i) { return b.indexOf(i) === -1; });
                if (tmp.length > 0) {
                    if (tmp.indexOf(key) > -1) { // 全不选
                        data = [key];
                    } else {
                        data = b;
                        var index = data.indexOf(key);
                        if (index > -1) {
                            data.splice(index, 1);
                        }
                    }
                }
            } else {
                data = [key];
                /**
                if (a.indexOf(key) > -1) {
                    for (let kk in items) {
                        data.push(kk);
                    }
                }
                */
            }
        } else {
            var tmp = b.filter(function (i) { return a.indexOf(i) === -1; });
            if (tmp.length > 0) {
                if (tmp.indexOf(key) > -1) { // 全选
                    data = [key];
                    /**
                    for (let kk in items) {
                        data.push(kk);
                    }
                    */
                } else {
                    data = b;
                    var index = data.indexOf(key);
                    if (index > -1) {
                        data.splice(index, 1);
                    }
                }
            }
        }

        if (data.length === 0) data = [key];
        return data;
    };

    handleConceptChange = value => {
        var { concept } = this.state;
        concept = this.setSelectStockGroup(concept, value, '2');

        if (concept.indexOf('2') > -1) {
            this.setStockGroup('concept', []);
        } else {
            this.setStockGroup('concept', concept);
        }
    }

    handleSrcChange = value => {
        var { src } = this.state;
        src = this.setSelectStockGroup(src, value, '5');

        if (src.indexOf('5') > -1) {
            this.setStockGroup('src', []);
        } else {
            this.setStockGroup('src', src);
        }
    }

    handleIndexChange = value => {
        var { index } = this.state;
        index = this.setSelectStockGroup(index, value, '7');

        if (index.indexOf('7') > -1) {
            this.setStockGroup('index', []);
        } else {
            this.setStockGroup('index', index);
        }
    };

    handleStChange = value => {
        this.setStockGroup('st', value.key);
    }

    handleFundChange = value => {
        this.setStockGroup('fund', value.key);
    }

    handleCustomGroupsChange = value => {
        this.setStockGroup('customGroups', value.key);
    }

    setStockGroup(type, value) {
        var { stockPoolKey, stock, concept, src, index, st, generalIndex, fund, customGroups } = this.state;

        switch (type) {
            case 'stockPoolKey':
                stockPoolKey = value;
                break;
            case 'stock':
                stock = value;
                break;
            case 'concept':
                concept = value;
                break;
            case 'src':
                src = value;
                break;
            case 'index':
                index = value;
                break;
            case 'st':
                st = value;
                break;
            case 'fund':
                fund = value;
                break;
            case 'customGroups':
                customGroups = value;
                break;
            default:
                break;
        }

        var stockGroups = '';
        switch (stockPoolKey) {
            case 'STOCK':
                stockGroups = stock;

                if (concept.indexOf('2') === -1) {
                    stockGroups += ';' + concept.join(',');
                }
                if (src.indexOf('5') === -1) {
                    stockGroups += ';' + src.join(',');
                }
                if (index.indexOf('7') === -1) {
                    stockGroups += ';' + index.join(',');
                }
                if (st !== '1') {
                    stockGroups += st;
                }
                break;
            case 'GENERAL_INDEX':
                stockGroups = generalIndex;
                break;
            case 'FUND':
                stockGroups = fund;
                break;
            case 'CUSTOM_GROUPS':
                stockGroups = customGroups;
                break;
            default:
                stockGroups = stock;
                break;
        }

        this.props.stockGroup({ stockPoolKey: stockPoolKey, stockGroups: stockGroups });
    }

    renderSymbolGroup() {
        return (<div style={{ padding: '10px 0' }}>
            <div className='common-panel'>
                <label className='common-label-cn'>{languageMessages['index.component']}</label>
                <Select labelInValue
                    size='small'
                    value={{ key: this.state.stock }}
                    style={{ minWidth: SelectWidth }}
                    onChange={this.handleStockChange}>
                    <Option key='3180'>{languageMessages['all.stocks']}</Option>
                    <Option key='3183'>{languageMessages['sh.sz.motherboard']}</Option>
                    <Option key='3181'>{languageMessages['second.boardmarket']}</Option>
                    <Option key='3182'>{languageMessages['sme.board']}</Option>
                    <Option key='3069'>{languageMessages['csi.300']}</Option>
                    <Option key='7300'>{languageMessages['csi.500']}</Option>
                    <Option key='7302'>{languageMessages['csi.800']}</Option>
                </Select>
            </div>

            <div className='common-panel'>
                <label className='common-label-cn'>{languageMessages['concept.plate']}</label>
                <Select mode="multiple"
                    maxTagCount={1}
                    size='small'
                    showArrow={true}
                    maxTagTextLength={MaxTagTextLength}
                    maxTagPlaceholder={this.state.maxTagPlaceholder}
                    value={this.state.concept}
                    style={{ minWidth: SelectWidth }}
                    onChange={this.handleConceptChange}>
                    {this.conceptData}
                </Select>
            </div>

            <div className='common-panel'>
                <label className='common-label-cn'>{languageMessages['src.plate']}</label>
                <Select mode="multiple"
                    maxTagCount={1}
                    size='small'
                    showArrow={true}
                    maxTagTextLength={MaxTagTextLength}
                    maxTagPlaceholder={this.state.maxTagPlaceholder}
                    value={this.state.src}
                    style={{ minWidth: SelectWidth }}
                    onChange={this.handleSrcChange}>
                    {this.srcData}
                </Select>
            </div>
            <div className='common-panel'>
                <label className='common-label-cn'>{languageMessages['index.plate']}</label>
                <Select mode="multiple"
                    maxTagCount={1}
                    size='small'
                    showArrow={true}
                    maxTagTextLength={MaxTagTextLength}
                    maxTagPlaceholder={this.state.maxTagPlaceholder}
                    value={this.state.index}
                    style={{ minWidth: SelectWidth }}
                    onChange={this.handleIndexChange}>
                    {this.indexData}
                </Select>
            </div>

            <div className='common-panel'>
                <label className='common-label-cn'>{languageMessages['ST.stock']}</label>
                <Select labelInValue
                    size='small'
                    value={{ key: this.state.st }}
                    style={{ minWidth: SelectWidth }}
                    onChange={this.handleStChange}>
                    <Option key='1'>{languageMessages['contain']}</Option>
                    <Option key='-9001'>{languageMessages['excluded']}</Option>
                </Select>
            </div>
        </div>);
    }

    //基金
    renderFundPanel() {
        return (<div style={{ padding: '10px 0' }}>
            <label>{languageMessages['sys.fund.pool']}</label>
            <Select labelInValue size='small'
                defaultValue={{ key: this.state.fund }}
                style={{ width: 120, padding: '0 10px' }}
                onChange={this.handleFundChange}>
                <Option key='4006'>ETF</Option>
                <Option key='4030'>{languageMessages['sh.grading.mother']}</Option>
                <Option key='4031'>{languageMessages['sh.grading.a']}</Option>
                <Option key='4032'>{languageMessages['sh.grading.b']}</Option>
                <Option key='4033'>{languageMessages['sz.grading.mother']}</Option>
                <Option key='4034'>{languageMessages['sz.grading.a']}</Option>
                <Option key='4035'>{languageMessages['sz.grading.b']}</Option>
            </Select>
        </div>);
    }

    //自选
    renderCustomGroupsPanel() {
        return (<div style={{ padding: '10px 0' }}>
            <span>
                <label>{languageMessages['optional.stock.pool']}</label>
                <Select labelInValue size='small'
                    defaultValue={{ key: this.state.customGroups }}
                    style={{ width: 180, padding: '0 10px' }}
                    onChange={this.handleCustomGroupsChange}>
                    {this.customGroupsData}
                </Select>
            </span>
        </div>);
    }

    toggleLuisStockPicker = () => {
        var { isSymbolGroup } = this.state;
        isSymbolGroup = isSymbolGroup ? false : true;
        this.setState({ isSymbolGroup });
    }

    renderPoolCount(stockPoolKey, stockCount) {
        if (stockPoolKey === 'STOCK') {
            return <div className='common-title'>
                <span style={{ cursor: 'pointer' }} onClick={this.toggleLuisStockPicker}>
                    <Divider type="vertical" />
                    {languageMessages['stock.pool']}
                    <Icon type={this.state.isSymbolGroup ? 'up' : 'down'} style={{ padding: '0 5px' }} />
                </span>
                <span style={{ fontSize: '12px', padding: '0 10px' }}>
                    (<span style={{ color: 'red' }}><FormattedMessage id="stock.pool.count" values={{ num: stockCount || 0 }} /></span>)
                </span>
            </div>
        } else if (stockPoolKey === 'GENERAL_INDEX') {
            return <div className='common-title'>
                <Divider type="vertical" />
                <span style={{ cursor: 'pointer' }} onClick={this.toggleLuisStockPicker}>
                    {languageMessages['stock.pool']}
                    <Icon type={this.state.isSymbolGroup ? 'up' : 'down'} style={{ padding: '0 5px' }} />
                </span>
                <span style={{ fontSize: '12px', padding: '0 10px' }}>
                    (<span style={{ color: 'red' }}><FormattedMessage id="index.pool.count" values={{ num: stockCount || 0 }} /></span>)
                </span>
            </div>
        } else if (stockPoolKey === 'FUND') {
            return <div className='common-title'>
                <Divider type="vertical" />
                <span style={{ cursor: 'pointer' }} onClick={this.toggleLuisStockPicker}>
                    {languageMessages['stock.pool']}
                    <Icon type={this.state.isSymbolGroup ? 'up' : 'down'} style={{ padding: '0 5px' }} />
                </span>
                <span style={{ fontSize: '12px', padding: '0 10px' }}>
                    (<span style={{ color: 'red' }}><FormattedMessage id="fund.pool.count" values={{ num: stockCount || 0 }} /></span>)
                </span>
            </div>
        } else {
            return <div className='common-title'>
                <Divider type="vertical" />
                <span style={{ cursor: 'pointer' }} onClick={this.toggleLuisStockPicker}>
                    {languageMessages['stock.pool']}
                    <Icon type={this.state.isSymbolGroup ? 'up' : 'down'} style={{ padding: '0 5px' }} />
                </span>
                <span style={{ fontSize: '12px', padding: '0 10px' }}>
                    (<span style={{ color: 'red' }}><FormattedMessage id="stock.pool.count" values={{ num: stockCount || 0 }} /></span>)
                </span>
            </div>
        }
    }

    render() {
        var { stockPoolKey, stockCount, isSymbolGroup } = this.state;
        return (<div>
            {this.renderPoolCount(stockPoolKey, stockCount)}
            <div style={{ display: isSymbolGroup ? '' : 'none' }}>
                <RadioGroup onChange={this.onSelectStockPool} value={stockPoolKey}>
                    <Radio value={'STOCK'}>{languageMessages['stock']}</Radio>
                    <Radio value={'GENERAL_INDEX'}>{languageMessages['general.index']}</Radio>
                    <Radio value={'FUND'}>{languageMessages['fund']}</Radio>
                    <Radio value={'CUSTOM_GROUPS'}>{languageMessages['optional.stock.pool']}</Radio>
                </RadioGroup>
                {
                    stockPoolKey === 'STOCK' ? this.renderSymbolGroup() :
                        stockPoolKey === 'FUND' ? this.renderFundPanel() :
                            stockPoolKey === 'CUSTOM_GROUPS' ? this.renderCustomGroupsPanel() : ''
                }
                {this.props.error === 'STOCK_GROUP_ERROR' ? (<Alert message={languageMessages['please.select.stock.pool']} type="error" />) : ''}
            </div>
        </div>)
    }
}

export { SymbolGroupPage as default }