import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../helpers/history';

import { Layout, PageHeader, Button, Input, Form, Modal, Divider, Spin, message, Row, Col } from 'antd';
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";

import LuisPage from '../../pannels/stock/LuisPage';
import SymbolGroupPage from '../../pannels/stock/SymbolGroupPage';
import USSymbolGroupPage from '../../pannels/stock/USSymbolGroupPage';
import StockOrderPage from '../../pannels/stock/StockOrderPage';
import StockResultPage from '../../pannels/stock/StockResultPage';

import TooltipPage from '../../pannels/account/TooltipPage';
import emitter from '../Evt.js';

import FilterFunction from '../filterFunctions';

const { Content } = Layout;

const Item = ({ entity: { name, txt, char } }) => <Row><Col span={7}>{name}</Col><Col span={17}>{txt}</Col></Row>;

var timer = null;

var languageMessages = window.appLocale.messages;

function strReplace(source, jsonObjcet) {
    for (var v in jsonObjcet) {
        source = source.replace(v, jsonObjcet[v]);
    }
    return source;
}

const locale = window.appLocale.locale;
class VernacularPage extends Component {
    constructor(props) {
        super(props);

        var VernacularStrategy = JSON.parse(sessionStorage.getItem("VernacularStrategyCache") || '{}') || {};
        
        let tmpStockGroups = window.webType === 'US' ? '10000' : '3180';

        let tmpFilterString = VernacularStrategy.filterString === undefined ? '' : VernacularStrategy.filterString;
        let tmpStockPoolKey = VernacularStrategy.stockPoolKey === undefined ? 'STOCK' : VernacularStrategy.stockPoolKey;
        tmpStockGroups = VernacularStrategy.stockGroups === undefined ? tmpStockGroups : VernacularStrategy.stockGroups;

        let tmpOrderType = '$VOLUME_NUM';
        let tmpOrderDirection = '0';

        /**
        var cacheFilterString = JSON.parse(sessionStorage.getItem("cacheScanCondition") || '{}') || {};
        if (Object.keys(cacheFilterString).length > 0) {
            tmpFilterString = cacheFilterString['filterString'];
            tmpStockPoolKey = cacheFilterString['stockPoolKey'];
            tmpStockGroups = cacheFilterString['stockGroups'];

            tmpOrderType = cacheFilterString['orderType'];
            tmpOrderDirection = cacheFilterString['orderDirection'];

            sessionStorage.removeItem("cacheScanCondition");
        }
        */
        
        this.user = {};
        this.state = {
            customId: VernacularStrategy.customId === undefined ? 0 : VernacularStrategy.customId,
            stockPoolKey: tmpStockPoolKey,

            lastTimes: 0,

            caretPosition: 0,

            stockGroups: tmpStockGroups,
            
            filterId: VernacularStrategy.filterId === undefined ? 0 : VernacularStrategy.filterId,
            filterName: VernacularStrategy.filterName === undefined ? '' : VernacularStrategy.filterName,
            filterString: tmpFilterString,

            orderType: tmpOrderType,
            orderDirection: tmpOrderDirection,
            isUseOrder: false,

            modalVisible: false,
            modalTitle: '',

            validateStatus: '',
            validateMessage: '',
            strategyName: '',
            
            stockCount: 0,

            isShowStockResultPage: false,

            spinLoadding: true,

            error: '',
            filterError: '',

            textareaClass: 'common-textarea',

            isLuisAnalysis: false,
            isFilterScan: false
        };
    }

    componentDidMount() {
        emitter.addListener('initPage', this.init);

        this.rta.setCaretPosition(this.state.filterString.length);
    }

    componentWillMount() {
        this.init();
    }

    componentWillUnmount() {
        emitter.removeListener('initPage', this.init);
    }

    init = () => {
        this.user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        /**
        if (user.username === undefined) {
            this.setState({ spinLoadding: false }, () => {
                this.props.dispatch({ type: "action_non_user" });
            });
        } else {
            this.props.dispatch({ type: "action_get_luis_sectors", payload: { UserId: user.username, stockPoolKey: this.state.stockPoolKey, stockGroups: this.state.stockGroups } });
        }
        */
        this.props.dispatch({ type: "action_get_luis_sectors", payload: { UserId: this.user.username, stockPoolKey: this.state.stockPoolKey, stockGroups: this.state.stockGroups } });
    }

    setUserPermission(permissions) {
        var lastTimes = 0;
        for (var key in permissions) {
            var permission = permissions[key];
            switch (permission.type) {
                case 's':
                    lastTimes += permission.totalCount - permission.usageCount;
                    break;
                case 'vs':
                    lastTimes += permission.totalCount - permission.usageCount;
                    break;
                case 's1':
                    lastTimes += permission.totalCount - permission.usageCount;
                    break;
                case 's2':
                    lastTimes += permission.totalCount - permission.usageCount;
                    break;
                default:
                    lastTimes += 0;
                    break;
            }
        }
        this.setState({ lastTimes });
    }

    componentWillReceiveProps(props) {
        var { status, state, fail, editStrategy, customGroup, permission, lastTimes, useFormulaData } = props;
        
        switch (status) {
            case 'luis_success':
                if (this.user.permission !== undefined) {
                    this.user.permission = permission;
                    sessionStorage.setItem("auth", JSON.stringify(this.user));
                    this.setUserPermission(this.user.permission);
                }
                this.setState({ spinLoadding: false });
                break;
            case 'luis_fail':
                if (this.user.permission !== undefined) {
                    this.setUserPermission(this.user.permission);
                }
                var error = languageMessages['get.data.failed'];
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = languageMessages['user.exception'];
                        break;
                    default:
                        break;
                }
                message.error(error);
                this.setState({ spinLoadding: false });
                break;
            case 'scan_formula_success':
                this.user.permission = permission;
                sessionStorage.setItem("auth", JSON.stringify(this.user));

                this.setState({
                    lastTimes: lastTimes,
                    isShowStockResultPage: true,
                    filterError: '',
                    textareaClass: 'common-textarea',
                    isFilterScan: false,
                });
                break;
            case 'scan_formula_fail':
                var error = '';
                var textareaClass = 'common-textarea';
                switch (state) {
                    case 0:
                        error = languageMessages['stock.picker.exception'];
                        break;
                    case 2:
                        error = languageMessages['no.choice.to.stock'];
                        break;
                    case 3:
                        error = languageMessages['connection.strategy.Service.failed'];
                        break;
                    case 4:
                        error = languageMessages['user.exception'];
                        break;
                    case 5:
                        error = languageMessages['request.stock.picker.failed'];
                        break;
                    case 6:
                        error = languageMessages['stock.picker.strategy.error'];
                        if (fail === 'Filter quota is reached.') {
                            error = languageMessages['reaching.strategy.quota'];
                        } else {
                            textareaClass = 'common-textarea common-textarea-error';
                            var eer = '';
                            var ff = fail.split(':');
                            if (ff.length > 0) {
                                for (var i in ff) {
                                    if (i == 0) continue;
                                    eer += ff[i];
                                }
                            }
                            if (eer !== '') error += ' : ' + eer;
                        }
                        break;
                    case 7:
                        error = languageMessages['today.stock.picks.end'];
                        break;
                    default:
                        break;
                }
                this.setState({ filterError: error, textareaClass: textareaClass, isFilterScan: false, });
                break;
            case 'analysis_success':
                this.setState({ isLuisAnalysis: false });
                break;
            case 'analysis_fail':
                var error = languageMessages['Intelligent.analysis.failed'];
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = languageMessages['unable.get.intelligent.analysis.results'];
                        break;
                    case 3:
                        error = languageMessages['connection.analytics.service.failed'];
                        break;
                    case 4:
                        error = languageMessages['request.analytics.failed'];
                        break;
                    case 5:
                        error = languageMessages['user.exception'];
                        break;
                    case 6:
                        error = languageMessages['request.analysis.timeout'];
                        break;
                    default:
                        break;
                }
                message.error(error);

                this.setState({ isLuisAnalysis: false });
                break;
            case 'save_formula_success':
                var customStrategy = JSON.parse(sessionStorage.getItem("CustomStrategyCache") || '[]') || [];
                if (customStrategy.length > 0) {
                    const index = customStrategy.findIndex(item => item.id === editStrategy.id);
                    if (index > -1) {
                        customStrategy[index] = editStrategy;
                    } else {
                        customStrategy.push(editStrategy);
                    }
                } else {
                    customStrategy.push(editStrategy);
                }
                sessionStorage.setItem("CustomStrategyCache", JSON.stringify(customStrategy));

                var VernacularStrategyCache = {
                    customId: editStrategy.id,
                    stockPoolKey: this.state.stockPoolKey,
                    stockGroups: this.state.stockGroups,
                    filterId: editStrategy.filterId,
                    filterName: editStrategy.filterName,
                    filterString: editStrategy.filterString
                };
                sessionStorage.setItem("VernacularStrategyCache", JSON.stringify(VernacularStrategyCache));

                var content = <span>{languageMessages['to view, please go']}"<a href="javascript:;" onClick={this.jumpMyStrategy}>{languageMessages['my.strategy']}</a>"</span>;
                this.timerModal(languageMessages['save.strategy.success'], content);

                this.setState({
                    modalVisible: false,
                    customId: editStrategy.id,
                });

                break;
            case 'save_formula_fail':
                var error = '';
                switch (state) {
                    case 0:
                        error = languageMessages['save.strategy.exception'];
                        break;
                    case 2:
                        error = languageMessages['save.strategy.failed'];
                        break;
                    case 3:
                        error = languageMessages['save.strategy.account.not.exist'];
                        break;
                    case 4:
                        error = languageMessages['user.exception'];
                        break;
                    case 5:
                        error = languageMessages['request.save.strategy.failed'];
                        break;
                    case 6:
                        error = languageMessages['save.strategy.timeout'];
                        break;
                    case 7:
                        error = languageMessages['save.strategy.error'];
                        if (fail === 'Filter quota is reached.') {
                            error = languageMessages['reaching.strategy.quota'];
                        } else {
                            var eer = '';
                            var ff = fail.split(':');
                            if (ff.length > 0) {
                                for (var i in ff) {
                                    if (i == 0) continue;
                                    eer += ff[i];
                                }
                            }
                            if (eer !== '') error += ' : ' + eer;
                        }
                        break;
                    case 8:
                        error = languageMessages['connection.strategy.service.failed'];
                        break;
                    default:
                        break;
                }
                message.error(error);
                this.setState({
                    modalVisible: false,
                });
                break;
            case 'update_customStock_success':
                var customStockGroups = JSON.parse(sessionStorage.getItem("CustomStockGroupsCache") || '[]') || [];
                if (customStockGroups.length > 0) {
                    const index = customStockGroups.findIndex(item => item.groupId === customGroup.groupId);
                    if (index > -1) {
                        customStockGroups[index].groupName = customGroup.groupName;
                        customStockGroups[index].symbolList = customGroup.symbolList;
                    } else {
                        customStockGroups.push(customGroup);
                    }
                } else {
                    customStockGroups.push(customGroup);
                }
                sessionStorage.setItem("CustomStockGroupsCache", JSON.stringify(customStockGroups));

                var content = <span>{strReplace(languageMessages['already.add.success'], { '{num}': this.state.stockCount })}，<a href="javascript:;" onClick={this.jumpMyCustomStock}>{languageMessages['view.my.self-selected.stock']}</a></span>;
                this.timerModal(languageMessages['self-selected.stock.added.success'], content);
                break;
            case 'update_customStock_fail':
                var error = languageMessages['joining.stock.pool.failed'];
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = languageMessages['user.exception'];
                        break;
                    default:
                        break;
                }
                message.error(error);
                break;
            case 'get_stock_count_success':
                this.setState({
                    //stockGroups: data.stockGroups,
                    //stockPoolKey: data.stockPoolKey,
                    error: '',
                    filterError: '',
                });
                break;
            case 'get_stock_count_fail':
                this.setState({
                    error: '',
                    filterError: '',
                });
                break;
            case 'clear_filter_string':
                this.setState({ filterString: '' });
                break;
            case 'use_formula':
                this.setState({
                    isLuisAnalysis: useFormulaData.isLuisAnalysis,
                    filterString: useFormulaData.filterString
                }, () => {
                    if (!useFormulaData.isLuisAnalysis) this.rta.setCaretPosition(this.state.filterString.length);
                });
                break;
            default:
                break;
        }
    }

    timerModal(title, content) {
        let secondsToGo = 5;
        const modal = Modal.success({
            title: title,
            content: content,
        });

        setTimeout(() => {
            modal.destroy();
        }, secondsToGo * 1000);
    }

    handleStockGroup(data) {
        this.setState({
            stockGroups: data.stockGroups,
            stockPoolKey: data.stockPoolKey
        }, () => {
            /**
            if (user.username === undefined) {
                this.setState({ spinLoadding: false }, () => {
                    this.props.dispatch({ type: "action_non_user" });
                });
            } else {
                this.props.dispatch({ type: "action_get_stock_count", payload: { UserId: user.username, stockPoolKey: data.stockPoolKey, stockGroups: data.stockGroups } });
            }
            */
            this.props.dispatch({ type: "action_get_stock_count", payload: { UserId: this.user.username, stockPoolKey: data.stockPoolKey, stockGroups: data.stockGroups } });
        });
    }

    handleStockOrder(data) {
        this.setState({
            orderType: data.orderType,
            orderDirection: data.orderDirection,
            isUseOrder: data.isUseOrder,
        });
    }

    handleLuisAnalysis(data) {
        this.setState({
            isLuisAnalysis: data.isLuisAnalysis
        }, () => {
            if (!data.isLuisAnalysis) this.rta.setCaretPosition(this.state.filterString.length);
        });
    }

    handleCustomStock(data) {
        this.setState({
            stockCount: data.StockCount
        }, () => {
            if (this.user.username === undefined) {
                this.props.dispatch({ type: "action_non_user" });
            } else {
                // 更新股票池
                if (data.GroupId > 0) {
                    this.props.dispatch({ type: "action_update_formula_custom_stock", payload: { GroupId: data.GroupId, UserId: this.user.username, GroupName: data.GroupName, SymbolList: data.SymbolList } });
                } else {
                    this.props.dispatch({ type: "action_add_formula_custom_stock", payload: { UserId: this.user.username, GroupName: data.GroupName, SymbolList: data.SymbolList } });
                }
            }
        });
    }

    jumpMyStrategy = e => {
        e.preventDefault();

        Modal.destroyAll();

        history.push("/strategy");
    }

    jumpMyCustomStock = e => {
        e.preventDefault();

        Modal.destroyAll();

        history.push("/customStock");
    }

    onMyStrategy = e => {
        e.preventDefault();

        Modal.destroyAll();

        history.push("/strategy");
    }

    onValueAddedServices = e => {
        e.preventDefault();
        Modal.destroyAll();
        
        history.push({ pathname: '/member', state: { memberMenuSelectKey: "2" } });
    }

    startStockPicker = () => {
        let { filterError, lastTimes } = this.state;

        if (filterError !== '') {
            //message.error(languageMessages['stock.picker.strategy.in.error']);
            //return;
        }
        
        if (this.user.username === undefined) {
            //saved to cache
            let tmpScanCondition = {
                filterString: this.state.filterString,
                stockPoolKey: this.state.stockPoolKey,
                stockGroups: this.state.stockGroups,
                orderType: this.state.orderType,
                orderDirection: this.state.orderDirection
            };

            //sessionStorage.setItem("cacheScanCondition", JSON.stringify(tmpScanCondition));

            this.props.dispatch({ type: "action_non_user" });
        } else {
            if (lastTimes <= 0) {
                var content = <span>{languageMessages['today.stock.picks.end']}，{languageMessages['please go']}<a onClick={this.onValueAddedServices}>{languageMessages['value-added.services']}</a></span>;
                Modal.info({
                    title: languageMessages['prompt'],
                    content: content
                });
                return;
            }

            this.setState({ isShowStockResultPage: false, isFilterScan: true, filterError: '' }, () => {
                this.checkOperatingStrategy('scen_strategy');
            });
        }
    };

    onSaveStrategy = () => {
        var { filterName, strategyName } = this.state;

        this.setState({
            modalTitle: languageMessages['new.strategy'],
            modalVisible: true,
            validateStatus: '',
            validateMessage: '',
            strategyName: strategyName === '' ? filterName : strategyName
        });
    }

    handleSaveStrategy = () => {
        var { strategyName, customId } = this.state;
        
        var customStrategy = JSON.parse(sessionStorage.getItem("CustomStrategyCache") || '[]') || [];
        var tmpData = customStrategy.filter(function (e) { return e.filterName == strategyName && e.id != customId });
        if (tmpData.length > 0) {
            this.setState({
                validateStatus: 'error',
                validateMessage: languageMessages['strategy.name.already.exists'],
            });
        } else {
            if (this.user.username === undefined) {
                this.props.dispatch({ type: "action_non_user" });
            } else {
                this.checkOperatingStrategy('save_strategy');
            }
        }
    };

    handleCancelStrategy = () => {
        this.setState({
            modalTitle: '',
            modalVisible: false,
            strategyName: '',

            validateStatus: '',
            validateMessage: '',
        });
    };

    checkOperatingStrategy(type) {
        var { stockGroups,
            stockPoolKey,
            filterName,
            filterString,
            customId,
            strategyName,
            orderType,
            orderDirection,
            isUseOrder} = this.state;

        if (stockGroups === '') {
            this.setState({
                error: 'STOCK_GROUP_ERROR',
                isFilterScan: false,
            });
            return;
        }

        if (filterString.length > 8000) {
            message.error(languageMessages['strategy.content.length.too.long']);
            return;
        }

        strategyName = strategyName === '' ? filterName : strategyName;
        switch (type) {
            case 'scen_strategy':
                var params = {
                    UserId: this.user.username,
                    Type: 1,
                    FilterId: 0,
                    FilterName: '',
                    GroupType: stockPoolKey,
                    FilterString: filterString,
                    SymbolGroupId: stockGroups,
                    OrderType: isUseOrder ? '' : orderType,
                    OrderDirection: isUseOrder ? '' : orderDirection
                };

                this.props.dispatch({ type: "action_scen_formula_strategy", payload: params });
                break;
            case 'save_strategy':
                this.props.dispatch({
                    type: "action_save_formula_strategy", payload: {
                        UserId: this.user.username,
                        Type: 1,
                        CustomId: customId,
                        ConceptId: 0,
                        FilterId: 0,
                        FilterName: strategyName,
                        GroupType: stockPoolKey,
                        FilterString: filterString,
                        SymbolGroupId: stockGroups
                    }
                });
                break;
        }
    }

    onCaretPositionChangeHandle = position => {
        this.setState({
            caretPosition: position
        });
    }

    onChangeFilterString = e => {
        var filterString = e.target.value;
        var tmpFilterString = filterString.toLowerCase();
        var topIndex = tmpFilterString.indexOf("selecttopn");
        var bottomIndex = tmpFilterString.indexOf("selectbottomn");
        var isUseOrder = false;
        if (topIndex !== -1 || bottomIndex !== -1) {
            //SelectTopN,SelectBottomN
            isUseOrder = true;
        }

        this.setState({
            filterString: filterString,
            filterError: '',
            textareaClass: 'common-textarea',
            isUseOrder: isUseOrder,
        });
    }

    buildFilterString() {
        var promise = new Promise(resolve => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                var { filterString, caretPosition } = this.state;

                var textareaValue = filterString.slice(0, caretPosition);
                var startPosition = textareaValue.search(/[A-Za-z]*$/);
                var autoMatchData = textareaValue.slice(startPosition, caretPosition);

                var dataSource = [];
                if (autoMatchData !== '') {
                    //var filterFunctionsCache = FilterFunction || [];
                    var filterFunctionsCache = JSON.parse(sessionStorage.getItem("FilterFunctionsCache") || '[]') || [];
                    var filterFunctions = filterFunctionsCache.filter(function (e) { return e.cn.toLowerCase().indexOf(autoMatchData.toLowerCase()) !== -1; });
                    if (filterFunctions.length > 0) {
                        for (var k in filterFunctions) {
                            if (filterFunctions[k].m2 === '') continue;
                            var mouseDiscr = locale.toLowerCase() === 'en-us' ? filterFunctions[k].men : filterFunctions[k].mcn;
                            dataSource.push({
                                key: filterFunctions[k].n,
                                name: filterFunctions[k].cn,
                                txt: mouseDiscr + languageMessages['example'] + filterFunctions[k].m2,
                                char: filterFunctions[k].m2,
                            });
                        }
                    }
                }

                resolve(dataSource);
            }, 200);
        });
        return promise;
    }

    onChangeStrategy = e => {
        e.preventDefault();

        var validateStatus = '';
        var validateMessage = '';
        var strategyName = e.target.value;
        if (strategyName !== '') {
            strategyName = strategyName.slice(0, 20);
            
            var pattern = /^[A-Za-z0-9_\u4e00-\u9fa5]+$/gi;
            if (!pattern.test(strategyName)) {
                validateStatus = 'error';
                validateMessage = languageMessages['strategy.name.format'];
            }
        }
        this.setState({
            validateStatus: validateStatus,
            validateMessage: validateMessage,
            strategyName: strategyName
        });
    }

    clearFilterString = () => {
        if (this.props.status === 'clear_filter_string') {
            this.setState({ filterString: '' });
        } else {
            this.props.dispatch({ type: "action_clear_filterString" });
        }
    }

    render() {
        var { stockPoolKey, filterString, stockGroups } = this.state;
        var { sectors, stockCount } = this.props;

        var defaultSymbolGroups = {
            stockPoolKey: stockPoolKey,
            stockGroups: stockGroups,
            sectors: sectors,
            stockCount: stockCount,
        }

        var formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 4 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 20 }, }, };

        return (<Content className='main-container'>
            <Spin spinning={this.state.spinLoadding}>
            <div className='common-page-header'>
                <PageHeader title={languageMessages['intl.menu.newScan']}
                        extra={[
                        <Button key="1" size='small' onClick={this.onMyStrategy}>{languageMessages['my.strategy']}</Button>,
                        <Button key="2" size='small' onClick={this.onSaveStrategy} disabled={this.state.filterString === ''}>{languageMessages['save.strategy']}</Button>
                    ]} />
                <Modal
                    title={this.state.modalTitle}
                    visible={this.state.modalVisible}
                    onOk={this.handleSaveStrategy}
                    confirmLoading={this.props.status === 'save_formula_commit'}
                    onCancel={this.handleCancelStrategy}>
                    <Form {...formItemLayout}>
                        <Form.Item label={languageMessages['strategy.name.form']}
                            hasFeedback
                            validateStatus={this.state.validateStatus}
                            help={this.state.validateMessage}>
                            <Input placeholder={languageMessages['strategy.name.placeholder']} id={this.state.validateStatus} value={this.state.strategyName} onChange={this.onChangeStrategy} />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
            <Layout className='cont-content luis-main-content'>
                {window.webType === 'CN' ? <SymbolGroupPage defaultSymbolGroups={defaultSymbolGroups} stockGroup={this.handleStockGroup.bind(this)} error={this.state.error} /> : <USSymbolGroupPage defaultSymbolGroups={defaultSymbolGroups} stockGroup={this.handleStockGroup.bind(this)} error={this.state.error} />}
                <LuisPage {...this.props} filterString={filterString} isFilterScan={this.state.isFilterScan} LuisAnalysis={this.handleLuisAnalysis.bind(this)}/>

                <div id="textareaAnchor" className='common-title'>
                    <Divider type="vertical" /><span>{languageMessages['formula.editing']}</span>
                    <Button size='small' onClick={this.clearFilterString} style={{ marginLeft: '20px' }}>{languageMessages['clear']}</Button>
                </div>
                <ReactTextareaAutocomplete rows={10} className={this.state.textareaClass}
                    value={filterString}
                    onChange={this.onChangeFilterString}
                    loadingComponent={() => <span></span>}
                    placeholder={languageMessages['use.formula.generated.or.vernacular.stock.selection']}
                    minChar={0}
                    trigger={{
                        'w': {
                            dataProvider: token => {
                                return this.buildFilterString();
                            },
                            component: Item,
                            output: item => ({ text: item.char, caretPosition: "next" })
                        }
                    }}
                    ref={(rta) => { this.rta = rta; }}
                    onCaretPositionChange={this.onCaretPositionChangeHandle}/>

                <StockOrderPage filterString={filterString} isUseOrder={this.state.isUseOrder} stockOrder={this.handleStockOrder.bind(this)} />
                
                <div style={{ padding: '10px 0' }}>{languageMessages['real-time.market.stock.picker']}</div>

                <div style={{ padding: '0' }}>
                    <Button size='small' onClick={this.startStockPicker} loading={this.props.status === 'scan_formula_commit'} disabled={this.props.status === 'scan_formula_commit' || this.state.filterString === '' || this.state.isLuisAnalysis}>
                        {this.props.status === 'scan_formula_commit' ? languageMessages['stock.picking'] : languageMessages['real.stock.picking']}
                    </Button>
                    <TooltipPage lastTimes={this.state.lastTimes} filterError={this.state.filterError} />
                </div>
            </Layout>
            {this.state.isShowStockResultPage && (<div>
            <div className='common-separate-block'></div>
            <Content className='cont-content'>
                <StockResultPage status={this.props.status} stockPoolKey={stockPoolKey} scanAlert={this.props.scanAlert} customStockEvent={this.handleCustomStock.bind(this)} />
            </Content></div>)}
        </Spin>
    </Content>);
    }
}

function mapStateToProps(state) {
    return state.vernacular;
}

const page = connect(mapStateToProps)(VernacularPage);
export { page as default }