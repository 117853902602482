import { put, call, takeLatest } from 'redux-saga/effects'
import { get } from '../../helpers/server'

function* download(action) {
    try {
        const resp = yield call(get, "/download/files?files=" + action.payload.files);
        if (resp && resp.status === 1) {
            yield put({ type: 'download_success' });
        } else {
            yield put({ type: 'download_failed', data: { status: resp.status } });
        }
    } catch (e) {
        yield put({ type: 'download_failed', status: 0 });
    }
}

function* auth() {
    yield takeLatest('action_download', download);
    
}

export default auth;