const zh_CN = {
    'intl.menu.home': '首页',
    'intl.menu.usStocks': '美股',
    'intl.menu.cnStocks': 'A股',
    'intl.menu.stockPicking': '智能选股',
    'intl.menu.strategyStockPicking': '策略库选股',
    'intl.menu.formulaStockPicking': '公式选股',
    'intl.menu.vernacularStockPicking': '白话选股',
    'intl.menu.newScan': '白话选股',
    'intl.menu.myStragegy': '我的策略',
    'intl.menu.customStock': '我的自选股',
    'intl.menu.option': '期权',
    'intl.menu.factorService': '量化因子服务',
    'intl.menu.hawkeye': '智能盯盘',
    'intl.menu.download': '下载',
    'intl.menu.help': '帮助',
    'videoTutorial': '视频教程',
    'pricing': '购买',

    'factorService': '因子服务',

    'intl.login': '登录',
    'intl.logining': '登录中',
    'intl.login.rememberMe': '记住我',
    'intl.login.forgotPassword': '忘记密码？',
    'intl.login.verificationCodeLogin': '手机验证码登录',
    'intl.login.userNameLogin': '用户名登录',

    'intl.register.free': '注册',
    'intl.login.immediately': '立即登录',

    'intl.register': '注册',
    'intl.registered': '注册中',
    'intl.register.phone': '手机号',
    'intl.register.captcha': '验证码',
    'intl.register.getCaptcha': '获取验证码',
    'intl.register.readAndAccept': '阅读并接受',
    'intl.register.agreement': '《协议》',

    'promo.code': '优惠码',
    'promoCode.format': '优惠码(7位字母和数字)',
    'promoCode.required.format': '优惠码必须为7位数字和字母组合',
    'promoCode.error': '优惠码不存在或已过期',

    'intl.resetPassword': '重置密码',
    'intl.resetPasswording': '重置密码中',

    'intl.youHaveRegisterSuccess': '恭喜您已注册成功！',
    'intl.register.openQuantitativeInvestment': '开启量化投资之旅',

    'intl.forgetPasswordSuccess': '重置密码成功！',
    'intl.login.goToLogin': '请前往登录',

    'optional': '选填',

    'intl.optionSymbol': '期权标的',
    'intl.scanStartEndDate': '扫描起止日',
    'intl.today': '今天',
    'intl.to': '至',
    'intl.direction': '涨跌设置',
    'intl.strategyScan': '策略扫描',
    'intl.strategyScanning': '策略扫描中',

    'please.select.option.target': '请选择期权标的',
    'please.enter.scan.start.and.end.date': '请输入扫描起止日',
    'please.select.ups.and.downs.setting': '请选择涨跌设置',
    'rise': '上涨',
    'fall': '下跌',
    'riseTo': '上涨至',
    'fallTo': '下跌至',
    'please.select.ups.and.downs.setting.unit': '请选择涨跌设置单位',
    'price.difference': '价格差',

    'combination.name': '组合名称',
    'combination.details': '组合详情',
    'maxProfit': '最大收益',
    'maxRisk': '最大风险',
    'investment': '权利金支出/收入',
    'yieldToMaturity': '到期收益率(%)',
    'cost': '支出',
    'income': '收入',

    'option.scan.result': '根据参数设置，共扫描出 {num} 种期权策略组合',
    'combined.profit.and.loss.curve': '组合损益曲线',

    'company': 'CopyRight 宽投金融科技 {year} ',
    'about': '关于我们',
    'contact': '联系我们',
    'WeChat.public.number': '微信公众号',

    'about.info.01': '宽投金融科技创始团队来自于JP Morgan Chase, Lehman Brothers, RBC Capital、Goldman Sachs 等华尔街知名金融机构，并获得国内证券市场专家，数据科学人工智能专家，资深交易技术团队的加强。宽投专注于证券科技，提供量化投研交易平台。团队从负责各种金融产品交易和风控系统设计与研发，到管理基金进行量化交易，积累并创造了InSync 和 CrystalTrade 等核心产品。',
    'about.info.02': '宽投拥有100%自主创新的CEP（复杂事件处理引擎）技术，多年华尔街丰富的交易和风控系统研发与量化交易经验、人工智能和机器学习的最新技术，全市场Tick级行情数据处理，面向券商、买方机构与个人投资者提供一站式完整解决方案。投资者可通过自然语言或宽投独有的向导式简易函数与公式，无需编程，完成策略定制、调试、交易和风控规则编辑、回测验证、智能优化、实盘运行等，帮助用户便利、快速、高效完成投资思想并投放市场。',
    'about.info.03': '小宽全能股票机器人 – 用户在手机APP上，通过自然语言迅速完成智能的选股、回测、优化、白话下单等完整投资流程。',
    'about.info.04': '宽投的智能量化因子分析系统，使专业投资者的阿尔法因子探索简单化与系统化，短短几十分钟，就能在实时市场实现几十、上百个因子的探索、历史周期的因子文件生成、因子的单调性、自相关性、收益率、换手率、在各行业的表现等多维度的分析。',

    'contact.info.01': '公司总部：上海市静安区静安嘉里中心1号楼26F',
    'contact.info.02': '客户服务热线：021-52685165',
    'contact.info.03': '邮箱：info@quancept.cn',
    'contact.info.04': '微信公众号：quancept',

    'get.verification.code.failed': '获取验证码失败',
    'phone.not.register': '手机未注册',
    'phone.format.failed': '手机号格式错误',
    'login.exception': '登录异常',
    'account.not.register': '账号未注册',
    'account.exception': '账号异常',
    'password.or.verification.code.match.error': '密码或验证码匹配错误',

    'user.account.has.expired': '用户账号已过期，请联系客服',
    'account.not.expired': '账号过期时间还有 {num} 天',
    'account.about.to.expire': '账号即将过期',


    'reset.password.success': '重置密码成功',
    'reset.password.exception': '重置密码异常',
    'verification.code.matching.error': '验证码匹配错误',

    'reset.password.failed': '重置密码失败',

    'phone.is.register': '手机已注册',
    'register.success': '注册成功',
    'register.failed': '注册失败',
    'register.exception': '注册异常',
    'account.is.exist': '账号已存在',

    'phone': '手机号码',
    'username/phone': '用户名/手机号',
    'account.password': '账号密码',

    'phone.china.only': '手机号码',
    'phone.captcha': '手机验证码',
    'password.format': '密码(6-16位，字母，数字，区分大小写)',

    'phone.use.register': '手机号码(注册时使用的手机号码)',
    'password.new.format': '新密码(6-16位，字母，数字，区分大小写)',
    'password.old.format': '原密码(6-16位，字母，数字，区分大小写)',


    'please.input.phone': '请输入手机号',
    'please.input.captcha': '请输入验证码',
    'please.input.password': '请输入密码',

    'please.input.username/phone': '请输入用户名/手机号',

    'password.min': '密码不能少于6个字符',
    'password.max': '密码不能大于16个字符',
    'password.required.format': '密码必须为数字字母[区分大小写]',

    'get.data.failed': '获取数据失败',
    'user.exception': '用户没有登录或登录已过期',

    'function.not.open': '功能暂未开放，敬请期待',

    'sequence': '序号',

    'please go': '请前往',
    //选股
    'featured.strategy.directory': '精选策略目录',
    'my.formula.stock.picking.strategy': '我的公式选股策略',
    'strategy.items': '策略列表',
    'no.strategy.library': '暂无策略库',
    'no.stock.pool': '暂无股票池',
    'no.strategy': '暂无策略',

    'scan.strategy.exception': '扫描策略出现异常',
    'no.scan.to.stock': '没有扫描到股票，请修改策略后再试试',
    'request.scan.strategy.failed': '请求扫描策略失败',
    'scan.strategy.error': '扫描的策略有错误',
    'today.scan.strategy.end': '今日扫描次数已用完',

    'scan.strategy.in.error': '请修改公式或股票池后再扫描',
    'scan.strategy.exception': '扫描策略出现异常',


    'stock.picker.exception': '选股出现异常',
    'no.choice.to.stock': '没有选择到股票，请修改策略后再试试',
    'connection.strategy.Service.failed': '连接策略服务失败',
    'request.stock.picker.failed': '请求选股失败',
    'stock.picker.strategy.error': '选股的策略有错误',
    'reaching.strategy.quota': '达到策略配额',
    'today.stock.picks.end': '今日选股次数已用完',

    'remaining.times': '今日剩余次数',
    'times': '次',

    'Intelligent.analysis.failed': '智能分析失败',
    'unable.get.intelligent.analysis.results': '未获取到智能分析结果',
    'connection.analytics.service.failed': '连接智能分析服务失败',
    'request.analytics.failed': '请求智能分析失败',
    'request.analysis.timeout': '请求智能分析超时',

    'save.strategy.success': '保存策略成功',
    'to view, please go': '如要查看，请前往',
    'my.strategy': '我的策略',
    'save.strategy.exception': '保存策略出现异常',
    'save.strategy.failed': '保存策略失败',
    'save.strategy.account.not.exist': '保存策略账号不存在',
    'request.save.strategy.failed': '请求保存策略失败',
    'save.strategy.timeout': '保存策略超时',
    'save.strategy.error': '保存的策略有错误',
    'connection.strategy.service.failed': '连接策略服务失败',

    're-get.stock.condition.failed': '重新获取选股条件失败',

    'already.add.success': '已成功添加{num}只股票',
    'stocks': '只股票',

    'view.my.self-selected.stock': '查看我的自选股',
    'self-selected.stock.added.success': '自选股添加成功',
    'joining.stock.pool.failed': '加入到股票池失败',

    'save.strategy': '保存策略',
    'edit.strategy': '编辑策略',
    'new.strategy': '新建策略',
    'copy.strategy': '复制策略',
    '_copy': '_副本',

    'strategy.name.already.exists': '该策略名已经存在，请换个名字重新尝试！',

    'new.strategy.success': '新建策略成功！',
    'copy.strategy.success': '复制策略成功！',

    'saved.strategy.in.error': '请修改公式或股票池后再保存',
    'stock.picker.strategy.in.error': '请修改公式或股票池后再选股',
    'strategy.content.length.too.long': '策略内容长度不能超过8000字节',

    'example': '，举例：',

    'prompt': '提示',
    'are.you.leaving.this.page': '确定要离开此页面？系统不会保存您所做的更改。',
    'leave': '离开',
    'stay': '留下',
    'clear': '清除',

    'use.formula.generated.or.vernacular.stock.selection': '可使用上面白话选股生成的公式，也可自己输入公式',

    'strategy.name.format': '策略名称只能是中文、字母大小写、数字',

    'real-time.market.stock.picker': '根据以上选股策略，使用实时行情数据选股。',

    'saving': '保存中...',

    'strategy.name.form': '策略名称：',
    'strategy.name.placeholder': '取个好名儿(1-24字节)',

    'vernacular.stock.picking': '白话选股',
    'guess.you.want.input': '猜你想输入',
    'change.batch': '换一批',
    'input.stock.picking.statement': '输入选股语句',
    'input.hawkeye.statement': '输入盯盘语句',
    'parsing': '解析中...',
    'intelligent.analysis': '智能解析',

    'correspond.formula': '对应公式',
    'using.formula': '使用公式',
    'formula.editing': '公式编辑',

    'stock.picking': '选股中...',
    'real.stock.picking': '实盘选股',

    'press': '按',
    'sort.filter': '排序筛选',
    'sort,filter': '排序，筛选',
    'before': '前',
    'after': '后',
    'ranking': '20名',

    'latest.price': '最新价',
    'quote.change': '涨跌幅',
    'volume': '成交量',
    'turnover': '成交额',
    'turnover.rate': '换手率',
    'volatility': '波动率',

    'strategy.formula': '策略公式',
    'comment': '注释',

    'stock.pool.name.already.exists': '该股票池名已经存在，请换个名字重新尝试！',
    'stock.pool.name.format': '股票池名只能是中文、字母大小写、数字',
    'please.select.or.new.stock.pool': '请选择股票池或新建股票池',

    'select.all': '全选',

    'symbol.code': '股票代码',
    'symbol.name': '股票名称',
    'symbol.type': '股票类型',

    'eligible.stocks': '符合条件的{num}只{name}如下',

    'new.stock.pool': '新建股票池',
    'add.choice': '加入自选',
    'save.stock': '保存到本地',
    'stock.pool.name.form': '股票池名：',
    'stock.pool.name.placeholder': '取个好名儿(1-20字节)',

    'mete.price': '量价',
    'fundamental': '基本面',
    'technically': '技术面',
    'other': '其他',

    'vernacular.left.prompt': '请选择左边语句或输入你的关键词或语句',
    'not.match.mete.price.statements': '没有匹配到量价相关语句',
    'not.match.fundamental.statements': '没有匹配到基本面相关语句',
    'not.match.technically.sentences': '没有匹配到技术面相关语句',
    'not.match.other.statements': '没有匹配到其他相关语句',
    'not.match.intraday.statements': '没有匹配到日内相关语句',

    'index.component': '指数成份',
    'all.stocks': '所有股票',

    'ST.stock': 'ST股票',
    'contain': '包含ST',
    'excluded': '不含ST',

    'optional.stock.pool': '自选股票池',
    'stock.pool': '股票池',
    
    'total.stock': '只股票',

    'stock.pool.count': '股票池共计：{num}只股票',
    'index.pool.count': '指数池共计：{num}只指数',
    'fund.pool.count': '基金池共计：{num}只基金',

    'stock': '股票',
    'fund': '基金',
    'index': '指数',
    'general.index': '沪深指数',

    'please.select.stock.pool': '请选择股票池',

    'sh.sz.motherboard': '沪深主板',
    'second.boardmarket': '创业板',
    'sme.board': '中小板',
    'csi.300': '沪深300',
    'csi.500': '中证500',
    'csi.800': '中证800',

    'area.plate': '地域板块',
    'concept.plate': '概念板块',
    'industry.plate': '行业板块',
    'src.plate': '证监会行业',
    'index.plate': '指数板块',
    'other.plate': '其他板块',

    'sys.fund.pool': '系统基金池',
    'sh.grading.mother': '沪分级母',
    'sh.grading.a': '沪分级A',
    'sh.grading.b': '沪分级B',
    'sz.grading.mother': '深分级母',
    'sz.grading.a': '深分级A',
    'sz.grading.b': '深分级B',

    'nyse': '纽交所',
    'nasdaq': '纳斯达克',
    'd-jones.index': '道琼斯指数',
    'nasdaq.100': '纳斯达克100',
    'sp.500': '标准普尔500',
    'russell.2000': '罗素2000',

    'my.strategy.lib': '我的策略库',
    'new.strategy.lib.stock.picker': '新建策略库选股',
    'new.vernacular.stock.picker': '新建白话选股',

    'rename': '重命名',
    'copy': '复制',
    'remove': '删除',

    'update': '更新',

    'backtest.status': '回测进行状态',
    'last.updated': '最后更新时间',
    'backtest.count': '回测次数',
    'control': '操作',
    'backtest.items': '回测列表',

    'remove.this.strategy': '确定要删除这个策略吗?',
    'yes': '是',
    'no': '否',

    'rename.strategy.failed': '重命名策略失败',
    'copy.strategy.failed': '复制策略失败',
    'copy.strategy.account.not.exist': '复制策略账号不存在',
    'request.copy.strategy.failed': '请求复制策略失败',
    'copy.strategy.timeout': '复制策略超时',
    'copy.strategy.error': '复制的策略有错误',
    'connection.strategy.server.failed': '连接策略服务器失败',

    'want.delete.stock': '确定要删除股票吗?',
    'remove.strategy.success': '删除策略成功',
    'remove.strategy.failed': '删除策略失败',

    'add.stock.option': '添加自选股',
    'add.stock.option.failed': '添加自选股失败',
    'reach.the.stock.quota': '达到自选股配额',
    'add.or.update.stock.option.failed': '添加或更新自选股失败',
    'remove.stock.option.success': '删除自选股成功',
    'remove.stock.option.failed': '删除自选股失败',

    'copy.stock.pool': '复制股票池',
    'make.sure.delete.stock.pool': '确定要删除股票池',

    'no.matches.found': '未找到匹配项',

    'update.failed': '更新失败',
    'update.success': '更新成功',
    'logout.failed': '登出失败',

    'edit.user.info': '编辑用户信息',

    'my.home': '我的主页',
    'value-added.services': '增值服务',
    'my.account': '我的账单',
    'account.setting': '账号设置',
    'message': '消息',
    'exit': '退出',

    'coupon': '优惠券',
    'be.usable': '可使用',
    'no.coupon.available': '您暂无可用优惠券',
    'valid.until': '有效期至',
    'invalid.coupon': '无效券',
    'no.invalid.coupon': '您暂无无效优惠券',
    'to.use': '去使用',

    'send': '发送',
    'get.message.failed': '获取消息失败',
    'send.message.success': '发送消息成功',
    'send.message.failed': '发送消息失败',
    'private.letter.administrator': '私信给管理员',
    'personal message': '个人消息',
    'sys.message': '系统消息',

    'all': '全部',
    'complete': '完成',
    'processing': '进行中',
    'failure': '失败',

    '1month': '1个月',
    '6month': '半年',
    '1year': '1年',

    'tradeTime': '交易时间',
    'tradeAmount': '交易金额',
    'tradeIntro': '交易详情',
    'months': '月数',
    'expiredDate': '到期日期',
    'tradeStatus': '交易状态',

    'get.account.info.failed': '获取账号信息失败',

    'time': '时间',
    'recent': '最近',
    'status': '状态',
    'tradeRecord': '交易记录',

    'real.stock.picker': '实时选股',
    'stock monitoringr': '智能盯盘',

    'personal.position.scan': '个人仓位扫描',
    'parameter.settings': '参数设置',
    'option.scan.exception': '期权扫描出现异常',
    'option.scans.have.run.out': '本月期权扫描次数已用完',
    'request.option.scan.failed': '请求期权扫描失败',
    'no.option.data.scanned': '没有扫描到期权数据',

    'profitloss': '损益',
    'price': '价格',
    'profit.and.loss': '到期收益',

    'optionStrategyScan': '期权策略扫描',
    'factorScan': '因子扫描',
    'factorGeneration': '量化因子服务因子生成',
    'intelligentFactorAnalysis': '智能因子分析',
    'ordinary.member': '普通会员',
    'vip.member': 'VIP会员',
    'intro': '简介',
    'intro.description': '这家伙很懒，什么也没留下~',
    'account': '账户',
    'value-added.service.usage.status': '增值服务使用状态',
    'coupons': '张优惠券',

    'used': '已使用',
    'expired': '已过期',

    'can.only.upload.image.files': '你只能上传jpg文件',
    'upload.avatar.size': '上传头像必须小于2MB',

    'account.name.already.exists': '该账户名已经存在，请换个名字重新尝试',
    'update.password.success': '修改密码成功',
    'update.password.failed': '修改密码失败',
    'old.password.input.error': '原密码输入错误',
    'newPassword.and.oldPassword.not.same': '新密码和原密码不能相同',
    'newPassword.and.confirmPassword.must.same': '新密码和确认密码必须相同',

    'phone.is.bound': '手机已绑定',
    'binding.success': '绑定成功',
    'unbind.success': '取消绑定成功',
    'binding.failed': '绑定失败',
    'unbind.failed': '取消绑定失败',
    'account.is.bound.phone': '已有账号绑定此手机号',
    'verification.code.error': '验证码错误',
    'nickname.format': '用户昵称只能是中文、字母大小写、数字',
    'upload.failed': '上传失败',

    'ok.to.unbind': '确定要取消绑定?',

    'basic.info': '基本信息',
    'nickname': '用户昵称',
    'bind.phone': '绑定手机号码',

    'unbind': '取消绑定',

    'please.input.bind.phone': '请输入要绑定的手机号码',

    'login.password': '登录密码',
    'update.password': '修改密码',
    'old.password': '原密码',
    'new.password': '新密码',
    'confirm.password': '确认密码',

    'personal.profile': '个人简介',
    'modify.avatar': '修改头像',
    'select.avatar': '选择头像',

    'upload.image.format': '支持JPG、JPEG、BMP或PNG格式图片，不超过2MB；拖拽或缩放图中的虚线方格可调整头像。',

    'payment.server.00.01': '1. 凡注册成功，即成为宽投普通会员；',
    'payment.server.00.02': '2. 可免费享受3次/天实时选股功能；',

    'payment.server.01.01': '1. 可享受10次/天实时选股功能；',
    'payment.server.01.02': '2. 可享受10次/月期权策略扫描功能；',

    'payment.server.02.01': '1. 可享受30次/天实时选股功能；',
    'payment.server.03.01': '1. 可享受50次/天实时选股功能；',

    'payment.server.04.01': '1. 基于对市场未来预期，对不同时间跨度、涨跌幅的期待，进行期权策略扫描，获取不同期权策略组合的结构、收益、风险及图示；',
    'payment.server.04.02': '2. 可享受总共30次/月期权策略扫描服务；',

    'payment.server.05.01': '1. 金融大数据结合人工智能技术，技术指标、财务数据、实时量价等因子库信息实时生成因子；',
    'payment.server.05.02': '2. 可享受30次/天因子扫描服务；',
    'payment.server.05.03': '3. 可享受5次/天因子生成服务；',
    'payment.server.05.04': '4. 可享受5次/天智能因子分析服务；',

    'inSync.platform': 'InSync智能量化平台',
    'payment.server.06.01': '1. 智能选股择时：白话选股、向导式公式选股、自定义公式选股，智能选股引擎、智能择时引擎；',
    'payment.server.06.02': '2. 策略构建：策略基础构建、策略风控、调仓策略；',
    'payment.server.06.03': '3. 智能回测服务：智能回测、归因分析、智能策略优化；',
    'payment.server.06.04': '4. 可创建20个过滤器；',
    'payment.server.06.05': '5. 可享受100,000点/月智能回测及优化服务；',

    'hawkeye.server.07.01': '1. 可享受30次/天智能盯盘功能；',

    'factor.scan': '因子扫描',
    'factor.generation': '因子生成',
    'intelligent.factor.analysis': '智能因子分析',

    'option.strategy.scan': '期权策略扫描',

    'payment.failed': '付款失败',

    'option.strategy.service': '期权策略服务',
    'Quantitative factor service': '量化因子服务',

    'upgrade.vip.member': '升级到VIP会员',
    'free': '免费',
    'buy': '购买',
    'pay': '应付',
    'yuan': '元',
    'wan': '万',
    'yuan/month': '元/月',

    'yuan/year': '元/年',
    'yuan/2year': '元/2年',
    'discount': '折',

    'remaining.validity.period': '有效期内剩余次数',

    '1year.discount': '1年(8.4折)',
    '2year.discount': '2年(7.6折)',

    'vip.special': 'VIP特惠',

    'service.name': '服务名称',
    'unit.price': '单价',
    'vip.special.price': 'VIP特惠价',
    'subtotal': '小计',
    'do.not.use': '不使用',
    'no.available': '无可用',

    'payment.prompt.01': '请尽快完成支付，未完成前不要关闭本窗口',
    'payment.prompt.02': '如付款遇到问题，请联系宽投QQ客服:3432772199；或联系宽投电话客服：021-52685165',

    'service.intro': '服务介绍',
    'please.select.service.type': '请选择服务类型',
    'waiting.for.payment': '等待付款中',
    'confirm.payment': '确认付款',
    'description': '说明',

    'payment.info.03': '无论您是刚刚接触量化投资的新手，还是身经百炼的量化达人，宽投都可以通过机器人帮您进行深度量化策略优化。总有一款适合您！',
    'payment.info.01': '1.所有服务购买成功后无法退款，不可折现；',
    'payment.info.02': '2.如果遇到无法交易、充值失败等问题，可通过以下两种方式和客服进行联系：',

    'quancept.info.qq': '添加宽投QQ客服：487139452',
    'quancept.info.phone': '拨打电话：021-52685165',
    'quancept.info.work': '工作时间为周一至周五09:00—19:00',


    'download.immediately': '立即下载',
    'download.info.001': '智能量化平台',
    'download.info.002': '无需编程，自然语言或简易公式实现选股择时、量化策略编写、精准回测、策略优化、归因分析',
    'download.info.003': '',
    'download.info.004': '',
    'download.info.005': '',

    'download.info.006': '集中交易管理端',
    'download.info.007': '支持多种下单类型；',
    'download.info.008': '支持策略触发下单；',
    'download.info.009': '支持多策略组交易；',
    'download.info.010': '支持全自动或手工确认交易',
    'download.info.011': '',
    'download.info.012': '',

    'android.version': 'Android版本',
    'ios.version': 'IOS版本',

    'stay.tuned': '敬请期待',
    'download.info.08': '全能股票机器人',
    'download.info.09': '基于宽投多年量化系统和交易的丰厚积累，结合人工智能与机器学习最新技术，通过自然语言交互，实现白话选股、智能回测、策略优化、白话下单等一系列智能量化投资服务',

    //因子扫描
    'factor.scan.result': '根据最新行情数据，共扫描出 {num} 只{name}',
    'save.factor.local': '查看全部数据，请保存至本地',
    'my.factor.file': '我的因子文件',
    'factor.title.01': '1、因子扫描',
    'scanning': '扫描中...',
    'generating.factor.file.condition': '根据以上公式条件，生成因子文件',
    'factor.title.02': '2、生成因子文件',

    'generating.factor.file': '生成因子文件',
    'generating': '正在生成',
    'submitting': '提交中...',
    'ready.generate': '准备生成',
    'build.failed': '生成失败',
    'factor.generation.has.run.out': '今日因子生成次数已用完',
    'data.frequency': '数据频率',
    '60minute.line': '60分钟线',
    'generated.complete': '生成完成',
    'please.complete.factor.scan': '请完成因子扫描',

    'start.generating': '开始生成',
    'generated.file.in': '生成文件中',
    'not.analyzed': '未分析',
    'start.analysis': '开始分析',
    'analysis.failure': '分析失败',
    'analyzing': '分析中',
    'analysis.completed': '分析完成',
    'upload.factor.format': '你只能上传csv文件',
    'upload.factor.size': '上传文件必须小于20MB',
    'factor.file.name': '因子文件名',
    'last.update.date': '最后更新日期',
    'type': '类型',
    'is.submitting': '正在提交',
    'prepare.analysis': '准备分析',
    'view.analysis.results': '查看分析结果',
    'build.please.wait': '生成中，请稍后',

    'upload': '上传',
    'generate': '生成',

    'analysis.of.anomalies': '分析出现异常',
    'error.analyzing.data': '分析数据出错或不存在',
    'analysis.points.end': '今日分析点数已用完',
    'analysis.server.not.responding': '分析服务器无响应',
    'no.querying.analysis.tasks': '没有查询到分析的任务',

    'upload.failed': '上传失败',

    'remove.factor.file.failed': '删除因子文件失败',

    'analyze.this.factor.file': '你确定要分析这个因子文件吗?',
    'analysis': '分析',
    'cancel': '取消',

    'delete.this.factor.file': '你确定要删除这个因子文件吗?',

    'upload.factor.file': '上传因子文件',

    'factorName': '因子名称',
    'periodWise': '周期',
    'totalRet': '收益率',
    'sharpeRatio': '夏普-[Sharpe]',
    'annAlpha': '阿尔法-[ALPHA]',
    'beta': '贝塔-[BETA]',

    'have': '有',
    'not': '无',

    'industry.name': '行业名称',
    'monotonic(period)': '单调性(周期)',
    'monotonic': '单调性',

    'factor.analysis': '因子分析',

    'rate.of.return.analysis': '收益率分析',
    'ic.analysis': 'IC分析',
    'turnover.analysis': '换手率分析',

    //期权
    'breakEvenPoint': '盈亏平衡点',
    'Long Call': '买入看涨',
    'Uncovered Put Write': '无备兑看跌',
    'Bull Call Spread': '牛市看涨价差',
    'Bull Put Spread': '牛市看跌价差',
    'Bull Call': '牛市看涨',
    'Bull Put': '牛市看跌',
    'Synthetic Long Stock': '合成股票多头',
    'Synthetic Long Stock 2': '合成股票多头2',
    'Call Backspread': '看涨反向价差',
    'Covered Calls': '抛补看涨',
    'Synthetic Long Call': '合成买入看涨',
    'Collar': '领子',
    'Covered Combination': '备兑组合',
    'Covered Straddle': '备兑跨式',

    'Long Put': '买入看跌',
    'Naked Call': '裸卖空',
    'Naked Call 2': '裸卖空2',
    'Bear Call': '熊市看涨',
    'Bear Put': '熊市看跌',
    'Put Backspread': '看跌反向价差',
    'Synthetic Short Stock': '合成股票空头',
    'Synthetic Short Stock 2': '合成股票空头2',
    'Covered Put Write': '备兑看跌',
    'Synthetic Long Put': '合成买入看跌',

    'Call Ratio Spread': '看涨比率价差',
    'Put Ratio Spread': '看跌比率价差',
    'Long Straddle': '买入跨式',
    'Short Straddle': '卖出跨式',
    'Long Strangle': '买入宽跨式',
    'Short Strangle': '卖出宽跨式',
    'Long Guts': '买入飞碟式',
    'Short Guts': '卖出飞碟式',
    'Strip': '条式',
    'Strap': '带式',
    'Ratio Call Write': '比率看涨',
    'Ratio Put Write': '比率看跌',
    'Long Put Synt Straddle': '买入看跌合成跨式',
    'Long Call Synt Straddle': '买入看涨合成跨式',
    'Long Put Butterfly': '买入看跌蝶式',
    'Short Put Butterfly': '卖出看跌蝶式',
    'Long Butterfly': '买入蝶式',
    'Short Butterfly': '卖出蝶式',
    'Long Call Ladder': '买入看涨梯形',
    'Short Call Ladder': '卖出看涨梯形',
    'Long Put Ladder': '买入看跌梯形',
    'Short Put Ladder': '卖出看跌梯形',
    'Variable Ratio Write': '可变比率',
    'Condor': '秃鹰',
    'Short Condor': '卖出秃鹰',
    'Iron Condor': '铁秃鹰',
    'Reverse Iron Condor': '反向铁秃鹰',
    'Iron Butterfly': '铁蝶式',
    'Reverse Iron Butterfly': '反向铁蝶式',

    'Reversal': '反转',
    'Conversion': '转换',
    'Long Box': '买入箱体',
    'Short Box': '卖出箱体',

    'Neutral Calendar': '中性日历',

    'Short Calendar Straddle': '卖出日历宽跨式',

    'Fake Neutral Calendar': '伪装中性日历',

    'PriceRiseTo': '价格涨到',
    'PriceRiseToInfo': '价格涨到{num}元',

    'PriceFellTo': '价格跌到',
    'PriceFellToInfo': '价格跌到{num}元',

    'ReboundRange': '上涨回落',
    'ReboundRangeInfo': '价格上涨至{num1}，回落{num2}',

    'Fallback': '下跌反弹',
    'FallbackInfo': '价格下跌至{num1}，反弹{num2}',

    'priceDownTo': '价格下跌至',
    'priceUpTo': '价格上涨至',
    'Bounce': '反弹',
    'huiLuo': '回落',

    'UpperOvergo': '上穿',
    'UnderOvergo': '下穿',
    'AvgLine': '均线',

    'VolumeIsPast': '成交量为过去',
    'Average': '平均值的',
    'day': '天',
    'multiple': '倍',

    'DailyIncreaseReached': '价格日涨幅',
    'DailyIncreaseReachedInfo': '价格日涨幅达到{num}%',

    'DailyDeclineReached': '价格日跌幅',
    'DailyDeclineReachedInfo': '价格日跌幅达到{num}%',

    'dayIncreaseReach': '日涨幅达到',
    'dayDeclineReach': '日跌幅达到',

    'PriceRise': '价格创新高',
    'PriceFalling': '价格创新低',
    'priceUpWound': '价格上涨创',
    'priceUpWoundHistoryHigh': '价格上涨创历史新高',
    'priceUpWoundInfo': '价格上涨创{num}日新高',

    'priceDownWound': '价格下跌创',
    'priceDownWoundHistoryLow': '价格上涨创历史新低',
    'priceDownWoundInfo': '价格下跌创{num}日新低',

    'StockPriceWearOn': '价格上穿均线',
    'StockPriceWearOnInfo': '价格上穿{num}{avg}均线',

    'StockPriceFellBelow': '价格下穿均线',
    'StockPriceFellBelowInfo': '价格下穿{num}{avg}均线',

    'StockPriceUp': '价格上穿',
    'StockPriceDown': '价格下穿',

    'Increase': '分钟涨幅达到',
    'IncreaseInfo': '{num1}分钟内，涨幅达到{num2}%',

    'Decline': '分钟跌幅达到',
    'DeclineInfo': '{num1}分钟内，跌幅达到{num2}%',

    'increaseReach': '涨幅达到',
    'declineReach': '跌幅达到',

    'DailyTurnoverRate': '日换手率',

    'KDJGoldFork': 'KDJ金叉',
    'KDJDeadFork': 'KDJ死叉',
    'MACDGoldFork': 'MACD金叉',
    'MACDDeadFork': 'MACD死叉',

    'PricesGoThroughBollinger': '价格上穿布林线',
    'PricesGoThroughBollingerInfo': '{num1}价格上穿布林线{num2}',

    'PriceDownBollinger': '价格下穿布林线',
    'PriceDownBollingerInfo': '{num1}价格下穿布林线{num2}',

    'BuyOneHigherThan': '买一价高于',
    'SellPriceBelow': '卖一价低于',
    'ReachHigh': '高开达到',
    'GapReachHigh': '跳空高开达到',

    'VolumeAveragePastDays': '成交量为过去{num1}天平均值的{num2}倍',
    'VolumeRatio': '量比',
    'VolumeRatioThan': '量比大于',
    'DailyAmplitude': '日振幅',

    'AnyOnlineWear': '任意均线上穿',
    'AnyOnlineWearInfo': '{num1}{avg1}均线上穿{num2}{avg2}均线',
    'AnyMovingAverage': '任意均线下穿',
    'AnyMovingAverageInfo': '{num1}{avg1}均线下穿{num2}{avg2}均线',

    'intelligentHawkeye': '智能盯盘',
    'newHawkeye': '新建盯盘',
    'myHawkeye': '我的盯盘',

    'closeShort': '买入',
    'closeLong': '卖出',

    'closeShortHawkeye': '买入盯盘',
    'closeLongHawkeye': '卖出盯盘',

    'smartPay': '智能埋单',
    'goHawkeye': '去盯盘',
    'PaySingle': '埋一单',

    'intelligentHawkeyeConfirm': '智能盯盘确认',
    'today.hawkeye.end':'今日盯盘次数已用完',

    'settingSuccess': '设置成功',
    'stopHawkeyeMsgSendSuccess': '终止盯盘消息发送成功',

    'isConfirmPlaceOrder': '是否确认下单?',

    'confirm': '确认',
    'submitPlaceOrder': '确认下单',
    'cancelPlaceOrder': '取消下单',
    'sendSucc': '{cont}发送成功',
    'sendFail': '{cont}发送失败',
    'hawkeyeCondFail': '盯盘条件有错误：{cont}',

    'intelligentHawkeyeAlert': '智能盯盘预警',
    'HawkeyeAlertRemind': '您有 {cont} 个盯盘条件已触发，是否查看详情？',

    'close': '关闭',
    'view': '查看',
    'stop': '终止',
    'placeOrder': '下单',

    'pendingOrder': '待下单',
    'monitoring': '监控中',
    'over': '已结束',

    'hawkeyeMode':'盯盘模式',

    'ClosePendingOrder': '关闭待下单',
    'PendingOrderHawkeye': '待下单盯盘',

    'symbolName': '证券名称',
    'symbolCode': '证券代码',
    'hawkeyeType': '盯盘类型',
    'hawkeyeCondition': '盯盘条件',
    'tradeDirection': '交易方向',
    'tradeQuantity': '交易数量',
    'expireDate': '失效时间',
    'triggerDate': '触发时间',
    'triggerCondition': '触发条件',

    'hawkeyeStragegy': '盯盘策略',

    'vernacularHawkeye':'白话盯盘',
    'priceHawkeye': '价格盯盘',
    'condHawkeye': '条件盯盘',
    'kLineHawkeye': 'K线盯盘',
    'HybridHawkeye': '混合盯盘',

    'hawkeyeAction': '盯盘动作',
    'effectTime': '有效时间',

    'reach': '达到',
    'norm': '指标',
    'value': '值',
	
    'delete': '操作',
    'Add to conditions': '使用公式',
    'hCondition': '盯盘条件',

    'numMinLine': '{num}分钟线',
    '1MinLine': '1分钟线',
    '2MinLine': '2分钟线',
    '3MinLine': '3分钟线',
    '5MinLine': '5分钟线',
    '10MinLine': '10分钟线',
    '15MinLine': '15分钟线',
    '30MinLine': '30分钟线',
    '60MinLine': '60分钟线',
    'dailyLine': '日线',

    '5DayHigh': '5日新高',
    '10DayHigh': '10日新高',
    '20DayHigh': '20日新高',
    '30DayHigh': '30日新高',
    '60DayHigh': '60日新高',
    '120DayHigh': '120日新高',
    '200DayHigh': '200日新高',
    'historyHigh': '历史新高',

    '5DayLow': '5日新低',
    '10DayLow': '10日新低',
    '20DayLow': '20日新低',
    '30DayLow': '30日新低',
    '60DayLow': '60日新低',
    '120DayLow': '120日新低',
    '200DayLow': '200日新低',
    'historyLow': '历史新低',

    '5DayAvgLine': '5日均线',
    '10DayAvgLine': '10日均线',
    '20DayAvgLine': '20日均线',
    '60DayAvgLine': '60日均线',
    '120DayAvgLine': '120日均线',
    '200DayAvgLine': '200日均线',

    '1MinInside': '1分钟内',
    '2MinInside': '2分钟内',
    '3MinInside': '3分钟内',
    '5MinInside': '5分钟内',
    '10MinInside': '10分钟内',
    '15MinInside': '15分钟内',
    '30MinInside': '30分钟内',
    '60MinInside': '60分钟内',

    'bollingerOnRail': '布林线上轨',
    'bollingerMidRail': '布林线中轨',
    'bollingerLowRail': '布林线下轨',

    'OnRail': '上轨',
    'MidRail': '中轨',
    'LowRail': '下轨',

    'priceUp': '价格上穿',
    'priceDown': '价格下穿',

    'ordered': '已下单',
    'triggered': '已触发',
    'closed': '已关闭',
    'activeTermination': '主动终止',
    'hawkeyeError': '盯盘错误',
    'invalid': '已失效',

    'PleaseEnterQuoteChange': '请输入涨跌幅',
    'priceNotEmpty': '请输入价格',
    'priceDiffNotEmpty': '请输入价差',

    'PleaseEnterTargetPrice': '请输入目标价格',
    'PleaseEnterIncrease': '请输入涨幅',
    'PleaseEnterDecline': '请输入跌幅',
    'PleaseEnterTargetExtent': '请输入目标幅度',
    'PleaseEnterDays': '请输入天数',
    'PleaseEnterMultiple': '请输入倍数',
    'PleaseEnterVolumeRatio': '请输入量比值',
    'PleaseEnterTargetAmplitude': '请输入目标振幅',
    'PleaseEnterInteger': '请输入整数',

    'pleaseEnterRange0-100': '请输入0-100幅度',
    'pleaseEnterTargetTurnoverRate': '请输入目标换手率',

    'selCondTrigger':'已选条件(任意满足其中一个条件，即触发并结束盯盘)',
    'hawkeye.info': '您已开启 {priceCount} 个价格盯盘，{condCount} 个条件盯盘，{mixCount} 个混合盯盘。',
    'hawkeye.mode': '根据以上策略，使用实时行情智能盯盘，触发条件后',

    'confirmOrderManually': '手动确认下单',
    'systemAutoOrders': '系统自动下单',

    'startDateGreaterThanEndDate': '开始日期不能大于结束日期',
    'tradeQuantityFormatFail': '交易数量格式错误',
    'tradeQuantityLengthFail': '交易数量长度不能超过12位',
    'tradeQuantityNotEmpty': '请输入交易数量',

    'pleaseEnterStockName-Symbol': '请输入股票代码',
    'pleaseEnterStartDate': '请输入开始日期',
    'pleaseEnterEndDate': '请输入结束日期',
    'customExpirationTime': '自定义有效期时间',
    'VIP.use-value.added.services': '(VIP使用，增值服务)',

    'hawkeyeUpperLimit': '最多同时可选5个盯盘条件',

    'deleteHawkeyeFailed': '删除盯盘失败',
    'confirmPlaceOrder': '确定对“{name}”下单？',
    'confirmPlaceOrders': '确定要对“{name}”等 {num} 个待下单盯盘下单？',

    'confirmCloseHawkeye': '确定要关闭待下单盯盘“{name}”？',
    'confirmCloseHawkeyes': '确定要关闭“{name}”等 {num} 个待下单盯盘？',

    'confirmStopHawkeye': '确定要终止监控中盯盘“{name}”？',
    'confirmStopHawkeyes': '确定要终止“{name}”等 {num} 个监控中盯盘？',

    'confirmRemoveHawkeye': '确定要删除已结束盯盘“{name}”？',
    'confirmRemoveHawkeyes': '确定要删除“{name}”等 {num} 个已结束盯盘？',

    'selProduct': '请选择产品',

    'videoTitle01': '选股过滤器',
    'videoCont0101': '1.登录并新建选股过滤器-1',
    'videoCont0102': '2.登录并新建选股过滤器-2',
    'videoCont0103': '3.登录并新建选股过滤器-3',
    'videoCont0104': '4.新增选股条件',
    'videoCont0105': '5.使用自定义代码集选股',

    'videoTitle02': '构建完整策略',
    'videoCont0201': '1.如何构建一个完整策略',
    'videoCont0202': '2.策略编辑如何使用',
    'videoCont0203': '3.创建一个策略并回测验证',

    'videoTitle03': 'DIY策略专区',
    'videoCont0301': '1.基本面选股',
    'videoCont0302': '2.布林线指标',
    'videoCont0303': '3.阶段高低Tick引用',
    'videoCont0304': '4.实现期货策略',
    'videoCont0305': '5.建立进出仓过滤器',
    'videoCont0306': '6.如何在公式过滤器中预设变量',

    'videoTitle04': '交易功能',
    'videoCont0401': '1.CrystalTrade功能介绍',
    'videoCont0402': '2.手动交易股票',

    'protocol': '会员服务协议',

    'commissionInformation': '请确认以下委托信息：',
    'orderConfirmation': '下单确认',
    'commissionMode': '委托方式',
    'marketPrice': '市价',

    'applicationSubmitted': '您的申请已提交',

    'orderInfo': '订单信息',

    'margin': '保证金',

    'hawkeyeConditionAlreadyExists': '盯盘条件已存在',

    'intraday': '日内',
    'Sector': 'Sector',
}

export default zh_CN;