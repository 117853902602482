import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Modal, Button, Menu, PageHeader, Table, Input, Icon, AutoComplete, Form, Divider, Popover, Spin, message } from 'antd';

import emitter from '../Evt.js';

const { Content, Sider } = Layout;
const { Option } = AutoComplete;
const Confirm = Modal.confirm;

var languageMessages = window.appLocale.messages;

class CustomStockPage extends Component {
    constructor(props) {
        super(props);

        this.customStockGroupData = [];

        this.user = {};
        this.state = {
            selectedRowKeys: [],
            
            dataSource: [],

            keywords: '',
            symbolItems: [],
            customStockGroup: [],

            currentSelectKey: '',
            groupName: '',
            symbolList: '',

            validateStatus: '',
            validateMessage: '',

            modalVisible: false,
            modalTitle: '',

            stockName: '',
            action: '',

            spinLoadding: true,
        };
    }

    componentDidMount() {
        emitter.addListener('initPage', this.init);
    }

    componentWillMount() {
        this.init();
    }

    componentWillUnmount() {
        emitter.removeListener('initPage', this.init);
    }

    init = () => {
        this.user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        /**
        if (user.username === undefined) {
            this.setState({ spinLoadding: false }, () => {
                this.props.dispatch({ type: "action_non_user" });
            });
        } else {
            
        }
        */
        var customStockGroups = JSON.parse(sessionStorage.getItem("CustomStockGroupsCache") || '[]') || [];
        if (customStockGroups.length > 0) {
            this.props.dispatch({ type: "action_get_custom_stock_tick", payload: { UserId: this.user.username, SymbolList: customStockGroups[0].symbolList } });
        } else {
            this.setState({ spinLoadding: false });
        }
    }

    setCustomGroup(customStock, symbols, currentSelectKey) {
        var { groupName, symbolList, dataSource } = this.state
        
        this.customStockGroupData = [];
        for (let key in customStock) {
            if (currentSelectKey === '') currentSelectKey = customStock[key].groupId.toString();
            
            if (currentSelectKey === customStock[key].groupId.toString()) {
                groupName = customStock[key].groupName;
                symbolList = customStock[key].symbolList;
            }

            var rename = 'rename_' + customStock[key].groupId;
            var copy = 'copy_' + customStock[key].groupId;
            var remove = 'remove_' + customStock[key].groupId;
            var content = (<Menu style={{ textAlign: 'center' }} selectedKeys={[]} inlineIndent={16} mode="inline" onClick={this.handlePopoverMenuClick}>
                <Menu.Item key={rename}>
                    <span>{languageMessages['rename']}</span>
                </Menu.Item>
                <Menu.Item key={copy}>
                    <span>{languageMessages['copy']}</span>
                </Menu.Item>
                <Menu.Item key={remove}>
                    <span>{languageMessages['remove']}</span>
                </Menu.Item>
            </Menu>);
            this.customStockGroupData.push(<Menu.Item key={customStock[key].groupId}>
                <span><Icon type="file" />{customStock[key].groupName}</span>
                <span style={{ float: 'right' }}>
                    <Popover placement="bottomLeft" title='' content={content} trigger="click">
                        <Button style={{ padding: '0 0 0 8px', backgroundColor: 'transparent', border: 'none' }} size="small" shape="round" icon="ellipsis" />
                    </Popover>
                </span></Menu.Item>);
        }

        dataSource = [];
        if (symbols.length > 0) {
            var index = symbols.length;
            for (var kk in symbols) {
                dataSource.unshift({
                    key: symbols[kk].symbol+','+index,
                    sequence: index,
                    stockCode: symbols[kk].symbol,
                    stockName: symbols[kk].symbolName,
                    price: parseFloat(symbols[kk].close).toFixed(2),
                    quoteChange: parseFloat(symbols[kk].chg).toFixed(2)
                });
                index--;
            }
        }
        
        this.setState({
            customStockGroup: customStock,
            currentSelectKey: currentSelectKey,
            groupName: groupName,
            symbolList: symbolList,
            symbolItems: [],
            dataSource: dataSource,
            selectedRowKeys: [],
            spinLoadding: false,
        });
    }

    componentWillReceiveProps(props) {
        var { status, state, fail, customGroup, symbolList, symbolItems } = props;
        var { currentSelectKey } = this.state;
        
        var customStockGroups = JSON.parse(sessionStorage.getItem("CustomStockGroupsCache") || '[]') || [];
        switch (status) {
            case 'customStockTick_success':
                if (customStockGroups.length > 0) {
                    this.setCustomGroup(customStockGroups, symbolList, currentSelectKey);
                } else {
                    this.setState({ spinLoadding: false });
                }
                break;
            case 'customStockTick_fail':
                var error = languageMessages['get.data.failed'];
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = languageMessages['user.exception'];
                        break;
                    default:
                        break;
                }
                message.error(error);
                this.setState({ spinLoadding: false });
                break;
            case 'add_customStock_success':
                customStockGroups.push(customGroup);
                sessionStorage.setItem("CustomStockGroupsCache", JSON.stringify(customStockGroups));

                currentSelectKey = customGroup.groupId.toString();
                this.setCustomGroup(customStockGroups, symbolList, currentSelectKey);

                this.setState({ modalVisible: false });
                break;
            case 'add_customStock_fail':
                var error = languageMessages['add.stock.option.failed'];
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = languageMessages['user.exception'];
                        break;
                    case 3:
                        if (fail === 'Custom symbol group quota reached.') {
                            error = languageMessages['reach.the.stock.quota'];
                        }
                        break;
                    case 4:
                        break;
                    default:
                        break;
                }
                message.error(error);
                break;
            case 'update_customStock_success':
                const index = customStockGroups.findIndex(item => item.groupId === customGroup.groupId);
                if (index > -1) {
                    customStockGroups[index].groupName = customGroup.groupName;
                    customStockGroups[index].symbolList = customGroup.symbolList;
                }
                sessionStorage.setItem("CustomStockGroupsCache", JSON.stringify(customStockGroups));

                currentSelectKey = customGroup.groupId.toString();
                this.setCustomGroup(customStockGroups, symbolList, currentSelectKey);

                this.setState({ modalVisible: false });
                break;
            case 'update_customStock_fail':
                var error = languageMessages['add.or.update.stock.option.failed'];
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = languageMessages['user.exception'];
                        break;
                    case 3:
                        break;
                    default:
                        break;
                }
                message.error(error);
                break;
            case 'remove_customStock_success':
                if (currentSelectKey !== '') {
                    customStockGroups = customStockGroups.filter(function (e) { return e.groupId != currentSelectKey });
                    sessionStorage.setItem("CustomStockGroupsCache", JSON.stringify(customStockGroups));
                    
                    this.setCustomGroup(customStockGroups, symbolList, '');

                    message.success(languageMessages['remove.stock.option.success']);
                }
                break;
            case 'remove_customStock_fail':
                var error = languageMessages['remove.stock.option.failed'];
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = languageMessages['user.exception'];
                        break;
                    case 3:
                        break;
                    default:
                        break;
                }
                message.error(error);
                this.setState({ spinLoadding: false });
                break;
            case 'get_symbol_items_success':
                if (symbolItems !== undefined && symbolItems.length > 0) {
                    this.setState({ symbolItems });
                } else {
                    if (this.state.keywords !== '') {
                        this.setState({ symbolItems: [{ symbol: '"' + this.state.keywords + '"' + languageMessages['no.matches.found'], symbolName: '' }] });
                    } else {
                        this.setState({
                            symbolItems: []
                        });
                    }
                }
                break;
            case 'get_symbol_items_fail':
                this.setState({
                    symbolItems: [],
                });
                break;
            default:
                break;
        }
    }

    handleMenuClick = e => {
        var { customStockGroup, dataSource } = this.state;
        var tmpSelectKey = e.key;
        
        var tmpData = customStockGroup.filter(function (e) { return e.groupId == tmpSelectKey; });
        if (tmpData.length > 0) {
            var data = tmpData[0];

            dataSource = [];
            if (data.symbolList !== '') {
                this.setState({
                    currentSelectKey: tmpSelectKey,
                    groupName: data.groupName,
                }, () => {
                    if (this.user.username === undefined) {
                        this.props.dispatch({ type: "action_non_user" });
                    } else {
                        this.props.dispatch({ type: "action_get_custom_stock_tick", payload: { UserId: this.user.username, SymbolList: data.symbolList } });
                    }
                });
            } else {
                this.setState({
                    currentSelectKey: tmpSelectKey,
                    groupName: data.groupName,
                    symbolList: '',
                    dataSource: dataSource
                });
            }
        }
    };

    handlePopoverMenuClick = e => {
        if (e.key !== '') {
            var groupIds = e.key.split('_');
            if (groupIds.length > 1) {
                switch (groupIds[0]) {
                    case 'rename':
                        this.renameStockGroup();
                        break;
                    case 'copy':
                        this.copyStockGroup();
                        break;
                    case 'remove':
                        this.removeStockGroup();
                        break;
                }
            }
        }
    }

    renameStockGroup() {
        let { groupName } = this.state;

        this.setState({
            modalVisible: true,
            modalTitle: languageMessages['rename'],
            stockName: groupName + languageMessages['_copy'],
            validateStatus: '',
            validateMessage: '',
            action: 'rename',
        });
    }

    copyStockGroup() {
        let { groupName } = this.state;

        this.setState({
            modalVisible: true,
            modalTitle: languageMessages['copy.stock.pool'],
            stockName: groupName + languageMessages['_copy'],
            validateStatus: '',
            validateMessage: '',
            action: 'copy',
        });
    }

    removeStockGroup() {
        var { currentSelectKey, groupName, symbolList } = this.state;
        
        if (this.user.username === undefined) {
            this.props.dispatch({ type: "action_non_user" });
        } else {
            var _this = this;
            Confirm({
                title: <div>{languageMessages['make.sure.delete.stock.pool']}“{groupName}”?</div>,
                content: '',
                okText: languageMessages['yes'],
                okType: 'danger',
                cancelText: languageMessages['no'],
                onOk() {
                    _this.props.dispatch({ type: "action_remove_custom_stock", payload: { GroupId: currentSelectKey, UserId: _this.user.username, GroupName: groupName, SymbolList: symbolList } });
                },
                onCancel() {},
            });
        }
    }

    onCreateCustomStockGroup = e => {
        this.setState({
            modalVisible: true,
            modalTitle: languageMessages['new.stock.pool'],
            stockName: '',
            validateStatus: '',
            validateMessage: '',
            action: 'new',
        });
    };

    handleSaveGroupName = e => {
        var { currentSelectKey, stockName, symbolList, action } = this.state

        var customStockGroups = JSON.parse(sessionStorage.getItem("CustomStockGroupsCache") || '[]') || [];
        var tmpData = customStockGroups.filter(function (e) { return e.groupName == stockName; });
        if (tmpData.length > 0) {
            this.setState({
                validateStatus: 'error',
                validateMessage: languageMessages['stock.pool.name.already.exists'],
            });
        } else {
            if (this.user.username === undefined) {
                this.props.dispatch({ type: "action_non_user" });
            } else {
                switch (action) {
                    case 'new':
                        this.props.dispatch({ type: "action_add_custom_stock", payload: { UserId: this.user.username, GroupName: stockName, SymbolList: '' } });
                        break;
                    case 'rename':
                        if (currentSelectKey !== '') {
                            this.props.dispatch({ type: "action_rename_custom_stock", payload: { GroupId: currentSelectKey, UserId: this.user.username, GroupName: stockName, SymbolList: symbolList } });
                        }
                        break;
                    case 'copy':
                        if (currentSelectKey !== '') {
                            this.props.dispatch({ type: "action_add_custom_stock", payload: { UserId: this.user.username, GroupName: stockName, SymbolList: symbolList } });
                        }
                        break;
                }
            }
        }
    };

    handleCancelGroupName = e => {
        this.setState({
            modalVisible: false,
            modalTitle: '',
            stockName: '',
            validateStatus: '',
            validateMessage: '',
            action: '',
        });
    };

    onChangeGroupName = e => {
        e.preventDefault();

        var validateStatus = '';
        var validateMessage = '';
        var stockName = e.target.value;
        if (stockName !== '') {
            stockName = stockName.slice(0, 20);
            
            var pattern = /^[A-Za-z0-9_\u4e00-\u9fa5]+$/gi;
            if (!pattern.test(stockName)) {
                validateStatus = 'error';
                validateMessage = languageMessages['stock.pool.name.format'];
            }
        }
        this.setState({
            validateStatus: validateStatus,
            validateMessage: validateMessage,
            stockName: stockName
        });
    }

    onRemove = () => {
        var { currentSelectKey, groupName, symbolList, selectedRowKeys } = this.state;
        
        if (selectedRowKeys.length > 0) {
            var tmpSelectRowKeys = [];
            for (var k in selectedRowKeys) {
                var tmpValue = selectedRowKeys[k].split(',');
                if (tmpValue.length > 0) {
                    tmpSelectRowKeys.push(tmpValue[0]);
                } else {
                    tmpSelectRowKeys.push(selectedRowKeys[k]);
                }
            }
            
            var tmpSymbolList = symbolList.split(',');
            tmpSymbolList = tmpSymbolList.filter(function (i) { return tmpSelectRowKeys.indexOf(i) === -1; });
            symbolList = tmpSymbolList.join(',');
            if (this.user.username === undefined) {
                this.props.dispatch({ type: "action_non_user" });
            } else {
                var _this = this;
                Confirm({
                    title: languageMessages['want.delete.stock'],
                    content: '',
                    okText: languageMessages['yes'],
                    okType: 'danger',
                    cancelText: languageMessages['no'],
                    onOk() {
                        _this.setState({ spinLoadding: true }, () => {
                            _this.props.dispatch({ type: "action_update_custom_stock", payload: { GroupId: currentSelectKey, UserId: _this.user.username, GroupName: groupName, SymbolList: symbolList } });
                        });
                    },
                    onCancel() { },
                });
            }
        }
    };

    onSelectSymbol = value => {
        if (this.user.username === undefined) {
            this.props.dispatch({ type: "action_non_user" });
        } else {
            var { currentSelectKey, groupName, symbolList, symbolItems } = this.state;

            if (currentSelectKey === '') {
                message.warning(languageMessages['please.select.or.new.stock.pool']);
                return;
            }

            var tmpDataCode = symbolItems.filter(function (e) { return e.symbol.indexOf(value) !== -1; });
            if (tmpDataCode.length > 0) {
                var selectSymbol = tmpDataCode[0];

                if (symbolList.indexOf(selectSymbol.symbol) === -1) {
                    if (symbolList !== '') symbolList += ',';
                    symbolList += selectSymbol.symbol;

                    this.props.dispatch({ type: "action_update_custom_stock", payload: { GroupId: currentSelectKey, UserId: this.user.username, GroupName: groupName, SymbolList: symbolList } });
                }
            }
        }
    }

    handleSearch = value => {
        /**
        var symbolItems = JSON.parse(sessionStorage.getItem("SymbolItemsCache") || '[]') || [];
        var tmpDataCode = symbolItems.filter(function (e) { return e.stockCode.toLowerCase().indexOf(value.toLowerCase()) !== -1; });
        if (tmpDataCode.length < 20) {
            var tmpDataName = symbolItems.filter(function (e) { return e.stockName.toLowerCase().indexOf(value.toLowerCase()) !== -1; });
            if (tmpDataCode.length > 0) tmpDataCode = tmpDataCode.concat(tmpDataName);
            else tmpDataCode = tmpDataName.concat(tmpDataCode);
        }

        if (tmpDataCode.length > 0) {
            tmpDataCode = tmpDataCode.slice(0, 20);
        } else {
            tmpDataCode = [{ stockCode: '"' + value + '"' + languageMessages['no.matches.found'], stockName: '' }];
        }
        console.log('value', value);
        */
        this.setState({ keywords: value }, () => {
            /**
            if (this.user.username === undefined) {
                this.props.dispatch({ type: "action_non_user" });
            } else {
                this.props.dispatch({ type: "action_get_symbol_items", payload: { UserId: this.user.username, keywords: value } });
            }
            */
            this.props.dispatch({ type: "action_get_symbol_items", payload: { UserId: this.user.username, keywords: value } });
        })
    };

    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.setState({ selectedRowKeys });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    renderCustomStockItem(currentSelectKey) {
        if (this.customStockGroupData.length > 0) {
            return <Menu inlineIndent={50} selectedKeys={[currentSelectKey || '0']} mode="inline" onClick={this.handleMenuClick}>
                {this.customStockGroupData}
            </Menu>;
        } else {
            return <div style={{ textAlign: 'center', padding: '20px 0', color: 'rgba(255, 255, 255, 0.75' }}>{languageMessages['no.stock.pool']}</div>
        }
    }

    render() {
        var { dataSource, symbolItems, selectedRowKeys, currentSelectKey } = this.state;

        const rowSelection = {
            title: languageMessages['select.all'],
            selectedRowKeys: selectedRowKeys,
            onChange: this.onSelectChange
        }

        var formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };
        
        const columns = [
            {
                title: languageMessages['sequence'],
                dataIndex: 'sequence',
                width: 50
            }, {
                title: languageMessages['symbol.code'],
                dataIndex: 'stockCode',
                sorter: (a, b) => a.stockCode < b.stockCode ? -1 : a.stockCode > b.stockCode ? 1 : 0,
                sortDirections: ['descend', 'ascend'],
            }, {
                title: languageMessages['symbol.name'],
                dataIndex: 'stockName',
                sorter: (a, b) => a.stockName < b.stockName ? -1 : a.stockName > b.stockName ? 1 : 0,
                sortDirections: ['descend', 'ascend'],
            }, {
                title: languageMessages['latest.price'],
                dataIndex: 'price',
                sorter: (a, b) => a.price - b.price,
                align: 'right',
                sortDirections: ['descend', 'ascend'],
                render: (text, row) => {
                    return <span>{text}</span>;
                },
            }, {
                title: languageMessages['quote.change'],
                dataIndex: 'quoteChange',
                sorter: (a, b) => a.quoteChange - b.quoteChange,
                align: 'right',
                sortDirections: ['descend', 'ascend'],
                render: (text) => {
                    if (text >= 0) {
                        return <span style={{ color: window.webType === 'US' ? 'green' : 'red' }}>{text}%</span>;
                    } else {
                        return <span style={{ color: window.webType === 'US' ? 'red' : 'green' }}>{text}%</span>;
                    }
                },
            }
        ];

        return (<Content className='main-container'>
            <Spin spinning={this.state.spinLoadding}>
            <div className='common-page-header'>
                <PageHeader title={languageMessages['intl.menu.customStock']}
                    extra={[
                        <Button key="1" size='small' onClick={this.onCreateCustomStockGroup}>{languageMessages['new.stock.pool']}</Button>
                        ]} />
                <Modal
                    title={this.state.modalTitle}
                    visible={this.state.modalVisible}
                    maskClosable={false}
                    onOk={this.handleSaveGroupName}
                    confirmLoading={this.props.status === 'customStock_commit'}
                    onCancel={this.handleCancelGroupName}>
                    <Form {...formItemLayout}>
                        <Form.Item label={languageMessages['stock.pool.name.form']}
                            hasFeedback
                            validateStatus={this.state.validateStatus}
                            help={this.state.validateMessage}>
                            <Input placeholder={languageMessages['stock.pool.name.placeholder']} id={this.state.validateStatus} value={this.state.stockName} onChange={this.onChangeGroupName} />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
            <Layout className='main-content'>
                <Sider width={300}>
                    <PageHeader className='common-sider-header' title={languageMessages['optional.stock.pool']} />
                    <Divider />
                    <div className="common-sider-content">
                        {this.renderCustomStockItem(currentSelectKey)}
                    </div>
                </Sider>
                <Layout className='common-content'>
                    <div className='common-tools'>
                        <Button onClick={this.onRemove} disabled={selectedRowKeys.length > 0 ? false : true}>{languageMessages['remove']}</Button>
                        <AutoComplete
                            className="certain-category-search"
                            dropdownClassName="certain-category-search-dropdown"
                            dropdownMatchSelectWidth={false}
                            dropdownStyle={{ width: 200 }}
                            size="small"
                            style={{ width: '200px' }}
                            dataSource={symbolItems.map(group => (
                                <Option key={group.symbol} value={group.symbol}>
                                    {group.symbol}
                                    <span className="certain-search-item-count">{group.symbolName}</span>
                                </Option>
                            ))}
                            onSearch={this.handleSearch}
                            onSelect={this.onSelectSymbol}
                            placeholder={languageMessages['add.stock.option']}
                            optionLabelProp="value">
                            <Input suffix={<Icon type="search" className="certain-category-icon" />} />
                        </AutoComplete>
                    </div>
                    <Table rowSelection={rowSelection}
                        bordered size='small'
                        columns={columns}
                        dataSource={dataSource}
                        onRow={(record) => ({
                            onClick: () => {
                                this.selectRow(record);
                            },
                        })} />
                </Layout>
            </Layout>
        </Spin>
    </Content>)
    }
}

function mapStateToProps(state) {
    return state.customStock;
}

const page = connect(mapStateToProps)(CustomStockPage);
export { page as default }