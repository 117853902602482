import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

function callback(key) {
    console.log(key);
};

function CardSection() {
    return (<div className="card-container common-coupon">
        <Tabs defaultActiveKey="1" onChange={callback} type="card">
        <TabPane tab="Card" key="1">
            <fieldset>
                <label>
                    <span>Card</span>
                    <div className="field">
                        <CardElement options={CARD_ELEMENT_OPTIONS} />
                    </div>
                </label>
            </fieldset>
        </TabPane>
        </Tabs>
    </div>);
};
        
export default CardSection;