import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Card, Alert, Row, Col, Icon } from 'antd';
import WrappedLoginForm from '../../pannels/form/LoginForm';
import history from '../../helpers/history';
import emitter from '../Evt.js';

import { FormattedMessage, defineMessages } from 'react-intl';

const { Content } = Layout;

const messages = defineMessages({
    login: {
        id: 'intl.login',
        defaultMessage: '登录',
    },
    freeRegister: {
        id: 'intl.register.free',
        defaultMessage: '注册',
    },
    getVerificationCodeFailed: {
        id: 'get.verification.code.failed',
        defaultMessage: '获取验证码失败',
    },
    phoneNotRegister: {
        id: 'phone.not.register',
        defaultMessage: '手机未注册',
    },
    phoneFormatFailed: {
        id: 'phone.format.failed',
        defaultMessage: '手机号格式错误',
    },
    loginException: {
        id: 'login.exception',
        defaultMessage: '登录异常',
    },
    accountNotRegister: {
        id: 'account.not.register',
        defaultMessage: '账号未注册',
    },
    accountException: {
        id: 'account.exception',
        defaultMessage: '账号异常',
    },
    passwordVerificationCodeFailed: {
        id: 'password.or.verification.code.match.error',
        defaultMessage:'密码或验证码匹配错误',
    }
});

let timer = null;
class LoginPage extends Component {
    constructor(props) {
        super(props);
        let auth = JSON.parse(localStorage.getItem("auth") || '{}') || {};

        sessionStorage.clear();

        this.state = {
            isGetCaptcha: true,
            count: 60,
            code: '',
            message: '',
            logining: false,
            ...auth
        }
    }

    componentWillReceiveProps(props) {
        let { status, state } = props;
        let { count } = this.state;

        this.setState({
            code: '',
            message: '',
            logining: false
        });
        
        switch (status) {
            case 'Get_Captcha':
                switch (state) {
                    case 0://失败
                        this.setState({
                            code: 'ERROR',
                            message: <FormattedMessage {...messages.getVerificationCodeFailed} />
                        });
                        break;
                    case 1://成功
                        timer = setInterval(() => {
                            this.setState({
                                isGetCaptcha: false,
                                count: (count--)
                            }, () => {
                                if (count === 0) {
                                    clearInterval(timer);
                                    this.setState({
                                        isGetCaptcha: true,
                                        count: 60
                                    })
                                }
                            })
                        }, 1000);
                        break;
                    case 2://手机未注册
                        this.setState({
                            code: 'ERROR',
                            message: <FormattedMessage {...messages.phoneNotRegister} />
                        });
                        break;
                    case 3://手机有误
                        this.setState({
                            code: 'ERROR',
                            message: <FormattedMessage {...messages.phoneFormatFailed} />
                        });
                        break;
                }
                break;
            case 'Logining':
                this.setState({
                    logining: true,
                });
                break;
            case 'Login_Success':
                if (timer !== null) {
                    clearInterval(timer);
                }
                this.setState({
                    isGetCaptcha: true,
                    count: 60
                }, () => {
                    const n = window.location.search.indexOf("next=")
                    if (n > 0) {
                        history.replace(window.location.search.substr(n + 5))
                    } else {
                        emitter.emit('callJump', 'vernacular');
                        history.replace('/vernacular');
                    }
                });
                break;
            case 'Login_Failed':
                if (timer !== null) {
                    clearInterval(timer);
                }
                let errorMeg = '';
                switch (state) {
                    case 0:
                        errorMeg = <FormattedMessage {...messages.loginException} />
                        break;
                    case 2:
                        errorMeg = <FormattedMessage {...messages.accountNotRegister } />
                        break;
                    case 3:
                        errorMeg = <FormattedMessage {...messages.accountException} />
                        break;
                    case 4:
                        errorMeg = <FormattedMessage {...messages.passwordVerificationCodeFailed} />
                        break;
                    case 5:
                        errorMeg = <FormattedMessage id='user.account.has.expired' />
                        break;
                    default:
                        break;
                }
                this.setState({
                    code: 'ERROR',
                    message: errorMeg,
                    isGetCaptcha: true,
                    count: 60
                });
                break;
            default:
                break;
        }
    }

    handleLoginEvent() {
    }

    toRegister = e => {
        e.preventDefault();
        history.push("/register");
    }

    handleChange(data) {
        this.setState({ code: data.error });
    }

    render() {
        return (<Content className='main-container total-content'>
            <div className="row-container" style={{ justifyContent: "center", padding: "36px 0", marginBottom: '30px' }}>
                <Card className='common-box'
                    cover={<div className='form-logo'></div>}>
                    <div className='title'>
                        <Row>
                            <Col span={12} style={{ textAlign: 'right', padding: '10px' }}>
                                <FormattedMessage {...messages.login} />
                            </Col>
                            <Col span={12} style={{ textAlign: 'left', padding: '10px' }}>
                                <a onClick={this.toRegister}><FormattedMessage {...messages.freeRegister} /></a>
                            </Col>
                        </Row>
                    </div>
                    <WrappedLoginForm {...this.state} dispatch={this.props.dispatch} loginEvent={this.handleLoginEvent.bind(this)} handleChange={this.handleChange.bind(this)} />
                    {this.state.code === 'ERROR' ? (<Alert message={this.state.message} type="error" />) : ''}
                    {window.webType === 'test'?
                        (<div style={{ fontSize: '24px', color: '#337ab7' }}>
                            <Icon type="facebook" theme="filled" style={{ marginRight: '5px' }} />
                            <Icon type="linkedin" theme="filled" style={{ marginRight: '5px' }} />
                        </div>):''
                    }
                </Card>
            </div>
        </Content>
        )
    }
}

function mapStateToProps(state) {
    return state.member ? state.member : {};
}

const page = connect(mapStateToProps)(LoginPage);
export { page as default }