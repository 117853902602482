export default function concept(state = { }, action) {
    switch (action.type) {
        case "get_concept_success":
            return { ...state, status: "concept_success", sectors: action.sectors, stockCount: action.stockCount, concepts: action.concepts, conceptExplanations: action.conceptExplanations, permission: action.permission };
        case "get_concept_fail":
            return { ...state, status: "concept_fail", state: action.status };

        case "get_luis_success":
            return { ...state, status: "luis_success", sectors: action.sectors, luString: action.luString, stockCount: action.stockCount, permission: action.permission };
        case "get_luis_fail":
            return { ...state, status: "luis_fail", state: action.status };

        case "get_change_batch_success":
            return { ...state, status: "get_change_batch_success", luString: action.luString };
        case "get_change_batch_fail":
            return { ...state, status: "get_change_batch_fail", state: action.status }

        case "get_change_condition_success":
            return { ...state, status: "get_change_condition_success", luString: action.luString, sentenceFormula: action.sentenceFormula };
        case "get_change_condition_fail":
            return { ...state, status: "get_change_condition_fail", state: action.status }

        case "scan_strategy_commit":
            return { ...state, status: "scan_commit" };
        case "get_scan_success":
            return { ...state, status: "scan_success", scanAlert: action.scanAlert, filters: action.filter, permission: action.permission, lastTimes: action.lastTimes };
        case "get_scan_fail":
            return { ...state, status: "scan_fail", state: action.status, fail: action.error };

        case "save_strategy_commit":
            return { ...state, status: "save_commit" };
        case "get_save_success":
            return { ...state, status: "save_success", editStrategy: action.editStrategy };
        case "get_save_fail":
            return { ...state, status: "save_fail", state: action.status, fail: action.error };

        case "luis_analysis_commit":
            return { ...state, status: "analysis_commit" };
        case "get_luis_analysis_success":
            return { ...state, status: "analysis_success", formulaString: action.formulaString };
        case "get_luis_analysis_fail":
            return { ...state, status: "analysis_fail", state: action.status };

        case "customStock_commit":
            return { ...state, status: "customStock_commit" };
        case "update_customStock_success":
            return { ...state, status: "update_customStock_success", customGroup: action.customGroup, symbolList: action.symbolList };
        case "update_customStock_fail":
            return { ...state, status: "update_customStock_fail", state: action.status };

        case "get_stock_count_success":
            return { ...state, status: "get_stock_count_success", stockPoolKey: action.stockPoolKey, stockGroups: action.stockGroups, stockCount: action.stockCount };
        case "get_stock_count_fail":
            return { ...state, status: "get_stock_count_fail", state: action.status };

        case "clear_filter_string":
            return { ...state, status: "clear_filter_string" };
            
        case "use_formula":
            return { ...state, status: "use_formula", useFormulaData: action.useFormulaData };

        default:
            return state;
    }
}