import { put, call, takeLatest } from 'redux-saga/effects'
import { post, get } from '../../helpers/server'

function* getPaymentService(action) {
    try {
        const resp = yield call(post, "/charge/service", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'service_success', p: resp.p, Vp: resp.Vp, vas: resp.vas });
        } else {
            yield put({ type: 'service_failed' });
        }
    } catch (e) {
        yield put({ type: 'strioe_failed' });
    }
}

function* getStripeKey(action) {
    try {
        const resp = yield call(post, "/charge/stripe", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'strioe_success', Vp: resp.Vp, vas: resp.vas, c: resp.c, scs: resp.scs });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'strioe_failed' });
        }
    } catch (e) {
        yield put({ type: 'strioe_failed' });
    }
}

function* PayMethod(action) {
    try {
        yield put({ type: "pay_processing", stepStatus: 'finish', stepIndex: 1 });
        const resp = yield call(post, "/charge/payMethod", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'payMethod_success', clientSecret: resp.clientSecret, p: resp.p, c: resp.c, a: resp.a });
        } else {
            yield put({ type: 'payMethod_failed', p: resp.p, c: resp.c, a: resp.a });
        }
    } catch (e) {
        yield put({ type: 'payMethod_failed' });
    }
}

function* PayIntent(action) {
    try {
        const resp = yield call(post, "/charge/payIntent", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'payIntent_success', p: resp.p, c: resp.c, a: resp.a });
        } else {
            yield put({ type: 'payIntent_failed', a: resp.a });
        }
    } catch (e) {
        yield put({ type: 'payIntent_failed' });
    }
}

function* createSubscription(action) {
    try {
        yield put({ type: "pay_processing", stepStatus: 'finish', stepIndex: 2 });
        const resp = yield call(post, "/billing/createSubscription", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'create_subscription_success', sc: resp.sc, d: resp.d, c: resp.c, p: resp.p, m: action.payload.m });
        } else {
            yield put({ type: 'create_subscription_failed', c: resp.c });
        }
    } catch (e) {
        yield put({ type: 'create_subscription_failed' });
    }
}

function* retryInvoice(action) {
    try {
        yield put({ type: "pay_processing", stepStatus: 'finish', stepIndex: 2 });
        const resp = yield call(post, "/billing/retryInvoice", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'retry_invoice_success', i: resp.i });
        } else {
            yield put({ type: 'retry_invoice_failed' });
        }
    } catch (e) {
        yield put({ type: 'retry_invoice_failed' });
    }
}

function* getSubscription(action) {
    try {
        const resp = yield call(post, "/billing/getSubscription", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'subscription_success', sc: resp.sc, vas: resp.vas, Vp: resp.Vp, scs: resp.scs });
        } else {
            yield put({ type: 'subscription_failed' });
        }
    } catch (e) {
        yield put({ type: 'subscription_failed' });
    }
}

function* updateSubscription(action) {
    try {
        yield put({ type: "subscription_processing_commit" });
        const resp = yield call(post, "/billing/updateSubscription", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'update_subscription_success' });
        } else {
            yield put({ type: 'update_subscription_failed', c: resp.c });
        }
    } catch (e) {
        yield put({ type: 'update_subscription_failed' });
    }
}

function* emailCaptcha(action) {
    try {
        yield put({ type: "email_captcha_commit" });
        const resp = yield call(post, "/billing/emailCaptcha", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'email_captcha_success' });
        } else {
            yield put({ type: 'email_captcha_failed' });
        }
    } catch (e) {
        yield put({ type: 'email_captcha_failed' });
    }
}

function* checkEmail(action) {
    try {
        yield put({ type: "check_email_commit" });
        const resp = yield call(post, "/billing/checkEmail", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'check_email_success', c: resp.c });
        } else {
            yield put({ type: 'check_email_failed' });
        }
    } catch (e) {
        yield put({ type: 'check_email_failed' });
    }
}

function* payProcessing(action) {
    try {
        yield put({ type: "pay_processing", stepStatus: action.payload.stepStatus, stepIndex: action.payload.stepIndex });
    } catch (e) {
    }
}

function* nextProcessing(action) {
    try {
        yield put({ type: "next_processing", stepStatus: action.payload.stepStatus, stepIndex: action.payload.stepIndex });
    } catch (e) {
    }
}

function* alipay(action) {
    try {
        const resp = yield call(post, "/charge/alipay", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'alipay_success', clientSecret: resp.clientSecret });
        } else {
            yield put({ type: 'alipay_failed' });
        }
    } catch (e) {
        yield put({ type: 'alipay_failed' });
    }
}

function* charge() {
    yield takeLatest('action_get_payment_service', getPaymentService);
    
    yield takeLatest('action_get_stripe_key', getStripeKey);
    yield takeLatest('action_payment_method', PayMethod);
    yield takeLatest('action_payment_intent', PayIntent);

    yield takeLatest('action_create_subscription', createSubscription);
    yield takeLatest('action_retry_invoice', retryInvoice);

    yield takeLatest('action_subscription', getSubscription);
    yield takeLatest('action_update_subscription', updateSubscription);

    yield takeLatest('action_pay_processing', payProcessing);
    yield takeLatest('action_next_processing', nextProcessing);

    yield takeLatest('action_email_captcha', emailCaptcha);
    yield takeLatest('action_check_email', checkEmail);
    

    yield takeLatest('action_ali_payment', alipay);
    
}

export default charge;