export default function factor(state = {}, action) {
    switch (action.type) {
        case "get_factor_filters_success":
            return { ...state, status: "get_factor_filters_success", filter: action.filter, currDate: action.currDate, sectors: action.sectors, stockCount: action.stockCount, permission: action.permission };
        case "get_factor_filters_failed":
            return { ...state, status: "get_factor_filters_failed", state: action.status };

        case "scan_factorFilter_commit":
            return { ...state, status: "scan_factorFilter_commit" };
        case "scan_factorFilter_success":
            return { ...state, status: "scan_factorFilter_success", scanAlert: action.scanAlert, filter: action.filter, permission: action.permission, fLastTimes: action.lastTimes };
        case "scan_factorFilter_failed":
            return { ...state, status: "scan_factorFilter_failed", state: action.status, fail: action.error };

        case "factor_generate_commit":
            return { ...state, status: "commit" }
        case "factor_generate_ready":
            return { ...state, status: "ready", taskName: action.taskName };
        case "factor_generate_working":
            return { ...state, status: "working" }
        case "factor_generate_finished":
            return { ...state, status: "finished", permission: action.permission, lastTimes: action.lastTimes };
        case "factor_generate_error":
            return { ...state, status: "error", state: action.status }

        case "get_factor_files_success":
            return { ...state, status: "get_factor_files_success", factorFiles: action.factorFiles }
        case "get_factor_files_failed":
            return { ...state, status: "get_factor_files_failed", state: action.status }

        case "file_migrate_finished":
            return { ...state, status: "upload_file", factorFile: action.factorFile }
        case "file_migrate_error":
            return { ...state, status: "upload_file_error", state: action.status }
        case "file_remove_finished":
            return { ...state, status: "remove_file", fileId: action.fileName }
        case "file_remove_error":
            return { ...state, status: "remove_file_error", state: action.status }
            
        //因子分析
        case "factor_analyze_commit":
            return { ...state, status: "factor_analyze_commit" }
        case "factor_analyze_ready":
            return { ...state, status: "factor_analyze_ready", analyzeTaskId: action.taskId, analyzeState: action.analyzeState, analyzeStage: action.stage }
        case "factor_analyze_working":
            return { ...state, status: "factor_analyze_working", analyzeState: action.analyzeState, analyzeStage: action.stage }
        case "factor_analyze_finished":
            return { ...state, status: "factor_analyze_finished", analyzeState: action.analyzeState }
        case "factor_analyze_error":
            return { ...state, status: "factor_analyze_error", state: action.status, analyzeState: action.analyzeState }

        case "get_factor_list_success"://因子列表
            return { ...state, status: "factor_list_loaded", factorNames: action.factorNames }
        case "get_factor_list_failed":
            return { ...state, status: "factor_list_failed", state: action.status }

        case "get_factor_data_success"://收益
            const nextFactorData = { ...state.factorData, [action.chartName]: action.data }
            return { ...state, status: "data_loaded", factorData: nextFactorData, factorName: action.factorName, chartName: action.chartName }

        case "get_factor_indicate_data_success"://ic
            return { ...state, status: "data_loaded", factorData: action.data, factorName: action.factorName }

        case "get_factor_turnover_data_success"://换手率
            return { ...state, status: "data_loaded", factorData: action.data, factorName: action.factorName }

        case "get_stock_count_success":
            return { ...state, status: "get_stock_count_success", stockPoolKey: action.stockPoolKey, stockGroups: action.stockGroups, stockCount: action.stockCount };
        case "get_stock_count_fail":
            return { ...state, status: "get_stock_count_fail", state: action.status };

        case "empty_status":
            return { ...state, status: "empty" }

        default:
            return state;
    }
}