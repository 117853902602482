import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, PageHeader, Table, Row, Col, Button, Modal, message } from 'antd';
import history from '../../helpers/history';

const { Content } = Layout;

var languageMessages = window.appLocale.messages;

class ServicePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            initLoadding: true,
            dataSource: [],

            modalTitle: '',
            modalContent: '',
            
            total: '',
            isVip: false,

            visible: false,
            selectKey: '',
            selectCouponKey: '0',

            selectMon: '12',

            currency: 'usd',
        }
    }

    componentWillMount() {
        this.user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};

        this.props.dispatch({ type: "action_get_payment_service", payload: { u: this.user.username } });
    }

    componentWillReceiveProps(props) {
        let { status, state, permission, isVip, vServices } = props;

        switch (status) {
            case 'service_success':

                permission = permission === undefined ? [] : permission;
                isVip = isVip === undefined ? false : isVip;

                if (this.user.permission !== undefined) {
                    this.user.permission = permission;
                    sessionStorage.setItem("auth", JSON.stringify(this.user));
                }
                    
                this.SetServiceInfo(permission, isVip, vServices);
                
                break;
            case 'service_failed':
                break;
            case 'get_permission_success':
                
                break;
            case 'get_permission_failed':
                if (this.user.permission !== undefined) {
                    this.SetServiceInfo(this.user.permission, false);
                }
                var error = this.languageMessages['get.data.failed'];
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = this.languageMessages['user.exception'];
                        break;
                    default:
                        break;
                }
                message.error(error);
                break;
            case 'payment_success':
                this.user.permission = permission;
                sessionStorage.setItem("auth", JSON.stringify(this.user));

                this.SetServiceInfo(this.user.permission);
                break;
            case 'payment_failed':
                var error = languageMessages['payment.failed'];
                switch (state) {
                    case 0:

                        break;
                    case 2:
                        // 第三方支付失败
                        break;
                    case 3:
                        // 优惠券异常
                        break;
                    case 4: // 付款金额异常
                        this.user.permission = permission;
                        sessionStorage.setItem("auth", JSON.stringify(this.user));

                        this.SetServiceInfo(this.user.permission);
                        break;
                    case 10:
                        error = this.languageMessages['user.exception'];
                        break;
                    default:
                        break;
                }

                message.error(error);
                break;
        }
    }

    //设置列表内容
    SetServiceInfo(permission, isVip, vServices) {
        var dataSource = [];
        if (vServices.length > 0) {
            for (var kk in vServices) {
                var value = vServices[kk];

                let addOn = {};
                if (window.webType === 'US') {
                    //if (value.type === 5 || value.type === 6) continue;

                    if (value.type === 1) {
                        addOn = { key: 1000, name: [{ title: 'Add-on' }], intro: { items: [], info: [] }, date: '', status: [{ title: '' }], isCoupon: false };
                    }

                    if (value.type === 4) {
                        addOn = { key: 1001, name: [{ title: 'MachineTrader Service' }], intro: { items: ['Description'], info: [] }, date: 'Expiration', status: [{ title: 'Remaining Credit' }], isCoupon: false };
                    }
                }

                var discount = value.discount.split('|');
                var p = value.permission.split(';');

                let price = value.price > 0 ? this.formatPrice(value.price) : 0;
                let specialPrice = value.specialPrice > 0 ? this.formatPrice(value.specialPrice) : 0;

                price += languageMessages['yuan/month'];
                specialPrice += languageMessages['yuan/month'];

                var name = [];
                var intro = {};
                var date = '';
                var status = [];
                switch (value.type) {
                    case 0:
                        name = [{
                            title: languageMessages['free']
                        }];

                        var items = [languageMessages['payment.server.00.01'], languageMessages['payment.server.00.02'], languageMessages['payment.server.00.03']];

                        intro = { items: items, info: [] };
                        date = '---';

                        var ups = permission.filter(function (e) { return e.type === 's' });
                        if (ups.length > 0) {
                            var count = ups[0].totalCount - ups[0].usageCount;
                            status = [{ title: languageMessages['remaining.times'] + '：', cont: [languageMessages['real.stock.picker'] + '：' + count + languageMessages['times']] }];
                        }

                        break;
                    case 1:
                        var info = this.parseDiscount(discount);
                        name = [{
                            title: 'Basic',
                            price: price,
                            specialPrice: info,
                            button: languageMessages['buy'],
                        }];
                        
                        var items = [languageMessages['payment.server.01.01']];
                        intro = { items: items };

                        var ups = permission.filter(function (e) { return e.type === 'vs' });
                        if (ups.length > 0) {
                            var count = ups[0].totalCount - ups[0].usageCount;
                            status.push({ title: languageMessages['remaining.times'] + '：', cont: [languageMessages['real.stock.picker'] + '：' + count + languageMessages['times']] });

                            date = ups[0].expiredDate;
                        }

                        var upo = permission.filter(function (e) { return e.type === 'vh' });
                        if (upo.length > 0) {
                            var count = upo[0].totalCount - upo[0].usageCount;
                            status.push({ title: languageMessages['remaining.validity.period'] + '：', cont: [languageMessages['option.strategy.scan']+'：' + count + languageMessages['times']] });

                            date = upo[0].expiredDate;
                        }
                        break;
                    case 2:
                        var info = this.parseDiscount(discount);
                        name = [{
                            title: languageMessages['Scan']+"30",
                            price: price,
                            specialPrice: info,
                            button: languageMessages['buy'],
                        }];
                        
                        var items = [languageMessages['payment.server.02.01']];
                        intro = { items: items };

                        var ups = permission.filter(function (e) { return e.type === 's1' });
                        if (ups.length > 0) {
                            var count = ups[0].totalCount - ups[0].usageCount;
                            status.push({ title: languageMessages['remaining.times'] + '：', cont: [languageMessages['real.stock.picker'] + '：' + count + languageMessages['times']] });

                            date = ups[0].expiredDate;
                        }
                        break;
                    case 3:
                        var info = this.parseDiscount(discount);
                        name = [{
                            title: languageMessages['Scan'] +"50",
                            price: price,
                            specialPrice: info,
                            button: languageMessages['buy'],
                        }];
                        
                        var items = [languageMessages['payment.server.03.01']];
                        intro = { items: items };

                        var ups = permission.filter(function (e) { return e.type === 's2' });
                        if (ups.length > 0) {
                            var count = ups[0].totalCount - ups[0].usageCount;
                            status.push({ title: languageMessages['remaining.times'] + '：', cont: [languageMessages['real.stock.picker'] + '：' + count + languageMessages['times']] });

                            date = ups[0].expiredDate;
                        }
                        break;
                    case 4:
                        var info = this.parseDiscount(discount);
                        name = [{
                            title: languageMessages['Monitor']+"30",
                            price: price,
                            specialPrice: info,
                            button: languageMessages['buy'],
                        }];
                        
                        var items = [languageMessages['hawkeye.server.07.01']];
                        intro = { items: items };

                        var ups = permission.filter(function (e) { return e.type === 'h' });
                        if (ups.length > 0) {
                            var count = ups[0].totalCount - ups[0].usageCount;
                            status.push({ title: languageMessages['remaining.times'] + '：', cont: [languageMessages['real.stock.picker'] + '：' + count + languageMessages['times']] });

                            date = ups[0].expiredDate;
                        }
                        break;
                    case 5:
                        var info = this.parseDiscount(discount);
                        name = [{
                            title: 'Quancept InSync',
                            price: price,
                            specialPrice: info,
                            button: languageMessages['buy'],
                        }];
                        
                        var items = [languageMessages['payment.server.06.01'], languageMessages['payment.server.06.02'], languageMessages['payment.server.06.03']];
                        intro = { items: items };

                        var upo = permission.filter(function (e) { return e.type === 'o' });
                        if (upo.length > 0) {
                            var count = upo[0].totalCount - upo[0].usageCount;
                            status.push({ title: languageMessages['remaining.validity.period'] + '：', cont: [languageMessages['option.strategy.scan']+'：' + count + languageMessages['times']] });

                            date = upo[0].expiredDate;
                        }
                        break;
                    case 6:
                        var info = this.parseDiscount(discount);
                        name = [{
                            title: 'Quancept CrystalTrade',
                            price: price,
                            specialPrice: info,
                            button: languageMessages['buy'],
                        }];
                        
                        var items = [languageMessages['payment.server.06.04'], languageMessages['payment.server.06.05'], languageMessages['payment.server.06.06']];
                        intro = { items: items };

                        var cont = [];
                        var upf = permission.filter(function (e) { return e.type === 'f' });
                        if (upf.length > 0) {
                            var count = upf[0].totalCount - upf[0].usageCount;

                            cont.push(languageMessages['factor.scan']+'：' + count + languageMessages['times']);

                            date = upf[0].expiredDate;
                        }

                        var upg = permission.filter(function (e) { return e.type === 'g' });
                        if (upg.length > 0) {
                            var count = upg[0].totalCount - upg[0].usageCount;

                            cont.push(languageMessages['factor.generation'] +'：' + count + languageMessages['times']);

                            date = upg[0].expiredDate;
                        }

                        var upa = permission.filter(function (e) { return e.type === 'a' });
                        if (upa.length > 0) {
                            var count = upa[0].totalCount - upa[0].usageCount;

                            cont.push(languageMessages['intelligent.factor.analysis'] +'：' + count + languageMessages['times']);

                            date = upa[0].expiredDate;
                        }

                        if (cont.length > 0) {
                            status.push({ title: languageMessages['remaining.times'] +'：', cont: cont });
                        }
                        break;
                    
                }

                // 优惠券
                var couponSource = [];
                var coupon = JSON.parse(sessionStorage.getItem("CouponCache") || '{}') || {};
                if (coupon.dataSource !== undefined) {
                    couponSource = coupon.dataSource;
                }

                // 是否全场优惠券
                var universalCouponIndex = couponSource.findIndex((n) => n.type === 0);

                var isCoupon = false;
                if (value.type > 0) {
                    if (universalCouponIndex > -1) {
                        isCoupon = true;
                    } else {
                        var subscript = couponSource.findIndex((n) => n.type === value.type);
                        isCoupon = subscript > -1 ? true : false;
                    }
                    if (isVip && value.type === 1) {
                        isCoupon = false;
                    }
                }

                dataSource.push({
                    key: value.type,
                    name: name,
                    intro: intro,
                    date: date,
                    status: status,
                    isCoupon: isCoupon,
                });

                if (Object.keys(addOn).length > 0) {
                    dataSource.push(addOn);
                }
            }
        }

        this.setState({
            initLoadding: false,
            dataSource,
            isVip: isVip,
            visible: false,
        });
    }

    formatPrice(amount) {
        let price = amount;//(amount / 100).toFixed(2);
        let numberFormat = new Intl.NumberFormat(['en-US'], {
            style: 'currency',
            currency: this.state.currency,
            currencyDisplay: 'symbol',
        });
        return numberFormat.format(price);
    }
    //设置第二列的折扣和优惠价
    parseDiscount(discount, isDiscount=false) {
        var info = [];
        if (discount.length > 0) {
            var strInfo = '';
            var nv = discount[0].split(';');
            if (nv.length > 0) {
                for (var n in nv) {
                    var nvs = nv[n].split(',');
                    if (nvs.length > 0) {
                        var pp1 = nvs[1];
                        if (pp1 >= 10000) {
                            pp1 = (pp1 / 10000) + languageMessages['wan'];
                        }
                        pp1 = this.formatPrice(pp1);
                        if (nvs[0] === '12') {
                            if (strInfo !== '') strInfo += '，';
                            strInfo += pp1 + languageMessages['yuan/year'];// + '(' + nvs[2] + languageMessages['discount'] + ')';
                        } else if (nvs[0] === '24') {
                            if (strInfo !== '') strInfo += '，';
                            strInfo += pp1 + languageMessages['yuan/2year'];// + '(' + nvs[2] + languageMessages['discount'] +')';
                        }
                    }
                }
                //if (isDiscount) strInfo = languageMessages['vip.special'] + '：' + strInfo;
            }
            info.push(strInfo);

            if (discount.length > 1) {
                strInfo = '';
                nv = discount[1].split(';');
                if (nv.length > 0) {
                    for (var n in nv) {
                        var nvs = nv[n].split(',');
                        if (nvs.length > 0) {
                            pp1 = this.formatPrice(nvs[1]);
                            if (nvs[0] === '12') {
                                if (strInfo !== '') strInfo += '，'
                                strInfo += pp1 + languageMessages['yuan/year'];
                            } else if (nvs[0] === '24') {
                                if (strInfo !== '') strInfo += '，'
                                strInfo += pp1 + languageMessages['yuan/2year'];
                            }
                        }
                    }
                    strInfo = languageMessages['vip.special']+'：' + strInfo;
                }
                info.push(strInfo);
            }
        }
        return info;
    }

    onBuyService = key => {
        history.push({ pathname: '/charge', state: { id: [key] } });
    }

    jumpCoupon = e => {
        history.push("/coupon");
    }

    onMyBill = e => {
        e.preventDefault();
        history.push({ pathname: '/member', state: { memberMenuSelectKey: "3" } });
    }

    renderContent = (value, row, index) => {
        const obj = {
            children: value,
            props: {},
        };
        if (index === 4) {
            obj.props.colSpan = 0;
        }
        return obj;
    }

    render() {
        const columns = [
            {
                title: languageMessages['service.name'],
                dataIndex: 'name',
                align: 'center',
                className: 'common-service-name',
                render: (name, record) => {
                    return (<div>
                        {record.isCoupon && (<div className="coupon-conent">
                            <div className="coupon-conent-red" onClick={this.jumpCoupon}>{languageMessages['coupon']}</div>
                        </div>)}
                        {
                            name && name.map((item, index) => {
                                return <div key={index}>
                                    {item.title}
                                    {item.price !== undefined && (<div>{item.price}</div>)}
                                    {item.specialPrice !== undefined && (<div>{item.specialPrice}</div>)}
                                    {(item.button !== undefined) && (<div><Button size='small' style={{ width: 120 }} onClick={() => this.onBuyService(record.key)}>{item.button}</Button></div>)}
                                </div>;
                            })
                        }</div>)
                }
            }, {
                title: languageMessages['service.intro'],
                dataIndex: 'intro',
                align: 'center',
                render: (intro, record) => {
                    var items = intro.items && intro.items.map((item, index) => {
                        return (<span key={index} style={{ display: 'block' }}>{item}</span>)
                    })

                    const obj = {
                        children: <div>{items}</div>,
                        props: {},
                    };
                    if (record.key === 10000) {
                        obj.props.colSpan = 0;
                    }
                    return obj;
                }
            }, {
                title: languageMessages['Expiration'],
                dataIndex: 'date',
                align: 'center',
                width: 150,
                render: (date, record) => {
                    const obj = {
                        children: date,
                        props: {},
                    };
                    if (record.key === 10000) {
                        obj.props.colSpan = 0;
                    }
                    return obj;
                },
            }, {
                title: languageMessages['RemainingCredit'],
                dataIndex: 'status',
                width: 300,
                align: 'center',
                render: (status, record) => {
                    var items = status.map((item, index) => {
                        return (<div key={index}>
                            <span style={{ display: 'block' }}>{item.title}</span>
                            {item.cont && item.cont.map((ii, i) => {
                                return (<b key={i} style={{ display: 'block' }}>{ii}</b>)
                            })}
                        </div>);
                    })

                    const obj = {
                        children: <div>{items}</div>,
                        props: {},
                    };
                    if (record.key === 10000) {
                        obj.props.colSpan = 0;
                    }
                    return obj;
                },
            }
        ];

        return (<Content className='main-container'>
                <div className='common-page-header'>
                <PageHeader title={languageMessages['please.select.service.type']}
                        extra={[
                            <Button key="1" size='small' onClick={this.onMyBill}>{languageMessages['my.account']}</Button>
                        ]} />
                </div>
                <Layout className='cont-content luis-main-content'>
                    <p style={{padding:'10px 0'}}>{languageMessages['payment.info.03']}</p>
                    <div className='common-payment-service'>
                        <Table size='small' bordered
                            rowClassName={record => {
                                    let className = '';
                                    if (record.key === 1000) className = 'common-table-tips ant-table-expanded-row';
                                    if (record.key === 1001) className = 'common-table-hand ant-table-expanded-row';
                                    return className;
                                }}
                            columns={columns}
                            dataSource={this.state.dataSource}
                            loading={this.state.initLoadding}
                            pagination={false} />
                    </div>

                    <div style={{ margin: '20px 0', border: '1px solid #e8e8e8', padding: '16px' }}>
                        <p>
                            If you encounter problems such as unable to trade, failed to recharge, etc., you can contact customer service in the following  ways：
                        </p>
                        <p>
                        <span style={{ display: 'block' }}>*Facebook: Quancept@outlook.com</span>
                        <span style={{ display: 'block' }}>LinkedIn: Quancept@outlook.com</span>
                        <span style={{ display: 'block' }}>Skype: +1 6467701258</span>
                        </p>
                    </div>
                </Layout>
        </Content>)
    }
}

function mapStateToProps(state) {
    return state.charge;
}

const page = connect(mapStateToProps)(ServicePage);
export { page as default }