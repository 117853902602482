import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../helpers/history';
import { Layout, Table, Button, PageHeader, Modal, Form, Input, message, Spin } from 'antd';
import emitter from '../Evt.js';

const { Content } = Layout;
const Confirm = Modal.confirm;

var languageMessages = window.appLocale.messages;

class StrategyPage extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            spinLoadding: false,
            initLoadding: true,
            selectedRowKeys: [],
            dataSource: [],

            validateStatus: '',
            validateMessage: '',
            strategyName: '',

            modalVisible: false,
            modalTitle: '',

            action: '',
        };
    }

    componentDidMount() {
        emitter.addListener('initPage', this.init);
    }

    componentWillMount() {
        this.init();
    }

    componentWillUnmount() {
        emitter.removeListener('initPage', this.init);
    }

    init = () => {
        /**
        var user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        if (user.username === undefined) {
            this.setState({ initLoadding: false }, () => {
                this.props.dispatch({ type: "action_non_user" });
            });
        } else {
            
        }
        */

        var dataSource = [];
        var CustomStrategy = JSON.parse(sessionStorage.getItem("CustomStrategyCache") || '[]') || [];
        if (CustomStrategy.length > 0) {
            var index = 1;
            for (var kk in CustomStrategy) {
                dataSource.push({
                    key: CustomStrategy[kk].id,
                    sequence: index,
                    userId: CustomStrategy[kk].userId,
                    type: CustomStrategy[kk].type,
                    conceptId: CustomStrategy[kk].conceptId,
                    groupType: CustomStrategy[kk].groupType,
                    filterId: CustomStrategy[kk].filterId,
                    filterName: CustomStrategy[kk].filterName,
                    filterString: CustomStrategy[kk].filterString,
                    symbolGroupId: CustomStrategy[kk].symbolGroupId,
                    updated: CustomStrategy[kk].updated
                });
                index++;
            }
        }
        this.setState({ dataSource, initLoadding: false });
    }

    componentWillReceiveProps(props) {
        var { status, state, customStrategy } = props;
        var { dataSource, strategyName, selectedRowKeys, action } = this.state;

        var items = JSON.parse(sessionStorage.getItem("CustomStrategyCache") || '[]') || [];
        switch (status) {
            case 'strategy_success':
                
                break;
            case 'strategy_fail':
                var error = languageMessages['get.data.failed'];
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = languageMessages['user.exception'];
                        break;
                    default:
                        break;
                }
                message.error(error);
                break;
            case 'rename_strategy_success':
                if (selectedRowKeys.length > 0) {
                    if (action === 'rename') {
                        if (strategyName !== '') {
                            const index = dataSource.findIndex(item => item.key === selectedRowKeys[0]);
                            dataSource[index].filterName = strategyName;

                            const ii = items.findIndex(item => item.id === selectedRowKeys[0]);
                            if (ii > -1) {
                                items[ii].filterName = strategyName;
                                sessionStorage.setItem("CustomStrategyCache", JSON.stringify(items));
                            }
                        }

                        this.setState({
                            selectedRowKeys: [],
                            dataSource: dataSource,
                            strategyName: '',

                            modalTitle: '',
                            modalVisible: false,
                            validateStatus: '',
                            validateMessage: '',

                            action: '',
                        });
                    }
                }
                break;
            case 'rename_strategy_fail':
                var error = languageMessages['rename.strategy.failed'];
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = languageMessages['user.exception'];
                        break;
                    default:
                        break;
                }
                message.error(error);
                break;
            case 'copy_strategy_success':
                if (selectedRowKeys.length > 0) {
                    var index = dataSource.length;
                    index++;
                    dataSource.push({
                        key: customStrategy.id,
                        sequence: index++,
                        userId: customStrategy.userId,
                        type: customStrategy.type,
                        conceptId: customStrategy.conceptId,
                        groupType: customStrategy.groupType,
                        filterId: customStrategy.filterId,
                        filterName: customStrategy.filterName,
                        filterString: customStrategy.filterString,
                        symbolGroupId: customStrategy.symbolGroupId,
                        updated: customStrategy.updated
                    });
                    
                    items.push(customStrategy);
                    sessionStorage.setItem("CustomStrategyCache", JSON.stringify(items));

                    this.setState({
                        selectedRowKeys: [],
                        dataSource: dataSource,
                        strategyName: '',
                        modalTitle: '',
                        modalVisible: false,
                        validateStatus: '',
                        validateMessage: '',

                        action: '',
                    });
                }
                break;
            case 'copy_strategy_fail':
                var error = languageMessages['copy.strategy.failed'];
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = languageMessages['copy.strategy.failed'];
                        break;
                    case 3:
                        error = languageMessages['copy.strategy.account.not.exist'];
                        break;
                    case 4:
                        error = languageMessages['user.exception'];
                        break;
                    case 5:
                        error = languageMessages['request.copy.strategy.failed'];
                        break;
                    case 6:
                        error = languageMessages['copy.strategy.timeout'];
                        break;
                    case 7:
                        error = languageMessages['copy.strategy.error'];
                        break;
                    case 8:
                        error = languageMessages['connection.strategy.server.failed'];
                        break;
                    default:
                        break;
                }
                message.error(error);
                this.setState({
                    modalTitle: '',
                    modalVisible: false,
                    validateStatus: '',
                    validateMessage: '',

                    action: '',
                });
                break;
            case 'remove_success':
                if (selectedRowKeys.length > 0) {
                    dataSource = dataSource.filter(function (v) { return selectedRowKeys.indexOf(v.key) === -1 });

                    items = items.filter(function (v) { return selectedRowKeys.indexOf(v.id) === -1 });
                    sessionStorage.setItem("CustomStrategyCache", JSON.stringify(items));

                    message.success(languageMessages['remove.strategy.success']);
                }
                this.setState({
                    spinLoadding: false,
                    selectedRowKeys: [],
                    dataSource: dataSource,
                    strategyName: '',

                    modalTitle: '',
                    modalVisible: false,
                    validateStatus: '',
                    validateMessage: '',

                    action: '',
                });
                break;
            case 'remove_fail':
                var error = languageMessages['remove.strategy.failed'];
                switch (state) {
                    case 0:
                        break;
                    case -1:
                        error = languageMessages['user.exception'];
                        break;
                    default:
                        break;
                }
                message.error(error);
                this.setState({ spinLoadding: false });
                break;
            default:
                break;
        }
    }

    onRename = e => {
        e.preventDefault();
        var { selectedRowKeys, dataSource } = this.state;

        if (selectedRowKeys.length === 1) {
            var tmpData = dataSource.filter(function (e) { return e.key === selectedRowKeys[0]; });
            if (tmpData.length > 0) {
                this.setState({
                    modalVisible: true,
                    modalTitle: languageMessages['rename'],
                    strategyName: tmpData[0].filterName + languageMessages['_copy'],
                    action: 'rename',

                    validateStatus: '',
                    validateMessage: '',
                });
            }
        }
    }

    onCopy = e => {
        e.preventDefault();
        var { selectedRowKeys, dataSource } = this.state;

        if (selectedRowKeys.length === 1) {
            var tmpData = dataSource.filter(function (e) { return e.key === selectedRowKeys[0]; });
            if (tmpData.length > 0) {
                this.setState({
                    modalVisible: true,
                    modalTitle: languageMessages['copy'],
                    strategyName: tmpData[0].filterName + languageMessages['_copy'],
                    action: 'copy',
                    
                    validateStatus: '',
                    validateMessage: '',
                });
            }
        }
    }

    onRemove = e => {
        e.preventDefault();
        var { selectedRowKeys, dataSource } = this.state;

        if (selectedRowKeys.length > 0) {
            var data = dataSource.filter(function (v) { return selectedRowKeys.indexOf(v.key) !== -1 && v.filterId !== 0 });
            var filterId = '';
            if (data.length > 0) {
                for (var kk in data) {
                    if (filterId !== '') filterId += ',';
                    filterId += data[kk].filterId;
                }
            }
            var user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
            if (user.username === undefined) {
                this.props.dispatch({ type: "action_non_user" });
            } else {
                var _this = this;
                Confirm({
                    title: languageMessages['remove.this.strategy'],
                    content: '',
                    okText: languageMessages['yes'],
                    okType: 'danger',
                    cancelText: languageMessages['no'],
                    onOk() {
                        _this.setState({ spinLoadding: true }, () => {
                            _this.props.dispatch({ type: "action_remove_strategy", payload: { UserId: user.username, strategyId: selectedRowKeys.join(','), filterId: filterId } });  
                        });
                    },
                    onCancel() { },
                });
            }
        }
    };

    handleUpdateStrategy = e => {
        e.preventDefault();
        var { selectedRowKeys, dataSource, strategyName, action } = this.state;

        var items = JSON.parse(sessionStorage.getItem("CustomStrategyCache") || '[]') || [];
        var checkData = items.filter(function (e) { return e.filterName == strategyName; });
        if (checkData.length > 0) {
            this.setState({
                validateStatus: 'error',
                validateMessage: languageMessages['strategy.name.already.exists'],
            });
        } else {
            var tmpData = dataSource.filter(function (e) { return e.key === selectedRowKeys[0]; });
            if (tmpData.length > 0) {
                var user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
                if (user.username === undefined) {
                    this.props.dispatch({ type: "action_non_user" });
                } else {
                    switch (action) {
                        case 'rename':
                            this.props.dispatch({
                                type: "action_rename_strategy",
                                payload: {
                                    Id: tmpData[0].key,
                                    UserId: tmpData[0].userId,
                                    Type: tmpData[0].type,
                                    ConceptId: tmpData[0].conceptId,
                                    FilterId: tmpData[0].filterId,
                                    FilterName: strategyName,
                                    FilterString: tmpData[0].filterString,
                                    GroupType: tmpData[0].groupType,
                                    SymbolGroupId: tmpData[0].symbolGroupId
                                }
                            });
                            break;
                        case 'copy':
                            this.props.dispatch({
                                type: "action_copy_strategy",
                                payload: {
                                    customId: 0,
                                    UserId: user.username,
                                    Type: tmpData[0].type,
                                    ConceptId: tmpData[0].conceptId,
                                    FilterId: 0,
                                    FilterName: strategyName,
                                    FilterString: tmpData[0].filterString,
                                    GroupType: tmpData[0].groupType,
                                    SymbolGroupId: tmpData[0].symbolGroupId
                                }
                            });
                            break;
                    }
                }
            }
        }
    };

    handleCancelStrategy = e => {
        e.preventDefault();
        this.setState({
            modalTitle: '',
            modalVisible: false,
            validateStatus: '',
            validateMessage: '',

            action: '',
        });
    };

    onChangeStrategy = e => {
        e.preventDefault();

        var strategyName = e.target.value;
        if (strategyName !== '') {
            strategyName = strategyName.slice(0, 20);

            var validateStatus = '';
            var validateMessage = '';
            var pattern = /^[A-Za-z0-9_\u4e00-\u9fa5]+$/gi;
            if (!pattern.test(strategyName)) {
                validateStatus = 'error';
                validateMessage = languageMessages['strategy.name.format'];
            }

            this.setState({
                validateStatus: validateStatus,
                validateMessage: validateMessage,
                strategyName: strategyName
            });
        }
    }

    newConceptStrategy = () => {
        emitter.emit('callJump', 'concept');
        history.push("/concept");
    };

    newCustomStrategy = () => {
        sessionStorage.removeItem("VernacularStrategyCache");
        emitter.emit('callJump', 'vernacular');
        history.push("/vernacular");
    };

    onBacktestItems = () => {
        message.info(languageMessages['function.not.open']);
    }

    selectRow = (record) => {
        var StrategyCache = {
            conceptId: record.conceptId,
            customId: record.key,
            stockPoolKey: record.groupType,
            stockGroups: record.symbolGroupId,
            filterId: record.filterId,
            filterName: record.filterName,
            filterString: record.filterString
        };

        if (record.type === 0) {
            sessionStorage.setItem("ConceptStrategyCache", JSON.stringify(StrategyCache));
            emitter.emit('callJump', 'concept');
            history.push("/editStrategy");
        } else {
            sessionStorage.setItem("VernacularStrategyCache", JSON.stringify(StrategyCache));
            emitter.emit('callJump', 'vernacular');
            history.push("/vernacular");
        }
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    render() {
        var { dataSource, selectedRowKeys } = this.state;

        const rowSelection = {
            title: languageMessages['select.all'],
            selectedRowKeys: selectedRowKeys,
            //onClick: this.onSelectChange,
            onChange: this.onSelectChange
        }

        var formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 4 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 20 }, }, };

        const columns = [
            {
                title: languageMessages['sequence'],
                dataIndex: 'sequence',
                width: 50
            }, {
                title: languageMessages['symbol.name'],
                dataIndex: 'filterName',
                sorter: (a, b) => a.filterName < b.filterName ? -1 : a.filterName > b.filterName ? 1 : 0,
                sortDirections: ['descend', 'ascend'],
                render: (text, record) => <a onClick={() => this.selectRow(record)}>{text}</a>,
            }, {
                title: languageMessages['symbol.type'],
                dataIndex: 'type',
                sorter: (a, b) => a.type - b.type,
                sortDirections: ['descend', 'ascend'],
                render: type => (
                    <span>
                        {type === 0 ? languageMessages['intl.menu.strategyStockPicking'] : languageMessages['vernacular.stock.picking']}
                    </span>
                )
            }, {
                title: languageMessages['backtest.status'],
                dataIndex: 'backtestStatus',
                sorter: (a, b) => a.backtestStatus - b.backtestStatus,
                sortDirections: ['descend', 'ascend'],
            }, {
                title: languageMessages['last.updated'],
                dataIndex: 'updated',
                sorter: (a, b) => a.updated < b.updated ? -1 : a.updated > b.updated ? 1 : 0,
                sortDirections: ['descend', 'ascend'],
            }, {
                title: languageMessages['backtest.count'],
                dataIndex: 'backtestCount',
                sorter: (a, b) => a.backtestCount - b.backtestCount,
                sortDirections: ['descend', 'ascend'],
            }, {
                title: languageMessages['control'],
                dataIndex: '',
                render: (text, record) => <a onClick={() => this.onBacktestItems(record.key)}>{languageMessages['backtest.items']}</a>,
            },
        ];

        return (<Content className='main-container'>
            <Spin spinning={this.state.spinLoadding}>
            <div className='common-page-header'>
                <PageHeader title={languageMessages['my.strategy']}
                    extra={[
                        <Button key="1" size='small' onClick={this.newCustomStrategy}>{languageMessages['new.vernacular.stock.picker']}</Button>
                    ]}
                />
            </div>
            <div className='table-content'>
                <div className='common-tools'>
                    <Button onClick={this.onRename} disabled={selectedRowKeys.length === 1 ? false : true}>{languageMessages['rename']}</Button>
                    <Button onClick={this.onCopy} disabled={selectedRowKeys.length === 1 ? false : true}>{languageMessages['copy']}</Button>
                    <Button onClick={this.onRemove} disabled={selectedRowKeys.length > 0 ? false : true}>{languageMessages['remove']}</Button>
                    <Modal
                        title={this.state.modalTitle}
                        visible={this.state.modalVisible}
                        onOk={this.handleUpdateStrategy}
                        confirmLoading={this.props.status === 'strategy_commit'}
                        onCancel={this.handleCancelStrategy}>
                        <Form {...formItemLayout}>
                            <Form.Item label={languageMessages['strategy.name.form']}
                                hasFeedback
                                validateStatus={this.state.validateStatus}
                                help={this.state.validateMessage}>
                                <Input placeholder={languageMessages['strategy.name.placeholder']} id={this.state.validateStatus} value={this.state.strategyName} onChange={this.onChangeStrategy} />
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
                <Table rowSelection={rowSelection}
                    bordered size='small'
                    columns={columns}
                    dataSource={dataSource}
                    loading={this.state.initLoadding}
                    onRow={(record) => ({
                        onClick: () => {
                            //this.selectRow(record);
                        },
                    })}
                />
            </div>
            </Spin>
        </Content>)
    }
}
            
function mapStateToProps(state) {
    return state.strategy;
}

const page = connect(mapStateToProps)(StrategyPage);

export { page as default }