import React, { Component } from 'react';

import {
    Form,
    Input,
    Row,
    Col,
    Button,
    AutoComplete,
} from 'antd';

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class EmailForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSource: [],

        };
    }

    componentDidMount() {
        this.props.form.validateFields();
    }

    componentWillReceiveProps() {
        
    }

    handleSubmit = e => {
        e.preventDefault();

        var user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        if (user.username === undefined) {
            this.props.dispatch({ type: "action_non_user" });
        } else {
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    this.props.dispatch({ type: "action_check_email", payload: { u: user.username, e: values.email, c: values.captcha } });
                }
            });
        }
    };

    sendCaptcha = e => {
        e.preventDefault();

        var user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        if (user.username === undefined) {
            this.props.dispatch({ type: "action_non_user" });
        } else {
            this.props.form.validateFields(['email'], (err, values) => {
                if (!err) {
                    this.props.dispatch({ type: "action_email_captcha", payload: { u: user.username, e: values.email } });
                }
            });
        }
    }

    handleEmailChange = value => {
        this.setState({
            dataSource: !value || value.indexOf('@') >= 0 ? [] : [`${value}@gmail.com`, `${value}@163.com`, `${value}@qq.com`],
        }, () => {
            this.props.form.validateFields();
        });
    };

    handleChange = value => {
        this.props.form.validateFields();
    }

    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

        const emailError = isFieldTouched('email') && getFieldError('email');
        const captchaError = isFieldTouched('captcha') && getFieldError('captcha');

        return (<Form onSubmit={this.handleSubmit} className='register-form' style={{ textAlign: 'left' }}>
            <Form.Item validateStatus={emailError ? 'error' : ''} help={emailError || ''}>
                {getFieldDecorator('email', {
                    //initialValue: '',
                    rules: [
                        { type: 'email', message: 'The input is not valid E-mail', },
                        { required: true, message: 'Please input E-mail' }
                    ],
                })(
                    <AutoComplete dataSource={this.state.dataSource}
                        onChange={this.handleEmailChange}
                        placeholder="Email address"
                    >
                        <Input />
                    </AutoComplete>,
                )}
            </Form.Item>
            <Form.Item validateStatus={captchaError ? 'error' : ''} help={captchaError || ''}>
                <Row gutter={8}>
                    <Col span={12}>
                        {getFieldDecorator('captcha', {
                            rules: [{ required: true, message: 'Please input captcha' }],
                        })(<Input placeholder='Captcha' onChange={this.handleChange} autoComplete={'off'} />)}
                    </Col>
                    <Col span={12}>
                        <Button onClick={this.sendCaptcha} loading={this.props.status === 'email_captcha_commit'} disabled={this.props.status === 'email_captcha_commit'}>Send email captcha</Button>
                    </Col>
                </Row>
            </Form.Item>
            <Form.Item>
                <Button size='small' htmlType="submit" loading={this.props.status === 'check_email_commit'} disabled={this.props.status === 'check_email_commit' || hasErrors(getFieldsError())}>
                    Confirm
                </Button>
            </Form.Item>
        </Form>
        );
    }
}

const WrappedEmailForm = Form.create({ name: 'email' })(EmailForm);
export { WrappedEmailForm as default }