import { put, takeLatest } from 'redux-saga/effects'

function* nonUser(action) {
    try {
        yield put({ type: 'non_user' });
    } catch (e) {
        console.log(e);
    }
}

function* emptyStatus() {
    try {
        yield put({ type: 'empty' });
    } catch (e) {
        console.log(e);
    }
}

function* fail() {
    yield takeLatest('action_non_user', nonUser);

    yield takeLatest('action_empty', emptyStatus);
}

export default fail;