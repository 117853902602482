import { AverageRetByQuantileOption, AlphaStrengthOption, CumRetOption, CumRetByQuantileOption, AverageRetBySectorOption } from './factorReturn'
import { ICValueByPeriodOption, ICMeanBySectorOption } from './factorIndicate'
import { TurnoverCurveOption } from './factorTurnover'

function deepCopy(obj, cache = []) {
    function find(list, f) {
        return list.filter(f)[0]
    }

    // just return if obj is immutable value
    if (obj === null || typeof obj !== 'object') {
        return obj
    }

    // if obj is hit, it is in circular structure
    const hit = find(cache, c => c.original === obj)
    if (hit) {
        return hit.copy
    }

    const copy = Array.isArray(obj) ? [] : {}
    // put the copy into cache at first
    // because we want to refer it in recursive deepCopy
    cache.push({
        original: obj,
        copy
    })

    Object.keys(obj).forEach(key => {
        copy[key] = deepCopy(obj[key], cache)
    })

    return copy
}

function getChartOption(chartName, factorData, sector) {
    let option = null
    let max = 0
    let min = 999
    switch (chartName) {
        case "IcValueByPeriod":
            option = ICValueByPeriodOption
            option.xAxis.data = factorData['x']

            var ii = 0
            for (var key in factorData) {
                if (key == "x") continue;
                option.series[ii].data = factorData[key]
                ii++
            }

            break;
        case "IcMeanBySector":
            option = ICMeanBySectorOption
            option.xAxis.data = factorData['x']

            option.legend.data = []
            option.series = []

            for (var key in factorData) {
                if (key == "x") continue;
                option.legend.data.push(key)

                var seriesConfig = { name: key, type: 'bar', barGap: 0, data: factorData[key] }
                option.series.push(seriesConfig)
            }

            break;
        case 'AverageRetByQuantile':
            option = AverageRetByQuantileOption

            option.xAxis.data = factorData["AverageRetByQuantile"]['x']

            option.legend.data = []
            option.series = []

            for (var key in factorData["AverageRetByQuantile"]) {
                if (key == "x") continue;
                option.legend.data.push(key)

                var seriesConfig = { name: key, type: 'bar', barGap: 0, data: factorData["AverageRetByQuantile"][key] }
                option.series.push(seriesConfig)
            }
            break;
        case "AlphaStrength":
            option = AlphaStrengthOption
            option.xAxis.data = factorData["AlphaStrength"]['x']

            var ii = 0
            for (var key in factorData["AlphaStrength"]) {
                if (key == "x") continue;
                option.series[ii].data = factorData["AlphaStrength"][key]
                ii++
            }
            break;
        case "CumRet":
            option = CumRetOption
            option.xAxis.data = factorData["CumRet"]['x']

            var ii = 0
            for (var key in factorData["CumRet"]) {
                if (key == "x") continue;
                option.series[ii].data = factorData["CumRet"][key]
                ii++
            }

            max = 0
            min = 999
            for (let i = 0; i < 4; i++) {
                if (Math.max.apply(null, option.series[i].data) > max)
                    max = Math.max.apply(null, option.series[i].data)
                if (Math.min.apply(null, option.series[i].data) < min)
                    min = Math.min.apply(null, option.series[i].data)
            }
            min -= 0.01
            max += 0.01
            option.yAxis.min = min.toFixed(2)
            option.yAxis.max = max.toFixed(2)

            //  debugger
            break;
        case "CumRetByQuantile":
            option = CumRetByQuantileOption
            option.title.text = sector
            option.xAxis.data = factorData["CumRetByQuantile"]['x']
            
            var ii = 0
            for (var kk in factorData["CumRetByQuantile"][sector]) {
                option.series[ii].data = factorData["CumRetByQuantile"][sector][kk]
                ii++
            }

            max = 0
            min = 999
            for (let i = 0; i < 5; i++) {
                if (Math.max.apply(null, option.series[i].data) > max)
                    max = Math.max.apply(null, option.series[i].data)
                if (Math.min.apply(null, option.series[i].data) < min)
                    min = Math.min.apply(null, option.series[i].data)
            }
            min -= 0.01
            max += 0.01
            option.yAxis.min = min.toFixed(2)
            option.yAxis.max = max.toFixed(2)
            
            break;
        case "RetBySector":
            option = AverageRetBySectorOption
            option.title.text = sector
            option.xAxis.data = factorData["RetBySector"]['x']
            
            var ii = 0
            for (var kk in factorData["RetBySector"][sector]) {
                option.series[ii].data = factorData["RetBySector"][sector][kk]
                ii++
            }
            break;
        case "TurnoverCurve":
            option = TurnoverCurveOption
            option.xAxis.data = factorData['x']

            var ii = 0
            for (var key in factorData) {
                if (key == "x" || key == "min" || key == "max") continue;
                option.series[ii].data = factorData[key]
                ii++
            }

            option.yAxis.min = factorData['min']
            option.yAxis.max = factorData['max']

            break;
    }
    return deepCopy(option)
}

export { getChartOption }