import antdZh from 'antd/lib/locale-provider/zh_CN';
import appLocaleData from 'react-intl/locale-data/zh';
import zhMessages from './zh';

window.appLocale = {
  messages: {
    ...zhMessages,
  },
  antd: antdZh,
  locale: 'zh-Hans-CN',
  data: appLocaleData,
};
