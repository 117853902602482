//IC分析
var ICValueByPeriodOption = {
    legend: {
        data: ["1D", "3D", "5D", "7D"]
    },
    tooltip: {
        trigger: 'axis',
        position: function (pt) {
            return [pt[0], '10%'];
        }
    },
    xAxis: {
        type: 'category',
        data: []
    },
    yAxis: {
        type: 'value'
    },
    series: [
        {
            name: '1D',
            type: 'line',
            smooth: true,
            symbol: 'none',
            data: []
        }, {
            name: '3D',
            type: 'line',
            smooth: true,
            symbol: 'none',
            data: []
        },
        {
            name: '5D',
            type: 'line',
            smooth: true,
            symbol: 'none',
            data: []
        },
        {
            name: '7D',
            type: 'line',
            smooth: true,
            symbol: 'none',
            data: []
        }
    ]
};
//行业IC分析
var ICMeanBySectorOption = {
    legend: {
        data: []
    },
    tooltip: {
        trigger: 'axis',
        position: function (pt) {
            return [pt[0], '10%'];
        }
    },
    xAxis: {
        type: 'category',
        axisTick: { show: false },
        axisLabel: {
            formatter: function (value) {
                return value.split("").join("\n");
            }
        },
        data: []
    },
    yAxis: {
        type: 'value'
    },

    series: []
};

export { ICValueByPeriodOption, ICMeanBySectorOption }