export default function strategy(state = {}, action) {
    switch (action.type) {
        case "strategy_success":
            return { ...state, status: 'strategy_success', items: action.items };
        case "strategy_fail":
            return { ...state, status: 'strategy_fail', state: action.status };

        case "strategy_commit":
            return { ...state, status: 'strategy_commit' };
        case "rename_strategy_success":
            return { ...state, status: 'rename_strategy_success' };
        case "rename_strategy_fail":
            return { ...state, status: 'rename_strategy_fail', state: action.status };
            
        case "copy_strategy_success":
            return { ...state, status: 'copy_strategy_success', customStrategy: action.editStrategy };
        case "copy_strategy_fail":
            return { ...state, status: 'copy_strategy_fail', state: action.status };

        case "remove_success":
            return { ...state, status: 'remove_success', strategyId: action.strategyId };
        case "remove_fail":
            return { ...state, status: 'remove_fail', state: action.status };

        default:
            return state;
    }
}