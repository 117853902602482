import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../helpers/history';
import { Layout, PageHeader, Menu, Button, Input, Form, Modal, Divider, Spin, message } from 'antd';

import SymbolGroupPage from '../../pannels/stock/SymbolGroupPage';
import USSymbolGroupPage from '../../pannels/stock/USSymbolGroupPage';
import StockOrderPage from '../../pannels/stock/StockOrderPage';
import StockResultPage from '../../pannels/stock/StockResultPage';

import TooltipPage from '../../pannels/account/TooltipPage';
import emitter from '../Evt.js';

const { Sider, Content } = Layout;

var languageMessages = window.appLocale.messages;

function strReplace(source, jsonObjcet) {
    for (var v in jsonObjcet) {
        source = source.replace(v, jsonObjcet[v]);
    }
    return source;
}

class MyStrategyPage extends Component {
    constructor(props) {
        super(props);

        this.user = {};
        this.state = {
            stockPoolKey: 'STOCK',

            strategyData: [],
            currentSelectKey: '',

            saveVisible: false,

            lastTimes: 0,

            caretPosition: 0,
            
            stockGroups: window.webType === 'US' ? '10000' : '3180',
            
            filterName: '',
            filterString: '',

            orderType: '$VOLUME_NUM',
            orderDirection: '0',
            isUseOrder: false,

            modalVisible: false,
            modalTitle: '',

            validateStatus: '',
            validateMessage: '',
            strategyName: '',

            stockCount: 0,
            action: '',

            isShowStockResultPage: false,

            spinLoadding: true,

            error: '',
            filterError: '',

            textareaClass: 'common-textarea',
        };
    }

    componentDidMount() {
        emitter.addListener('initPage', this.init);
    }

    componentWillMount() {
        this.init();
    }

    componentWillUnmount() {
        emitter.removeListener('initPage', this.init);
    }

    init = () => {
        this.user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        /**
        if (user.username === undefined) {
            this.setState({ spinLoadding: false }, () => {
                this.props.dispatch({ type: "action_non_user" });
            });
        } else {
            this.props.dispatch({ type: "action_get_formula_sectors", payload: { UserId: user.username, stockPoolKey: this.state.stockPoolKey, stockGroups: this.state.stockGroups } });
        }
        */
        this.props.dispatch({ type: "action_get_formula_sectors", payload: { UserId: this.user.username, stockPoolKey: this.state.stockPoolKey, stockGroups: this.state.stockGroups } });
    }

    setUserPermission(permissions) {
        var lastTimes = 0;
        for (var key in permissions) {
            var permission = permissions[key];
            switch (permission.type) {
                case 's':
                    lastTimes += permission.totalCount - permission.usageCount;
                    break;
                case 'vs':
                    lastTimes += permission.totalCount - permission.usageCount;
                    break;
                case 's1':
                    lastTimes += permission.totalCount - permission.usageCount;
                    break;
                case 's2':
                    lastTimes += permission.totalCount - permission.usageCount;
                    break;
            }
        }
        this.setState({ lastTimes });
    }

    setStrategyShow(customStrategy) {
        var { strategyData, stockPoolKey, stockGroups, currentSelectKey, filterName, filterString } = this.state;
        
        strategyData = [];
        for (var key in customStrategy) {
            if (currentSelectKey === '') {
                currentSelectKey = customStrategy[key].id.toString();
                stockPoolKey = customStrategy[key].groupType;
                stockGroups = customStrategy[key].symbolGroupId;
                filterName = customStrategy[key].filterName;
                filterString = customStrategy[key].filterString;
            }

            strategyData.push(<Menu.Item key={customStrategy[key].id}>{customStrategy[key].filterName}</Menu.Item>);
        }

        if (currentSelectKey !== '') {
            this.setState({
                strategyData: strategyData,
                currentSelectKey: currentSelectKey,
                stockPoolKey: stockPoolKey,
                stockGroups: stockGroups,
                filterName: filterName,
                filterString: filterString,
                spinLoadding: false
            });
        }
    }

    componentWillReceiveProps(props) {
        var { status, state, fail, editStrategy, customGroup, permission, lastTimes } = props;
        
        var customStrategy = JSON.parse(sessionStorage.getItem("CustomStrategyCache") || '[]') || [];
        switch (status) {
            case 'formula_success':
                if (this.user.permission !== undefined) {
                    this.user.permission = permission;
                    sessionStorage.setItem("auth", JSON.stringify(this.user));
                    this.setUserPermission(this.user.permission);
                }

                if (customStrategy.length > 0) {
                    this.setStrategyShow(customStrategy);
                } else {
                    this.setState({ spinLoadding: false });
                }
                break;
            case 'formula_fail':
                if (this.user.permission !== undefined) {
                    this.setUserPermission(this.user.permission);
                }

                var error = languageMessages['get.data.failed'];
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = languageMessages['user.exception'];
                        break;
                    default:
                        break;
                }
                message.error(error);
                this.setState({ spinLoadding: false });
                break;
            case 'scan_formula_success':
                this.user.permission = permission;
                sessionStorage.setItem("auth", JSON.stringify(this.user));

                this.setState({ lastTimes: lastTimes, isShowStockResultPage: true, filterError: '', textareaClass: 'common-textarea' });
                break;
            case 'scan_formula_fail':
                var error = '';
                var textareaClass = 'common-textarea';
                switch (state) {
                    case 0:
                        error = languageMessages['stock.picker.exception'];
                        break;
                    case 2:
                        error = languageMessages['no.choice.to.stock'];
                        break;
                    case 3:
                        error = languageMessages['connection.strategy.Service.failed'];
                        break;
                    case 4:
                        error = languageMessages['user.exception'];
                        break;
                    case 5:
                        error = languageMessages['request.stock.picker.failed'];
                        break;
                    case 6:
                        error = languageMessages['stock.picker.strategy.error'];
                        if (fail === 'Filter quota is reached.') {
                            error = languageMessages['reaching.strategy.quota'];
                        } else {
                            textareaClass = 'common-textarea common-textarea-error';
                            var eer = '';
                            var ff = fail.split(':');
                            if (ff.length > 0) {
                                for (var i in ff) {
                                    if (i == 0) continue;
                                    eer += ff[i];
                                }
                            }
                            if (eer !== '') error += ' : ' + eer;
                        }
                        break;
                    case 7:
                        error = languageMessages['today.stock.picks.end'];
                        break;
                    default:
                        break;
                }
                this.setState({ filterError: error, textareaClass: textareaClass });
                break;
            case 'save_formula_success':
                if (customStrategy.length > 0) {
                    const index = customStrategy.findIndex(item => item.id === editStrategy.id);
                    if (index > -1) {
                        customStrategy[index] = editStrategy;
                    } else {
                        customStrategy.push(editStrategy);
                    }
                } else {
                    customStrategy.push(editStrategy);
                }
                sessionStorage.setItem("CustomStrategyCache", JSON.stringify(customStrategy));

                var content = <span>{languageMessages['to view, please go']}"<a href="javascript:;" onClick={this.jumpMyStrategy}>{languageMessages['my.strategy']}</a>"</span>;
                this.timerModal(languageMessages['save.strategy.success'], content);

                this.setState({
                    modalVisible: false,
                });

                //更新左侧数据
                if (customStrategy) {
                    this.setStrategyShow(customStrategy);
                }

                break;
            case 'save_formula_fail':
                var error = '';
                switch (state) {
                    case 0:
                        error = languageMessages['save.strategy.exception'];
                        break;
                    case 2:
                        error = languageMessages['save.strategy.failed'];
                        break;
                    case 3:
                        error = languageMessages['save.strategy.account.not.exist'];
                        break;
                    case 4:
                        error = languageMessages['user.exception'];
                        break;
                    case 5:
                        error = languageMessages['request.save.strategy.failed'];
                        break;
                    case 6:
                        error = languageMessages['save.strategy.timeout'];
                        break;
                    case 7:
                        error = languageMessages['save.strategy.error'];
                        if (fail === 'Filter quota is reached.') {
                            error = languageMessages['reaching.strategy.quota'];
                        } else {
                            var eer = '';
                            var ff = fail.split(':');
                            if (ff.length > 0) {
                                for (var i in ff) {
                                    if (i == 0) continue;
                                    eer += ff[i];
                                }
                            }
                            if (eer !== '') error += ' : ' + eer;
                        }
                        break;
                    case 8:
                        error = languageMessages['connection.strategy.service.failed'];
                        break;
                    default:
                        break;
                }
                message.error(error);
                this.setState({
                    modalVisible: false,
                });
                break;
            case 'update_customStock_success':
                var customStockGroups = JSON.parse(sessionStorage.getItem("CustomStockGroupsCache") || '[]') || [];
                if (customStockGroups.length > 0) {
                    const index = customStockGroups.findIndex(item => item.groupId === customGroup.groupId);
                    if (index > -1) {
                        customStockGroups[index].groupName = customGroup.groupName;
                        customStockGroups[index].symbolList = customGroup.symbolList;
                    } else {
                        customStockGroups.push(customGroup);
                    }
                } else {
                    customStockGroups.push(customGroup);
                }
                sessionStorage.setItem("CustomStockGroupsCache", JSON.stringify(customStockGroups));

                var content = <span>{strReplace(languageMessages['already.add.success'], { '{num}': this.state.stockCount })}，<a href="javascript:;" onClick={this.jumpMyCustomStock}>{languageMessages['view.my.self-selected.stock']}</a></span>;
                this.timerModal(languageMessages['self-selected.stock.added.success'], content);
                break;
            case 'update_customStock_fail':
                var error = languageMessages['joining.stock.pool.failed'];
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = languageMessages['user.exception'];
                        break;
                    default:
                        break;
                }
                message.error(error);
                break;
            case 'get_stock_count_success':
                this.setState({
                    //stockGroups: data.stockGroups,
                    //stockPoolKey: data.stockPoolKey,
                    error: '',
                    filterError: '',
                });
                break;
            case 'get_stock_count_fail':
                this.setState({
                    error: '',
                    filterError: '',
                });
                break;
            default:
                break;
        }
    }

    timerModal(title, content) {
        var secondsToGo = 5;
        const modal = Modal.success({
            title: title,
            content: content,
        });

        setTimeout(() => {
            modal.destroy();
        }, secondsToGo * 1000);
    }

    handleStockGroup(data) {
        this.setState({
            stockGroups: data.stockGroups,
            stockPoolKey: data.stockPoolKey
        }, () => {
            /**
            if (user.username === undefined) {
                this.setState({ spinLoadding: false }, () => {
                    this.props.dispatch({ type: "action_non_user" });
                });
            } else {
                this.props.dispatch({ type: "action_get_stock_count", payload: { UserId: user.username, stockPoolKey: data.stockPoolKey, stockGroups: data.stockGroups } });
            }
            */
            this.props.dispatch({ type: "action_get_stock_count", payload: { UserId: this.user.username, stockPoolKey: data.stockPoolKey, stockGroups: data.stockGroups } });
        });
    }

    handleStockOrder(data) {
        this.setState({
            orderType: data.orderType,
            orderDirection: data.orderDirection,
            isUseOrder: data.isUseOrder,
        });
    }

    handleCustomStock(data) {
        this.setState({
            stockCount: data.StockCount
        }, () => {
            if (this.user.username === undefined) {
                this.props.dispatch({ type: "action_non_user" });
            } else {
                // 更新股票池
                if (data.GroupId > 0) {
                    this.props.dispatch({ type: "action_update_formula_custom_stock", payload: { GroupId: data.GroupId, UserId: this.user.username, GroupName: data.GroupName, SymbolList: data.SymbolList } });
                } else {
                    this.props.dispatch({ type: "action_add_formula_custom_stock", payload: { UserId: this.user.username, GroupName: data.GroupName, SymbolList: data.SymbolList } });
                }
            }
        });
    }

    jumpMyStrategy = e => {
        e.preventDefault();

        Modal.destroyAll();

        history.push("/strategy");
    }

    jumpMyCustomStock = e => {
        e.preventDefault();

        Modal.destroyAll();

        history.push("/customStock");
    }

    onValueAddedServices = e => {
        e.preventDefault();
        Modal.destroyAll();
        
        history.push({ pathname: '/member', state: { memberMenuSelectKey: "2" } });
    }

    onSelectStockPool = e => {
        this.setState({
            stockPoolkKey: e.target.value,
            error: ''
        });
    };

    startStockPicker = () => {
        let { filterError, lastTimes } = this.state;

        if (filterError !== '') {
            //message.error(languageMessages['stock.picker.strategy.in.error']);
            //return;
        }

        if (this.user.username === undefined) {
            this.props.dispatch({ type: "action_non_user" });
        } else {
            if (lastTimes <= 0) {
                var content = <span>{languageMessages['today.stock.picks.end']}，{languageMessages['please go']}<a onClick={this.onValueAddedServices}>{languageMessages['value-added.services']}</a></span>;
                Modal.info({
                    title: languageMessages['prompt'],
                    content: content
                });
                return;
            }

            this.setState({ isShowStockResultPage: false, filterError: '' }, () => {
                this.checkOperatingStrategy();
            });
        }
    };

    checkOperatingStrategy() {
        let { stockGroups,
            stockPoolKey,
            filterString,
            orderType,
            orderDirection,
            isUseOrder} = this.state;

        if (stockGroups === '') {
            this.setState({
                error: 'STOCK_GROUP_ERROR'
            });
            return;
        }

        if (filterString.length > 8000) {
            message.error(languageMessages['strategy.content.length.too.long']);
            return;
        }
        
        this.props.dispatch({
            type: "action_scen_formula_strategy", payload: {
                UserId: this.user.username,
                Type: 1,
                FilterId: 0,
                FilterName: '',
                GroupType: stockPoolKey,
                FilterString: filterString,
                SymbolGroupId: stockGroups,
                OrderType: isUseOrder ? '' : orderType,
                OrderDirection: isUseOrder ? '' : orderDirection
            }
        });
    }

    onNewStrategy = () => {
        this.setState({
            modalTitle: languageMessages['new.strategy'],
            modalVisible: true,
            validateStatus: '',
            validateMessage: '',
            strategyName: '',
            action: 'new',
        });
    }

    onCopyStrategy = () => {
        let { filterName } = this.state;
        this.setState({
            modalTitle: languageMessages['copy.strategy'],
            modalVisible: true,
            validateStatus: '',
            validateMessage: '',
            strategyName: filterName + languageMessages['_copy'],
            action: 'copy',
        });
    }

    onEditStrategy = e => {
        e.preventDefault();
        var { stockPoolKey, stockGroups, currentSelectKey, filterName, filterString } = this.state;

        var conceptId = 0;
        var filterId = 0;
        var url = '/vernacular';
        var cacheName = 'VernacularStrategyCache';

        var customStrategy = JSON.parse(sessionStorage.getItem("CustomStrategyCache") || '[]') || [];
        var tmpCustomStrategy = customStrategy.filter(function (e) { return e.id == currentSelectKey; });

        if (tmpCustomStrategy.length > 0) {
            if (tmpCustomStrategy[0].type === 0) {
                conceptId = tmpCustomStrategy[0].conceptId;
                filterId = tmpCustomStrategy[0].filterId;
                url = '/editStrategy';
                cacheName = 'ConceptStrategyCache';
            } else {
                url = '/vernacular';
                cacheName = 'VernacularStrategyCache';
            }
        }

        var StrategyCache = {
            conceptId: conceptId,
            customId: currentSelectKey,
            stockPoolKey: stockPoolKey,
            stockGroups: stockGroups,
            filterId: filterId,
            filterName: filterName,
            filterString: filterString
        };

        sessionStorage.setItem(cacheName, JSON.stringify(StrategyCache));
        history.push(url);
    }

    handleSaveStrategy = e => {
        e.preventDefault();
        if (this.user.username === undefined) {
            this.props.dispatch({ type: "action_non_user" });
        } else {
            var { stockPoolKey, stockGroups, currentSelectKey, strategyName, filterName, filterString, action } = this.state;

            var customStrategy = JSON.parse(sessionStorage.getItem("CustomStrategyCache") || '[]') || [];
            var tmpData = customStrategy.filter(function (e) { return e.filterName == strategyName; });
            if (tmpData.length > 0) {
                this.setState({
                    validateStatus: 'error',
                    validateMessage: languageMessages['strategy.name.already.exists'],
                });
            } else {
                var conceptId = 0;
                var filterId = 0;
                var url = '/vernacular';
                var cacheName = 'VernacularStrategyCache';
                var msg = '';
                switch (action) {
                    case 'new':
                        stockPoolKey = 'STOCK';
                        stockGroups = window.webType === 'US' ? '10000' : '3180';
                        filterName = strategyName;
                        filterString = '';

                        msg = languageMessages['new.strategy.success'];
                        break;
                    case 'copy':
                        filterName = strategyName;

                        var tmpCustomStrategy = customStrategy.filter(function (e) { return e.id == currentSelectKey; });
                        if (tmpCustomStrategy.length > 0) {
                            if (tmpCustomStrategy[0].type === 0) {
                                conceptId = tmpCustomStrategy[0].conceptId;
                                filterId = tmpCustomStrategy[0].filterId;
                                url = '/editStrategy';
                                cacheName = 'ConceptStrategyCache';
                            } else {
                                url = '/vernacular';
                                cacheName = 'VernacularStrategyCache';
                            }
                        }

                        msg = languageMessages['copy.strategy.success'];
                        break;
                }

                this.setState({
                    modalTitle: '',
                    modalVisible: false,
                    strategyName: '',

                    filterName: strategyName,

                    action: '',
                });

                var StrategyCache = {
                    conceptId: conceptId, //
                    customId: 0,
                    stockPoolKey: stockPoolKey,
                    stockGroups: stockGroups,
                    filterId: filterId,
                    filterName: filterName,
                    filterString: filterString
                };

                sessionStorage.setItem(cacheName, JSON.stringify(StrategyCache));
                history.push(url);

                message.success(msg);
            }
        }
    };

    handleCancelStrategy = () => {
        this.setState({
            modalTitle: '',
            modalVisible: false,
            strategyName: '',

            validateStatus: '',
            validateMessage: '',

            action: '',
        });
    };

    handleMenuClick = e => {
        var tmpSelectKey = e.key;

        var customStrategy = JSON.parse(sessionStorage.getItem("CustomStrategyCache") || '[]') || [];
        var tmpData = customStrategy.filter(function (e) { return e.id == tmpSelectKey; });
        if (tmpData.length > 0) {
            var data = tmpData[0];

            var filterString = data.filterString;
            var tmpFilterString = filterString.toLowerCase();
            var topIndex = tmpFilterString.indexOf("selecttopn");
            var bottomIndex = tmpFilterString.indexOf("selectbottomn");
            var isUseOrder = false;
            if (topIndex !== -1 || bottomIndex !== -1) {
                //SelectTopN,SelectBottomN
                isUseOrder = true;
            }

            this.setState({
                currentSelectKey: tmpSelectKey.toString(),
                stockPoolKey: data.groupType,
                stockGroups: data.symbolGroupId,
                filterName: data.filterName,
                filterString: filterString,
                filterError: '',
                textareaClass: 'common-textarea',
                isUseOrder: isUseOrder,
            });
        }
    }

    onCaretPositionChangeHandle = position => {
        this.setState({
            caretPosition: position
        });
    }

    onChangeFilterString = e => {
        this.setState({
            filterString: e.target.value,
            filterError: '',
            textareaClass: 'common-textarea',
        });
    }

    buildFilterString() {
        let { filterString, caretPosition } = this.state;

        var textareaValue = filterString.slice(0, caretPosition);
        var startPosition = textareaValue.search(/[A-Za-z]*$/);
        var autoMatchData = textareaValue.slice(startPosition, caretPosition);

        var dataSource = [];
        if (autoMatchData !== '') {
            var filterFunctionsCache = JSON.parse(sessionStorage.getItem("FilterFunctionsCache") || '[]') || [];
            var filterFunctions = filterFunctionsCache.filter(function (e) { return e.functionNameCN.toLowerCase().indexOf(autoMatchData.toLowerCase()) !== -1; });
            if (filterFunctions.length > 0) {
                for (var k in filterFunctions) {
                    if (filterFunctions[k].m2 === '') continue;
                    dataSource.push({
                        key: filterFunctions[k].funNum,
                        name: filterFunctions[k].functionNameCN,
                        txt: filterFunctions[k].mouseDiscrCN + languageMessages['example'] + filterFunctions[k].m2,
                        char: filterFunctions[k].m2,
                    });
                }
            }
        }
        return dataSource;
    }

    onChangeStrategy = e => {
        e.preventDefault();

        var validateStatus = '';
        var validateMessage = '';
        var strategyName = e.target.value;
        if (strategyName !== '') {
            strategyName = strategyName.slice(0, 20);
            
            var pattern = /^[A-Za-z0-9_\u4e00-\u9fa5]+$/gi;
            if (!pattern.test(strategyName)) {
                validateStatus = 'error';
                validateMessage = languageMessages['strategy.name.format'];
            }
        }
        this.setState({
            validateStatus: validateStatus,
            validateMessage: validateMessage,
            strategyName: strategyName
        });
    }

    renderStrategyItem(strategyData, currentSelectKey) {
        if (strategyData.length > 0) {
            return <Menu inlineIndent={50} defaultSelectedKeys={[currentSelectKey || '1']} mode="inline" onClick={this.handleMenuClick}>
                    {strategyData}
                </Menu>
        } else {
            return <div style={{ textAlign: 'center', padding: '20px 0', color: 'rgba(255, 255, 255, 0.75' }}>{languageMessages['no.strategy']}</div>
        }
    }

    render() {
        var { strategyData, stockPoolKey, stockGroups, filterString, currentSelectKey } = this.state;
        var { sectors, stockCount } = this.props;

        var defaultSymbolGroups = {
            stockPoolKey: stockPoolKey,
            stockGroups: stockGroups,
            sectors: sectors,
            stockCount: stockCount,
        };

        var formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 4 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 20 }, }, };

        return (<Content className='main-container'>
            <Spin spinning={this.state.spinLoadding}>
            <div className='common-page-header'>
                <PageHeader title={languageMessages['my.strategy']}
                    extra={[
                        <Button key="1" size='small' onClick={this.onNewStrategy}>{languageMessages['new.strategy']}</Button>,
                        <Button key="2" size='small' onClick={this.onCopyStrategy} disabled={strategyData.length <= 0}>{languageMessages['copy.strategy']}</Button>,
                        <Button key="3" size='small' onClick={this.onEditStrategy} disabled={strategyData.length <= 0}>{languageMessages['edit.strategy']}</Button>
                    ]} />
                <Modal
                    title={this.state.modalTitle}
                    visible={this.state.modalVisible}
                    maskClosable={false}
                    onOk={this.handleSaveStrategy}
                    confirmLoading={this.props.status === 'save_formula_commit'}
                    onCancel={this.handleCancelStrategy}>
                    <Form {...formItemLayout}>
                        <Form.Item label={languageMessages['strategy.name.form']}
                            hasFeedback
                            validateStatus={this.state.validateStatus}
                            help={this.state.validateMessage}>
                                <Input placeholder={languageMessages['strategy.name.placeholder']} id={this.state.validateStatus} value={this.state.strategyName} onChange={this.onChangeStrategy} />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
            <Layout className='main-content'>
                <Sider width={300}>
                    <PageHeader className='common-sider-header' title={languageMessages['strategy.items']} />
                    <Divider />
                    <div className="common-sider-content">
                        {this.renderStrategyItem(strategyData, currentSelectKey)}
                    </div>
                </Sider>
                <Layout>
                    <Content className='common-content'>
                        {window.webType === 'CN' ? <SymbolGroupPage defaultSymbolGroups={defaultSymbolGroups} stockGroup={this.handleStockGroup.bind(this)} error={this.state.error} /> : <USSymbolGroupPage defaultSymbolGroups={defaultSymbolGroups} stockGroup={this.handleStockGroup.bind(this)} error={this.state.error} />}
                        <div className='common-title'><Divider type="vertical" />{languageMessages['formula.editing']}</div>
                        <div className='common-edit'><pre>{filterString}</pre></div>
                        
                        <StockOrderPage filterString={filterString} isUseOrder={this.state.isUseOrder} stockOrder={this.handleStockOrder.bind(this)} />

                        <div style={{ padding: '10px 0' }}>{languageMessages['real-time.market.stock.picker']}</div>
                        <div style={{ padding: '0' }}>
                            <Button size='small' onClick={this.startStockPicker} loading={this.props.status === 'scan_formula_commit'} disabled={this.props.status === 'scan_formula_commit' || this.state.filterString === ''}>
                                {this.props.status === 'scan_formula_commit' ? languageMessages['stock.picking'] : languageMessages['real.stock.picking']}
                            </Button>
                            <TooltipPage lastTimes={this.state.lastTimes} filterError={this.state.filterError} />
                        </div>
                    </Content>
                    {this.state.isShowStockResultPage && (<div>
                    <div className='common-separate-block'></div>
                    <Content className='common-content'>
                        <StockResultPage status={this.props.status} stockPoolKey={stockPoolKey} scanAlert={this.props.scanAlert} customStockEvent={this.handleCustomStock.bind(this)} />
                    </Content></div>
                    )}
                </Layout>
            </Layout>
        </Spin>
    </Content>);
    }
}

function mapStateToProps(state) {
    return state.vernacular;
}

const page = connect(mapStateToProps)(MyStrategyPage);
export { page as default }