const help_side_cn = {
    0: {
        id: '1',
        title: '快速入门',
        children: [
            {
                id: '1-1',
                num: '1',
                title: 'InSync 快速入门',
                children: [
                    {
                        id: '1-1-1',
                        num: '1.1',
                        title: 'InSync 使用手册',
                        children: '',
                        url: 'http://www.baidu.com',
                        color: '#5aa8ce'
                    },
                    {
                        id: '1-1-2',
                        num: '1.2',
                        title: 'InSync 视频教程',
                        children: '',
                        url: 'http://www.baidu.com',
                        color: '#5aa8ce'
                    }
                ]
            },
            {
                id: '1-2',
                num: '2',
                title: 'CrystalTrade 快速入门',
                children: [
                    {
                        id: '1-2-1',
                        num: '2.1',
                        title: ' CrystalTrade 使用手册',
                        children: '',
                        url: 'http://www.baidu.com',
                        color: '#5aa8ce'
                    },
                    {
                        id: '1-2-2',
                        num: '2.2',
                        title: ' CrystalTrade 完整视频教程',
                        children: '',
                        url: 'http://www.baidu.com',
                        color: '#5aa8ce'
                    }
                ]
            },
            {
                id: '1-3',
                num: '3',
                title: '全能股票机器人',
                children: [
                    {
                        id: '1-3-1',
                        num: '3.1',
                        title: '',
                        children: '点击观看视频教程',
                        url: 'http://www.baidu.com',
                        color: '#5aa8ce'
                    }
                ]
            },
            {
                id: '1-4',
                num: '4.',
                title: 'Alpha 量化因子服务平台',
                children: [
                    {
                        id: '1-4-1',
                        num: '4.1',
                        title: '',
                        children: '下载使用手册',
                        url: 'http://www.baidu.com',
                        color: '#5aa8ce'
                    }
                ]
            }
        ]
    },
    1: {
        id: '2',
        title: '精选策略',
        children: [
            {
                id: '2-1',
                num: '1',
                title: '入门级',
                children: '',
                children: [
                    {
                        id: '2-1-1',
                        num: '1.1',
                        title: '行情面',
                        children: [
                            {
                                tit: '股价创历史新高'
                            },
                            // 'BarHigh("D",0)>=HistoryHigh("qianfuquan","out_date")',
                            {
                                tit: '股价大于100Last>100'
                            },
                            {
                                tit: '振幅大于5%'
                            },
                            // 'BarRange("D",0)/BarClose("D",-1) > 0.05',
                            { tit: '开盘价向上跳空' },
                            // 'BarOpen("D",0)>BarHigh("D",-1)'
                        ]
                    },
                    {
                        id: '2-1-2',
                        num: '1.2',
                        title: '技术面',
                        children: [
                            { tit: '股价突破布林线上轨' },
                            // 'BarClose("D",-1) < BbUpper("D",20,2,-1) & BarClose("D",0) >BbUpper("D",20,2,0)',
                            { tit: '今天放量2倍' },
                            // 'BarVolume("D",0)>Sma("VOLUME","D",5,-1)*2',
                            { tit: '量比大于2小于5' },
                            // 'VolumeRatio("Now") > 2 & VolumeRatio("Now") < 5',
                            { tit: '换手率大于5%' },
                            // 'TurnoverRate("D",0) > 0.05'
                        ]
                    },
                    {
                        id: '2-1-3',
                        num: '1.3',
                        title: '财务面',
                        children: [
                            { tit: '每股收益同比增加1.5元' },
                            // 'JB_CW_ByIndex(4,-1)>JB_CW_ByIndex(4,-5)+1.5',
                            { tit: '每股净资产环比增加5%' },
                            // 'JB_CW_ByIndex(5,-1)>JB_CW_ByIndex(5,-2)*(1.05)',
                            { tit: '每股未分配利润大于2' },
                            // 'JB_CW_ByIndex(9,-1) > 2',
                            { tit: '流通股股本同比增加10%' },
                            // 'JB_CW_ByIndex(37,-1)*10000>JB_CW_ByIndex(37,-5)*10000*(1.1)',
                            { tit: '总股本减少一千万股' },
                            // 'JB_CW_ByIndex(36,-1)*10000<JB_CW_ByIndex(36,-5)*10000-10000000'
                        ]
                    }
                ]
            },
            {
                id: '2-2',
                num: '2',
                title: '进阶级',
                children: [
                    {
                        id: '2-2-1',
                        title: '60分钟MACD金叉',
                        children: [
                            // { tit: '60分钟MACD金叉' },
                            // 'Macd_DIF("CLOSE","1",12,26,-1)<Macd_DEA("CLOSE","1",12,26,9,-1)&Macd_DIF("CLOSE","1",12,26,0)>Macd_DEA("CLOSE","1",12,26,9,0)',
                            // { tit: 'MACD空头排列' },
                            // { tit: 'KDJ死叉' },
                            // 'KDJ_K("D",9,-1)>KDJ_D("D",9,-1)&KDJ_K("D",9,0)<KDJ_D("D",9,0)',
                            // { tit: '5日均线上穿10日均线' },
                            // 'Sma("CLOSE","D",5,-1) < Sma("CLOSE","D",10,-1) & Sma("CLOSE","D",5,0) > Sma("CLOSE","D",10,0)',
                            // { tit: '向上跳空缺口' },
                            // 'BarVolume("D",0)>Sma("VOLUME","D",5,-1)',
                            // { tit: '价格比最近5天均值高10%' },
                            // 'BarClose("D",0) >= LoopAvg({BarClose("D",#)},-5,-1,1) * (1+0.1)',
                            // { tit: '股价突破开盘后30分钟高点' },
                            // 'LASTTIME>Time("10:00") & BarClose("1",-1) < PeriodHighIntraday("09:30","10:00") & BarClose("1",0) > PeriodHighIntraday("09:30","10:00")',
                            // { tit: '从过去50日高点回落，幅度大于25%' },
                            // '$High:PeriodHigh("D",-50,-1);BarClose("D",0)/$High-1<-0.25',
                            // { tit: '过去3天连续放量' },
                            // 'LoopCount({BarVolume("D",#)>Sma("VOLUME","D",5,#-1)},-3,-1,1) >= 3',
                            // { tit: '60分钟线与SH600000配对交易的股票' },
                            // '@BenchMark:Sym_TSN_BarData("SH600000","CLOSE","60")',
                            // '@Pair:TSN_BarData("CLOSE","60")',
                            // '@Spread:TS_Divide(@BenchMark,@Pair)',
                            // '@Mean_Spread:TS_Sma(@Spread,20)',
                            // '@Std_Spread:TS_Stddev(@Spread,20)',
                            // 'TS_Get(@Spread,-1)>TS_Get(@Mean_Spread,-1)+TS_Get(@Std_Spread,-1)&TS_Get(@Spread,-2)<TS_Get(@Mean_Spread,-2)+TS_Get(@Std_Spread,-2)'
                        ]
                    },
                    {
                        id: '2-2-2',
                        title: 'MACD空头排列'
                    },
                    {
                        id: '2-2-2',
                        title: 'KDJ死叉'
                    },
                    {
                        id: '2-2-2',
                        title: '5日均线上穿10日均线'
                    },
                    {
                        id: '2-2-2',
                        title: '向上跳空缺口'
                    },
                    {
                        id: '2-2-2',
                        title: '价格比最近5天均值高10%'
                    },
                    {
                        id: '2-2-2',
                        title: '股价突破开盘后30分钟高点'
                    },
                    {
                        id: '2-2-2',
                        title: '从过去50日高点回落，幅度大于25%'
                    },
                    {
                        id: '2-2-2',
                        title: '过去3天连续放量'
                    },
                    {
                        id: '2-2-2',
                        title: '60分钟线与SH600000配对交易的股票'
                    }

                ]
            },
            {
                id: '2-3',
                title: '精通级',
                children: [
                    {
                        id: '2-3-1',
                        title: '弃婴状态',
                        isHideTitle: 1,
                        children: [
                            // 'Abs(BarOpen("D",-2)-BarClose("D",-2))<1/50*BarRange("D",-2) & BarClose("D",-3) < BarOpen("D",-3) & BarClose("D",-1) > BarOpen("D",-1) & BarHigh("D",-2) < BarLow("D",-3) & BarHigh("D",-2) < BarLow("D",-1)',
                        ]
                    },
                    {
                        id: '2-3-2',
                        title: '熊势吞没',
                        isHideTitle: 1,
                        children: [
                            // 'BarClose("D",-2)>BarOpen("D",-2) & BarOpen("D",-2)>BarClose("D",-1) & BarClose("D",-2)<BarOpen("D",-1) & BarLow("D",-1)<BarLow("D",-2) & BarHigh("D",-1)>=BarHigh("D",-2)'
                        ]
                    },
                    {
                        id: '2-3-3',
                        title: '熊势孕线',
                        isHideTitle: 1,
                        children: [
                            // 'BarOpen("D",-2)<BarClose("D",-2) & BarOpen("D",-1)>BarClose("D",-1) & BarOpen("D",-2)<BarClose("D",-1) & BarClose("D",-2)>BarOpen("D",-1)'
                        ]
                    },
                    {
                        id: '2-3-4',
                        title: '牛势吞没',
                        isHideTitle: 1,
                        children: [
                            // 'BarClose("D",-2)<BarOpen("D",-2) & BarOpen("D",-2)<BarClose("D",-1) & BarClose("D",-2)>BarOpen("D",-1) & BarHigh("D",-1)>BarHigh("D",-2) & BarLow("D",-1)<=BarLow("D",-2)'
                        ]
                    },
                    {
                        id: '2-3-5',
                        title: '牛势孕线',
                        isHideTitle: 1,
                        children: [
                            // 'BarOpen("D",-2)>BarClose("D",-2) & BarOpen("D",-1)<BarClose("D",-1) & BarOpen("D",-2)>BarClose("D",-1) & BarClose("D",-2)<BarOpen("D",-1)'
                        ]
                    },
                    {
                        id: '2-3-6',
                        title: '60分钟线熊势吞没',
                        isHideTitle: 1,
                        children: [
                            // 'SMA20DAY_VOLUME>1000000 & BarClose("60",-2)>BarOpen("60",-2) & BarOpen("60",-2)>BarClose("60",-1) & BarClose("60",-2)<BarOpen("60",-1) & BarLow("60",-1)<BarLow("60",-2) & BarHigh("60",-1)>=BarHigh("60",-2)'
                        ]
                    },
                    {
                        id: '2-3-7',
                        title: '60分钟线牛势吞没',
                        isHideTitle: 1,
                        children: [
                            // 'SMA20DAY_VOLUME>1000000 & BarClose("60",-2)<BarOpen("60",-2) & BarOpen("60",-2)<BarClose("60",-1) & BarClose("60",-2)>BarOpen("60",-1) & BarHigh("60",-1)>BarHigh("60",-2) & BarLow("60",-1)<=BarLow("60",-2)'
                        ]
                    },
                    {
                        id: '2-3-8',
                        title: '看涨三明治线',
                        isHideTitle: 1,
                        children: [
                            // "BarClose('D',-3)<BarOpen('D',-3) & BarClose('D',-2)>BarOpen('D',-2) & BarClose('D',-1) <BarOpen('D',-1) & BarClose('D',-1)<BarLow('D',-2) & BarOpen('D',-1)>BarHigh('D',-2)"
                        ]
                    },
                    {
                        id: '2-3-9',
                        title: '乌云盖顶',
                        isHideTitle: 1,
                        children: [
                            // "BarClose('D',-2)>BarOpen('D',-2) & BarRange('D',-2)>SmaBarRange('D',-21, -2)+0.5*StdDevBarRange('D',-21,-2) & Abs(BarClose('D',-2)-BarOpen('D',-2))>2/3*BarRange('D',-2) & BarOpen('D',-1)>BarHigh('D',-2) & BarClose('D',-1)<BarLow('D',-2)"
                        ]
                    },
                    {
                        id: '2-3-10',
                        title: '下降三法',
                        isHideTitle: 1,
                        children: [
                            // "BarRange('D',-5)>SmaBarRange('D',-24, -5) & BarClose('D',-5)<BarOpen('D',-5) & BarClose('D',-1)<BarOpen('D',-1) & PeriodLow('D',-4,-2)>BarLow('D',-5) & PeriodHigh('D',-4,-2)<BarHigh('D',-5) & BarLow('D',-1)<BarLow('D',-5)"
                        ]
                    },
                    {
                        id: '2-3-11',
                        title: '锤头',
                        isHideTitle: 1,
                        children: [
                            // "LAST>1 & Abs(BarOpen('D',-1) - BarClose('D',-1))<2/5*BarRange('D',-1) & Abs(BarOpen('D',-1) - BarClose('D',-1))>1/10*BarRange('D',-1) & BarHigh('D',-1)-BarOpen('D',-1)<1/30*BarRange('D',-1) & BarLow('D',-1)<PeriodLow('D',-4,-2) & LoopAnd({BARCLOSE('D',$)<BARCLOSE('D',$-4)},-10,-1,1)"
                        ]
                    },
                    {
                        id: '2-3-12',
                        title: '吊颈线',
                        isHideTitle: 1,
                        children: [
                            // "LAST>1 & Abs(BarOpen('D',-1) - BarClose('D',-1))<2/5*BarRange('D',-1) & Abs(BarOpen('D',-1) - BarClose('D',-1))>1/10*BarRange('D',-1) & BarHigh('D',-1)-BarOpen('D',-1)<1/30*BarRange('D',-1) & BarLow('D',-1)>PeriodLow('D',-4,-2) & LoopAnd({BARCLOSE('D',$)>BARCLOSE('D',$-4)},-10,-1,1)"
                        ]
                    },
                    {
                        id: '2-3-13',
                        title: '上升三法形态',
                        isHideTitle: 1,
                        children: [
                            // "BarRange('D',-5)>SmaBarRange('D',-24, -5) & BarClose('D',-5)>BarOpen('D',-5) & BarClose('D',-1)>BarOpen('D',-1) & PeriodLow('D',-4,-2)>BarLow('D',-5) & PeriodHigh('D',-4,-2)<BarHigh('D',-5) & BarHigh('D',-1)>BarHigh('D',-5)"
                        ]
                    },
                    {
                        id: '2-3-14',
                        title: '20日均线上穿60日均线',
                        isHideTitle: 1,
                        children: [
                            // "Sma20DAY_VOLUME>1000000 & Sma('CLOSE','D',20,-1)>=SMA('CLOSE','D',60,-1) & LoopAnd({SMA('CLOSE','D',20,$)<SMA('CLOSE','D',60,$)},-5,-2,1)"
                        ]
                    },
                    {
                        id: '2-3-15',
                        title: '下降插入30分钟线',
                        isHideTitle: 1,
                        children: [
                            // "SMA20DAY_VOLUME>1000000 & (PeriodHigh('30', -12, -1)-PeriodLow('30', -12, -1))/(PeriodHigh('30', -30, -1)-PeriodLow('30', -30, -1))<1/3 & 0.25*(BbUpper('30',12,2, -1)- BbLower('30',12,2, -1))/(PeriodHigh('30', -12, -1)-PeriodLow('30', -12, -1))<0.3 & LAST<PeriodLow('30', -12, -1) & !(LoopAnd({BARCLOSE('30',$)<BARCLOSE('30',$-1)},-5,-1,1))"
                        ]
                    },
                    {
                        id: '2-3-16',
                        title: '下降插入60分钟线',
                        isHideTitle: 1,
                        children: [
                            // "SMA20DAY_VOLUME>1000000 & (PeriodHigh('60', -12, -1)-PeriodLow('60', -12, -1))/(PeriodHigh('60', -30, -1)-PeriodLow('60', -30, -1))<1/3 & 0.25*(BbUpper('60',12,2, -1)- BbLower('60',12,2, -1))/(PeriodHigh('60', -12, -1)-PeriodLow('60', -12, -1))<0.3 & LAST<PeriodLow('60', -12, -1) & !(LoopAnd({BARCLOSE('60',$)<BARCLOSE('60',$-1)},-5,-1,1))"
                        ]
                    },
                    {
                        id: '2-3-17',
                        title: '盘整下破日线',
                        isHideTitle: 1,
                        children: [
                            // "SMA20DAY_VOLUME>1000000 & (PeriodHigh('D', -12, -1)-PeriodLow('D', -12, -1))/(PeriodHigh('D', -30, -1)-PeriodLow('D', -30, -1))<1/3 & 0.25*(BbUpper('D',12,2, -1)- BbLower('D',12,2, -1))/(PeriodHigh('D', -12, -1)-PeriodLow('D', -12, -1))<0.3 & LAST<PeriodLow('D', -12, -1) & !(LoopAnd({BARCLOSE('D',$)<BARCLOSE('D',$-1)},-5,-1,1))"
                        ]
                    },
                    {
                        id: '2-3-18',
                        title: '上升插入30分钟线',
                        isHideTitle: 1,
                        children: [
                            // "SMA20DAY_VOLUME>1000000 & (PeriodHigh('30', -12, -1)-PeriodLow('30', -12, -1))/(PeriodHigh('30', -30, -1)-PeriodLow('30', -30, -1))<1/3 & 0.25*(BbUpper('30',12,2, -1)- BbLower('30',12,2, -1))/(PeriodHigh('30', -12, -1)-PeriodLow('30', -12, -1))<0.3 & LAST>PeriodHigh('30', -12, -1) & !(LoopAnd({BARCLOSE('30',$)>BARCLOSE('30',$-1)},-5,-1,1))"
                        ]
                    },
                    {
                        id: '2-3-19',
                        title: '上升插入60分钟线',
                        isHideTitle: 1,
                        children: [
                            // "SMA20DAY_VOLUME>1000000 & (PeriodHigh('60', -12, -1)-PeriodLow('60', -12, -1))/(PeriodHigh('60', -30, -1)-PeriodLow('60', -30, -1))<1/3 & 0.25*(BbUpper('60',12,2, -1)- BbLower('60',12,2, -1))/(PeriodHigh('60', -12, -1)-PeriodLow('60', -12, -1))<0.3 & LAST>PeriodHigh('60', -12, -1) & !(LoopAnd({BARCLOSE('60',$)>BARCLOSE('60',$-1)},-5,-1,1))"
                        ]
                    },
                    {
                        id: '2-3-20',
                        title: '盘整上穿日线',
                        isHideTitle: 1,
                        children: [
                            // "SMA20DAY_VOLUME>1000000 & (PeriodHigh('D', -12, -1)-PeriodLow('D', -12, -1))/(PeriodHigh('D', -30, -1)-PeriodLow('D', -30, -1))<1/3 & 0.25*(BbUpper('D',12,2, -1)- BbLower('D',12,2, -1))/(PeriodHigh('D', -12, -1)-PeriodLow('D', -12, -1))<0.3 & LAST>PeriodHigh('D', -12, -1) & !(LoopAnd({BARCLOSE('D',$)>BARCLOSE('D',$-1)},-5,-1,1))"
                        ]
                    },
                    {
                        id: '2-3-21',
                        title: '十字星15分钟线',
                        isHideTitle: 1,
                        children: [
                            // "SMA20DAY_VOLUME>1000000 & LAST>5 & Abs(BarOpen('15',-1)-BarClose('15',-1))<1/20*BarRange('15',-1)"
                        ]
                    },
                    {
                        id: '2-3-22',
                        title: '十字星日线',
                        isHideTitle: 1,
                        children: [
                            // "SMA20DAY_VOLUME>500000 & Abs(BarOpen('D',-1)-BarClose('D',-1))<1/20*BarRange('D',-1)"
                        ]
                    },
                    {
                        id: '2-3-23',
                        title: '向下冲击30分钟线',
                        isHideTitle: 1,
                        children: [
                            // "SMA20DAY_VOLUME>1000000 & LoopAnd({SMA('CLOSE', '30', 20, $)<SMA('CLOSE', '30', 20, $-1)},-10,-1,1) & LoopAnd({SMA('CLOSE', '30', 8, $)<SMA('CLOSE', '30', 8, $-1)},-10,-1,1)"
                        ]
                    },
                    {
                        id: '2-3-24',
                        title: '向下冲击60分钟线',
                        isHideTitle: 1,
                        children: [
                            // "SMA20DAY_VOLUME>1000000 & LoopAnd({SMA('CLOSE', '60', 20, $)<SMA('CLOSE', '60', 20, $-1)},-10,-1,1) & LoopAnd({SMA('CLOSE', '60', 8, $)<SMA('CLOSE', '60', 8, $-1)},-10,-1,1)"
                        ]
                    },
                    {
                        id: '2-3-25',
                        title: '蜻蜓十字15分钟线',
                        isHideTitle: 1,
                        children: [
                            // "SMA20DAY_VOLUME>500000 & LAST>5 & Abs(BarOpen('15',-1)-BarClose('15',-1))<1/30*BarRange('15',-1) & Abs(BarOpen('15',-1)-BarHigh('15',-1))<1/30*BarRange('15',-1)"
                        ]
                    },
                    {
                        id: '2-3-26',
                        title: '蜻蜓十字日线',
                        isHideTitle: 1,
                        children: [
                            // "Abs(BarOpen('D',-1)-BarClose('D',-1))<1/30*BarRange('D',-1) & Abs(BarOpen('D',-1)-BarHigh('D',-1))<1/30*BarRange('D',-1)"
                        ]
                    },
                    {
                        id: '2-3-27',
                        title: '墓碑十字星',
                        isHideTitle: 1,
                        children: [
                            // "Abs(BarOpen('D',-1)-BarClose('D',-1))<1/30*BarRange('D',-1) & BarClose('D',-1)-BarLow('D',-1)<1/30*BarRange('D',-1)"
                        ]
                    },
                    {
                        id: '2-3-28',
                        title: '身怀六甲',
                        isHideTitle: 1,
                        children: [
                            // "LoopAnd({BARCLOSE('D',$)<BARCLOSE('D',$-2)},-11,-2,1) & BarOpen('D',-2)>BarClose('D',-2) & BarOpen('D',-1)<BarClose('D',-1) & BarOpen('D',-2)>BarClose('D',-1) & BarClose('D',-2)<BarOpen('D',-1) | LoopAnd({BARCLOSE('D',$)>BARCLOSE('D',$-2)},-11,-2,1) & BarOpen('D',-2)<BarClose('D',-2) & BarOpen('D',-1)>BarClose('D',-1) & BarOpen('D',-2)<BarClose('D',-1) & BarClose('D',-2)>BarOpen('D',-1)"
                        ]
                    },
                    {
                        id: '2-3-29',
                        title: '长光头',
                        isHideTitle: 1,
                        children: [
                            // "BarRange('D',-1)>SmaBarRange('D',-20, -1)+StdDevBarRange('D',-20,-1) & Abs(BarClose('D',-1)-BarOpen('D',-1))>5/6*BarRange('D',-1)"
                        ]
                    },
                    {
                        id: '2-3-30',
                        title: '长实体线',
                        isHideTitle: 1,
                        children: [
                            // "Abs(BarOpen('D',-1)-BarClose('D',-1))<1/30*BarRange('D',-1) & Abs(BarHigh('D',-1)-BarOpen('D',-1)-BarClose('D',-1)+BarLow('D',-1))<1/20*BarRange('D',-1) & BarRange('D',-1)>SmaBarRange('D',-20, -1)+StdDevBarRange('D',-20,-1)"
                        ]
                    },
                    {
                        id: '2-3-31',
                        title: 'Marubozu_5',
                        isHideTitle: 1,
                        children: [
                            // "SMA20DAY_VOLUME>1000000 & LAST>5 & Abs(BarOpen('5',-1)-BarClose('5',-1))=BarRange('5',-1)"
                        ]
                    },
                    {
                        id: '2-3-32',
                        title: 'Marubozu_D',
                        isHideTitle: 1,
                        children: [
                            // "Abs(BarOpen('D',-1)-BarClose('D',-1))=BarHigh('D',-1)-BarLow('D',-1)"
                        ]
                    },
                    {
                        id: '2-3-33',
                        title: 'NarrowRangeBuySignal_15min',
                        isHideTitle: 1,
                        children: [
                            // "Avg5:AVG(BarRange('15',-1), BarRange('15',-2), BarRange('15',-3), BarRange('15',-4), BarRange('15',-5)); SMA20DAY_VOLUME>1000000 & BarClose('15',-3)>BarOpen('15',-3) & BarClose('15',-2)>BarOpen('15',-2) & BarClose('15',-1)>BarOpen('15',-1) & BarClose('15',0)>BarOpen('15',0) & BarRange('15',0)<1/4*AVG5"
                        ]
                    },
                    {
                        id: '2-3-34',
                        title: 'NR7_30min',
                        isHideTitle: 1,
                        children: [
                            // "Avg6:AVG(BarRange('30',-2), BarRange('30',-3), BarRange('30',-4), BarRange('30',-5), BarRange('30',-6), BarRange('30',-7)); SMA20DAY_VOLUME>1000000 & BarRange('30',-1)<AVG6/2 & (PeriodHigh('30', -7, -1)-PeriodLow('30', -7, -1))/(PeriodHigh('30', -20, -1)-PeriodLow('30', -20, -1))<1/3"
                        ]
                    },
                    {
                        id: '2-3-35',
                        title: 'OpeningRangeBreakdown_30min',
                        isHideTitle: 1,
                        children: [
                            // "SMA20DAY_VOLUME>1000000 & LASTTime>TIME('10:00') & LAST<PeriodLowIntraday('9:30','10:00') & HIGH<=PeriodHighIntraday('9:30','10:00')"
                        ]
                    },
                    {
                        id: '2-3-36',
                        title: 'OpeningRangeBreakup_30min',
                        isHideTitle: 1,
                        children: [
                            // "SMA20DAY_VOLUME>1000000 & AVGRANGE_20DAY >0.5 & LASTTime>TIME('10:00')& LAST>PeriodHighIntraday('9:30','10:00') & LOW>=PeriodLowIntraday('9:30','10:00')"
                        ]
                    },
                    {
                        id: '2-3-37',
                        title: 'ShootingStar_D',
                        isHideTitle: 1,
                        children: [
                            // "BarRange('D',-2)>SmaBarRange('D',-21, -2)+StdDevBarRange('D',-21,-2) & Abs(BarClose('D',-2)-BarOpen('D',-2))>5/6*BarRange('D',-2) & BarClose('D',-2)>BarOpen('D',-2) & Abs(BarOpen('D',-1) - BarClose('D',-1))<2/5*BarRange('D',-1) & Abs(BarOpen('D',-1) - BarClose('D',-1))>1/10*BarRange('D',-1) & BarLow('D',-1)-BarOpen('D',-1)<1/30*BarRange('D',-1) & BarLow('D',-1)>BarClose('D',-2) & LoopAnd({BARCLOSE('D',$)>BARCLOSE('D',$-2)},-11,-2,1)"
                        ]
                    },
                    {
                        id: '2-3-38',
                        title: 'ShortDay_30',
                        isHideTitle: 1,
                        children: [
                            // "SMA20DAY_VOLUME>500000 & BarRange('30',-1)<SmaBarRange('30',-20, -1)*0.7 & Abs(BarClose('30',-1)-BarOpen('30',-1))<1/3*BarRange('30',-1)"
                        ]
                    },
                    {
                        id: '2-3-39',
                        title: 'ShortDay_D',
                        isHideTitle: 1,
                        children: [
                            // "BarRange('D',-1)<SmaBarRange('D',-20, -1)-StdDevBarRange('D',-20,-1) & Abs(BarClose('D',-1)-BarOpen('D',-1))<2/3*BarRange('D',-1)"
                        ]
                    },
                    {
                        id: '2-3-40',
                        title: 'SpinningTop_D',
                        isHideTitle: 1,
                        children: [
                            // "BarRange('D',-1)>SmaBarRange('D',-20, -1)+StdDevBarRange('D',-20,-1) & Abs(BarClose('D',-1)-BarOpen('D',-1))<1/6*BarRange('D',-1) & (BarHigh('D',-1)-Max(BarOpen('D',-1),BarClose('D',-1)))/(Min(BarOpen('D',-1),BarClose('D',-1))-BarLow('D',-1))<1.2 & (BarHigh('D',-1)-Max(BarOpen('D',-1),BarClose('D',-1)))/(Min(BarOpen('D',-1),BarClose('D',-1))-BarLow('D',-1))>0.8"
                        ]
                    },
                    {
                        id: '2-3-41',
                        title: 'Stars_D',
                        isHideTitle: 1,
                        children: [
                            // "BarLow('D',-1)>BarHigh('D',-2) | BarHigh('D',-1)<BarLow('D',-2)"
                        ]
                    },
                    {
                        id: '2-3-42',
                        title: 'StickSandwich_D',
                        isHideTitle: 1,
                        children: [
                            // "BarClose('D',-3)<BarOpen('D',-3) & BarClose('D',-2)>BarOpen('D',-2) & BarClose('D',-1)<BarOpen('D',-1) & BarClose('D',-3)=BarClose('D',-1) & BarClose('D',-1)<BarLow('D',-2) & BarOpen('D',-1)>BarHigh('D',-2) & LoopAnd({BARCLOSE('D',$)<BARCLOSE('D',$-2)},-12,-3,1)"
                        ]
                    },
                    {
                        id: '2-3-43',
                        title: 'Uptrend_30min',
                        isHideTitle: 1,
                        children: [
                            // "SMA20DAY_VOLUME>1000000 & LoopAnd({BARCLOSE('30',$)>BARCLOSE('30',$-3)},-10,-1,1)"
                        ]
                    },
                    {
                        id: '2-3-44',
                        title: 'UpwardThrust_30min',
                        isHideTitle: 1,
                        children: [
                            // "SMA20DAY_VOLUME>1000000 & LoopAnd({SMA('CLOSE', '30', 20, $)>SMA('CLOSE', '30', 20, $-1)},-10,-1,1) & LoopAnd({SMA('CLOSE', '30', 8, $)>SMA('CLOSE', '30', 8, $-1)},-10,-1,1)"
                        ]
                    },
                    {
                        id: '2-3-45',
                        title: 'WideRangeBar_30min',
                        isHideTitle: 1,
                        children: [
                            // "Avg5:AVG(BarRange('30',-2), BarRange('30',-3), BarRange('30',-4), BarRange('30',-5), BarRange('30',-6)); SMA20DAY_VOLUME>1000000 & BarRange('30',-1)>2.2*AVG5 & (PeriodHigh('30', -7, -1)-PeriodLow('30', -7, -1))/(PeriodHigh('30', -20, -1)-PeriodLow('30', -20, -1))<1/3"
                        ]
                    }
                ]
            }
        ]
    },
    2: {
        id: '3',
        title: '常见问题',
        children: [
            {
                id: '3-1',
                title: '关于宽投产品',
                children: [
                    {
                        id: '3-1-1',
                        title: '宽投提供什么服务',
                        children: [
                            // '智能量化平台：宽投拥有100%自主创新的CEP（复杂事件处理引擎）技术，全市场Tick级行情数据处理，面向券商、买方机构与个人投资者提供一站式完整量化解决方案。投资者可通过自然语言或宽投独有的向导式简易函数与公式，无需编程，完成策略构建、策略风控、智能回测验证、智能策略优化、模拟或实盘运行等，帮助用户便利、快速、高效完成投资思想并投放市场。',
                            // '宽投智能量化平台的主要功能有：',
                            // '思路挖掘 ：输入思路，即得结果。简易Quancept+公式，无编程、非固化。无编程多种实现方式：自然语言、简易公式、向导式；支持15种时间周期 – 日线、1,2,3,5,7,10,13,15,30,60分钟线;支持任意跨周期、跨品种；支持A股、美股、期货、期权',
                            // '构建策略：无需编程，简易公式编辑器结合自然语言理解，5分钟即可构建完整策略，同时支持Python、R；选股择时：止盈、止损、跟踪止盈、最大持仓期、下单类型等，策略风控、进仓条件、出仓条件；组合策略：止盈、止损、跟踪止盈、最大持仓期、下单类型等，策略风控、调仓条件、出仓条件',
                            // '回测验证：精准严谨回测，分钟级回测效率比纯Python平台高50倍以上，机器学习增强策略收益，降低风险和最大回撤日内、隔夜策略回测低频、高频策略回测跨市场、跨品种、跨周期回测;智能策略优化- AI驱动策略收益增强;归因分析-多维度指标筛选、黑白名单丰富的图形展示，详细回测报告',
                            // '智能交易管理: 自主创新的集中交易管理系统 CrystalTrade，实现严密策略风控与稳健策略交易;支持算法下单、市价、限价等多种下单类型;支持模拟、实盘交易;支持全自动交易和自动触发信号手工确认交易两种模式',
                            // '全能股票机器人',
                            // '宽投全能股票机器人基于宽投团队多年量化交易和系统研发经验，应用最新人工智能和机器学习技术，通过语音或文字问答的交互方式，快速匹配问题，并提供多种场景应用的智能问答，根据 tick 级实时市场数据，将关键信息提炼呈现。',
                            // '面向普通证券投资者，彻底消除量化门槛，帮助用户从海量信息中找到最适合自己的，从而快速获取择时选股、智能回测、智能策略优化、白话交易、白话盯盘等一系列智能量化投资服务。',
                            // '全能股票机器人具有以下三大亮点：',
                        ]
                    },
                    {
                        id: '3-1-2',
                        title: '全能股票机器人具有以下三大亮点',
                        children: [
                            // '自然语言语义识别：宽投应用最新人工智能技术，通过语音或文字与小宽机器人交互，快速智能解析，提炼与引导用户设置择时选股思路。',
                            // '识别上下文，对多条逻辑智能识别',
                            // '不同场景下，智能问答和智能提醒',
                            // '快速识别，闪电匹配',
                            // '随问随答，随时待命',
                            // '自定义多条件组合：',
                            // '多个选股条件可以自定义叠加，以便缩小筛选范围，支持使用自定义基本面指标和技术面指标进行叠加筛选，同时对选出的股票进行涨跌幅排序或价格排序，给用户呈现出最有价值的选股结果（例：涨幅前十，从小到大排序）。',
                            // '用户还可通过自然语言与机器人对话的方式来定义回测条件，比如止盈止损，最大持仓期强大并简单方便。',
                            // '完整量化流程',
                            // '投资者可通过自然语言，无需编程，完成智能白话选股、智能回测验证、智能策略优化、智能白话下单、智能白话盯盘、模拟或实盘等一系列智能量化投资服务，实现全流程的量化投资研究或交易。',
                            // 'Alpha量化因子服务平台',
                            // '宽投Alpha量化因子服务平台，是宽投团队依托金融大数据开发的量化服务平台，克服了传统量化因子开发过程中的种种问题，以金融大数据结合人工智能技术，自动化因子生成、智能因子分析、投资组合优化，历史回测，模拟或实盘交易。',
                        ]
                    },
                    {
                        id: '3-1-3',
                        title: '宽投Alpha量化因子服务平台能做到的',
                        children: [
                            // '自动化因子生成：无需编程，采用宽投特有的简易公式结合财务数据、技术指标、舆情情感、实时量价等数据，更灵活丰富的实现自定义因子生成',
                            // '支持多智能因子分析：可同时对多个因子进行智能分析，批量运算，更高效、更全面的实现因子评价指标',
                            // '智能投资组合优化——把信息、风险以及约束系统性地结合在一起进行投资组合优化'
                        ]
                    },
                    {
                        id: '3-1-4',
                        title: '智能量化平台客户端用户如何下载使用',
                        children: [
                            // '打开宽投官网www.quancept.cn，在官网首页导航栏点击‘下载’按钮，进入InSync 客户端和CrystalTrade 客户端下载页面',
                            // '若您已经是宽投客户端用户，可用客户端账号及密码(以CNTU开头)直接登入；若还不是宽投客户端用户，可用手机号注册宽投客户端账号登入'
                        ],
                        img: ''
                    },
                    {
                        id: '3-1-5',
                        title: '',
                        children: [
                            // '**注：手机号注册的账号可享受两周免费VIP试用权限，InSync 客户端和 CrystalTrade 客户端账号试用账号默认已开通VIP权限，用户可向客服QQ群或电话客服咨询试用延期或者购买事宜。'
                        ]
                    },
                    {
                        id: '3-1-6',
                        title: '全能股票机器人如何下载使用',
                        children: [
                            // '打开宽投官网www.quancept.cn，在官网首页导航栏点击‘下载’按钮，进入Andriod手机APP下载页面；',
                            // '若您已经是宽投手机APP用户，可用注册手机号码及密码直接登录；若还不是宽投手机APP用户，可用手机号注册后，以手机号登录；'
                        ],
                        img: ''
                    },
                    {
                        id: '3-1-7',
                        title: '',
                        children: [
                            // '**注：手机号注册的账号可享受两周免费VIP试用权限，手机APP账号试用账号默认已开通VIP权限，用户可向客服QQ群或电话客服咨询试用延期或者购买事宜。'
                        ]
                    },
                    {
                        id: '3-1-8',
                        title: 'Alpha量化因子服务如何试用',
                        children: [
                            // '打开宽投Alpha量化因子服务网址http://www.alpha4me.com；',
                            // '若您已经是宽投客户端用户，可用客户端账号及密码(以CNTU开头)直接登入；若还不是宽投客户端用户，可用手机号注册宽投客户端账号登入；'
                        ],
                        img: ''
                    },
                    {
                        id: '3-1-9',
                        title: '',
                        children: [
                            // '**注：手机号注册的账号可享受两周免费VIP试用权限，Alpha量化因子服务平台试用账号默认已开通VIP权限，用户可向客服QQ群或电话客服咨询试用延期或者购买事宜。'
                        ]
                    },
                    {
                        id: '3-1-10',
                        title: '如何与宽投取得联系',
                        children: [
                            // '您可以通过以下途径咨询各类使用宽投产品过程中遇到的问题：',
                            // '客服电话：021-',
                            // '客服QQ群：',
                            // '客服邮箱：info@quancept.cn',
                        ]
                    },
                    {
                        id: '3-1-11',
                        title: '宽投是否免费',
                        children: [
                            // '试用版用户提供的服务和数据都是免费的，试用账号有效期为两周',
                            // '购买标准版可以使用更多的功能和专业服务',
                            // '若您有意向，可致电咨询'
                        ]
                    }
                ]
            }
        ]
    },
    3: {
        id: '4',
        title: 'InSync 相关',
        children: [
            {
                id: '4-1',
                title: '策略常见问题',
                children: [
                    {
                        id: '4-1-1',
                        title: '如何构建一个最简单的策略',
                        children: [
                            // '参照 精选策略，修改公式或参数后，复制粘贴到新建过滤器窗口；',
                            // '从精选策略克隆一个您感兴趣的策略；',
                            // '新建一个策略，从头开始实现自己的交易思路；',
                            // '如果您想要更详细的了解如何写策略，点击这里。'
                        ]
                    },
                    {
                        id: '4-1-2',
                        title: '如何快速检验投资思路',
                        children: [
                            // '用户通过自然语言或便捷公式构建好策略后，开始紧盯市场，快速检验自己的投资思路。',
                            // '此时，有两种引擎模式供用户选择：',
                            // '第一种是全市场触发过滤',
                            // 'Tick by tick驱动过滤引擎，在策略运行时，一旦有目标股票满足条件，就会立即出现到预警窗口。还可以设置定时器，指定时间来扫描。',
                            // '第二种是横向市场扫描',
                            // '用户可一次性扫描全市场，当前符合条件的股票就会全部过滤出来，以此来检验自己的投资思路。',
                            // '如下图：'
                        ],
                        img: ''
                    },
                    {
                        id: '4-1-3',
                        title: '我的策略归谁所有',
                        children: [
                            // '您对自己构建的策略拥有100%的知识产权。宽投不会也无法查看、公布您的策略、公式、数据，开发者可自主公布分享。',
                        ]
                    },
                    {
                        id: '4-1-4',
                        title: '我的策略是否安全',
                        children: [
                            // '您对自宽投非常严肃对待用户的策略安全问题。我们提供业界最高的安全保护，除了您本人，任何人无法获取您的策略。己构建的策略拥有100%的知识产权。宽投不会也无法查看、公布您的策略、公式、数据，开发者可自主公布分享。',
                        ]
                    }
                ]
            },
            {
                id: '4-2',
                title: '回测常见问题',
                children: [
                    {
                        id: '4-2-1',
                        title: '回测的作用',
                        children: [
                            // { tit: '回测的作用' },
                            // { tit: '策略有效体现了您的交易思想，宽投提供简单易用、专业高效的策略回测平台，通过历史数据回测，可以检验策略的有效性。您可以专注于研究策略逻辑，而不必被数据获取、交易撮合、回测报告等繁琐细节所困扰，除此之外，宽投还提供了各种专业的策略回测图表报告，为您能研究更多更有效的策略而服务。', hide: 1 },
                            // { tit: '按日回测和分钟回测的区别', hide: 1 },
                            // { tit: '按日回测: 每天调用一次数据, 数据参数是上一个交易日的数据', hide: 1 },
                            // { tit: '分钟回测: 每分钟调用一次数据, 数据参数是上一分钟的数据', hide: 1 },
                            // { tit: '频率：日', hide: 1 },
                            // { tit: '当选择日频率时，算法在每根日线 Bar 都会运行一次，即每天运行一次', hide: 1 },
                            // { tit: '在算法中，可以获取任何粒度的数据', hide: 1 },
                            // { tit: '日K线(Bar序列)', hide: 1 }
                        ],
                        img: ''
                    },
                    {
                        id: '4-2-2',
                        title: '按日回测和分钟回测的区别',
                        children: [
                            // { tit: '频率：Tick' },
                            // { tit: '当选择 Tick 频率时，每当有一个新 Tick，算法都会被执行一次', hide: 1 },
                            // { tit: '回测可以使用哪些数据', hide: 1 },
                            // { tit: '回测可以免费使用宽投提供的所有数据。回测使用的交易数据有一天的延时，即当天的交易数据在第二天更新后可使用', hide: 1 },
                            // { tit: '延时原因：回测使用的交易数据基于Level-1行情数据，Level-1的完整交易数据需要收盘后才能获得', hide: 1 }
                        ]
                    }
                ]
            }
        ]
    },
    4: {
        id: '5',
        title: 'CrystalTrade 相关',
        children: [
            {
                id: '5-1',
                title: '模拟交易的作用',
                children: [
                    // {
                    //     id: '5-1-1',
                    //     title: '模拟交易的作用',
                    //     isHideTitle: 1,
                    //     children: [
                    //         { tit: '' }
                    //     ]
                    // }
                ]
            },
            {
                id: '5-2',
                title: '模拟交易使用什么数据源',
                children: [
                    // {
                    //     id: '5-2-1',
                    //     title: '模拟交易使用什么数据源',
                    //     isHideTitle: 1,
                    //     children: [
                    //     ]
                    // }
                ]
            },
            {
                id: '5-3',
                title: '如何进行模拟交易',
                children: [
                    // {
                    //     id: '5-3-1',
                    //     title: '如何进行模拟交易',
                    //     isHideTitle: 1,
                    //     children: [

                    //     ],
                    //     img: [src6, src7]
                    // },
                    // {
                    //     id: '5-3-2',
                    //     title: '',
                    //     isHideTitle: 1,
                    //     children: [

                    //     ],
                    //     img: src8
                    // },
                    // {
                    //     id: '5-3-3',
                    //     title: '',
                    //     isHideTitle: 1,
                    //     children: [
                    //     ],
                    //     img: src9
                    // }
                ]
            },
            {
                id: '5-4',
                title: '我可以实盘交易吗',
                children: [
                    // {
                    //     id: '5-4-1',
                    //     title: '我可以实盘交易吗',
                    //     isHideTitle: 1,
                    //     children: [
                    //     ]
                    // }
                ]
            }
        ]
    }
}

export default help_side_cn;