import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../helpers/history';

// 引入 ECharts 主模块
import echarts from 'echarts/lib/echarts';
import 'echarts-gl';

// 引入柱状图
import "echarts/lib/chart/bar";

// 引入提示框和标题组件
import "echarts/lib/component/tooltip";
import 'echarts/lib/component/title';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/scatter';//effectScatter
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/component/axisPointer';
import 'echarts/lib/component/markArea';
import 'echarts/lib/component/legendScroll';

import { Layout, Tabs, Menu, Table, PageHeader, Icon, Divider, message, Spin } from 'antd';
import { getChartOption } from './chartOptions/index';
import { PageTitle } from '../../components';
import emitter from '../Evt.js';

const { Content, Sider } = Layout;
const TabPane = Tabs.TabPane;

var languageMessages = window.appLocale.messages;

const columns = [
    {
        title: languageMessages['factorName'],
        dataIndex: 'factorName',
        render: (value, row, index) => {
            const obj = {
                children: value,
                props: {},
            };
            if (index === 0) {
                obj.props.rowSpan = 4;
            } else {
                obj.props.rowSpan = 0;
            }
            return obj;
        },
    }, {
        title: languageMessages['periodWise'],
        dataIndex: 'periodWise'
    }, {
        title: languageMessages['totalRet'],
        dataIndex: 'totalRet',
        render: totalRet => (
            <span>
                {totalRet}%
            </span>
        )
    }, {
        title: languageMessages['sharpeRatio'],
        dataIndex: 'sharpeRatio'
    }, {
        title: languageMessages['annAlpha'],
        dataIndex: 'annAlpha'
    }, {
        title: languageMessages['beta'],
        dataIndex: 'beta'
    }
];

const monotonicColumns = [
    {
        title: '',
        dataIndex: 'monotonic'
    }, {
        title: '1D',
        dataIndex: '1D',
        render: (text) => {
            if (text) {
                return <span style={{ color: "red" }}>{languageMessages['have']}</span>;
            } else {
                return <span>{languageMessages['not']}</span>;
            }
        },
    }, {
        title: '3D',
        dataIndex: '3D',
        render: (text) => {
            if (text) {
                return <span style={{ color: "red" }}>{languageMessages['have']}</span>;
            } else {
                return <span>{languageMessages['not']}</span>;
            }
        },
    }, {
        title: '5D',
        dataIndex: '5D',
        render: (text) => {
            if (text) {
                return <span style={{ color: "red" }}>{languageMessages['have']}</span>;
            } else {
                return <span>{languageMessages['not']}</span>;
            }
        },
    }, {
        title: '7D',
        dataIndex: '7D',
        render: (text) => {
            if (text) {
                return <span style={{ color: "red" }}>{languageMessages['have']}</span>;
            } else {
                return <span>{languageMessages['not']}</span>;
            }
        },
    }
];

const MonotonicBySectorColumns = [
    {
        title: languageMessages['industry.name'],
        dataIndex: 'sectorName'
    }, {
        title: languageMessages['monotonic(period)'],
        children: [
            {
                title: '1D',
                dataIndex: '1D',
                render: (text) => {
                    if (text) {
                        return <span style={{ color: "red" }}>{languageMessages['have']}</span>;
                    } else {
                        return <span>{languageMessages['not']}</span>;
                    }
                },
            }, {
                title: '3D',
                dataIndex: '3D',
                render: (text) => {
                    if (text) {
                        return <span style={{ color: "red" }}>{languageMessages['have']}</span>;
                    } else {
                        return <span>{languageMessages['not']}</span>;
                    }
                },
            }, {
                title: '5D',
                dataIndex: '5D',
                render: (text) => {
                    if (text) {
                        return <span style={{ color: "red" }}>{languageMessages['have']}</span>;
                    } else {
                        return <span>{languageMessages['not']}</span>;
                    }
                },
            }, {
                title: '7D',
                dataIndex: '7D',
                render: (text) => {
                    if (text) {
                        return <span style={{ color: "red" }}>{languageMessages['have']}</span>;
                    } else {
                        return <span>{languageMessages['not']}</span>;
                    }
                },
            }
        ],
    }
];

const icColumns = [
    {
        title: '',
        dataIndex: 'periodWise'
    }, {
        title: '1D',
        dataIndex: '1D'
    }, {
        title: '3D',
        dataIndex: '3D'
    }, {
        title: '5D',
        dataIndex: '5D'
    }, {
        title: '7D',
        dataIndex: '7D'
    }
]

const turnoverColumns = [
    {
        title: '',
        dataIndex: 'quantileNum'
    }, {
        title: '1D',
        dataIndex: '1D'
    }, {
        title: '3D',
        dataIndex: '3D'
    }, {
        title: '5D',
        dataIndex: '5D'
    }, {
        title: '7D',
        dataIndex: '7D'
    }
]

class FactorAnalyzer extends Component {
    constructor(props) {
        super(props);

        this.channels = {}
        this.charts = {
            "factor-return": {
                "OverView": null,
                "AverageRetByQuantile": null,
                "MonotonicByPeriod": null,
                "AlphaStrength": null,
                "CumRet": null,
                "CumRetByQuantile": null,
                "MonotonicBySector": null,
                "RetBySector": null
            }
        }

        this.IcValueByPeriodChart = null;
        this.IcMeanBySectorChart = null;
        this.TurnoverCurveChart = null;

        var defaultSelectedFactorName = ""
        if (props.factorNames && props.factorNames.length > 0) {
            defaultSelectedFactorName = props.factorNames[0]
        }

        var resultTaskId = sessionStorage.getItem("resultTaskId") || '0';
        this.state = {
            selectedFactorCategory: "factor-return",
            selectedFactorName: defaultSelectedFactorName,
            factorNames: this.props.factorNames,
            dataSource: [],
            resultTaskId: resultTaskId,
            monotonicDataSource: [],
            MonotonicBySectorDataSource: [],
            icDataSource: [],
            turnoverDataSource: [],

            spinLoadding: true,
        }
    }

    componentDidMount() {
        emitter.addListener('initPage', () => {
            this.init();
        });
    }

    componentWillMount() {
        this.init();
    }
    
    init() {// 左侧因子
        const { resultTaskId } = this.state

        var user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        if (user.username === undefined) {
            this.setState({ spinLoadding: false }, () => {
                this.props.dispatch({ type: "action_non_user" });
            });
        } else {
            if (resultTaskId > 0) {
                this.props.dispatch({ type: "action_get_factor_list", payload: { UserName: user.username, TaskId: resultTaskId } })
            }
        }
    }

    componentWillReceiveProps(props) {
        const { status, state, chartName, factorNames, factorName, factorData } = props;
        const { selectedFactorCategory, resultTaskId } = this.state;
        
        // 将fetch到的数据，装载到相应的option
        if (status === "data_loaded") {
            switch (selectedFactorCategory) {
                case "factor-return":
                    switch (chartName) {
                        case "OverView":
                            const overview = factorData[chartName]
                            if (overview != null) {
                                var data = [];
                                var index = 1000;
                                for (var ii in overview) {
                                    var tmp = {
                                        key: index,
                                        factorName: overview[ii].factorName,
                                        periodWise: overview[ii].periodWise,
                                        totalRet: overview[ii].totalRet,
                                        sharpeRatio: overview[ii].sharpeRatio,
                                        annAlpha: overview[ii].annAlpha,
                                        beta: overview[ii].beta
                                    };
                                    data.push(tmp);

                                    index++
                                }
                                this.setState({ dataSource: data })
                            }
                            break;
                        case "CumRetByQuantile":
                            this.charts["factor-return"][chartName] = {}
                            const labelItem = Object.keys(factorData[chartName])

                            for (let i = 0; i < labelItem.length; i++) {
                                if (labelItem[i] === "x") continue

                                this.charts["factor-return"][chartName][labelItem[i]] = echarts.init(document.getElementById(chartName + "-" + labelItem[i]));
                                this.charts["factor-return"][chartName][labelItem[i]].setOption(getChartOption(chartName, factorData, labelItem[i]));
                            }
                            break;
                        case "RetBySector":
                            this.charts["factor-return"][chartName] = {}
                            const nameList = Object.keys(factorData[chartName])

                            let pdiv = document.getElementById(chartName)
                            pdiv.innerHTML = ""
                            for (let i = 0; i < nameList.length; i++) {
                                if (nameList[i] === 'x') continue

                                let div = document.createElement("div");//创建一个div元素
                                div.style.width = "50%"
                                div.style.height = "450px"

                                div.setAttribute("id", chartName + "-" + i)
                                pdiv.appendChild(div)

                                this.charts["factor-return"][chartName][nameList[i]] = echarts.init(document.getElementById(chartName + "-" + i));
                                this.charts["factor-return"][chartName][nameList[i]].setOption(getChartOption(chartName, factorData, nameList[i]));
                            }
                            break;
                        case "MonotonicByPeriod":
                            const MonotonicItem = factorData[chartName]

                            if (MonotonicItem != null) {
                                var data = [];

                                var tmp = {
                                    key: 1,
                                    monotonic: languageMessages['monotonic']
                                };

                                for (var kk in MonotonicItem) {
                                    for (var sk in MonotonicItem[kk]) {
                                        tmp[sk] = MonotonicItem[kk][sk];
                                    }
                                }

                                data.push(tmp);
                                this.setState({ monotonicDataSource: data })
                            }
                            break;
                        case "MonotonicBySector":
                            const MonotonicBySectorItem = factorData[chartName]

                            if (MonotonicBySectorItem != null) {
                                var data = [];
                                var index = 10;
                                for (var kk in MonotonicBySectorItem) {
                                    var tmp = {
                                        key: index,
                                        sectorName: kk
                                    };

                                    for (var sk in MonotonicBySectorItem[kk]) {
                                        for (var jj in MonotonicBySectorItem[kk][sk]) {
                                            tmp[jj] = MonotonicBySectorItem[kk][sk][jj];
                                        }
                                    }

                                    data.push(tmp);

                                    index++;
                                }
                                this.setState({ MonotonicBySectorDataSource: data })
                            }
                            break;
                        default:
                            this.charts["factor-return"][chartName] = echarts.init(document.getElementById(chartName));
                            this.charts["factor-return"][chartName].setOption(getChartOption(chartName, factorData));
                            break;
                    }
                    break;
                case "factor-indicate":
                    if (factorData.IcValueByPeriodItem != null) {
                        this.IcValueByPeriodChart = echarts.init(document.getElementById("IcValueByPeriod"));
                        this.IcValueByPeriodChart.setOption(getChartOption("IcValueByPeriod", factorData.IcValueByPeriodItem));
                    }

                    if (factorData.IcStatsItem != null) {
                        var dd = [];
                        var index = 2000;
                        for (var kk in factorData.IcStatsItem) {
                            var tmp = {
                                key: index,
                                periodWise: kk,
                            };
                            for (var sk in factorData.IcStatsItem[kk]) {
                                for (var jj in factorData.IcStatsItem[kk][sk]) {
                                    tmp[jj] = factorData.IcStatsItem[kk][sk][jj];
                                }
                            }
                            dd.push(tmp);
                            index++;
                        }
                        this.setState({ icDataSource: dd })
                    }

                    if (factorData.IcMeanBySectorItem != null) {
                        this.IcMeanBySectorChart = echarts.init(document.getElementById("IcMeanBySector"));
                        this.IcMeanBySectorChart.setOption(getChartOption("IcMeanBySector", factorData.IcMeanBySectorItem));
                    }

                    break;
                case "factor-turnover":
                    if (factorData.FactorMeanOutPctItem != null) {
                        var dd = [];
                        var index = 3000;
                        for (var kk in factorData.FactorMeanOutPctItem) {
                            var tmp = {
                                key: index,
                                quantileNum: kk,
                            };
                            for (var sk in factorData.FactorMeanOutPctItem[kk]) {
                                for (var jj in factorData.FactorMeanOutPctItem[kk][sk]) {
                                    tmp[jj] = factorData.FactorMeanOutPctItem[kk][sk][jj];
                                }
                            }
                            dd.push(tmp);
                            index++;
                        }

                        this.setState({ turnoverDataSource: dd })
                    }
                    if (factorData.FactorRankingCorrItem != null) {
                        this.TurnoverCurveChart = echarts.init(document.getElementById("TurnoverCurve"));
                        this.TurnoverCurveChart.setOption(getChartOption("TurnoverCurve", factorData.FactorRankingCorrItem));
                    }
                    break;
                default:
                    break;
            }
            this.channels[factorName][selectedFactorCategory] = true;

            /**
            var _this = this;
            window.onresize = function () {
                if (_this.charts["factor-return"]["AverageRetByQuantile"] != null)
                    _this.charts["factor-return"]["AverageRetByQuantile"].resize();
                if (_this.charts["factor-return"]["AlphaStrength"] != null)
                    _this.charts["factor-return"]["AlphaStrength"].resize();
                if (_this.charts["factor-return"]["CumRet"] != null)
                    _this.charts["factor-return"]["CumRet"].resize();

                if (_this.IcValueByPeriodChart != null) _this.IcValueByPeriodChart.resize();
                if (_this.IcMeanBySectorChart != null) _this.IcMeanBySectorChart.resize();

                if (_this.TurnoverCurveChart != null) _this.TurnoverCurveChart.resize();
            }
            */

            //发出事件，表明数据已经渲染到前端
            //props.dispatch({ type: "empty_status" })

            this.setState({ spinLoadding: false });
        } else if (status === "factor_list_loaded") {
            if (factorNames == null) {
                this.setState({ factorNames: [], selectedFactorName: '', spinLoadding: false })
            } else {
                this.setState({
                    factorNames,
                    selectedFactorName: factorNames[0]
                });

                var chartNameList = Object.keys(this.charts["factor-return"])
                props.dispatch({ type: "action_get_factor_return", payload: { taskId: resultTaskId, chartNames: chartNameList, factorName: factorNames[0] } })

                this.channels[factorNames[0]] = {};
            }
            //props.dispatch({ type: "empty_status" })
        } else if (status === "factor_list_failed") {
            var error = languageMessages['get.data.failed'];
            switch (state) {
                case 0:
                    break;
                case 2:
                    error = languageMessages['user.exception'];
                    break;
                default:
                    break;
            }
            message.error(error);
            this.setState({ spinLoadding: false });
        }
    }

    renderTabContent = (selectedKey) => {
        switch (selectedKey) {
            case "factor-return":
                const { dataSource, monotonicDataSource, MonotonicBySectorDataSource } = this.state
                var t1 = (<div><p>各分位数平均收益：</p>
                    <p>图形说明：把每个交易日的因子值分成5个分位（分位值与因子值成正比），然后把所有交易日相同分位进行group，计算该group中的平均收益。</p>
                    <p>注：当股票因子值和其对应forward return成负相关时，quantile_5中的平均收益反而比quantile_1的平均收益低，这是正常现象。</p>
                    <p>呈单调性的数据，则表明因子有效。</p></div>)
                var t2 = (<div><p>因子Alpha强度：</p>
                    <p>图形说明：把每个交易日的因子值分成5个分位（分位值与因子值成正比），同时计算quantile_5中股票的平均收益与quantile_1中股票的平均收益之差，然后将该序列进行构图。</p>
                    <p>注：因为分位值是按因子值进行quantile的，当因子值大的股票对应的forward return反而小时，该图就会出现负数，这是正常现象。</p>
                    <p>始终在0轴之上或之下，同时绝对值越大越好，则表明因子有效。</p></div>);
                var t3 = (<div><p>因子累计收益：</p>
                    <p>图形说明：计算每日持仓的累计收益。</p>
                    <p>累计收益越大越好，则表明因子有效。</p></div>);
                var t4 = (<div><p>分位分组累计累计收益：</p>
                    <p>图形说明：考察 1D、3D、5D、7D forward return下，因子在各分位下的累计收益。</p>
                    <p>注：quantile5的累计收益并不一定会比quantile1的累计收益表现要好。</p></div>);
                var t5 = (<div><p>行业内分组收益对比:</p>
                    <p>目的：显示因子在不同行业中的表现，考察因子在不同行业中的有效性。对于不同的行业，计算用因子分位数选股构建组合得到的平均超额收益；直方图中不同的颜色代表不同的调仓周期。</p>
                    <p>图形说明：把每个交易日的因子值分成5个分位，然后把所有交易日的相同分位group，同时在该group中按sector进行group，最后计算该group中的平均收益。</p>
                    <p>注：该指标能观察因子在哪个行业更有效。</p>
                    <p>呈现单调性的数据，则表明因子有效。</p></div>);
                return (<div>
                    <PageTitle title="分析总览" position="sub" style={{ marginTop: "20px", marginBottom: "20px" }}></PageTitle>
                    <Table bordered size='small' locale={{ emptyText: ' ' }} columns={columns} dataSource={dataSource} pagination={false} />

                    <PageTitle title="各分位数平均收益" position="sub" circle={t1} style={{ marginTop: "20px" }}></PageTitle>
                    <div id="AverageRetByQuantile" style={{ height: "450px", marginTop: "20px" }} > </div>
                    <div><b>各分位数平均收益图</b>：判断因子的单调性。单调性越明显的因子，alpha属性越明显。上图表示持仓1、3、5、7天后，各分位数可以获得的平均收益。从图中可以看出该因子的单调性(Monotonic)如下：
                        <Table bordered size='small' locale={{ emptyText: ' ' }} dataSource={monotonicDataSource} columns={monotonicColumns} pagination={false} />
                    </div>

                    <PageTitle title="因子Alpha强度" position="sub" circle={t2} style={{ marginTop: "20px" }}></PageTitle>
                    <div id="AlphaStrength" style={{ height: "450px", marginTop: "20px" }} > </div>

                    <PageTitle title="因子累计收益" position="sub" circle={t3} style={{ marginTop: "20px" }}></PageTitle>
                    <div id="CumRet" style={{ height: "450px" }} > </div>
                    <div><b>因子累计收益曲线</b>：显示组合净值收益曲线。</div>

                    <PageTitle title="分位分组累计收益" position="sub" circle={t4} style={{ marginTop: "20px" }}></PageTitle>
                    <div id="CumRetByQuantile" style={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }} >
                        <div id="CumRetByQuantile-1D" style={{ width: "50%", height: "400px" }} ></div>
                        <div id="CumRetByQuantile-3D" style={{ width: "50%", height: "400px" }} ></div>
                        <div id="CumRetByQuantile-5D" style={{ width: "50%", height: "400px" }} ></div>
                        <div id="CumRetByQuantile-7D" style={{ width: "50%", height: "400px" }} ></div>
                    </div>
                    
                    <PageTitle title="行业内分组收益对比" position="sub" circle={t5} style={{ marginTop: "20px" }}></PageTitle>
                    <div style={{ marginTop: "20px" }}><b>行业内因子单调性对比表</b>：显示因子在不同行业中是否有单调性（Monotonic) 。单调性越好，表明该行业的 alpha 属性越强。从下表中可以看出该因子的单调性(Monotonic)如下：
                        <Table bordered size='small' dataSource={MonotonicBySectorDataSource} columns={MonotonicBySectorColumns} pagination={false} />
                    </div>
                    <div style={{ marginTop: "20px" }}><b>行业内分组收益对比图</b>：显示因子在不同行业中的表现。
                        <p>上面 '<b>行业内因子单调性对比表</b>' 中的因子是否有单调性是从下面一系列对比图中得出的结果。单调性越好，表明该行业的 alpha 属性越强。</p>
                    </div>
                    <div id="RetBySector" style={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }} > </div>
                </div>)
                break;
            case "factor-indicate":
                const { icDataSource } = this.state
                var t1 = (<div><p>{languageMessages['ic.analysis']}：</p>
                    <p>目的：信息系数（IC) 衡量的是因子预测股票收益的能力。</p>
                    <p>图形说明：按照不同周期的forward return，将每天的IC值进行展示。</p>
                    <p>始终在0轴之上或之下，同时绝对值越大越好的数据，则表明因子有效。</p></div>);
                var t2 = (<div><p>行业IC分析：</p>
                    <p>目的：分行业的IC均值作图，可以显示该因子在不同行业里的表现，在哪些行业里表现会比较均衡；不同的颜色代表不同的调仓周期。</p>
                    <p>图形说明：将行业进行group，同时计算该行业平均ic值。</p></div>);
                var t3 = (<div><p>因子IC统计分析：</p>
                    <p>目的：从统计学角度考察IC的稳定性。</p>
                    <p>表格说明：对IC序列做t-test，对应的Null hypothesis是population mean等于0，即如果求出来的P-value小于诸如0.05，那么可以reject null hypothesis，即IC值并不等于0，那么说明这个因子是有一定预测能力。</p></div>);
                return (<div>
                    <PageTitle title={languageMessages['ic.analysis']} position="sub" circle={t1} style={{ marginTop: "20px" }}></PageTitle>
                    <div id="IcValueByPeriod" style={{ height: "450px", marginTop: "20px" }} > </div>

                    <PageTitle title="行业IC分析" position="sub" circle={t2} style={{ marginTop: "20px" }}></PageTitle>
                    <div id="IcMeanBySector" style={{ height: "450px", marginTop: "20px" }} > </div>
                    <div><b>行业IC分析</b>：分行业的IC均值作图，可以显示该因子在不同行业里的表现；不同的颜色代表不同的调仓周期。</div>

                    <PageTitle title="因子IC统计分析" position="sub" circle={t3} style={{ marginTop: "20px", marginBottom: "20px" }}></PageTitle>
                    <Table bordered size='small' dataSource={icDataSource} columns={icColumns} pagination={false} />
                    <div><b>因子排序自相关分析</b>：从统计学角度考察IC的稳定性。</div>
                </div>)
                break;
            case "factor-turnover":
                const { turnoverDataSource } = this.state
                var t1 = (<div><p>因子值自相关性：</p>
                    <p>目的：通过计算每个交易周期的autocorrelation(rank(factor_values))来衡量换手率。</p>
                    <p>图形说明：计算每交易日因子的自相关性。具体地autocorrelation(rank(factor_values))，以5D为例，auto_corr_of_t5 = corr(rank(factor_values_t0), rank(factor_values_t5))。</p>
                    <p>值越大越好，说明每天的调仓数小，则表明因子有效。</p></div>);
                var t2 = (<div><p>组合调出率：</p>
                    <p>目的：通过每个不同交易周期的不同quantile的股票调出率来衡量换手率。</p>
                    <p>表格说明：按因子值以每个交易日为单位进行5分位，然后计算每分位中的平均调出率。调出率计算公式：today_turnover_ratio = len(set(yesterday_symbols)-set(today_symbols))/len(set(yesterday_symbols))。</p>
                    <p>值越小越好，说明每天的调仓数小，则表明因子有效。</p></div>)
                return (<div>
                    <PageTitle title="因子值自相关性" position="sub" circle={t1} style={{ marginTop: "20px", marginBottom: "20px" }}></PageTitle>
                    <div id="TurnoverCurve" style={{ height: "450px", marginTop: "20px" }} > </div>
                    <div><b>{languageMessages['turnover.analysis']}</b>：通过计算每个交易周期的autocorrelation(rank(factor_values))来衡量换手率。</div>

                    <PageTitle title="组合调出率" position="sub" circle={t2} style={{ marginTop: "20px", marginBottom: "20px" }}></PageTitle>
                    <Table bordered size='small' dataSource={turnoverDataSource} columns={turnoverColumns} pagination={false} />
                    <div><b>组合调出率</b>：通过每个不同交易周期的不同quantile的股票调出率来衡量换手率。
                        <p>表格中所对应值分别为：每1天调一次仓的平均调出率；每3天调一次仓的平均调出率；每5天调一次仓的平均调出率；每7天调一次仓的平均调出率。</p>
                        <p>如换算成换手率值，则需对不同调仓期的值除以持仓期，即可得到每天的换手率，如：3D所对应的调仓率换算成换手率，则需把其对应的调仓率除以3。</p>
                    </div>
                </div>)
                break;
            default:
                break;
        }
    }

    onSelectFactorCategory = (key) => {//key 为 tab 名称
        const { resultTaskId } = this.state

        if (resultTaskId > 0) {
            const { selectedFactorName } = this.state
            this.setState({ selectedFactorCategory: key })

            if (!this.channels.hasOwnProperty(selectedFactorName)) this.channels[selectedFactorName] = {};

            if (this.channels[selectedFactorName].hasOwnProperty(key)) {
                this.props.dispatch({ type: "empty_status" })
            } else {
                switch (key) {
                    case "factor-return":
                        var chartNameList = Object.keys(this.charts["factor-return"])
                        this.props.dispatch({ type: "action_get_factor_return", payload: { taskId: resultTaskId, chartNames: chartNameList, factorName: this.state.selectedFactorName || this.state.factorNames[0] } })
                        break;
                    case "factor-indicate":
                        this.props.dispatch({ type: "action_get_factor_indicate", payload: { taskId: resultTaskId, factorName: this.state.selectedFactorName || this.state.factorNames[0] } })
                        break;
                    case "factor-turnover":
                        this.props.dispatch({ type: "action_get_factor_turnover", payload: { taskId: resultTaskId, factorName: this.state.selectedFactorName || this.state.factorNames[0] } })
                        break;
                    default:
                        break;
                }
            }
        }
    }

    onSelectFactorName = ({ key }) => {
        this.channels = {}
        this.setState({ selectedFactorName: key }, () => this.onSelectFactorCategory(this.state.selectedFactorCategory))
    }

    onBack = e => {
        e.preventDefault();
        history.goBack();
    }

    render() {
        let { selectedFactorName, factorNames } = this.state

        return (<Content className='main-container'>
            <Spin spinning={this.state.spinLoadding}>
                <div className='common-page-header'>
                    <PageHeader onBack={this.onBack} backIcon={(<Icon type="left" />)}
                        title={(<span style={{ cursor: 'pointer' }} onClick={this.onBack}>{languageMessages['factor.analysis']}</span>)} />
                </div>
                <Layout className='main-content'>
                    <Sider width={200}>
                        <PageHeader className='common-sider-header' title={languageMessages['factorName']} />
                        <Divider />
                        <div className="common-sider-content">
                            {factorNames != null && (<Menu mode="inline" inlineIndent={50}
                                selectedKeys={[selectedFactorName]}
                                onSelect={this.onSelectFactorName} >
                                {factorNames.map((item => <Menu.Item key={item}>{item}</Menu.Item>))}
                            </Menu>)}
                        </div>
                    </Sider>
                    <Layout className='common-content'>
                        <div className="card-container factor-analyzer">
                            <Tabs type="card" defaultActiveKey="factor-return" onChange={this.onSelectFactorCategory}>
                                <TabPane tab={<span>{languageMessages['rate.of.return.analysis']}</span>} key="factor-return">
                                    {this.renderTabContent("factor-return")}
                                </TabPane>
                                <TabPane tab={<span>{languageMessages['ic.analysis']}</span>} key="factor-indicate">
                                    {this.renderTabContent("factor-indicate")}
                                </TabPane>
                                <TabPane tab={<span>{languageMessages['turnover.analysis']}</span>} key="factor-turnover">
                                    {this.renderTabContent("factor-turnover")}
                                </TabPane>
                            </Tabs>
                        </div>
                    </Layout>
                </Layout>
            </Spin>
        </Content>);
    }
}
        
function mapStateToProps(state) {
    return state.factor;
}

FactorAnalyzer.defaultProps = {
    factorNames: []
}

const page = connect(mapStateToProps)(FactorAnalyzer);

export { page as default }