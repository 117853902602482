import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../helpers/history';
import { DatePicker, Modal, Select, Button, message } from 'antd';
import moment from 'moment';

import TooltipPage from '../../pannels/account/TooltipPage';

const { RangePicker } = DatePicker;
const Option = Select.Option;

const dateFormat = 'YYYYMMDD';

var languageMessages = window.appLocale.messages;

class FactorGeneratorPage extends Component {
    constructor(props) {
        super(props);

        this.factorGeneratorTimer = null;

        var startDate = moment().quarter(moment().quarter() - 1).startOf('quarter').format(dateFormat);
        var endDate = moment().subtract(20, 'days').format(dateFormat);

        this.user = {};
        this.state = {
            "startdate": startDate,
            "enddate": endDate,
            "period": 'D',

            gLastTimes: 0,

            generatorButtonTxt: languageMessages['generating.factor.file'],
            generatorStatus: false,
            generatorWait: false,

            generatorError: '',
        }
    }

    componentWillMount() {
        this.user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        if (this.user.permission !== undefined) {
            this.setUserPermission(this.user.permission);

            var generatorButtonTxt = languageMessages['generating.factor.file'];
            var generatorStatus = false;
            var generatorFactor = JSON.parse(sessionStorage.getItem("GeneratorFactorCache") || '{}') || {};
            if (generatorFactor.generatorWait !== undefined) {
                generatorStatus = true;
                generatorButtonTxt = languageMessages['generating'];

                this.props.factorGenerator({ generatorStatus: true });
            }
            this.setState({
                generatorStatus: generatorStatus,
                generatorButtonTxt: generatorButtonTxt
            });
        }
    }

    setUserPermission(permissions) {
        var gLastTimes = 0;
        for (var key in permissions) {
            var permission = permissions[key];
            switch (permission.type) {
                case 'g':
                    gLastTimes += permission.totalCount - permission.usageCount;
                    break;
            }
        }
        this.setState({ gLastTimes });
    }

    componentDidUpdate() {
        var generatorWait = false;
        var generatorFactor = JSON.parse(sessionStorage.getItem("GeneratorFactorCache") || '{}') || {};
        if (generatorFactor.generatorWait !== undefined) {
            generatorWait = generatorFactor.generatorWait;
        } else {
            generatorWait = this.state.generatorWait;
        }
        if (!this.factorGeneratorTimer && generatorWait) {
            this.factorGeneratorTimer = setInterval(this.queryGenerateStatus, 10000);
        }
    }

    componentWillReceiveProps(props) {
        var { status, state, taskName, permission, lastTimes, currDate } = props;

        switch (status) {
            case 'get_factor_filters_success':
                if (currDate !== undefined) {
                    var enddate = moment(currDate).format(dateFormat);
                    this.setState({ enddate });
                }
                break;
            case 'commit':
                this.setState({ generatorButtonTxt: languageMessages['submitting'] });
                break;
            case 'ready':
                var generatorWait = true;
                this.setState({ generatorButtonTxt: languageMessages['ready.generate'], generatorWait: generatorWait });

                sessionStorage.setItem("GeneratorFactorCache", JSON.stringify({ taskName: taskName, generatorWait: generatorWait }));
                break;
            case 'working':
                this.setState({ generatorButtonTxt: languageMessages['generating'] });
                break;
            case 'finished':
                if (this.factorGeneratorTimer) {
                    clearInterval(this.factorGeneratorTimer)
                    this.factorGeneratorTimer = null
                }
                
                this.user.permission = permission;
                sessionStorage.setItem("auth", JSON.stringify(this.user));
                this.setUserPermission(this.user.permission);

                this.setState({
                    generatorButtonTxt: languageMessages['generating.factor.file'],
                    generatorStatus: false,
                    generatorWait: false,
                    gLastTimes: lastTimes,
                    generatorError: '',
                });

                sessionStorage.removeItem("GeneratorFactorCache");
                break;
            case 'error':
                if (this.factorGeneratorTimer) {
                    clearInterval(this.factorGeneratorTimer)
                    this.factorGeneratorTimer = null
                }

                var error = '';
                switch (state) {
                    case 0:// 生成异常
                        error = languageMessages['build.failed'];
                        break;
                    case 3:// 用户异常
                        error = languageMessages['user.exception'];
                        break;
                    case 4:// 点数不够
                        error = languageMessages['factor.generation.has.run.out'];
                        break;
                }

                this.setState({
                    generatorButtonTxt: languageMessages['generating.factor.file'],
                    generatorStatus: false,
                    generatorWait: false,
                    generatorError: error,
                });

                sessionStorage.removeItem("GeneratorFactorCache");
                break;
        }
    }

    componentWillUnmount() {
        if (this.factorGeneratorTimer) {
            clearInterval(this.factorGeneratorTimer)
            this.factorGeneratorTimer = null
        }
    }

    disabledDate = (current) => {
        return current && current > moment(this.props.currDate, "YYYY-MM-DD");
    }

    onDateChange = (dates, dateStrings) => {
        this.setState({ startdate: dateStrings[0], enddate: dateStrings[1] })
    }

    handlePeriodChange = (value) => {
        this.setState({ period: value.key })
    }

    onValueAddedServices = e => {
        e.preventDefault();
        Modal.destroyAll();
        
        history.push({ pathname: '/member', state: { memberMenuSelectKey: "2" } });
    }

    // 生成因子
    handleGeneratorFactorFile = () => {
        if (this.user.username === undefined) {
            this.props.dispatch({ type: "action_non_user" });
        } else {
            if (this.state.gLastTimes <= 0) {
                var content = <span>{languageMessages['factor.generation.has.run.out']}，{languageMessages['please go']}<a onClick={this.onValueAddedServices}>{languageMessages['value-added.services']}</a></span>;
                Modal.info({
                    title: languageMessages['prompt'],
                    content: content
                });
                return;
            }

            if (this.props.scanFilterStatus) {
                message.error(languageMessages['please.complete.factor.scan']);
                return;
            }

            this.setState({ generatorStatus: true }, () => {
                if (this.props.filter.filterName !== undefined) {
                    this.props.factorGenerator({ generatorStatus: true });

                    let feature = {
                        UserName: this.user.username,
                        FilterName: this.props.filter.filterName,
                        StartDate: this.state.startdate,
                        EndDate: this.state.enddate,
                        Period: this.state.period
                    }
                    this.props.dispatch({ type: "action_factor_file_generate", payload: feature });
                }
            });
        }
    }

    queryGenerateStatus = () => {
        if (this.factorGeneratorTimer) {
            var taskName = '';
            var generatorFactor = JSON.parse(sessionStorage.getItem("GeneratorFactorCache") || '{}') || {};
            if (generatorFactor.taskName !== undefined && generatorFactor.generatorWait !== undefined) {
                taskName = generatorFactor.taskName;
            } else {
                taskName = this.props.taskName;
            }
            if (taskName !== '') {
                this.props.dispatch({ type: "action_factor_file_generate_query", payload: taskName });
            }
        }
    }

    onDownload = () => {
        window.location.href = '/api/factor/download?taskname=' + this.props.taskName;
    }

    render() {
        var { startdate, enddate } = this.state;

        return (<React.Fragment>
            <div className='row-container' style={{ justifyContent: 'flex-start' }}>
                <div>
                    <RangePicker size='small' style={{ width: 200 }}
                        value={[moment(startdate, dateFormat), moment(enddate, dateFormat)]}
                        disabledDate={this.disabledDate}
                        format={dateFormat}
                        onChange={this.onDateChange} />
                </div>
                <div>
                    <label style={{ margin: "0 10px" }}> {languageMessages['data.frequency']} </label>
                    <Select size='small' labelInValue defaultValue={{ key: 'D' }} style={{ width: 120 }}
                        onChange={this.handlePeriodChange}>
                        <Option value="D">{languageMessages['dailyLine']}</Option>
                        <Option value="60">{languageMessages['60minute.line']}</Option>
                    </Select>
                </div>
            </div>
            <div style={{ padding: '10px 0', lineHeight: '32px' }}>
                <Button size='small' onClick={this.handleGeneratorFactorFile} loading={this.state.generatorStatus} disabled={this.state.generatorStatus || this.props.filterString === ''}>
                    {this.state.generatorButtonTxt}
                </Button>
                <TooltipPage lastTimes={this.state.gLastTimes} filterError={this.state.generatorError} />
                {this.props.status === "finished" && <span style={{ padding: '0px 20px' }}><span style={{ lineHeight: "32px", color: 'green' }}>{languageMessages['generated.complete']}</span> <a className='btn' onClick={this.onDownload} style={{ lineHeight: "32px" }}>{languageMessages['intl.menu.download']}</a></span>}
            </div>
        </React.Fragment>)
    }
}

function mapStateToProps(state) {
    return state.factor;
}

const page = connect(mapStateToProps)(FactorGeneratorPage);
export { page as FactorGenerator }