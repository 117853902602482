//末尾不要带‘/’

import store from './store'
import history from './history'

import 'whatwg-fetch'

const host='api'

//在默认基础上，定制化生成header，主要是提取客户端token
function requestHeader(header = null) {
    // return authorization header with jwt token
    const token = store.getState().member.token;

    let customHeader = { 'Accept': '*/*', 'Content-Type': 'application/json;charset=utf-8' }
    if (header !== null)
    {
		customHeader = header
	}
	
    if (token) {
        return { ...customHeader, 'Authorization': 'Token ' + token };
    } else {
        return { ...customHeader, 'Authorization': 'Token ' + "ForTest*" };
    }
}

//使用type指定图片服务器、文件服务器等，然后动态生成请求路径
function requestURL(link, type = null) {
	return host + link
}

function getRows(url) {
    /*
    if(refresh==null){
   let data = JSON.parse(sessionStorage.getItem(url));
  if (data && data.length>0){return Promise.resolve(data);}
    }
    */
    const requestOptions = {
        method: 'GET',
        headers: requestHeader(),
    };
    
    return fetch(requestURL(url), requestOptions).then(handleResponse).catch(handleErrorResponse);
}

function get(url, header = null) {
    if(url.indexOf('?') > -1){
        url += '&t=' + Math.random();
    } else {
        url += '?t=' + Math.random();
    }

    url = encodeURI(url);
    if (url.substr(0, 4) === "http") {
        return fetch(url, { cache: 'no-cache' }).then(handleResponse).then(data => {
            return data
        }).catch(handleErrorResponse);
    }
	const requestOptions = {
		method: 'GET',
        headers: requestHeader(header),
        cache: 'no-cache'
    };
    
	return fetch(requestURL(url), requestOptions).then(handleResponse).catch(handleErrorResponse).then(data => {
            return data
    }, e => {
        console.log(e);
    });
}

function getRow(url) {
  
/*     if(!refresh){
        let data = JSON.parse(sessionStorage.getItem(url));
        if (data){return Promise.resolve(data);}
    } */
    const requestOptions = {
        method: 'GET',
        headers: requestHeader(),
    };

    return fetch(requestURL(), requestOptions)
        .then(handleResponse).then(
            data=>
            {
             //   sessionStorage.setItem(url, JSON.stringify(data))
                return data}
        ).catch(handleErrorResponse);
}

function postFile(url, file, progressListenner = null, loadedListener = null) {
    let formData  = new FormData();
    formData.append("file", file);
    //formData.append('username', '111111');
    
	var xhr = new XMLHttpRequest();
	//设置xhr请求的超时时间
	// xhr.timeout = 6000000000;
	//设置响应返回的数据格式
    xhr.responseType = "text";

	//创建一个 post 请求，采用异步
    xhr.open('POST', requestURL(url), true);

	//注册相关事件回调处理函数
	xhr.onload = loadedListener;
	xhr.ontimeout = function(e) { console.log("time out") };
	xhr.onerror = function(e) { console.log("error") };
	xhr.upload.onprogress = progressListenner
	
	//发送数据
	xhr.send(formData);
	return
}

function post(url, data, header = null) {
    let requestOptions = {
        method: 'POST',
        headers: requestHeader(header),
        body: JSON.stringify(data),
        cache: 'no-cache'
    };
    
	return fetch(requestURL(url), requestOptions).then(handleResponse).catch(handleErrorResponse);
}

function put(url, data, header = null) {
    const requestOptions = {
        method: 'PUT',
        headers: requestHeader(header),
        body: JSON.stringify(data)
    };
    return fetch(requestURL(url), requestOptions).then(handleResponse).catch(handleErrorResponse);
}

function patch(url, data) {
    const requestOptions = {
        method: 'PATCH',
        headers: requestHeader(),
        body: JSON.stringify(data)
    };
    //   if(url[url.length-1]!='/')url=url+'/';
    return fetch(requestURL(url), requestOptions)
        .then(handleResponse).catch(handleErrorResponse);
}

function _delete(url, data) {
    const requestOptions = {
        method: 'DELETE',
        headers: requestHeader(),
        body: JSON.stringify(data)
    };
    return fetch(requestURL(url), requestOptions)
        .then(handleResponse).catch(handleErrorResponse);
}

function handleResponse(response) {
    if (response.status === 401) {
        store.dispatch({ type: "user_logout" })
        history.replace('/users/login')
        return
    }
    if (!response.ok) {
        return Promise.reject(response.json());
    }
    if (response.status === 204) { return Promise.resolve(response.statusText); }

    return response.json();
}

function handleErrorResponse(response) {
    if (response.status === 401) {
        store.dispatch({ type: "user_logout" })
        history.replace('/users/login')
        return
    }
    return response.json();
}

export { host, requestHeader, getRows, getRow, get, post, postFile, _delete as delete_, put, patch }