import React, { Component } from 'react';
import history from '../../helpers/history';

var languageMessages = window.appLocale.messages;
class TooltipPage extends Component {
    onValueAddedServices = e => {
        e.preventDefault();
        history.push({ pathname: '/member', state: { memberMenuSelectKey: "2" } });
    }

    render() {
        return (<span style={{ color: '#9a9a9a', fontSize: '10px', display: 'inline-block', lineHeight: '14px', verticalAlign: 'bottom', marginLeft: '8px' }}>
            <span style={{color:'red'}}>{this.props.filterError}</span><br />
            ({languageMessages['remaining.times']}：{this.props.lastTimes}{languageMessages['times']}，<a onClick={this.onValueAddedServices}>{languageMessages['value-added.services']}</a>)
    </span>)}
}

export { TooltipPage as default }