import React, { Component } from 'react';
import { Form, Input, Select, Row, Col, Checkbox, Button, Icon, Modal } from 'antd';

import CodeRegExp from './Countrys';

import './flags.css';

const { Option } = Select;

var languageMessages = window.appLocale.messages;

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class RegisterForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            phoneRegExp: '',
            phonePlaceholder: '',

            prefix: 'CN', //window.webType

            visible: false,
        };
    }

    componentDidMount() {
        this.props.form.validateFields();

        var tmpCodeRegExp = CodeRegExp.filter((e) => { return e.des === this.state.prefix });
        if (tmpCodeRegExp.length > 0) {
            var phonePlaceholder = languageMessages['phone.china.only'];
            if (window.webType !== 'CN') {
                phonePlaceholder = languageMessages['phone'];
            }

            var phoneRegExp = tmpCodeRegExp[0].pattern;
            this.setState({
                phoneRegExp,
                phonePlaceholder
            });
        }
    }

    getCaptcha = e => {
        e.preventDefault();
        let { isGetCaptcha } = this.props;
        this.props.form.validateFields(['phone'], (err, values) => {
            if (!err) {
                if (isGetCaptcha) {
                    var prefix = this.props.form.getFieldValue('prefix');

                    //发送手机验证码
                    this.props.dispatch({ type: "action_register_captcha", payload: { UserName: values.phone, Prefix: prefix } });
                }
            }
        });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (values.agreement) {
                    var requestData = {
                        UserName: values.phone,
                        Password: values.password,
                        Captcha: values.captcha,
                        Prefix: values.prefix,
                        PromoCode: values.promoCode,
                        Agreement: values.agreement
                    };

                    this.props.dispatch({ type: "action_register", payload: requestData });
                }
            }
        });
    };

    handlePrefixSelector = value => {
        var tmpCodeRegExp = CodeRegExp.filter((e) => { return e.des === value });
        if (tmpCodeRegExp.length > 0) {
            var phoneRegExp = tmpCodeRegExp[0].pattern;
            this.setState({
                phoneRegExp,
            }, () => {
                this.props.form.validateFields();
            });
        }

        this.props.handleChange({ error: '' });
    }

    handleChange = () => {
        this.props.handleChange({ error: '' });
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    renderProtocol() {
        return <Modal title={languageMessages['protocol']}
            style={{ top: 20 }}
            className='common-protocol-modal'
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
                <Button key="ok" type="primary" onClick={this.handleOk}>{languageMessages['close']}</Button>]}
        >
            <div className='common-protocol-class'>
                <b>一、	协议确认及接受</b>
                <div>1.1	本协议是深圳宽投信息科技有限公司（以下简称“我公司”）与用户就会员服务所订立的协议（以下简称“本协议”）。本协议描述我公司与用户之间关于软件许可、服务使用及相关方面的权利义务。“用户”或“您”是指享受我公司提供的会员服务的个人或单一实体。本协议构成您（无论个人或者单位）使用我公司所提供的会员服务之先决条件，除非您接受本协议的服务条款，否则您无权使用本协议的相关服务。您的使用行为将视为同意接受本协议各项条款的约束。</div>
                <div>1.2	我公司有权随时对服务条款进行修改，一旦服务条款发生变更和修改，我公司将在相关页面上提示修改的内容；如果您不同意本协议的修改，可以取消已经获取的会员服务并停止使用；如果您继续使用我公司提供的会员服务，则视为您已接受本协议的全部修改。</div>
                <div>1.3	请您审阅并接受或不接受本协议，未成年人应在法定监护人陪同下审阅和履行，未成年人行使和履行本协议项下的权利和义务视为已获得了法定监护人的认可，您在享受我公司提供的会员服务时必须完全、严格遵守本协议的服务条款。</div>
                <b>二、	服务说明</b>
                <div>2.1	我公司有对用户网上一切活动的监督、提示、检查的权利，如果用户的行为违反有关法律法规或违反本协议的约定，我公司享有要求其纠正并追究其责任等权利。</div>
                <div>2.2	全部会员服务均仅限于用户在当前平台使用，任何以恶意破解等非法手段将服务内容与平台分离的行为，均不属于本协议中约定的服务。由此引起的一切法律后果由行为人负责，我公司依法追究行为人的法律责任。</div>
                <div>2.3	当您使用各单项特选服务时，您的使用行为视为对其各单项会员服务的服务条款以及我公司在该单项会员服务中发出各类公告的同意。</div>
                <div>2.4	您明确了解并同意，会员服务的付费方式为代收费运营商托收的付款方式，您通过此种付费方式付费可能存在一定的商业风险，包括但不限于不法分子利用账户或银行卡等有价卡等进行违法活动，该风险均会给您造成相应的经济损失。您应自行承担向侵权方追究侵权责任和追究责任不能的后果。</div>
                <div>2.5	您应自行负责妥善地保管、使用、维护申请取得的账户、账户信息及密码。您应对账户信息及密码采取必要且有效的保密措施。非我公司原因致使您的账户密码泄露以及因您保管、使用、维护不当造成损失的，我公司无需承担与此有关的任何责任。</div>
                <div>2.6	我公司不对您因第三方的行为或不作为造成的损失承担任何责任，包括但不限于支付服务和网络接入服务、任意第三方的侵权行为。</div>
                <b>三、	服务开通及查询</b>
                <div>3.1	未来新增的渠道开通会员服务，包括但不限于：第三方支付、网银支付、手机支付等方式。在您使用某种具体方式开通会员服务时，须阅读并确认接受相关的服务条款和使用方法。</div>
                <div>3.2	会员服务的资费标准以本产品中标明的详细资费标准为准，我公司有权基于自身业务发展需要变更资费标准，但我公司会根据实际运营情况对不同阶段已开通会员服务且持续有效的用户给予续费、升级方面的不同资费优惠，具体优惠政策以本产品中相关页面的公告内容为准。</div>
                <div>3.3	一旦您开通会员服务，即视为其认可该项服务标明的价格；会员服务开通成功后，该项服务即使生效。</div>
                <div>3.4	会员服务开通后，您有权利不接受我公司的服务，可申请取消会员服务，但不获得任何已缴纳的服务费用的退还。</div>
                <b>四、	服务期限及权限</b>
                <div>4.1	每项会员服务均有自己单独的服务期限，您一旦开通会员服务即视为认可其服务期限。</div>
                <div>4.2	每项会员服务的服务期限均以相应会员服务介绍页面中标注的期限为准。如果您未在服务期限内使用会员服务，亦将视为已使用，我公司概不退还任何已缴纳的服务费用。</div>
                <div>4.3	您已开通的会员服务使用期限不会因其在使用期间的中断、终止等情况而延长。</div>
                <b>五、	用户权利限制</b>
                <div>5.1	会员服务仅限于开通的账号自行使用，且服务期限内不能在不同账户之间转移，禁止借用、转让或售卖，否则我公司有权在未经通知的情况下取消转让账户、受让账户的会员服务资格，由此带来的损失由用户自行承担，我公司不负任何责任。</div>
                <div>5.2	用户不得以盗窃、利用系统漏洞等非法途径，以及在未获我公司授权的非法平台上获取或开通会员服务，否则我公司有权取消其会员服务资格。有此引发的问题由用户自行承担，我公司不负任何责任。</div>
                <div>5.3	用户不得使用带有非法、淫秽、污辱或人身攻击含义的头像、昵称或个人简介，一经发现，我公司有权取消其会员服务资格而无须给予任何补偿。</div>
                <div>5.4	除非我公司提前书面许可，禁止复制、下载、上传、修改、开发、转让、销售、展示、传播服务内容；禁止合成、嵌套、链接服务内容；禁止利用服务内容进行教学或研究、商业开发或推广；禁止以商业目的使用服务内容；包括但不限于下列情况；</div>
                <div>5.4.1	不得通过非法手段对账户的服务期限、消费金额、抵扣积分、交易状态进行修改，或用非法的方式或为了非法的目的使用已开通的服务内容。</div>
                <div>5.4.2	不得将账户有偿或无偿提供给任何第三方，并允许其通过该账户使用非其开通的服务内容。</div>
                <div>5.4.3	不得将服务内容复制、销售、出租或授权给任何第三方。</div>
                <div>5.4.4	不得主动对我公司用于服务内容的任何安全措施技术进行破解、更改、反操作、破坏或其他篡改，或协助他人进行上述行为。</div>
                <div>5.4.5	不得通过非我公司认可的方式获得服务内容，或者移除、修改任何标注在服务内容上的广告、商标、只是产权或其他专有权声明。</div>
                <div>5.4.6	不得采用收费或免费的方式，全部或部分在任何场合展示服务内容。</div>
                <div>5.4.7	不得损害第三方利益、或通过当前平台收集第三方资料信息、破坏或盗取第三方账户，发送诈骗邮件和垃圾邮件等非法信息，侵犯第三方合法权益，包括但不限于隐私权、知识产权、财产权等。</div>
                <div>5.5	我公司可以单方面判断用户是否违反法律法规规定或违反本协议，并可以单方面选择一项或多项处理方式1）通知2）中断或终止服务3）要求违约方或侵权方赔偿损失4）处以罚款。用户同意一旦发生前述情况，将接受我们公司的决定，并同意赔偿我公司及其他受损害方的损失。</div>
                <b>六、	变更及免责条款</b>
                <div>6.1	我公司对会员服务的使用期限、购买价格保留随时变更的权利，而不需通知用户。对于所有会员服务的中断或终止而造成的任何损失，我公司无需对任何第三方承担任何责任。</div>
                <div>6.2	鉴于网络服务的特殊性，用户同意我公司有权随时变更、中断或终止部分或全部的网络服务。我公司保留随时修改或中断会员服务而不需通知用户的权利，对于所有会员服务的中断或终止而造成的任何损失，我公司无需对任何第三方承担任何责任。</div>
                <div>6.3	用户开通的会员服务使用期限中包含解决故障、服务器维修、调整、升级等所需用的合理时间，对上述情况所需用的时间我公司不予补偿并保留解释权。</div>
                <div>6.4	用户在使用我公司所提供的会员服务时，如果遭受任何人身或财务的损失、损害或伤害，不论原因如何，我公司均不负责任。由于用户将个人密码告知他人或与他人共享注册账户，由此导致的任何个人资料泄露，我公司不负任何责任。</div>
                <b>七、	中断及终止服务</b>
                <div>7.1	对于因服务器死机、服务器维修、网络故障、数据库故障、产品升级调整等问题，或其他不可抗拒的事由，包括但不限于政府行为、自然灾害、黑客攻击等造成的服务中断，和对用户个人数据及资料造成的损失，我公司不承担由此对用户造成的任何损失，并不退还任何已缴纳的服务费用。但我公司应尽可能实现进行通告。</div>
                <div>7.2	如果用户违反或被视为违反本协议中的内容，我公司有权在不通知用户的情况下立即终止用户已开通的所有会员服务，以及取消用户的账户和使用权限，但不退还任何已缴纳的服务费用。</div>
                <div>7.3	我公司未行使或延迟行使其在本协议下的权利并不构成对这些权利的放弃，而单一或部分行使其在本协议下的任何权利并不排斥其任何其他权利的行使。我公司随时有权要求用户继续履行义务并承担相应的违约责任。</div>
                <b>八、	产品风险提示</b>
                <div>8.1	用户应充分认识到证券投资的风险，本产品提供的所有数据与信息，仅供参考使用，用户不应该将其视作具体投资品种选择/买卖时机的建议，或其他任何形式的投资建议，用户应在投资决策前做出理性判断，并自行承担投资风险。</div>
                <div>8.2	证券市场具有较强的风险，本产品作为投资辅助工具，无法规避市场系统性或非系统性风险。本产品对用户进行相关投资原理、方法和理念培训，辅助用户进行自住投资决策，不能保证用户盈利，也不承担用户的投资不遭受损失。</div>
                <b>九、	知识产权声明</b>
                <div>9.1	本产品的一切著作权、商标权、专利权、商业秘密等知识产权，以及相关的所有信息内容均受中华人民共和国法律法规和相应国际条约的保护。</div>
                <div>9.2	用户不得对本产品设计的相关网页、应用、软件等产品进行反向工程、反向汇编、反向编译等。</div>
                <div>9.3	未经我公司或相关权利人授权，用户不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权。</div>
                <b>十、	其他</b>
                <div>10.1	在法律法规允许的范围内，本协议最终解释权贵我公司所有。</div>
                <div>10.2	本协议的订立、执行、解释及争议的解决均应使用中华人民共和国法律并接受中华人民共和国法院管辖。如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向我公司所在地的人民法院提起诉讼。</div>
                <div>10.3	如果本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</div>
    
            </div>
        </Modal>
    }

    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

        var options = [];
        for (var ii in CodeRegExp) {
            var className = 'flag flag-' + CodeRegExp[ii].des.toLowerCase();
            var title = <span className={className}></span>;
            var content = <span>{title} {' +' + CodeRegExp[ii].code}</span>;
            options.push(<Option key={ii} value={CodeRegExp[ii].des} label={content}>{content}</Option>);
        }
        /**
        if (window.webType === 'US') {
            
        } else {
            options.push(<Option key='0' value='CN' label='86'>+86</Option>);
        }
        */
        const prefixSelector = getFieldDecorator('prefix', { initialValue: this.state.prefix })(
            <Select style={{ width: 120 }}
                optionLabelProp='label'
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ zIndex: 4, minWidth: 100 }}
                onChange={this.handlePrefixSelector}>
                {options}
            </Select>,
        );

        var phoneError = isFieldTouched('phone') && getFieldError('phone');
        var captchaError = isFieldTouched('captcha') && getFieldError('captcha');
        var passwordError = isFieldTouched('password') && getFieldError('password');
        var promoCodeError = isFieldTouched('promoCode') && getFieldError('promoCode');

        return (<Form onSubmit={this.handleSubmit} className='register-form'>
            {this.renderProtocol()}
                <Form.Item validateStatus={phoneError ? 'error' : ''} help={phoneError || ''}>
                {getFieldDecorator('phone', {
                    rules: [
                        { required: true, message: languageMessages['please.input.phone'] },
                        { pattern: this.state.phoneRegExp, message: languageMessages['phone.format.failed'] }
                    ],
                })(<Input addonBefore={prefixSelector} style={{ width: '100%' }} placeholder={this.state.phonePlaceholder} onChange={this.handleChange} autoComplete={'off'} />)}
                </Form.Item>
                <Form.Item validateStatus={captchaError ? 'error' : ''} help={captchaError || ''}>
                    <Row gutter={8}>
                        <Col span={13}>
                        {getFieldDecorator('captcha', {
                            rules: [{ required: true, message: languageMessages['please.input.captcha'] }],
                        })(<Input placeholder={languageMessages['phone.captcha']} onChange={this.handleChange} autoComplete={'off'} />)}
                        </Col>
                        <Col span={11}>
                        {this.props.isGetCaptcha ? (<Button onClick={this.getCaptcha}>{languageMessages['intl.register.getCaptcha']}</Button>) : (<Button disabled onClick={this.getCaptcha}>{this.props.count}s</Button>)}
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
                    {getFieldDecorator('password', {
                    rules: [
                        { required: true, message: languageMessages['please.input.password'] },
                        { min: 6, message: languageMessages['password.min'] },
                        { max: 16, message: languageMessages['password.max'] },
                        { pattern: /^(?![^a-zA-Z]+$)(?!\D+$)/, message: languageMessages['password.required.format'] }
                    ],
                })(<Input.Password prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={languageMessages['password.format']} onChange={this.handleChange} autoComplete={'off'} />)}
            </Form.Item>
            <Form.Item validateStatus={promoCodeError ? 'error' : ''} help={promoCodeError || ''}>
                    <Row gutter={8}>
                        <Col span={13}>
                        {getFieldDecorator('promoCode', {
                            rules: [
                                { pattern: /^[a-zA-Z0-9]{7}$/, message: languageMessages['promoCode.required.format'] }
                            ],
                        })(<Input placeholder={languageMessages['promoCode.format']} onChange={this.handleChange} autoComplete={'off'} />)}
                        </Col>
                        <Col span={11}>
                            {languageMessages['optional']}
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('agreement', {
                        valuePropName: 'checked', initialValue: true,
                    })(
                    <Checkbox>
                        {languageMessages['intl.register.readAndAccept']} <a href="javascript:;" onClick={this.showModal}>{languageMessages['intl.register.agreement']}</a>
                    </Checkbox>,
                )}
                <Button size='small' htmlType="submit" className='register-form-button' loading={this.props.registered} disabled={this.props.registered ? this.props.registered : (hasErrors(getFieldsError()))}>
                    {this.props.registered ? languageMessages['intl.registered'] : languageMessages['intl.register']}
                </Button>
                </Form.Item>
            </Form>
        )
    }
}

const WrappedRegisterForm = Form.create({ name: 'register_form' })(RegisterForm);
export { WrappedRegisterForm as default };