import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, message, Spin } from 'antd';

const { Content } = Layout;

class TestPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            spinLoadding: true,
        };
    }

    componentWillMount() {
        var user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        if (user.username !== undefined) {
            this.props.dispatch({ type: "action_clear_cache", payload: { UserName: user.username, Password: '' } });
        } else {
            this.setState({ spinLoadding: false });
        }
    }

    componentWillReceiveProps(props) {
        var { status } = props;

        switch (status) {
            case 'clear_cache_success':
                message.success("清缓存数据成功");
                break;
            case 'clear_cache_failed':
                message.error("清缓存数据失败");
                break;
        }

        this.setState({ spinLoadding: false });
    }

    render() {
        return (<Content className='main-container'>
            <Spin spinning={this.state.spinLoadding}>
                <div style={{ padding: '50px', margin: '50px', textAlign: 'center' }}>空白页面</div>
            </Spin>
        </Content>)
    }
}

function mapStateToProps(state) {
    return state.test;
}

const page = connect(mapStateToProps)(TestPage);

export { page as default }