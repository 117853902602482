import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../helpers/history';
import { Layout, PageHeader, Tree, Button, Input, Form, Modal, Divider, Spin, message } from 'antd';

import StockFormulaPage from '../../pannels/stock/StockFormulaPage';
import SymbolGroupPage from '../../pannels/stock/SymbolGroupPage';
import USSymbolGroupPage from '../../pannels/stock/USSymbolGroupPage';
import StockOrderPage from '../../pannels/stock/StockOrderPage';
import StockResultPage from '../../pannels/stock/StockResultPage';

import TooltipPage from '../../pannels/account/TooltipPage';

import emitter from '../Evt.js';

const { Sider, Content } = Layout;

const { TreeNode } = Tree;

var languageMessages = window.appLocale.messages;

function strReplace(source, jsonObjcet) {
    for (var v in jsonObjcet) {
        source = source.replace(v, jsonObjcet[v]);
    }
    return source;
}

const locale = window.appLocale.locale;
class ConceptPage extends Component {
    constructor(props) {
        super(props);

        this.strageies = {};
        this.conceptExplanations = [];

        this.user = {};
        this.state = {
            customId: 0,
            stockPoolKey: 'STOCK',

            lastTimes: 0,

            conceptStrategyData: [],

            selectKeys: [],
            expandedKeys: [],

            stockGroups: window.webType === 'US' ? '10000' : '3180',

            filterId: 0,
            filterName: '',
            filterString: '',
            formulaString: '',

            orderType: '$VOLUME_NUM', //默认排序类型
            orderDirection: '0',
            isUseOrder: false,

            validateStatus: '',
            validateMessage: '',
            strategyName: '',

            saveVisible: false,

            stockCount: 0,

            isShowStockResultPage: false,

            spinLoadding: true,

            error: '',
            filterError: '',

            siderClass: false,
        };

        this.onSelectConceptStrategy = this.onSelectConceptStrategy.bind(this);
    }

    componentDidMount() {
        emitter.addListener('initPage', () => {
            this.init();
        });
    }

    componentWillMount() {
        this.init();
    }

    init() {
        this.user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        /**
        if (user.username === undefined) {
            this.setState({ spinLoadding: false }, () => {
                this.props.dispatch({ type: "action_non_user" });
            });
        } else {
            this.props.dispatch({ type: "action_get_concept_sectors", payload: { UserId: user.username, stockPoolKey: this.state.stockPoolKey, stockGroups: this.state.stockGroups } });
        }
        */
        this.props.dispatch({ type: "action_get_concept_sectors", payload: { UserId: this.user.username, stockPoolKey: this.state.stockPoolKey, stockGroups: this.state.stockGroups } });
    }

    /**
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll.bind(this)); //监听滚动
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll.bind(this));
    }

    handleScroll = e => {
        const scrollTop = (e.srcElement && e.srcElement.scrollingElement.scrollTop) || document.body.scrollTop;

        const clientHeight = (e.srcElement && e.srcElement.scrollingElement.clientHeight) || document.body.clientHeight;
        // 页面高度
        const scrollHeight = (e.srcElement && e.srcElement.scrollingElement.scrollHeight) || document.body.scrollHeight;
        // 距离页面底部的高度
        const height = scrollHeight - scrollTop - clientHeight;
        if (scrollTop >= 48) {
            //this.setState({ siderClass: true });
        } else {
            //this.setState({ siderClass: false });
        }
        
        //console.log(scrollTop, clientHeight, scrollHeight, height);
    }
    */

    setUserPermission(permissions) {
        var lastTimes = 0;
        for (var key in permissions) {
            var permission = permissions[key];
            switch (permission.type) {
                case 's':
                    lastTimes += permission.totalCount - permission.usageCount;
                    break;
                case 'vs':
                    lastTimes += permission.totalCount - permission.usageCount;
                    break;
                case 's1':
                    lastTimes += permission.totalCount - permission.usageCount;
                    break;
                case 's2':
                    lastTimes += permission.totalCount - permission.usageCount;
                    break;
            }
        }
        this.setState({ lastTimes });
    }

    setTreeStrategy(key, tmpSelectedRowKeys) {
        if (this.strageies.hasOwnProperty(key)) {
            var tmpStrategy = this.strageies[key];
            if (tmpStrategy.hasOwnProperty('0')) {
                var kk = tmpStrategy['0'].key;
                if (tmpStrategy['0'].isLeaf) {
                    var sks = [];
                    sks.push(kk.toString());

                    var filterString = [];
                    if (tmpStrategy['0'].filterString !== '') {
                        var explanations = tmpStrategy['0'].filterString.split(',');
                        if (explanations.length > 0) {
                            for (var num in explanations) {
                                filterString.push(this.conceptExplanations[explanations[num]]);
                            }
                        }
                    }

                    var strings = '';
                    if (filterString.length > 0) {
                        for (var kk in filterString) {
                            strings += filterString[kk].formula;
                        }
                    }

                    this.setState({
                        customId: 0,
                        selectKeys: sks,
                        expandedKeys: tmpSelectedRowKeys,
                        filterName: tmpStrategy['0'].title,
                        filterString: filterString,
                        formulaString: strings,
                    });
                } else {
                    tmpSelectedRowKeys.push(kk.toString());
                    this.setTreeStrategy(kk, tmpSelectedRowKeys);
                }
            }
        }
    }

    setTreeStrategyData(key) {
        var dd = [];
        for (var kk in this.strageies[key]) {
            dd.push(this.strageies[key][kk]);
            var ks = this.strageies[key][kk].key;
            if (this.strageies.hasOwnProperty(ks)) {
                this.strageies[key][kk].children = this.setTreeStrategyData(ks);
            }
        }
        return dd;
    }

    componentWillReceiveProps(props) {
        var { status, state, fail, concepts, conceptExplanations, editStrategy, customGroup, permission, lastTimes } = props;

        switch (status) {
            case 'concept_success':
                if (this.user.permission !== undefined) {
                    this.user.permission = permission;
                    sessionStorage.setItem("auth", JSON.stringify(this.user));
                    this.setUserPermission(this.user.permission);
                }

                // 策略库解释
                this.conceptExplanations = conceptExplanations;
                // 策略库
                var conceptStrategyData = [];
                for (var ii in concepts) {
                    var tmpConcepts = [];
                    for (var jj in concepts[ii]) {
                        var key = concepts[ii][jj].id;
                        var title = locale.toLowerCase() === 'en-us' ? concepts[ii][jj].en : concepts[ii][jj].cn;
                        var filterString = concepts[ii][jj].filterString;
                        var isLeaf = concepts[ii][jj].isLeaf;
                        var comment = locale.toLowerCase() === 'en-us' ? concepts[ii][jj].cen : concepts[ii][jj].ccn;
                        var tmpItem = { key: key, title: title, filterString: filterString, isLeaf: isLeaf, comment: comment };

                        tmpConcepts.push(tmpItem);
                    }

                    this.strageies[ii] = tmpConcepts;
                }
                
                if (this.strageies.hasOwnProperty('0')) {
                    var tmpSelectedRowKeys = [];
                    this.setTreeStrategy('0', tmpSelectedRowKeys);

                    for (var kk in this.strageies['0']) {
                        var key = this.strageies['0'][kk].key;
                        if (this.strageies.hasOwnProperty(key)) {
                            this.strageies['0'][kk].children = this.setTreeStrategyData(key);
                        }
                    }
                    conceptStrategyData = this.strageies[0];
                }
                
                sessionStorage.setItem("ConceptsCache", JSON.stringify(this.strageies));
                sessionStorage.setItem("ConceptExplanationsCache", JSON.stringify(this.conceptExplanations));

                this.setState({ conceptStrategyData, spinLoadding: false });
                break;
            case 'concept_fail':
                if (this.user.permission !== undefined) {
                    this.setUserPermission(this.user.permission);
                }
                var error = languageMessages['get.data.failed'];
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = languageMessages['user.exception'];
                        break;
                }
                message.error(error);
                this.setState({ spinLoadding: false });
                break;
            case 'scan_success':
                this.user.permission = permission;
                sessionStorage.setItem("auth", JSON.stringify(this.user));

                this.setState({ lastTimes: lastTimes, isShowStockResultPage: true, filterError: '' });
                break;
            case 'scan_fail':
                var error = '';
                switch (state) {
                    case 0:
                        error = languageMessages['stock.picker.exception'];
                        break;
                    case 2:
                        error = languageMessages['no.choice.to.stock'];
                        break;
                    case 3:
                        error = languageMessages['connection.strategy.Service.failed'];
                        break;
                    case 4:
                        error = languageMessages['user.exception'];
                        break;
                    case 5:
                        error = languageMessages['request.stock.picker.failed'];
                        break;
                    case 6:
                        error = languageMessages['stock.picker.strategy.error'];
                        if (fail === 'Filter quota is reached.') {
                            error = languageMessages['reaching.strategy.quota'];
                        } else {
                            var eer = '';
                            var ff = fail.split(':');
                            if (ff.length > 0) {
                                for (var i in ff) {
                                    if (i == 0) continue;
                                    eer += ff[i];
                                }
                            }
                            if (eer !== '') error += ' : ' + eer;
                        }
                        break;
                    case 7:
                        error = languageMessages['today.stock.picks.end'];
                        break;
                    default:
                        break;
                }
                this.setState({ filterError: error });
                break;
            case 'save_success':
                var customStrategy = JSON.parse(sessionStorage.getItem("CustomStrategyCache") || '[]') || [];
                if (customStrategy.length > 0) {
                    const index = customStrategy.findIndex(item => item.id === editStrategy.id);
                    if (index > -1) {
                        customStrategy[index] = editStrategy;
                    } else {
                        customStrategy.push(editStrategy);
                    }
                } else {
                    customStrategy.push(editStrategy);
                }
                sessionStorage.setItem("CustomStrategyCache", JSON.stringify(customStrategy));

                var conceptStrategyCache = {
                    customId: editStrategy.id,
                    stockPoolKey: this.state.stockPoolKey,
                    stockGroups: this.state.stockGroups,
                    filterId: editStrategy.filterId,
                    filterName: editStrategy.filterName,
                    filterString: editStrategy.filterString,
                };
                sessionStorage.setItem("ConceptStrategyCache", JSON.stringify(conceptStrategyCache));
                
                var content = <span>{languageMessages['to view, please go']}"<a href="javascript:;" onClick={this.jumpMyStrategy}>{languageMessages['my.strategy']}</a>"</span>;
                this.timerModal(languageMessages['save.strategy.success'], content);

                this.setState({
                    saveVisible: false,
                    validateStatus: '',
                    validateMessage: '',
                    customId: editStrategy.id,
                    strategyName: editStrategy.filterName,
                });
                break;
            case 'save_fail':
                var error = '';
                switch (state) {
                    case 0:
                        error = languageMessages['save.strategy.exception'];
                        break;
                    case 2:
                        error = languageMessages['save.strategy.failed'];
                        break;
                    case 3:
                        error = languageMessages['save.strategy.account.not.exist'];
                        break;
                    case 4:
                        error = languageMessages['user.exception'];
                        break;
                    case 5:
                        error = languageMessages['request.save.strategy.failed'];
                        break;
                    case 6:
                        error = languageMessages['save.strategy.timeout'];
                        break;
                    case 7:
                        error = languageMessages['save.strategy.error'];
                        if (fail === 'Filter quota is reached.') {
                            error = languageMessages['reaching.strategy.quota'];
                        } else {
                            var eer = '';
                            var ff = fail.split(':');
                            if (ff.length > 0) {
                                for (var i in ff) {
                                    if (i == 0) continue;
                                    eer += ff[i];
                                }
                            }
                            if (eer !== '') error += ' : ' + eer;
                        }
                        break;
                    case 8:
                        error = languageMessages['connection.strategy.service.failed'];
                        break;
                }
                message.error(error);
                this.setState({
                    saveVisible: false,
                });
                break;
            case 'update_customStock_success':
                var customStockGroups = JSON.parse(sessionStorage.getItem("CustomStockGroupsCache") || '[]') || [];
                if (customStockGroups.length > 0) {
                    const index = customStockGroups.findIndex(item => item.groupId === customGroup.groupId);
                    if (index > -1) {
                        customStockGroups[index].groupName = customGroup.groupName;
                        customStockGroups[index].symbolList = customGroup.symbolList;
                    } else {
                        customStockGroups.push(customGroup);
                    }
                } else {
                    customStockGroups.push(customGroup);
                }
                sessionStorage.setItem("CustomStockGroupsCache", JSON.stringify(customStockGroups));

                var content = <span>{strReplace(languageMessages['already.add.success'], { '{num}': this.state.stockCount })}，<a href="javascript:;" onClick={this.jumpMyCustomStock}>{languageMessages['view.my.self-selected.stock']}</a></span>;
                this.timerModal(languageMessages['self-selected.stock.added.success'], content);

                break;
            case 'update_customStock_fail':
                var error = languageMessages['joining.stock.pool.failed'];
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = languageMessages['user.exception'];
                        break;
                }
                message.error(error);
                break;
            case 'get_stock_count_success':
                this.setState({
                    //stockGroups: data.stockGroups,
                    //stockPoolKey: data.stockPoolKey,
                    error: '',
                    filterError: '',
                });
                break;
            case 'get_stock_count_fail':
                this.setState({
                    error: '',
                    filterError: '',
                });
                break;
            default:
                break;
        }
    }

    timerModal(title, content) {
        let secondsToGo = 5;
        const modal = Modal.success({
            title: title,
            content: content,
        });

        setTimeout(() => {
            modal.destroy();
        }, secondsToGo * 1000);
    }

    handleStockGroup(data) {
        this.setState({
            stockGroups: data.stockGroups,
            stockPoolKey: data.stockPoolKey
        }, () => {
            /**
            if (user.username === undefined) {
                this.setState({ spinLoadding: false }, () => {
                    this.props.dispatch({ type: "action_non_user" });
                });
            } else {
                this.props.dispatch({ type: "action_get_stock_count", payload: { UserId: user.username, stockPoolKey: data.stockPoolKey, stockGroups: data.stockGroups } });
            }
            */
            this.props.dispatch({ type: "action_get_stock_count", payload: { UserId: this.user.username, stockPoolKey: data.stockPoolKey, stockGroups: data.stockGroups } });
        });
    }

    handleStockOrder(data) {
        this.setState({
            orderType: data.orderType,
            orderDirection: data.orderDirection,
            isUseOrder: data.isUseOrder,
        });
    }

    handleCustomStock(data) {
        this.setState({
            stockCount: data.StockCount
        }, () => {
            if (this.user.username === undefined) {
                this.props.dispatch({ type: "action_non_user" });
            } else {
                // 更新股票池
                if (data.GroupId > 0) {
                    this.props.dispatch({ type: "action_update_result_custom_stock", payload: { GroupId: data.GroupId, UserId: this.user.username, GroupName: data.GroupName, SymbolList: data.SymbolList } });
                } else {
                    this.props.dispatch({ type: "action_add_result_custom_stock", payload: { UserId: this.user.username, GroupName: data.GroupName, SymbolList: data.SymbolList } });
                }
            }
        });
    }

    jumpMyStrategy = e => {
        e.preventDefault();

        Modal.destroyAll();

        history.push("/strategy");
    }

    jumpMyCustomStock = e => {
        e.preventDefault();

        Modal.destroyAll();

        history.push("/customStock");
    }

    onMyStrategy = e => {
        e.preventDefault();

        Modal.destroyAll();

        history.push("/myStrategy");
    }

    onValueAddedServices = e => {
        e.preventDefault();
        Modal.destroyAll();
        
        history.push({ pathname: '/member', state: { memberMenuSelectKey: "2" } });
    }

    startStockPicker = () => {
        var { filterError, lastTimes } = this.state;

        if (filterError !== '') {
            //message.error(languageMessages['stock.picker.strategy.in.error']);
            //return;
        }

        if (this.user.username === undefined) {
            this.props.dispatch({ type: "action_non_user" });
        } else {
            if (lastTimes <= 0) {
                var content = <span>{languageMessages['today.stock.picks.end']}，{languageMessages['please go']}<a onClick={this.onValueAddedServices}>{languageMessages['value-added.services']}</a></span>;
                Modal.info({
                    title: languageMessages['prompt'],
                    content: content
                });
                return;
            }
            this.setState({ isShowStockResultPage: false, filterError: '' }, () => {
                this.checkOperatingStrategy('scen_strategy');
            });
        }
    };

    onSaveStrategy = () => {
        var { filterName } = this.state;

        this.setState({
            saveVisible: true,
            validateStatus: '',
            validateMessage: '',
            strategyName: filterName,
        });
    }

    handleSaveStrategy = () => {
        var { strategyName, customId } = this.state;

        var customStrategy = JSON.parse(sessionStorage.getItem("CustomStrategyCache") || '[]') || [];
        var tmpData = customStrategy.filter(function (e) { return e.filterName == strategyName && e.id !== customId });
        if (tmpData.length > 0) {
            this.setState({
                validateStatus: 'error',
                validateMessage: languageMessages['strategy.name.already.exists'],
            });
        } else {
            if (this.user.username === undefined) {
                this.props.dispatch({ type: "action_non_user" });
            } else {
                this.checkOperatingStrategy('save_strategy');
            }
        }
    };

    handleCancelStrategy = () => {
        this.setState({
            saveVisible: false,
            validateStatus: '',
            validateMessage: '',
            strategyName: '',
        });
    };

    checkOperatingStrategy(type) {
        var { stockGroups,
            stockPoolKey,
            selectKeys,
            filterName,
            filterString,
            customId,
            strategyName,
            orderType,
            orderDirection,
            isUseOrder} = this.state;

        if (stockGroups === '') {
            this.setState({
                error: 'STOCK_GROUP_ERROR'
            });
            return;
        }
        
        var strings = '';
        for (var kk in filterString) {
            strings += filterString[kk].formula;
        }

        if (strings.length > 8000) {
            message.error(languageMessages['strategy.content.length.too.long']);
            return;
        }

        strategyName = strategyName === '' ? filterName : strategyName;
        switch (type) {
            case 'scen_strategy':
                this.props.dispatch({
                    type: "action_scen_strategy", payload: {
                        UserId: this.user.username,
                        Type: 0,
                        FilterId: 0,
                        FilterName: '',
                        GroupType: stockPoolKey,
                        FilterString: strings,
                        SymbolGroupId: stockGroups,
                        OrderType: isUseOrder ? '' : orderType,
                        OrderDirection: isUseOrder ? '' : orderDirection
                    }
                });
                break;
            case 'save_strategy':
                var conceptId = selectKeys.length > 0 ? selectKeys[0] : 0;
                this.props.dispatch({ type: "action_save_strategy", payload: { UserId: this.user.username, Type: 0, CustomId: customId, ConceptId: conceptId, FilterId: 0, FilterName: strategyName, GroupType: stockPoolKey, FilterString: strings, SymbolGroupId: stockGroups } });
                break;
        }
    }

    onEditStrategy = e => {
        e.preventDefault();
        let { stockPoolKey, stockGroups, filterId, filterName, filterString, customId, selectKeys } = this.state;

        var strings = '';
        for (var kk in filterString) {
            var tmpString = strings.charAt(strings.length - 1);
            if (tmpString === ';') strings += '\n';
            strings += filterString[kk].formula;
        }
        
        var conceptStrategyCache = {
            conceptId: selectKeys.length > 0 ? selectKeys[0] : 0,
            customId: customId,
            stockPoolKey: stockPoolKey,
            stockGroups: stockGroups,
            filterId: filterId,
            filterName: filterName,
            filterString: strings
        };
        sessionStorage.setItem("ConceptStrategyCache", JSON.stringify(conceptStrategyCache));
        history.push("/editStrategy");
    }

    onChangeStrategyName = e => {
        e.preventDefault();

        var validateStatus = '';
        var validateMessage = '';
        var strategyName = e.target.value;
        if (strategyName !== '') {
            strategyName = strategyName.slice(0, 20);
            
            var pattern = /^[A-Za-z0-9_\u4e00-\u9fa5]+$/gi;
            if (!pattern.test(strategyName)) {
                validateStatus = 'error';
                validateMessage = languageMessages['strategy.name.format'];
            }
        }
        this.setState({
            validateStatus: validateStatus,
            validateMessage: validateMessage,
            strategyName: strategyName
        });
    }

    onSelectConceptStrategy = (keys, event) => {
        if (event.node.props.isLeaf) {
            var filterString = [];
            if (event.node.props.filterString !== '') {
                var explanations = event.node.props.filterString.split(',');
                if (explanations.length > 0) {
                    for (var num in explanations) {
                        filterString.push(this.conceptExplanations[explanations[num]]);
                    }
                }
            }

            var strings = '';
            if (filterString.length > 0) {
                for (var kk in filterString) {
                    strings += filterString[kk].formula;
                }
            }

            var tmpFilterString = strings.toLowerCase();
            var topIndex = tmpFilterString.indexOf("selecttopn");
            var bottomIndex = tmpFilterString.indexOf("selectbottomn");
            var isUseOrder = false;
            if (topIndex !== -1 || bottomIndex !== -1) {
                //SelectTopN,SelectBottomN
                isUseOrder = true;
            }

            this.setState({
                selectKeys: keys,
                filterName: event.node.props.title,
                strategyName: '',
                filterString: filterString,
                formulaString: strings,
                filterError: '',
                customId: 0,
                isUseOrder: isUseOrder,
            });
        } else {
            var { expandedKeys } = this.state;
            if (event.selected) {
                var index = expandedKeys.indexOf(keys[0]);
                if (index === -1) {
                    this.setState({ expandedKeys: keys });
                } else {
                    this.setState({ expandedKeys: [] });
                }
            }
        }
    };

    onExpandConceptStrategy = (expandedKey, event) => {
        if (event.expanded) {
            if (expandedKey.length > 0) {
                var { expandedKeys } = this.state;
                expandedKeys = expandedKey.filter(function (i) { return expandedKeys.indexOf(i) === -1; });

                this.setState({ expandedKeys });
            }
        } else {
            this.setState({ expandedKeys: [] });
        }
    }

    renderTreeNodes = data =>
        data.map(item => {
            if (item.children) {
                return (
                    <TreeNode title={item.title} key={item.key} dataRef={item}>
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode {...item} dataRef={item} />;
        });

    renderConceptStrategy(conceptStrategyData, selectKeys, expandedKeys) {
        if (conceptStrategyData.length > 0) {
            return <Tree
                expandedKeys={expandedKeys}
                onExpand={this.onExpandConceptStrategy}
                selectedKeys={selectKeys}
                onSelect={this.onSelectConceptStrategy}>{this.renderTreeNodes(conceptStrategyData)}</Tree>
        } else {
            return <div style={{ textAlign: 'center', padding: '20px 0', color: 'rgba(255, 255, 255, 0.75' }}>{languageMessages['no.strategy.library']}</div>
        }
    }

    render() {
        var { stockPoolKey, stockGroups, selectKeys, expandedKeys, conceptStrategyData } = this.state;
        var { sectors, stockCount } = this.props;

        var defaultSymbolGroups = {
            stockPoolKey: stockPoolKey,
            stockGroups: stockGroups,
            sectors: sectors,
            stockCount: stockCount,
        }

        var conceptId = selectKeys.length > 0 ? selectKeys[0] : 0;

        var formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 4 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 20 }, }, };

        return (<Content className='main-container'>
            <Spin spinning={this.state.spinLoadding}>
            <div className="common-page-header">
                <PageHeader title={languageMessages['intl.menu.strategyStockPicking']}
                        extra={[
                        <Button key="1" size='small' onClick={this.onMyStrategy}>{languageMessages['my.strategy']}</Button>,
                        <Button key="2" size='small' onClick={this.onEditStrategy} disabled={this.state.filterString === ''}>{languageMessages['edit.strategy']}</Button>,
                        <Button key="3" size='small' onClick={this.onSaveStrategy} disabled={this.state.filterString === ''}>{languageMessages['save.strategy']}</Button>
                    ]} />
                <Modal title={languageMessages['new.strategy']}
                    visible={this.state.saveVisible}
                    onOk={this.handleSaveStrategy}
                    confirmLoading={this.props.status === 'save_commit'}
                    onCancel={this.handleCancelStrategy}>
                    <Form {...formItemLayout}>
                        <Form.Item label={languageMessages['strategy.name.form']}
                            hasFeedback
                            validateStatus={this.state.validateStatus}
                            help={this.state.validateMessage}>
                            <Input placeholder={languageMessages['strategy.name.placeholder']} id={this.state.validateStatus} value={this.state.strategyName} onChange={this.onChangeStrategyName} />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
            <Layout className={`main-content ${this.state.siderClass ? "sider-class" : ""}`}>
                <Sider width={300}>
                    <PageHeader className='common-sider-header' title={languageMessages['featured.strategy.directory']} />
                    <Divider />
                    <div className="common-sider-content">
                        {this.renderConceptStrategy(conceptStrategyData, selectKeys, expandedKeys)}
                    </div>
                </Sider>
                <Layout>
                    <Content className='common-content'>
                        {window.webType === 'CN' ? <SymbolGroupPage defaultSymbolGroups={defaultSymbolGroups} stockGroup={this.handleStockGroup.bind(this)} error={this.state.error} /> : <USSymbolGroupPage defaultSymbolGroups={defaultSymbolGroups} stockGroup={this.handleStockGroup.bind(this)} error={this.state.error} />}
                        <StockFormulaPage conceptId={conceptId} />
                        <StockOrderPage filterString={this.state.formulaString} isUseOrder={this.state.isUseOrder} stockOrder={this.handleStockOrder.bind(this)} />

                        <div style={{ padding: '10px 0' }}>{languageMessages['real-time.market.stock.picker']}</div>
                        <div style={{ padding: '0' }}>
                            <Button size='small' onClick={this.startStockPicker} loading={this.props.status === 'scan_commit'} disabled={this.props.status === 'scan_commit'}>
                                {this.props.status === 'scan_commit' ? languageMessages['stock.picking'] : languageMessages['real.stock.picking']}
                            </Button>
                            <TooltipPage lastTimes={this.state.lastTimes} filterError={this.state.filterError} />
                        </div>
                    </Content>
                    {this.state.isShowStockResultPage && (<div>
                    <div className='common-separate-block'></div>
                    <Content className='common-content'>
                        <StockResultPage status={this.props.status} stockPoolKey={stockPoolKey} scanAlert={this.props.scanAlert} customStockEvent={this.handleCustomStock.bind(this)} />
                    </Content></div>
                    )}
                </Layout>
            </Layout>
        </Spin>
    </Content>);
    }
}

function mapStateToProps(state) {
    return state.concept;
}

const page = connect(mapStateToProps)(ConceptPage);
export { page as default }