import { call, takeLatest, put } from 'redux-saga/effects';
import { post, get } from '../../helpers/server';

function* getFactorSectors(action) {
    try {
        const resp = yield call(get, "/filter/factor?u=" + action.payload.UserId + "&spk=" + action.payload.stockPoolKey + "&sg=" + action.payload.stockGroups);
        if (resp.status === 1) {
            yield put({ type: "get_factor_filters_success", filter: resp.f, currDate: resp.cd, sectors: resp.s, stockCount: resp.sc, permission: resp.p });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "get_factor_filters_failed", status: resp.status });
        }
    } catch (e) {
        yield put({ type: "get_factor_filters_failed", status: 0 });
    }
}

function* factorfilterScan(action) {
    try {
        yield put({ type: "scan_factorFilter_commit" });
        const resp = yield call(post, "/factor/scanfilter", action.payload);
        if (resp.status === 1) {
            yield put({ type: "scan_factorFilter_success", scanAlert: resp.scanAlert, filter: resp.filter, permission: resp.permission, lastTimes: resp.lastTimes });
        } else {
            if (resp.status === 4) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "scan_factorFilter_failed", status: resp.status, error: resp.error === undefined ? '' : resp.error });
        }
    } catch (e) {
        yield put({ type: "scan_factorFilter_failed", status: 0 });
    }
}

// 生成因子文件
function* generateFactorFile(action) {
    try {
        yield put({ type: "factor_generate_commit" });
        const resp = yield call(post, "/factor/generator", action.payload);
        if (resp.status === 1) {
            yield put({ type: "factor_generate_ready", taskName: resp.taskName });
        } else {
            if (resp.status === 3) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "factor_generate_error", status: resp.status });
        }
    } catch (e) {
        yield put({ type: "factor_generate_error", status: 0 });
    }
}

function* queryGenerateFactorFileStatus(action) {
    try {
        const resp = yield call(get, "/factor/query?taskName=" + action.payload);
        if (resp.status === 1) {
            yield put({ type: "factor_generate_finished", permission: resp.permission, lastTimes: resp.lastTimes });
        } else if (resp.status === 2) {
            yield put({ type: "factor_generate_working" });
        } else {
            if (resp.status === 3) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "factor_generate_error", status: resp.status });
        }
    } catch (e) {
        yield put({ type: "factor_generate_error", status: 0 });
    }
}

function* getFactorFiles(action) {
    try {
        const resp = yield call(get, "/factor/files?u=" + action.payload.UserId);
        if (resp.status === 1) {
            yield put({ type: "get_factor_files_success", factorFiles: resp.ff });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "get_factor_files_failed", status: resp.status });
        }
    } catch (e) {
        yield put({ type: "get_factor_files_failed", status: 0 });
    }
}

function* uploadMigrate(action) {
    try {
        const resp = yield call(post, "/factor/migrate", action.payload);
        if (resp.status === 1) {
            yield put({ type: "file_migrate_finished", factorFile: resp.factorFile });
        } else {
            yield put({ type: "file_migrate_error", status: resp.status });
        }
    } catch (e) {
        yield put({ type: "file_migrate_error", status: 0 });
    }
}

function* removeFile(action) {
    try {
        const resp = yield call(post, "/factor/remove", action.payload);
        if (resp.status === 1) {
            yield put({ type: "file_remove_finished", fileName: resp.fileName })
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "file_remove_error", status: resp.status })
        }
    } catch (e) {
        yield put({ type: "file_remove_error", status: 0 });
    }
}

function* startAnalyze(action) {
    try {
        yield put({ type: "factor_analyze_commit" })
        const resp = yield call(post, "/factor/startAnalyze", action.payload);
        if (resp.status === 1) {
            yield put({ type: "factor_analyze_ready", taskId: resp.taskId, stage: 0, analyzeState: resp.analyzeState })
        } else {
            if (resp.status === 4) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "factor_analyze_error", status: resp.status, analyzeState: resp.analyzeState })
        }
    } catch (e) {
        yield put({ type: "factor_analyze_error", status: 0 });
    }
}

function* analyzeQuery(action) {
    try {
        const resp = yield call(post, "/factor/queryAnalyze", action.payload);
        if (resp.status === 1) {
            if (resp.stage[0] === resp.stage[1]) {
                yield put({ type: "factor_analyze_finished", stage: resp.stage, analyzeState: resp.analyzeState })
            } else {
                yield put({ type: "factor_analyze_working", stage: resp.stage, analyzeState: resp.analyzeState })
            }
        } else {
            if (resp.status === 4) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "factor_analyze_error", status: resp.status, analyzeState: resp.analyzeState })
        }
    } catch (e) {
        yield put({ type: "factor_analyze_error", status: 0 });
    }
}

function* getFactors(action) {
    try {
        const resp = yield call(post, "/analyzerResult/Factors", action.payload);
        if (resp.status === 1) {
            yield put({ type: "get_factor_list_success", factorNames: resp.factorNames })
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "get_factor_list_failed", status: resp.status })
        }
    } catch (e) {
        yield put({ type: "get_factor_list_failed", status: 0 });
    }
}

function* getFactorReturn(action) {
    const { chartNames, factorName } = action.payload
    for (let i = 0; i < chartNames.length; i++) {
        const resp = yield call(post, "/analyzerResult/factorReturn", { taskId: action.payload.taskId, factorName: factorName, chartName: chartNames[i] });
        if (resp.status === 1) {
            yield put({ type: "get_factor_data_success", chartName: chartNames[i], data: resp.data, factorName: factorName })
        }
    }
}

function* getFactorIndicate(action) {
    const { factorName } = action.payload
    const resp = yield call(post, "/analyzerResult/factorIndicate", { taskId: action.payload.taskId, factorName: factorName });
    if (resp.status === 1) {
        yield put({ type: "get_factor_indicate_data_success", data: resp.data, factorName: factorName })
    }
}

function* getFactorTurnover(action) {
    const { factorName } = action.payload
    const resp = yield call(post, "/analyzerResult/factorturnover", { taskId: action.payload.taskId, factorName: factorName });
    if (resp.status === 1) {
        yield put({ type: "get_factor_turnover_data_success", data: resp.data, factorName: factorName })
    }
}

function* factor() {
    yield takeLatest('action_get_factor_sectors', getFactorSectors);
    yield takeLatest('action_factorfilter_scan', factorfilterScan);
    yield takeLatest('action_factor_file_generate', generateFactorFile);
    yield takeLatest('action_factor_file_generate_query', queryGenerateFactorFileStatus);

    yield takeLatest('action_get_factor_files', getFactorFiles);

    yield takeLatest('action_start_analyze', startAnalyze);
    yield takeLatest('action_analyze_query', analyzeQuery);

    yield takeLatest('action_upload_migrate', uploadMigrate);
    yield takeLatest('action_remove_file', removeFile);

    yield takeLatest('action_get_factor_list', getFactors);
    
    yield takeLatest('action_get_factor_return', getFactorReturn);
    yield takeLatest('action_get_factor_indicate', getFactorIndicate);
    yield takeLatest('action_get_factor_turnover', getFactorTurnover);
}

export default factor;