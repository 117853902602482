import { call, takeLatest, put } from 'redux-saga/effects';
import { post, get } from '../../helpers/server';

function* getoption(action) {
    try {
        const resp = yield call(get, "/option/getOptions?u=" + action.payload.UserId);
        if (resp.status === 1) {
            yield put({ type: "get_option_success", underlyingList: resp.ul, permission: resp.p })
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "get_option_fail", status: resp.status });
        }
    } catch (e) {
        yield put({ type: "get_option_fail", status: 0 });
    }
}

function* optionScan(action) {
    try {
        yield put({ type: 'option_scan_commit', data: { message: "策略扫描中..." } });
        const resp = yield call(post, "/option/scan", action.payload);
        if (resp.status === 1) {
            yield put({ type: "option_scan_success", scanResponse: resp.sr, permission: resp.p, lastTimes: resp.lt });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "option_scan_fail", status: resp.status });
        }
    } catch (e) {
        yield put({ type: "option_scan_fail", status: 0 });
    }
}

function* Effect() {
    yield takeLatest('action_get_option', getoption);
    yield takeLatest('action_option_scan', optionScan);
}

export default Effect;