var RotationSentence = {
    'cn': [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10'
    ],
    'en': [
        'Price makes 2 day high',
        'Price makes 2 day high',
        'Price makes 2 day high',
        'Price makes 2 day high',
        'Price makes 2 day high',
        'Price makes 2 day high',
        'Price makes 2 day high',
        'Price makes 2 day high',
        'Price makes 2 day high',
        'Price makes 2 day high'
    ]
};

export default RotationSentence;