import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../rootReducer';
import rootSaga from '../rootEffects';

const sagaMiddleware = createSagaMiddleware()
/** 
const store2 = createStore(
    rootReducer,
    applyMiddleware(
        sagaMiddleware,//用于启用sagas功能，其捕获组件dispatch的动作。传递给saga和redux的action数据结构一样。
        //  logger,
    )
);
*/
const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(
        sagaMiddleware,//用于启用sagas功能，其捕获组件dispatch的动作。传递给saga和redux的action数据结构一样。
        //  logger,
    ))
);

sagaMiddleware.run(rootSaga);
export default store;