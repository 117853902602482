import { call, takeLatest, put } from 'redux-saga/effects';
import { post, get } from '../../helpers/server';

function* getFormulaSectors(action) {
    try {
        const resp = yield call(get, "/filter/formula?u=" + action.payload.UserId + "&spk=" + action.payload.stockPoolKey + "&sg=" + action.payload.stockGroups);
        if (resp.status === 1) {
            yield put({ type: "get_formula_success", sectors: resp.s, stockCount: resp.sc, permission: resp.p });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "get_formula_fail", status: resp.status });
        }
    } catch (e) {
        yield put({ type: "get_formula_fail", status: 0 });
    }
}

function* scenStrategy(action) {
    try {
        yield put({ type: "scan_formula_strategy_commit" });
        const resp = yield call(post, "/filter/ScanFilter", action.payload);
        if (resp.status === 1) {
            yield put({ type: "get_scan_formula_success", scanAlert: resp.scanAlert, filter: resp.filter, permission: resp.permission, lastTimes: resp.lastTimes });
        } else {
            if (resp.status === 4) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "get_scan_formula_fail", status: resp.status, error: resp.error });
        }
    } catch (e) {
        yield put({ type: "get_scan_formula_fail", status: 0 });
    }
}

function* saveStrategy(action) {
    try {
        yield put({ type: "save_formula_strategy_commit" });
        const resp = yield call(post, "/filter/SaveFilter", action.payload);
        if (resp.status === 1) {
            yield put({ type: "get_save_formula_success", editStrategy: resp.editStrategy });
        } else {
            if (resp.status === 4) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "get_save_formula_fail", status: resp.status, error: resp.error });
        }
    } catch (e) {
        yield put({ type: "get_save_formula_fail", status: 0 });
    }
}

function* getLuisSectors(action) {
    try {
        const resp = yield call(get, "/filter/luis?u=" + action.payload.UserId + "&spk=" + action.payload.stockPoolKey + "&sg=" + action.payload.stockGroups);
        if (resp.status === 1) {
            yield put({ type: "get_luis_success", sectors: resp.s, luString: resp.ls, stockCount: resp.sc, permission: resp.p });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "get_luis_fail", status: resp.status });
        }
    } catch (e) {
        yield put({ type: "get_luis_fail", status: 0 });
    }
}

function* ChangeBatch(action) {
    try {
        var params = "?u=" + action.payload.UserId;
        params += "&kw=" + action.payload.keywords;
        params += "&ltK=" + action.payload.luisTabsKey;

        const resp = yield call(get, "/formula/changeBatchLuString" + params);
        if (resp.status === 1) {
            yield put({ type: "get_change_batch_success", luString: resp.ls });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "get_change_batch_fail", status: resp.status });
        }
    } catch (e) {
        yield put({ type: "get_change_batch_fail", status: 0 });
    }
}

function* changeCondition(action) {
    try {
        var params = "?u=" + action.payload.UserId;
        params += "&kw=" + action.payload.keywords;
        params += "&ltK=" + action.payload.luisTabsKey;

        const resp = yield call(get, "/formula/lustring" + params);
        if (resp.status === 1) {
            yield put({ type: "get_change_condition_success", luString: resp.ls, sentenceFormula: resp.sf });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "get_change_condition_fail", status: resp.status });
        }
    } catch (e) {
        yield put({ type: "get_change_condition_fail", status: 0 });
    }
}

function* luisAnalysis(action) {
    try {
        yield put({ type: "luis_analysis_commit" });
        const resp = yield call(post, "/formula/lu", action.payload);
        if (resp.status === 1) {
            yield put({ type: "get_luis_analysis_success", formulaString: resp.formulaString });
        } else {
            if (resp.status === 5) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "get_luis_analysis_fail", status: resp.status });
        }
    } catch (e) {
        yield put({ type: "get_luis_analysis_fail", status: 0 });
    }
}

function* addFormulaCustomStock(action) {
    try {
        yield put({ type: "customStock_commit" });
        const resp = yield call(post, "/customStock/Add", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'update_customStock_success', customGroup: resp.customGroup, symbolList: resp.symbolList });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'update_customStock_fail', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "update_customStock_fail", status: 0 });
    }
}

function* updateFormulaCustomStock(action) {
    try {
        yield put({ type: "customStock_commit" });
        const resp = yield call(post, "/customStock/update", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'update_customStock_success', customGroup: resp.customGroup, symbolList: resp.symbolList });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'update_customStock_fail', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "update_customStock_fail", status: 0 });
    }
}

function* clearFilterString() {
    yield put({ type: 'clear_filter_string' });
}

function* useFormula(action) {
    yield put({ type: 'use_formula', useFormulaData: action.payload });
}

function* Effect() {
    yield takeLatest('action_get_formula_sectors', getFormulaSectors);

    yield takeLatest('action_scen_formula_strategy', scenStrategy);
    yield takeLatest('action_save_formula_strategy', saveStrategy);

    yield takeLatest('action_get_luis_sectors', getLuisSectors);
    yield takeLatest('action_change_batch', ChangeBatch);
    yield takeLatest('action_change_condition', changeCondition);

    yield takeLatest('action_luis_analysis', luisAnalysis);

    yield takeLatest('action_add_formula_custom_stock', addFormulaCustomStock);
    yield takeLatest('action_update_formula_custom_stock', updateFormulaCustomStock);

    yield takeLatest('action_clear_filterString', clearFilterString);
    yield takeLatest('action_Use_formula', useFormula);
}

export default Effect;