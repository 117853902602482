import React, { Component } from 'react';
import { Form, Input, Select, Row, Col, Button, Icon } from 'antd';

import { FormattedMessage, defineMessages } from 'react-intl';
import CodeRegExp from './Countrys';

import './flags.css';

const { Option } = Select;

const messages = defineMessages({
    resetPassword: {
        id: 'intl.resetPassword',
        defaultMessage: '重置密码',
    },
    resetPasswording: {
        id: 'intl.resetPasswording',
        defaultMessage: '重置密码中',
    },
    phone: {
        id: 'intl.register.phone',
        defaultMessage: '手机号',
    },
    captcha: {
        id: 'intl.register.captcha',
        defaultMessage: '验证码',
    },
    getCaptcha: {
        id: 'intl.register.getCaptcha',
        defaultMessage: '获取验证码',
    },

});

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class ForgetForm extends Component {
    constructor(props) {
        super(props);

        this.languageMessages = window.appLocale.messages;

        this.state = {
            phoneRegExp: '',

            prefix: 'CN'
        };
    }

    componentDidMount() {
        this.props.form.validateFields();

        var tmpCodeRegExp = CodeRegExp.filter((e) => { return e.des === this.state.prefix });
        if (tmpCodeRegExp.length > 0) {
            var phoneRegExp = tmpCodeRegExp[0].pattern;
            this.setState({
                phoneRegExp
            });
        }
    }

    getCaptcha = e => {
        e.preventDefault();
        let { isGetCaptcha } = this.props;
        this.props.form.validateFields(['phone'], (err, values) => {
            if (!err) {
                if (isGetCaptcha) {
                    var prefix = this.props.form.getFieldValue('prefix');

                    //发送手机验证码
                    this.props.dispatch({ type: "action_forget_captcha", payload: { UserName: values.phone, Prefix: prefix } });
                }
            }
        });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                var requestData = {
                    UserName: values.phone,
                    Password: values.password,
                    Captcha: values.captcha,
                    Prefix: values.prefix
                };

                //找回密码
                this.props.dispatch({ type: "action_forget", payload: requestData });
            }
        });
    };

    handlePrefixSelector = value => {
        var tmpCodeRegExp = CodeRegExp.filter((e) => { return e.des === value });
        if (tmpCodeRegExp.length > 0) {
            var phoneRegExp = tmpCodeRegExp[0].pattern;
            this.setState({
                phoneRegExp,
            }, () => {
                this.props.form.validateFields();
            });
        }

        this.props.handleChange({ error: '' });
    }

    handleChange = () => {
        this.props.handleChange({ error: '' });
    }
    
    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

        let prefix = this.state.prefix;
        let prefixCode = '0';
        let username = this.props.username;
        if (username !== undefined && username !== '') {
            let users = username.split('-');
            if (users.length > 1) {
                prefixCode = users[0];
                username = users[1];
            }
        }

        var isFlag = false;
        var options = [];
        for (var ii in CodeRegExp) {
            if (prefixCode === CodeRegExp[ii].code) {
                if (!isFlag) {
                    prefix = CodeRegExp[ii].des;
                    isFlag = true;
                }
            }
            var className = 'flag flag-' + CodeRegExp[ii].des.toLowerCase();
            var title = <span className={className}></span>;
            var content = <span>{title} {' +' + CodeRegExp[ii].code}</span>;
            options.push(<Option key={ii} value={CodeRegExp[ii].des} label={content}>{content}</Option>);
        }
        /**
        if (window.webType === 'US') {
            
        } else {
            options.push(<Option key='0' value='CN' label='86'>+86</Option>);
        }
        */
        const prefixSelector = getFieldDecorator('prefix', { initialValue: prefix })(
            <Select style={{ width: 120 }}
                optionLabelProp='label'
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ zIndex: 4, minWidth: 100 }}
                onChange={this.handlePrefixSelector}>
                {options}
            </Select>,
        );

        const phoneError = isFieldTouched('phone') && getFieldError('phone');
        const captchaError = isFieldTouched('captcha') && getFieldError('captcha');
        const passwordError = isFieldTouched('password') && getFieldError('password');

        return (<Form onSubmit={this.handleSubmit} className='register-form'>
            <Form.Item validateStatus={phoneError ? 'error' : ''} help={phoneError || ''}>
                {getFieldDecorator('phone', {
                    initialValue: username,
                    rules: [
                        { required: true, message: this.languageMessages['please.input.phone'] },
                        { pattern: this.state.phoneRegExp, message: this.languageMessages['phone.format.failed'] }
                    ],
                })(<Input addonBefore={prefixSelector} style={{ width: '100%' }} placeholder={this.languageMessages['phone.use.register']} onChange={this.handleChange} />)}
            </Form.Item>
            <Form.Item validateStatus={captchaError ? 'error' : ''} help={captchaError || ''}>
                <Row gutter={8}>
                    <Col span={12}>
                        {getFieldDecorator('captcha', {
                            rules: [{ required: true, message: this.languageMessages['please.input.captcha'] }],
                        })(<Input placeholder={this.languageMessages['phone.captcha']} onChange={this.handleChange} autoComplete={'off'} />)}
                    </Col>
                    <Col span={12}>
                    {this.props.isGetCaptcha ? (<Button onClick={this.getCaptcha}><FormattedMessage {...messages.getCaptcha} /></Button>) : (<Button disabled onClick={this.getCaptcha}>{this.props.count}s</Button>)}
                    </Col>
                </Row>
            </Form.Item>
            <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
                {getFieldDecorator('password', {
                    rules: [
                        { required: true, message: this.languageMessages['please.input.password'] },
                        { min: 6, message: this.languageMessages['password.min'] },
                        { max: 16, message: this.languageMessages['password.max'] },
                        { pattern: /^(?![^a-zA-Z]+$)(?!\D+$)/, message: this.languageMessages['password.required.format'] }
                    ],
                })(<Input.Password prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={this.languageMessages['password.new.format']} onChange={this.handleChange} autoComplete={'off'} />)}
            </Form.Item>
            <Form.Item>
                <Button size='small' htmlType="submit" className='register-form-button' loading={this.props.resetPassword} disabled={this.props.resetPassword ? this.props.resetPassword : (hasErrors(getFieldsError()))}>
                    {this.props.resetPassword ? (<FormattedMessage {...messages.resetPasswording} />) : (<FormattedMessage {...messages.resetPassword} />)}
                </Button>
            </Form.Item>
        </Form>
        )
    }
}

const WrappedForgetForm = Form.create({ name: 'Forget_Password' })(ForgetForm);
export { WrappedForgetForm as default }