//各分位数平均收益
var AverageRetByQuantileOption = {
    legend: {
        data: []
    },
    tooltip: {
        trigger: 'axis',
        position: function (pt) {
            return [pt[0], '10%'];
        }
    },
    xAxis: {
        type: 'category',
        axisTick: {show: false},
        data: []
    },
    yAxis: {
        type: 'value'
    },

    series: []
};
//因子Alpha强度
var AlphaStrengthOption = {
    legend: {
        data: ["1D","3D","5D","7D"]
    },
    tooltip: {
        trigger: 'axis',
        position: function (pt) {
            return [pt[0], '10%'];
        }
    },
    xAxis: {
        type: 'category',
        boundaryGap: false,
        data: []
    },
    yAxis: {
        type: 'value'
    },
    series: [
        {
            name:'1D',
            type:'line',
            smooth:true,
            symbol: 'none',
            data: []
        }, {
            name:'3D',
            type:'line',
            smooth:true,
            symbol: 'none',
            data: []
        },
        {
            name:'5D',
            type:'line',
            smooth:true,
            symbol: 'none',
            data: []
        },
        {
            name:'7D',
            type:'line',
            smooth:true,
            symbol: 'none',
            data: []
        }
    ]
};
//因子累计收益
var CumRetOption = {
    legend: {
        data: ["1D","3D","5D","7D"]
    },
    tooltip: {
        trigger: 'axis',
        position: function (pt) {
            return [pt[0], '10%'];
        }
    },
    xAxis: {
        type: 'category',
        boundaryGap: false,
        data: []
    },
    yAxis: {
        type: 'value',
    },

    series: [
        {
            name:'1D',
            type:'line',
            smooth:true,
            symbol: 'none',
            data: []
        }, {
            name:'3D',
            type:'line',
            smooth:true,
            symbol: 'none',
            data: []
        },
        {
            name:'5D',
            type:'line',
            smooth:true,
            symbol: 'none',
            data: []
        } ,       {
            name:'7D',
            type:'line',
            smooth:true,
            symbol: 'none',
            data: []
        }
    ]
};
//分位分组累计累计收益
var CumRetByQuantileOption = {
    legend: {
        top: 25,
        data: ["Qn 1","Qn 2","Qn 3","Qn 4","Qn 5"]
    },
    tooltip: {
        trigger: 'axis',
        formatter: function (params) {
            var relVal = params[0].name;
            for (var i = 0; i < params.length; i++) {
                var seriesName = params[i].seriesName.replace('Qn', 'Quantile')
                relVal += '<div><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background:' + params[i].color + '"></span>' + seriesName + ' : ' + params[i].value + "</div>";
            }
            return relVal;
        },
        position: function (pt) {
            return [pt[0], '10%'];
        }
    },
    title: {
        left: 'center',
        text: '',
    },
    xAxis: {
        type: 'category',
        boundaryGap: false,
        data: []
    },
    yAxis: {
        type: 'value',
    },
    series: [
        {
            name:'Qn 1',
            type:'line',
            smooth:true,
            symbol: 'none',
            data: []
        }, {
            name:'Qn 2',
            type:'line',
            smooth:true,
            symbol: 'none',
            data: []
        },
        {
            name:'Qn 3',
            type:'line',
            smooth:true,
            symbol: 'none',
            data: []
        },
        {
            name:'Qn 4',
            type:'line',
            smooth:true,
            symbol: 'none',
            data: []
        },
        {
            name:'Qn 5',
            type:'line',
            smooth:true,
            symbol: 'none',
            data: []
        }
    ]
};
//行业
var AverageRetBySectorOption = {
    legend: {
        top: 25,
        data: ["1D","3D","5D","7D"]
    },
    tooltip: {
        trigger: 'axis',
        position: function (pt) {
            return [pt[0], '10%'];
        }
    },
    title: {
        left: 'center',
        text: '',
    },
    xAxis: {
        type: 'category',
        axisTick: {show: false},
        data: []
    },
    yAxis: {
        type: 'value'
    },
    series: [
        {
            name:'1D',
            type:'bar',
            barGap: 0,
            data: []
        },
        {
            name:'3D',
            type:'bar',
            barGap: 0,
            data:[]
        },
        {
            name:'5D',
            type:'bar',
            barGap: 0,
            data: []
        },
        {
            name:'7D',
            type:'bar',
            barGap: 0,
            data: []
        }
    ]
};

export {AverageRetByQuantileOption,AlphaStrengthOption,CumRetOption,CumRetByQuantileOption,AverageRetBySectorOption}