import React, { Component } from 'react';
import { Row, Col, Input, Select, Button } from 'antd';

var languageMessages = window.appLocale.messages;

const { Option } = Select;

class HawkeyeCondition extends Component {
    constructor(props) {
        super(props);

        this.state = {
            conditionContent: [],

            PriceRiseTo: '',
            PriceFellTo: '',
            ReboundRange01: '',
            ReboundRange02: '',
            Fallback01: '',
            Fallback02: '',

            DailyIncreaseReached: '5.00',
            DailyDeclineReached: '5.00',
            PriceRise: '20',
            PriceFalling: '20',

            StockPriceWearOn01: 'D',
            StockPriceWearOn02: '20',
            StockPriceFellBelow01: 'D',
            StockPriceFellBelow02: '20',

            Increase01: '1',
            Increase02: '0.50',
            Decline01: '1',
            Decline02: '0.50',

            DailyTurnoverRate: '8.00',
            KDJGoldFork: 'D',
            KDJDeadFork: 'D',
            MACDGoldFork: 'D',
            MACDDeadFork: 'D',

            PriceWearBollinger01: 'D',
            PriceWearBollinger02: 'up',
            PriceDownBollinger01: 'D',
            PriceDownBollinger02: 'down',

            //20200605
            BuyOneHigherThan: '', //买一价高于
            SellPriceBelow: '', //卖一价低于

            ReachHigh: '3.00', //高开达到
            GapReachHigh: '3.00', //跳空高开达到

            Volume01: '5', //成交量
            Volume02: '3.0',

            VolumeRatio: '3.00', //量比
            DailyAmplitude: '5.00', //日振幅

            AnyOnlineWear01: '60', //任意均线上穿
            AnyOnlineWear02: '10',
            AnyOnlineWear03: '60',
            AnyOnlineWear04: '20',
            AnyMovingAverage01: '60', //任意均线下穿
            AnyMovingAverage02: '10',
            AnyMovingAverage03: '60',
            AnyMovingAverage04: '20'
        };
    }

    componentWillReceiveProps(props) {
        var { conditions, VernacularConditions } = props;

        var PriceRiseTo = '',
            PriceFellTo = '',

            ReboundRange01 = '',
            ReboundRange02 = '',
            Fallback01 = '',
            Fallback02 = '',

            DailyIncreaseReached = '5.00',
            DailyDeclineReached = '5.00',
            PriceRise = '20',
            PriceFalling = '20',
            StockPriceWearOn01 = 'D',
            StockPriceWearOn02 = '20',
            StockPriceFellBelow01 = 'D',
            StockPriceFellBelow02 = '20',
            Increase01 = '1',
            Increase02 = '0.50',
            Decline01 = '1',
            Decline02 = '0.50',

            DailyTurnoverRate = '8.00',
            KDJGoldFork = 'D',
            KDJDeadFork = 'D',
            MACDGoldFork = 'D',
            MACDDeadFork = 'D',

            PriceWearBollinger01 = 'D',
            PriceWearBollinger02 = 'up',
            PriceDownBollinger01 = 'D',
            PriceDownBollinger02 = 'down',

            //20200605
            BuyOneHigherThan = '', //买一价高于
            SellPriceBelow = '', //卖一价低于

            ReachHigh = '3.00', //高开达到
            GapReachHigh = '3.00', //跳空高开达到

            Volume01 = '5', //成交量
            Volume02 = '3.0',
            VolumeRatio = '3.00', //量比
            DailyAmplitude = '5.00', //日振幅

            AnyOnlineWear01 = '60', //任意均线上穿
            AnyOnlineWear02 = '10',
            AnyOnlineWear03 = '60',
            AnyOnlineWear04 = '20',
            AnyMovingAverage01 = '60', //任意均线下穿
            AnyMovingAverage02 = '10',
            AnyMovingAverage03 = '60',
            AnyMovingAverage04 = '20';

        var conditionContent = [];
        if (conditions.length > 0) {
            for (var i in conditions) {
                var key = conditions[i].key;
                var item = conditions[i].name;
                switch (item) {
                    case 'PriceRiseTo':
                        PriceRiseTo = this.state.PriceRiseTo;
                        conditionContent.push({ key: key, cont: this.renderPriceRiseTo() });
                        break;
                    case 'PriceFellTo':
                        PriceFellTo = this.state.PriceFellTo;
                        conditionContent.push({ key: key, cont: this.renderPriceFellTo() });
                        //conditionContent.push(this.renderPriceFellTo());
                        break;
                    case 'ReboundRange':
                        ReboundRange01 = this.state.ReboundRange01;
                        ReboundRange02 = this.state.ReboundRange02;
                        conditionContent.push({ key: key, cont: this.renderReboundRange() });
                        //conditionContent.push(this.renderReboundRange());
                        break;
                    case 'Fallback':
                        Fallback01 = this.state.Fallback01;
                        Fallback02 = this.state.Fallback02;
                        conditionContent.push({ key: key, cont: this.renderFallback() });
                        //conditionContent.push(this.renderFallback());
                        break;

                    case 'DailyIncreaseReached':
                        DailyIncreaseReached = this.state.DailyIncreaseReached;
                        conditionContent.push({ key: key, cont: this.renderDailyIncreaseReached() });
                        //conditionContent.push(this.renderDailyIncreaseReached());
                        break;
                    case 'DailyDeclineReached':
                        DailyDeclineReached = this.state.DailyDeclineReached;
                        conditionContent.push({ key: key, cont: this.renderDailyDeclineReached() });
                        //conditionContent.push(this.renderDailyDeclineReached());
                        break;
                    case 'PriceRise':
                        PriceRise = this.state.PriceRise;
                        conditionContent.push({ key: key, cont: this.renderPriceRise() });
                        //conditionContent.push(this.renderPriceRise());
                        break;
                    case 'PriceFalling':
                        PriceFalling = this.state.PriceFalling;
                        conditionContent.push({ key: key, cont: this.renderPriceFalling() });
                        //conditionContent.push(this.renderPriceFalling());
                        break;
                    case 'StockPriceWearOn':
                        StockPriceWearOn01 = this.state.StockPriceWearOn01;
                        StockPriceWearOn02 = this.state.StockPriceWearOn02;
                        conditionContent.push({ key: key, cont: this.renderStockPriceWearOn() });
                        //conditionContent.push(this.renderStockPriceWearOn());
                        break;
                    case 'StockPriceFellBelow':
                        StockPriceFellBelow01 = this.state.StockPriceFellBelow01;
                        StockPriceFellBelow02 = this.state.StockPriceFellBelow02;
                        conditionContent.push({ key: key, cont: this.renderStockPriceFellBelow() });
                        //conditionContent.push(this.renderStockPriceFellBelow());
                        break;
                    case 'Increase':
                        Increase01 = this.state.Increase01;
                        Increase02 = this.state.Increase02;
                        conditionContent.push({ key: key, cont: this.renderIncrease() });
                        //conditionContent.push(this.renderIncrease());
                        break;
                    case 'Decline':
                        Decline01 = this.state.Decline01;
                        Decline02 = this.state.Decline02;
                        conditionContent.push({ key: key, cont: this.renderDecline() });
                        //conditionContent.push(this.renderDecline());
                        break;
                    case 'DailyTurnoverRate':
                        DailyTurnoverRate = this.state.DailyTurnoverRate;
                        conditionContent.push({ key: key, cont: this.renderDailyTurnoverRate() });
                        //conditionContent.push(this.renderDailyTurnoverRate());
                        break;
                    case 'KDJGoldFork':
                        KDJGoldFork = this.state.KDJGoldFork;
                        conditionContent.push({ key: key, cont: this.renderKDJGoldFork() });
                        //conditionContent.push(this.renderKDJGoldFork());
                        break;
                    case 'KDJDeadFork':
                        KDJDeadFork = this.state.KDJDeadFork;
                        conditionContent.push({ key: key, cont: this.renderKDJDeadFork() });
                        //conditionContent.push(this.renderKDJDeadFork());
                        break;
                    case 'MACDGoldFork':
                        MACDGoldFork = this.state.MACDGoldFork;
                        conditionContent.push({ key: key, cont: this.renderMACDGoldFork() });
                        //conditionContent.push(this.renderMACDGoldFork());
                        break;
                    case 'MACDDeadFork':
                        MACDDeadFork = this.state.MACDDeadFork;
                        conditionContent.push({ key: key, cont: this.renderMACDDeadFork() });
                        //conditionContent.push(this.renderMACDDeadFork());
                        break;
                    case 'PriceWearBollinger':
                        PriceWearBollinger01 = this.state.PriceWearBollinger01;
                        PriceWearBollinger02 = this.state.PriceWearBollinger02;
                        conditionContent.push({ key: key, cont: this.renderPriceWearBollinger() });
                        //conditionContent.push(this.renderPriceWearBollinger());
                        break;
                    case 'PriceDownBollinger':
                        PriceDownBollinger01 = this.state.PriceDownBollinger01;
                        PriceDownBollinger02 = this.state.PriceDownBollinger02;
                        conditionContent.push({ key: key, cont: this.renderPriceDownBollinger() });
                        //conditionContent.push(this.renderPriceDownBollinger());
                        break;

                    //20200605
                    case 'BuyOneHigherThan': //买一价高于
                        BuyOneHigherThan = this.state.BuyOneHigherThan;
                        conditionContent.push({ key: key, cont: this.renderBuyOneHigherThan() });
                        //conditionContent.push(this.renderBuyOneHigherThan());
                        break;
                    case 'SellPriceBelow': //卖一价低于
                        SellPriceBelow = this.state.SellPriceBelow;
                        conditionContent.push({ key: key, cont: this.renderSellPriceBelow() });
                        //conditionContent.push(this.renderSellPriceBelow());
                        break;
                    case 'ReachHigh': //高开达到
                        ReachHigh = this.state.ReachHigh;
                        conditionContent.push({ key: key, cont: this.renderReachHigh() });
                        //conditionContent.push(this.renderReachHigh());
                        break;
                    case 'GapReachHigh': //跳空高开达到
                        GapReachHigh = this.state.GapReachHigh;
                        conditionContent.push({ key: key, cont: this.renderGapReachHigh() });
                        //conditionContent.push(this.renderGapReachHigh());
                        break;
                    case 'Volume': //成交量
                        Volume01 = this.state.Volume01;
                        Volume02 = this.state.Volume02;
                        conditionContent.push({ key: key, cont: this.renderVolume() });
                        //conditionContent.push(this.renderVolume());
                        break;
                    case 'VolumeRatio': //量比
                        VolumeRatio = this.state.VolumeRatio;
                        conditionContent.push({ key: key, cont: this.renderVolumeRatio() });
                        //conditionContent.push(this.renderVolumeRatio());
                        break;
                    case 'DailyAmplitude': //日振幅
                        DailyAmplitude = this.state.DailyAmplitude;
                        conditionContent.push({ key: key, cont: this.renderDailyAmplitude() });
                        //conditionContent.push(this.renderDailyAmplitude());
                        break;
                    case 'AnyOnlineWear': //任意均线上穿
                        AnyOnlineWear01 = this.state.AnyOnlineWear01;
                        AnyOnlineWear02 = this.state.AnyOnlineWear02;
                        AnyOnlineWear03 = this.state.AnyOnlineWear03;
                        AnyOnlineWear04 = this.state.AnyOnlineWear04;
                        conditionContent.push({ key: key, cont: this.renderAnyOnlineWear() });
                        //conditionContent.push(this.renderAnyOnlineWear());
                        break;
                    case 'AnyMovingAverage': //任意均线下穿
                        AnyMovingAverage01 = this.state.AnyMovingAverage01;
                        AnyMovingAverage02 = this.state.AnyMovingAverage02;
                        AnyMovingAverage03 = this.state.AnyMovingAverage03;
                        AnyMovingAverage04 = this.state.AnyMovingAverage04;
                        conditionContent.push({ key: key, cont: this.renderAnyMovingAverage() });
                        //conditionContent.push(this.renderAnyMovingAverage());
                        break;
                    default:
                        break;
                }
            }
        }

        if (VernacularConditions.length > 0) {
            for (let k in VernacularConditions) {
                conditionContent.push({ key: VernacularConditions[k].key, cont: this.renderVernacularCondition(VernacularConditions[k]) });
                //conditionContent.push(this.renderVernacularCondition(VernacularConditions[k]));
            }
        }

        if (conditionContent.length > 0) {
            conditionContent = conditionContent.sort((a, b) => {
                return b.key - a.key
            });
        }

        this.setState({
            conditionContent,

            PriceRiseTo,
            PriceFellTo,

            ReboundRange01,
            ReboundRange02,
            Fallback01,
            Fallback02,

            DailyIncreaseReached,
            DailyDeclineReached,
            PriceRise,
            PriceFalling,
            StockPriceWearOn01,
            StockPriceWearOn02,
            StockPriceFellBelow01,
            StockPriceFellBelow02,
            Increase01,
            Increase02,
            Decline01,
            Decline02,

            DailyTurnoverRate,
            KDJGoldFork,
            KDJDeadFork,
            MACDGoldFork,
            MACDDeadFork,

            PriceWearBollinger01,
            PriceWearBollinger02,
            PriceDownBollinger01,
            PriceDownBollinger02,

            //20200605
            BuyOneHigherThan, //买一价高于
            SellPriceBelow, //卖一价低于

            ReachHigh, //高开达到
            GapReachHigh, //跳空高开达到

            Volume01, //成交量
            Volume02,

            VolumeRatio, //量比
            DailyAmplitude, //日振幅

            AnyOnlineWear01, //任意均线上穿
            AnyOnlineWear02,
            AnyOnlineWear03,
            AnyOnlineWear04,
            AnyMovingAverage01, //任意均线下穿
            AnyMovingAverage02,
            AnyMovingAverage03,
            AnyMovingAverage04,
        });
    }

    setConditions() {
        this.props.hawkeyeConditionParams({
            PriceRiseTo: this.state.PriceRiseTo,
            PriceFellTo: this.state.PriceFellTo,

            ReboundRange01: this.state.ReboundRange01,
            ReboundRange02: this.state.ReboundRange02,
            Fallback01: this.state.Fallback01,
            Fallback02: this.state.Fallback02,

            DailyIncreaseReached: this.state.DailyIncreaseReached,
            DailyDeclineReached: this.state.DailyDeclineReached,
            PriceRise: this.state.PriceRise,
            PriceFalling: this.state.PriceFalling,
            StockPriceWearOn01: this.state.StockPriceWearOn01,
            StockPriceWearOn02: this.state.StockPriceWearOn02,
            StockPriceFellBelow01: this.state.StockPriceFellBelow01,
            StockPriceFellBelow02: this.state.StockPriceFellBelow02,
            Increase01: this.state.Increase01,
            Increase02: this.state.Increase02,
            Decline01: this.state.Decline01,
            Decline02: this.state.Decline02,

            DailyTurnoverRate: this.state.DailyTurnoverRate,
            KDJGoldFork: this.state.KDJGoldFork,
            KDJDeadFork: this.state.KDJDeadFork,
            MACDGoldFork: this.state.MACDGoldFork,
            MACDDeadFork: this.state.MACDDeadFork,

            PriceWearBollinger01: this.state.PriceWearBollinger01,
            PriceWearBollinger02: this.state.PriceWearBollinger02,
            PriceDownBollinger01: this.state.PriceDownBollinger01,
            PriceDownBollinger02: this.state.PriceDownBollinger02,

            //20200605
            BuyOneHigherThan: this.state.BuyOneHigherThan, //买一价高于
            SellPriceBelow: this.state.SellPriceBelow, //卖一价低于

            ReachHigh: this.state.ReachHigh, //高开达到
            GapReachHigh: this.state.GapReachHigh, //跳空高开达到

            Volume01: this.state.Volume01, //成交量
            Volume02: this.state.Volume02,
            VolumeRatio: this.state.VolumeRatio, //量比
            DailyAmplitude: this.state.DailyAmplitude, //日振幅

            AnyOnlineWear01: this.state.AnyOnlineWear01, //任意均线上穿
            AnyOnlineWear02: this.state.AnyOnlineWear02,
            AnyOnlineWear03: this.state.AnyOnlineWear03,
            AnyOnlineWear04: this.state.AnyOnlineWear04,
            AnyMovingAverage01: this.state.AnyMovingAverage01, //任意均线下穿
            AnyMovingAverage02: this.state.AnyMovingAverage02,
            AnyMovingAverage03: this.state.AnyMovingAverage03,
            AnyMovingAverage04: this.state.AnyMovingAverage04
        });
    }

    //验证目标价格
    onChangeTargetDecimal = e => {
        e.preventDefault();

        var tags = e.currentTarget.getAttribute('data-value');
        let intLen = 12;
        let decimalLen = 2;
        if (tags === 'Volume02') {
            decimalLen = 1;
        }

        var price = e.target.value;
        price = price.replace(/^\s+|\s+$/g, "");
        if (price.indexOf('.') === 0) {
            price = price.replace(/[^\d]/g, "");
        }
        price = price.replace(/[^\d.]/g, "");
        price = price.replace(/\.{2,3}/g, ".");
        price = price.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
        
        if (price.length > 0) {
            if (price.indexOf('.') < 0) {
                if (price.length > intLen) price = price.slice(0, intLen);
                price = parseInt(price);
            } else {
                var tmpNumber = price.split('.');
                var tmpNum00 = tmpNumber[0];
                if (tmpNum00 > intLen) tmpNum00 = tmpNum00.slice(0, intLen);
                tmpNum00 = parseInt(tmpNum00);

                var tmpNum01 = tmpNumber[1];
                if (tmpNum01.length > decimalLen) tmpNum01 = tmpNum01.slice(0, decimalLen);
                price = tmpNum00 + '.' + tmpNum01;
            }
        }

        var tmpPrice = {};
        tmpPrice[tags] = price;
        this.setState(tmpPrice, () => {
            this.setConditions();
        });
    }

    onBlurTargetDecimal = e => {
        e.preventDefault();

        var tags = e.currentTarget.getAttribute('data-value');
        let intLen = 12;
        let decimalLen = 2;
        if (tags === 'Volume02') {
            decimalLen = 1;
        }

        var price = e.target.value;
        price = price.replace(/^\s+|\s+$/g, "");

        if (price.length > 0) {
            if (price.indexOf('.') < 0) {
                if (price.length > intLen) price = price.slice(0, intLen);
                price = parseInt(price);
                price = price + '.' + Array(decimalLen + 1).join(0);
            } else {
                var tmpValue = price.split('.');
                var tmpNum00 = tmpValue[0];
                if (tmpNum00.length > intLen) tmpNum00 = tmpNum00.slice(0, intLen);
                tmpNum00 = parseInt(tmpNum00);

                var tmpNum01 = tmpValue[1];
                if (tmpNum01.length > decimalLen) tmpNum01 = tmpNum01.slice(0, decimalLen);
                else {
                    var len = tmpNum01.length;
                    if (len === 0) {
                        tmpNum01 = tmpNum01 + Array(decimalLen + 1).join(0);
                    } else if (len === 1) {
                        tmpNum01 = tmpNum01 + Array(decimalLen).join(0);
                    } else if (len === 2) {
                        tmpNum01 = tmpNum01 + Array(decimalLen - 1).join(0);
                    }
                }

                price = tmpNum00 + '.' + tmpNum01;
            }
        }

        var tmpPrice = {};
        tmpPrice[tags] = price;
        this.setState(tmpPrice, () => {
            this.setConditions();
        });
    }

    onChangeInt = (e) => {
        e.preventDefault();
        
        var tags = e.currentTarget.getAttribute('data-value');

        var amplitude = e.target.value;
        amplitude = amplitude.replace(/[^\d]/g, "");

        if (amplitude.length > 0) {
            if (tags !== "Volume01") {
                let tmpAmplitude = parseInt(amplitude);
                if (tmpAmplitude > 200) amplitude = 200;
            } else {
                amplitude = amplitude.slice(0, 12);
                amplitude = parseInt(amplitude);
            }

            amplitude = amplitude + "";
        }
        
        var tmpAmplitude = {};
        tmpAmplitude[tags] = amplitude;
        this.setState(tmpAmplitude, () => {
            this.setConditions();
        });
    }

    handleChangePriceRise = value => {
        this.setState({ PriceRise: value }, () => {
            this.setConditions();
        });
    }

    handleChangePriceFalling = value => {
        this.setState({ PriceFalling: value }, () => {
            this.setConditions();
        });
    }

    handleChangeStockPriceWearOn01 = value => {
        this.setState({ StockPriceWearOn01: value }, () => {
            this.setConditions();
        });
    }

    handleChangeStockPriceFellBelow01 = value => {
        this.setState({ StockPriceFellBelow01: value }, () => {
            this.setConditions();
        });
    }

    handleChangeIncrease01 = value => {
        this.setState({ Increase01: value }, () => {
            this.setConditions();
        });
    }

    handleChangeDecline01 = value => {
        this.setState({ Decline01: value }, () => {
            this.setConditions();
        });
    }

    handleChangeKDJGoldFork = value => {
        this.setState({ KDJGoldFork: value }, () => {
            this.setConditions();
        });
    }

    handleChangeKDJDeadFork = value => {
        this.setState({ KDJDeadFork: value }, () => {
            this.setConditions();
        });
    }

    handleChangeMACDGoldFork = value => {
        this.setState({ MACDGoldFork: value }, () => {
            this.setConditions();
        });
    }

    handleChangeMACDDeadFork = value => {
        this.setState({ MACDDeadFork: value }, () => {
            this.setConditions();
        });
    }

    handleChangePriceWearBollinger01 = value => {
        this.setState({ PriceWearBollinger01: value }, () => {
            this.setConditions();
        });
    }

    handleChangePriceWearBollinger02 = value => {
        this.setState({ PriceWearBollinger02: value }, () => {
            this.setConditions();
        });
    }

    handleChangePriceDownBollinger01 = value => {
        this.setState({ PriceDownBollinger01: value }, () => {
            this.setConditions();
        });
    }

    handleChangePriceDownBollinger02 = value => {
        this.setState({ PriceDownBollinger02: value }, () => {
            this.setConditions();
        });
    }

    handleChangeAnyOnlineWear01 = value => {
        this.setState({ AnyOnlineWear01: value }, () => {
            this.setConditions();
        });
    }

    handleChangeAnyOnlineWear03 = value => {
        this.setState({ AnyOnlineWear03: value }, () => {
            this.setConditions();
        });
    }

    handleChangeAnyMovingAverage01 = value => {
        this.setState({ AnyMovingAverage01: value }, () => {
            this.setConditions();
        });
    }

    handleChangeAnyMovingAverage03 = value => {
        this.setState({ AnyMovingAverage03: value }, () => {
            this.setConditions();
        });
    }

    onClickClose = e => {
        var { conditions, VernacularConditions } = this.props;

        var tags = e.currentTarget.getAttribute('data-value');
        conditions = conditions.filter(function (item) { return item.name !== tags });
        VernacularConditions = VernacularConditions.filter(function (item) { return item.key !== tags });

        this.props.hawkeyeConditions({ conditions: conditions, VernacularConditions: VernacularConditions });
    }

    //价格涨到
    renderPriceRiseTo() {
        return <Row key='PriceRiseTo' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>{languageMessages['PriceRiseTo']}</Col>
            <Col span={9} className='common-hawkeye-condition'>
                <Input style={{ width: 200 }} size='small' value={this.state.PriceRiseTo} addonBefore={languageMessages['usd']} addonAfter={languageMessages['yuan']} placeholder={languageMessages['PleaseEnterTargetPrice']} data-value='PriceRiseTo' onChange={this.onChangeTargetDecimal} onBlur={this.onBlurTargetDecimal} />
            </Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['priceHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='PriceRiseTo' /></Col>
        </Row>;
    }

    //价格跌到
    renderPriceFellTo() {
        return <Row key='PriceFellTo' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>{languageMessages['PriceFellTo']}</Col>
            <Col span={9} className='common-hawkeye-condition'>
                <Input style={{ width: 200 }} size='small' value={this.state.PriceFellTo} addonBefore={languageMessages['usd']} addonAfter={languageMessages['yuan']} placeholder={languageMessages['PleaseEnterTargetPrice']} data-value='PriceFellTo' onChange={this.onChangeTargetDecimal} onBlur={this.onBlurTargetDecimal} />
            </Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['priceHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='PriceFellTo' /></Col>
        </Row>;
    }

    //上涨回落
    renderReboundRange() {
        return <Row key='ReboundRange' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>
                <span style={{ paddingRight: '5px' }}>{languageMessages['priceUpTo']}</span>
                <Input style={{ width: 100 }} size='small' value={this.state.ReboundRange01} placeholder={languageMessages['priceNotEmpty']} data-value='ReboundRange01' onChange={this.onChangeTargetDecimal} onBlur={this.onBlurTargetDecimal} />
            </Col>
            <Col span={9} className='common-hawkeye-condition'>
                <span style={{ paddingRight: '5px' }}>{languageMessages['huiLuo']}</span>
                <Input style={{ width: 168 }} size='small' value={this.state.ReboundRange02} placeholder={languageMessages['priceDiffNotEmpty']} data-value='ReboundRange02' onChange={this.onChangeTargetDecimal} onBlur={this.onBlurTargetDecimal} />
            </Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['priceHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='ReboundRange' /></Col>
        </Row>;
    }

    //下跌反弹
    renderFallback() {
        return <Row key='Fallback' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>
                <span style={{ paddingRight: '5px' }}>{languageMessages['priceDownTo']}</span>
                <Input style={{ width: 100 }} size='small' value={this.state.Fallback01} placeholder={languageMessages['priceNotEmpty']} data-value='Fallback01' onChange={this.onChangeTargetDecimal} onBlur={this.onBlurTargetDecimal} />
            </Col>
            <Col span={9} className='common-hawkeye-condition'>
                <span style={{ paddingRight: '5px' }}>{languageMessages['Bounce']}</span>
                <Input style={{ width: 168 }} size='small' value={this.state.Fallback02} placeholder={languageMessages['priceDiffNotEmpty']} data-value='Fallback02' onChange={this.onChangeTargetDecimal} onBlur={this.onBlurTargetDecimal} />
            </Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['priceHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='Fallback' /></Col>
        </Row>;
    }

    //日涨幅达到
    renderDailyIncreaseReached() {
        return <Row key='DailyIncreaseReached' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>{languageMessages['dayIncreaseReach']}</Col>
            <Col span={9} className='common-hawkeye-condition'>
                <Input style={{ width: 200 }} size='small' value={this.state.DailyIncreaseReached} addonAfter="%" placeholder={languageMessages['PleaseEnterIncrease']} data-value='DailyIncreaseReached' onChange={this.onChangeTargetDecimal} onBlur={this.onBlurTargetDecimal} />
            </Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['condHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='DailyIncreaseReached' /></Col>
        </Row>;
    }

    //日跌幅达到
    renderDailyDeclineReached() {
        return <Row key='DailyDeclineReached' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>{languageMessages['dayDeclineReach']}</Col>
            <Col span={9} className='common-hawkeye-condition'>
                <Input style={{ width: 200 }} size='small' value={this.state.DailyDeclineReached} addonAfter="%" placeholder={languageMessages['PleaseEnterDecline']} data-value='DailyDeclineReached' onChange={this.onChangeTargetDecimal} onBlur={this.onBlurTargetDecimal} />
            </Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['condHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='DailyDeclineReached' /></Col>
        </Row>;
    }

    //涨幅达到
    renderIncrease() {
        return <Row key='Increase' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>
                <Select size='small' value={this.state.Increase01} style={{ width: 100 }} onChange={this.handleChangeIncrease01}>
                    <Option value="1">{languageMessages['1MinInside']}</Option>
                    <Option value="2">{languageMessages['2MinInside']}</Option>
                    <Option value="3">{languageMessages['3MinInside']}</Option>
                    <Option value="5">{languageMessages['5MinInside']}</Option>
                    <Option value="10">{languageMessages['10MinInside']}</Option>
                    <Option value="15">{languageMessages['15MinInside']}</Option>
                    <Option value="30">{languageMessages['30MinInside']}</Option>
                    <Option value="60">{languageMessages['60MinInside']}</Option>
                </Select>
            </Col>
            <Col span={9} className='common-hawkeye-condition'>
                <span style={{ paddingRight: '5px' }}>{languageMessages['increaseReach']}</span>
                <Input style={{ width: 138 }} size='small' value={this.state.Increase02} addonAfter="%" placeholder={languageMessages['PleaseEnterIncrease']} data-value='Increase02' onChange={this.onChangeTargetDecimal} onBlur={this.onBlurTargetDecimal} />
            </Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['condHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='Increase' /></Col>
        </Row>;
    }

    //跌幅达到
    renderDecline() {
        return <Row key='Decline' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>
                <Select size='small' value={this.state.Decline01} style={{ width: 100 }} onChange={this.handleChangeDecline01}>
                    <Option value="1">{languageMessages['1MinInside']}</Option>
                    <Option value="2">{languageMessages['2MinInside']}</Option>
                    <Option value="3">{languageMessages['3MinInside']}</Option>
                    <Option value="5">{languageMessages['5MinInside']}</Option>
                    <Option value="10">{languageMessages['10MinInside']}</Option>
                    <Option value="15">{languageMessages['15MinInside']}</Option>
                    <Option value="30">{languageMessages['30MinInside']}</Option>
                    <Option value="60">{languageMessages['60MinInside']}</Option>
                </Select>
            </Col>
            <Col span={9} className='common-hawkeye-condition'>
                <span style={{ paddingRight: '5px' }}>{languageMessages['declineReach']}</span>
                <Input style={{ width: 138 }} size='small' value={this.state.Decline02} addonAfter="%" placeholder={languageMessages['PleaseEnterDecline']} data-value='Decline02' onChange={this.onChangeTargetDecimal} onBlur={this.onBlurTargetDecimal} />
            </Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['condHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='Decline' /></Col>
        </Row>;
    }

    //价格上涨创
    renderPriceRise() {
        return <Row key='PriceRise' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>{languageMessages['priceUpWound']}</Col>
            <Col span={9} className='common-hawkeye-condition'>
                <Select size='small' value={this.state.PriceRise} style={{ width: 200 }} onChange={this.handleChangePriceRise}>
                    <Option value="5">{languageMessages['5DayHigh']}</Option>
                    <Option value="10">{languageMessages['10DayHigh']}</Option>
                    <Option value="20">{languageMessages['20DayHigh']}</Option>
                    <Option value="30">{languageMessages['30DayHigh']}</Option>
                    <Option value="60">{languageMessages['60DayHigh']}</Option>
                    <Option value="120">{languageMessages['120DayHigh']}</Option>
                    <Option value="200">{languageMessages['200DayHigh']}</Option>
                    <Option value="0">{languageMessages['historyHigh']}</Option>
                </Select>
            </Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['condHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='PriceRise' /></Col>
        </Row>;
    }

    //价格下跌创
    renderPriceFalling() {
        return <Row key='PriceFalling' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>{languageMessages['priceDownWound']}</Col>
            <Col span={9} className='common-hawkeye-condition'>
                <Select size='small' value={this.state.PriceFalling} style={{ width: 200 }} onChange={this.handleChangePriceFalling}>
                    <Option value="5">{languageMessages['5DayLow']}</Option>
                    <Option value="10">{languageMessages['10DayLow']}</Option>
                    <Option value="20">{languageMessages['20DayLow']}</Option>
                    <Option value="30">{languageMessages['30DayLow']}</Option>
                    <Option value="60">{languageMessages['60DayLow']}</Option>
                    <Option value="120">{languageMessages['120DayLow']}</Option>
                    <Option value="200">{languageMessages['200DayLow']}</Option>
                    <Option value="0">{languageMessages['historyLow']}</Option>
                </Select>
            </Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['condHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='PriceFalling' /></Col>
        </Row>;
    }

    //价格上穿
    renderStockPriceWearOn() {
        return <Row key='StockPriceWearOn' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>{languageMessages['StockPriceUp']}</Col>
            <Col span={9} className='common-hawkeye-condition'>
                <Select size='small' value={this.state.StockPriceWearOn01} style={{ width: 100 }} onChange={this.handleChangeStockPriceWearOn01}>
                    <Option value="1">{languageMessages['1MinLine']}</Option>
                    <Option value="2">{languageMessages['2MinLine']}</Option>
                    <Option value="3">{languageMessages['3MinLine']}</Option>
                    <Option value="5">{languageMessages['5MinLine']}</Option>
                    <Option value="10">{languageMessages['10MinLine']}</Option>
                    <Option value="15">{languageMessages['15MinLine']}</Option>
                    <Option value="30">{languageMessages['30MinLine']}</Option>
                    <Option value="60">{languageMessages['60MinLine']}</Option>
                    <Option value="D">{languageMessages['dailyLine']}</Option>
                </Select>
                <span style={{ paddingLeft: '5px' }}></span>
                <Input style={{ width: 100 }} size='small' value={this.state.StockPriceWearOn02} placeholder={languageMessages['PleaseEnterInteger']} data-value='StockPriceWearOn02' onChange={this.onChangeInt} />
                <span style={{ paddingLeft: '5px' }}>{languageMessages['AvgLine']}</span>
            </Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['condHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='StockPriceWearOn' /></Col>
        </Row>;
    }

    //价格下穿
    renderStockPriceFellBelow() {
        return <Row key='StockPriceFellBelow' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>{languageMessages['StockPriceDown']}</Col>
            <Col span={9} className='common-hawkeye-condition'>
                <Select size='small' value={this.state.StockPriceFellBelow01} style={{ width: 100 }} onChange={this.handleChangeStockPriceFellBelow01}>
                    <Option value="1">{languageMessages['1MinLine']}</Option>
                    <Option value="2">{languageMessages['2MinLine']}</Option>
                    <Option value="3">{languageMessages['3MinLine']}</Option>
                    <Option value="5">{languageMessages['5MinLine']}</Option>
                    <Option value="10">{languageMessages['10MinLine']}</Option>
                    <Option value="15">{languageMessages['15MinLine']}</Option>
                    <Option value="30">{languageMessages['30MinLine']}</Option>
                    <Option value="60">{languageMessages['60MinLine']}</Option>
                    <Option value="D">{languageMessages['dailyLine']}</Option>
                </Select>
                <span style={{ paddingLeft: '5px' }}></span>
                <Input style={{ width: 100 }} size='small' value={this.state.StockPriceFellBelow02} placeholder={languageMessages['PleaseEnterInteger']} data-value='StockPriceFellBelow02' onChange={this.onChangeInt} />
                <span style={{ paddingLeft: '5px' }}>{languageMessages['AvgLine']}</span>
            </Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['condHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='StockPriceFellBelow' /></Col>
        </Row>;
    }

    //日换手率
    renderDailyTurnoverRate() {
        return <Row key='DailyTurnoverRate' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>{languageMessages['DailyTurnoverRate']}</Col>
            <Col span={9} className='common-hawkeye-condition'>
                <span style={{ paddingRight: '5px' }}>{languageMessages['reach']}</span>
                <Input style={{ width: window.webType === 'US' ? 181 : 165 }} size='small' value={this.state.DailyTurnoverRate} addonAfter="%" placeholder={languageMessages['pleaseEnterTargetTurnoverRate']} data-value='DailyTurnoverRate' onChange={this.onChangeTargetDecimal} onBlur={this.onBlurTargetDecimal} />
            </Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['condHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='DailyTurnoverRate' /></Col>
        </Row>;
    }

    //KDJ金叉
    renderKDJGoldFork() {
        return <Row key='KDJGoldFork' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>
                <Select size='small' value={this.state.KDJGoldFork} style={{ width: 100 }} onChange={this.handleChangeKDJGoldFork}>
                    <Option value="1">{languageMessages['1MinLine']}</Option>
                    <Option value="2">{languageMessages['2MinLine']}</Option>
                    <Option value="3">{languageMessages['3MinLine']}</Option>
                    <Option value="5">{languageMessages['5MinLine']}</Option>
                    <Option value="10">{languageMessages['10MinLine']}</Option>
                    <Option value="15">{languageMessages['15MinLine']}</Option>
                    <Option value="30">{languageMessages['30MinLine']}</Option>
                    <Option value="60">{languageMessages['60MinLine']}</Option>
                    <Option value="D">{languageMessages['dailyLine']}</Option>
                </Select>
                <span style={{ padding: '0 5px' }}>{languageMessages['KDJGoldFork']}</span>
            </Col>
            <Col span={9} className='common-hawkeye-condition'></Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['condHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='KDJGoldFork' /></Col>
        </Row>;
    }

    //KDJ死叉
    renderKDJDeadFork() {
        return <Row key='KDJDeadFork' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>
                <Select size='small' value={this.state.KDJDeadFork} style={{ width: 100 }} onChange={this.handleChangeKDJDeadFork}>
                    <Option value="1">{languageMessages['1MinLine']}</Option>
                    <Option value="2">{languageMessages['2MinLine']}</Option>
                    <Option value="3">{languageMessages['3MinLine']}</Option>
                    <Option value="5">{languageMessages['5MinLine']}</Option>
                    <Option value="10">{languageMessages['10MinLine']}</Option>
                    <Option value="15">{languageMessages['15MinLine']}</Option>
                    <Option value="30">{languageMessages['30MinLine']}</Option>
                    <Option value="60">{languageMessages['60MinLine']}</Option>
                    <Option value="D">{languageMessages['dailyLine']}</Option>
                </Select>
                <span style={{ padding: '0 5px' }}>{languageMessages['KDJDeadFork']}</span>
            </Col>
            <Col span={9} className='common-hawkeye-condition'></Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['condHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='KDJDeadFork' /></Col>
        </Row>;
    }

    //MACD金叉
    renderMACDGoldFork() {
        return <Row key='MACDGoldFork' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>
                <Select size='small' value={this.state.MACDGoldFork} style={{ width: 100 }} onChange={this.handleChangeMACDGoldFork}>
                    <Option value="1">{languageMessages['1MinLine']}</Option>
                    <Option value="2">{languageMessages['2MinLine']}</Option>
                    <Option value="3">{languageMessages['3MinLine']}</Option>
                    <Option value="5">{languageMessages['5MinLine']}</Option>
                    <Option value="10">{languageMessages['10MinLine']}</Option>
                    <Option value="15">{languageMessages['15MinLine']}</Option>
                    <Option value="30">{languageMessages['30MinLine']}</Option>
                    <Option value="60">{languageMessages['60MinLine']}</Option>
                    <Option value="D">{languageMessages['dailyLine']}</Option>
                </Select>
                <span style={{ padding: '0 5px' }}>{languageMessages['MACDGoldFork']}</span>
            </Col>
            <Col span={9} className='common-hawkeye-condition'></Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['condHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='MACDGoldFork' /></Col>
        </Row>;
    }

    //MACD死叉
    renderMACDDeadFork() {
        return <Row key='MACDDeadFork' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>
                <Select size='small' value={this.state.MACDDeadFork} style={{ width: 100 }} onChange={this.handleChangeMACDDeadFork}>
                    <Option value="1">{languageMessages['1MinLine']}</Option>
                    <Option value="2">{languageMessages['2MinLine']}</Option>
                    <Option value="3">{languageMessages['3MinLine']}</Option>
                    <Option value="5">{languageMessages['5MinLine']}</Option>
                    <Option value="10">{languageMessages['10MinLine']}</Option>
                    <Option value="15">{languageMessages['15MinLine']}</Option>
                    <Option value="30">{languageMessages['30MinLine']}</Option>
                    <Option value="60">{languageMessages['60MinLine']}</Option>
                    <Option value="D">{languageMessages['dailyLine']}</Option>
                </Select>
                <span style={{ padding: '0 5px' }}>{languageMessages['MACDDeadFork']}</span>
            </Col>
            <Col span={9} className='common-hawkeye-condition'></Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['condHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='MACDDeadFork' /></Col>
        </Row>;
    }

    //价格上穿布林线
    renderPriceWearBollinger() {
        return <Row key='PriceWearBollinger' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>
                <Select size='small' value={this.state.PriceWearBollinger01} style={{ width: 100 }} onChange={this.handleChangePriceWearBollinger01}>
                    <Option value="1">{languageMessages['1MinLine']}</Option>
                    <Option value="2">{languageMessages['2MinLine']}</Option>
                    <Option value="3">{languageMessages['3MinLine']}</Option>
                    <Option value="5">{languageMessages['5MinLine']}</Option>
                    <Option value="10">{languageMessages['10MinLine']}</Option>
                    <Option value="15">{languageMessages['15MinLine']}</Option>
                    <Option value="30">{languageMessages['30MinLine']}</Option>
                    <Option value="60">{languageMessages['60MinLine']}</Option>
                    <Option value="D">{languageMessages['dailyLine']}</Option>
                </Select>
                <span style={{ padding: '0 5px' }}>{languageMessages['priceUp']}</span>
            </Col>
            <Col span={9} className='common-hawkeye-condition'>
                <Select size='small' value={this.state.PriceWearBollinger02} style={{ width: 200 }} onChange={this.handleChangePriceWearBollinger02}>
                    <Option value="up">{languageMessages['bollingerOnRail']}</Option>
                    <Option value="mid">{languageMessages['bollingerMidRail']}</Option>
                    <Option value="down">{languageMessages['bollingerLowRail']}</Option>
                </Select>
            </Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['condHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='PriceWearBollinger' /></Col>
        </Row>;
    }

    //价格下穿布林线
    renderPriceDownBollinger() {
        return <Row key='PriceDownBollinger' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>
                <Select size='small' value={this.state.PriceDownBollinger01} style={{ width: 100 }} onChange={this.handleChangePriceDownBollinger01}>
                    <Option value="1">{languageMessages['1MinLine']}</Option>
                    <Option value="2">{languageMessages['2MinLine']}</Option>
                    <Option value="3">{languageMessages['3MinLine']}</Option>
                    <Option value="5">{languageMessages['5MinLine']}</Option>
                    <Option value="10">{languageMessages['10MinLine']}</Option>
                    <Option value="15">{languageMessages['15MinLine']}</Option>
                    <Option value="30">{languageMessages['30MinLine']}</Option>
                    <Option value="60">{languageMessages['60MinLine']}</Option>
                    <Option value="D">{languageMessages['dailyLine']}</Option>
                </Select>
                <span style={{ padding: '0 5px' }}>{languageMessages['priceDown']}</span>
            </Col>
            <Col span={9} className='common-hawkeye-condition'>
                <Select size='small' value={this.state.PriceDownBollinger02} style={{ width: 200 }} onChange={this.handleChangePriceDownBollinger02}>
                    <Option value="up">{languageMessages['bollingerOnRail']}</Option>
                    <Option value="mid">{languageMessages['bollingerMidRail']}</Option>
                    <Option value="down">{languageMessages['bollingerLowRail']}</Option>
                </Select>
            </Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['condHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='PriceDownBollinger' /></Col>
        </Row>;
    }

    //20200605
    renderBuyOneHigherThan() {
        return <Row key='BuyOneHigherThan' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>{languageMessages['BuyOneHigherThan']}</Col>
            <Col span={9} className='common-hawkeye-condition'>
                <Input style={{ width: 200 }} size='small' value={this.state.BuyOneHigherThan} addonBefore={languageMessages['usd']} addonAfter={languageMessages['yuan']} placeholder={languageMessages['PleaseEnterTargetPrice']} data-value='BuyOneHigherThan' onChange={this.onChangeTargetDecimal} onBlur={this.onBlurTargetDecimal} />
            </Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['condHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='BuyOneHigherThan' /></Col>
        </Row>;
    }

    renderSellPriceBelow() {
        return <Row key='SellPriceBelow' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>{languageMessages['SellPriceBelow']}</Col>
            <Col span={9} className='common-hawkeye-condition'>
                <Input style={{ width: 200 }} size='small' value={this.state.SellPriceBelow} addonBefore={languageMessages['usd']} addonAfter={languageMessages['yuan']} placeholder={languageMessages['PleaseEnterTargetPrice']} data-value='SellPriceBelow' onChange={this.onChangeTargetDecimal} onBlur={this.onBlurTargetDecimal} />
            </Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['condHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='SellPriceBelow' /></Col>
        </Row>;
    }

    renderReachHigh() {
        return <Row key='ReachHigh' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>{languageMessages['ReachHigh']}</Col>
            <Col span={9} className='common-hawkeye-condition'>
                <Input style={{ width: 200 }} size='small' value={this.state.ReachHigh} addonAfter="%" placeholder={languageMessages['PleaseEnterTargetExtent']} data-value='ReachHigh' onChange={this.onChangeTargetDecimal} onBlur={this.onBlurTargetDecimal} />
            </Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['condHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='ReachHigh' /></Col>
        </Row>;
    }

    renderGapReachHigh() {
        return <Row key='GapReachHigh' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>{languageMessages['GapReachHigh']}</Col>
            <Col span={9} className='common-hawkeye-condition'>
                <Input style={{ width: 200 }} size='small' value={this.state.GapReachHigh} addonAfter="%" placeholder={languageMessages['PleaseEnterTargetExtent']} data-value='GapReachHigh' onChange={this.onChangeTargetDecimal} onBlur={this.onBlurTargetDecimal} />
            </Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['condHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='GapReachHigh' /></Col>
        </Row>;
    }

    renderVolume() {
        return <Row key='Volume' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>
                <span style={{ paddingRight: '5px' }}>{languageMessages['VolumeIsPast']}</span>
                <Input style={{ width: 125 }} size='small' value={this.state.Volume01} addonAfter={languageMessages['day']} placeholder={languageMessages['PleaseEnterDays']} data-value='Volume01' onChange={this.onChangeInt} />
                <span style={{ paddingLeft: '5px' }}>{languageMessages['Average']}</span>
            </Col>
            <Col span={9} className='common-hawkeye-condition'>
                <Input style={{ width: 200 }} size='small' value={this.state.Volume02} addonAfter={languageMessages['multiple']} placeholder={languageMessages['PleaseEnterMultiple']} data-value='Volume02' onChange={this.onChangeTargetDecimal} onBlur={this.onBlurTargetDecimal} />
            </Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['condHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='Volume' /></Col>
        </Row>;
    }

    renderVolumeRatio() {
        return <Row key='VolumeRatio' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>{languageMessages['VolumeRatioThan']}</Col>
            <Col span={9} className='common-hawkeye-condition'>
                <Input style={{ width: 200 }} size='small' value={this.state.VolumeRatio} placeholder={languageMessages['PleaseEnterVolumeRatio']} data-value='VolumeRatio' onChange={this.onChangeTargetDecimal} onBlur={this.onBlurTargetDecimal} />
            </Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['condHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='VolumeRatio' /></Col>
        </Row>;
    }

    renderDailyAmplitude() {
        return <Row key='DailyAmplitude' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>{languageMessages['DailyAmplitude']}</Col>
            <Col span={9} className='common-hawkeye-condition'>
                <span style={{ paddingRight: '5px' }}>{languageMessages['reach']}</span>
                <Input style={{ width: window.webType === 'US' ? 181 : 165 }} size='small' value={this.state.DailyAmplitude} addonAfter="%" placeholder={languageMessages['PleaseEnterTargetAmplitude']} data-value='DailyAmplitude' onChange={this.onChangeTargetDecimal} onBlur={this.onBlurTargetDecimal} />
            </Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['condHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='DailyAmplitude' /></Col>
        </Row>;
    }

    renderAnyOnlineWear() {
        return <Row key='AnyOnlineWear' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>
                <Select size='small' value={this.state.AnyOnlineWear01} style={{ width: 100 }} onChange={this.handleChangeAnyOnlineWear01}>
                    <Option value="1">{languageMessages['1MinLine']}</Option>
                    <Option value="2">{languageMessages['2MinLine']}</Option>
                    <Option value="3">{languageMessages['3MinLine']}</Option>
                    <Option value="5">{languageMessages['5MinLine']}</Option>
                    <Option value="10">{languageMessages['10MinLine']}</Option>
                    <Option value="15">{languageMessages['15MinLine']}</Option>
                    <Option value="30">{languageMessages['30MinLine']}</Option>
                    <Option value="60">{languageMessages['60MinLine']}</Option>
                    <Option value="D">{languageMessages['dailyLine']}</Option>
                </Select>
                <span style={{ paddingLeft: '5px' }}></span>
                <Input style={{ width: 100 }} size='small' value={this.state.AnyOnlineWear02} placeholder={languageMessages['PleaseEnterInteger']} data-value='AnyOnlineWear02' onChange={this.onChangeInt} />
                <span style={{ paddingLeft: '5px' }}>{languageMessages['AvgLine']}</span>
            </Col>
            <Col span={9} className='common-hawkeye-condition'>
                <span style={{ paddingRight: '5px' }}>{languageMessages['UpperOvergo']}</span>
                <Select size='small' value={this.state.AnyOnlineWear03} style={{ width: 100 }} onChange={this.handleChangeAnyOnlineWear03}>
                    <Option value="1">{languageMessages['1MinLine']}</Option>
                    <Option value="2">{languageMessages['2MinLine']}</Option>
                    <Option value="3">{languageMessages['3MinLine']}</Option>
                    <Option value="5">{languageMessages['5MinLine']}</Option>
                    <Option value="10">{languageMessages['10MinLine']}</Option>
                    <Option value="15">{languageMessages['15MinLine']}</Option>
                    <Option value="30">{languageMessages['30MinLine']}</Option>
                    <Option value="60">{languageMessages['60MinLine']}</Option>
                    <Option value="D">{languageMessages['dailyLine']}</Option>
                </Select>
                <span style={{ paddingLeft: '5px' }}></span>
                <Input style={{ width: 100 }} size='small' value={this.state.AnyOnlineWear04} placeholder={languageMessages['PleaseEnterInteger']} data-value='AnyOnlineWear04' onChange={this.onChangeInt} />
                <span style={{ paddingLeft: '5px' }}>{languageMessages['AvgLine']}</span>
            </Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['condHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='AnyOnlineWear' /></Col>
        </Row>;
    }

    renderAnyMovingAverage() {
        return <Row key='AnyMovingAverage' className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>
                <Select size='small' value={this.state.AnyMovingAverage01} style={{ width: 100 }} onChange={this.handleChangeAnyMovingAverage01}>
                    <Option value="1">{languageMessages['1MinLine']}</Option>
                    <Option value="2">{languageMessages['2MinLine']}</Option>
                    <Option value="3">{languageMessages['3MinLine']}</Option>
                    <Option value="5">{languageMessages['5MinLine']}</Option>
                    <Option value="10">{languageMessages['10MinLine']}</Option>
                    <Option value="15">{languageMessages['15MinLine']}</Option>
                    <Option value="30">{languageMessages['30MinLine']}</Option>
                    <Option value="60">{languageMessages['60MinLine']}</Option>
                    <Option value="D">{languageMessages['dailyLine']}</Option>
                </Select>
                <span style={{ paddingLeft: '5px' }}></span>
                <Input style={{ width: 100 }} size='small' value={this.state.AnyMovingAverage02} placeholder={languageMessages['PleaseEnterInteger']} data-value='AnyMovingAverage02' onChange={this.onChangeInt} />
                <span style={{ paddingLeft: '5px' }}>{languageMessages['AvgLine']}</span>
            </Col>
            <Col span={9} className='common-hawkeye-condition'>
                <span style={{ paddingRight: '5px' }}>{languageMessages['UnderOvergo']}</span>
                <Select size='small' value={this.state.AnyMovingAverage03} style={{ width: 100 }} onChange={this.handleChangeAnyMovingAverage03}>
                    <Option value="1">{languageMessages['1MinLine']}</Option>
                    <Option value="2">{languageMessages['2MinLine']}</Option>
                    <Option value="3">{languageMessages['3MinLine']}</Option>
                    <Option value="5">{languageMessages['5MinLine']}</Option>
                    <Option value="10">{languageMessages['10MinLine']}</Option>
                    <Option value="15">{languageMessages['15MinLine']}</Option>
                    <Option value="30">{languageMessages['30MinLine']}</Option>
                    <Option value="60">{languageMessages['60MinLine']}</Option>
                    <Option value="D">{languageMessages['dailyLine']}</Option>
                </Select>
                <span style={{ paddingLeft: '5px' }}></span>
                <Input style={{ width: 100 }} size='small' value={this.state.AnyMovingAverage04} placeholder={languageMessages['PleaseEnterInteger']} data-value='AnyMovingAverage04' onChange={this.onChangeInt} />
                <span style={{ paddingLeft: '5px' }}>{languageMessages['AvgLine']}</span>
            </Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['condHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value='AnyMovingAverage' /></Col>
        </Row>;
    }

    renderVernacularCondition(VernacularCondition) {
        var useFormulas = [];
        var items = VernacularCondition.formula.split('\n');
        for (var item in items) {
            if (useFormulas.length > 0) useFormulas.push(<br key={item} />);
            useFormulas.push(items[item]);
        }

        return <Row key={VernacularCondition.key} className='common-hawkeye-cell'>
            <Col span={9} className='common-hawkeye-condition'>{VernacularCondition.name}</Col>
            <Col span={9} className='common-hawkeye-condition'><code>{useFormulas}</code></Col>
            <Col span={4} className='common-hawkeye-condition'>{languageMessages['vernacularHawkeye']}</Col>
            <Col span={2}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value={VernacularCondition.key} /></Col>
        </Row>;
    }

    render() {
        return (<div style={{ display: this.props.hidden }}>
            <Row className='common-hawkeye-head'>
                <Col span={9} className='common-hawkeye-condition'>{languageMessages['hCondition']}</Col>
                <Col span={9} className='common-hawkeye-condition'>{languageMessages['correspond.formula'] +'\\'+ languageMessages['value']}</Col>
                <Col span={4} className='common-hawkeye-condition'>{languageMessages['type']}</Col>
                <Col span={2}>{languageMessages['control']}</Col>
            </Row>
            {
                this.state.conditionContent.map(item => {
                    return item.cont;
                })
            }
        </div>);
    }
}

export { HawkeyeCondition as default }