import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../helpers/history'
import store from '../helpers/store'

class RestrictedPage extends Component {
    constructor(props) {
        super(props);

        this.loggedin = false;
    }

    componentWillMount() {
        var user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        if (user.token !== undefined) {
            this.loggedin = true;
            store.dispatch({ type: 'Normal_User', user: user, token: user.token });
        } else {
            this.loggedin = false;
            history.replace("/login?next=" + window.location.pathname);
        }
    }

    render() {
        const { children } = this.props;

        return this.loggedin ? children : <div></div>;
    }
}

const mapStateToProps = (state) => ({
    token: state.member ? state.member.token : undefined
});

const CRestrictedPage = connect(mapStateToProps)(RestrictedPage)

export { CRestrictedPage as RestrictedPage }