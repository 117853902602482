import React from 'react';
import { Tooltip, Icon } from 'antd';

export function PageTitle(props) {
    if (props.position === "sub") {
        return (<div style={{ paddingLeft: "10px", borderLeft: "4px solid #1C9FCE", marginTop: "10px", ...props.style }}>
            <span style={{ paddingLeft: "2px", paddingRight: "8px" }}> {props.title} </span>
            {props.circle === undefined ? '' : (<Tooltip placement="bottomLeft" title={props.circle}>
                <Icon type="question-circle" />
            </Tooltip>)}
        </div>)
    } else if (props.position === "title") {
        var subTitle = ""
        if (props.subTitle !== undefined) subTitle = props.subTitle
        return (<div className="title">{props.title}<span style={{ fontSize: "9px" }}>{subTitle}</span></div>)
    } else {
        return (<div className="row-container"><p className="page-title">{props.title}</p></div>)
    }  
}