export default function fail(state = {}, action) {
    switch (action.type) {
        case "get_captcha":
            return { ...state, status: '' };

        case "login_commit":
            return { ...state, status: '' };
        case "login_success":
            return { ...state, status: '' };
        case "login_failed":
            return { ...state, status: '' };

        case "register_commit":
            return { ...state, status: '' };
        case "register_success":
            return { ...state, status: '' };
        case "register_failed":
            return { ...state, status: '' };

        case "forget_commit":
            return { ...state, status: '' };
        case "forget_success":
            return { ...state, status: '' };
        case "forget_failed":
            return { ...state, status: '' };

        case "jump":
            return { ...state, status: '' };

        //发送signal消息
        case "sendHubMessage":
            return { ...state, status: '' };

        case "non_user":
            return { ...state, status: 'non_user' };

        default:
            return state;
    }
}