const FilterFunction = [
    {
        "n": "001001001",
        "cn": "BarClose",
        "en": "",
        "mcn": "K线的收盘价",
        "men": "",
        "m2": "BarClose('D', -1)"
    },
    {
        "n": "001001001001",
        "cn": "Sym_BarClose",
        "en": "",
        "mcn": "K线的收盘价",
        "men": "",
        "m2": "Sym_BarClose('SZ000001','D', -1)"
    },
    {
        "n": "001001002",
        "cn": "BarHigh",
        "en": "",
        "mcn": "K线的最高价 ",
        "men": "",
        "m2": "BarHigh('D', -1)"
    },
    {
        "n": "001001002001",
        "cn": "Sym_BarHigh",
        "en": "",
        "mcn": "K线的最高价 ",
        "men": "",
        "m2": "Sym_BarHigh('SZ000001','D', -1)"
    },
    {
        "n": "001001003",
        "cn": "BarLow",
        "en": "",
        "mcn": "K线的最低价",
        "men": "",
        "m2": "BarLow('D', -1)"
    },
    {
        "n": "001001003001",
        "cn": "Sym_BarLow",
        "en": "",
        "mcn": "K线的最低价",
        "men": "",
        "m2": "Sym_BarLow('SZ000001','D', -1)"
    },
    {
        "n": "001001004",
        "cn": "BarOpen",
        "en": "",
        "mcn": "K线的开盘价",
        "men": "",
        "m2": "BarOpen('D', -1)"
    },
    {
        "n": "001001004001",
        "cn": "Sym_BarOpen",
        "en": "",
        "mcn": "K线的开盘价",
        "men": "",
        "m2": "Sym_BarOpen('SZ000001','D', -1)"
    },
    {
        "n": "001001005",
        "cn": "BarRange",
        "en": "",
        "mcn": "K线的振幅",
        "men": "",
        "m2": "BarRange('D', -1)"
    },
    {
        "n": "001001005001",
        "cn": "Sym_BarRange",
        "en": "",
        "mcn": "K线的振幅",
        "men": "",
        "m2": "Sym_BarRange('SZ000001','D', -1)"
    },
    {
        "n": "001001006",
        "cn": "BarBody",
        "en": "",
        "mcn": "K线的实体",
        "men": "",
        "m2": "BarBody('D', -1)"
    },
    {
        "n": "001001006001",
        "cn": "Sym_BarBody",
        "en": "",
        "mcn": "K线的实体",
        "men": "",
        "m2": "Sym_BarBody('SZ000001','D', -1)"
    },
    {
        "n": "001001007",
        "cn": "BarBodySize",
        "en": "",
        "mcn": "K线实体的绝对值",
        "men": "",
        "m2": "BarBodySize('D', -1)"
    },
    {
        "n": "001001007001",
        "cn": "Sym_BarBodySize",
        "en": "",
        "mcn": "K线实体的绝对值",
        "men": "",
        "m2": "Sym_BarBodySize('SZ000001','D', -1)"
    },
    {
        "n": "001001008",
        "cn": "BarVolume",
        "en": "",
        "mcn": "K线的成交量",
        "men": "",
        "m2": "BarVolume('D', -1)"
    },
    {
        "n": "001001008001",
        "cn": "Sym_BarVolume",
        "en": "",
        "mcn": "K线的成交量",
        "men": "",
        "m2": "Sym_BarVolume('SZ000001','D', -1)"
    },
    {
        "n": "001001009",
        "cn": "BarVwap",
        "en": "",
        "mcn": "K线的平均成交价",
        "men": "",
        "m2": "BarVwap('D', -1)"
    },
    {
        "n": "001001009001",
        "cn": "Sym_BarVwap",
        "en": "",
        "mcn": "K线的平均成交价",
        "men": "",
        "m2": "Sym_BarVwap('SZ000001','D', -1)"
    },
    {
        "n": "001001010",
        "cn": "HistoryLow",
        "en": "",
        "mcn": "历史最低价",
        "men": "",
        "m2": "HistoryLow('bufuquan/qianfuquan', '$out_date')"
    },
    {
        "n": "001001010001",
        "cn": "Sym_HistoryLow",
        "en": "",
        "mcn": "历史最低价",
        "men": "",
        "m2": "Sym_HistoryLow('SZ000001','bufuquan/qianfuquan', '$out_date')"
    },
    {
        "n": "001001011",
        "cn": "HistoryHigh",
        "en": "",
        "mcn": "历史最高价",
        "men": "",
        "m2": "HistoryHigh('bufuquan/qianfuquan', '$out_date')"
    },
    {
        "n": "001001011001",
        "cn": "Sym_HistoryHigh",
        "en": "",
        "mcn": "历史最高价",
        "men": "",
        "m2": "Sym_HistoryHigh('SZ000001','bufuquan/qianfuquan', '$out_date')"
    },
    {
        "n": "001001012",
        "cn": "CapitalStream",
        "en": "",
        "mcn": "获取日内资金流",
        "men": "",
        "m2": "CapitalStream('total', '60', -1, '$volume', '$amount', '$involume', '$inamount', '$outvolume', '$outamount')"
    },
    {
        "n": "001001012001",
        "cn": "Sym_CapitalStream",
        "en": "",
        "mcn": "获取日内资金流",
        "men": "",
        "m2": "Sym_CapitalStream('SZ000001','total', '60', -1, '$volume', '$amount', '$involume', '$inamount', '$outvolume', '$outamount')"
    },
    {
        "n": "001001012002",
        "cn": "Sym_TSN_BarData",
        "en": "",
        "mcn": "K线表达式的时间序列",
        "men": "",
        "m2": "Sym_TSN_BarData('SZ000001','CLOSE', 'D')"
    },
    {
        "n": "001001013",
        "cn": "TSN_BarData",
        "en": "",
        "mcn": "K线表达式的时间序列",
        "men": "",
        "m2": "TSN_BarData('CLOSE', 'D')"
    },
    {
        "n": "001002001",
        "cn": "MaxBarBodySize",
        "en": "",
        "mcn": "一系列K线的实体的绝对值的的最大值",
        "men": "",
        "m2": "MaxBarBodySize('D', 'ALL', -10, -1 )"
    },
    {
        "n": "001002001001",
        "cn": "Sym_MaxBarBodySize",
        "en": "",
        "mcn": "一系列K线的实体的绝对值的的最大值",
        "men": "",
        "m2": "Sym_MaxBarBodySize('D', 'ALL', -10, -1 )"
    },
    {
        "n": "001002002",
        "cn": "MinBarBodySize",
        "en": "",
        "mcn": "一系列K线的实体的绝对值的的最小值",
        "men": "",
        "m2": "MinBarBodySize('D', 'ALL', -10, -1 )"
    },
    {
        "n": "001002002001",
        "cn": "Sym_MinBarBodySize",
        "en": "",
        "mcn": "一系列K线的实体的绝对值的的最小值",
        "men": "",
        "m2": "Sym_MinBarBodySize('SZ000001','D', 'ALL', -10, -1 )"
    },
    {
        "n": "001002003",
        "cn": "StdDevBarRange",
        "en": "",
        "mcn": "一系列K线振幅的标准差",
        "men": "",
        "m2": "StdDevBarRange('D', -10, -1)"
    },
    {
        "n": "001002003001",
        "cn": "Sym_StdDevBarRange",
        "en": "",
        "mcn": "一系列K线振幅的标准差",
        "men": "",
        "m2": "Sym_StdDevBarRange('SZ000001','D', -10, -1)"
    },
    {
        "n": "001002004",
        "cn": "SmaBarRange ",
        "en": "",
        "mcn": "一系列K线振幅的简单移动平均值",
        "men": "",
        "m2": "SmaBarRange ('D', -10, -1)"
    },
    {
        "n": "001002004001",
        "cn": "Sym_SmaBarRange ",
        "en": "",
        "mcn": "一系列K线振幅的简单移动平均值",
        "men": "",
        "m2": "Sym_SmaBarRange('SZ000001','D', -10, -1)"
    },
    {
        "n": "001002005",
        "cn": "PeriodLow",
        "en": "",
        "mcn": "一系列K线的最低价",
        "men": "",
        "m2": "PeriodLow('D', -10, -1)"
    },
    {
        "n": "001002005001",
        "cn": "Sym_PeriodLow",
        "en": "",
        "mcn": "一系列K线的最低价",
        "men": "",
        "m2": "Sym_PeriodLow('SZ000001','D', -10, -1)"
    },
    {
        "n": "001002006",
        "cn": "PeriodHigh",
        "en": "",
        "mcn": "一系列K线的最高价",
        "men": "",
        "m2": "PeriodHigh('D', -10, -1)"
    },
    {
        "n": "001002006001",
        "cn": "Sym_PeriodHigh",
        "en": "",
        "mcn": "一系列K线的最高价",
        "men": "",
        "m2": "Sym_PeriodHigh('D', -10, -1)"
    },
    {
        "n": "001002007",
        "cn": "PeriodLow2",
        "en": "",
        "mcn": "一系列K线的最低价以及位置和时间",
        "men": "",
        "m2": "PeriodLow2('D', -10, -1,'$out_index','$out_time_t')"
    },
    {
        "n": "001002007001",
        "cn": "Sym_PeriodLow2",
        "en": "",
        "mcn": "一系列K线的最低价以及位置和时间",
        "men": "",
        "m2": "Sym_PeriodLow2('SZ000001','D', -10, -1, '$out_index', '$out_time_t')"
    },
    {
        "n": "001002008",
        "cn": "PeriodHigh2",
        "en": "",
        "mcn": "一系列K线的最高价以及位置和时间",
        "men": "",
        "m2": "PeriodHigh2('D', -10, -1, '$out_index', '$out_time_t')"
    },
    {
        "n": "001002008001",
        "cn": "Sym_PeriodHigh2",
        "en": "",
        "mcn": "一系列K线的最高价以及位置和时间",
        "men": "",
        "m2": "Sym_PeriodHigh2('SZ000001','D', -10, -1, '$out_index', '$out_time_t')"
    },
    {
        "n": "001002009",
        "cn": "PeriodHigh3",
        "en": "",
        "mcn": "一系列K线的表达式的最大值以及位置和时间",
        "men": "",
        "m2": "PeriodHigh3('CLOSE','D', -10, -1, '$out_index', '$out_time_t')"
    },
    {
        "n": "001002009001",
        "cn": "Sym_PeriodHigh3",
        "en": "",
        "mcn": "一系列K线的表达式的最大值以及位置和时间",
        "men": "",
        "m2": "Sym_PeriodHigh3('SZ000001','CLOSE','D', -10, -1, '$out_index', '$out_time_t')"
    },
    {
        "n": "001002010",
        "cn": "PeriodLow3",
        "en": "",
        "mcn": "一系列K线的表达式的最小值以及位置和时间",
        "men": "",
        "m2": "PeriodLow3('CLOSE','D', -10, -1, '$out_index', '$out_time_t')"
    },
    {
        "n": "001002010001",
        "cn": "Sym_PeriodLow3",
        "en": "",
        "mcn": "一系列K线的表达式的最小值以及位置和时间",
        "men": "",
        "m2": "Sym_PeriodLow3('SZ000001','CLOSE','D', -10, -1,'$out_index','$out_time_t')"
    },
    {
        "n": "001002011",
        "cn": "TS_PeriodHigh",
        "en": "",
        "mcn": "一系列时间序列的最大值以及位置",
        "men": "",
        "m2": "TS_PeriodHigh(@ , -10, -1, '$out_index' )"
    },
    {
        "n": "001002011001",
        "cn": "Sym_TS_PeriodHigh",
        "en": "",
        "mcn": "一系列时间序列的最大值以及位置",
        "men": "",
        "m2": "Sym_TS_PeriodHigh('SZ000001',@ , -10, -1, '$out_index')"
    },
    {
        "n": "001002012",
        "cn": "TS_PeriodLow",
        "en": "",
        "mcn": "一系列时间序列的最小值以及位置",
        "men": "",
        "m2": "TS_PeriodLow(@ , -10, -1, '$out_index' )"
    },
    {
        "n": "001002012001",
        "cn": "Sym_TS_PeriodLow",
        "en": "",
        "mcn": "一系列时间序列的最小值以及位置",
        "men": "",
        "m2": "Sym_TS_PeriodLow('SZ000001',@ ,-10, -1, '$out_index')"
    },
    {
        "n": "001003001",
        "cn": "BidPrice",
        "en": "",
        "mcn": "十档盘口买入价",
        "men": "",
        "m2": "BidPrice(1)"
    },
    {
        "n": "001003001001",
        "cn": "Sym_BidPrice",
        "en": "",
        "mcn": "十档盘口买入价",
        "men": "",
        "m2": "Sym_BidPrice('SZ000001',1)"
    },
    {
        "n": "001003002",
        "cn": "AskPrice",
        "en": "",
        "mcn": "十档盘口卖出价",
        "men": "",
        "m2": "AskPrice(1)"
    },
    {
        "n": "001003002001",
        "cn": "Sym_AskPrice",
        "en": "",
        "mcn": "十档盘口卖出价",
        "men": "",
        "m2": "Sym_AskPrice('SZ000001',1)"
    },
    {
        "n": "001003003",
        "cn": "BidSize",
        "en": "",
        "mcn": "十档盘口买盘挂单量",
        "men": "",
        "m2": "BidSize(1)"
    },
    {
        "n": "001003003001",
        "cn": "Sym_BidSize",
        "en": "",
        "mcn": "十档盘口买盘挂单量",
        "men": "",
        "m2": "Sym_BidSize('SZ000001',1)"
    },
    {
        "n": "001003004",
        "cn": "AskSize",
        "en": "",
        "mcn": "十档盘口卖盘挂单量",
        "men": "",
        "m2": "AskSize(1)"
    },
    {
        "n": "001003004001",
        "cn": "Sym_AskSize",
        "en": "",
        "mcn": "十档盘口卖盘挂单量",
        "men": "",
        "m2": "Sym_AskSize('SZ000001',1)"
    },
    {
        "n": "001003005",
        "cn": "BID",
        "en": "",
        "mcn": "最新买一价",
        "men": "",
        "m2": "BID"
    },
    {
        "n": "001003005001",
        "cn": "Sym_BID",
        "en": "",
        "mcn": "最新买一价",
        "men": "",
        "m2": "Sym_BID('SZ000001')"
    },
    {
        "n": "001003006",
        "cn": "BID2",
        "en": "",
        "mcn": "前一个买一价",
        "men": "",
        "m2": "BID2"
    },
    {
        "n": "001003006001",
        "cn": "Sym_BID2",
        "en": "",
        "mcn": "前一个买一价",
        "men": "",
        "m2": "Sym_BID2('SZ000001')"
    },
    {
        "n": "001003007",
        "cn": "BID3",
        "en": "",
        "mcn": "前两个买一价",
        "men": "",
        "m2": "BID3"
    },
    {
        "n": "001003007001",
        "cn": "Sym_BID3",
        "en": "",
        "mcn": "前两个买一价",
        "men": "",
        "m2": "Sym_BID3('SZ000001')"
    },
    {
        "n": "001003008",
        "cn": "BID4",
        "en": "",
        "mcn": "前三个买一价",
        "men": "",
        "m2": "BID4"
    },
    {
        "n": "001003008001",
        "cn": "Sym_BID4",
        "en": "",
        "mcn": "前三个买一价",
        "men": "",
        "m2": "Sym_BID4('SZ000001')"
    },
    {
        "n": "001003009",
        "cn": "BIDSIZE",
        "en": "",
        "mcn": "最新买一挂单量",
        "men": "",
        "m2": "BIDSIZE"
    },
    {
        "n": "001003009001",
        "cn": "Sym_BIDSIZE",
        "en": "",
        "mcn": "最新买一挂单量",
        "men": "",
        "m2": "Sym_BidSize ('SZ000001')"
    },
    {
        "n": "001003010",
        "cn": "BID2SIZE",
        "en": "",
        "mcn": "前一个买一挂单量",
        "men": "",
        "m2": "BID2SIZE"
    },
    {
        "n": "001003010001",
        "cn": "Sym_BID2SIZE",
        "en": "",
        "mcn": "前一个买一挂单量",
        "men": "",
        "m2": "Sym_BID2SIZE('SZ000001')"
    },
    {
        "n": "001003011",
        "cn": "BID3SIZE",
        "en": "",
        "mcn": "前两个买一挂单量  ",
        "men": "",
        "m2": "BID3SIZE"
    },
    {
        "n": "001003011001",
        "cn": "Sym_BID3SIZE",
        "en": "",
        "mcn": "前两个买一挂单量  ",
        "men": "",
        "m2": "Sym_BID3SIZE('SZ000001')"
    },
    {
        "n": "001003012",
        "cn": "BID4SIZE",
        "en": "",
        "mcn": "前三个买一挂单量                    ",
        "men": "",
        "m2": "BID4SIZE"
    },
    {
        "n": "001003012001",
        "cn": "Sym_BID4SIZE",
        "en": "",
        "mcn": "前三个买一挂单量                    ",
        "men": "",
        "m2": "Sym_BID4SIZE('SZ000001')"
    },
    {
        "n": "001003013",
        "cn": "BIDTIME",
        "en": "",
        "mcn": "最新买一挂单时间",
        "men": "",
        "m2": "BIDTIME"
    },
    {
        "n": "001003013001",
        "cn": "Sym_BIDTIME",
        "en": "",
        "mcn": "买一挂单时间",
        "men": "",
        "m2": "Sym_BIDTIME('SZ000001')"
    },
    {
        "n": "001003014",
        "cn": "ASK",
        "en": "",
        "mcn": "最新卖一价",
        "men": "",
        "m2": "ASK"
    },
    {
        "n": "001003014001",
        "cn": "Sym_ASK",
        "en": "",
        "mcn": "卖一价",
        "men": "",
        "m2": "Sym_ASK('SZ000001')"
    },
    {
        "n": "001003015",
        "cn": "ASK2",
        "en": "",
        "mcn": "前一个卖一价",
        "men": "",
        "m2": "ASK2"
    },
    {
        "n": "001003015001",
        "cn": "Sym_ASK2",
        "en": "",
        "mcn": "前一个卖一价",
        "men": "",
        "m2": "Sym_ASK2('SZ000001')"
    },
    {
        "n": "001003016",
        "cn": "ASK3",
        "en": "",
        "mcn": "前两个卖一价",
        "men": "",
        "m2": "ASK3"
    },
    {
        "n": "001003016001",
        "cn": "Sym_ASK3",
        "en": "",
        "mcn": "前两个卖一价",
        "men": "",
        "m2": "Sym_ASK3('SZ000001')"
    },
    {
        "n": "001003017",
        "cn": "ASK4",
        "en": "",
        "mcn": "前三个卖一价",
        "men": "",
        "m2": "ASK4"
    },
    {
        "n": "001003017001",
        "cn": "Sym_ASK4",
        "en": "",
        "mcn": "前三个卖一价",
        "men": "",
        "m2": "Sym_ASK4('SZ000001')"
    },
    {
        "n": "001003018",
        "cn": "ASKSIZE",
        "en": "",
        "mcn": "最新卖一挂单量",
        "men": "",
        "m2": "ASKSIZE"
    },
    {
        "n": "001003018001",
        "cn": "Sym_ASKSIZE",
        "en": "",
        "mcn": "最新卖一价",
        "men": "",
        "m2": "Sym_ASKSIZE('SZ000001')"
    },
    {
        "n": "001003019",
        "cn": "ASK2SIZE",
        "en": "",
        "mcn": "前一个卖一挂单量",
        "men": "",
        "m2": "ASK2SIZE"
    },
    {
        "n": "001003019001",
        "cn": "Sym_ASK2SIZE",
        "en": "",
        "mcn": "前一个卖一挂单量",
        "men": "",
        "m2": "Sym_ASK2SIZE('SZ000001')"
    },
    {
        "n": "001003020",
        "cn": "ASK3SIZE",
        "en": "",
        "mcn": "前两个卖一挂单量  ",
        "men": "",
        "m2": "ASK3SIZE"
    },
    {
        "n": "001003020001",
        "cn": "Sym_ASK3SIZE",
        "en": "",
        "mcn": "前两个卖一挂单量  ",
        "men": "",
        "m2": "Sym_ASK3SIZE('SZ000001')"
    },
    {
        "n": "001003021",
        "cn": "ASK4SIZE",
        "en": "",
        "mcn": "前三个卖一挂单量                    ",
        "men": "",
        "m2": "ASK4SIZE"
    },
    {
        "n": "001003021001",
        "cn": "Sym_ASK4SIZE",
        "en": "",
        "mcn": "前三个卖一挂单量                    ",
        "men": "",
        "m2": "Sym_ASK4SIZE('SZ000001')"
    },
    {
        "n": "001003022",
        "cn": "ASKTIME",
        "en": "",
        "mcn": "最新卖一挂单时间",
        "men": "",
        "m2": "ASKTIME"
    },
    {
        "n": "001003022001",
        "cn": "Sym_ASKTIME",
        "en": "",
        "mcn": "最新卖一挂单时间",
        "men": "",
        "m2": "Sym_ASKTIME('SZ000001')"
    },
    {
        "n": "001004001",
        "cn": "BaSpread1MinuteMin",
        "en": "",
        "mcn": "前1分钟的买卖差价的最小值",
        "men": "",
        "m2": "BaSpread1MinuteMin()"
    },
    {
        "n": "001004001001",
        "cn": "Sym_BaSpread1MinuteMin",
        "en": "",
        "mcn": "前1分钟的买卖差价的最小值",
        "men": "",
        "m2": "Sym_BaSpread1MinuteMin('SZ000001')"
    },
    {
        "n": "001004002",
        "cn": "BaSpread1MinuteMax",
        "en": "",
        "mcn": "前1分钟的买卖差价的最大值",
        "men": "",
        "m2": "BaSpread1MinuteMax()"
    },
    {
        "n": "001004002001",
        "cn": "Sym_BaSpread1MinuteMax",
        "en": "",
        "mcn": "前1分钟的买卖差价的最大值",
        "men": "",
        "m2": "Sym_BaSpread1MinuteMax('SZ000001')"
    },
    {
        "n": "001004003",
        "cn": "BaSpread1MinuteAvg",
        "en": "",
        "mcn": "前1分钟的买卖差价的平均值",
        "men": "",
        "m2": "BaSpread1MinuteAvg()"
    },
    {
        "n": "001004003001",
        "cn": "Sym_BaSpread1MinuteAvg",
        "en": "",
        "mcn": "前1分钟的买卖差价的平均值",
        "men": "",
        "m2": "Sym_BaSpread1MinuteAvg('SZ000001')"
    },
    {
        "n": "001004004",
        "cn": "BaSpread5MinuteMin",
        "en": "",
        "mcn": "前5分钟的买卖差价的最小值",
        "men": "",
        "m2": "BaSpread5MinuteMin()"
    },
    {
        "n": "001004004001",
        "cn": "Sym_BaSpread5MinuteMin",
        "en": "",
        "mcn": "前5分钟的买卖差价的最小值",
        "men": "",
        "m2": "Sym_BaSpread5MinuteMin('SZ000001')"
    },
    {
        "n": "001004005",
        "cn": "BaSpread5MinuteMax",
        "en": "",
        "mcn": "前5分钟的买卖差价的最大值",
        "men": "",
        "m2": "BaSpread5MinuteMax()"
    },
    {
        "n": "001004005001",
        "cn": "Sym_BaSpread5MinuteMax",
        "en": "",
        "mcn": "前5分钟的买卖差价的最大值",
        "men": "",
        "m2": "Sym_BaSpread5MinuteMax('SZ000001')"
    },
    {
        "n": "001004006",
        "cn": "BaSpread5MinuteAvg",
        "en": "",
        "mcn": "前5分钟的买卖差价的平均值",
        "men": "",
        "m2": "BaSpread5MinuteAvg()"
    },
    {
        "n": "001004006001",
        "cn": "Sym_BaSpread5MinuteAvg",
        "en": "",
        "mcn": "前5分钟的买卖差价的平均值",
        "men": "",
        "m2": "Sym_BaSpread5MinuteAvg('SZ000001')"
    },
    {
        "n": "001005001",
        "cn": "LAST",
        "en": "",
        "mcn": "最新的成交价格",
        "men": "",
        "m2": "LAST"
    },
    {
        "n": "001005001001",
        "cn": "Sym_LAST",
        "en": "",
        "mcn": "最新的成交价格",
        "men": "",
        "m2": "Sym_LAST('SZ000001')"
    },
    {
        "n": "001005002",
        "cn": "LAST2",
        "en": "",
        "mcn": "前一个成交价格",
        "men": "",
        "m2": "LAST2"
    },
    {
        "n": "001005002001",
        "cn": "Sym_LAST2",
        "en": "",
        "mcn": "前一个成交价格",
        "men": "",
        "m2": "Sym_LAST2('SZ000001')"
    },
    {
        "n": "001005003",
        "cn": "LAST3",
        "en": "",
        "mcn": "前两个成交价格",
        "men": "",
        "m2": "LAST3"
    },
    {
        "n": "001005003001",
        "cn": "Sym_LAST3",
        "en": "",
        "mcn": "前两个成交价格",
        "men": "",
        "m2": "Sym_LAST3('SZ000001')"
    },
    {
        "n": "001005004",
        "cn": "LAST4",
        "en": "",
        "mcn": "前三个成交价格",
        "men": "",
        "m2": "LAST4"
    },
    {
        "n": "001005004001",
        "cn": "Sym_LAST4",
        "en": "",
        "mcn": "前三个成交价格",
        "men": "",
        "m2": "Sym_LAST4('SZ000001')"
    },
    {
        "n": "001005005",
        "cn": "LASTTIME",
        "en": "",
        "mcn": "最新成交时间",
        "men": "",
        "m2": "LASTTIME"
    },
    {
        "n": "001005005001",
        "cn": "Sym_LASTTIME",
        "en": "",
        "mcn": "最新成交时间",
        "men": "",
        "m2": "Sym_LASTTIME('SZ000001')"
    },
    {
        "n": "001005006",
        "cn": "LAST2TIME",
        "en": "",
        "mcn": "前一个成交时间",
        "men": "",
        "m2": "LAST2TIME"
    },
    {
        "n": "001005006001",
        "cn": "Sym_LAST2TIME",
        "en": "",
        "mcn": "前一个成交时间",
        "men": "",
        "m2": "Sym_LAST2TIME('SZ000001')"
    },
    {
        "n": "001005007",
        "cn": "LAST3TIME",
        "en": "",
        "mcn": "前两个成交时间",
        "men": "",
        "m2": "LAST3TIME"
    },
    {
        "n": "001005007001",
        "cn": "Sym_LAST3TIME",
        "en": "",
        "mcn": "前两个成交时间",
        "men": "",
        "m2": "Sym_LAST3TIME"
    },
    {
        "n": "001005008",
        "cn": "LAST4TIME",
        "en": "",
        "mcn": "前三个成交时间",
        "men": "",
        "m2": "LAST4TIME"
    },
    {
        "n": "001005008001",
        "cn": "Sym_LAST4TIME",
        "en": "",
        "mcn": "前三个成交时间",
        "men": "",
        "m2": "Sym_LAST4TIME('SZ000001')"
    },
    {
        "n": "001005009",
        "cn": "LASTSIZE",
        "en": "",
        "mcn": "最新的成交量",
        "men": "",
        "m2": "LASTSIZE"
    },
    {
        "n": "001005009001",
        "cn": "Sym_LASTSIZE",
        "en": "",
        "mcn": "最新的成交量",
        "men": "",
        "m2": "Sym_LASTSIZE('SZ000001')"
    },
    {
        "n": "001005010",
        "cn": "LAST2SIZE",
        "en": "",
        "mcn": "前一个成交量",
        "men": "",
        "m2": "LAST2SIZE"
    },
    {
        "n": "001005010001",
        "cn": "Sym_LAST2SIZE",
        "en": "",
        "mcn": "前一个成交量",
        "men": "",
        "m2": "Sym_LAST2SIZE('SZ000001')"
    },
    {
        "n": "001005011",
        "cn": "LAST3SIZE",
        "en": "",
        "mcn": "前两个成交量",
        "men": "",
        "m2": "LAST3SIZE"
    },
    {
        "n": "001005011001",
        "cn": "Sym_LAST3SIZE",
        "en": "",
        "mcn": "前两个成交量",
        "men": "",
        "m2": "Sym_LAST3SIZE('SZ000001')"
    },
    {
        "n": "001005012",
        "cn": "LAST4SIZE",
        "en": "",
        "mcn": "前三个成交量",
        "men": "",
        "m2": "LAST4SIZE"
    },
    {
        "n": "001005012001",
        "cn": "Sym_LAST4SIZE",
        "en": "",
        "mcn": "前三个成交量",
        "men": "",
        "m2": "Sym_LAST4SIZE('SZ000001')"
    },
    {
        "n": "001005013",
        "cn": "NET",
        "en": "",
        "mcn": "当前交易日最新涨跌价差",
        "men": "",
        "m2": "NET"
    },
    {
        "n": "001005013001",
        "cn": "Sym_NET",
        "en": "",
        "mcn": "最新涨跌价差",
        "men": "",
        "m2": "Sym_NET('SZ000001')"
    },
    {
        "n": "001006001",
        "cn": "Date",
        "en": "",
        "mcn": "日期",
        "men": "",
        "m2": "Date()"
    },
    {
        "n": "001006002",
        "cn": "DayOfWeek",
        "en": "",
        "mcn": "星期值",
        "men": "",
        "m2": "DayOfWeek('D', -1)"
    },
    {
        "n": "001006003",
        "cn": "DayOfWeekForDate",
        "en": "",
        "mcn": "星期值",
        "men": "",
        "m2": " DayOfWeekForDate(20180101)"
    },
    {
        "n": "001006004",
        "cn": "tradingDayIndex",
        "en": "",
        "mcn": "判断是否为交易日",
        "men": "",
        "m2": "tradingDayIndex(20170112,'W')"
    },
    {
        "n": "001006005",
        "cn": "Hour",
        "en": "",
        "mcn": "小时数",
        "men": "",
        "m2": "Hour()"
    },
    {
        "n": "001006006",
        "cn": "Minute",
        "en": "",
        "mcn": "分钟数",
        "men": "",
        "m2": "Minute()"
    },
    {
        "n": "001006007",
        "cn": "Second",
        "en": "",
        "mcn": "秒数",
        "men": "",
        "m2": "Second()"
    },
    {
        "n": "001006008",
        "cn": "Time",
        "en": "",
        "mcn": "秒数",
        "men": "",
        "m2": "Time('09:30')"
    },
    {
        "n": "001006009",
        "cn": "time_t",
        "en": "",
        "mcn": "秒数",
        "men": "",
        "m2": "time_t()"
    },
    {
        "n": "001006010",
        "cn": "Time_t2BarIndex",
        "en": "",
        "mcn": "K线的位置",
        "men": "",
        "m2": "Time_t2BarIndex(' D ',  ); "
    },
    {
        "n": "001006011",
        "cn": "UniversalTime\n",
        "en": "",
        "mcn": "秒数",
        "men": "",
        "m2": "UniversalTime(34200)"
    },
    {
        "n": "001006012",
        "cn": "BarCloseTime",
        "en": "",
        "mcn": "秒数",
        "men": "",
        "m2": "BarCloseTime('D', -1)"
    },
    {
        "n": "001006013",
        "cn": "BarCloseTimes\n",
        "en": "",
        "mcn": "秒数",
        "men": "",
        "m2": "BarCloseTimes('60', -1)"
    },
    {
        "n": "001006014",
        "cn": "BarOpenTime",
        "en": "",
        "mcn": "秒数",
        "men": "",
        "m2": "BarOpenTime('D', -1)"
    },
    {
        "n": "001006015",
        "cn": "BarOpenTimes\n",
        "en": "",
        "mcn": "秒数",
        "men": "",
        "m2": "BarOpenTimes('60', -1)"
    },
    {
        "n": "001006016",
        "cn": "IntradayTimeDIFF",
        "en": "",
        "mcn": "开始秒数到结束秒数的时间差",
        "men": "",
        "m2": "IntradayTimeDIFF('S',34200,54000 )"
    },
    {
        "n": "001006017",
        "cn": "SecsTillBarClose\n",
        "en": "",
        "mcn": "指定时间到指定时间所在K线结束时间的时间差\n",
        "men": "",
        "m2": "SecsTillBarClose('D',$out_datetime )"
    },
    {
        "n": "001006018",
        "cn": "BarIndex\n",
        "en": "",
        "mcn": "K线的位置",
        "men": "",
        "m2": "BarIndex('D', $out_datetime)"
    },
    {
        "n": "001006019",
        "cn": "BarSpan",
        "en": "",
        "mcn": "K线的数量",
        "men": "",
        "m2": "BarSpan('D',34200,54000 )"
    },
    {
        "n": "001006020",
        "cn": "OccurIndex",
        "en": "",
        "mcn": "K线的位置",
        "men": "",
        "m2": "OccurIndex({ }, -1, -10, -1)"
    },
    {
        "n": "001006021",
        "cn": "DateFromDailyIndex",
        "en": "",
        "mcn": "日线的日期",
        "men": "",
        "m2": "DateFromDailyIndex(-1)"
    },
    {
        "n": "001007001",
        "cn": "OPEN",
        "en": "",
        "mcn": "当前交易日的开盘价",
        "men": "",
        "m2": "OPEN"
    },
    {
        "n": "001007001001",
        "cn": "Sym_OPEN",
        "en": "",
        "mcn": "当前交易日的开盘价",
        "men": "",
        "m2": "Sym_OPEN('SZ000001')"
    },
    {
        "n": "001007002",
        "cn": "LOW",
        "en": "",
        "mcn": "当前交易日的最低价",
        "men": "",
        "m2": "LOW"
    },
    {
        "n": "001007002001",
        "cn": "Sym_LOW",
        "en": "",
        "mcn": "当前交易日的最低价",
        "men": "",
        "m2": "Sym_LOW('SZ000001')"
    },
    {
        "n": "001007003",
        "cn": "HIGH",
        "en": "",
        "mcn": "当前交易日最高价",
        "men": "",
        "m2": "HIGH"
    },
    {
        "n": "001007003001",
        "cn": "Sym_HIGH",
        "en": "",
        "mcn": "当前交易日最高价",
        "men": "",
        "m2": "Sym_HIGH('SZ000001')"
    },
    {
        "n": "001007004",
        "cn": "VOLUME",
        "en": "",
        "mcn": "当前交易日成交量",
        "men": "",
        "m2": "VOLUME"
    },
    {
        "n": "001007004001",
        "cn": "Sym_VOLUME",
        "en": "",
        "mcn": "当前交易日成交量",
        "men": "",
        "m2": "Sym_VOLUME('SZ000001')"
    },
    {
        "n": "001008001",
        "cn": "YESTERDAY_CLOSE",
        "en": "",
        "mcn": "前一个交易日的收盘价",
        "men": "",
        "m2": "YESTERDAY_CLOSE"
    },
    {
        "n": "001008001001",
        "cn": "Sym_YESTERDAYCLOSE",
        "en": "",
        "mcn": "前一个交易日的收盘价",
        "men": "",
        "m2": "Sym_YESTERDAYCLOSE('SZ000001')"
    },
    {
        "n": "001008002",
        "cn": "YESTERDAY_OPEN",
        "en": "",
        "mcn": "前一个交易日的开盘价",
        "men": "",
        "m2": "YESTERDAY_OPEN"
    },
    {
        "n": "001008002001",
        "cn": "Sym_YESTERDAYOPEN",
        "en": "",
        "mcn": "前一个交易日的开盘价",
        "men": "",
        "m2": "Sym_YESTERDAYOPEN('SZ000001')"
    },
    {
        "n": "001008003",
        "cn": "YESTERDAY_LOW",
        "en": "",
        "mcn": "前一个交易日的最低价",
        "men": "",
        "m2": "YESTERDAY_LOW"
    },
    {
        "n": "001008003001",
        "cn": "Sym_YESTERDAYLOW",
        "en": "",
        "mcn": "前一个交易日的最低价",
        "men": "",
        "m2": "Sym_YESTERDAYLOW('SZ000001')"
    },
    {
        "n": "001008004",
        "cn": "YESTERDAY_HIGH",
        "en": "",
        "mcn": "前一个交易日最高价",
        "men": "",
        "m2": "YESTERDAY_HIGH"
    },
    {
        "n": "001008004001",
        "cn": "Sym_YESTERDAYHIGH",
        "en": "",
        "mcn": "前一个交易日最高价",
        "men": "",
        "m2": "Sym_YESTERDAYHIGH('SZ000001')"
    },
    {
        "n": "001008005",
        "cn": "YESTERDAY_VOLUME",
        "en": "",
        "mcn": "前一个交易日成交量",
        "men": "",
        "m2": "YESTERDAY_VOLUME"
    },
    {
        "n": "001008005001",
        "cn": "Sym_YESTERDAYVOLUME",
        "en": "",
        "mcn": "前一个交易日成交量",
        "men": "",
        "m2": "Sym_YESTERDAYVOLUME('SZ000001')"
    },
    {
        "n": "001009001",
        "cn": "20DAY_LOW",
        "en": "",
        "mcn": " ",
        "men": "",
        "m2": "20DAY_LOW"
    },
    {
        "n": "001009001001",
        "cn": "Sym_20DAYLOW",
        "en": "",
        "mcn": "前20个交易日的最低价",
        "men": "",
        "m2": "Sym_20DAYLOW('SZ000001')"
    },
    {
        "n": "001009002",
        "cn": "20DAY_HIGH",
        "en": "",
        "mcn": "前20个交易日的最高价",
        "men": "",
        "m2": "20DAY_HIGH"
    },
    {
        "n": "001009002001",
        "cn": "Sym_20DAYHIGH",
        "en": "",
        "mcn": "前20个交易日的最高价",
        "men": "",
        "m2": "Sym_20DAYHIGH('SZ000001')"
    },
    {
        "n": "001009003",
        "cn": "MINRANGE_20DAY",
        "en": "",
        "mcn": "前20个交易日振幅的最小值",
        "men": "",
        "m2": "MINRANGE_20DAY"
    },
    {
        "n": "001009003001",
        "cn": "Sym_MINRANGE20DAY",
        "en": "",
        "mcn": "前20个交易日振幅的最小值",
        "men": "",
        "m2": "Sym_MINRANGE20DAY('SZ000001')"
    },
    {
        "n": "001009004",
        "cn": "MAXRANGE_20DAY",
        "en": "",
        "mcn": "前20个交易日振幅的最大值",
        "men": "",
        "m2": "MAXRANGE_20DAY"
    },
    {
        "n": "001009004001",
        "cn": "Sym_MAXRANGE20DAY",
        "en": "",
        "mcn": "前20个交易日振幅的最大值",
        "men": "",
        "m2": "Sym_MAXRANGE20DAY('SZ000001')"
    },
    {
        "n": "001009005",
        "cn": "AVGRANGE_20DAY",
        "en": "",
        "mcn": "前20个交易日振幅的平均值",
        "men": "",
        "m2": "AVGRANGE_20DAY"
    },
    {
        "n": "001009005001",
        "cn": "Sym_AVGRANGE20DAY",
        "en": "",
        "mcn": "前20个交易日振幅的平均值",
        "men": "",
        "m2": "Sym_AVGRANGE20DAY('SZ000001')"
    },
    {
        "n": "001009006",
        "cn": "STDRANGE_20DAY",
        "en": "",
        "mcn": "前20个交易日振幅的标准差",
        "men": "",
        "m2": "STDRANGE_20DAY"
    },
    {
        "n": "001009006001",
        "cn": "Sym_STDRANGE20DAY",
        "en": "",
        "mcn": "前20个交易日振幅的标准差",
        "men": "",
        "m2": "Sym_STDRANGE20DAY('SZ000001')"
    },
    {
        "n": "001010001",
        "cn": "PeriodCloseIntraday",
        "en": "",
        "mcn": "日内开始时间到结束时间之间的收盘价",
        "men": "",
        "m2": "PeriodCloseIntraday('9:30', '15:00' )"
    },
    {
        "n": "001010001001",
        "cn": "Sym_PeriodCloseIntraday",
        "en": "",
        "mcn": "日内开始时间到结束时间之间的收盘价",
        "men": "",
        "m2": "Sym_PeriodCloseIntraday('SZ000001','9:30', '15:00' )"
    },
    {
        "n": "001010002",
        "cn": "PeriodOpenIntraday",
        "en": "",
        "mcn": "日内开始时间到结束时间之间的开盘价",
        "men": "",
        "m2": "PeriodOpenIntraday('9:30', '15:00' )"
    },
    {
        "n": "001010002001",
        "cn": "Sym_PeriodOpenIntraday",
        "en": "",
        "mcn": "日内开始时间到结束时间之间的开盘价",
        "men": "",
        "m2": "Sym_PeriodOpenIntraday('SZ000001','9:30', '15:00' )"
    },
    {
        "n": "001010003",
        "cn": "PeriodLowIntraday",
        "en": "",
        "mcn": "日内开始时间到结束时间之间的最低价",
        "men": "",
        "m2": "PeriodLowIntraday('9:30', '15:00' )"
    },
    {
        "n": "001010003001",
        "cn": "Sym_PeriodLowIntraday",
        "en": "",
        "mcn": "日内开始时间到结束时间之间的最低价",
        "men": "",
        "m2": "Sym_PeriodLowIntraday('SZ000001','9:30', '15:00' )"
    },
    {
        "n": "001010004",
        "cn": "PeriodHighIntraday",
        "en": "",
        "mcn": "日内开始时间到结束时间之间的最高价",
        "men": "",
        "m2": "PeriodHighIntraday('9:30', '15:00' )"
    },
    {
        "n": "001010004001",
        "cn": "Sym_PeriodHighIntraday",
        "en": "",
        "mcn": "日内开始时间到结束时间之间的最高价",
        "men": "",
        "m2": "Sym_PeriodHighIntraday('SZ000001','9:30', '15:00' )"
    },
    {
        "n": "001010005",
        "cn": "VwapIntraday",
        "en": "",
        "mcn": "日内开始时间到结束时间之间成交价的平均值",
        "men": "",
        "m2": "VwapIntraday('9:30', '15:00' )"
    },
    {
        "n": "001010005001",
        "cn": "Sym_VwapIntraday",
        "en": "",
        "mcn": "日内开始时间到结束时间之间的成交价的平均值",
        "men": "",
        "m2": "Sym_VwapIntraday('SZ000001','9:30', '15:00' )"
    },
    {
        "n": "001010006",
        "cn": "PeriodLowIntradayTillNow",
        "en": "",
        "mcn": "日内开始时间到现在的最低价",
        "men": "",
        "m2": "PeriodLowIntradayTillNow('10:00')"
    },
    {
        "n": "001010006001",
        "cn": "Sym_PeriodLowIntradayTillNow",
        "en": "",
        "mcn": "日内开始时间到现在的最低价",
        "men": "",
        "m2": "Sym_PeriodLowIntradayTillNow('SZ000001','10:00')"
    },
    {
        "n": "001010007",
        "cn": "PeriodHighIntradayTillNow",
        "en": "",
        "mcn": "日内开始时间到现在的最高价",
        "men": "",
        "m2": "PeriodHighIntradayTillNow('10:00')"
    },
    {
        "n": "001010007001",
        "cn": "Sym_PeriodHighIntradayTillNow",
        "en": "",
        "mcn": "日内开始时间到现在的最高价",
        "men": "",
        "m2": "Sym_PeriodHighIntradayTillNow('SZ000001','10:00')"
    },
    {
        "n": "001010008",
        "cn": "PeriodRangeIntradayTillNow",
        "en": "",
        "mcn": "日内开始时间到现在的振幅",
        "men": "",
        "m2": "PeriodRangeIntradayTillNow('10:00')"
    },
    {
        "n": "001010008001",
        "cn": "Sym_PeriodRangeIntradayTillNow",
        "en": "",
        "mcn": "日内开始时间到现在的振幅",
        "men": "",
        "m2": "Sym_PeriodRangeIntradayTillNow('SZ000001','10:00')"
    },
    {
        "n": "001010009",
        "cn": "PeriodVolumeIntraday",
        "en": "",
        "mcn": "日内开始时间到结束时间之间成交量之和",
        "men": "",
        "m2": "VwapIntraday('9:30', '15:00' )"
    },
    {
        "n": "001010009001",
        "cn": "Sym_PeriodVolumeIntraday",
        "en": "",
        "mcn": "日内开始时间到结束时间之间成交量之和",
        "men": "",
        "m2": "Sym_PeriodVolumeIntraday('SZ000001','9:30', '15:00' )"
    },
    {
        "n": "001011001",
        "cn": "AvgIntraday",
        "en": "",
        "mcn": "一段时间内表达式的平均值",
        "men": "",
        "m2": "AvgIntraday('CLOSE', '9:30', '15:00')"
    },
    {
        "n": "001011001001",
        "cn": "Sym_AvgIntraday",
        "en": "",
        "mcn": "一段时间内表达式的平均值",
        "men": "",
        "m2": "Sym_AvgIntraday('SZ000001','CLOSE', '9:30', '15:00')"
    },
    {
        "n": "001011002",
        "cn": "AvgIntradayTillIndex",
        "en": "",
        "mcn": "前若干个1分钟的表达式的平均值",
        "men": "",
        "m2": "AvgIntradayTillIndex('CLOSE', '10:00', -10)"
    },
    {
        "n": "001011002001",
        "cn": "Sym_AvgIntradayTillIndex",
        "en": "",
        "mcn": "前若干个1分钟的表达式的平均值",
        "men": "",
        "m2": "Sym_AvgIntradayTillIndex('SZ000001','CLOSE', '10:00', -10)"
    },
    {
        "n": "002001001",
        "cn": "Rsi",
        "en": "",
        "mcn": "相对强度指标 ",
        "men": "",
        "m2": "Rsi('CLOSE', 'D', 24, -1)"
    },
    {
        "n": "002001001001",
        "cn": "Sym_Rsi",
        "en": "",
        "mcn": "相对强度指标 ",
        "men": "",
        "m2": "Sym_Rsi('SZ000001','CLOSE', 'D', 6, -1)"
    },
    {
        "n": "002001002",
        "cn": "Obv ",
        "en": "",
        "mcn": "能量潮指标",
        "men": "",
        "m2": "Obv ('CLOSE', 'D', -1)"
    },
    {
        "n": "002001002001",
        "cn": "Sym_Obv ",
        "en": "",
        "mcn": "能量潮指标",
        "men": "",
        "m2": "Sym_Obv ('SZ000001','CLOSE', 'D', -1)"
    },
    {
        "n": "002001003",
        "cn": "Adx",
        "en": "",
        "mcn": "平均趋向指标",
        "men": "",
        "m2": "Adx('D', 14, -1)"
    },
    {
        "n": "002001003001",
        "cn": "Sym_Adx",
        "en": "",
        "mcn": "平均趋向指标",
        "men": "",
        "m2": "Sym_Adx('SZ000001','D', 14, -1)"
    },
    {
        "n": "002001004",
        "cn": "Atr",
        "en": "",
        "mcn": "平均真实波动幅度",
        "men": "",
        "m2": "Atr('D', 14, -1)"
    },
    {
        "n": "002001004001",
        "cn": "Sym_Atr",
        "en": "",
        "mcn": "平均真实波动幅度",
        "men": "",
        "m2": "Sym_Atr('SZ000001','D', 14, -1"
    },
    {
        "n": "002001005",
        "cn": "Bias",
        "en": "",
        "mcn": "乖离率",
        "men": "",
        "m2": "Bias('D', 20, -1)"
    },
    {
        "n": "002001005001",
        "cn": "Sym_Bias",
        "en": "",
        "mcn": "乖离率",
        "men": "",
        "m2": "Sym_Bias('SZ000001','D', 20, -1)"
    },
    {
        "n": "002001006",
        "cn": "TSN_Bias",
        "en": "",
        "mcn": "乖离率时间序列",
        "men": "",
        "m2": "TSN_Bias('D', 20)"
    },
    {
        "n": "002001006001",
        "cn": "Sym_TSN_Bias",
        "en": "",
        "mcn": "乖离率时间序列",
        "men": "",
        "m2": "Sym_TSN_Bias('SZ000001', 'D', 20)"
    },
    {
        "n": "002001007",
        "cn": "Beta",
        "en": "",
        "mcn": "β系数",
        "men": "",
        "m2": "Beta('D', -10, -1, 'SZ000001')"
    },
    {
        "n": "002001007001",
        "cn": "Sym_Beta",
        "en": "",
        "mcn": "β系数",
        "men": "",
        "m2": "Sym_Beta('SH002736','D', -10, -1, 'SZ000001')"
    },
    {
        "n": "002001008",
        "cn": "LinearReg",
        "en": "",
        "mcn": "线性回归值",
        "men": "",
        "m2": "LinearReg('CLOSE', 'D', 20, -1)"
    },
    {
        "n": "002001008001",
        "cn": "Sym_LinearReg",
        "en": "",
        "mcn": "线性回归值",
        "men": "",
        "m2": "Sym_LinearReg('SZ000001','CLOSE', 'D', 20, -1)"
    },
    {
        "n": "002001009",
        "cn": "TS_LinearRegression",
        "en": "",
        "mcn": "线性回归的阿尔法和beta时间序列",
        "men": "",
        "m2": "TS_LinearRegression(@ , @  , '$weight', '$bias')"
    },
    {
        "n": "002001010",
        "cn": "LBRRsi ",
        "en": "",
        "mcn": "Roc序列的Rsi ",
        "men": "",
        "m2": "LBRRsi ('CLOSE', 'D', 14, 20, -1)"
    },
    {
        "n": "002001010001",
        "cn": "Sym_LBRRsi ",
        "en": "",
        "mcn": "Roc序列的Rsi ",
        "men": "",
        "m2": "Sym_LBRRsi('SZ000001','CLOSE', 'D',14, 20, -1)"
    },
    {
        "n": "002001011",
        "cn": "Roc",
        "en": "",
        "mcn": "变化率指标 ",
        "men": "",
        "m2": "Roc('CLOSE', 'D', 20, -1)"
    },
    {
        "n": "002001011001",
        "cn": "Sym_Roc",
        "en": "",
        "mcn": "变化率指标 ",
        "men": "",
        "m2": "Sym_Roc('SZ000001','CLOSE', 'D', 20, -1)"
    },
    {
        "n": "002001012",
        "cn": "TSN_Roc",
        "en": "",
        "mcn": "变化率指标 时间序列",
        "men": "",
        "m2": "TSN_Roc('CLOSE', 'D', 20)"
    },
    {
        "n": "002001012001",
        "cn": "Sym_TSN_Roc",
        "en": "",
        "mcn": "变化率指标 时间序列",
        "men": "",
        "m2": "Sym_TSN_Roc('SZ000001','CLOSE', 'D', 20)"
    },
    {
        "n": "002001013",
        "cn": "TurnoverRate",
        "en": "",
        "mcn": "换手率",
        "men": "",
        "m2": "TurnoverRate('D', -1)"
    },
    {
        "n": "002001013001",
        "cn": "Sym_TurnoverRate",
        "en": "",
        "mcn": "换手率",
        "men": "",
        "m2": "Sym_TurnoverRate('SZ000001','D', -1)"
    },
    {
        "n": "002001014",
        "cn": "TSN_TurnoverRate\n",
        "en": "",
        "mcn": "换手率时间序列",
        "men": "",
        "m2": "TSN_TurnoverRate('D')"
    },
    {
        "n": "002001014001",
        "cn": "Sym_TSN_TurnoverRate",
        "en": "",
        "mcn": "换手率时间序列",
        "men": "",
        "m2": "Sym_TSN_TurnoverRate('SZ000001', 'D')"
    },
    {
        "n": "002001015",
        "cn": "VolumeRatio",
        "en": "",
        "mcn": "量比指标",
        "men": "",
        "m2": "VolumeRatio('NOW')"
    },
    {
        "n": "002001015001",
        "cn": "Sym_VolumeRatio",
        "en": "",
        "mcn": "量比指标",
        "men": "",
        "m2": "Sym_VolumeRatio('SZ000001','10:00'/'NOW')"
    },
    {
        "n": "002001016",
        "cn": "Cci",
        "en": "",
        "mcn": "超买超卖",
        "men": "",
        "m2": "Cci('D', 14, -1)"
    },
    {
        "n": "002001016001",
        "cn": "Sym_Cci",
        "en": "",
        "mcn": "超买超卖",
        "men": "",
        "m2": "Sym_Cci('SZ000001','D', 14, -1)"
    },
    {
        "n": "002001017",
        "cn": "DMI",
        "en": "",
        "mcn": "动向指标",
        "men": "",
        "m2": "DMI('D', 7, 14, -1, '$out_PDI', '$out_MDI', '$out_ADXR') "
    },
    {
        "n": "002001017001",
        "cn": "Sym_DMI",
        "en": "",
        "mcn": "动向指标",
        "men": "",
        "m2": "Sym_DMI('SZ000001','D' ,7, 14, -1, '$out_PDI', '$out_MDI', '$out_ADXR') "
    },
    {
        "n": "002001018",
        "cn": "DEM",
        "en": "",
        "mcn": "震荡指标",
        "men": "",
        "m2": "DEM('D', 20, -1)"
    },
    {
        "n": "002001018001",
        "cn": "Sym_DEM",
        "en": "",
        "mcn": "震荡指标",
        "men": "",
        "m2": "Sym_DEM('SZ000001','D', 20, -1)\n"
    },
    {
        "n": "002001019",
        "cn": "TDREI",
        "en": "",
        "mcn": "TD区间扩张指标",
        "men": "",
        "m2": "TDREI('D',5, 5, 8, -1)"
    },
    {
        "n": "002001019001",
        "cn": "Sym_TDREI",
        "en": "",
        "mcn": "TD区间扩张指标",
        "men": "",
        "m2": "Sym_TDREI('SZ000001','D',5, 5, 8, -1)"
    },
    {
        "n": "002002001",
        "cn": "Sma",
        "en": "",
        "mcn": "简单移动平均值",
        "men": "",
        "m2": "Sma('CLOSE', 'D', 20, -1)"
    },
    {
        "n": "002002001001",
        "cn": "Sym_Sma",
        "en": "",
        "mcn": "简单移动平均值",
        "men": "",
        "m2": "Sym_Sma('SZ000001','CLOSE', 'D', 20, -1)"
    },
    {
        "n": "002002002",
        "cn": "TSN_Sma",
        "en": "",
        "mcn": "简单移动平均值时间序列",
        "men": "",
        "m2": "TSN_Sma('CLOSE', 'D', 20)"
    },
    {
        "n": "002002002001",
        "cn": "Sym_TSN_Sma",
        "en": "",
        "mcn": "简单移动平均值时间序列",
        "men": "",
        "m2": "Sym_TSN_Sma('SZ000001','CLOSE', 'D', 20)"
    },
    {
        "n": "002002003",
        "cn": "TS_Sma",
        "en": "",
        "mcn": "简单移动平均值时间序列",
        "men": "",
        "m2": "TS_Ma(@ , 20,)"
    },
    {
        "n": "002002004",
        "cn": "TSN_Ma",
        "en": "",
        "mcn": "简单移动平均值时间序列",
        "men": "",
        "m2": "TSN_Ma('CLOSE', 'D', 20)"
    },
    {
        "n": "002002004001",
        "cn": "Sym_TSN_Ma",
        "en": "",
        "mcn": "简单移动平均值时间序列",
        "men": "",
        "m2": "Sym_TSN_Ma('SZ000001','CLOSE', 'D', 20)"
    },
    {
        "n": "002002005",
        "cn": "SMA20DAY_CLOSE",
        "en": "",
        "mcn": "前20个交易日收盘价的简单移动平均值",
        "men": "",
        "m2": "SMA20DAY_CLOSE"
    },
    {
        "n": "002002005001",
        "cn": "Sym_SMA20DAYCLOSE",
        "en": "",
        "mcn": "前20个交易日收盘价的简单移动平均值",
        "men": "",
        "m2": "Sym_SMA20DAYCLOSE('SZ000001')"
    },
    {
        "n": "002002006",
        "cn": "SMA50DAY_CLOSE",
        "en": "",
        "mcn": "前50个交易日收盘价的Sma",
        "men": "",
        "m2": "SMA50DAY_CLOSE"
    },
    {
        "n": "002002006001",
        "cn": "Sym_SMA50DAYCLOSE",
        "en": "",
        "mcn": "前50个交易日收盘价的简单移动平均值",
        "men": "",
        "m2": "Sym_SMA50DAYCLOSE('SZ000001')"
    },
    {
        "n": "002002007",
        "cn": "SMA200DAY_CLOSE",
        "en": "",
        "mcn": "前200个交易日收盘价的简单移动平均值",
        "men": "",
        "m2": "SMA200DAY_CLOSE"
    },
    {
        "n": "002002007001",
        "cn": "Sym_SMA200DAYCLOSE",
        "en": "",
        "mcn": "前200个交易日收盘价的简单移动平均值",
        "men": "",
        "m2": "Sym_SMA200DAYCLOSE('SZ000001')"
    },
    {
        "n": "002002008",
        "cn": "SMA10DAY_VOLUME",
        "en": "",
        "mcn": "前十个交易日成交量的简单移动平均值",
        "men": "",
        "m2": "SMA10DAY_VOLUME"
    },
    {
        "n": "002002008001",
        "cn": "Sym_SMA10DAYVOLUME",
        "en": "",
        "mcn": "前十个交易日成交量的简单移动平均值",
        "men": "",
        "m2": "Sym_SMA10DAYVOLUME('SZ000001')"
    },
    {
        "n": "002002009",
        "cn": "SMA20DAY_VOLUME",
        "en": "",
        "mcn": "前20个交易日成交量的简单移动平均值",
        "men": "",
        "m2": "SMA20DAY_VOLUME"
    },
    {
        "n": "002002009001",
        "cn": "Sym_SMA20DAYVOLUME",
        "en": "",
        "mcn": "前20个交易日成交量的简单移动平均值",
        "men": "",
        "m2": "Sym_SMA20DAYVOLUME('SZ000001')"
    },
    {
        "n": "002002010",
        "cn": "SMA50DAY_VOLUME",
        "en": "",
        "mcn": "前50个交易日成交量的简单移动平均值",
        "men": "",
        "m2": "SMA50DAY_VOLUME"
    },
    {
        "n": "002002010001",
        "cn": "Sym_SMA50DAYVOLUME",
        "en": "",
        "mcn": "前50个交易日成交量的简单移动平均值",
        "men": "",
        "m2": "Sym_SMA50DAYVOLUME('SZ000001')"
    },
    {
        "n": "002002011",
        "cn": "Ema",
        "en": "",
        "mcn": "指数加权移动平均值",
        "men": "",
        "m2": "Ema('CLOSE', 'D', 20, -1)"
    },
    {
        "n": "002002011001",
        "cn": "Sym_Ema",
        "en": "",
        "mcn": "指数加权移动平均值",
        "men": "",
        "m2": "Sym_Ema('SZ000001','CLOSE', 'D', 20, -1)"
    },
    {
        "n": "002002012",
        "cn": "TSN_Ema",
        "en": "",
        "mcn": "指数加权移动平均值时间序列",
        "men": "",
        "m2": "TSN_Ema('CLOSE', 'D', 20)"
    },
    {
        "n": "002002012001",
        "cn": "Sym_TSN_Ema",
        "en": "",
        "mcn": "指数加权移动平均值时间序列",
        "men": "",
        "m2": "Sym_TSN_Ema('SZ000001','CLOSE', 'D', 20)"
    },
    {
        "n": "002002013",
        "cn": "TS_Ema",
        "en": "",
        "mcn": "指数加权移动平均值时间序列",
        "men": "",
        "m2": "TS_Ema(@ , 3)"
    },
    {
        "n": "002002014",
        "cn": "EMA20DAY_CLOSE",
        "en": "",
        "mcn": "前20个交易日收盘价的指数加权移动平均值",
        "men": "",
        "m2": "EMA20DAY_CLOSE"
    },
    {
        "n": "002002014001",
        "cn": "Sym_EMA20DAYCLOSE",
        "en": "",
        "mcn": "前20个交易日收盘价的指数加权移动平均值",
        "men": "",
        "m2": "Sym_EMA20DAYCLOSE('SZ000001')"
    },
    {
        "n": "002002015",
        "cn": "EMA50DAY_CLOSE",
        "en": "",
        "mcn": "前50个交易日收盘价的指数加权移动平均值",
        "men": "",
        "m2": "EMA50DAY_CLOSE"
    },
    {
        "n": "002002015001",
        "cn": "Sym_EMA50DAYCLOSE",
        "en": "",
        "mcn": "前50个交易日收盘价的指数加权移动平均值",
        "men": "",
        "m2": "Sym_EMA50DAYCLOSE('SZ000001')"
    },
    {
        "n": "002002016",
        "cn": "EMA200DAY_CLOSE",
        "en": "",
        "mcn": "前200个交易日收盘价的指数加权移动平均值",
        "men": "",
        "m2": "EMA200DAY_CLOSE"
    },
    {
        "n": "002002016001",
        "cn": "Sym_EMA200DAYCLOSE",
        "en": "",
        "mcn": "前200个交易日收盘价的指数加权移动平均值",
        "men": "",
        "m2": "Sym_EMA200DAYCLOSE('SZ000001')"
    },
    {
        "n": "002002017",
        "cn": "Wma",
        "en": "",
        "mcn": "线性加权移动平均值",
        "men": "",
        "m2": "Wma('CLOSE', 'D', 20, -1)"
    },
    {
        "n": "002002017001",
        "cn": "Sym_Wma",
        "en": "",
        "mcn": "线性加权移动平均值",
        "men": "",
        "m2": "Sym_Wma('SZ000001','CLOSE', 'D', 20, -1)"
    },
    {
        "n": "002002018",
        "cn": "TSN_Wma",
        "en": "",
        "mcn": "线性加权移动平均值时间序列",
        "men": "",
        "m2": "TSN_Wma('CLOSE', 'D', 20)"
    },
    {
        "n": "002002018001",
        "cn": "Sym_TSN_Wma",
        "en": "",
        "mcn": "线性加权移动平均值时间序列",
        "men": "",
        "m2": "Sym_TSN_Wma('SZ000001','CLOSE', 'D', 20)"
    },
    {
        "n": "002002019",
        "cn": "Dma",
        "en": "",
        "mcn": "动态移动平均值",
        "men": "",
        "m2": "Dma('CLOSE', 'D', 20, 0.1, -1)"
    },
    {
        "n": "002002019001",
        "cn": "Sym_Dma",
        "en": "",
        "mcn": "动态移动平均值",
        "men": "",
        "m2": "Sym_Dma('SZ000001','CLOSE', 'D', 20, 0.1, -1)"
    },
    {
        "n": "002002020",
        "cn": "TSN_Dma",
        "en": "",
        "mcn": "动态移动平均值时间序列",
        "men": "",
        "m2": "TSN_Dma('CLOSE', 'D', 20, 0.1)"
    },
    {
        "n": "002002020001",
        "cn": "Sym_TSN_Dma",
        "en": "",
        "mcn": "动态移动平均值时间序列",
        "men": "",
        "m2": "Sym_TSN_Dma('SZ000001','CLOSE', 'D', 20, 0.1)"
    },
    {
        "n": "002002021",
        "cn": "TS_Dma",
        "en": "",
        "mcn": "动态移动平均值时间序列",
        "men": "",
        "m2": "TS_Dma(@ , 3, 0.1)"
    },
    {
        "n": "002003001",
        "cn": "SmaCrossAbove",
        "en": "",
        "mcn": "Sma的交叉点",
        "men": "",
        "m2": "SmaCrossAbove('CLOSE', 'D',10 ,5 , -1)"
    },
    {
        "n": "002003001001",
        "cn": "Sym_SmaCrossAbove",
        "en": "",
        "mcn": "Sma的交叉点",
        "men": "",
        "m2": "Sym_SmaCrossAbove('SZ000001','CLOSE', 'D',10 ,5 , -1)"
    },
    {
        "n": "002003002",
        "cn": "TS_CrossUp",
        "en": "",
        "mcn": "时间序列的交叉点",
        "men": "",
        "m2": "  TS_CrossUp(@ , @, -1)"
    },
    {
        "n": "002003003",
        "cn": "TS_CrossDown",
        "en": "",
        "mcn": "时间序列的交叉点",
        "men": "",
        "m2": "  TS_CrossDown(@ ,@ ,-1)"
    },
    {
        "n": "002003004",
        "cn": "TS_CrossAboveLine",
        "en": "",
        "mcn": "时间序列与一个数的交叉点",
        "men": "",
        "m2": "TS_CrossAboveLine(@ ,10,-1)"
    },
    {
        "n": "002003005",
        "cn": "TS_CrossBelowLine",
        "en": "",
        "mcn": "时间序列与一个数的交叉点",
        "men": "",
        "m2": "TS_CrossBelowLine(@ ,10,-1)"
    },
    {
        "n": "002004001",
        "cn": "Macd_DIF",
        "en": "",
        "mcn": "Macd快线的值",
        "men": "",
        "m2": "Macd_DIF('CLOSE', 'D', 12, 26, -1)"
    },
    {
        "n": "002004001001",
        "cn": "Sym_Macd_DIF",
        "en": "",
        "mcn": "Macd快线的值",
        "men": "",
        "m2": "Sym_Macd_DIF('SZ000001','CLOSE', 'D',12, 26, -1)"
    },
    {
        "n": "002004002",
        "cn": "Macd_DEA",
        "en": "",
        "mcn": "Macd慢线的值",
        "men": "",
        "m2": "Macd_DEA('CLOSE', 'D', 12, 26, 9, -1)"
    },
    {
        "n": "002004002001",
        "cn": "Sym_Macd_DEA",
        "en": "",
        "mcn": "Macd慢线的值",
        "men": "",
        "m2": "Sym_Macd_DEA('SZ000001','CLOSE', 'D', 12, 26, 9, -1)"
    },
    {
        "n": "002004003",
        "cn": "Macd_MACD",
        "en": "",
        "mcn": "Macd快线-慢线的值",
        "men": "",
        "m2": "Macd_MACD('SZ000001','CLOSE', 'D', 12, 26, 9, -1)"
    },
    {
        "n": "002004003001",
        "cn": "Sym_Macd_MACD",
        "en": "",
        "mcn": "Macd快线-慢线的值",
        "men": "",
        "m2": "Sym_Macd_MACD('SZ000001','CLOSE', 'D',  12, 26, 9,  -1)"
    },
    {
        "n": "002004004",
        "cn": "TSN_Macd",
        "en": "",
        "mcn": "Macd指标时间序列",
        "men": "",
        "m2": "TSN_Macd('CLOSE', 'D', 12, 26, 9, '@tsDIF', '@tsDEA', '@tsMACD' )"
    },
    {
        "n": "002004004001",
        "cn": "Sym_TSN_Macd",
        "en": "",
        "mcn": "Macd指标时间序列",
        "men": "",
        "m2": "Sym_TSN_Macd('SZ000001','CLOSE', 'D', 12, 26, 9, '@tsDIF', '@tsDEA', '@tsMACD' )"
    },
    {
        "n": "002005001",
        "cn": "Macd",
        "en": "",
        "mcn": "Macd快线的值",
        "men": "",
        "m2": "Macd('CLOSE', 'D', 12, 26, -1)"
    },
    {
        "n": "002005001001",
        "cn": "Sym_Macd",
        "en": "",
        "mcn": "Macd快线的值",
        "men": "",
        "m2": "Sym_Macd('SZ000001','CLOSE', 'D',12, 26, -1)"
    },
    {
        "n": "002005002",
        "cn": "MacdSignal",
        "en": "",
        "mcn": "Macd慢线的值",
        "men": "",
        "m2": "MacdSignal('CLOSE', 'D', 12, 26, 9, -1)"
    },
    {
        "n": "002005002001",
        "cn": "Sym_MacdSignal",
        "en": "",
        "mcn": "Macd慢线的值",
        "men": "",
        "m2": "Sym_MacdSignal('SZ000001','CLOSE', 'D', 12, 26, 9, -1)"
    },
    {
        "n": "002005003",
        "cn": "MacdHist",
        "en": "",
        "mcn": "Macd快线-慢线的值的两倍",
        "men": "",
        "m2": "MacdHist('CLOSE', 'D', 12, 26, 9, -1)"
    },
    {
        "n": "002005003001",
        "cn": "Sym_MacdHist",
        "en": "",
        "mcn": "Macd快线-慢线的值的两倍",
        "men": "",
        "m2": "Sym_MacdHist('SZ000001','CLOSE', 'D',  12, 26, 9,  -1)"
    },
    {
        "n": "002006001",
        "cn": "BbUpper",
        "en": "",
        "mcn": "布林线上轨值",
        "men": "",
        "m2": "BbUpper('D', 20, 2.0, -1)"
    },
    {
        "n": "002006001001",
        "cn": "Sym_BbUpper",
        "en": "",
        "mcn": "布林线上轨值",
        "men": "",
        "m2": "Sym_BbUpper('SZ000001','D', 20, 2.0, -1)"
    },
    {
        "n": "002006002",
        "cn": "BbLower",
        "en": "",
        "mcn": "布林线下轨值",
        "men": "",
        "m2": "BbLower('D', 20, 2.0, -1)"
    },
    {
        "n": "002006002001",
        "cn": "Sym_BbLower",
        "en": "",
        "mcn": "布林线下轨值",
        "men": "",
        "m2": "Sym_BbLower('SZ000001','D', 20, 2.0, -1)"
    },
    {
        "n": "002006003",
        "cn": "TSN_BB",
        "en": "",
        "mcn": "布林线指标时间序列",
        "men": "",
        "m2": "TSN_BB( 'D', 20, 2.0, '@tsUpper', '@tsLower' )"
    },
    {
        "n": "002006003001",
        "cn": "Sym_TSN_BB",
        "en": "",
        "mcn": "布林线指标时间序列",
        "men": "",
        "m2": "Sym_TSN_BB('SZ000001', 'D', 20, 2.0, '@tsUpper', '@tsLower' )"
    },
    {
        "n": "002007001",
        "cn": "KDJ_K",
        "en": "",
        "mcn": "随机指标K",
        "men": "",
        "m2": "KDJ_K('D', 9, -1)"
    },
    {
        "n": "002007001001",
        "cn": "Sym_KDJ_K",
        "en": "",
        "mcn": "随机指标K",
        "men": "",
        "m2": "Sym_KDJ_K('SZ000001','D', 9, -1)"
    },
    {
        "n": "002007002",
        "cn": "KDJ_D",
        "en": "",
        "mcn": "随机指标D",
        "men": "",
        "m2": "KDJ_D('D', 9, -1)"
    },
    {
        "n": "002007002001",
        "cn": "Sym_KDJ_D",
        "en": "",
        "mcn": "随机指标D",
        "men": "",
        "m2": "Sym_KDJ_D('SZ000001','D', 9, -1)"
    },
    {
        "n": "002007003",
        "cn": "KDJ_J",
        "en": "",
        "mcn": "随机指标J",
        "men": "",
        "m2": "KDJ_J('D', 9, -1)"
    },
    {
        "n": "002007003001",
        "cn": "Sym_KDJ_J",
        "en": "",
        "mcn": "随机指标J",
        "men": "",
        "m2": "Sym_KDJ_J('SZ000001','D', 9, -1)"
    },
    {
        "n": "002007004",
        "cn": "TSN_KDJ",
        "en": "",
        "mcn": "随机指标KDJ时间序列函数",
        "men": "",
        "m2": "TSN_KDJ( 'D', 9, 3, 3, '@tsK', '@tsD', '@tsJ' )"
    },
    {
        "n": "002007004001",
        "cn": "Sym_TSN_KDJ",
        "en": "",
        "mcn": "随机指标KDJ时间序列函数",
        "men": "",
        "m2": "Sym_TSN_KDJ('SZ000001','D', 9, 3, 3, '@tsK', '@tsD', '@tsJ' )"
    },
    {
        "n": "002008001",
        "cn": "StochFastK ",
        "en": "",
        "mcn": "随机震荡%K值",
        "men": "",
        "m2": "StochFastK ('D',5, -1)"
    },
    {
        "n": "002008001001",
        "cn": "Sym_StochFastK ",
        "en": "",
        "mcn": "随机震荡%K值",
        "men": "",
        "m2": "Sym_StochFastK ('SZ000001','D', 5, -1)"
    },
    {
        "n": "002008002",
        "cn": "StochFastD",
        "en": "",
        "mcn": "随机震荡%D值",
        "men": "",
        "m2": "StochFastD('D', 5, 3, -1)"
    },
    {
        "n": "002008002001",
        "cn": "Sym_StochFastD",
        "en": "",
        "mcn": "随机震荡%D值",
        "men": "",
        "m2": "Sym_StochFastD('SZ000001','D', 5, 3, -1)"
    },
    {
        "n": "002009001",
        "cn": "WilliamR_CN",
        "en": "",
        "mcn": "威廉指标(中国习惯)",
        "men": "",
        "m2": "WilliamR_CN('D', 20, -1)"
    },
    {
        "n": "002009001001",
        "cn": "Sym_WilliamR_CN",
        "en": "",
        "mcn": "威廉指标(中国习惯)",
        "men": "",
        "m2": "Sym_WilliamR_CN('SZ000001','D', 20, -1)"
    },
    {
        "n": "002010001",
        "cn": "WilliamR",
        "en": "",
        "mcn": "威廉指标(美国习惯)",
        "men": "",
        "m2": "WilliamR('D', 20, -1)"
    },
    {
        "n": "002010001001",
        "cn": "Sym_WilliamR",
        "en": "",
        "mcn": "威廉指标",
        "men": "",
        "m2": "Sym_WilliamR('SZ000001','D', 20, -1)"
    },
    {
        "n": "002010004001",
        "cn": "Sym_S1",
        "en": "",
        "mcn": "支撑价位1",
        "men": "",
        "m2": "Sym_S1('SZ000001')"
    },
    {
        "n": "002011001",
        "cn": "HistVolatility",
        "en": "",
        "mcn": "历史波动率",
        "men": "",
        "m2": "HistVolatility('D', 20, -1, 1, '$skewness', '$kurtosis')"
    },
    {
        "n": "002011001001",
        "cn": "Sym_HistVolatility",
        "en": "",
        "mcn": "历史波动率",
        "men": "",
        "m2": "Sym_HistVolatility('SZ000001','D', 20, -1, 1, '$skewness', '$kurtosis')"
    },
    {
        "n": "002011002",
        "cn": "HistVolatilityMaxMin",
        "en": "",
        "mcn": "历史波动率的最大值和最小值",
        "men": "",
        "m2": "HistVolatilityMaxMin ('D', 20, -10, -1, annualized, '$max', '$min')"
    },
    {
        "n": "002011002001",
        "cn": "Sym_HistVolatilityMaxMin",
        "en": "",
        "mcn": "历史波动率的最大值和最小值",
        "men": "",
        "m2": "Sym_HistVolatilityMaxMin ('SZ000001','D',20, -10, -1, annualized, '$max', '$min')"
    },
    {
        "n": "002012001",
        "cn": "PIVOT",
        "en": "",
        "mcn": "支点",
        "men": "",
        "m2": "PIVOT"
    },
    {
        "n": "002012001001",
        "cn": "Sym_PIVOT",
        "en": "",
        "mcn": "支点",
        "men": "",
        "m2": "Sym_PIVOT('SZ000001')"
    },
    {
        "n": "002012002",
        "cn": "R1",
        "en": "",
        "mcn": "阻力价位1",
        "men": "",
        "m2": "R1"
    },
    {
        "n": "002012002001",
        "cn": "Sym_R1",
        "en": "",
        "mcn": "阻力价位1",
        "men": "",
        "m2": "Sym_R1('SZ000001')"
    },
    {
        "n": "002012003",
        "cn": "R2",
        "en": "",
        "mcn": "阻力价位2",
        "men": "",
        "m2": "R2"
    },
    {
        "n": "002012003001",
        "cn": "Sym_R2",
        "en": "",
        "mcn": "阻力价位2",
        "men": "",
        "m2": "Sym_R2('SZ000001')"
    },
    {
        "n": "002012004",
        "cn": "S1",
        "en": "",
        "mcn": "支撑价位1",
        "men": "",
        "m2": "S1"
    },
    {
        "n": "002012005",
        "cn": "S2",
        "en": "",
        "mcn": "支撑价位2",
        "men": "",
        "m2": "S2"
    },
    {
        "n": "002012005001",
        "cn": "Sym_S2",
        "en": "",
        "mcn": "支撑价位2",
        "men": "",
        "m2": "Sym_S2('SZ000001')"
    },
    {
        "n": "002013001",
        "cn": "ADR",
        "en": "",
        "mcn": "涨跌比率",
        "men": "",
        "m2": "ADR('D', 0, -1) "
    },
    {
        "n": "002013002",
        "cn": "Arms",
        "en": "",
        "mcn": "阿姆氏指标",
        "men": "",
        "m2": "Arms(0, -1) "
    },
    {
        "n": "002013003",
        "cn": "McClellan",
        "en": "",
        "mcn": "麦克连指标",
        "men": "",
        "m2": "McClellan(0, -1) "
    },
    {
        "n": "002013004",
        "cn": "ABI",
        "en": "",
        "mcn": "绝对广量指标(中国习惯)",
        "men": "",
        "m2": "ABI(0, -1) "
    },
    {
        "n": "002013005",
        "cn": "Breadth",
        "en": "",
        "mcn": "绝对广量指标(美国习惯)",
        "men": "",
        "m2": "Breadth(0, -1) "
    },
    {
        "n": "003001",
        "cn": "OptTheoreticalPirce",
        "en": "",
        "mcn": "理论期权价格",
        "men": "",
        "m2": "OptTheoreticalPirce('Intraday')"
    },
    {
        "n": "003002",
        "cn": "OptStrikeUnderlyingPriceDiff",
        "en": "",
        "mcn": "期货执行价格与当前标的价格之差",
        "men": "",
        "m2": "OptStrikeUnderlyingPriceDiff()"
    },
    {
        "n": "004001",
        "cn": "JB_CW_ByIndex",
        "en": "",
        "mcn": "根据季度位置取季报的数据项",
        "men": "",
        "m2": "JB_CW_ByIndex(4, -1)"
    },
    {
        "n": "004001001",
        "cn": "Sym_JB_CW_ByIndex",
        "en": "",
        "mcn": "根据季度位置取季报的数据项",
        "men": "",
        "m2": "Sym_JB_CW_ByIndex('SZ000001',4, -1)"
    },
    {
        "n": "004002",
        "cn": "JB_CW_LAST",
        "en": "",
        "mcn": "最新季报的值",
        "men": "",
        "m2": "JB_CW_LAST(4)"
    },
    {
        "n": "004002001",
        "cn": "Sym_JB_CW_LAST",
        "en": "",
        "mcn": "最新季报的值",
        "men": "",
        "m2": "Sym_JB_CW_LAST('SZ000001',4)"
    },
    {
        "n": "004003",
        "cn": "JB_CW_LastQuarterIndex",
        "en": "",
        "mcn": "最新出财务报表的季度值",
        "men": "",
        "m2": "JB_CW_LastQuarterIndex()"
    },
    {
        "n": "004003001",
        "cn": "Sym_JB_CW_LastQuarterIndex",
        "en": "",
        "mcn": "最新出财务报表的季度值",
        "men": "",
        "m2": "JB_CW_LastQuarterIndex('SZ000001')"
    },
    {
        "n": "004004",
        "cn": "JB_CW_Sum",
        "en": "",
        "mcn": "几个季报的数据之和",
        "men": "",
        "m2": "JB_CW_Sum(4, n,-1)"
    },
    {
        "n": "004004001",
        "cn": "Sym_JB_CW_Sum",
        "en": "",
        "mcn": "几个季报的数据之和",
        "men": "",
        "m2": "Sym_JB_CW_Sum('SZ000001',4, n,-1)"
    },
    {
        "n": "004005",
        "cn": "JB_CW_Data ",
        "en": "",
        "mcn": "某年某季度的季报发布值",
        "men": "",
        "m2": "JB_CW_Data (4, 2016, 1)"
    },
    {
        "n": "004005001",
        "cn": "Sym_JB_CW_Data ",
        "en": "",
        "mcn": "某年某季度的季报发布值",
        "men": "",
        "m2": "Sym_JB_CW_Data ('SZ000001',4, 2016, 1 )"
    },
    {
        "n": "004006",
        "cn": "JB_CW_ByQuarter",
        "en": "",
        "mcn": "某年某季度的季报变动值",
        "men": "",
        "m2": "JB_CW_ByQuarter(4, 2016, 1)"
    },
    {
        "n": "004006001",
        "cn": "Sym_JB_CW_ByQuarter",
        "en": "",
        "mcn": "某年某季度的季报变动值",
        "men": "",
        "m2": "Sym_JB_CW_ByQuarter('SZ000001',4, 2016, 1)"
    },
    {
        "n": "004007",
        "cn": "SymbolInfo",
        "en": "",
        "mcn": "上市日期",
        "men": "",
        "m2": "SymbolInfo（8）"
    },
    {
        "n": "004007001",
        "cn": "Sym_SymbolInfo",
        "en": "",
        "mcn": "上市日期",
        "men": "",
        "m2": "Sym_SymbolInfo（'SZ000001', 8 )"
    },
    {
        "n": "004008",
        "cn": "StockFloatByIndex",
        "en": "",
        "mcn": "流通股票信息",
        "men": "",
        "m2": "StockFloatByIndex('MarketValue', 'Qty', 'Ratio', -1)"
    },
    {
        "n": "004008001",
        "cn": "Sym_StockFloatByIndex",
        "en": "",
        "mcn": "流通股票信息",
        "men": "",
        "m2": "Sym_StockFloatByIndex( 'SZ000001','MarketValue', 'Qty', 'Ratio', -1)"
    },
    {
        "n": "005001",
        "cn": "TS_Get",
        "en": "",
        "mcn": "读取时间序列函数值",
        "men": "",
        "m2": "TS_Get( @ ,-1)"
    },
    {
        "n": "005002",
        "cn": "TS_Plus",
        "en": "",
        "mcn": "时间序列的加法运算",
        "men": "",
        "m2": "TS_Plus(@ ,@ )"
    },
    {
        "n": "005003",
        "cn": "TS_PlusX",
        "en": "",
        "mcn": "时间序列与一个数的加法运算",
        "men": "",
        "m2": "TS_PlusX(@ , 2)"
    },
    {
        "n": "005004",
        "cn": "TS_Minus",
        "en": "",
        "mcn": "时间序列的减法运算",
        "men": "",
        "m2": "TS_Minus(@ ,@ )"
    },
    {
        "n": "005005",
        "cn": "TS_MinusX",
        "en": "",
        "mcn": "时间序列与一个数的减法运算",
        "men": "",
        "m2": "TS_MinusX(@ , 2)"
    },
    {
        "n": "005006",
        "cn": "TS_Multiply",
        "en": "",
        "mcn": "时间序列的乘法运算",
        "men": "",
        "m2": "TS_Multiply(@ ,@ )"
    },
    {
        "n": "005007",
        "cn": "TS_MultiplyX",
        "en": "",
        "mcn": "时间序列与一个数的乘法运算",
        "men": "",
        "m2": "TS_MultiplyX(@ , 2)"
    },
    {
        "n": "005008",
        "cn": "TS_Divide",
        "en": "",
        "mcn": "时间序列的除法运算",
        "men": "",
        "m2": "TS_Divide(@ , 2)"
    },
    {
        "n": "005009",
        "cn": "TS_DivideX",
        "en": "",
        "mcn": "时间序列与一个数的除法运算",
        "men": "",
        "m2": "TS_DivideX(@ , 2)"
    },
    {
        "n": "005010",
        "cn": "TsOperator",
        "en": "",
        "mcn": "四则运算的时间序列",
        "men": "",
        "m2": "TsOperator('-',@ , @ )"
    },
    {
        "n": "005011",
        "cn": "TS_Ref\n",
        "en": "",
        "mcn": "需剔除数据的时间序列",
        "men": "",
        "m2": "TS_Ref(@ , 3)"
    },
    {
        "n": "005012",
        "cn": "TS_Size",
        "en": "",
        "mcn": "时间序列大小",
        "men": "",
        "m2": "TS_Size(@ )"
    },
    {
        "n": "005013",
        "cn": "TS_ValidCount",
        "en": "",
        "mcn": "时间序列有效值大小",
        "men": "",
        "m2": " TS_ValidCount( @ , -20, -1 )"
    },
    {
        "n": "006001",
        "cn": "LoopAnd",
        "en": "",
        "mcn": "条件全部满足",
        "men": "",
        "m2": "LoopAnd( {}, -10, -1,1)"
    },
    {
        "n": "006002",
        "cn": "LoopOr",
        "en": "",
        "mcn": "条件之一满足",
        "men": "",
        "m2": "LoopOr( {}, -10, -1,1)"
    },
    {
        "n": "006003",
        "cn": "LoopAvg",
        "en": "",
        "mcn": "循环表达式的平均值",
        "men": "",
        "m2": "LoopAvg( {}, -10, -1,1)"
    },
    {
        "n": "006004",
        "cn": "LoopSum",
        "en": "",
        "mcn": "循环表达式的和",
        "men": "",
        "m2": "LoopSum( {}, -10, -1,1)"
    },
    {
        "n": "006005",
        "cn": "LoopMax",
        "en": "",
        "mcn": "循环表达式的最大值",
        "men": "",
        "m2": "LoopMax( {}, -10, -1,1)"
    },
    {
        "n": "006006",
        "cn": "LoopMin",
        "en": "",
        "mcn": "循环表达式的最小值",
        "men": "",
        "m2": "LoopMin( {}, -10, -1,1)"
    },
    {
        "n": "006007",
        "cn": "LoopCount",
        "en": "",
        "mcn": "满足循环表达式的数量",
        "men": "",
        "m2": "LoopCount( {}, -10, -1,1)"
    },
    {
        "n": "006008",
        "cn": "LoopStddev",
        "en": "",
        "mcn": "循环表达式的标准差",
        "men": "",
        "m2": "LoopStddev( {}, -10, -1,1)"
    },
    {
        "n": "007001",
        "cn": "upstream.",
        "en": "",
        "mcn": "读取上游自定义变量值",
        "men": "",
        "m2": "upstream.$"
    },
    {
        "n": "007018",
        "cn": "TSN_Stddev",
        "en": "",
        "mcn": "标准差时间序列",
        "men": "",
        "m2": "TSN_Stddev('CLOSE', 'D', 10)"
    },
    {
        "n": "007018001",
        "cn": "Sym_TSN_Stddev",
        "en": "",
        "mcn": "标准差时间序列",
        "men": "",
        "m2": "Sym_TSN_Stddev('SZ000001','CLOSE', 'D', 10)"
    },
    {
        "n": "008001",
        "cn": "Avg",
        "en": "",
        "mcn": "一组数的平均值",
        "men": "",
        "m2": "Avg(1,2,3,...)"
    },
    {
        "n": "008002",
        "cn": "TS_Avg",
        "en": "",
        "mcn": "平均值时间序列",
        "men": "",
        "m2": "TS_Avg(@ ,@ ,@ ,...)"
    },
    {
        "n": "008003",
        "cn": "Sum",
        "en": "",
        "mcn": "一组数的和",
        "men": "",
        "m2": "Sum(1,2,3,...)"
    },
    {
        "n": "008004",
        "cn": "TS_Sum\n",
        "en": "",
        "mcn": "一组数的和时间序列",
        "men": "",
        "m2": "TS_Sum(@ ,@ ,@ ,...)"
    },
    {
        "n": "008005",
        "cn": "Max",
        "en": "",
        "mcn": "一组数的最大值",
        "men": "",
        "m2": "Max(1,2,3,...)"
    },
    {
        "n": "008006",
        "cn": "TS_Max",
        "en": "",
        "mcn": "最大值时间序列",
        "men": "",
        "m2": "TS_Max( @SMA5, -20,  -1, '$out_index' )"
    },
    {
        "n": "008007",
        "cn": "MaxExNan",
        "en": "",
        "mcn": "一组数的最大值",
        "men": "",
        "m2": "MaxExNan(1,2,3,...)"
    },
    {
        "n": "008008",
        "cn": "Min",
        "en": "",
        "mcn": "一组数的最小值",
        "men": "",
        "m2": "Min(1,2,3,...)"
    },
    {
        "n": "008009",
        "cn": "TS_Min\n",
        "en": "",
        "mcn": "最小值时间序列",
        "men": "",
        "m2": "TS_Min( @SMA5, -20,  -1, '$out_index' )"
    },
    {
        "n": "008010",
        "cn": "MinExNan",
        "en": "",
        "mcn": "一组数的最小值",
        "men": "",
        "m2": "MinExNan(1,2,3,...)"
    },
    {
        "n": "008011",
        "cn": "Dvar",
        "en": "",
        "mcn": "数据集的方差",
        "men": "",
        "m2": "Dvar ('CLOSE', 'D', -10, -1)"
    },
    {
        "n": "008011001",
        "cn": "Sym_Dvar",
        "en": "",
        "mcn": "数据集的方差\n",
        "men": "",
        "m2": "Sym_Dvar('SZ000001','CLOSE', 'D', -10, -1)"
    },
    {
        "n": "008012",
        "cn": "Correl",
        "en": "",
        "mcn": "两个数据集的相关系数\n",
        "men": "",
        "m2": "Correl ('CLOSE', 'D', -10, -1, ' SZ002736')"
    },
    {
        "n": "008012001",
        "cn": "Sym_Correl",
        "en": "",
        "mcn": "两个数据集的相关系数",
        "men": "",
        "m2": "Sym_Correl('SZ000001','CLOSE', 'D', -10, -1, ' SZ002736')"
    },
    {
        "n": "008013",
        "cn": "XS_Value_Correl",
        "en": "",
        "mcn": "两个时间序列的相关系数",
        "men": "",
        "m2": "XS_Value_Correl(@ ,@ ,20,-1) "
    },
    {
        "n": "008014",
        "cn": "Covar",
        "en": "",
        "mcn": "两个数据集的协方差\n",
        "men": "",
        "m2": "Covar ('CLOSE', 'D', -10, -1, ' SZ002736')"
    },
    {
        "n": "008014001",
        "cn": "Sym_Covar",
        "en": "",
        "mcn": "两个数据集的协方差",
        "men": "",
        "m2": "Sym_Covar('SZ000001','CLOSE', 'D', -10, -1, ' SZ002736')"
    },
    {
        "n": "008015",
        "cn": "XS_Value_Covar",
        "en": "",
        "mcn": "两个时间序列的协方差",
        "men": "",
        "m2": "XS_Value_Covar(@ ,@ ,20,-1) "
    },
    {
        "n": "008016",
        "cn": "Median",
        "en": "",
        "mcn": "数据集的中间值",
        "men": "",
        "m2": "Median('CLOSE', 'D', -10, -1)"
    },
    {
        "n": "008016001",
        "cn": "Sym_Median",
        "en": "",
        "mcn": "数据集的中间值",
        "men": "",
        "m2": "Sym_Median('SZ000001','CLOSE', 'D', -10, -1)"
    },
    {
        "n": "008017",
        "cn": "Stddev",
        "en": "",
        "mcn": "数据集的标准差\n",
        "men": "",
        "m2": "Stddev('CLOSE', 'D', -10, -1)"
    },
    {
        "n": "008017001",
        "cn": "Sym_Stddev",
        "en": "",
        "mcn": "数据集的标准差\n",
        "men": "",
        "m2": "Sym_Stddev('SZ000001','CLOSE', 'D', -10, -1)"
    },
    {
        "n": "008019",
        "cn": "TS_Stddev",
        "en": "",
        "mcn": "标准差时间序列",
        "men": "",
        "m2": "TS_Stddev(@ , 5)"
    },
    {
        "n": "008020",
        "cn": "TS_Value_Stddev(@ts)",
        "en": "",
        "mcn": "时间序列标准差的值",
        "men": "",
        "m2": "TS_Value_Stddev(@  )"
    },
    {
        "n": "008021",
        "cn": "TopPercentile",
        "en": "",
        "mcn": "百分比分布的值",
        "men": "",
        "m2": "TopPercentile  ('CLOSE', 'D', -10, -1, 50)"
    },
    {
        "n": "008021001",
        "cn": "Sym_TopPercentile",
        "en": "",
        "mcn": "百分比分布的值",
        "men": "",
        "m2": "Sym_TopPercentile('SZ000001','CLOSE', 'D', -10, -1, 50)"
    },
    {
        "n": "009001",
        "cn": "SelectTopN",
        "en": "",
        "mcn": "记录扫描前N个最大结果",
        "men": "",
        "m2": "SelectTopN('$', N)"
    },
    {
        "n": "009002",
        "cn": "SelectBottomN",
        "en": "",
        "mcn": "记录扫描前N个最小结果",
        "men": "",
        "m2": "SelectTopN('$', N)"
    },
    {
        "n": "009003",
        "cn": "setScanOnceContext",
        "en": "",
        "mcn": "扫描时刻最新的tick数据",
        "men": "",
        "m2": "setScanOnceContext('14:59:58')"
    },
    {
        "n": "009004",
        "cn": "ScanFilterAsFormulaInput",
        "en": "",
        "mcn": "获取过滤器在指定期间内产生信号作为代码集",
        "men": "",
        "m2": "ScanFilterAsFormulaInput('  ', 20180330, '10:00', '14:00', 'D',2)"
    },
    {
        "n": "009005",
        "cn": "SymbolLike",
        "en": "",
        "mcn": "指定股票/期货代码",
        "men": "",
        "m2": "SymbolLike(' SZ000001')"
    },
    {
        "n": "009006",
        "cn": "SetAlertThrottle",
        "en": "",
        "mcn": "指定周期产生有限个信号",
        "men": "",
        "m2": "SetAlertThrottle('D', 10)"
    },
    {
        "n": "009007",
        "cn": "SetDownStreamAlertOnce",
        "en": "",
        "mcn": "仅触发下游一次",
        "men": "",
        "m2": "SetDownStreamAlertOnce(1)"
    },
    {
        "n": "010001",
        "cn": "ScheduleRun",
        "en": "",
        "mcn": "设置执行周期",
        "men": "",
        "m2": "ScheduleRun('H',1 '10:00', '14:00');"
    },
    {
        "n": "010002",
        "cn": "ScanFilter",
        "en": "",
        "mcn": "扫描指定过滤器",
        "men": "",
        "m2": "ScanFilter('      ');"
    },
    {
        "n": "010003",
        "cn": "ScanFilterForPastTime",
        "en": "",
        "mcn": "扫描指定过滤器的历史时间",
        "men": "",
        "m2": "ScanFilterForPastTime( '      ', '10:00');"
    },
    {
        "n": "010004",
        "cn": "ScanFilterInMem",
        "en": "",
        "mcn": "扫描指定过滤器结果保留在系统内部",
        "men": "",
        "m2": "$InMem:ScanFilterInMem('      ');"
    },
    {
        "n": "010005",
        "cn": "JoinSymbolSet",
        "en": "",
        "mcn": "两个代码集并集",
        "men": "",
        "m2": "JoinSymbolSet($  ,$  )"
    },
    {
        "n": "010006",
        "cn": "UnionSymbolSet",
        "en": "",
        "mcn": "两个代码集交集",
        "men": "",
        "m2": "UnionSymbolSet($  ,$  )"
    },
    {
        "n": "010007",
        "cn": "ExpandSymbolSet",
        "en": "",
        "mcn": "代码集扩展",
        "men": "",
        "m2": "$SC2: ExpandSymbolSet($ , 50 ,1, 1, 0.6, 0.5, 'D', 20);       \n                    "
    },
    {
        "n": "010008",
        "cn": "ApplySymbolSet",
        "en": "",
        "mcn": "指定代码集中过滤股票/期货代码",
        "men": "",
        "m2": "ApplySymbolSet('  ',$SC2)   "
    },
    {
        "n": "011001",
        "cn": "RunScript",
        "en": "",
        "mcn": "扫描外部脚本（R语言，Python）的函数",
        "men": "",
        "m2": "RunScript('r/python', ' ')"
    },
    {
        "n": "011002",
        "cn": "RunScript4SymSet",
        "en": "",
        "mcn": "执行外部脚本（R语言，Python）的函数",
        "men": "",
        "m2": "RunScript4SymSet('r/python', ' ' )"
    },
    {
        "n": "011003",
        "cn": "RunScriptFromSymSet",
        "en": "",
        "mcn": "扫描外部脚本（R语言，Python）的函数",
        "men": "",
        "m2": "RunScriptFromSymSet('r/python', ' ',$)"
    },
    {
        "n": "011004",
        "cn": "RunScript4SymSetFromSymSet",
        "en": "",
        "mcn": "执行外部脚本（R语言，Python）的函数",
        "men": "",
        "m2": "RunScript4SymSetFromSymSet('r/python', ' ',$)"
    },
    {
        "n": "012001001",
        "cn": "Abs",
        "en": "",
        "mcn": "绝对值",
        "men": "",
        "m2": "Abs(  )"
    },
    {
        "n": "012001002",
        "cn": "TS_Abs",
        "en": "",
        "mcn": "绝对值时间序列",
        "men": "",
        "m2": "TS_Abs(@ )"
    },
    {
        "n": "012001003",
        "cn": "Sqrt",
        "en": "",
        "mcn": "平方根",
        "men": "",
        "m2": "Sqrt(  )"
    },
    {
        "n": "012001004",
        "cn": "Exp",
        "en": "",
        "mcn": "指数",
        "men": "",
        "m2": "Exp(  )"
    },
    {
        "n": "012001005",
        "cn": "TS_Exp",
        "en": "",
        "mcn": "指数时间序列",
        "men": "",
        "m2": "TS_Exp(@)"
    },
    {
        "n": "012001006",
        "cn": "Ln",
        "en": "",
        "mcn": "自然对数",
        "men": "",
        "m2": "Ln(  )"
    },
    {
        "n": "012001007",
        "cn": "TS_Ln",
        "en": "",
        "mcn": "自然对数时间序列",
        "men": "",
        "m2": "TS_Ln(@)"
    },
    {
        "n": "012001008",
        "cn": "Log10",
        "en": "",
        "mcn": "常用对数",
        "men": "",
        "m2": "Log10(  )"
    },
    {
        "n": "012001009",
        "cn": "TS_Log10",
        "en": "",
        "mcn": "常用对数时间序列",
        "men": "",
        "m2": "TS_Log10(@)"
    },
    {
        "n": "012001010",
        "cn": "Fact",
        "en": "",
        "mcn": "阶乘",
        "men": "",
        "m2": "Fact(  )"
    },
    {
        "n": "012001011",
        "cn": "Rand",
        "en": "",
        "mcn": "随机数",
        "men": "",
        "m2": "Rand(  ,  )"
    },
    {
        "n": "012001012",
        "cn": "Sign",
        "en": "",
        "mcn": "判断符合函数",
        "men": "",
        "m2": "Sign(  )"
    },
    {
        "n": "012001013",
        "cn": "SignedPower",
        "en": "",
        "mcn": "保持符号的幂处理",
        "men": "",
        "m2": "SignedPower(  ,  )"
    },
    {
        "n": "012001014",
        "cn": "Mod",
        "en": "",
        "mcn": "取整后取余",
        "men": "",
        "m2": "Mod(  ,  )"
    },
    {
        "n": "012001015",
        "cn": "IntDiv",
        "en": "",
        "mcn": "取整后除法运算",
        "men": "",
        "m2": "IntDiv(  ,  )"
    },
    {
        "n": "012002001",
        "cn": "Bin",
        "en": "",
        "mcn": "二进制转十进制",
        "men": "",
        "m2": "Bin(  )"
    },
    {
        "n": "012002002",
        "cn": "Hex",
        "en": "",
        "mcn": "十六进制转十进制",
        "men": "",
        "m2": "Hex(  )"
    },
    {
        "n": "012003001",
        "cn": "Ceil",
        "en": "",
        "mcn": "正方向舍入取整",
        "men": "",
        "m2": "Ceil(  )"
    },
    {
        "n": "012003002",
        "cn": "Floor",
        "en": "",
        "mcn": "负方向舍入取整",
        "men": "",
        "m2": "Floor(  )"
    },
    {
        "n": "012003003",
        "cn": "Round",
        "en": "",
        "mcn": "四舍五入取整",
        "men": "",
        "m2": "Round(  )"
    },
    {
        "n": "012004001",
        "cn": "Sin",
        "en": "",
        "mcn": "正弦",
        "men": "",
        "m2": "Sin(  )"
    },
    {
        "n": "012004002",
        "cn": "Asin",
        "en": "",
        "mcn": "反正弦",
        "men": "",
        "m2": "Asin(  )"
    },
    {
        "n": "012004003",
        "cn": "Sinh",
        "en": "",
        "mcn": "双曲正弦",
        "men": "",
        "m2": "Sinh(  )"
    },
    {
        "n": "012004004",
        "cn": "Cos",
        "en": "",
        "mcn": "余弦",
        "men": "",
        "m2": "Cos(  )"
    },
    {
        "n": "012004005",
        "cn": "Acos",
        "en": "",
        "mcn": "反余弦",
        "men": "",
        "m2": "Acos(  )"
    },
    {
        "n": "012004006",
        "cn": "Cosh",
        "en": "",
        "mcn": "双曲余弦",
        "men": "",
        "m2": "Cosh(  )"
    },
    {
        "n": "012004007",
        "cn": "Tan",
        "en": "",
        "mcn": "正切",
        "men": "",
        "m2": "Tan(  )"
    },
    {
        "n": "012004008",
        "cn": "Atan",
        "en": "",
        "mcn": "反正切",
        "men": "",
        "m2": "Atan(  )"
    },
    {
        "n": "012004009",
        "cn": "Tanh",
        "en": "",
        "mcn": "双曲正切",
        "men": "",
        "m2": "Tanh(  )"
    },
    {
        "n": "012005001",
        "cn": "If",
        "en": "",
        "mcn": "判断条件是否为真",
        "men": "",
        "m2": "If(  , 1, 0)"
    },
    {
        "n": "012005002",
        "cn": "IfExNan\n",
        "en": "",
        "mcn": "判断条件是否为真",
        "men": "",
        "m2": "IfExNan(  , 1, 0)"
    },
    {
        "n": "012005003",
        "cn": "TS_Arr_IF",
        "en": "",
        "mcn": "判断条件是否为真",
        "men": "",
        "m2": "TS_Arr_IF(  , @ ,@ )"
    },
    {
        "n": "012005004",
        "cn": "IsNan",
        "en": "",
        "mcn": "判断数值是否是有效值（明确值）",
        "men": "",
        "m2": "IsNan(  )"
    }
];
export default FilterFunction;