var languageMessages = window.appLocale.messages;

var optionData = {
    tooltip: {
        trigger: 'axis',
        extraCssText: 'background:#fff;border:1px solid #aaa;color:#555'
    },
    legend: {
        data: [languageMessages['profit.and.loss']]
    },
    grid: {
        left: '2%',
        right: '8%',
        bottom: '2%',
        containLabel: true
    },
    xAxis: {
        type: 'category',
        name: languageMessages['price'],
        boundaryGap: false,
        data: []
    },
    yAxis: {
        type: 'value',
        name: languageMessages['profitloss'],
    },
    series: [
        {
            name: languageMessages['profit.and.loss'],
            type: 'line',
            showSymbol: false,
            smooth: true,
            itemStyle: {
                normal: {
                    color: '#0099CC',
                    lineStyle: {
                        color: '#0099CC'
                    }
                }
            },
            data: [],
            markPoint: {
                symbol: 'circle',
                symbolSize: [8, 8],
                itemStyle: {
                    color: '#336699',
                    borderColor: '#336699'
                },
                label: {
                    color:'#333333',
                    position: [10, 10],
                    //align: 'center',
                    /**
                    formatter: function (param) {
                        return param.value.split("").join("\n");
                    } 
                    */
                },
                data: []
            }
        },
        /**
        {
            name: languageMessages['breakEvenPoint'],
            type: 'line',
            itemStyle: {
                normal: {
                    color: '#339999',
                    lineStyle: {
                        color: '#339999'
                    }
                }
            },
            data: []
        }
        */
    ]
};

function deepCopy(obj, cache = []) {
    function find(list, f) {
        return list.filter(f)[0]
    }

    // just return if obj is immutable value
    if (obj === null || typeof obj !== 'object') {
        return obj
    }

    // if obj is hit, it is in circular structure
    const hit = find(cache, c => c.original === obj)
    if (hit) {
        return hit.copy
    }

    const copy = Array.isArray(obj) ? [] : {}
    // put the copy into cache at first
    // because we want to refer it in recursive deepCopy
    cache.push({
        original: obj,
        copy
    })

    Object.keys(obj).forEach(key => {
        copy[key] = deepCopy(obj[key], cache)
    })

    return copy
}

function getChartOption(chartName, data) {
    var option = optionData;
            
    var x = [];
    var y = [];
    var p = [];

    let ins = 0;
    for (var key in data) {
        let xx = data[key].x.toFixed(3);

        let yy = data[key].y;
        if (yy === 0) {
            let tmpKey = ins > 0 ? ins - 1 : 0;
            if (xx === x[tmpKey]) {
                y[tmpKey] = 0;

                if (ins > 0) ins--;
            } else {
                x.push(xx);
                y.push(yy);
            }
            
            p.push({ value: languageMessages['breakEvenPoint'], xAxis: ins, yAxis: 0 });
        } else {
            x.push(xx);
            y.push(yy);
        }

        ins++;
    }

    option.xAxis.data = x;
    option.series[0].data = y;
    option.series[0].markPoint.data = p;

    option.tooltip.formatter = function (params) {
        let result = languageMessages[chartName] === undefined ? chartName : languageMessages[chartName];
        if (params[0] !== undefined) {
            result += '<br/>';
            let yValue = params[0].value === null ? 0 : params[0].value === undefined ? 0 : params[0].value;
            if (yValue === 0) {
                result += languageMessages['breakEvenPoint'] + ': ' + params[0].name + '<br>';
            } else {
                result += languageMessages['price'] + ': ' + params[0].name + '<br>';
            }
            
            let className = yValue > 0 ? 'color:red' : 'color:green';
            result += params[0].seriesName + ': <span style=' + className + '>' + yValue.toFixed(3) + '</span>';
        }
        return result;
    }

    return deepCopy(option)
}

export { getChartOption }