import React from 'react';
import {ElementsConsumer, CardElement} from '@stripe/react-stripe-js';
import { Layout, Divider, Select, Button, message } from 'antd';

import CardSection from './CardSection';
import { string } from 'prop-types';

const { Content } = Layout;
const { Option } = Select;

var languageMessages = window.appLocale.messages;
class CheckoutForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectMon: 12,
            couponId: 0,

            amount: 0,
            currency: 'usd',

            isVip: false,

            payProcessing :false,
        }
    }

    componentWillReceiveProps(props) {
        var { data, stripe } = props;

        switch (data.status) {
            case 'strioe_success':
                if (data.vService !== undefined && data.vService !== null) {
                    let isVip = data.isVip === undefined ? false : data.isVip;

                    this.setState({ isVip }, () => {
                        this.changeAmount(isVip, this.state.selectMon, this.state.couponId);
                    });
                }
                break;
            case 'payment_method_success':
                if (data.clientSecret !== undefined) {
                    try {
                        stripe.handleCardAction(data.clientSecret).then(this.handlePaymentIntent);
                    } catch (e) {
                        console.log('e',e);
                    }
                } else {
                    if (data.permission !== undefined && data.permission !== null) {
                        let user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
                        if (user.permission !== undefined) {
                            user.permission = data.permission;
                            sessionStorage.setItem("auth", JSON.stringify(user));
                        }
                    }
                    if (data.coupons !== undefined && data.coupons !== null) {
                        sessionStorage.setItem("CouponCache", JSON.stringify(data.coupons));
                    }
                    
                    this.setState({ payProcessing: false });
                }
                break;
            case 'payment_method_failed':
                if (data.permission !== undefined && data.permission !== null) {
                    let user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
                    if (user.permission !== undefined) {
                        user.permission = data.permission;
                        sessionStorage.setItem("auth", JSON.stringify(user));
                    }
                }
                if (data.coupons !== undefined && data.coupons !== null) {
                    sessionStorage.setItem("CouponCache", JSON.stringify(data.coupons));
                }

                this.setState({ payProcessing: false });
                break;
            case 'payment_intent_success':
                if (data.permission !== undefined && data.permission !== null) {
                    let user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
                    if (user.permission !== undefined) {
                        user.permission = data.permission;
                        sessionStorage.setItem("auth", JSON.stringify(user));
                    }
                }
                if (data.coupons !== undefined && data.coupons !== null) {
                    sessionStorage.setItem("CouponCache", JSON.stringify(data.coupons));
                }
                
                this.setState({ payProcessing: false });
                break;
            case 'payment_intent_failed':
                this.setState({ payProcessing: false });
                break;
        }
    }

    handlePaymentIntent = (result) => {
        if (result.error) {
            this.setState({ payProcessing: false }, () => {
                this.props.data.dispatch({ type: "action_pay_processing", payload: { stepStatus: 'error', stepIndex: 2 }});
            });
        } else {
            let user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
            if (user.username === undefined) {
                this.setState({ payProcessing: false }, () => {
                    this.props.data.dispatch({ type: "action_non_user" });
                });
            } else {
                this.props.data.dispatch({
                    type: "action_payment_intent",
                    payload: {
                        pii: result.paymentIntent.id,
                        u: user.username,
                    }
                });
            }
        }
    }

    handlePaymentMethod = (result) => {
        let { amount, currency, selectMon, couponId } = this.state;
        
        let user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        if (user.username === undefined) {
            this.setState({ payProcessing: false }, () => {
                this.props.data.dispatch({ type: "action_non_user" });
            });
        } else {
            this.props.data.dispatch({
                type: "action_payment_method",
                payload: {
                    pmi: result.paymentMethod.id,
                    a: this.multiply(amount, 100),
                    c: currency,
                    m: selectMon,
                    n: couponId,
                    t: this.props.data.vService[0].type,
                    u: user.username,
                }
            });
        }
    }

    handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        let user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        if (user.username === undefined) {
            this.props.data.dispatch({ type: "action_non_user" });
        } else {
            const { stripe, elements } = this.props

            if (!stripe || !elements) {
                // Stripe.js has not yet loaded.
                // Make  sure to disable form submission until Stripe.js has loaded.
                return;
            }

            this.setState({ payProcessing: true }, async () => {
                const payload = await stripe.createPaymentMethod({
                    type: 'card',
                    card: elements.getElement(CardElement),
                    billing_details: {
                        // Include any additional collected billing details.
                        name: user.username,
                        //address: 'test',
                        //email: 'Jenny.Rosen@examplate.com',
                        phone: user.username,
                    },
                });

                if (payload.error) {
                    message.error(`Payment failed ${payload.error.message}`);
                    this.setState({ payProcessing: false });
                } else {
                    //this.props.data.handleStepState({ stepStatus: 'finish', stepIndex: 1 });
                    this.handlePaymentMethod(payload);
                }
            });
        }
    };

    multiply = (a, b) => {
        let m = 0, c = a.toString(), d = b.toString();

        try {
            m += c.split(".")[1].length
        } catch (e) { }

        try {
            m += d.split(".")[1].length
        } catch (e) { }
        return Number(c.replace(".", "")) * Number(d.replace(".", "")) / Math.pow(10, m);
    }

    formatPrice(amount) {
        let price = amount;//(amount / 100).toFixed(2);
        let numberFormat = new Intl.NumberFormat(['en-US'], {
            style: 'currency',
            currency: this.state.currency,
            currencyDisplay: 'symbol',
        });
        return numberFormat.format(price);
    }

    //选择套餐 折扣和优惠
    onSelectMon = key => {
        let { isVip, couponId } = this.state;
        this.changeAmount(isVip, key, couponId);
    }

    //选择优惠券
    onSelectCoupon = key => {
        let { isVip, selectMon } = this.state;
        this.changeAmount(isVip, selectMon, key);
    }

    changeAmount(isVip, selectMon, couponId) {
        let vService = this.props.data.vService !== undefined ? this.props.data.vService[0] : undefined;
        if (vService !== undefined) {
            let price = vService.price;
            let specialPrice = vService.specialPrice;

            var discounts = vService.discount.split('|');

            let amount = 0;
            //TODO：
            if (isVip) {
                let discount = 0;
                if (discounts.length > 1) {
                    discount = discounts[1].split(';');
                } else {
                    discount = discounts[0].split(';');
                }
                amount = this.getOrderTotalPrice(discount, selectMon);
            } else {
                var discount = discounts[0].split(';');
                amount = this.getOrderTotalPrice(discount, selectMon);
            }

            if (amount === 0) {
                if (isVip) {
                    amount = this.multiply(specialPrice, selectMon);
                } else {
                    amount = this.multiply(price, selectMon);
                }
            }

            if (couponId !== 0) {
                var couponSource = [];
                var coupons = JSON.parse(sessionStorage.getItem("CouponCache") || '{}') || {};
                if (coupons.dataSource !== undefined) {
                    couponSource = coupons.dataSource;
                }

                var coupon = couponSource.filter((e) => { return e.id === couponId });
                amount = this.multiply(amount, coupon[0].discount);
            }

            this.setState({ amount, selectMon, couponId });
        }
    }

    getOrderTotalPrice(discount, selectMon) {
        var total = 0;
        if (discount.length > 0) {
            for (var kk in discount) {
                var d = discount[kk].split(',');
                if (d.length > 0) {
                    if (d[0].toString() === selectMon.toString()) {
                        total = d[1];
                        break;
                    }
                }
            }
        }
        return total;
    }

    parseDiscount(discount) {
        var strInfo = '';
        if (discount === '') return strInfo;
        
        var nvs = discount.split(',');
        if (nvs.length > 0) {
            var pp1 = nvs[1];
            if (pp1 >= 10000) {
                pp1 = (pp1 / 10000) + languageMessages['wan'];
            }
            pp1 = this.formatPrice(pp1);
            if (nvs[0] === '12') {
                if (strInfo !== '') strInfo += '，';
                strInfo += pp1 + languageMessages['yuan/year'];
            }
        }
        return strInfo;
    }

    SetCoupon(type) {
        var couponSource = [];
        var coupon = JSON.parse(sessionStorage.getItem("CouponCache") || '{}') || {};
        if (coupon.dataSource !== undefined) {
            couponSource = coupon.dataSource;
        }
        var coupons = couponSource.filter((n) => n.type === type || n.type === 0);
        return (<div><Select size='small' value={this.state.couponId} style={{ width: 180 }}
            onSelect={this.onSelectCoupon}
            dropdownStyle={{ zIndex: '1' }}>
            {coupons.map(item => {
                return (<Option key={item.id} value={item.id}>{item.name}</Option>)
            })}
            {coupons.length > 0 ? (<Option key='0' value={0}>{languageMessages['do.not.use']}</Option>) : (<Option key='0' value={0}>{languageMessages['no.available']}</Option>)}
        </Select></div>);
    }

    renderSpecialPrice(specialPrice) {
        if (specialPrice === 0) return '';
        return <label>
            <span>{languageMessages['vip.special.price']}</span>
            <div className="field price">{this.formatPrice(specialPrice)}</div>
        </label>
    }

    renderBillInfo(vService) {
        if (vService === undefined) return '';
        
        return <fieldset>
            <label className='common-pay-form-hand'>
                <span>{languageMessages['service.name']}</span>
                <div className="field">{vService.title}</div>
            </label>
            <label>
                <span>{'Payment method'}</span>
                <div className="field">
                    <Select size='small' value={this.state.selectMon} style={{ width: 180 }}
                        onSelect={this.onSelectMon}
                        dropdownStyle={{ zIndex: '1' }}>
                        <Option value={1}>{this.formatPrice(vService.price) + '/month'}</Option>
                        <Option value={12}>{this.parseDiscount(vService.discount)}</Option>
                    </Select>
                </div>
            </label>
            <label>
                <span>{languageMessages['coupon']}</span>
                <div className="field">{this.SetCoupon(vService.type)}</div>
            </label>
            <label>
                <span>{languageMessages['subtotal']}</span>
                <div className="field"><span>{this.formatPrice(this.state.amount)}</span></div>
            </label>
          </fieldset>
    }

    render() {
        const { stripe, data } = this.props;
        let vService = data.vService !== undefined && data.vService !== null ? data.vService[0] : undefined;

        return (<Content className='card-section'>
            <div className='common-title'><Divider type="vertical" />{'Billing information'}</div>
            {this.renderBillInfo(vService)}
            <div className='common-title'><Divider type="vertical" />{'Payment information'}</div>
            <CardSection />
            <div className='common-total'>{languageMessages['subtotal']}： {this.formatPrice(this.state.amount)}</div>
            <Button className="ant-btn ant-btn-sm pay-btn" disabled={!stripe || this.state.payProcessing} onClick={this.handleSubmit} loading={this.state.payProcessing}>
                {languageMessages['pay']}
            </Button>
        </Content>);
    }
}

export default function InjectedCheckoutForm(data) {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <CheckoutForm stripe={stripe} elements={elements} data={data} />
            )}
        </ElementsConsumer>
    );
}