import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, PageHeader, Table, Row, Col, Button, Modal, Select, message } from 'antd';
import history from '../../helpers/history';
import emitter from '../Evt.js';

const { Content } = Layout;
const { Option } = Select;

var languageMessages = window.appLocale.messages;

class SubscriptionPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            initLoadding: true,
            dataSource: [],

            total: '',
            isVip: false,

            subscriptions: [],

            selectMon: '12',

            currency: 'usd',
        }
    }

    componentDidMount() {
        emitter.addListener('initPage', () => {
            this.init();
        });
    }

    componentWillMount() {
        this.init();
    }

    init() {
        this.user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};

        this.props.dispatch({ type: "action_get_payment_service", payload: { u: this.user.username } });
    }

    componentWillReceiveProps(props) {
        let { status, state, permission, isVip, vServices } = props;

        switch (status) {
            case 'service_success':

                permission = permission === undefined ? [] : permission;
                isVip = isVip === undefined ? false : isVip;

                if (this.user.permission !== undefined) {
                    this.user.permission = permission;
                    sessionStorage.setItem("auth", JSON.stringify(this.user));
                }

                this.SetServiceInfo(permission, isVip, vServices);

                break;
            case 'service_failed':
                break;
            case 'get_permission_success':

                break;
            case 'get_permission_failed':
                if (this.user.permission !== undefined) {
                    this.SetServiceInfo(this.user.permission, false);
                }
                var error = this.languageMessages['get.data.failed'];
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = this.languageMessages['user.exception'];
                        break;
                    default:
                        break;
                }
                message.error(error);
                break;
        }
    }

    //设置列表内容
    SetServiceInfo(permission, isVip, vServices) {
        var dataSource = [];
        if (vServices.length > 0) {
            for (var kk in vServices) {
                var value = vServices[kk];

                if (window.webType === 'US') {
                    if (value.type === 5 || value.type === 6) continue;
                }

                var discount = value.discount.split('|');
                var p = value.permission.split(';');

                let price = value.price > 0 ? this.formatPrice(value.price) : 0;
                let specialPrice = value.specialPrice > 0 ? this.formatPrice(value.specialPrice) : 0;

                price += languageMessages['yuan/month'];
                specialPrice += languageMessages['yuan/month'];

                var name = [];
                var intro = {};
                var date = '';
                var status = [];
                switch (value.type) {
                    case 0:
                        name = [{
                            title: languageMessages['ordinary.member'],
                            price: languageMessages['free'],
                        }];

                        var s = p[0].split(',');

                        var items = [languageMessages['payment.server.00.01'], languageMessages['payment.server.00.02'], languageMessages['payment.server.00.03']];

                        intro = { items: items, info: [] };
                        date = '---';

                        var ups = permission.filter(function (e) { return e.type === 's' });
                        if (ups.length > 0) {
                            var count = ups[0].totalCount - ups[0].usageCount;
                            status = [{ title: languageMessages['remaining.times'] + '：', cont: [languageMessages['real.stock.picker'] + '：' + count + languageMessages['times']] }];
                        }
                        break;
                    case 1:
                        name = [{
                            title: languageMessages['vip.member'],
                            price: price,
                            button: languageMessages['upgrade.vip.member'],
                        }];

                        var s = p[0].split(',');
                        var o = p[1].split(',');
                        var items = [languageMessages['payment.server.01.01'], languageMessages['payment.server.01.02']];
                        var info = this.parseDiscount(discount);
                        intro = { items: items, info: info };

                        var ups = permission.filter(function (e) { return e.type === 'vs' });
                        if (ups.length > 0) {
                            var count = ups[0].totalCount - ups[0].usageCount;
                            status.push({ title: languageMessages['remaining.times'] + '：', cont: [languageMessages['real.stock.picker'] + '：' + count + languageMessages['times']] });

                            date = ups[0].expiredDate;
                        }

                        var upo = permission.filter(function (e) { return e.type === 'vh' });
                        if (upo.length > 0) {
                            var count = upo[0].totalCount - upo[0].usageCount;
                            status.push({ title: languageMessages['remaining.validity.period'] + '：', cont: [languageMessages['option.strategy.scan'] + '：' + count + languageMessages['times']] });

                            date = upo[0].expiredDate;
                        }
                        break;
                    case 2:
                        name = [{
                            title: languageMessages['real.stock.picker'] + "(1)",
                            price: price,
                            specialPrice: '(' + languageMessages['vip.special'] + ':' + specialPrice + ')',
                            button: languageMessages['buy'],
                        }];

                        var s = p[0].split(',');
                        var items = [languageMessages['payment.server.02.01']];
                        var info = this.parseDiscount(discount);
                        intro = { items: items, info: info };

                        var ups = permission.filter(function (e) { return e.type === 's1' });
                        if (ups.length > 0) {
                            var count = ups[0].totalCount - ups[0].usageCount;
                            status.push({ title: languageMessages['remaining.times'] + '：', cont: [languageMessages['real.stock.picker'] + '：' + count + languageMessages['times']] });

                            date = ups[0].expiredDate;
                        }
                        break;
                    case 3:
                        name = [{
                            title: languageMessages['real.stock.picker'] + "(2)",
                            price: price,
                            specialPrice: '(' + languageMessages['vip.special'] + ':' + specialPrice + ')',
                            button: languageMessages['buy'],
                        }];

                        var s = p[0].split(',');
                        var items = [languageMessages['payment.server.03.01']];
                        var info = this.parseDiscount(discount);
                        intro = { items: items, info: info };

                        var ups = permission.filter(function (e) { return e.type === 's2' });
                        if (ups.length > 0) {
                            var count = ups[0].totalCount - ups[0].usageCount;
                            status.push({ title: languageMessages['remaining.times'] + '：', cont: [languageMessages['real.stock.picker'] + '：' + count + languageMessages['times']] });

                            date = ups[0].expiredDate;
                        }
                        break;
                    case 4:
                        name = [{
                            title: languageMessages['stock monitoringr'],
                            price: price,
                            specialPrice: '(' + languageMessages['vip.special'] + ':' + specialPrice + ')',
                            button: languageMessages['buy'],
                        }];

                        var h = p[0].split(',');
                        var items = [languageMessages['hawkeye.server.07.01']];
                        var info = this.parseDiscount(discount);
                        intro = { items: items, info: info };

                        var ups = permission.filter(function (e) { return e.type === 'h' });
                        if (ups.length > 0) {
                            var count = ups[0].totalCount - ups[0].usageCount;
                            status.push({ title: languageMessages['remaining.times'] + '：', cont: [languageMessages['real.stock.picker'] + '：' + count + languageMessages['times']] });

                            date = ups[0].expiredDate;
                        }
                        break;
                    case 5:
                        name = [{
                            title: languageMessages['option.strategy.service'],
                            price: price,
                            specialPrice: '(' + languageMessages['vip.special'] + ':' + specialPrice + ')',
                            button: languageMessages['buy'],
                        }];

                        var o = p[0].split(',');
                        var items = [languageMessages['payment.server.04.01'], languageMessages['payment.server.04.02']];
                        var info = this.parseDiscount(discount);
                        intro = { items: items, info: info };

                        var upo = permission.filter(function (e) { return e.type === 'o' });
                        if (upo.length > 0) {
                            var count = upo[0].totalCount - upo[0].usageCount;
                            status.push({ title: languageMessages['remaining.validity.period'] + '：', cont: [languageMessages['option.strategy.scan'] + '：' + count + languageMessages['times']] });

                            date = upo[0].expiredDate;
                        }
                        break;
                    case 6:
                        name = [{
                            title: languageMessages['intl.menu.factorService'],
                            price: price,
                            specialPrice: '(' + languageMessages['vip.special'] + ':' + specialPrice + ')',
                            button: languageMessages['buy'],
                        }];

                        var f = p[0].split(',');
                        var g = p[1].split(',');
                        var a = p[2].split(',');
                        var items = [languageMessages['payment.server.05.01'], languageMessages['payment.server.05.02'], languageMessages['payment.server.05.03'], languageMessages['payment.server.05.04']];
                        var info = this.parseDiscount(discount);
                        intro = { items: items, info: info };

                        var cont = [];
                        var upf = permission.filter(function (e) { return e.type === 'f' });
                        if (upf.length > 0) {
                            var count = upf[0].totalCount - upf[0].usageCount;

                            cont.push(languageMessages['factor.scan'] + '：' + count + languageMessages['times']);

                            date = upf[0].expiredDate;
                        }

                        var upg = permission.filter(function (e) { return e.type === 'g' });
                        if (upg.length > 0) {
                            var count = upg[0].totalCount - upg[0].usageCount;

                            cont.push(languageMessages['factor.generation'] + '：' + count + languageMessages['times']);

                            date = upg[0].expiredDate;
                        }

                        var upa = permission.filter(function (e) { return e.type === 'a' });
                        if (upa.length > 0) {
                            var count = upa[0].totalCount - upa[0].usageCount;

                            cont.push(languageMessages['intelligent.factor.analysis'] + '：' + count + languageMessages['times']);

                            date = upa[0].expiredDate;
                        }

                        if (cont.length > 0) {
                            status.push({ title: languageMessages['remaining.times'] + '：', cont: cont });
                        }
                        break;
                    case 7:
                        //第一列
                        //value.price
                        var pp1 = value.price;
                        if (pp1 >= 10000) {
                            pp1 = (pp1 / 10000) + languageMessages['wan'];
                        }
                        //value.specialPrice
                        var pp2 = value.specialPrice;
                        if (pp2 >= 10000) {
                            pp2 = (pp1 / 10000) + languageMessages['wan'];
                        }
                        name = [{
                            title: languageMessages['inSync.platform'],
                            price: pp1 + languageMessages['yuan/month'],
                            specialPrice: '(' + languageMessages['vip.special'] + ':' + pp2 + languageMessages['yuan/month'] + ')',
                            button: languageMessages['buy'],
                        }];

                        //第二列
                        var i = p[0].split(',');
                        var b = p[1].split(',');
                        var items = [languageMessages['payment.server.06.01'], languageMessages['payment.server.06.02'], languageMessages['payment.server.06.03'], languageMessages['payment.server.06.04'], languageMessages['payment.server.06.05']];
                        var isDiscount = true;
                        var info = this.parseDiscount(discount, isDiscount);
                        intro = { items: items, info: info };

                        //第三列
                        var cont = [];
                        var upi = permission.filter(function (e) { return e.type === 'i' });
                        if (upi.length > 0) {
                            //var count = upi[0].totalCount - upi[0].usageCount;

                            //cont.push(languageMessages['factor.scan'] + '：' + count + languageMessages['times']);

                            date = upi[0].expiredDate;
                        }

                        var upb = permission.filter(function (e) { return e.type === 'b' });
                        if (upb.length > 0) {
                            //var count = upb[0].totalCount - upb[0].usageCount;

                            //cont.push(languageMessages['factor.generation'] + '：' + count + languageMessages['times']);

                            date = upb[0].expiredDate;
                        }

                        //第三列
                        if (cont.length > 0) {
                            status.push({ title: languageMessages['remaining.times'] + '：', cont: cont });
                        }
                        break;

                }

                // 优惠券
                var couponSource = [];
                var coupon = JSON.parse(sessionStorage.getItem("CouponCache") || '{}') || {};
                if (coupon.dataSource !== undefined) {
                    couponSource = coupon.dataSource;
                }

                // 是否全场优惠券
                var universalCouponIndex = couponSource.findIndex((n) => n.type === 0);

                let subState = '';
                var isCoupon = false;
                if (value.type > 0) {
                    if (universalCouponIndex > -1) {
                        isCoupon = true;
                    } else {
                        var subscript = couponSource.findIndex((n) => n.type === value.type);
                        isCoupon = subscript > -1 ? true : false;
                    }
                    if (isVip && value.type === 1) {
                        isCoupon = false;
                    }

                    subState = 'plus';
                }

                dataSource.push({
                    key: value.type,
                    name: name,
                    intro: intro,
                    date: date,
                    status: status,
                    isCoupon: isCoupon,
                    subState: subState,
                });
            }
        }

        this.setState({
            initLoadding: false,
            dataSource,
            isVip: isVip,
        });
    }

    formatPrice(amount) {
        let price = amount;//(amount / 100).toFixed(2);
        let numberFormat = new Intl.NumberFormat(['en-US'], {
            style: 'currency',
            currency: this.state.currency,
            currencyDisplay: 'symbol',
        });
        return numberFormat.format(price);
    }
    //设置第二列的折扣和优惠价
    parseDiscount(discount, isDiscount = false) {
        var info = [];
        if (discount.length > 0) {
            var strInfo = '';
            var nv = discount[0].split(';');
            if (nv.length > 0) {
                for (var n in nv) {
                    var nvs = nv[n].split(',');
                    if (nvs.length > 0) {
                        var pp1 = nvs[1];
                        if (pp1 >= 10000) {
                            pp1 = (pp1 / 10000) + languageMessages['wan'];
                        }
                        pp1 = this.formatPrice(pp1);
                        if (nvs[0] === '12') {
                            if (strInfo !== '') strInfo += '，';
                            strInfo += pp1 + languageMessages['yuan/year'] + '(' + nvs[2] + languageMessages['discount'] + ')';
                        } else if (nvs[0] === '24') {
                            if (strInfo !== '') strInfo += '，';
                            strInfo += pp1 + languageMessages['yuan/2year'] + '(' + nvs[2] + languageMessages['discount'] + ')';
                        }
                    }
                }
                if (isDiscount) strInfo = languageMessages['vip.special'] + '：' + strInfo;
            }
            info.push(strInfo);

            if (discount.length > 1) {
                strInfo = '';
                nv = discount[1].split(';');
                if (nv.length > 0) {
                    for (var n in nv) {
                        var nvs = nv[n].split(',');
                        if (nvs.length > 0) {
                            pp1 = this.formatPrice(nvs[1]);
                            if (nvs[0] === '12') {
                                if (strInfo !== '') strInfo += '，'
                                strInfo += pp1 + languageMessages['yuan/year'];
                            } else if (nvs[0] === '24') {
                                if (strInfo !== '') strInfo += '，'
                                strInfo += pp1 + languageMessages['yuan/2year'];
                            }
                        }
                    }
                    strInfo = languageMessages['vip.special'] + '：' + strInfo;
                }
                info.push(strInfo);
            }
        }
        return info;
    }

    onBuyService = (key) => {
        //history.push({ pathname: '/charge', state: { id: key, title: title } });

        //let data = this.state.dataSource.filter(function (n) { n.key == key });
        

        let { dataSource, subscriptions } = this.state;

        for (let k in dataSource) {
            if (dataSource[k].key == key) {
                if (dataSource[k].subState === 'plus') {
                    dataSource[k].subState = 'minus';
                    subscriptions.push(key);
                } else {
                    dataSource[k].subState = 'plus';
                    subscriptions = subscriptions.filter(function (item) {
                        return item !== key
                    });
                }
            }
        }

        this.setState({ dataSource, subscriptions });
    }

    jumpCoupon = e => {
        history.push("/coupon");
    }

    onMySubscription = e => {
        e.preventDefault();
        if (this.user.username === undefined) {
            this.props.dispatch({ type: "action_non_user" });
        } else {
            history.push({ pathname: '/member', state: { memberMenuSelectKey: "7" } });
        }
    }

    handleSubscription = e => {
        let { subscriptions } = this.state;
        if (subscriptions.length > 0) {
            history.push({ pathname: '/b', state: { subscriptions: this.state.subscriptions } });
        }
    }

    render() {
        const columns = [
            {
                title: languageMessages['service.name'],
                dataIndex: 'name',
                align: 'center',
                className: 'common-service-name',
                render: (name, record) => (<div>
                    {record.isCoupon && (<div className="coupon-conent">
                        <div className="coupon-conent-red" onClick={this.jumpCoupon}>{languageMessages['coupon']}</div>
                    </div>)}
                    {
                        name.map((item, index) => {
                            return (<div key={index}>
                                <p>{item.title}</p>
                                <div>{item.price}</div>
                                {item.specialPrice !== undefined && (<div>{item.specialPrice}</div>)}
                                {(item.button !== undefined) && (<div><Button size='small' className={record.subState === 'plus' ? '' : 'common-btn-selected'} icon={record.subState} onClick={() => this.onBuyService(record.key)} >{'Subscription'}</Button></div>)}
                            </div>);
                        })
                    }</div>)
            }, {
                title: languageMessages['service.intro'],
                dataIndex: 'intro',
                render: intro => {
                    var items = intro.items.map((item, index) => {
                        return (<span key={index} style={{ display: 'block' }}>{item}</span>)
                    })
                    var info = intro.info.map((item, index) => {
                        return (<span key={index} style={{ display: 'block' }}>{item}</span>)
                    })

                    return (<div><p>{items}</p><p>{info}</p></div>);
                }
            }, {
                title: languageMessages['expiredDate'],
                dataIndex: 'date',
                align: 'center',
                width: 100,
            }, {
                title: languageMessages['status'],
                dataIndex: 'status',
                width: 300,
                render: status => (<div>{
                    status.map((item, index) => {
                        return (<div key={index} style={{ padding: '5px 0' }}>
                            <span style={{ display: 'block' }}>{item.title}</span>
                            {item.cont.map((ii, i) => {
                                return (<b key={i} style={{ display: 'block' }}>{ii}</b>)
                            })}
                        </div>);
                    })
                }</div>),
            }
        ];

        return (<Content className='main-container'>
            <div className='common-page-header'>
                <PageHeader title={languageMessages['please.select.service.type']}
                    extra={[
                        <Button key="1" size='small' onClick={this.onMySubscription}>{'My subscription'}</Button>
                    ]} />
            </div>
            <Layout className='cont-content luis-main-content'>
                <p style={{ padding: '10px 0' }}>{languageMessages['payment.info.03']}</p>

                <Table size='small' bordered
                    columns={columns}
                    dataSource={this.state.dataSource}
                    loading={this.state.initLoadding}
                    pagination={false}
                    footer={() => <div style={{ textAlign: "right" }}>
                        <Button className="ant-btn ant-btn-sm" style={{ padding: '10px 20px', margin: '0 10px', height: '40px', lineHeight: '20px' }}
                            onClick={this.handleSubscription} disabled={this.state.subscriptions <= 0}>{'Subscription'}</Button>
                    </div>} />
                
                <div style={{ margin: '20px 0', border: '1px solid #e8e8e8', padding: '16px' }}>
                    <b>{languageMessages['description']}：</b>
                    <p>
                        <span style={{ display: 'block' }}>{languageMessages['payment.info.01']}</span>
                        <span style={{ display: 'block' }}>{languageMessages['payment.info.02']}</span>
                    </p>
                    <p>
                        <span style={{ display: 'block' }}>*{languageMessages['quancept.info.qq']}</span>
                        <span>{languageMessages['quancept.info.work']}</span>
                    </p>
                </div>
            </Layout>
        </Content>)
    }
}

function mapStateToProps(state) {
    return state.charge;
}

const page = connect(mapStateToProps)(SubscriptionPage);
export { page as default }