import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import history from '../../helpers/history';

import { Layout, Steps, Result, Button, Icon, Spin } from 'antd';

import CheckoutForm from './charge/CheckoutForm';
import emitter from '../Evt.js';

import './CardSectionStyles.css'

const { Content } = Layout;
const { Step } = Steps;

var languageMessages = window.appLocale.messages;
class ChargePage extends Component {
    constructor(props) {
        super(props);

        this.stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, { locale: window.webType === 'US' ? 'en' : 'auto' });

        this.state = {
            spinLoadding: true,
            
            currency: 'usd',
            
            stepStatus: 'wait', //wait process finish error
            stepIndex: 1,
        }
    }

    componentDidMount() {
        emitter.addListener('initPage', () => {
            this.init();
        });
    }

    componentWillMount() {
        this.init();
    }

    init() {
        this.user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        
        if (history.location.state !== undefined) {
            let type = history.location.state.id;

            this.props.dispatch({ type: "action_get_stripe_key", payload: { u: this.user.username, t: type } });
        }
    }

    componentWillReceiveProps(props) {
        var { status, clientSecret, stepStatus, stepIndex } = props;

        switch (status) {
            case 'strioe_success':
                this.setState({ spinLoadding: false });
                break;
            case 'strioe_failed':
                break;
            case 'payment_method_success':
                if (clientSecret === undefined) {
                    //success jump page

                    this.setState({ stepStatus: 'finish', stepIndex: 2 });
                }
                break;
            case 'payment_method_failed':
                this.setState({ stepStatus: 'error', stepIndex: 2 });
                break;
            case 'payment_intent_success':
                this.setState({ stepStatus: 'finish', stepIndex: 2 });
                break;
            case 'payment_intent_failed':
                this.setState({ stepStatus: 'error', stepIndex: 2 });
                break;
            case 'pay_processing':
                this.setState({ stepStatus, stepIndex });
                break;
        }
    }

    handleStepState(data) {
        this.setState({ stepStatus: data.stepStatus, stepIndex: data.stepIndex });
    }

    formatPrice(amount) {
        let price = amount;//(amount / 100).toFixed(2);
        let numberFormat = new Intl.NumberFormat(['en-US'], {
            style: 'currency',
            currency: this.state.currency,
            currencyDisplay: 'symbol',
        });
        return numberFormat.format(price);
    }

    handleBackHome = () => {
        history.replace("/");
    }

    handlePayAgain = () => {
        this.setState({ stepStatus: 'wait', stepIndex: 1, spinLoadding: true }, () => {
            this.init();
        });
    }

    renderSuccess() {
        let title = '', amount = 0, coupon = '--', months = 0, expiredDate = '', orderNo = '', paymentStatus = 0;
        let paymentMethod = [];
        if (this.props.account !== undefined) {
            title = this.props.account.intro;
            amount = this.formatPrice(this.props.account.amount);
            
            months = this.props.account.months + ' ' + 'months';
            expiredDate = this.props.account.expiredDate;
            orderNo = this.props.account.orderNo;
            paymentStatus = this.props.account.status;

            if (this.props.account.paymentMethodDetails !== undefined) {
                let paymentMethodDetails = this.props.account.paymentMethodDetails.split('|');
                if (paymentMethodDetails['0'] === 'card') {
                    paymentMethod.push(<Icon key='card 01' type="credit-card" />);
                    paymentMethod.push(' ');
                    paymentMethod.push(paymentMethodDetails['1']);
                    paymentMethod.push(' •••• ');//<Icon type="small-dash" />
                    paymentMethod.push(paymentMethodDetails[2]);
                }
            }

            let couponId = this.props.account.couponId;
            if (couponId !== 0) {
                var couponSource = [];
                var coupons = JSON.parse(sessionStorage.getItem("CouponCache") || '{}') || {};
                if (coupons.dataSource !== undefined) {
                    couponSource = coupons.dataSource;
                }

                var coupons = couponSource.filter((e) => { return e.id === couponId });
                coupon = coupons[0].name;
            }
        }

        let status = 'error';
        let subject = 'Your payment failed';
        let btn = [<Button key="resubscribe" onClick={this.handlePayAgain}>Pay again</Button>];
        if (paymentStatus === 1) {
            status = 'success';
            subject = 'Your payment succeeded';
            btn = [<Button key="resubscribe" onClick={this.handleBackHome}>Back home</Button>];
        }

        return (<div className="common-payment-success">
            <Result status={status} title={subject} subTitle="" extra={btn}>
                <div className="common-payment-cont card-section">
                    <fieldset>
                        <label>
                            <span>{languageMessages['service.name']}</span>
                            <div className="field">{title}</div>
                        </label>
                        <label>
                            <span>{'Total'}</span>
                            <div className="field"><span>{amount}</span></div>
                        </label>
                        <label>
                            <span>{'Payment method'}</span>
                            <div className="field">{paymentMethod}</div>
                        </label>
                        <label>
                            <span>{'Coupon'}</span>
                            <div className="field">{coupon}</div>
                        </label>
                        <label>
                            <span>{'Months'}</span>
                            <div className="field">{months}</div>
                        </label>
                        <label>
                            <span>{'Expired date'}</span>
                            <div className="field">{expiredDate}</div>
                        </label>
                        <label>
                            <span>{'Order no.'}</span>
                            <div className="field">{orderNo}</div>
                        </label>
                    </fieldset>
                    <div className='common-payment-tips'>
                        <p>{languageMessages['payment.prompt.01']}</p>
                        <p>{languageMessages['payment.prompt.02']}</p>
                    </div>
                </div>
            </Result>
        </div>);
    };

    renderForm() {
        return <Layout className='common-main-content'>
            <Elements stripe={this.stripePromise}>
                <CheckoutForm {...this.props} handleStepState={this.handleStepState.bind(this)} />
                </Elements>
            </Layout>
    };

    render() {
        return (<Content className='main-container'>
            <Spin spinning={this.state.spinLoadding}>
                <div className='common-page-header'>
                    <Steps current={this.state.stepIndex} status={this.state.stepStatus}>
                        <Step title="Billing information" />
                        <Step title="Payment and confirmation" />
                        <Step title="Finished" />
                    </Steps>
                </div>
                {this.state.stepIndex === 2 ? this.renderSuccess() : this.renderForm()}
            </Spin>
        </Content>)
    }
}

function mapStateToProps(state) {
    return state.charge;
}

const page = connect(mapStateToProps)(ChargePage);
export { page as default }