export default function customStock(state = {}, action) {
    switch (action.type) {
        case "get_symbol_items_success":
            return { ...state, status: 'get_symbol_items_success', symbolItems: action.symbolItems };
        case "get_symbol_items_fail":
            return { ...state, status: 'get_symbol_items_fail', state: action.status };
        case "customStockTick_success":
            return { ...state, status: 'customStockTick_success', symbolList: action.symbolList };
        case "customStockTick_fail":
            return { ...state, status: 'customStockTick_fail', state: action.status };

        case "customStock_commit":
            return { ...state, status: 'customStock_commit' };
        case "add_customStock_success":
            return { ...state, status: 'add_customStock_success', customGroup: action.customGroup, symbolList: action.symbolList };
        case "add_customStock_fail":
            return { ...state, status: 'add_customStock_fail', state: action.status, fail: action.fail };

        case "update_customStock_success":
            return { ...state, status: 'update_customStock_success', customGroup: action.customGroup, symbolList: action.symbolList };
        case "update_customStock_fail":
            return { ...state, status: 'update_customStock_fail', state: action.status };

        case "remove_customStock_success":
            return { ...state, status: 'remove_customStock_success', customStock: action.customStock, symbolList: action.symbolList };
        case "remove_customStock_fail":
            return { ...state, status: 'remove_customStock_fail', state: action.status };

        default:
            return state;
    }
}