import { all } from 'redux-saga/effects'
import member from './pages/member/effects'
import concept from './pages/concept/effects'
import vernacular from './pages/vernacular/effects'
import customStock from './pages/customStock/effects'
import strategy from './pages/strategy/effects'
import option from './pages/option/effects'
import hawkeye from './pages/hawkeye/effects'
import factor from './pages/factor/effects'
import download from './pages/download/effects'
import test from './pages/test/effects'
import charge from './pages/stripe/effects'
import fail from './pages/effects'

export default function* rootSaga() {
    yield all([
        member(),
        concept(),
        vernacular(),
        customStock(),
        strategy(),
        option(),
        hawkeye(),
        factor(),
        download(),
        test(),
        charge(),
        fail(),
    ])
}