import { combineReducers } from 'redux';
import member from './pages/member/reducer'
import concept from './pages/concept/reducer'
import vernacular from './pages/vernacular/reducer'
import customStock from './pages/customStock/reducer'
import strategy from './pages/strategy/reducer'
import option from './pages/option/reducer'
import factor from './pages/factor/reducer'
import hawkeye from './pages/hawkeye/reducer'
import download from './pages/download/reducer'
import test from './pages/test/reducer'
import charge from './pages/stripe/reducer'
import fail from './pages/reducer'

const rootReducer = combineReducers({
    member,
    concept,
    vernacular,
    customStock,
    strategy,
    option,
    factor,
    hawkeye,
    download,
    test,
    charge,
    fail,
})

export default rootReducer;