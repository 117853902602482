import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from "react-csv";
import history from '../../helpers/history';

import { FormattedMessage } from 'react-intl';

import { FactorGenerator } from './FactorGenerator';

import { Layout, Table, PageHeader, Modal, Select, Button, Spin, Icon, Row, Col, Divider, message } from 'antd';
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";

import SymbolGroupPage from '../../pannels/stock/SymbolGroupPage';

import TooltipPage from '../../pannels/account/TooltipPage';
import emitter from '../Evt.js';

import FilterFunction from '../filterFunctions';

const { Content } = Layout;

const Option = Select.Option;

const Item = ({ entity: { name, txt, char } }) => <Row><Col span={7}>{name}</Col><Col span={17}>{txt}</Col></Row>;

var timer = null;

var languageMessages = window.appLocale.messages;

class FactorPage extends Component {
    constructor(props) {
        super(props);

        this.csvData = [];
        this.myDate = new Date();
        this.fileName = 'scan_factor_result_' + this.myDate.getYear() + '' + this.myDate.getMonth() + '' + this.myDate.getDate() + '' + this.myDate.getHours() + '' + this.myDate.getMinutes() + '' + this.myDate.getSeconds() + ".csv";

        this.user = {};
        this.state = {
            stockPoolKey: 'STOCK',
            stockPickerModeKey: 'REAL',

            fLastTimes: 0,

            pastTime: '10:00',

            caretPosition: 0,

            stockGroups: '3069',

            filterId: 0,
            filterName: '',
            filterString: '',

            isShowStockResultPage: false,

            spinLoadding: true,
            
            selectedRowKeys: [],

            error: '',
            filterError: '',

            textareaClass: 'common-textarea',

            scanFilterStatus: true,
            generatorStatus: false,
        }
    }

    componentDidMount() {
        emitter.addListener('initPage', () => {
            this.init();
        });
    }

    componentWillMount() {
        this.init();
    }

    init() {
        this.user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        /**
        if (user.username === undefined) {
            this.setState({ spinLoadding: false }, () => {
                this.props.dispatch({ type: "action_non_user" });
            });
        } else {
            this.props.dispatch({ type: "action_get_factor_sectors", payload: { UserId: user.username, stockPoolKey: this.state.stockPoolKey, stockGroups: this.state.stockGroups } });
        }
        */
        this.props.dispatch({ type: "action_get_factor_sectors", payload: { UserId: this.user.username, stockPoolKey: this.state.stockPoolKey, stockGroups: this.state.stockGroups } });
    }

    setUserPermission(permissions) {
        var fLastTimes = 0;
        for (var key in permissions) {
            var permission = permissions[key];
            switch (permission.type) {
                case 'f':
                    fLastTimes += permission.totalCount - permission.usageCount;
                    break;
            }
        }
        this.setState({ fLastTimes });
    }

    componentWillReceiveProps(props) {
        let { status, state, fail, filter, permission, fLastTimes } = props;

        switch (status) {
            case 'get_factor_filters_success':
                if (this.user.permission !== undefined) {
                    this.user.permission = permission;
                    sessionStorage.setItem("auth", JSON.stringify(this.user));
                    this.setUserPermission(this.user.permission);
                }

                var filterId = 0;
                var filterName = '';
                var filterString = '';
                var scanFilterStatus = true;
                if (filter !== null && filter.filterId !== undefined) {
                    filterId = filter.filterId;
                    filterName = filter.filterName;
                    filterString = filter.filterString;
                    scanFilterStatus = false;
                }

                this.setState({
                    filterId,
                    filterName,
                    filterString,
                    spinLoadding: false,
                    scanFilterStatus,
                });
                break;
            case 'get_factor_filters_failed':
                if (this.user.permission !== undefined) {
                    this.setUserPermission(this.user.permission);
                }
                var error = languageMessages['get.data.failed'];
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = languageMessages['user.exception'];
                        break;
                    default:
                        break;
                }
                message.error(error);
                this.setState({ spinLoadding: false });
                break;
            case 'scan_factorFilter_success':
                this.user.permission = permission;
                sessionStorage.setItem("auth", JSON.stringify(this.user));

                this.fileName = 'scan_factor_result_' + this.myDate.getYear() + '' + this.myDate.getMonth() + '' + this.myDate.getDate() + '' + this.myDate.getHours() + '' + this.myDate.getMinutes() + '' + this.myDate.getSeconds() + ".csv";

                var filterId = filter.filterId;
                var filterName = filter.filterName;
                var filterString = filter.filterString;
                var scanFilterStatus = filterId > 0 ? false : true;

                this.setState({
                    filterId,
                    filterName,
                    filterString,
                    fLastTimes: fLastTimes,
                    isShowStockResultPage: true,
                    filterError: '',
                    textareaClass: 'common-textarea',
                    scanFilterStatus,
                });
                break;
            case 'scan_factorFilter_failed':
                var error = '';
                var textareaClass = 'common-textarea';
                switch (state) {
                    case 0:
                        error = languageMessages['scan.strategy.exception'];
                        break;
                    case 2:
                        error = languageMessages['no.scan.to.stock'];
                        break;
                    case 3:
                        error = languageMessages['connection.strategy.Service.failed'];
                        break;
                    case 4:
                        error = languageMessages['user.exception'];
                        break;
                    case 5:
                        error = languageMessages['request.scan.strategy.failed'];
                        break;
                    case 6:
                        error = languageMessages['scan.strategy.error'];
                        if (fail === 'Filter quota is reached.') {
                            error = languageMessages['reaching.strategy.quota'];
                        } else {
                            textareaClass = 'common-textarea common-textarea-error';
                            var eer = '';
                            var ff = fail.split(':');
                            if (ff.length > 0) {
                                for (var i in ff) {
                                    if (i == 0) continue;
                                    eer += ff[i];
                                }
                            }
                            if (eer !== '') error += ' : ' + eer;
                        }
                        break;
                    case 7:
                        error = languageMessages['today.scan.strategy.end'];
                        break;
                    default:
                        break;
                }

                this.setState({
                    filterError: error,
                    textareaClass: textareaClass
                });
                break;
            case 'finished':
                this.setState({ generatorStatus: false });
                break;
            case 'error':
                this.setState({ generatorStatus: false });
                break;
            case 'get_stock_count_success':
                this.setState({
                    //stockGroups: data.stockGroups,
                    //stockPoolKey: data.stockPoolKey,
                    error: '',
                    filterError: '',
                });
                break;
            case 'get_stock_count_fail':
                this.setState({
                    error: '',
                    filterError: '',
                });
                break;
            default:
                break;
        }
    }

    handleStockGroup(data) {
        this.setState({
            stockGroups: data.stockGroups,
            stockPoolKey: data.stockPoolKey
        }, () => {
            if (this.user.username === undefined) {
                this.setState({ spinLoadding: false }, () => {
                    this.props.dispatch({ type: "action_non_user" });
                });
            } else {
                this.props.dispatch({ type: "action_get_stock_count", payload: { UserId: this.user.username, stockPoolKey: data.stockPoolKey, stockGroups: data.stockGroups } });
            }
        });
    }

    handlefactorGenerator(data) {
        this.setState({
            generatorStatus: data.generatorStatus,
        });
    }

    myFactorFile = e => {
        e.preventDefault();

        history.push("/factorIndex");
    }

    onValueAddedServices = e => {
        e.preventDefault();
        Modal.destroyAll();

        history.push({ pathname: '/member', state: { memberMenuSelectKey: "2" } });
    }

    onCaretPositionChangeHandle = position => {
        this.setState({
            caretPosition: position
        });
    }

    onChangeFilterString = e => {
        this.setState({
            filterString: e.target.value,
            filterError: '',
            textareaClass: 'common-textarea',
            scanFilterStatus: true,
        });
    }

    buildFilterString() {
        var promise = new Promise(resolve => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                var { filterString, caretPosition } = this.state;

                var textareaValue = filterString.slice(0, caretPosition);
                var startPosition = textareaValue.search(/[A-Za-z]*$/);
                var autoMatchData = textareaValue.slice(startPosition, caretPosition);

                var dataSource = [];
                if (autoMatchData !== '') {
                    var filterFunctionsCache = FilterFunction || [];//JSON.parse(sessionStorage.getItem("FilterFunctionsCache") || '[]') || [];
                    var filterFunctions = filterFunctionsCache.filter(function (e) { return e.functionNameCN.toLowerCase().indexOf(autoMatchData.toLowerCase()) !== -1; });
                    if (filterFunctions.length > 0) {
                        for (var k in filterFunctions) {
                            if (filterFunctions[k].m2 === '') continue;
                            dataSource.push({
                                key: filterFunctions[k].funNum,
                                name: filterFunctions[k].functionNameCN,
                                txt: filterFunctions[k].mouseDiscrCN + languageMessages['example'] + filterFunctions[k].m2,
                                char: filterFunctions[k].m2,
                            });
                        }
                    }
                }

                resolve(dataSource);
            }, 200);
        });
        return promise;
    }

    onRowChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys: selectedRowKeys })
    }

    handleFactorScan = () => {
        let { stockPoolKey, filterId, filterName, filterString, stockGroups, filterError, fLastTimes } = this.state;

        if (filterError !== '') {
            //message.error(languageMessages['scan.strategy.in.error']);
            //return;
        }
        
        if (this.user.username === undefined) {
            this.props.dispatch({ type: "action_non_user" });
        } else {
            if (fLastTimes <= 0) {
                var content = <span>{languageMessages['today.scan.strategy.end']}，{languageMessages['please go']}<a onClick={this.onValueAddedServices}>{languageMessages['value-added.services']}</a></span>;
                Modal.info({
                    title: languageMessages['prompt'],
                    content: content
                });
                return;
            }

            if (filterString.length > 8000) {
                message.error(languageMessages['strategy.content.length.too.long']);
                return;
            }

            this.setState({ isShowStockResultPage: false, scanFilterStatus: true, filterError: '' }, () => {
                var requestFilter = {
                    UserId: this.user.username,
                    Type: 1,
                    FilterId: filterId,
                    FilterName: filterName,
                    FilterString: filterString,
                    GroupType: stockPoolKey,
                    SymbolGroupId: stockGroups
                };
                this.props.dispatch({ type: "action_factorfilter_scan", payload: requestFilter });
            });
        }
    }

    renderRealPanel() {
        return (<div style={{ padding: '10px 0' }}>{languageMessages['real-time.market.stock.picker']}</div>);
    }

    renderPastTimePanel() {
        return (<div style={{ padding: '10px 0' }}>
            <p>根据以上选股策略，回溯到过去某一个时间点选股。</p>
            <label>时间点</label>
            <Select defaultValue={this.state.pastTime}
                style={{ width: 120 }}
                onChange={this.handlePastTimeChange}>
                <Option key='09:30'>09:30</Option>
                <Option key='09:45'>09:45</Option>
                <Option key='10:00'>10:00</Option>
                <Option key='10:15'>10:15</Option>
                <Option key='10:30'>10:30</Option>
                <Option key='10:45'>10:45</Option>
                <Option key='11:00'>11:00</Option>
                <Option key='11:15'>11:15</Option>
                <Option key='11:30'>11:30</Option>
                <Option key='13:00'>13:00</Option>
                <Option key='13:15'>13:15</Option>
                <Option key='13:30'>13:30</Option>
                <Option key='13:45'>13:45</Option>
                <Option key='14:00'>14:00</Option>
                <Option key='14:15'>14:15</Option>
                <Option key='14:10'>14:30</Option>
                <Option key='14:45'>14:45</Option>
                <Option key='15:00'>15:00</Option>
            </Select>
        </div>);
    }

    renderStockResult = (stockPoolKey) => {
        const { scanAlert } = this.props;
        /**
        const rowSelection = {
            title: '全选',
            onClick: this.onRowChange,
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onRowChange
        }*/

        /**
        let header = [{ label: "DATETIME", key: "DATETIME" },
            { label: "WEEKDAY", key: "WEEKDAY" },
            { label: "SYMBOL", key: "SYMBOL" }
        ]
         */

        var columns = [{
                title: languageMessages['sequence'],
                dataIndex: 'sequence',
                fixed: 'left',
                width: 80
            }, {
                title: languageMessages['symbol.code'],
                dataIndex: 'symbolCode',
                fixed: 'left',
                width: 120
            }, {
                title: languageMessages['symbol.name'],
                dataIndex: 'symbolName',
                fixed: 'left',
                width: 120
        }];
        
        this.csvData = [];
        var dataSource = [];
        if (scanAlert != null && scanAlert !== undefined) {
            // 标题
            var csvDataTitle = [languageMessages['symbol.code'], languageMessages['symbol.name']];
            var subject = scanAlert.title !== '' ? scanAlert.title.split(",") : [];
            if (subject.length > 0) {
                subject.forEach((v, i) => {
                    columns.push({ title: v, dataIndex: v });
                    csvDataTitle.push(v);
                });
            }
            this.csvData.push(csvDataTitle);

            // 内容
            scanAlert.stockItems.forEach((item, index) => {
                var row = {
                    key: index,
                    sequence: index + 1
                }

                var csvDataItem = [];
                for (var kk in item) {
                    if (kk === 'symbolCode' || kk === 'symbolName') {
                        row[kk] = item[kk];
                        csvDataItem.push(item[kk]);
                    } else {
                        row[kk] = parseFloat(item[kk]).toFixed(4);
                        csvDataItem.push(parseFloat(item[kk]).toFixed(4));
                    }
                }
                dataSource.push(row);
                this.csvData.push(csvDataItem);
            })
        }
        var scrollParams = { x: columns.length * 150 };

        var txt = stockPoolKey === 'GENERAL_INDEX' ? languageMessages['index'] : stockPoolKey === 'FUND' ? languageMessages['fund'] : languageMessages['stock'];

        return (<div className='common-stock-result'>
            <Divider style={{ margin: '16px 0px 10px 0px' }} />
            <PageHeader
                title={(<p style={{ fontWeight: 'normal', fontSize: '14px', color: '#a2a2a2' }}>
                    <FormattedMessage id="factor.scan.result" values={{ num: dataSource.length || 0, name: txt }} />
                </p>)}
                extra={[<span key="1" style={{ fontWeight: 'normal', fontSize: '14px', color: '#a2a2a2' }}>{languageMessages['save.factor.local']}</span>,
                    <CSVLink key="2" data={this.csvData} filename={this.fileName} className="ant-btn ant-btn-sm" target="_blank">
                        <Icon type="download" /><span>{languageMessages['save.stock']}</span>
                    </CSVLink>
                ]} />
            <Table size='small' bordered columns={columns} dataSource={dataSource} scroll={scrollParams}
                pagination={{ pageSize: 20, }} />
        </div>)
    }

    render() {
        var { stockPoolKey, stockGroups, filterString } = this.state;
        var { sectors, stockCount } = this.props;

        var defaultSymbolGroups = {
            stockPoolKey: stockPoolKey,
            stockGroups: stockGroups,
            sectors: sectors,
            stockCount: stockCount
        };

        return (<Content className='main-container total-content'>
            <Spin spinning={this.state.spinLoadding}>
                <div className='common-page-header'>
                    <PageHeader
                        title=""
                        extra={[
                            <Button key="1" size='small' onClick={this.myFactorFile}>{languageMessages['my.factor.file']}</Button>
                        ]} />
                </div>
                <Content className='factor-content'>
                    <div className='common-title' style={{ paddingBottom: '0px' }}>{languageMessages['factor.title.01']}</div>
                    <SymbolGroupPage defaultSymbolGroups={defaultSymbolGroups} stockGroup={this.handleStockGroup.bind(this)} error={this.state.error} />
                    <div className='common-title'>
                        <Divider type="vertical" />
                        {languageMessages['formula.editing']}
                    </div>
                    <ReactTextareaAutocomplete rows={10} className={this.state.textareaClass}
                        value={filterString}
                        onChange={this.onChangeFilterString}
                        loadingComponent={() => <span></span>}
                        minChar={0}
                        trigger={{
                            'w': {
                                dataProvider: token => {
                                    return this.buildFilterString();
                                },
                                component: Item,
                                output: item => ({ text: item.char, caretPosition: "next" })
                            }
                        }}
                        ref={(rta) => { this.rta = rta; }}
                        onCaretPositionChange={this.onCaretPositionChangeHandle} />
                    
                    <div style={{ padding: '10px 0 0 0' }}>
                        <Button size='small' onClick={this.handleFactorScan} loading={this.props.status === 'scan_factorFilter_commit'} disabled={this.props.status === 'scan_factorFilter_commit' || this.state.filterString === '' || this.state.generatorStatus}>
                            {this.props.status === 'scan_factorFilter_commit' ? languageMessages['scanning'] : languageMessages['factorScan']}
                        </Button>
                        <TooltipPage lastTimes={this.state.fLastTimes} filterError={this.state.filterError} />
                    </div>
                    {this.state.isShowStockResultPage && this.renderStockResult(stockPoolKey)}
                </Content>
                
                <div className='common-separate-block' style={{ margin: 0 }}></div>
                <Content className='factor-content'>
                    <div className='common-title'>{languageMessages['factor.title.02']}<span style={{ fontSize: '10px' }}>({languageMessages['generating.factor.file.condition']})</span></div>
                    <FactorGenerator filterString={filterString} scanFilterStatus={this.state.scanFilterStatus} factorGenerator={this.handlefactorGenerator.bind(this)}></FactorGenerator>
                </Content>
            </Spin>
        </Content>)
    }
}

function mapStateToProps(state) {
    return state.factor;
}

const page = connect(mapStateToProps)(FactorPage);
export { page as default }