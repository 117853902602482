import "babel-polyfill";
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { Layout, ConfigProvider, Spin, Icon } from 'antd';
import { IntlProvider, addLocaleData } from 'react-intl';

import './locale/zh-Hans-CN';
//import './locale/en-US';

import { Router, Route, Switch } from 'react-router-dom';

import store from './helpers/store';
import history from './helpers/history';

import { RestrictedPage, HeaderPage, FooterPage } from './pages';

//import HomePage from './pages/home/HomePage';
import LoginPage from './pages/auth/Login';
import RegisterPage from './pages/auth/Register';
import ForgetPage from './pages/auth/Forget';

import HelpPage from './pages/help/HelpPage';
import DownloadPage from './pages/download/DownloadPage';
import VideoPage from './pages/video/VideoPage';

import AboutPage from './pages/about/AboutPage';

import BlankPage from './pages/blank/BlankPage';
import TestPage from './pages/test/TestPage';

import ServicePage from './pages/stripe/ServicePage';
import ChargePage from './pages/stripe/ChargePage';
import SubscriptionPage from './pages/stripe/SubscriptionPage';

import BillingPage from './pages/stripe/BillingPage';
import UpdatePage from './pages/stripe/UpdatePage';


import HawkeyePage from './pages/hawkeye/HawkeyePage';
import HawkeyeListPage from './pages/hawkeye/HawkeyeListPage';

import ConceptPage from './pages/concept/ConceptPage';
import EditStrategyPage from './pages/concept/EditStrategyPage';
import VernacularPage from './pages/vernacular/VernacularPage';
import MyStrategyPage from './pages/vernacular/MyStrategyPage';

import StrategyPage from './pages/strategy/StrategyPage';
import CustomStockPage from './pages/customStock/CustomStockPage';

import OptionPage from './pages/option/OptionPage';

import FactorPage from './pages/factor/FactorPage';
import FactorIndex from './pages/factor/FactorIndex';
import FactorAnalyzer from './pages/factor/FactorAnalyzer';

import "antd/dist/antd.css";
import './index.css';
//import './index-us.css';

window.webType = 'CN'; //US,CN

const appLocale = window.appLocale;
addLocaleData(appLocale.data);

//const ConceptPage = React.lazy(() => import('./pages/concept/ConceptPage'));
//const EditStrategyPage = React.lazy(() => import('./pages/concept/EditStrategyPage'));
//const VernacularPage = React.lazy(() => import('./pages/vernacular/VernacularPage'));
//const MyStrategyPage = React.lazy(() => import('./pages/vernacular/MyStrategyPage'));
//const StrategyPage = React.lazy(() => import('./pages/strategy/StrategyPage'));
//const CustomStockPage = React.lazy(() => import('./pages/customStock/CustomStockPage'));
const MemberPage = React.lazy(() => import('./pages/member/MemberPage'));
const CouponPage = React.lazy(() => import('./pages/member/CouponPage'));

//const OptionPage = React.lazy(() => import('./pages/option/OptionPage'));

//const FactorPage = React.lazy(() => import('./pages/factor/FactorPage'));
//const FactorIndex = React.lazy(() => import('./pages/factor/FactorIndex'));
//const FactorAnalyzer = React.lazy(() => import('./pages/factor/FactorAnalyzer'));

//const HawkeyePage = React.lazy(() => import('./pages/hawkeye/HawkeyePage'));
//const HawkeyeListPage = React.lazy(() => import('./pages/hawkeye/HawkeyeListPage'));

Spin.setDefaultIndicator(<Icon type="loading" className='common-loading-icon' spin />);
const Loading = () => <div className='common-root-loading-container'><div className='common-root-loading'><Spin></Spin></div></div>
const rootElement = document.getElementById('root');
ReactDOM.render(
    <ConfigProvider locale={appLocale.antd}>
        <IntlProvider locale={appLocale.locale} messages={appLocale.messages}>
            <Provider store={store}>
                <Router history={history}>
                    <Layout>
                        <HeaderPage />
                        <Switch>
                            <Route exact path="/" component={() => <Suspense fallback={<Loading />}><VernacularPage /></Suspense>} />
                            <Route path="/login" component={() => <LoginPage />} />
                            <Route path="/register" component={() => <RegisterPage />} />
                            <Route path="/forget" component={() => <ForgetPage />} />

                            <Route path="/help" component={() => <HelpPage />} />
                            <Route path="/download" component={() => <DownloadPage />} />
                            <Route path="/video" component={() => <VideoPage />} />

                            <Route path="/concept" component={() => <ConceptPage />} />
                            <Route path="/editStrategy" component={() => <EditStrategyPage />} />

                            <Route path="/vernacular" component={() => <VernacularPage />} />
                            <Route path="/myStrategy" component={() => <MyStrategyPage />} />

                            <Route path="/strategy" component={() => <StrategyPage />} />
                            <Route path="/customStock" component={() => <CustomStockPage />} />

                            <Route path="/option" component={() => <OptionPage />} />
                            <Route path="/factor" component={() => <FactorPage />} />
                            <Route path="/factorIndex" component={() => <FactorIndex />} />
                            <Route path="/factorAnalyzer" component={() => <FactorAnalyzer />} />

                            <Route path="/hawkeye" component={() => <HawkeyePage />} />
                            <Route path="/hawkeyeList" component={() => <HawkeyeListPage />} />

                            <Route path="/member" component={() => <RestrictedPage><Suspense fallback={<Loading />}><MemberPage /></Suspense></RestrictedPage>} />
                            <Route path="/coupon" component={() => <RestrictedPage><Suspense fallback={<Loading />}><CouponPage /></Suspense></RestrictedPage>} />

                            <Route path="/about" component={() => <AboutPage />} />

                            <Route path="/blank" component={() => <BlankPage />} />

                            <Route path="/test" component={() => <TestPage />} />
                            
                            <Route path="/service" component={() => <ServicePage />} />
                            <Route path="/charge" component={() => <ChargePage />} />
                            <Route path="/sc" component={() => <SubscriptionPage />} />
                            <Route path="/b" component={() => <BillingPage />} />
                            <Route path="/su" component={() => <UpdatePage />} />

                        </Switch>
                        <FooterPage />
                    </Layout>
                </Router>
            </Provider>
        </IntlProvider>
    </ConfigProvider>,
    rootElement);
                        
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
                
//serviceWorker();
