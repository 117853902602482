import React, { Component } from 'react';
import history from '../../helpers/history';
import { Form, Input, Select, Button, Modal, DatePicker } from 'antd';
import moment from 'moment';

import { FormattedMessage, defineMessages } from 'react-intl';

import TooltipPage from '../../pannels/account/TooltipPage';

const { Option } = Select;

const messages = defineMessages({
    optionSymbol: {
        id: 'intl.optionSymbol',
        defaultMessage: '期权标的',
    },
    scanStartEndDate: {
        id: 'intl.scanStartEndDate',
        defaultMessage: '扫描起止日',
    },
    today: {
        id: 'intl.today',
        defaultMessage: '今天',
    },
    to: {
        id: 'intl.to',
        defaultMessage: '至',
    },
    direction: {
        id: 'intl.direction',
        defaultMessage: '涨跌设置',
    },
    strategyScan: {
        id: 'intl.strategyScan',
        defaultMessage: '策略扫描',
    },
    strategyScanning: {
        id: 'intl.strategyScanning',
        defaultMessage: '策略扫描中',
    },
});

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const dateFormat = 'YYYYMMDD';

function CurrentDate() {
    var now = new Date();

    var year = now.getFullYear();       //年
    var month = now.getMonth() + 1;     //月
    var day = now.getDate();            //日

    var clock = year + "";

    if (month < 10)
        clock += "0";

    clock += month + "";

    if (day < 10)
        clock += "0";

    clock += day;

    return clock;
}

class PriceInput extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            number: props.number,
        };
    }

    /**
    static getDerivedStateFromProps(nextProps) {
        // Should be a controlled component.
        if ('value' in nextProps) {
            return {
                ...(nextProps.value || {}),
            };
        }
        
        return null;
    }
    */

    componentWillReceiveProps(props) {
        this.setState({ number: props.number });
    }

    handleNumberChange = e => {
        //const number = parseInt(e.target.value || 0, 10);
        //const number = parseFloat(Number(e.target.value || 0).toFixed(2));
        //console.log('number', number);
        ////if (Number.isNaN(number)) {
         //   return;
        //}
        /** 
        const number = e.target.value;
        var re = /^\d+(?=\.{0,1}\d+$|$)/;
        if (number !== "") {
            if (!re.test(number)) {
                return;
            }
        }
        */

        let direction = this.props.direction;
        let intLen = 12;
        let decimalLen = 2;
        if (direction === '0' || direction === '1') {
            decimalLen = 3;
        }

        var number = e.target.value;
        if (number.indexOf('.') === 0) {
            number = number.replace(/[^\d]/g, "");
        }
        number = number.replace(/[^\d.]/g, "");
        number = number.replace(/\.{2,3}/g, ".");
        number = number.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
        
        if (number.indexOf('.') < 0) {
            if (number.length > intLen) number = number.slice(0, intLen);

            if (number !== '') number = parseInt(number);
        } else {
            var tmpNumber = number.split('.');
            var tmpNum00 = tmpNumber[0];
            if (tmpNum00.length > intLen) tmpNum00 = tmpNum00.slice(0, intLen);

            if (tmpNum00 !== '') tmpNum00 = parseInt(tmpNum00);
            
            var tmpNum01 = tmpNumber[1];
            if (tmpNum01.length > decimalLen) tmpNum01 = tmpNum01.slice(0, decimalLen);
            number = tmpNum00 + '.' + tmpNum01;
        }

        if (!('value' in this.props)) {
            this.setState({ number });
        }
        this.triggerChange({ number });
    };

    onBlurNumberChange = e => {
        e.preventDefault();

        let direction = this.props.direction;

        var number = e.target.value;
        if (number.length > 0) {
            if (number.indexOf('.') < 0) {
                if (direction === '0' || direction === '1') {
                    number = number + '.000';
                } else {
                    number = number + '.00';
                }
            } else {
                var tmpValue = number.split('.');
                var len = tmpValue[1].length;
                if (direction === '0' || direction === '1') {
                    if (len === 0) {
                        number = number + '000';
                    } else if (len === 1) {
                        number = number + '00';
                    } else if (len === 2) {
                        number = number + '0';
                    }
                } else {
                    if (len === 0) {
                        number = number + '00';
                    } else if (len === 1) {
                        number = number + '0';
                    }
                }
            }
        }
        if (!('value' in this.props)) {
            this.setState({ number });
        }
        this.triggerChange({ number });
    }

    triggerChange = changedValue => {
        const { onChange } = this.props;
        if (onChange) {
            onChange(changedValue);
        }
    };

    render() {
        return (
            <Input type="text" size='small' value={this.state.number} style={{ width: '100px' }} onChange={this.handleNumberChange} onBlur={this.onBlurNumberChange} placeholder={this.props.placeholder} />
        );
    }
}

var languageMessages = window.appLocale.messages;

class OptionParams extends Component {
    constructor(props) {
        super(props);

        this.underlyingList = [];

        this.state = {
            underlying: 'SH510300',
            price: '0',
            endDate: moment().add(30, 'days').format(dateFormat),

            direction: '0',
            predictedValue: '',

            endDateStatus: '',
            predictedValueStatus: '',

            placeholder: languageMessages['priceNotEmpty'],
            isShowPercent: false,
        };
    }

    componentDidMount() {
        this.props.form.validateFields();
    }

    componentWillReceiveProps(props) {
        if (props.underlyingList !== undefined && props.underlyingList !== null) {
            if (props.underlyingList.length > 0) {
                //var underlying = '';
                //var price = '0';
                // var endDate = CurrentDate();
                this.underlyingList = [];
                for (var kk in props.underlyingList) {
                    //if (underlying === '') {
                        //underlying = props.underlyingList[kk];
                        //price = props.underlyingList[kk].price;
                        //endDate = props.underlyingList[kk].endDate;
                    //}
                    this.underlyingList.push(<Option key={props.underlyingList[kk]}>{props.underlyingList[kk]}</Option>);
                }

                if (props.price !== '0') {
                    //price = props.price;
                }

                this.setState({
                    //underlying: underlying,
                    //price: price,
                    //endDate: endDate
                });
            }
        }
    }

    onValueAddedServices = e => {
        e.preventDefault();
        Modal.destroyAll();
        
        history.push({ pathname: '/member', state: { memberMenuSelectKey: "2" } });
    }

    handleSubmit = e => {
        e.preventDefault();
        let user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        if (user.username === undefined) {
            this.props.dispatch({ type: "action_non_user" });
        } else {
            if (this.props.lastTimes <= 0) {
                var content = <span>{languageMessages['option.scans.have.run.out']}，{languageMessages['please go']}<a onClick={this.onValueAddedServices}>{languageMessages['value-added.services']}</a></span>;
                Modal.info({
                    title: languageMessages['prompt'],
                    content: content
                });
                return;
            }

            this.props.form.validateFields((err, values) => {
                if (!err) {
                    let { endDate, predictedValue } = this.state;
                    if (endDate === '') {
                        let endDateStatus = 'error';
                        this.setState({ endDateStatus });
                        return;
                    }
                    
                    if (predictedValue === '') {
                        let predictedValueStatus = 'error';
                        this.setState({ predictedValueStatus });
                        return;
                    }

                    //Bullish,Bearish 0,1,2,3
                    //Percent,Price
                    let predictedValueUnit = 'Price';
                    let direction = 'Bullish';
                    switch (values.Direction) {
                        case '0':
                            direction = 'Bullish';
                            predictedValueUnit = 'Price';
                            break;
                        case '1':
                            direction = 'Bearish';
                            predictedValueUnit = 'Price';
                            break;
                        case '2':
                            direction = 'Bullish';
                            predictedValueUnit = 'Percent';
                            break;
                        case '3':
                            direction = 'Bearish';
                            predictedValueUnit = 'Percent';
                            break;
                        default:
                            direction = 'Bullish';
                            predictedValueUnit = 'Price';
                            break;
                    }
                    
                    var scanRequest = {
                        UserName: user.username,
                        Underlying: values.Underlying,
                        EndDate: endDate,
                        Direction: direction,
                        PredictedValue: predictedValue,
                        PredictedValueUnit: predictedValueUnit
                    };
                    this.props.dispatch({ type: "action_option_scan", payload: scanRequest });
                }
            });
        }
    };

    handleUnderlyingChange = value => {
        this.setState({ underlying: value });
    }

    handleEndDate = (dates, dateStrings) => {
        var endDateStatus = '';
        if (dateStrings === '') endDateStatus = 'error';
        this.setState({ endDate: dateStrings, endDateStatus });
    }

    todayDisabledDate = current => {
        return current && (current < moment().startOf('day'));
    }

    handleDirectionChange = value => {
        let predictedValue = '';
        let isShowPercent = false;
        let placeholder = languageMessages['priceNotEmpty'];
        if (value === '2' || value === '3') {
            predictedValue = '5.00';
            isShowPercent = true;
            placeholder = languageMessages['PleaseEnterQuoteChange'];
        }
        
        this.setState({ direction: value, predictedValue, isShowPercent, placeholder });
    }
    
    handlePredictedValueChange = e => {
        var predictedValue = e.number;
        var predictedValueStatus = '';
        if (predictedValue === '') predictedValueStatus = 'error';
        this.setState({ predictedValue, predictedValueStatus });
    }

    render() {
        const { getFieldDecorator, getFieldsError } = this.props.form;

        return (<div>
            <Form layout="inline">
                <Form.Item label={(<FormattedMessage {...messages.optionSymbol} />)}>
                    <div>
                    {getFieldDecorator('Underlying', {
                        initialValue: this.state.underlying,
                    })(
                    <Select size='small' style={{ width: '120px' }}
                        placeholder={languageMessages['please.select.option.target']}
                        onChange={this.handleUnderlyingChange}>
                        {this.underlyingList.length > 0 && this.underlyingList}
                        </Select>,
                        )}
                    </div>
                    <div style={{ position: 'relative', top: '-15px', display: 'none' }}>{languageMessages['latest.price']}：<span style={{ color: 'red' }}>{this.state.price}</span></div>
                </Form.Item>
                <Form.Item label={(<FormattedMessage {...messages.scanStartEndDate} />)} validateStatus={this.state.endDateStatus}>
                    <span><FormattedMessage {...messages.today} /></span>
                    <span style={{ padding: '0 10px' }}><FormattedMessage {...messages.to} /></span>
                    {
                        getFieldDecorator('EndDate', {
                            initialValue: moment(this.state.endDate, dateFormat),
                            rules: [{ type: 'object', message: languageMessages['please.enter.scan.start.and.end.date'] }],
                        })(<DatePicker size='small' style={{ width: '100px' }}
                            allowClear={false}
                            format={dateFormat}
                            disabledDate={this.todayDisabledDate}
                            onChange={this.handleEndDate} />)
                    }
                </Form.Item>
                <Form.Item label={(<FormattedMessage {...messages.direction} />)}>
                    {getFieldDecorator('Direction', {
                        initialValue: '0',
                        rules: [{ message: languageMessages['please.select.ups.and.downs.setting'] }],
                    })(
                    <Select size='small' style={{ width: '80px' }} onChange={this.handleDirectionChange}>
                        <Option value="0">{languageMessages['riseTo']}</Option>
                        <Option value="1">{languageMessages['fallTo']}</Option>
                        <Option value="2">{languageMessages['rise']}</Option>
                        <Option value="3">{languageMessages['fall']}</Option>
                    </Select>,
                    )}
                </Form.Item>

                <Form.Item validateStatus={this.state.predictedValueStatus}>
                    {getFieldDecorator('PredictedValue', {
                    })(
                        <PriceInput direction={this.state.direction} number={this.state.predictedValue} onChange={this.handlePredictedValueChange} placeholder={this.state.placeholder} />
                    )}
                    <span style={{ padding: '0 5px', display: this.state.isShowPercent ? '' : 'none' }}>%</span>
                </Form.Item>
            </Form>
            <div style={{ padding: '0', height: '40px' }}>
                <Button size='small' loading={this.props.strategyScan} onClick={this.handleSubmit} disabled={this.underlyingList.length > 0 ? (this.props.strategyScan ? this.props.strategyScan : (hasErrors(getFieldsError()))):true}>
                    {this.props.strategyScan ? (<FormattedMessage {...messages.strategyScanning} />) : (<FormattedMessage {...messages.strategyScan} />)}
                </Button>
                <TooltipPage lastTimes={this.props.lastTimes} filterError={this.props.optionError} />
            </div>
        </div>);
    }
}

const OptionParamsForm = Form.create({ name: 'Option_Params' })(OptionParams);
export { OptionParamsForm as default }