import { put, call, takeLatest } from 'redux-saga/effects'
import { get, post } from '../../helpers/server'

function* getSymbolItems(action) {
    try {
        const resp = yield call(get, "/customStock/GetSymbolItems?u=" + action.payload.UserId + "&kw=" + action.payload.keywords);
        if (resp && resp.status === 1) {
            yield put({ type: 'get_symbol_items_success', symbolItems: resp.st });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'get_symbol_items_fail', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "get_symbol_items_fail", status: 0 });
    }
}

function* getCustomStockTick(action) {
    try {
        const resp = yield call(get, "/customStock/tick?u=" + action.payload.UserId + "&s=" + action.payload.SymbolList);
        if (resp && resp.status === 1) {
            yield put({ type: 'customStockTick_success', symbolList: resp.st });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'customStockTick_fail', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "customStockTick_fail", status: 0 });
    }
}

function* addCustomStock(action) {
    try {
        yield put({ type: "customStock_commit" });
        const resp = yield call(post, "/customStock/Add", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'add_customStock_success', customGroup: resp.customGroup, symbolList: resp.symbolList });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'add_customStock_fail', status: resp.status, fail: resp.fail });
        }
    } catch (e) {
        yield put({ type: 'add_customStock_fail', status: 0 });
    }
}

function* updateCustomStock(action) {
    try {
        yield put({ type: "customStock_commit" });
        const resp = yield call(post, "/customStock/update", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'update_customStock_success', customGroup: resp.customGroup, symbolList: resp.symbolList });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'update_customStock_fail', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "update_customStock_fail", status: 0 });
    }
}

function* removeCustomStock(action) {
    try {
        const resp = yield call(post, "/customStock/remove", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'remove_customStock_success', customStock: resp.items, symbolList: resp.symbolList });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'remove_customStock_fail', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "remove_customStock_fail", status: 0 });
    }
}

function* renameCustomStock(action) {
    try {
        yield put({ type: "customStock_commit" });
        const resp = yield call(post, "/customStock/rename", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'update_customStock_success', customGroup: resp.customGroup, symbolList: resp.symbolList });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'update_customStock_fail', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "update_customStock_fail", status: 0 });
    }
}

function* customStock() {
    yield takeLatest('action_get_symbol_items', getSymbolItems);
    yield takeLatest('action_get_custom_stock_tick', getCustomStockTick);

    yield takeLatest('action_add_custom_stock', addCustomStock);
    yield takeLatest('action_update_custom_stock', updateCustomStock);
    yield takeLatest('action_remove_custom_stock', removeCustomStock);

    yield takeLatest('action_rename_custom_stock', renameCustomStock);
}

export default customStock;