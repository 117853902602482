import React, { Component } from 'react';
import { Layout, Divider } from 'antd';

const { Content } = Layout;

var languageMessages = window.appLocale.messages;

class AboutPage extends Component {

    render() {
        return (<Content className='main-container'>
            <div className='about-container'></div>
            <div className='cont-content about'>
                <div className='common-title'><Divider type="vertical" />{languageMessages['about']}</div>
                <p>{languageMessages['about.info.01']}</p>
                <p>{languageMessages['about.info.02']}</p>
                <p>{languageMessages['about.info.03']}</p>
                <p>{window.webType === 'US' ? '' : languageMessages['about.info.04']}</p>
                
                <div className='common-title'><Divider type="vertical" />{languageMessages['contact']}<a name='contact' /></div>
                <div>{languageMessages['contact.info.01']}</div>
                <div>{languageMessages['contact.info.02']}</div>
                <div>{languageMessages['contact.info.03']}</div>
                <div>{languageMessages['contact.info.04']}</div>
            </div>
        </Content>)
    }
}
            
export { AboutPage as default }