import React, { Component } from "react";
import SideMenu from "./components/sideMenu/sideMenu";
import help_cn from './components/help_cn';
import help_en from './components/help_en';

import '../main.css';

class HelpPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: window.webType === 'US' ? help_en : help_cn,
            current: 0,
            currentId: 0
        };
    }

    currentState(current) {
        this.setState({
            current
        })
    }

    renderChildNode(inner, descs) {
        var txt = '';
        if (inner.isHideTitle === undefined) {
            if (descs.tit === undefined) {
                if (inner.url !== undefined && inner.url !== '') {
                    txt = <a href={inner.url} target="_blank">{descs}</a>
                } else {
                    txt = `${descs}`;
                }
            } else {
                txt = `${descs.tit}`;
            }
        } else {
            txt = descs.tit || descs;
        }
        return txt;
    }

    renderContent() {
        let { data, current } = this.state;

        return Object.keys(data).map((item, i) => (
            <div className="tree-title" key={item} data-cur-title={data[item].title}>
                {data[item].title}
                {data[item].children.map(outer => (
                    <div className="tree-outer" key={outer.id} data-cur-title={outer.title}>
                        {outer.title}
                        <div className="tree-inner">
                            {outer.children.map((inner, titIndex) => {
                                return (
                                    <div
                                        key={inner.id}
                                        id={`inner-1-${inner.id}`}
                                        className="tree-inner-tit"

                                    >
                                        {
                                            inner.title ?
                                                inner.isHideTitle ?
                                                    <div id={`inner-1-${inner.title}`} data-cur-title={inner.title} className={`tree-inner-dt ${inner.id == current ? "active" : ""}`}>{inner.title}</div> :
                                                    <div id={`inner-1-${inner.title}`} data-cur-title={inner.title} className={`tree-inner-dt ${inner.id == current ? "active" : ""}`}>
                                                        <span style={{ display: 'inline-block', visibility: 'hidden', width: 5 }}> </span>{inner.title}
                                                    </div> :
                                                null
                                        }
                                        {Array.isArray(inner.children) ? (
                                            <div className="tree-inner-di">
                                                {inner.children.map((descs, i) => (
                                                    descs.point ? <div key={i} id={`inner-1-${descs.tit || descs}`} data-cur-title={descs.tit || descs} className={`tree-inner-dit ${descs == current ? "active" : ""}`}>&bull; {descs.tit}</div> :
                                                        <div key={i} id={`inner-1-${descs.tit || descs}`} data-cur-title={descs.tit || descs} className={`tree-inner-dit ${descs == current ? "active" : ""}`}>
                                                            {
                                                            this.renderChildNode(inner,descs)
                                                                //inner.isHideTitle ? (descs.tit || descs) : `${descs.tit}` || inner.url ? <a href={inner.url}>{descs}</a> : descs
                                                            }
                                                        </div>
                                                ))}
                                                {
                                                    inner.img ?
                                                        Array.isArray(inner.img) ?
                                                            (inner.img.map((imgs, imgsIndex) => <img src={imgs} key={imgsIndex} />)) :
                                                            <img src={inner.img} />  : null
                                                }
                                            </div>
                                        ) : (
                                                <div style={{ color: `${inner.color ? inner.color : ''}` }}>{inner.desc}</div>
                                            )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ))}
            </div>
        ));
    }

    contentScroll(id) {
        let ele = document.getElementsByClassName("help-content")[0];
        let cons = ele.getElementsByTagName('*')

        if (id) {

            for (let i = 0; i < cons.length; i++) {
                let curId = cons[i].id
                if (curId.indexOf(id) !== -1) {
                    ele.scrollTop = document.getElementById(curId) ? document.getElementById(curId).offsetTop : 0
                }
            }

            // ele.scrollTop = document.getElementById(`inner-1-${id}`) ? document.getElementById(`inner-1-${id}`).offsetTop : 0
        }

    }

    setCurrentId(currentId) {
        this.setState({
            currentId
        })
    }

    render() {
        return (
            <div className="help-container">
                <SideMenu data={this.state.data} scrollContent={this.contentScroll.bind(this)} currentState={this.currentState.bind(this)} setCurrentId={this.setCurrentId.bind(this)} />
                <div className="help-content">
                    {this.renderContent()}
                </div>
            </div>
        );
    }
}

export { HelpPage as default };