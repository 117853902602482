import { call, takeLatest, put } from 'redux-saga/effects';
import { post, get } from '../../helpers/server';

function* getStockCount(action) {
    try {
        const resp = yield call(get, "/filter/getStockCount?u=" + action.payload.UserId + "&spk=" + action.payload.stockPoolKey + "&sg=" + action.payload.stockGroups);
        if (resp.status === 1) {
            yield put({ type: "get_stock_count_success", stockCount: resp.sc });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "get_stock_count_fail", status: resp.status });
        }
    } catch (e) {
        yield put({ type: "get_stock_count_fail", status: 0 });
    }
}
function* getConceptSectors(action) {
    try {
        const resp = yield call(get, "/filter/concept?u=" + action.payload.UserId + "&spk=" + action.payload.stockPoolKey + "&sg=" + action.payload.stockGroups);
        if (resp.status === 1) {
            yield put({ type: "get_concept_success", sectors: resp.s, stockCount: resp.sc, concepts: resp.c, conceptExplanations: resp.ce, permission: resp.p });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "get_concept_fail", status: resp.status });
        }
    } catch (e) {
        yield put({ type: "get_concept_fail", status: 0 });
    }
}

function* scenStrategy(action) {
    try {
        yield put({ type: "scan_strategy_commit" });
        const resp = yield call(post, "/filter/ScanFilter", action.payload);
        if (resp.status === 1) {
            yield put({ type: "get_scan_success", scanAlert: resp.scanAlert, filter: resp.filter, permission: resp.permission, lastTimes: resp.lastTimes });
        } else {
            if (resp.status === 4) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "get_scan_fail", status: resp.status, error: resp.error });
        }
    } catch (e) {
        yield put({ type: "get_scan_fail", status: 0 });
    }
}

function* saveStrategy(action) {
    try {
        yield put({ type: "save_strategy_commit" });
        const resp = yield call(post, "/filter/SaveFilter", action.payload);
        if (resp.status === 1) {
            if (resp.status === 4) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "get_save_success", editStrategy: resp.editStrategy });
        } else {
            yield put({ type: "get_save_fail", status: resp.status, error: resp.error });
        }
    } catch (e) {
        yield put({ type: "get_save_fail", status: 0 });
    }
}

function* addResultCustomStock(action) {
    try {
        yield put({ type: "customStock_commit" });
        const resp = yield call(post, "/customStock/Add", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'update_customStock_success', customGroup: resp.customGroup, symbolList: resp.symbolList });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'update_customStock_fail', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "update_customStock_fail", status: 0 });
    }
}

function* updateResultCustomStock(action) {
    try {
        yield put({ type: "customStock_commit" });
        const resp = yield call(post, "/customStock/update", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'update_customStock_success', customGroup: resp.customGroup, symbolList: resp.symbolList });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'update_customStock_fail', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "update_customStock_fail", status: 0 });
    }
}

function* Effect() {
    yield takeLatest('action_get_stock_count', getStockCount);
    
    yield takeLatest('action_get_concept_sectors', getConceptSectors);

    yield takeLatest('action_scen_strategy', scenStrategy);
    yield takeLatest('action_save_strategy', saveStrategy);

    yield takeLatest('action_add_result_custom_stock', addResultCustomStock);
    yield takeLatest('action_update_result_custom_stock', updateResultCustomStock);
}

export default Effect;