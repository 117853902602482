import React, { Component } from 'react';
import AnyChart from 'anychart-react';
import anychart from 'anychart';
import { Layout, Select, AutoComplete, LocaleProvider, DatePicker, Row, Col, Radio, Icon } from 'antd';
import moment, { locales } from 'moment';

import emitter from '../../Evt.js';

import zh_CN from 'antd/lib/locale-provider/zh_CN';

import 'moment/locale/zh-cn';


const { Content } = Layout;

var languageMessages = window.appLocale.messages;

Date.prototype.format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1,                 //月份 
        "d+": this.getDate(),                    //日 
        "h+": this.getHours(),                   //小时 
        "m+": this.getMinutes(),                 //分 
        "s+": this.getSeconds(),                 //秒 
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
        "S": this.getMilliseconds()             //毫秒 
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }
    return fmt;
}

//生成数据
function generate5MinsOHLCData(startDate, rowsCount, openValue, spread, opt_startVolume) {
    function rand(opt_spreadMult, opt_spreadAdd) {
        var spreadMult = (isNaN(opt_spreadMult) ? 1 : opt_spreadMult) * spread;
        var spreadAdd = isNaN(opt_spreadAdd) ? -0.5 : +opt_spreadAdd;
        return Math.round(((Math.random() * spreadMult) + spreadMult * spreadAdd) * 100) * 0.01;
    }

    function randVolume(opt_prev) {
        if (!opt_prev)
            return Math.round(Math.random() * 1e3) + 1e3;
        var diff = Math.round(Math.random() * 2e2) - 4e2;
        return Math.abs(opt_prev + diff);
    }

    function nextDate(date) {
        date.setTime(date.getTime() + 1000 * 60 * 5);
        if (date.getUTCHours() > 18) {
            date.setUTCDate(date.getUTCDate() + 1);
            date.setUTCHours(9);
        }
        if (date.getUTCDay() == 6) {
            date.setUTCDate(date.getUTCDate() + 2);
        } else if (date.getUTCDay() == 0) {
            date.setUTCDate(date.getUTCDate() + 1);
        }
    }

    startDate = startDate instanceof Date ? startDate : new Date(startDate);
    var current = new Date(startDate.getTime());
    var index = -1;

    var data = [];
    var open = openValue, close, high, low;
    var volume = randVolume(opt_startVolume);
    while (++index < rowsCount) {
        var diff = rand();
        close = open + diff;
        if (close < 0) close = open - diff;
        high = Math.max(open, close) + rand(0.2, 0);
        low = Math.max(Math.min(open, close) - rand(0.2, 0), 0);
        volume = randVolume(volume);
        data.push([
            current.getTime(),
            open,
            high,
            low,
            close,
            volume
        ]);
        open = close + rand(0.0001);
        nextDate(current);
    }
    return {
        data: data,
        lastValue: open,
        lastDate: current,
        lastVolume: volume
    };
}
/**
var rawData = {
    data: [],
    lastValue: 100,
    lastDate: new Date(new Date().getUTCFullYear() - 4, 0),
    lastVolume: 100
};
*/

var rawData = [];

var streamingTimerId;

var chart;
var dataTable;
var plot;

var mainMapping;

var chartHeight = 500;

var streamPointsCount = 500;
var streamingInterval = 1000;

var index = 0;

var requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;

var datetime = '2019-02-26';

// 周期
var timeSharing = 1440;


// 开始时间，结束时间，最小，最大，宽，高，位置
var chartInfo = { sDate: 0, eDate: 0, min: 0, max: 0, w: 0, h: 0, nPos: 0 };

//chart系统设置
var locale = "zh-cn";
var timeZoneOffset = new Date().getTimezoneOffset();
var dateFormat = 'yyyy-MM-dd HH:mm:ss';

anychart.format.outputLocale(locale);
anychart.format.outputTimezone(timeZoneOffset);
anychart.format.outputDateTimeFormat(dateFormat);

class HawkeyeKLine extends Component {
    constructor(props) {
        super(props);

        this.state = {
            historyDataItems: [],
            profitRange: [],

            translateX: 208,
            translateY: 60,

            estimatedDaysDisplay: 'block',//block
            targetPriceDisplay: 'block',//block

        }

        this.moving = false;
        this.lastX = null;
        this.lastY = null;
        window.onmouseup = e => this.onMouseUp(e);
        window.onmousemove = e => this.onMouseMove(e);
    }

    componentDidMount() {
        emitter.addListener('callTickData', (hd, pr) => {
            var { historyDataItems } = this.state;
            historyDataItems = historyDataItems.concat(hd);
            this.setState({ historyDataItems, profitRange: pr }, () => {
                this.renderChart();
            });
        });
    }

    componentDidUpdate() {
    }

    componentWillUpdate() {
    }
    
    componentWillReceiveProps(props) {
        //console.log('componentWillReceiveProps');
    }

    onMouseDown(e) {
        //e.stopPropagation();
        //this.moving = true;
    }

    onMouseUp() {
        this.moving = false;
        this.lastX = null;
        this.lastY = null;
    }

    onMouseMove(e) {
        this.moving && this.onMove(e);
    }

    onMove(e) {
        if (this.lastX && this.lastY) {
            let dx = e.clientX - this.lastX;
            let dy = e.clientY - this.lastY;

            let vy = this.state.translateY + dy;
            if (vy < 0) vy = 0;
            if (vy > chartInfo.h) vy = chartInfo.h;

            let hx = this.state.translateX + dx;
            if (hx < 56) hx = 56;
            if (hx > chartInfo.w) hx = chartInfo.w;

            let { targetPriceDisplay, estimatedDaysDisplay } = this.state;
            if (targetPriceDisplay === 'block' && estimatedDaysDisplay === 'block') {
                this.setState({ translateX: hx, translateY: vy });
            } else if (targetPriceDisplay === 'block') {
                this.setState({ translateY: vy });
            } else if (estimatedDaysDisplay === 'block') {
                this.setState({ translateX: hx });
            }
        }
        this.lastX = e.clientX;
        this.lastY = e.clientY;
    }

    onTargetPriceLine = () => {
        let { targetPriceDisplay } = this.state;

        targetPriceDisplay =  targetPriceDisplay === 'block' ? 'none' : 'block';
        this.setState({ targetPriceDisplay });
    }

    onEstimatedTargetPriceDays = () => {
        let { estimatedDaysDisplay } = this.state;

        estimatedDaysDisplay = estimatedDaysDisplay === 'block' ? 'none' : 'block';
        this.setState({ estimatedDaysDisplay });
    }

    onTimeSharing = (e) => {
        var nodes = document.querySelectorAll('.stock-info-btn');
        for (var i = 0; i < nodes.length; i++) {
            if (nodes[i].classList.contains("active")) {
                nodes[i].classList.remove("active");
            }
        }

        e.target.classList.add("active");

        timeSharing = e.currentTarget.getAttribute('data-value');
        //console.log('timeSharing', timeSharing);
    }

    startStreaming() {
        streamingTimerId = setInterval(function () {
            if (requestAnimationFrame) {
                requestAnimationFrame(streamData);
            } else {
                streamData();
            }

            function streamData() {
                // 请求数据
                rawData = generate5MinsOHLCData(rawData.lastDate, streamPointsCount, rawData.lastValue, 10, rawData.lastVolume);

                //dataTable.addData(rawData.data, true);
                dataTable.addData(rawData.data);
                rawData.data = null;

            }

        }, streamingInterval);
    }

    renderChart() {
        function getDateTime(dt) {
            var df = dateFormat;
            switch (timeSharing) {
                case 0:
                    df = 'HH:mm';
                    break;
                case 5:
                    df = 'MM/dd HH:mm';
                    break;
                case 60:
                    df = 'MM/dd HH:mm';
                    break;
                case 1440:
                    df = 'yyyy/MM/dd';
                    break;
                case 10080:
                    df = 'yyyy/MM/dd';
                    break;
                default:
                    break;
            }
            return anychart.format.dateTime(dt, df, timeZoneOffset, locale);
        }

        var { historyDataItems, profitRange } = this.state;

        if (historyDataItems.length > 0) {
            dataTable = anychart.data.table("BarStart");
            dataTable.addData(historyDataItems);
            mainMapping = dataTable.mapAs({ open: "Open", high: "High", low: "Low", close: "Close", volume: "Volume" });

            //mainMapping = dataTable.mapAs();
            // first 3 fields for OHLC and Candlestick
            //mainMapping.addField('Open', 1, 'first');
            //mainMapping.addField('High', 2, 'max');
            //mainMapping.addField('Low', 3, 'min');
            //mainMapping.addField('Close', 4, 'last');
            // this one for line and spline
            //mainMapping.addField('value', 4, 'last');

            //columnMapping = dataTable.mapAs();
            //columnMapping.addField('value', 5, 'sum');

            //scrollerMapping = dataTable.mapAs();
            //scrollerMapping.addField('value', 4, 'last');

            var chartContainer = document.getElementById('chartContainer');
            var childs = chartContainer.childNodes;
            for (var i = childs.length - 1; i >= 0; i--) {
                chartContainer.removeChild(childs[i]);
            }

            chart = anychart.stock();

            //******************** 设置不显示区域组件  *********************/
            chart.scroller().enabled(false);

            /**
            chart.scroller().xAxis().labels().format(function () {
                return anychart.format.dateTime(this.tickValue, "HH:mm");
            });
            chart.crosshair(false);
            */
            var _this = this;
            chart.crosshair().displayMode("float");
            chart.crosshair().xLabel().format(function () {
                console.log('x this', this);

                /**
                 * let { targetPriceDisplay, estimatedDaysDisplay } = this.state;
                if (targetPriceDisplay === 'block' && estimatedDaysDisplay === 'block') {
                    this.setState({ translateX: hx, translateY: vy });
                } else if (targetPriceDisplay === 'block') {
                    this.setState({ translateY: vy });
                } else if (estimatedDaysDisplay === 'block') {
                    this.setState({ translateX: hx });
                }
                 */
                return getDateTime(this.tickValue);
            });
            chart.crosshair().yLabel().format(function () {
                
                var vy = (this.max - this.value) / ((this.max - this.min) / chartInfo.h);

                let { targetPriceDisplay } = _this.state;
                if (targetPriceDisplay === 'block') {
                    _this.setState({ translateY: vy });
                }
                
                return this.value;
            });

            chart.tooltip(false);
            chart.tooltip().titleFormat(function () {
                var dt = isNaN(this.points[0].x) ? isNaN(this.points[1].x) ? '' : this.points[1].x : this.points[0].x;
                if (dt === '') return '';
                console.log('this.points',this.points);
                return getDateTime(dt);
            });

            chart.padding(10, 66, 10, 66);

            chart.credits().enabled(false);

            //chart.scroller().line(scrollerMapping);

            //////////////////////////////////////////////////////////////

            plot = chart.plot(0);
            plot.legend(false);

            // K线图
            var series = plot.candlestick(mainMapping);

            series.normal().fallingFill("#438f4f");
            series.normal().fallingStroke("#438f4f");

            series.normal().risingFill("#da3634", 0);
            series.normal().risingStroke("#da3634");

            

            // 最新价
            var indicator = plot.priceIndicator();
            indicator.axis(chart.plot(0).yAxis(1));
            indicator.value('last-visible');

            indicator.stroke("#2655D9");
            //indicator.stroke("#2655D9", 2, "2 2");
            var tmpLabel = indicator.label();

            tmpLabel.fontColor("#666");
            tmpLabel.padding(0);
            //tmpLabel.offsetX(-66);
            tmpLabel.width(66);
            tmpLabel.height(30);

            var bg = tmpLabel.background();
            bg.stroke("#2655D9");
            bg.fill("#FFF");
            tmpLabel.useHtml(true).format(function () {
                return this.value.toFixed(2) + '<br /> 最新价';
            });

            plot.yGrid(true);
            plot.xGrid(true);
            plot.xGrid().stroke({ color: "#ccc", dash: "3 5" });
            plot.yGrid().stroke({ color: "#ccc", dash: "3 5" });
            
            plot.yAxis(1).orientation('right');
            

            // x轴
            plot.xAxis().labels().format(function (value) {
                var date = new Date(value["tickValue"]);
                var options = {
                    year: "numeric",
                    month: "short"
                };

                return date.toLocaleDateString(locale, options);
            });
            //plot.yAxis().labels().offsetX(0);
            

            // attach it to all required events
            chart.listen("selectedrangechangestart", function (e) {
                chartInfo.sDate = e.firstSelected;
                chartInfo.eDate = e.lastSelected;
                //console.log('selectedrangechangestart', e);
                //console.log('selectedrangechangestart', getDateTime(e.firstSelected), getDateTime(e.lastSelected));
            });
            chart.listen("selectedrangebeforechange", function (e) {
                chartInfo.sDate = e.firstSelected;
                chartInfo.eDate = e.lastSelected;
                //console.log('selectedrangebeforechange', e);
                //console.log('selectedrangebeforechange', getDateTime(e.firstSelected), getDateTime(e.lastSelected));
            });
            chart.listen("selectedrangechange", function (e) {
                chartInfo.sDate = e.firstSelected;
                chartInfo.eDate = e.lastSelected;
                //console.log('selectedrangechange', getDateTime(e.firstSelected), getDateTime(e.lastSelected));
            });
            chart.listen("selectedrangechangefinish", function (e) {
                chartInfo.sDate = e.firstSelected;
                chartInfo.eDate = e.lastSelected;
                //console.log('selectedrangechangefinish', e);
                //console.log('selectedrangechangefinish', getDateTime(e.firstSelected), getDateTime(e.lastSelected));
            });

            chart.container('chartContainer');
            chart.draw();


            //chart.xMinorGrid(true);
            //chart.yMinorGrid(true);

            //plot.yGrid().enabled(true);

            

            var lastDate = '';
            //*************************** 交易区域  *******************************/
            if (profitRange.length > 0) {

                // 右侧空间
                /**
                chart.xScale().maximumGap({
                    intervalsCount: 15,
                    unitType: 'day',//second,minute,hour,day,week
                    unitCount: 0
                });
                */
                chartInfo.w = chart.getPixelBounds().width - 76;

                //chartInfo.w = plot.getPixelBounds().width;
                chartInfo.h = plot.yAxis().getPixelBounds().height;
                console.log('chartInfo', chartInfo);

                var items = [];
                var firstDate = '';

                for (var i in profitRange) {
                    if (firstDate === '') firstDate = profitRange[i].BarStart;
                    lastDate = profitRange[i].BarStart;

                    var tmp = [];
                    tmp.push(lastDate);
                    tmp.push(profitRange[i].StopLoss);
                    tmp.push(profitRange[i].TakeProfit);

                    items.push(tmp);
                }

                var profitRangeTable = anychart.data.table();
                profitRangeTable.addData(items);
                //var areas = plot.rangeSplineArea(items);
                var areas = plot.rangeSplineArea(profitRangeTable.mapAs({ 'x': 0, 'high': 1, 'low': 2 }));

                areas.highStroke('#F5A623', 1, 1, 'round', 'round');
                areas.lowStroke('#F5A623', 1, 1, 'round', 'round');
                areas.fill('#FFEAC7');

                // 加一天，铺满显示区
                var tmpLastDate = moment(lastDate).add(1, 'day').format('YYYY-MM-DD');

                var rangeMarker = plot.rangeMarker(0);
                rangeMarker.from(firstDate);
                rangeMarker.to(tmpLastDate);
                rangeMarker.fill('#D1E3FF 0.3403');
                rangeMarker.axis(plot.xAxis());

                /**
                // 目标价
                var horizontalLine = plot.annotations().horizontalLine({
                    valueAnchor: profitRange[5].TakeProfit,
                    normal: { stroke: "1 #9013FE" },
                    hovered: { stroke: "1 #9013FE" },
                    selected: { stroke: "1 #9013FE" },
                    zIndex: 99
                });

                var right = chartInfo.w / 2 - 31;

                var horizontalMarkers = horizontalLine.markers();
                horizontalMarkers.enabled(true);
                horizontalMarkers.size(30);
                horizontalMarkers.stroke('#9013FE');
                horizontalMarkers.fill('#FFF');
                horizontalMarkers.type('line');
                horizontalMarkers.rotation(90);
                horizontalMarkers.offsetX(right);
                horizontalMarkers.zIndex(100);


                var firstDate = profitRange[0].BarStart;
                // 设置最大持仓期
                var verticalLine = plot.annotations().verticalLine({
                    xAnchor: firstDate,
                    normal: { stroke: "1 #9013FE" },
                    hovered: { stroke: "1 #9013FE" },
                    selected: { stroke: "1 #9013FE" },
                    zIndex: 99
                });

                var top = 0 - chartInfo.h / 2 + 30;

                var markers = verticalLine.markers();
                markers.enabled(true);
                markers.size(30);
                markers.stroke('#9013FE');
                markers.fill('#FFF');
                markers.type('line');
                //markers.rotation(90);
                markers.offsetY(top);

                var verticalLineLabel = plot.annotations().label();

                var barEndTime = historyDataItems[historyDataItems.length - 1].BarStart;
                verticalLineLabel.xAnchor(barEndTime);
                verticalLineLabel.valueAnchor(chartInfo.max);

                var bg = verticalLineLabel.background();
                bg.stroke('0 #FFF');
                bg.fill('#FFF');
                verticalLineLabel.fontColor('#666');
                verticalLineLabel.fontSize(9);
                //verticalLineLabel.textIndent(5);
                verticalLineLabel.text('预计\n时间');
                verticalLineLabel.markers().size(0);
                verticalLineLabel.padding(0);

                verticalLineLabel.useHtml(true);

                verticalLineLabel.allowEdit(false);

                verticalLineLabel.zIndex(100);

                var offsetX = verticalLineLabel.offsetX();
                var offsetY = verticalLineLabel.offsetY();
                verticalLineLabel.offsetX(offsetX);
                verticalLineLabel.offsetY(offsetY);
                

                chart.listen('annotationSelect', function (e) {
                    var annotation = e.annotation;
                });
                
                chart.listen('annotationChange', function (e) {
                    var annotation = e.annotation;
                    if (annotation.getType() === 'horizontal-line') {
                        
                    } else {
                        var xAnchor = getDateTime(annotation.xAnchor());

                        var oDate1 = Date.parse(xAnchor);
                        var oDate2 = Date.parse(barEndTime);
                        if (oDate1 > oDate2) {
                            var dateSpan = oDate1 - oDate2;
                            dateSpan = Math.abs(dateSpan);
                            var iDays = Math.floor(dateSpan / (24 * 3600 * 1000));

                        } else {
                            verticalLine.xAnchor(firstDate);
                            verticalLineLabel.xAnchor(barEndTime);
                        }
                    }
                });

                chart.listen('annotationChangeStart', function (e) {
                    var annotation = e.annotation;
                    if (annotation.getType() === 'horizontal-line') {

                    } else {
                        var xAnchor = getDateTime(annotation.xAnchor());

                        var oDate1 = Date.parse(xAnchor);
                        var oDate2 = Date.parse(barEndTime);
                        if (oDate1 < oDate2) {
                            verticalLine.xAnchor(firstDate);
                            verticalLineLabel.xAnchor(barEndTime);
                        }
                    }
                });

                // Set event type to finish the annotation change
                chart.listen('annotationChangeFinish', function (e) {
                    var annotation = e.annotation;
                    if (annotation.getType() === 'horizontal-line') {

                    } else {
                        var xAnchor = getDateTime(annotation.xAnchor());

                        var oDate1 = Date.parse(xAnchor);
                        var oDate2 = Date.parse(barEndTime);
                        if (oDate1 < oDate2) {
                            verticalLine.xAnchor(firstDate);
                            verticalLineLabel.xAnchor(barEndTime);
                        }
                    }
                });
                */

                // 取前n条记录展示图表
                var firstRange = '';
                if (historyDataItems.length > 15) {
                    firstRange = historyDataItems[historyDataItems.length - 15].BarStart;
                } else {
                    firstRange = historyDataItems[0].BarStart;
                }

                chart.selectRange(firstRange, lastDate);
                //chart.selectRange('points', 30, true);
                
            }
        }

        //return <AnyChart id="stockChart" title="Stock hawkeye" instance={chart} height={chartHeight} />
    }

    render() {
        return (<Content style={{ display: this.props.hidden }}>

            <div className="time-sharing">
                <div data-value="0" className="stock-info-btn" onClick={this.onTimeSharing}>分时</div>
                <div data-value="5" className="stock-info-btn" onClick={this.onTimeSharing}>5分</div>
                <div data-value="60" className="stock-info-btn" onClick={this.onTimeSharing}>60分</div>
                <div data-value="1440" className="stock-info-btn active" onClick={this.onTimeSharing}>日K</div>
                <div data-value="10080" className="stock-info-btn" onClick={this.onTimeSharing}>周K</div>
            </div>
            <div className="stock-info">
                <span className="stock-info-button" onClick={this.onTargetPriceLine}>目标价</span>
                <span className="stock-info-button" onClick={this.onEstimatedTargetPriceDays}>预计达到目标价天数</span>
            </div>

            <div className="charts">
                <div id="chartContainer" className="chart-content"></div>
                <div className="drag-date-v-line blur"
                    onMouseDown={e => this.onMouseDown(e)}
                    style={{ top: "10px", left: this.state.translateX + "px", display: this.state.estimatedDaysDisplay }}>
                    <div className="drag-v-line"></div>
                    <div className="tipbox">预计时间</div>
                </div>
                <div className="drag-date-h-line red"
                    onMouseDown={e => this.onMouseDown(e)}
                    style={{ top: this.state.translateY + "px", display: this.state.targetPriceDisplay }}>
                    <div className="drag-h-line"></div>
                    <div className="tipbox-txt">
                        <div>目标价<span id="take-profit-value">69.00</span></div>
                        <div>概率<span id="take-profit-probability">98</span>%</div>
                    </div>
                </div>
            </div>
        </Content>);
    }
}

export { HawkeyeKLine as default }