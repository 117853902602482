export default function vernacular(state = {}, action) {
    switch (action.type) {
        case "get_formula_success":
            return { ...state, status: "formula_success", sectors: action.sectors, stockCount: action.stockCount, permission: action.permission };
        case "get_formula_fail":
            return { ...state, status: "formula_fail", state: action.status };

        case "save_formula_strategy_commit":
            return { ...state, status: "save_formula_commit" };
        case "get_save_formula_success":
            return { ...state, status: "save_formula_success", editStrategy: action.editStrategy };
        case "get_save_formula_fail":
            return { ...state, status: "save_formula_fail", state: action.status, fail: action.error };

        case "scan_formula_strategy_commit":
            return { ...state, status: "scan_formula_commit" };
        case "get_scan_formula_success":
            return { ...state, status: "scan_formula_success", scanAlert: action.scanAlert, filters: action.filter, permission: action.permission, lastTimes: action.lastTimes };
        case "get_scan_formula_fail":
            return { ...state, status: "scan_formula_fail", state: action.status, fail: action.error };

        case "get_luis_success":
            return { ...state, status: "luis_success", sectors: action.sectors, luString: action.luString, stockCount: action.stockCount, permission: action.permission };
        case "get_luis_fail":
            return { ...state, status: "luis_fail", state: action.status }

        case "get_change_batch_success":
            return { ...state, status: "get_change_batch_success", luString: action.luString };
        case "get_change_batch_fail":
            return { ...state, status: "get_change_batch_fail", state: action.status }

        case "get_change_condition_success":
            return { ...state, status: "get_change_condition_success", luString: action.luString, sentenceFormula: action.sentenceFormula };
        case "get_change_condition_fail":
            return { ...state, status: "get_change_condition_fail", state: action.status }

        case "luis_analysis_commit":
            return { ...state, status: "analysis_commit" };
        case "get_luis_analysis_success":
            return { ...state, status: "analysis_success", formulaString: action.formulaString };
        case "get_luis_analysis_fail":
            return { ...state, status: "analysis_fail", state: action.status };

        case "change_luis_strategy_commit":
            return { ...state, status: "change_luis_strategy_commit" };
        case "change_luis_strategy_success":
            return { ...state, status: "change_luis_strategy_success", luisString: action.luisString };
        case "change_luis_strategy_fail":
            return { ...state, status: "change_luis_strategy_fail", state: action.status };

        case "customStock_commit":
            return { ...state, status: "customStock_commit" };
        case "update_customStock_success":
            return { ...state, status: "update_customStock_success", customGroup: action.customGroup, symbolList: action.symbolList };
        case "update_customStock_fail":
            return { ...state, status: "update_customStock_fail", state: action.status };

        case "get_stock_count_success":
            return { ...state, status: "get_stock_count_success", stockCount: action.stockCount };
        case "get_stock_count_fail":
            return { ...state, status: "get_stock_count_fail", state: action.status };

        case "clear_filter_string":
            return { ...state, status: "clear_filter_string" };

        case "use_formula":
            return { ...state, status: "use_formula", useFormulaData: action.useFormulaData };
        case "empty":
            return { ...state, status: '' };
        default:
            return state;
    }
}