import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../helpers/history';
import { Layout, PageHeader, Tabs, Table, Modal, Button, Icon, message, Row, Col } from 'antd';
import moment from 'moment';
import emitter from '../Evt.js';

const { Content } = Layout;
const { TabPane } = Tabs;

const Confirm = Modal.confirm;

var languageMessages = window.appLocale.messages;

function strReplace(source, jsonObjcet) {
    for (var v in jsonObjcet) {
        source = source.replace(v, jsonObjcet[v]);
    }
    return source;
}

const closeShort = 'Close Short';

class HawkeyeListPage extends Component {
    constructor(props) {
        super(props);

        this.wColumns = [
            {
                title: languageMessages['sequence'],
                dataIndex: 'sequence',
                width: 50
            }, {
                title: languageMessages['symbolName'],
                dataIndex: 'symbolName',
                sorter: (a, b) => a.symbolName < b.symbolName ? -1 : a.symbolName > b.symbolName ? 1 : 0,
                sortDirections: ['descend', 'ascend'],
                render: (text) => <a>{text}</a>,
            }, {
                title: languageMessages['symbolCode'],
                dataIndex: 'symbolCode',
                sorter: (a, b) => a.symbolCode < b.symbolCode ? -1 : a.symbolCode > b.symbolCode ? 1 : 0,
                sortDirections: ['descend', 'ascend'],
                render: (text) => <a>{text}</a>,
            }, {
                title: languageMessages['latest.price'],
                dataIndex: 'latestPrice',
                sorter: (a, b) => a.latestPrice - b.latestPrice,
                sortDirections: ['descend', 'ascend'],
                render: (text, row) => {
                    return <span>{text}</span>;
                },
            }, {
                title: languageMessages['quote.change'],
                dataIndex: 'rangePercent',
                sorter: (a, b) => a.rangePercent - b.rangePercent,
                sortDirections: ['descend', 'ascend'],
                render: (text) => {
                    if (text >= 0) {
                        return <span style={{ color: window.webType === 'US' ? 'green' : 'red' }}>{text}%</span>;
                    } else {
                        return <span style={{ color: window.webType === 'US' ? 'red' : 'green' }}>{text}%</span>;
                    }
                },
            }, {
                title: languageMessages['hawkeyeType'],
                dataIndex: 'hawkeyeType',
                sorter: (a, b) => a.hawkeyeType - b.hawkeyeType,
                sortDirections: ['descend', 'ascend'],
                render: type => (
                    <span>
                        {type === 0 ? languageMessages['vernacularHawkeye'] : type === 1 ? languageMessages['priceHawkeye'] : type === 2 ? languageMessages['condHawkeye'] : type === 3 ? languageMessages['HybridHawkeye'] : languageMessages['kLineHawkeye']}
                    </span>
                )
            }, {
                title: languageMessages['hawkeyeCondition'],
                dataIndex: 'hawkeyeCondition',
                sorter: (a, b) => a.hawkeyeCondition < b.hawkeyeCondition ? -1 : a.hawkeyeCondition > b.hawkeyeCondition ? 1 : 0,
                sortDirections: ['descend', 'ascend'],
                render: cond => {
                    var html = [];
                    if (cond !== undefined && cond !== '') {
                        var hawkeyeCondition = JSON.parse(cond || '{}') || {};
                        for (var item in hawkeyeCondition) {
                            html.push(<div key={item}>{hawkeyeCondition[item]}</div>);
                        }
                    }
                    return html;
                }
            }, {
                title: languageMessages['tradeDirection'],
                dataIndex: 'tradeDirection',
                sorter: (a, b) => a.tradeDirection < b.tradeDirection ? -1 : a.tradeDirection > b.tradeDirection ? 1 : 0,
                sortDirections: ['descend', 'ascend'],
                render: direction => (
                    <span>
                        {direction === closeShort ? languageMessages['closeShort'] : languageMessages['closeLong']}
                    </span>
                )
            }, {
                title: languageMessages['tradeQuantity'],
                dataIndex: 'tradeQuantity',
                sorter: (a, b) => a.tradeQuantity - b.tradeQuantity,
                sortDirections: ['descend', 'ascend'],
            }, {
                title: languageMessages['expireDate'],
                dataIndex: 'expireDate',
                sorter: (a, b) => a.expireDate - b.expireDate,
                sortDirections: ['descend', 'ascend'],
            }, {
                title: languageMessages['triggerDate'],
                dataIndex: 'triggerDate',
                sorter: (a, b) => a.triggerDate - b.triggerDate,
                sortDirections: ['descend', 'ascend'],
            }, {
                title: languageMessages['triggerCondition'],
                dataIndex: 'triggerCondition',
                sorter: (a, b) => a.triggerCondition - b.triggerCondition,
                sortDirections: ['descend', 'ascend'],
            }, {
                title: languageMessages['control'],
                dataIndex: '',
                align: 'center',
                render: (text, record) =>
                    (<span style={{ whiteSpace: 'nowrap' }}>
                        <a className='table-button' onClick={() => this.onHawkeyePlaceOrder(record.key)}>{languageMessages['placeOrder']}</a>
                        <span style={{ padding: '0 2px' }}></span>
                        <a className='table-button' onClick={() => this.onHawkeyeClose(record.key)}>{languageMessages['close']}</a>
                    </span>),
            },
        ];

        this.columns = [
            {
                title: languageMessages['sequence'],
                dataIndex: 'sequence',
                width: 50
            }, {
                title: languageMessages['symbolName'],
                dataIndex: 'symbolName',
                sorter: (a, b) => a.symbolName < b.symbolName ? -1 : a.symbolName > b.symbolName ? 1 : 0,
                sortDirections: ['descend', 'ascend'],
                render: (text) => <a>{text}</a>,
            }, {
                title: languageMessages['symbolCode'],
                dataIndex: 'symbolCode',
                sorter: (a, b) => a.symbolCode < b.symbolCode ? -1 : a.symbolCode > b.symbolCode ? 1 : 0,
                sortDirections: ['descend', 'ascend'],
                render: (text) => <a>{text}</a>,
            }, {
                title: languageMessages['latest.price'],
                dataIndex: 'latestPrice',
                sorter: (a, b) => a.latestPrice - b.latestPrice,
                sortDirections: ['descend', 'ascend'],
                render: (text, row) => {
                    if (row.rangePercent >= 0) {
                        return <span style={{ color: "red" }}>{text}</span>;
                    } else {
                        return <span style={{ color: "green" }}>{text}</span>;
                    }
                },
            }, {
                title: languageMessages['quote.change'],
                dataIndex: 'rangePercent',
                sorter: (a, b) => a.rangePercent - b.rangePercent,
                sortDirections: ['descend', 'ascend'],
                render: (text) => {
                    if (text >= 0) {
                        return <span style={{ color: "red" }}>{text}%</span>;
                    } else {
                        return <span style={{ color: "green" }}>{text}%</span>;
                    }
                },
            }, {
                title: languageMessages['hawkeyeType'],
                dataIndex: 'hawkeyeType',
                sorter: (a, b) => a.hawkeyeType - b.hawkeyeType,
                sortDirections: ['descend', 'ascend'],
                render: type => (
                    <span>
                        {type === 0 ? languageMessages['vernacularHawkeye'] : type === 1 ? languageMessages['priceHawkeye'] : type === 2 ? languageMessages['condHawkeye'] : type === 3 ? languageMessages['HybridHawkeye'] : languageMessages['kLineHawkeye']}
                    </span>
                )
            }, {
                title: languageMessages['hawkeyeCondition'],
                dataIndex: 'hawkeyeCondition',
                sorter: (a, b) => a.hawkeyeCondition < b.hawkeyeCondition ? -1 : a.hawkeyeCondition > b.hawkeyeCondition ? 1 : 0,
                sortDirections: ['descend', 'ascend'],
                render: cond => {
                    var html = [];
                    if (cond !== undefined && cond !== '') {
                        var hawkeyeCondition = JSON.parse(cond || '{}') || {};
                        for (var item in hawkeyeCondition) {
                            html.push(<div key={item}>{hawkeyeCondition[item]}</div>);
                        }
                    }
                    return html;
                }
            }, {
                title: languageMessages['tradeDirection'],
                dataIndex: 'tradeDirection',
                sorter: (a, b) => a.tradeDirection < b.tradeDirection ? -1 : a.tradeDirection > b.tradeDirection ? 1 : 0,
                sortDirections: ['descend', 'ascend'],
                render: direction => (
                    <span>
                        {direction === closeShort ? languageMessages['closeShort'] : languageMessages['closeLong']}
                    </span>
                )
            }, {
                title: languageMessages['tradeQuantity'],
                dataIndex: 'tradeQuantity',
                sorter: (a, b) => a.tradeQuantity - b.tradeQuantity,
                sortDirections: ['descend', 'ascend'],
            }, {
                title: languageMessages['expireDate'],
                dataIndex: 'expireDate',
                sorter: (a, b) => a.expireDate - b.expireDate,
                sortDirections: ['descend', 'ascend'],
            }, {
                title: languageMessages['control'],
                dataIndex: '',
                align: 'center',
                //render: (text, record) => <Icon type="stop" style={{ fontSize: '18px', color: '#1C9FCE' }} onClick={() => this.onHawkeyeCancel(record.key)} />,
                render: (text, record) => <a className='table-button' onClick={() => this.onHawkeyeCancel(record.key)}>{languageMessages['stop']}</a>,
            },
        ];

        this.nColumns = [
            {
                title: languageMessages['sequence'],
                dataIndex: 'sequence',
                className: 'common-gray',
                width: 50
            }, {
                title: languageMessages['symbolName'],
                dataIndex: 'symbolName',
                className: 'common-gray',
                sorter: (a, b) => a.symbolName < b.symbolName ? -1 : a.symbolName > b.symbolName ? 1 : 0,
                sortDirections: ['descend', 'ascend'],
            }, {
                title: languageMessages['symbolCode'],
                dataIndex: 'symbolCode',
                className: 'common-gray',
                sorter: (a, b) => a.symbolCode < b.symbolCode ? -1 : a.symbolCode > b.symbolCode ? 1 : 0,
                sortDirections: ['descend', 'ascend'],
            }, {
                title: languageMessages['hawkeyeType'],
                dataIndex: 'hawkeyeType',
                className: 'common-gray',
                sorter: (a, b) => a.hawkeyeType - b.hawkeyeType,
                sortDirections: ['descend', 'ascend'],
                render: type => (
                    <span>
                        {type === 0 ? languageMessages['vernacularHawkeye'] : type === 1 ? languageMessages['priceHawkeye'] : type === 2 ? languageMessages['condHawkeye'] : type === 3 ? languageMessages['HybridHawkeye'] : languageMessages['kLineHawkeye']}
                    </span>
                )
            }, {
                title: languageMessages['hawkeyeCondition'],
                dataIndex: 'hawkeyeCondition',
                className: 'common-gray',
                sorter: (a, b) => a.hawkeyeCondition < b.hawkeyeCondition ? -1 : a.hawkeyeCondition > b.hawkeyeCondition ? 1 : 0,
                sortDirections: ['descend', 'ascend'],
                render: cond => {
                    var html = [];
                    if (cond !== undefined && cond !== '') {
                        var hawkeyeCondition = JSON.parse(cond || '{}') || {};
                        for (var item in hawkeyeCondition) {
                            html.push(<div key={item}>{hawkeyeCondition[item]}</div>);
                        }
                    }
                    return html;
                }
            }, {
                title: languageMessages['tradeDirection'],
                dataIndex: 'tradeDirection',
                className: 'common-gray',
                sorter: (a, b) => a.tradeDirection < b.tradeDirection ? -1 : a.tradeDirection > b.tradeDirection ? 1 : 0,
                sortDirections: ['descend', 'ascend'],
                render: direction => (
                    <span>
                        {direction === closeShort ? languageMessages['closeShort'] : languageMessages['closeLong']}
                    </span>
                )
            }, {
                title: languageMessages['tradeQuantity'],
                dataIndex: 'tradeQuantity',
                className: 'common-gray',
                sorter: (a, b) => a.tradeQuantity - b.tradeQuantity,
                sortDirections: ['descend', 'ascend'],
            }, {
                title: languageMessages['expireDate'],
                dataIndex: 'expireDate',
                className: 'common-gray',
                sorter: (a, b) => a.expireDate - b.expireDate,
                sortDirections: ['descend', 'ascend'],
            }, {
                title: languageMessages['triggerDate'],
                dataIndex: 'triggerDate',
                className: 'common-gray',
                sorter: (a, b) => a.triggerDate - b.triggerDate,
                sortDirections: ['descend', 'ascend'],
            }, {
                title: languageMessages['triggerCondition'],
                dataIndex: 'triggerCondition',
                className: 'common-gray',
                sorter: (a, b) => a.triggerCondition - b.triggerCondition,
                sortDirections: ['descend', 'ascend']
            }, {
                title: languageMessages['status'],
                dataIndex: 'status',
                className: 'common-gray',
                sorter: (a, b) => a.status - b.status,
                sortDirections: ['descend', 'ascend'],
                render: status => (
                    <span>
                        {status === 2 ? languageMessages['ordered'] : status === 3 ? languageMessages['triggered'] : status === 4 ? languageMessages['closed'] : status === 5 ? languageMessages['activeTermination'] : status === 6 ? languageMessages['hawkeyeError'] : languageMessages['invalid']}
                    </span>
                )
            }, {
                title: languageMessages['control'],
                dataIndex: '',
                align: 'center',
                render: (text, record) => <Icon type="delete" style={{ fontSize: '18px' }} onClick={() => this.onRemove(record.key)}/>,
            },
        ];

        this.user = {};
        this.state = {
            initLoadding: true,

            activeKey: sessionStorage.getItem("hawkeyeListTabActiveKey") || '0',

            selectedRowKeys: [],

            wDataSource: [],
            dataSource: [],
            nDataSource: [],

            placeOrderVisible: false,
            hawkeyeOrder: {},
        };
    }

    componentDidMount() {
        emitter.addListener('initPage', this.init);

        emitter.addListener('ConfirmHawkeye', this.ConfirmHawkeye);
        emitter.addListener('triggerHawkeye', this.triggerHawkeye);
        emitter.addListener('callHawkeye', this.callHawkeye);
    }

    componentWillMount() {
        this.init();
    }

    componentWillUnmount() {
        emitter.removeListener('initPage', this.init);

        emitter.removeListener('callHawkeye', this.callHawkeye);
        emitter.removeListener('ConfirmHawkeye', this.ConfirmHawkeye);
        emitter.removeListener('triggerHawkeye', this.triggerHawkeye);
    }

    ConfirmHawkeye = (hawkeyeIds, confirmResult) => {
        var { wDataSource, dataSource, nDataSource, activeKey, initLoadding } = this.state;

        var confirmHawkeye = wDataSource.filter(function (v) { return hawkeyeIds.indexOf(v.key) !== -1 });
        if (confirmHawkeye.length > 0) {
            activeKey = '2';
            for (var item in confirmHawkeye) {
                confirmHawkeye[item].updated = moment().format('YYYY-MM-DD HH:mm:ss');
                if (confirmResult === 0) {
                    confirmHawkeye[item].status = 4;
                } else {
                    confirmHawkeye[item].status = 2;
                }
                nDataSource.splice(0, 0, confirmHawkeye[item]);
            }

            wDataSource = wDataSource.filter(function (v) { return hawkeyeIds.indexOf(v.key) === -1 });

            var len = wDataSource.length;
            for (var ii = 0; ii < len; ii++) {
                var index = ii + 1;
                wDataSource[ii].sequence = index;
            }
            len = dataSource.length;
            for (var ii = 0; ii < len; ii++) {
                var index = ii + 1;
                dataSource[ii].sequence = index;
            }
            len = nDataSource.length;
            for (var ii = 0; ii < len; ii++) {
                var index = ii + 1;
                nDataSource[ii].sequence = index;
            }

            sessionStorage.setItem("hawkeyeListTabActiveKey", activeKey);
            this.setState({
                wDataSource,
                dataSource,
                nDataSource,
                activeKey,
                selectedRowKeys: [],
                initLoadding: false
            });
        } else {
            if (initLoadding) this.setState({ initLoadding: false });
        }
    }

    triggerHawkeye = (hawkeye) => {
        var { wDataSource, dataSource, nDataSource, activeKey, initLoadding } = this.state;

        var tmpHawkeyes = dataSource.filter(function (v) { return hawkeye.Id == v.key });
        if (tmpHawkeyes.length > 0) {
            activeKey = hawkeye.ConfirmOrder === 0 ? '0' : '2';
            for (var item in tmpHawkeyes) {
                tmpHawkeyes[item].triggerCondition = hawkeye.TriggerCondition;
                tmpHawkeyes[item].triggerDate = hawkeye.TriggerDateTime;
                tmpHawkeyes[item].updated = moment().format('YYYY-MM-DD HH:mm:ss');

                if (hawkeye.ConfirmOrder === 0) {
                    tmpHawkeyes[item].status = 1;
                    wDataSource.splice(0, 0, tmpHawkeyes[item]);
                } else {
                    tmpHawkeyes[item].status = 2;
                    nDataSource.splice(0, 0, tmpHawkeyes[item]);
                }
            }

            dataSource = dataSource.filter(function (v) { return hawkeye.Id != v.key });

            var len = wDataSource.length;
            for (var ii = 0; ii < len; ii++) {
                var index = ii + 1;
                wDataSource[ii].sequence = index;
            }
            len = dataSource.length;
            for (var ii = 0; ii < len; ii++) {
                var index = ii + 1;
                dataSource[ii].sequence = index;
            }
            len = nDataSource.length;
            for (var ii = 0; ii < len; ii++) {
                var index = ii + 1;
                nDataSource[ii].sequence = index;
            }

            sessionStorage.setItem("hawkeyeListTabActiveKey", activeKey);
            this.setState({
                wDataSource,
                dataSource,
                nDataSource,
                activeKey,
                selectedRowKeys: [],
                initLoadding: false
            });
        } else {
            if (initLoadding) this.setState({ initLoadding: false });
        }
    }

    callHawkeye = (hawkeyeIds, type) => {
        var { wDataSource, dataSource, nDataSource, activeKey, initLoadding } = this.state;

        var tmpHawkeyes = dataSource.filter(function (v) { return hawkeyeIds.indexOf(v.key) !== -1 });
        if (tmpHawkeyes.length > 0) {
            activeKey = type === 0 ? '0' : '2';
            for (var item in tmpHawkeyes) {
                tmpHawkeyes[item].updated = moment().format('YYYY-MM-DD HH:mm:ss');
                if (type === 0) {
                    tmpHawkeyes[item].status = 1;
                    wDataSource.splice(0, 0, tmpHawkeyes[item]);
                } else {
                    tmpHawkeyes[item].status = type === 1 ? 3 : 5;
                    nDataSource.splice(0, 0, tmpHawkeyes[item]);
                }
            }

            dataSource = dataSource.filter(function (v) { return hawkeyeIds.indexOf(v.key) === -1 });

            var len = wDataSource.length;
            for (var ii = 0; ii < len; ii++) {
                var index = ii + 1;
                wDataSource[ii].sequence = index;
            }
            len = dataSource.length;
            for (var ii = 0; ii < len; ii++) {
                var index = ii + 1;
                dataSource[ii].sequence = index;
            }
            len = nDataSource.length;
            for (var ii = 0; ii < len; ii++) {
                var index = ii + 1;
                nDataSource[ii].sequence = index;
            }

            sessionStorage.setItem("hawkeyeListTabActiveKey", activeKey);
            this.setState({
                wDataSource,
                dataSource,
                nDataSource,
                activeKey,
                selectedRowKeys: [],
                initLoadding: false
            });
        } else {
            if (initLoadding) this.setState({ initLoadding: false });
        }
    }

    init = () => {
        this.user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        /**
        if (user.username === undefined) {
            this.setState({ initLoadding: false }, () => {
                this.props.dispatch({ type: "action_non_user" });
            });
        } else {
            this.props.dispatch({ type: "action_get_hawkeye_items", payload: { UserId: user.username } });
        }
        */

        var u = this.user.username === undefined ? '' : this.user.username;
        this.props.dispatch({ type: "action_get_hawkeye_items", payload: { UserId: u } });
    }

    componentWillReceiveProps(props) {
        var { status, state, items, hawkeyeId } = props;

        switch (status) {
            case 'get_hawkeye_success':
                var wDataSource = [];
                var dataSource = [];
                var nDataSource = [];

                if (items !== null) {
                    var wIndex = 1;
                    var index = 1;
                    var nIndex = 1;
                    for (var item in items) {
                        var status = items[item].status;

                        //是否已失效 items[item].endDate
                        if (status < 2) {
                            var now = moment();
                            var end = moment(items[item].endDate);
                            if (now > end) status = 8;
                        }

                        var data = {
                            key: items[item].id.toString(),
                            userId: items[item].userId,
                            symbolCode: items[item].symbolCode,
                            symbolName: items[item].symbolName,
                            latestPrice: items[item].latestPrice,
                            rangePercent: items[item].rangePercent,
                            hawkeyeType: items[item].hawkeyeType,
                            hawkeyeCondition: items[item].hawkeyeCondition,
                            tradeDirection: items[item].tradeDirection,
                            tradeQuantity: items[item].tradeQuantity,
                            expireDate: items[item].startDate + '~' + items[item].endDate,
                            triggerDate: items[item].triggerDate,
                            triggerCondition: items[item].triggerCondition,
                            updated: items[item].updated,
                            status: status
                        };

                        if (status === 0) {
                            data.sequence = wIndex;
                            dataSource.push(data);
                            wIndex++;
                        } else if (status === 1) {
                            data.sequence = index;
                            wDataSource.push(data);
                            index++;
                        } else {
                            data.sequence = nIndex;
                            nDataSource.push(data);
                            nIndex++;
                        }
                    }
                }

                this.setState({
                    dataSource,
                    wDataSource,
                    nDataSource,
                    initLoadding: false
                });
                break;
            case 'get_hawkeye_fail':
                var error = languageMessages['get.data.failed'];
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = languageMessages['user.exception'];
                        break;
                    default:
                        break;
                }
                message.error(error);
                this.setState({ initLoadding: false });
                break;
            case 'remove_hawkeye_success':
                if (hawkeyeId !== '') {
                    var { nDataSource } = this.state;
                    var hawkeyeIds = hawkeyeId.split(',');

                    nDataSource = nDataSource.filter(function (v) { return hawkeyeIds.indexOf(v.key.toString()) === -1 });
                    var len = nDataSource.length;
                    for (var ii = 0; ii < len; ii++) {
                        var index = ii + 1;
                        nDataSource[ii].sequence = index;
                    }

                    this.setState({ nDataSource, initLoadding: false });
                }
                break;
            case 'remove_hawkeye_fail':
                var error = languageMessages['deleteHawkeyeFailed'];
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = languageMessages['user.exception'];
                        break;
                    default:
                        break;
                }
                message.error(error);
                this.setState({ initLoadding: false });
                break;
            default:
                break;
        }
    }

    onCreateHawkEye = e => {
        e.preventDefault();

        emitter.emit('callJump', 'hawkeye');

        history.push("/hawkeye");
    }

    onChangeHawkeyeItems = activeKey => {
        sessionStorage.setItem("hawkeyeListTabActiveKey", activeKey);
        this.setState({ activeKey, selectedRowKeys: [] });
    }

    onHawkeyePlaceOrder = hawkeyeId => {
        //var hawkeyeIds = [hawkeyeId];
        this.hawkeyePlaceOrder(hawkeyeId);
    }

    hawkeyePlaceOrder(hawkeyeId) {
        if (this.user.username === undefined) {
            this.props.dispatch({ type: "action_non_user" });
        } else {
            var tmpHawkeyeOrder = this.state.wDataSource.filter(function (e) { return hawkeyeId.indexOf(e.key) >= 0 });

            if (tmpHawkeyeOrder.length > 0) {
                this.setState({ placeOrderVisible: true, hawkeyeOrder: tmpHawkeyeOrder[0] });
            }
        }
    }

    onHawkeyeClose = hawkeyeId => {
        var hawkeyeIds = [hawkeyeId];
        this.hawkeyeClose(hawkeyeIds);
    }

    onHawkeyeCloses = () => {
        var hawkeyeIds = this.state.selectedRowKeys;
        this.hawkeyeClose(hawkeyeIds);
    }

    hawkeyeClose(hawkeyeIds) {
        if (hawkeyeIds.length > 0) {
            if (this.user.username === undefined) {
                this.props.dispatch({ type: "action_non_user" });
            } else {
                var hawkeyeName = '';
                var tmpHawkeye = this.state.wDataSource.filter(function (e) { return hawkeyeIds.indexOf(e.key) >= 0 });
                if (tmpHawkeye.length > 0) {
                    hawkeyeName = tmpHawkeye[0].symbolName;
                }
                //var txt = '确定要关闭待下单盯盘“' + hawkeyeName + '”？';
                var txt = strReplace(languageMessages['confirmCloseHawkeye'], { '{name}': hawkeyeName });
                if (hawkeyeIds.length > 1) {
                    //txt = '确定要关闭“' + hawkeyeName + '”等 ' + hawkeyeIds.length + ' 个待下单盯盘？';
                    txt = strReplace(languageMessages['confirmCloseHawkeyes'], { '{name}': hawkeyeName, '{num}': hawkeyeIds.length });
                }
                var hawkeyeId = hawkeyeIds.join(',');

                var _this = this;
                Confirm({
                    title: languageMessages['prompt'],
                    content: txt,
                    okText: languageMessages['yes'],
                    okType: 'danger',
                    cancelText: languageMessages['no'],
                    onOk() {
                        _this.setState({ initLoadding: true }, () => {
                            emitter.emit('HawkeyeConfirm', hawkeyeId, 0);
                        });
                    },
                    onCancel() { },
                });
            }
        }
    }

    onHawkeyeCancel = hawkeyeId => {
        //e.stopPropagation();

        var hawkeyeIds = [hawkeyeId];
        this.hawkeyeCancel(hawkeyeIds);
    }

    onHawkeyeCancels = () => {
        var hawkeyeIds = this.state.selectedRowKeys;
        this.hawkeyeCancel(hawkeyeIds);
    }

    hawkeyeCancel(hawkeyeIds) {
        if (hawkeyeIds.length > 0) {
            if (this.user.username === undefined) {
                this.props.dispatch({ type: "action_non_user" });
            } else {
                var hawkeyeName = '';
                var tmpHawkeye = this.state.dataSource.filter(function (e) { return e.key === hawkeyeIds[0] });
                if (tmpHawkeye.length > 0) {
                    hawkeyeName = tmpHawkeye[0].symbolName;
                }
                //var txt = '确定要终止监控中盯盘“' + hawkeyeName + '”？';
                var txt = strReplace(languageMessages['confirmStopHawkeye'], { '{name}': hawkeyeName });
                if (hawkeyeIds.length > 1) {
                    //txt = '确定要终止“' + hawkeyeName + '”等 ' + hawkeyeIds.length + ' 个监控中盯盘？';
                    txt = strReplace(languageMessages['confirmStopHawkeyes'], { '{name}': hawkeyeName, '{num}': hawkeyeIds.length });
                }
                var hawkeyeId = hawkeyeIds.join(',');

                var _this = this;
                Confirm({
                    title: languageMessages['prompt'],
                    content: txt,
                    okText: languageMessages['yes'],
                    okType: 'danger',
                    cancelText: languageMessages['no'],
                    onOk() {
                        _this.setState({ initLoadding: true }, () => {
                            _this.props.dispatch({ type: "action_send_hub_message", payload: { service: 'SendHawkeyeCancel', userId: _this.user.username, msg: hawkeyeId } });
                        });
                    },
                    onCancel() { },
                });
            }
        }
    }

    onRemove = hawkeyeId => {
        //e.stopPropagation();

        var hawkeyeIds = [hawkeyeId];
        this.remove(hawkeyeIds);
    }

    onRemoves = () => {
        var hawkeyeIds = this.state.selectedRowKeys;
        this.remove(hawkeyeIds);
    }

    remove(hawkeyeIds) {
        if (hawkeyeIds.length > 0) {
            if (this.user.username === undefined) {
                this.props.dispatch({ type: "action_non_user" });
            } else {
                var hawkeyeName = '';
                var tmpHawkeye = this.state.nDataSource.filter(function (e) { return e.key === hawkeyeIds[0] });
                if (tmpHawkeye.length > 0) {
                    hawkeyeName = tmpHawkeye[0].symbolName;
                }
                //var txt = '确定要删除已结束盯盘“' + hawkeyeName + '”？';
                var txt = strReplace(languageMessages['confirmRemoveHawkeye'], { '{name}': hawkeyeName });
                if (hawkeyeIds.length > 1) {
                    //txt = '确定要删除“' + hawkeyeName + '”等 ' + hawkeyeIds.length + ' 个已结束盯盘？';
                    txt = strReplace(languageMessages['confirmRemoveHawkeyes'], { '{name}': hawkeyeName, '{num}': hawkeyeIds.length });
                }
                var hawkeyeId = hawkeyeIds.join(',');

                var _this = this;
                Confirm({
                    title: languageMessages['prompt'],
                    content: txt,
                    okText: languageMessages['yes'],
                    okType: 'danger',
                    cancelText: languageMessages['no'],
                    onOk() {
                        _this.setState({ initLoadding: true }, () => {
                            _this.props.dispatch({ type: "action_remove_hawkeye", payload: { UserId: _this.user.username, hawkeyeId: hawkeyeId } });
                        });
                    },
                    onCancel() { },
                });
            }
        }
    }

    /**
    OnSelectRows = (record) => {
        return {
            onClick: () => {
                
                this.setState({ rowId: record.key });
            }
        }
    }
    */

    setRowClassName = (record) => {
        return record.key === this.state.rowId ? 'common-table-row' : '';
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    handleConfirmOrder = () => {
        var { hawkeyeOrder } = this.state;

        this.setState({ initLoadding: true, placeOrderVisible: false }, () => {
            emitter.emit('HawkeyeConfirm', hawkeyeOrder.key, 1);
        });
    }

    handleCancelOrder = () => {
        this.setState({ placeOrderVisible: false, hawkeyeOrder: {} });
    }

    renderHawkeyeOrder(hawkeyeOrder) {
        return <div>
            <p>{languageMessages['commissionInformation']}</p>
            <Row>
                <Col className="common-gutter-row" span={4}>{languageMessages['symbolCode']}：</Col>
                <Col className="common-gutter-row" span={20}>{hawkeyeOrder['symbolCode']}</Col>
            </Row>
            <Row>
                <Col className="common-gutter-row" span={4}>{languageMessages['symbolName']}：</Col>
                <Col className="common-gutter-row" span={20}>{hawkeyeOrder['symbolName']}</Col>
            </Row>
            <Row>
                <Col className="common-gutter-row" span={4}>{languageMessages['tradeDirection']}：</Col>
                <Col className="common-gutter-row" span={20}>{hawkeyeOrder['Direction'] === 'Close Short' ? languageMessages['closeShort'] : languageMessages['closeLong']}</Col>
            </Row>
            <Row>
                <Col className="common-gutter-row" span={4}>{languageMessages['tradeQuantity']}：</Col>
                <Col className="common-gutter-row" span={20}>{hawkeyeOrder['tradeQuantity']}</Col>
            </Row>
            <Row>
                <Col className="common-gutter-row" span={4}>{languageMessages['commissionMode']}</Col>
                <Col className="common-gutter-row" span={20}>{languageMessages['marketPrice']}</Col>
            </Row>
        </div>
    }

    render() {
        const { wDataSource, dataSource, nDataSource, selectedRowKeys, activeKey } = this.state;

        const rowSelection = {
            title: languageMessages['select.all'],
            selectedRowKeys: selectedRowKeys,
            //onClick: this.onSelectChange,
            onChange: this.onSelectChange
        }

        var operations = '';
        switch (activeKey) {
            case '0':
                operations = <Button size='small' onClick={this.onHawkeyeCloses} disabled={selectedRowKeys.length > 0 ? false : true}>{languageMessages['close']}</Button>;
                break;
            case '1':
                operations = <Button size='small' onClick={this.onHawkeyeCancels} disabled={selectedRowKeys.length > 0 ? false : true}>{languageMessages['stop']}</Button>;
                break;
            case '2':
                operations = <Button size='small' onClick={this.onRemoves} disabled={selectedRowKeys.length > 0 ? false : true}>{languageMessages['remove']}</Button>;
                break;
            default:
                operations = <Button size='small' onClick={this.onHawkeyeCancels} disabled={selectedRowKeys.length > 0 ? false : true}>{languageMessages['stop']}</Button>;
                break;
        }

        return (<Content className='main-container'>
            <div className='common-page-header'>
                <PageHeader title={languageMessages['myHawkeye']}
                    extra={[
                        <Button key="1" size='small' onClick={this.onCreateHawkEye}>{languageMessages['newHawkeye']}</Button>
                    ]} />
            </div>
            <Modal title={languageMessages['orderConfirmation']}
                visible={this.state.placeOrderVisible}
                okText={languageMessages['confirm']}
                onOk={this.handleConfirmOrder}
                cancelText={languageMessages['cancel']}
                onCancel={this.handleCancelOrder}>
                {this.renderHawkeyeOrder(this.state.hawkeyeOrder)}
            </Modal>
            <Layout className='sub-tabs-content'>
                <div className="card-container common-tabs">
                    <Tabs activeKey={activeKey} type="card"
                        onChange={this.onChangeHawkeyeItems}
                        tabBarExtraContent={operations}>
                        <TabPane tab={languageMessages['pendingOrder']} key="0">
                            <Table bordered size='small' columns={this.wColumns}
                                dataSource={wDataSource}
                                loading={this.state.initLoadding}
                                rowSelection={rowSelection}
                                //onRow={this.OnSelectRows}
                                rowClassName={this.setRowClassName} />
                        </TabPane>
                        <TabPane tab={languageMessages['monitoring']} key="1">
                            <Table bordered size='small' columns={this.columns}
                                dataSource={dataSource}
                                loading={this.state.initLoadding}
                                rowSelection={rowSelection}
                                //onRow={this.OnSelectRows}
                                rowClassName={this.setRowClassName}/>
                        </TabPane>
                        <TabPane tab={languageMessages['over']} key="2">
                            <Table className='common-table-gray' bordered size='small' columns={this.nColumns}
                                dataSource={nDataSource}
                                loading={this.state.initLoadding}
                                rowSelection={rowSelection}
                                //onRow={this.OnSelectRows}
                                rowClassName={this.setRowClassName}/>
                        </TabPane>
                    </Tabs>
                </div>
            </Layout>
    </Content>)
    }
}

function mapStateToProps(state) {
    return state.hawkeye;
}

const page = connect(mapStateToProps)(HawkeyeListPage);
export { page as default }