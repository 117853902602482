import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Card, Alert, Row, Col } from 'antd';
import WrappedRegisterForm from '../../pannels/form/RegisterForm';
import RegisterSuccess from '../../pannels/form/RegisterSuccess';
import history from '../../helpers/history';
import emitter from '../Evt.js';

const { Content } = Layout;

var languageMessages = window.appLocale.messages;

let timer = null;
class RegisterPage extends Component {
    constructor(props) {
        super(props);
        var auth = JSON.parse(localStorage.getItem("auth") || '{}') || {};

        this.state = {
            isGetCaptcha: true,
            count: 60,
            code: '',
            message: '',
            registered: false,
            ...auth
        }
    }

    componentWillReceiveProps(props) {
        var { status, state } = props;
        var { count } = this.state;

        this.setState({
            code: '',
            message: '',
            registered: false
        });

        switch (status) {
            case 'Get_Captcha':
                switch (state) {
                    case 0://失败
                        this.setState({
                            code: 'ERROR',
                            message: languageMessages['get.verification.code.failed']
                        });
                        break;
                    case 1://成功
                        timer = setInterval(() => {
                            this.setState({
                                isGetCaptcha: false,
                                count: (count--)
                            }, () => {
                                if (count === 0) {
                                    clearInterval(timer);
                                    this.setState({
                                        isGetCaptcha: true,
                                        count: 60
                                    })
                                }
                            })
                        }, 1000);
                        break;
                    case 2://手机已注册
                        this.setState({
                            code: 'ERROR',
                            message: languageMessages['phone.is.register']
                        });
                        break;
                    case 3://手机有误
                        this.setState({
                            code: 'ERROR',
                            message: languageMessages['phone.format.failed']
                        });
                        break;
                }
                break;
            case "Registered":
                this.setState({
                    registered: true,
                });
                break;
            case "Register_Success":
                if (timer !== null) {
                    clearInterval(timer);
                }
                this.setState({
                    code: 'SUCCRSS',
                    message: languageMessages['register.success'],
                    isGetCaptcha: true,
                    count: 60
                });
                break;
            case "Register_Failed":
                if (timer !== null) {
                    clearInterval(timer);
                }
                let errorMeg = '';
                switch (state) {
                    case 0:
                        errorMeg = languageMessages['register.exception'];
                        break;
                    case 2:
                        errorMeg = languageMessages['account.is.exist'];
                        break;
                    case 3:
                        errorMeg = languageMessages['verification.code.matching.error'];
                        break;
                    case 4:
                        errorMeg = languageMessages['register.failed'];
                        break;
                    case 5:
                        errorMeg = languageMessages['promoCode.error'];
                        break;
                    default:
                        break;
                }
                this.setState({
                    code: 'ERROR',
                    message: errorMeg,
                    isGetCaptcha: true,
                    count: 60
                });
                break;
            default:
                break;
        }
    }

    handleJumpHome() {
        if (timer !== null) {
            clearInterval(timer);
        }
        this.setState({ isGetCaptcha: true, count: 60 }, () => {
            const n = window.location.search.indexOf("next=");
            if (n > 0) {
                history.replace(window.location.search.substr(n + 5));
            } else {
                emitter.emit('callJump', 'vernacular');
                history.replace('/vernacular');
            }
        });
    }

    toLogin = e => {
        e.preventDefault();
        history.push("/login");
    }

    handleChange(data) {
        this.setState({ code: data.error });
    }

    render() {
        return (<Content className='main-container total-content'>
            <div className="row-container" style={{ justifyContent: "center", padding: "36px 0", marginBottom: '30px' }}>
                {this.state.code === 'SUCCRSS' ? (<RegisterSuccess handleJumpHome={this.handleJumpHome.bind(this)} />) : (
                <Card className='common-box' cover={<div className='form-logo'></div>}>
                    <div className='title'>
                        <Row>
                            <Col span={12} style={{ textAlign: 'right', padding: '10px' }}>
                                <a onClick={this.toLogin}>{languageMessages['intl.login']}</a>
                            </Col>
                            <Col span={12} style={{ textAlign: 'left', padding: '10px' }}>
                                {languageMessages['intl.register']}
                            </Col>
                        </Row>
                    </div>
                    <WrappedRegisterForm dispatch={this.props.dispatch} isGetCaptcha={this.state.isGetCaptcha} count={this.state.count} registered={this.state.registered} handleChange={this.handleChange.bind(this)} />
                    {this.state.code === 'ERROR' ? (<Alert message={this.state.message} type="error" />) : ''}
                </Card>
                )}
            </div>
        </Content>
        )
    }
}

function mapStateToProps(state) {
    return state.member ? state.member : {}
}

const page = connect(mapStateToProps)(RegisterPage);
export { page as default }