import React, { Component } from 'react';
import { Table, Row, Col } from 'antd';

import echarts from 'echarts/lib/echarts';
import "echarts/lib/component/tooltip";
import 'echarts/lib/component/title';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/scatter';//effectScatter
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/component/axisPointer';
import 'echarts/lib/component/markArea';
import 'echarts/lib/component/legendScroll';

import { getChartOption } from '../../pannels/option/ChartsParams';

import { FormattedMessage } from 'react-intl';

var optionsChart = null;

var languageMessages = window.appLocale.messages;

class OptionResultPage extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            dataSource: [],

            rowId: -1,
            
            chartHeight: '0px',
        };
    }

    componentWillReceiveProps(props) {
        var { strategies } = props;
        var { rowId } = this.state;

        var recordName = '';
        var dataSource = [];
        if (strategies.length > 0) {
            var index = 0;
            for (var kk in strategies) {
                if (rowId === -1) {
                    rowId = kk;
                    recordName = strategies[kk].name;
                }
                var sequence = index + 1;
                let breakEventPoints = strategies[kk].breakEvenPoint;

                dataSource.push({
                    key: index,
                    sequence: sequence,
                    name: strategies[kk].name,
                    description: strategies[kk].description,
                    margin: strategies[kk].margin,
                    investment: strategies[kk].investment,
                    breakEvenPoint: breakEventPoints,
                    pnl: strategies[kk].pnl,
                    yieldToMaturity: strategies[kk].yieldToMaturity,
                    maxProfit: strategies[kk].maxProfit,
                    maxRisk: strategies[kk].maxRisk
                });
                index++;
            }
        } else {
            rowId = -1;
        }
        
        this.setState({ dataSource, rowId, chartHeight: '450px' }, () => {
            if (strategies.hasOwnProperty(rowId)) {
                optionsChart = echarts.init(document.getElementById('options'));
                optionsChart.setOption(getChartOption(recordName, strategies[rowId].pnlPoints));

                optionsChart.resize();

                window.onresize = function () {
                    optionsChart.resize();
                }

                //this.OnSelectShowChart(dataSource[rowId]);
                this.setRowClassName(dataSource[rowId]);
            } else {
                if (optionsChart !== null) {
                    optionsChart.resize();
                    optionsChart.clear();
                }
            }
        });
    }

    OnSelectShowChart = (record) => {
        return {
            onClick: () => {
                const { strategies } = this.props;
                
                if (strategies.length > 0) {
                    if (strategies.hasOwnProperty(record.key)) {
                        this.setState({ chartHeight: '450px' }, () => {
                            optionsChart = echarts.init(document.getElementById('options'));
                            optionsChart.setOption(getChartOption(record.name, strategies[record.key].pnlPoints));

                            optionsChart.resize();

                            window.onresize = function () {
                                optionsChart.resize();
                            }
                        });
                    }
                }
                this.setState({ rowId: record.key });
            }
        }
    }

    setRowClassName = (record) => {
        return record.key.toString() === this.state.rowId.toString() ? 'common-table-row' : '';
    }

    render() {
        var { dataSource, chartHeight } = this.state;

        var columns = [
            {
                title: languageMessages['sequence'],
                dataIndex: 'sequence',
                width: 50,
            }, {
                title: languageMessages['combination.name'],
                dataIndex: 'name',
                key: 'name',
                width: 120,
                sorter: (a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
                sortDirections: ['descend', 'ascend'],
                render: (text, record) => (
                    <span>
                        <a onClick={() => this.OnSelectShowChart(record.key)}>{languageMessages[text] !== undefined ? languageMessages[text] : text}</a>
                    </span>
                ),
            }, {
                title: languageMessages['combination.details'],
                dataIndex: 'description',
                key: 'description',
                width: 150,
                sorter: (a, b) => a.description < b.description ? -1 : a.description > b.description ? 1 : 0,
                sortDirections: ['descend', 'ascend'],
                render: (text) => {
                    const description = text.split(',');
                    return description.map((item, index) => {
                        return (<div key={index}>{item}</div>);
                    })
                }, 
            }, {
                title: languageMessages['margin'],
                dataIndex: 'margin',
                key: 'margin',
                className: 'common-right',
                width: 80,
                align: 'right',
                sorter: (a, b) => a.margin.replace(/,/g, "") - b.margin.replace(/,/g, ""),
                sortDirections: ['descend', 'ascend'],
            }, {
                title: languageMessages['investment'],
                dataIndex: 'investment',
                key: 'investment',
                className: 'common-right',
                width: 130,
                align: 'right',
                //sorter: (a, b) => a.investment < b.investment ? -1 : a.investment > b.investment ? 1 : 0,
                //sorter: (a, b) => a.investment - b.investment,
                sorter: (a, b) => a.investment.replace(/,/g, "") - b.investment.replace(/,/g, ""),
                sortDirections: ['descend', 'ascend'],
                render: (text, row) => {
                    let tmpInvestment = row['investment'].replace(/,/g, "");
                    if (tmpInvestment > 0) {
                        return <span>{text} ({languageMessages['cost']})</span>;
                    } else {
                        text = text.replace('-', '');
                        return <span>{text} ({languageMessages['income']})</span>;
                    }
                },
            }, {
                title: languageMessages['breakEvenPoint'],
                dataIndex: 'breakEvenPoint',
                key: 'breakEvenPoint',
                className: 'common-right',
                width: 90,
                align: 'right',
                sorter: (a, b) => a.breakEvenPoint[0] - b.breakEvenPoint[0],
                sortDirections: ['descend', 'ascend'],
                render: (text, row) => {
                    return text.map((item, index) => {
                        return index < 3 ? (<div key={index}>{item.toFixed(3)}</div>) : index === 3 ? (<div key={index}>...</div>) : '';
                    })
                },
            }, {
                title: languageMessages['profit.and.loss'],
                dataIndex: 'pnl',
                key: 'pnl',
                className: 'common-right',
                width: 90,
                align: 'right',
                //sorter: (a, b) => a.pnl < b.pnl ? -1 : a.pnl > b.pnl ? 1 : 0,
                sorter: (a, b) => a.pnl.replace(/,/g, "") - b.pnl.replace(/,/g, ""),
                //sorter: (a, b) => sort(a.pnl, b.pnl),
                sortDirections: ['descend', 'ascend'],
            }, {
                title: languageMessages['yieldToMaturity'],
                dataIndex: 'yieldToMaturity',
                key: 'yieldToMaturity',
                className: 'common-right',
                width: 100,
                align: 'right',
                //sorter: (a, b) => a.yieldToMaturity - b.yieldToMaturity,
                sorter: (a, b) => a.yieldToMaturity.replace(/,/g, "") - b.yieldToMaturity.replace(/,/g, ""),
                //sorter: (a, b) => sort(a.yieldToMaturity, b.yieldToMaturity),
                sortDirections: ['descend', 'ascend'],
            }, {
                title: languageMessages['maxProfit'],
                dataIndex: 'maxProfit',
                key: 'maxProfit',
                className: 'common-right',
                width: 90,
                align: 'right',
                //sorter: (a, b) => a.maxProfit < b.maxProfit ? -1 : a.maxProfit > b.maxProfit ? 1 : 0,
                sorter: (a, b) => a.maxProfit.replace(/,/g, "") - b.maxProfit.replace(/,/g, ""),
                //sorter: (a, b) => sort(a.maxProfit, b.maxProfit),
                sortDirections: ['descend', 'ascend'],
                render: (text, row) => {
                    if (row['maxProfit'] === 'Infinity') {
                        text = '无限';
                    }
                    return <span style={{ color: "red" }}>{text}</span>;
                },
            }, {
                title: languageMessages['maxRisk'],
                dataIndex: 'maxRisk',
                key: 'maxRisk',
                className: 'common-right',
                width: 90,
                align: 'right',
                sorter: (a, b) => a.maxRisk.replace(/,/g, "") - b.maxRisk.replace(/,/g, ""),
                //sorter: (a, b) => sort(a.maxRisk, b.maxRisk),
                sortDirections: ['descend', 'ascend'],
                render: (text, row) => {
                    if (row['maxRisk'] === '-Infinity') {
                        text = '-无限';
                    }
                    return <span style={{ color: "green" }}>{text}</span>;
                },
            }
        ]

        return (<div className='common-stock-result'>
            <Row gutter={5}>
                <Col span={14}>
                    <div className='common-title'><FormattedMessage id="option.scan.result" values={{ num: dataSource.length || 0 }} /></div>
                    <Table bordered size='small'
                        columns={columns}
                        dataSource={dataSource}
                        pagination={{ pageSize: 10 }}
                        onRow={this.OnSelectShowChart}
                        rowClassName={this.setRowClassName}
                        scroll={{ x: 1000 }} />
                </Col>
                <Col span={10}>
                    <div className='common-title'>{languageMessages['combined.profit.and.loss.curve']}</div>
                    <div id="options" style={{ height: chartHeight }}></div>
                </Col>
            </Row>
        </div>)
    }
}

export { OptionResultPage as default }