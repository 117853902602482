import { put, call, takeLatest, takeEvery } from 'redux-saga/effects'
import { post, get } from '../../helpers/server'

function* sendLoginCaptcha(action) {
    try {
        yield put({ type: 'captcha_commit' });
        const resp = yield call(post, "/captcha/login", action.payload);
        yield put({ type: "get_captcha", status: resp.status });
    } catch (e) {
        yield put({ type: "get_captcha", status: 0 });
    }
}

function* sendRegisterCaptcha(action) {
    try {
        yield put({ type: 'captcha_commit' });
        const resp = yield call(post, "/captcha/register", action.payload);
        yield put({ type: "get_captcha", status: resp.status });
    } catch (e) {
        yield put({ type: "get_captcha", status: 0 });
    }
}

function* sendForgetCaptcha(action) {
    try {
        yield put({ type: 'captcha_commit' });
        const resp = yield call(post, "/captcha/forget", action.payload);
        yield put({ type: "get_captcha", status: resp.status });
    } catch (e) {
        yield put({ type: "get_captcha", status: 0 });
    }
}

function* Login(action) {
    try {
        yield put({ type: 'login_commit' });
        const resp = yield call(post, "/users/login", action.payload);
        if (resp && resp.status === 1) {
            sessionStorage.setItem("auth", JSON.stringify(resp.user));
            sessionStorage.setItem("ValueAddedServiceCache", JSON.stringify(resp.vs));
            if (resp.cs !== null) {
                sessionStorage.setItem("CustomStrategyCache", JSON.stringify(resp.cs));
            }
            if (resp.sg !== null) {
                sessionStorage.setItem("CustomStockGroupsCache", JSON.stringify(resp.sg));
            }
            //sessionStorage.setItem("LuisStringCache", JSON.stringify(resp.luisString));
            //sessionStorage.setItem("SymbolItemsCache", JSON.stringify(resp.symbolItems));
            //sessionStorage.setItem("ConceptsCache", JSON.stringify(resp.concepts));
            //sessionStorage.setItem("ConceptExplanationsCache", JSON.stringify(resp.conceptExplanations));

            if (resp.ff !== null) {
                sessionStorage.setItem("FilterFunctionsCache", JSON.stringify(resp.ff));
            }
            if (resp.coupon !== null) {
                sessionStorage.setItem("CouponCache", JSON.stringify(resp.coupon));
            }

            if (resp.tn === null || resp.tn === undefined) {
                resp.tn = 0;
            }
            sessionStorage.setItem("TriggerNumberCache", resp.tn);

            localStorage.removeItem("auth");
            if (action.payload.RememberMe) {
                localStorage.setItem("auth", JSON.stringify({ username: resp.user.username, password: resp.user.password }));
            }

            yield put({ type: 'login_success', user: resp.user, token: resp.user.token });
        } else {
            yield put({ type: 'login_failed', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "login_failed", status: 0 });
    }
}

function* Register(action) {
    try {
        yield put({ type: 'register_commit' });
        const resp = yield call(post, "/users/register", action.payload);
        if (resp && resp.status === 1) {
            sessionStorage.setItem("auth", JSON.stringify(resp.user));
            sessionStorage.setItem("ValueAddedServiceCache", JSON.stringify(resp.vs));
            if (resp.cs !== null) {
                sessionStorage.setItem("CustomStrategyCache", JSON.stringify(resp.cs));
            }
            if (resp.sg !== null) {
                sessionStorage.setItem("CustomStockGroupsCache", JSON.stringify(resp.sg));
            }
            //sessionStorage.setItem("LuisStringCache", JSON.stringify(resp.luisString));
            //sessionStorage.setItem("SymbolItemsCache", JSON.stringify(resp.symbolItems));
            //sessionStorage.setItem("ConceptsCache", JSON.stringify(resp.concepts));
            //sessionStorage.setItem("ConceptExplanationsCache", JSON.stringify(resp.conceptExplanations));
            if (resp.ff !== null) {
                sessionStorage.setItem("FilterFunctionsCache", JSON.stringify(resp.ff));
            }
            if (resp.coupon !== null) {
                sessionStorage.setItem("CouponCache", JSON.stringify(resp.coupon));
            }

            if (resp.tn === null || resp.tn === undefined) {
                resp.tn = 0;
            }
            sessionStorage.setItem("TriggerNumberCache", resp.tn);

            localStorage.removeItem("auth");

            yield put({ type: 'register_success', user: resp.user, token: resp.user.token });
        } else {
            yield put({ type: 'register_failed', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "register_failed", status: 0 });
    }
}

function* Forget(action) {
    try {
        yield put({ type: 'forget_commit' });
        const resp = yield call(post, "/users/forget", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'forget_success' });
        } else {
            yield put({ type: 'forget_failed', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "forget_failed", status: 0 });
    }
}

function* getPermission(action) {
    try {
        const resp = yield call(get, "/member/permission?u=" + action.payload.UserId);
        if (resp && resp.status === 1) {
            yield put({ type: 'get_permission_success', permission: resp.p, isVip: resp.Vp });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'get_permission_failed', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "get_permission_failed", status: 0 });
    }
}

function* payment(action) {
    try {
        yield put({ type: "payment_commit" });
        const resp = yield call(post, "/member/payment", action.payload);
        if (resp && resp.status === 1) {
            if (resp.coupon !== null) {
                sessionStorage.setItem("CouponCache", JSON.stringify(resp.coupon));
            }
            yield put({ type: 'payment_success', permission: resp.permission });
        } else {
            if (resp.status === 3) {
                if (resp.coupon !== null) {
                    sessionStorage.setItem("CouponCache", JSON.stringify(resp.coupon));
                }
            }
            if (resp.status === 10) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'payment_failed', status: resp.status, permission: resp.permission });
        }
    } catch (e) {
        yield put({ type: "payment_failed", status: 0 });
    }
}

function* updateUser(action) {
    try {
        const resp = yield call(post, "/member/updateUser", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'update_user_success', user: resp.user });
        } else {
            if (resp.status === 3) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'update_user_failed', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "update_user_failed", status: 0 });
    }
}

function* getAccount(action) {
    try {
        const resp = yield call(post, "/member/account", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'get_account_success', accounts: resp.accounts });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'get_account_failed', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "get_account_failed", status: 0 });
    }
}

function* getSubscription(action) {
    try {
        const resp = yield call(post, "/member/subscription", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'get_subscription_success', sc: resp.sc });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'get_subscription_failed', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "get_subscription_failed", status: 0 });
    }
}

function* cancelSubscription(action) {
    try {
        const resp = yield call(post, "/billing/cancelSubscription", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'cancel_subscription_success', i: action.payload.i });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'cancel_subscription_failed', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "cancel_subscription_failed", status: 0 });
    }
}

function* getMessage(action) {
    try {
        const resp = yield call(get, "/member/message?userName=" + action.payload.UserId);
        if (resp && resp.status === 1) {
            yield put({ type: 'get_message_success', messages: resp.messages });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'get_message_failed', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "get_message_failed", status: 0 });
    }
}

function* sendMessage(action) {
    try {
        const resp = yield call(post, "/member/sendMessage", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'send_message_success' });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'send_message_failed', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "send_message_failed", status: 0 });
    }
}

function* updatePassword(action) {
    try {
        yield put({ type: "updatePassword_commit" });
        const resp = yield call(post, "/member/updatePassword", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'update_password_success', user: resp.user });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'update_password_failed', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "update_password_failed", status: 0 });
    }
}

function* phoneCaptcha(action) {
    try {
        const resp = yield call(post, "/captcha/bind", action.payload);
        yield put({ type: "get_phone_captcha", status: resp.status });
    } catch (e) {
        yield put({ type: "get_phone_captcha", status: 0 });
    }
}

function* bindPhone(action) {
    try {
        yield put({ type: "bindPhone_commit" });
        const resp = yield call(post, "/member/bindPhone", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'bind_phone_success', user: resp.user });
        } else {
            if (resp.status === 3) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'bind_phone_failed', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "bind_phone_failed", status: 0 });
    }
}

function* coupon(action) {
    try {
        const resp = yield call(get, "/member/coupon?userName=" + action.payload.UserId);
        if (resp && resp.status === 1) {
            yield put({ type: 'get_coupon_success', coupon: resp.coupon });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'get_coupon_failed', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "get_coupon_failed", status: 0 });
    }
}

function* Logout(action) {
    try {
        const resp = yield call(post, "/users/logout", action.payload);
        if (resp && resp.status === 1) {
            sessionStorage.clear();

            yield put({ type: 'logout_success' });
        } else {
            yield put({ type: 'logout_failed' });
        }
    } catch (e) {
        yield put({ type: "logout_failed", status: 0 });
    }
}

function* sendHubMessage(action) {
    try {
        yield put({ type: 'sendHubMessage', data: action.payload });
    } catch (e) {
        console.log(e);
    }
}

function* member() {
    yield takeLatest('action_login_captcha', sendLoginCaptcha);
    yield takeLatest('action_register_captcha', sendRegisterCaptcha);
    yield takeLatest('action_forget_captcha', sendForgetCaptcha);

    yield takeEvery('action_login', Login);
    yield takeEvery('action_register', Register);
    yield takeEvery('action_forget', Forget);


    yield takeLatest('action_get_permission', getPermission);

    yield takeLatest('action_payment', payment);
    
    yield takeLatest('action_update_user', updateUser);
    yield takeLatest('action_get_account', getAccount);
    yield takeLatest('action_get_message', getMessage);

    yield takeLatest('action_get_subscription', getSubscription);
    yield takeLatest('action_cancel_subscription', cancelSubscription);
    

    yield takeLatest('action_send_message', sendMessage);

    yield takeLatest('action_update_password', updatePassword);

    yield takeLatest('action_phone_captcha', phoneCaptcha);

    yield takeLatest('action_bind_phone', bindPhone);

    yield takeLatest('action_get_coupon', coupon);

    yield takeLatest('action_logout', Logout);

    yield takeLatest('action_send_hub_message', sendHubMessage);
}

export default member;