export default function hawkeye(state = {}, action) {
    switch (action.type) {
        case "get_symbol_items_success":
            return { ...state, status: 'get_symbol_items_success', symbolItems: action.symbolItems };
        case "get_symbol_items_fail":
            return { ...state, status: 'get_symbol_items_fail', state: action.status };

        case "StockTick_success":
            return { ...state, status: 'StockTick_success', symbolList: action.symbolList };
        case "StockTick_fail":
            return { ...state, status: 'StockTick_fail', state: action.status };
            
        case "get_hawkeye_info_success":
            return { ...state, status: 'get_hawkeye_info_success', luString: action.ls, nextDate: action.nd, permission: action.p };
        case "get_hawkeye_info_fail":
            return { ...state, status: 'get_hawkeye_info_fail', state: action.status };

        case "get_hawkeye_success":
            return { ...state, status: 'get_hawkeye_success', items: action.items };
        case "get_hawkeye_fail":
            return { ...state, status: 'get_hawkeye_fail', state: action.status };

        case "remove_hawkeye_success":
            return { ...state, status: 'remove_hawkeye_success', hawkeyeId: action.hawkeyeId };
        case "remove_hawkeye_fail":
            return { ...state, status: 'remove_hawkeye_fail', state: action.status };

        case "get_hawkeye_base":
            return { ...state, status: 'get_hawkeye_base', data: action.data };

        case "hawkeye_change_batch_success":
            return { ...state, status: "hawkeye_change_batch_success", luString: action.luString };
        case "hawkeye_change_batch_fail":
            return { ...state, status: "hawkeye_change_batch_fail", state: action.status }

        case "hawkeye_change_condition_success":
            return { ...state, status: "hawkeye_change_condition_success", luString: action.luString, sentenceFormula: action.sentenceFormula };
        case "hawkeye_change_condition_fail":
            return { ...state, status: "hawkeye_change_condition_fail", state: action.status }

        case "luis_analysis_commit":
            return { ...state, status: "analysis_commit" };
        case "get_luis_analysis_success":
            return { ...state, status: "analysis_success", formulaString: action.formulaString };
        case "get_luis_analysis_fail":
            return { ...state, status: "analysis_fail", state: action.status };

        case "Use_formula_hawkeye":
            return { ...state, status: 'Use_formula_hawkeye', isLuisAnalysis: action.isLuisAnalysis, formulaName: action.formulaName, formulaString: action.formulaString, vernacularKey: action.vernacularKey };

        default:
            return state;
    }
}