import React, { Component } from 'react';
import { Layout, PageHeader, Tabs, Row, Col, Tag } from 'antd';

const { Content } = Layout;
const { TabPane } = Tabs;
const { CheckableTag } = Tag;

var languageMessages = window.appLocale.messages;

class VideoPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeKey: sessionStorage.getItem("activeVideoKey") || '0',

            selectedKeys: '1'
        };
    }

    onSelectInSyncProduct = () => {
        this.setState({ selectedKeys: '1' });
    }

    onSelectCrystalTradeProduct = () => {
        this.setState({ selectedKeys: '2' });
    }

    onChangeItems = activeKey => {
        sessionStorage.setItem("activeVideoKey", activeKey);
        this.setState({ activeKey });
    }

    renderInsync() {
        return <Tabs activeKey={this.state.activeKey} type="card" onChange={this.onChangeItems}>
            <TabPane tab={languageMessages['videoTitle01']} key="0">
                <Row gutter={24} justify="center">
                    <Col span={6}>
                        <div className="common-video">
                            <iframe src='//player.youku.com/embed/XNDQzMjYxMDQ2OA==' frameBorder="0" allowFullScreen></iframe>
                        </div>
                        <p className='common-video-title'>{languageMessages['videoCont0101']}</p>
                    </Col>
                    <Col span={6} >
                        <div className="common-video">
                            <iframe src='//player.youku.com/embed/XNDQzMjYzOTgxNg==' frameBorder="0" allowFullScreen></iframe>
                        </div>
                        <p className='common-video-title'>{languageMessages['videoCont0102']}</p>
                    </Col>
                    <Col span={6} >
                        <div className="common-video">
                            <iframe src='//player.youku.com/embed/XNDQzMzUyMTAwNA==' frameBorder="0" allowFullScreen></iframe>
                        </div>
                        <p className='common-video-title'>{languageMessages['videoCont0103']}</p>
                    </Col>
                    <Col span={6} >
                        <div className="common-video">
                            <iframe src='//player.youku.com/embed/XNDQzMjYxMTMwMA==' frameBorder="0" allowFullScreen></iframe>
                        </div>
                        <p className='common-video-title'>{languageMessages['videoCont0104']}</p>
                    </Col>
                    <Col span={6} >
                        <div className="common-video">
                            <iframe src='//player.youku.com/embed/XNDQzMzUyNDI4NA==' frameBorder="0" allowFullScreen></iframe>
                        </div>
                        <p className='common-video-title'>{languageMessages['videoCont0105']}</p>
                    </Col>
                </Row>
            </TabPane>
            <TabPane tab={languageMessages['videoTitle02']} key="1">
                <Row gutter={24} justify="center">
                    <Col span={6} >
                        <div className="common-video">
                            <iframe src='//player.youku.com/embed/XNDQzMjY0MjQ5Ng==' frameBorder="0" allowFullScreen></iframe>
                        </div>
                        <p className='common-video-title'>{languageMessages['videoCont0201']}</p>
                    </Col>
                    <Col span={6} >
                        <div className="common-video">
                            <iframe src='//player.youku.com/embed/XNDQzMzUyMjM3Ng==' frameBorder="0" allowFullScreen></iframe>
                        </div>
                        <p className='common-video-title'>{languageMessages['videoCont0202']}</p>
                    </Col>
                    <Col span={6} >
                        <div className="common-video">
                            <iframe src='//player.youku.com/embed/XNDQzMjY0Mjk1Mg==' frameBorder="0" allowFullScreen></iframe>
                        </div>
                        <p className='common-video-title'>{languageMessages['videoCont0203']}</p>
                    </Col>
                </Row>
            </TabPane>
            <TabPane tab={languageMessages['videoTitle03']} key="2">
                <Row gutter={24} justify="center">
                    <Col span={6} >
                        <div className="common-video">
                            <iframe src='//player.youku.com/embed/XNDQzMzUyMjk3Mg==' frameBorder="0" allowFullScreen></iframe>
                        </div>
                        <p className='common-video-title'>{languageMessages['videoCont0301']}</p>
                    </Col>
                    <Col span={6} >
                        <div className="common-video">
                            <iframe src='//player.youku.com/embed/XNDQzMjYxMTU4NA==' frameBorder="0" allowFullScreen></iframe>
                        </div>
                        <p className='common-video-title'>{languageMessages['videoCont0302']}</p>
                    </Col>
                    <Col span={6} >
                        <div className="common-video">
                            <iframe src='//player.youku.com/embed/XNDU2NTk0NjkyMA==' frameBorder="0" allowFullScreen></iframe>
                        </div>
                        <p className='common-video-title'>{languageMessages['videoCont0303']}</p>
                    </Col>
                    <Col span={6} >
                        <div className="common-video">
                            <iframe src='//player.youku.com/embed/XNDUxMjYyMDkyMA==' frameBorder="0" allowFullScreen></iframe>
                        </div>
                        <p className='common-video-title'>{languageMessages['videoCont0304']}</p>
                    </Col>
                    <Col span={6} >
                        <div className="common-video">
                            <iframe src='//player.youku.com/embed/XNDQzMzUyMTk4NA==' frameBorder="0" allowFullScreen></iframe>
                        </div>
                        <p className='common-video-title'>{languageMessages['videoCont0305']}</p>
                    </Col>
                    <Col span={6} >
                        <div className="common-video">
                            <iframe src='//player.youku.com/embed/XNDQzMjY0MzE2MA==' frameBorder="0" allowFullScreen></iframe>
                        </div>
                        <p className='common-video-title'>{languageMessages['videoCont0306']}</p>
                    </Col>
                </Row>
            </TabPane>
        </Tabs>
    }

    renderCrystalTrade() {
        return <Tabs activeKey={'0'} type="card">
            <TabPane tab={languageMessages['videoTitle04']} key="0">
                <Row gutter={24} justify="center">
                    <Col span={6}>
                        <div className="common-video">
                            <iframe src='//player.youku.com/embed/XNDUxMjYyMDQ2OA==' frameBorder="0" allowFullScreen></iframe>
                        </div>
                        <p className='common-video-title'>{languageMessages['videoCont0401']}</p>
                    </Col>
                    <Col span={6} >
                        <div className="common-video">
                            <iframe src='//player.youku.com/embed/XNDU1MTExNDAxMg==' frameBorder="0" allowFullScreen></iframe>
                        </div>
                        <p className='common-video-title'>{languageMessages['videoCont0402']}</p>
                    </Col>
                </Row>
            </TabPane>
        </Tabs>
    }

    render() {
        return (<Content className='main-container'>
            <div className='common-page-header'>
                <PageHeader title={languageMessages['selProduct']}
                    tags={[<CheckableTag key="1" onChange={this.onSelectInSyncProduct} checked={this.state.selectedKeys === '1'}>InSync</CheckableTag>,
                    <CheckableTag key="2" onChange={this.onSelectCrystalTradeProduct} checked={this.state.selectedKeys === '2'}>CrystalTrade</CheckableTag>]}
                />
            </div>
            <Layout className='sub-tabs-content'>
                <div className="card-container common-tabs">
                    {this.state.selectedKeys === '1' ? this.renderInsync() : this.renderCrystalTrade()}
                </div>
            </Layout>
        </Content>)
    }
}

export { VideoPage as default }