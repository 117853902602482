import React, { Component } from 'react';
import { Card, Button } from 'antd';
import history from '../../helpers/history'

import { FormattedMessage, defineMessages } from 'react-intl';

const messages = defineMessages({
    forgetPasswordSuccess: {
        id: 'intl.forgetPasswordSuccess',
        defaultMessage: '重置密码成功！',
    },
    goToLogin: {
        id: 'intl.login.goToLogin',
        defaultMessage: '请前往登录',
    }
});

class ForgetSuccess extends Component {
    constructor(props) {
        super(props);
    }

    handleGoToLogin = e => {
        e.preventDefault();

        history.push("/login");
    }

    render() {
        return (<Card style={{ width: 380 }}>
            <p style={{ color: 'green', fontSize: '18px', textAlign: 'center' }}><FormattedMessage {...messages.forgetPasswordSuccess} /></p>
            <Button type="primary" onClick={this.handleGoToLogin} className='register-form-button'><FormattedMessage {...messages.goToLogin} /></Button>
            </Card>
        )
    }
}

export { ForgetSuccess as default }