import React from 'react';
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import { Layout, Table, Divider, Select, Button, message } from 'antd';

import CardSection from './CardSection';

const { Content } = Layout;
const { Option } = Select;

var languageMessages = window.appLocale.messages;
class PaymentForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSource: [],

            months: 12,
            couponId: 0,

            amount: 0,
            currency: 'usd',

            isVip: false,

            userName: '',
            email: '',

            hisSubscriptions: [],
            subscribed: [],


            payProcessing: false,
        }
    }

    componentWillReceiveProps(props) {
        var { data, stripe } = props;

        switch (data.status) {
            case 'next_processing':
                let isVip = data.isVip === undefined ? false : data.isVip;

                let months = this.state.months;

                let hisSubscriptions = [];
                if (data.hisSubscription !== undefined) {
                    for (let i in data.hisSubscription) {
                        for (let j in data.hisSubscription[i]) {
                            hisSubscriptions.push(data.hisSubscription[i][j].i);
                        }
                    }
                }

                let subscribed = [];
                let dataSource = [];
                if (data.vService !== undefined && data.vService !== null) {
                    for (let kk in data.vService) {
                        let total = this.formatPrice(this.multiply(data.vService[kk].price, months));
                        if (isVip && data.vService[kk].specialPrice > 0) total = this.formatPrice(this.multiply(data.vService[kk].specialPrice, months));

                        let Status = hisSubscriptions.filter(function(n){ return n === data.vService[kk].type });
                        if (Status.length > 0) {
                            subscribed.push(Status[0]);
                        }

                        dataSource.push({
                            key: data.vService[kk].type,
                            title: data.vService[kk].title,
                            unitPrice: this.formatPrice(data.vService[kk].price),
                            specialOffer: data.vService[kk].specialPrice === 0 ? '' : this.formatPrice(data.vService[kk].specialPrice),
                            months: months,
                            total: total,
                            Status: Status,
                        });
                    }
                }

                let email = '';
                if (data.account !== undefined && data.account !== null) {
                    email = data.account;
                }

                this.setState({ isVip, dataSource, email, hisSubscriptions, subscribed }, () => {
                    this.changeAmount(months, this.state.couponId);
                });
                break;
            case 'create_subscription_success':
                let isSuccess = true;

                var paymentIntent = data.subscription.latest_invoice.payment_intent;
                
                if (paymentIntent !== null) {
                    if (paymentIntent.status === 'requires_action') {
                        stripe.confirmCardPayment(paymentIntent.client_secret, {
                            payment_method: this.paymentMethodId,
                        }).then((result) => {
                            if (result.error) {
                                // start code flow to handle updating the payment details Display error message in your UI.
                                // The card was declined (i.e. insufficient funds, card has expired, etc)
                                message.error(result.error);
                                isSuccess = false;
                            } else {
                                if (result.paymentIntent.status === 'succeeded') {
                                    // There's a risk of the customer closing the window before callback
                                    // execution. To handle this case, set up a webhook endpoint and
                                    // listen to invoice.payment_succeeded. This webhook endpoint
                                    // returns an Invoice.

                                    //return { priceIds: priceIds, subscription: subscription, invoice: invoice, paymentMethodId: paymentMethodId, };
                                }
                            }
                        });
                    }

                    if (isSuccess && paymentIntent.status === 'requires_payment_method') {
                        // Using localStorage to store the state of the retry here
                        // (feel free to replace with what you prefer)
                        // Store the latest invoice ID and status
                        localStorage.setItem('latestInvoiceId', data.subscription.latest_invoice.id);
                        localStorage.setItem('latestInvoicePaymentIntentStatus', data.subscription.latest_invoice.payment_intent.status);
                        message.error('Your card was declined.');

                        isSuccess = false;
                    }
                    
                }

                //success
                if (data.permission !== undefined && data.permission !== null) {
                    let user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
                    if (user.permission !== undefined) {
                        user.permission = data.permission;
                        sessionStorage.setItem("auth", JSON.stringify(user));
                    }
                }
                if (data.coupons !== undefined && data.coupons !== null) {
                    sessionStorage.setItem("CouponCache", JSON.stringify(data.coupons));
                }

                this.setState({ payProcessing: false });
                break;
            case 'create_subscription_failed':
                if (data.coupons !== undefined && data.coupons !== null) {
                    sessionStorage.setItem("CouponCache", JSON.stringify(data.coupons));
                }
                this.setState({ payProcessing: false });
                break;
            case 'retry_invoice_success':
                var paymentIntent = data.invoice.payment_intent;

                if (paymentIntent !== null) {
                    if (paymentIntent.status === 'requires_action' || paymentIntent.status === 'requires_payment_method') {
                        stripe.confirmCardPayment(paymentIntent.client_secret, {
                            payment_method: this.paymentMethodId,
                        }).then((result) => {
                            if (result.error) {
                                // start code flow to handle updating the payment details Display error message in your UI.
                                // The card was declined (i.e. insufficient funds, card has expired, etc)
                                message.error(result.error);
                            } else {
                                if (result.paymentIntent.status === 'succeeded') {
                                    // There's a risk of the customer closing the window before callback
                                    // execution. To handle this case, set up a webhook endpoint and
                                    // listen to invoice.payment_succeeded. This webhook endpoint
                                    // returns an Invoice.

                                    //return { priceIds: priceIds, subscription: subscription, invoice: invoice, paymentMethodId: paymentMethodId, };
                                }
                            }
                        });
                    }
                }

                if (data.invoice) {
                    //const subscription = { id: data.invoice.subscription };
                    localStorage.clear();
                }

                this.setState({ payProcessing: false });
                break;
            case 'retry_invoice_failed':
                if (data.coupons !== undefined && data.coupons !== null) {
                    sessionStorage.setItem("CouponCache", JSON.stringify(data.coupons));
                }
                this.setState({ payProcessing: false });
                break;
        }
    }

    retryInvoiceWithNewPaymentMethod = (paymentMethodId, invoiceId) => {
        let user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        if (user.username === undefined) {
            this.setState({ payProcessing: false }, () => {
                this.props.data.dispatch({ type: "action_non_user" });
            });
        } else {
            let { amount, months, currency, couponId } = this.state;

            this.props.data.dispatch({
                type: "action_retry_invoice",
                payload: {
                    pmi: paymentMethodId,
                    a: this.multiply(amount, 100),
                    c: currency,
                    e: this.state.email,
                    i: invoiceId,
                    m: months,
                    n: couponId,
                    u: user.username,
                }
            });
        }
    }

    createSubscription = (paymentMethodId) => {
        let user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        if (user.username === undefined) {
            this.setState({ payProcessing: false }, () => {
                this.props.data.dispatch({ type: "action_non_user" });
            });
        } else {
            let { dataSource, amount, months, currency, couponId } = this.state;

            const product = [];
            for (let kk in dataSource) {
                product.push({ t: dataSource[kk].key });
            }

            this.props.data.dispatch({
                type: "action_create_subscription",
                payload: {
                    pmi: paymentMethodId,
                    a: this.multiply(amount, 100),
                    c: currency,
                    d: product,
                    e: this.state.email,
                    m: months,
                    n: couponId,
                    u: user.username,
                }
            });
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        let user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        if (user.username === undefined) {
            this.props.data.dispatch({ type: "action_non_user" });
        } else {
            const { stripe, elements } = this.props

            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return;
            }

            this.setState({ payProcessing: true }, async () => {
                // Use your card Element with other Stripe.js APIs
                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: elements.getElement(CardElement),
                });

                if (error) {
                    message.error(`[createPaymentMethod error] ${error}`);
                    this.setState({ payProcessing: false });
                    return;
                }

                this.paymentMethodId = paymentMethod.id;
                // If a previous payment was attempted, get the lastest invoice
                const latestInvoicePaymentIntentStatus = localStorage.getItem('latestInvoicePaymentIntentStatus');
                if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
                    // Update the payment method and retry invoice payment
                    const invoiceId = localStorage.getItem('latestInvoiceId');
                    this.retryInvoiceWithNewPaymentMethod(this.paymentMethodId, invoiceId);
                    return;
                }

                // Create the subscription
                this.createSubscription(this.paymentMethodId);
            });
        }
    };

    add = (a, b) => {
        var r1, r2, m;
        try { r1 = a.toString().split(".")[1].length } catch (e) { r1 = 0 }
        try { r2 = b.toString().split(".")[1].length } catch (e) { r2 = 0 }
        m = Math.pow(10, Math.max(r1, r2));
        return (a * m + b * m) / m;
    }

    multiply = (a, b) => {
        let m = 0, c = a.toString(), d = b.toString();

        try {
            m += c.split(".")[1].length
        } catch (e) { }

        try {
            m += d.split(".")[1].length
        } catch (e) { }
        return Number(c.replace(".", "")) * Number(d.replace(".", "")) / Math.pow(10, m);
    }

    formatPrice(amount) {
        let price = amount;//(amount / 100).toFixed(2);
        let numberFormat = new Intl.NumberFormat(['en-US'], {
            style: 'currency',
            currency: this.state.currency,
            currencyDisplay: 'symbol',
        });
        return numberFormat.format(price);
    }

    //选择套餐 折扣和优惠
    onSelectMon = months => {
        this.setState({ months }, () => {
            this.changeAmount(months, this.state.couponId);
        });
    }

    //选择优惠券
    onSelectCoupon = couponId => {
        this.changeAmount(this.state.months, couponId);
    }

    getMonthsByAmount(isVip, type, months) {
        let amount = 0;

        let vService = this.props.data.vService.filter(function (item) { return item.type === type });
        if (vService.length > 0) {
            let price = vService[0].price;
            let specialPrice = vService[0].specialPrice;

            let discounts = vService[0].discount.split('|');

            //TODO：
            if (isVip) {
                let discount = 0;
                if (discounts.length > 1) {
                    discount = discounts[1].split(';');
                } else {
                    discount = discounts[0].split(';');
                }
                amount = this.getOrderTotalPrice(discount, months);
            } else {
                var discount = discounts[0].split(';');
                amount = this.getOrderTotalPrice(discount, months);
            }

            if (amount === 0) {
                if (isVip && specialPrice > 0) {
                    amount = this.multiply(specialPrice, months);
                } else {
                    amount = this.multiply(price, months);
                }
            }
        }

        return amount;
    }

    changeAmount(months, couponId) {
        let { isVip, dataSource } = this.state;
        
        let amount = 0;
        for (let kk in dataSource) {
            let type = dataSource[kk].key;

            let total = this.getMonthsByAmount(isVip, type, months);

            if (couponId > 0) {
                var couponSource = [];
                var coupons = JSON.parse(sessionStorage.getItem("CouponCache") || '{}') || {};
                if (coupons.dataSource !== undefined) {
                    couponSource = coupons.dataSource;
                }

                var coupon = couponSource.filter((e) => { return e.id === couponId && e.type === type });
                if (coupon.length > 0) total = this.multiply(total, coupon[0].discount);
            }

            amount = this.add(amount, total);

            dataSource[kk].months = months;
            dataSource[kk].total = this.formatPrice(total);
        }
        
        this.setState({ amount, dataSource, couponId });
    }

    getOrderTotalPrice(discount, months) {
        var total = 0;
        if (discount.length > 0) {
            for (var kk in discount) {
                var d = discount[kk].split(',');
                if (d.length > 0) {
                    if (d[0].toString() === months.toString()) {
                        total = parseFloat(d[1]);
                        break;
                    }
                }
            }
        }
        return total;
    }

    onClickClose = e => {
        let tags = e.currentTarget.getAttribute('data-value');
        let type = parseInt(tags);

        let { dataSource, subscribed } = this.state;
        if (dataSource.length > 0) {
            dataSource = dataSource.filter(function (item) { return item.key !== type });
            subscribed = subscribed.filter(function (n) { return n !== type });
            this.setState({ dataSource, subscribed }, () => {
                this.changeAmount(this.state.months, this.state.couponId);
            });
        }
    }

    onNameChange = e => {
        let userName = e.target.value;
        this.setState({ userName });
    }

    SetCoupon() {
        var couponSource = [];
        var coupon = JSON.parse(sessionStorage.getItem("CouponCache") || '{}') || {};
        if (coupon.dataSource !== undefined) {
            couponSource = coupon.dataSource;
        }
        //var coupons = couponSource.filter((n) => n.type === type || n.type === 0);
        return (<Select size='small' value={this.state.couponId} style={{ width: 180 }}
            onSelect={this.onSelectCoupon}
            dropdownStyle={{ zIndex: '1' }}>
            {couponSource.map(item => {
                return (<Option key={item.id} value={item.id}>{item.name}</Option>)
            })}
            {couponSource.length > 0 ? (<Option key='0' value={0}>{languageMessages['do.not.use']}</Option>) : (<Option key='0' value={0}>{languageMessages['no.available']}</Option>)}
        </Select>);
    }
    
    renderMonths() {
        return <Select size='small' value={this.state.months} style={{ width: 180 }}
                onSelect={this.onSelectMon} dropdownStyle={{ zIndex: '1' }}>
                <Option value={1}>1</Option>
                <Option value={2}>2</Option>
                <Option value={3}>3</Option>
                <Option value={4}>4</Option>
                <Option value={5}>5</Option>
                <Option value={6}>6</Option>
                <Option value={7}>7</Option>
                <Option value={8}>8</Option>
                <Option value={9}>9</Option>
                <Option value={10}>10</Option>
                <Option value={11}>11</Option>
                <Option value={12}>{languageMessages['1year.discount']}</Option>
                {
                    //   <Option value={24}>{languageMessages['2year.discount']}</Option>
                }
            </Select>
    }

    renderProduct() {
        const columns = [
            {
                title: '',
                key: 'Delete',
                width: 50,
                align: 'center',
                render: (text, record) => (
                    <span>
                        <Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value={record.key} />
                    </span>
                ),
            }, {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
            }, {
                title: 'Unit price',
                dataIndex: 'unitPrice',
                key: 'unitPrice',
                align: 'right',
                render: (text, record) => (
                    <b>{record.unitPrice}</b>
                ),
            }, {
                title: 'Special offer',
                dataIndex: 'specialOffer',
                key: 'specialOffer',
                align: 'right',
                render: (text, record) => (
                    record.specialOffer === '' ? '--' : <b>{record.specialOffer}</b>
                ),
            }, {
                title: 'Months',
                key: 'months',
                dataIndex: 'months',
                align: 'right',
            }, {
                title: 'Total',
                key: 'total',
                dataIndex: 'total',
                align: 'right',
                render: (text, record) => (
                    <b>{record.total}</b>
                ),
            }, {
                title: 'Status',
                key: 'Status',
                dataIndex: 'Status',
                render: Status => (
                    <span>{
                        Status.length > 0 ? <span className='common-red'>{'Subscribed'}</span> : <span className='common-green'>{'Not subscribed'}</span>
                    }
                    </span>
                ),
            }
        ];

        return <Table size='small' bordered columns={columns} dataSource={this.state.dataSource} pagination={false} />;
    }

    renderBillInfo() {
        return <fieldset className="with-state" style={{ maxWidth: '600px' }}>
            <label>
                <span>Select months</span>
                <div className="field">{this.renderMonths()}</div>
            </label>
            <label>
                <span>Select coupon</span>
                <div className="field">{this.SetCoupon()}</div>
            </label>
            <label>
                <span>Name</span>
                <input name="name" value={this.state.userName} onChange={this.onNameChange} className="field" placeholder="First and last name" required />
            </label>
            <label>
                <span>Email</span>
                <input name="email" value={this.state.email} type="email" className="field" placeholder="Email address" readOnly />
            </label>
        </fieldset>
    }

    render() {
        let disabled = !this.props.stripe || this.state.payProcessing || this.state.subscribed.length > 0 || this.state.dataSource.length <= 0;

        return (<Content className='card-section'>
            <div className='common-title'><Divider type="vertical" />{'Billing information'}</div>
            {this.renderProduct()}

            {this.renderBillInfo()}
            <div className='common-title'><Divider type="vertical" />{'Payment information'}</div>
            <CardSection />
            <div className='common-total'>{languageMessages['subtotal']}： {this.formatPrice(this.state.amount)}</div>
            <Button className="ant-btn ant-btn-sm pay-btn" disabled={disabled} onClick={this.handleSubmit} loading={this.state.payProcessing}>
                {'Subscription'}
            </Button>
        </Content>);
    }
}

export default function InjectedCheckoutForm(data) {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <PaymentForm stripe={stripe} elements={elements} data={data} />
            )}
        </ElementsConsumer>
    );
}