import React, { Component } from 'react';
import { CSVLink } from "react-csv";
import { Table, PageHeader, Button, Input, Form, Menu, Dropdown, Icon, Modal } from 'antd';

import { FormattedMessage } from 'react-intl';

var languageMessages = window.appLocale.messages;

class StockResultPage extends Component {
    constructor(props) {
        super(props);

        this.csvData = [];

        this.myDate = new Date();
        this.fileName = 'scan_stock_result_'+this.myDate.getYear() +''+ this.myDate.getMonth() +''+ this.myDate.getDate() +''+ this.myDate.getHours() +''+ this.myDate.getMinutes() +''+ this.myDate.getSeconds() + ".csv";

        this.state = {
            selectedRowKeys: [],

            saveStock: false,

            validateStatus: '',
            validateMessage: '',

            modalVisible: false,

            stockName: '',
        };
    }

    componentWillReceiveProps(props) {
        if (props.status !== undefined && props.status === 'update_customStock_success') {
            this.setState({ modalVisible: false });
        }
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys, saveStock: selectedRowKeys.length > 0 ? true : false });
    };

    onJoinCustomStock = e => {
        let { scanAlert } = this.props;
        let { selectedRowKeys } = this.state;
        
        if (selectedRowKeys.length > 0) {
            var stockCount = selectedRowKeys.length;
            var groupId = e.key;

            var customStockGroups = JSON.parse(sessionStorage.getItem("CustomStockGroupsCache") || '[]') || [];
            var tmpCustomGroups = customStockGroups.filter(function (e) { return e.groupId == groupId });
            if (tmpCustomGroups.length > 0) {
                var symbolList = tmpCustomGroups[0].symbolList;
                for (var kk in selectedRowKeys) {
                    var stockItem = scanAlert.stockItems[selectedRowKeys[kk]];
                    if (symbolList.indexOf(stockItem.symbolCode) === -1) {
                        if (symbolList !== '') symbolList += ',';
                        symbolList += stockItem.symbolCode;
                    }
                }

                if (symbolList !== '') {
                    this.props.customStockEvent({ GroupId: groupId, GroupName: tmpCustomGroups[0].groupName, SymbolList: symbolList, StockCount: stockCount });
                }
            } else {
                var symbolList = '';
                for (var kk in selectedRowKeys) {
                    var stockItem = scanAlert.stockItems[selectedRowKeys[kk]];
                    if (symbolList.indexOf(stockItem.symbolCode) === -1) {
                        if (symbolList !== '') symbolList += ',';
                        symbolList += stockItem.symbolCode;
                    }
                }

                if (symbolList !== '') {
                    this.setState({
                        modalVisible: true,
                        stockName: '',

                        validateStatus: '',
                        validateMessage: '',
                    });
                }
            }
        }
    }

    handleSaveGroupName = () => {
        let { scanAlert } = this.props;
        let { stockName, selectedRowKeys } = this.state

        var customStockGroups = JSON.parse(sessionStorage.getItem("CustomStockGroupsCache") || '[]') || [];
        var tmpData = customStockGroups.filter(function (e) { return e.groupName == stockName; });
        if (tmpData.length > 0) {
            this.setState({
                validateStatus: 'error',
                validateMessage: languageMessages['stock.pool.name.already.exists'],
            });
        } else {
            if (selectedRowKeys.length > 0) {
                var stockCount = selectedRowKeys.length;
                var symbolList = '';
                for (var kk in selectedRowKeys) {
                    var stockItem = scanAlert.stockItems[selectedRowKeys[kk]];
                    if (symbolList.indexOf(stockItem.symbolCode) === -1) {
                        if (symbolList !== '') symbolList += ',';
                        symbolList += stockItem.symbolCode;
                    }
                }

                if (symbolList !== '') {
                    this.props.customStockEvent({ GroupId: 0, GroupName: stockName, SymbolList: symbolList, StockCount: stockCount });
                }
            }
        }
    };

    handleCancelGroupName = e => {
        this.setState({
            modalVisible: false,
            stockName: '',

            validateStatus: '',
            validateMessage: '',
        });
    };

    onChangeGroupName = e => {
        e.preventDefault();

        let stockName = e.target.value;
        if (stockName !== '') {
            stockName = stockName.slice(0, 20);

            var validateStatus = '';
            var validateMessage = '';
            var pattern = /^[A-Za-z0-9_\u4e00-\u9fa5]+$/gi;
            if (!pattern.test(stockName)) {
                validateStatus = 'error';
                validateMessage = languageMessages['stock.pool.name.format'];
            }

            this.setState({
                validateStatus: validateStatus,
                validateMessage: validateMessage,
                stockName: stockName
            });
        }
    }

    render() {
        let { scanAlert, stockPoolKey } = this.props;

        const rowSelection = {
            title: languageMessages['select.all'],
            selectedRowKeys: this.state.selectedRowKeys,
            //onClick: this.onSelectChange,
            onChange: this.onSelectChange
        }

        let columns = [
            {
                title: languageMessages['sequence'],
                dataIndex: 'sequence',
                width: 50
            }, {
                title: languageMessages['symbol.code'],
                dataIndex: 'symbolCode',
                sorter: (a, b) => a.symbolCode < b.symbolCode ? -1 : a.symbolCode > b.symbolCode ? 1 : 0,
                sortDirections: ['descend', 'ascend'],
            }, {
                title: languageMessages['symbol.name'],
                dataIndex: 'symbolName',
                sorter: (a, b) => a.symbolName < b.symbolName ? -1 : a.symbolName > b.symbolName ? 1 : 0,
                sortDirections: ['descend', 'ascend'],
            }, {
                title: languageMessages['latest.price'],
                dataIndex: '$LATEST_PRICE',
                sorter: (a, b) => a.$LATEST_PRICE - b.$LATEST_PRICE,
                align: 'right',
                sortDirections: ['descend', 'ascend'],
                render: (text, row, index) => {
                    return <span>{text}</span>;
                },
            }, {
                title: languageMessages['quote.change'],
                dataIndex: '$RANGE_PERCENT',
                sorter: (a, b) => a.$RANGE_PERCENT - b.$RANGE_PERCENT,
                align: 'right',
                sortDirections: ['descend', 'ascend'],
                render: (text, row, index) => {
                    if (row['$RANGE_PERCENT'] >= 0) {
                        return <span style={{ color: window.webType === 'US' ? 'green' : 'red' }}>{text}%</span>;
                    } else {
                        return <span style={{ color: window.webType === 'US' ? 'red' : 'green' }}>{text}%</span>;
                    }
                },
            }
        ]

        this.csvData = [];
        this.csvData.push([languageMessages['symbol.code'], languageMessages['symbol.code'], languageMessages['latest.price'], languageMessages['quote.change']]);

        var dataSource = [];
        if (scanAlert != null && scanAlert !== undefined) {
            var subject = scanAlert.title !== '' ? scanAlert.title.split(",") : [];
            if (subject.length > 0) {

                //获取变量因子
                /**
                subject.forEach((v, i) => {
                    if (v === '$LATEST_PRICE' || v === '$RANGE_PERCENT') {
                        var nn = v;
    
                        if (v === '$LATEST_PRICE') nn = '最新价';
                        if (v === '$RANGE_PERCENT') nn = '涨跌幅';
    
                        columns.push({
                            title: nn,
                            dataIndex: v,
                            sorter: (a, b) => a[v] - b[v],
                            sortDirections: ['descend', 'ascend'],
                            render: (text, row, index) => {
                                if (row['$RANGE_PERCENT'] >= 0) {
                                    return <span style={{ color: "red" }}>{text}</span>;
                                } else {
                                    return <span style={{ color: "green" }}>{text}</span>;
                                }
                            },
                        });
                    }
                })
                */
            }

            //获取每支股票的变量值
            scanAlert.stockItems.forEach((item, index) => {
                var row = {
                    key: index,
                    sequence: index + 1
                }

                //将每个变量值添加到row对象
                for (var kk in item) {
                    if (kk === 'symbolCode' || kk === 'symbolName') {
                        row[kk] = item[kk];
                    }

                    if (kk === '$LATEST_PRICE' || kk === '$RANGE_PERCENT') {
                        if (kk === '$RANGE_PERCENT' && item[kk] === '-1000000000') {
                            row[kk] = parseFloat(item[kk]).toFixed(2);
                        } else {
                            row[kk] = parseFloat(item[kk]).toFixed(2);
                        }
                    }
                }
                dataSource.push(row);
                this.csvData.push([row.symbolCode, row.symbolName, row.$LATEST_PRICE, row.$RANGE_PERCENT]);
            })
        }

        var customStockGroups = JSON.parse(sessionStorage.getItem("CustomStockGroupsCache") || '[]') || [];
        var customStockGroupData = [];
        if (customStockGroups.length > 0) {
            for (let key in customStockGroups) {
                customStockGroupData.push(<Menu.Item key={customStockGroups[key].groupId}>{customStockGroups[key].groupName}</Menu.Item>);
            }
        } else {
            customStockGroupData.push(<Menu.Item key='0'><Icon type="plus-circle" />{languageMessages['new.stock.pool']}</Menu.Item>);
        }

        let menu = (
            <Menu onClick={this.onJoinCustomStock}>
                {customStockGroupData}
            </Menu>
        );

        var formItemLayout = { labelCol: { xs: { span: 24 }, sm: { span: 4 }, }, wrapperCol: { xs: { span: 24 }, sm: { span: 20 }, }, };

        var txt = stockPoolKey === 'GENERAL_INDEX' ? languageMessages['index'] : stockPoolKey === 'FUND' ? languageMessages['fund'] : languageMessages['stock'];
        txt = txt.toLowerCase();
        return (<div className='common-stock-result'>
            <PageHeader
                title={(<p style={{ fontWeight: 'normal', fontSize: '14px', color: '#a2a2a2' }}>
                    <FormattedMessage id="eligible.stocks" values={{ num: dataSource.length || 0, name: txt }} />
                </p>)}
                extra={[
                    <Dropdown key="1" overlay={menu} disabled={!this.state.saveStock}><Button size='small'>{languageMessages['add.choice']}<Icon type="down" /></Button></Dropdown>,
                    <CSVLink key="2" data={this.csvData} filename={this.fileName} className="ant-btn ant-btn-sm" target="_blank">
                        <Icon type="download" /><span>{languageMessages['save.stock']}</span>
                    </CSVLink>
                ]} />
            <Modal title={languageMessages['new.stock.pool']}
                visible={this.state.modalVisible}
                maskClosable={false}
                onOk={this.handleSaveGroupName}
                confirmLoading={this.props.status === 'customStock_commit'}
                onCancel={this.handleCancelGroupName}>
                <Form {...formItemLayout}>
                    <Form.Item
                        label={languageMessages['stock.pool.name.form']}
                        hasFeedback
                        validateStatus={this.state.validateStatus}
                        help={this.state.validateMessage}>
                        <Input placeholder={languageMessages['stock.pool.name.placeholder']} id={this.state.validateStatus} value={this.state.stockName} onChange={this.onChangeGroupName} />
                    </Form.Item>
                </Form>
            </Modal>
            <Table rowSelection={rowSelection}
                bordered size='small'
                columns={columns}
                dataSource={dataSource}
                pagination={{ pageSize: 20 }} />
        </div>)
    }
}

export { StockResultPage as default }