import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Button, PageHeader, Divider, Spin, message } from 'antd';

import OptionParamsForm from '../../pannels/option/OptionParams';
import OptionResultPage from '../../pannels/option/OptionResultPage';

import emitter from '../Evt.js';

const { Content } = Layout;

var languageMessages = window.appLocale.messages;

class OptionPage extends Component {
    constructor(props) {
        super(props);

        this.user = {};
        this.state = {
            underlyingList: [],
            strategies: [],
            price: '0',

            lastTimes: 0,

            strategyScan: false,

            optionError: '',

            spinLoadding: true,
        };
    }

    componentDidMount() {
        emitter.addListener('initPage', this.init);
    }

    componentWillMount() {
        this.init();
    }

    componentWillUnmount() {
        emitter.removeListener('initPage', this.init);
    }

    init = () => {
        this.user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        /**
        if (user.username === undefined) {
            this.setState({ spinLoadding: false }, () => {
                this.props.dispatch({ type: "action_non_user" });
            });
        } else {
            
        }
        */
        this.props.dispatch({ type: "action_get_option", payload: { UserId: this.user.username } });
    }

    setUserPermission(permissions) {
        var lastTimes = 0;
        for (var key in permissions) {
            var permission = permissions[key];
            switch (permission.type) {
                case 'vo':
                    lastTimes += permission.totalCount - permission.usageCount;
                    break;
                case 'o':
                    lastTimes += permission.totalCount - permission.usageCount;
                    break;
            }
        }
        this.setState({ lastTimes });
    }

    componentWillReceiveProps(props) {
        var { status, state, underlyingList, scanResponse, permission, lastTimes } = props

        this.setState({
            strategies: [],
            strategyScan: false
        });

        switch (status) {
            case 'get_option_success':
                if (this.user.permission !== undefined) {
                    this.user.permission = permission;
                    sessionStorage.setItem("auth", JSON.stringify(this.user));
                    this.setUserPermission(this.user.permission);
                }

                //this.setState({ spinLoadding: false });
                this.setState({ underlyingList, spinLoadding: false });
                break;
            case 'get_option_fail':
                if (this.user.permission !== undefined) {
                    this.setUserPermission(this.user.permission);
                }
                var error = languageMessages['get.data.failed'];
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = languageMessages['user.exception'];
                        break;
                }
                message.error(error);
                this.setState({ spinLoadding: false });
                break;
            case 'option_scan_commit':
                this.setState({
                    strategyScan: true,
                });
                break;
            case 'option_scan_success':
                if (scanResponse !== undefined) {
                    if (scanResponse.error === null) {
                        this.user.permission = permission;
                        sessionStorage.setItem("auth", JSON.stringify(this.user));
                        
                        this.setState({
                            lastTimes: lastTimes,
                            strategies: scanResponse.strategies,
                            price: scanResponse.stockPrice,
                            optionError: '',
                        });
                    }
                }
                break;
            case 'option_scan_fail':
                var error = '';
                switch (state) {
                    case 0:
                        error = languageMessages['option.scan.exception'];
                        break;
                    case 2:
                        error = languageMessages['user.exception'];
                        break;
                    case 3:
                        error = languageMessages['option.scans.have.run.out'];
                        break;
                    case 4:
                        error = languageMessages['request.option.scan.failed'];
                        break;
                    case 5:
                        error = languageMessages['no.option.data.scanned'];
                        break;
                    default:
                        break;
                }
                this.setState({ optionError: error });
                break;
        }
    }

    onPerpleScan = () => {
        message.info(languageMessages['function.not.open']);
    }

    render() {
        return (<Content className='main-container total-content'>
            <Spin spinning={this.state.spinLoadding}>
                <div className='common-page-header'>
                    <PageHeader title={languageMessages['optionStrategyScan']}
                        extra={[
                            <Button key="1" size='small' onClick={this.onPerpleScan}>{languageMessages['personal.position.scan']}</Button>
                        ]} />
                </div>
                <Content style={{ padding: '0px 50px' }}>
                    <div className='common-title'><Divider type="vertical" />{languageMessages['parameter.settings']}</div>
                    <div style={{padding:'10px 0'}}>
                        <OptionParamsForm {...this.state} dispatch={this.props.dispatch} />
                    </div>
                </Content>
                <div style={{ backgroundColor: '#efefef', height:'24px' }}></div>
                <Content style={{ padding: '0px 50px', marginBottom: '30px' }}>
                    <OptionResultPage strategies={this.state.strategies} />
                </Content>
            </Spin>
        </Content>);
    }
}

function mapStateToProps(state) {
    return state.option;
}

const page = connect(mapStateToProps)(OptionPage);
export { page as default }