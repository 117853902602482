import React, { Component } from 'react';
import history from '../../../helpers/history';
import { Form, Input, Select, Checkbox, AutoComplete, DatePicker, Radio, Icon } from 'antd';
import moment from 'moment';

import emitter from '../../Evt.js';

var moment_timezone = require('moment-timezone');

const { Option } = Select;

var languageMessages = window.appLocale.messages;

var format = 'YYYY-MM-DD HH:mm';
function range(start, end) {
    const result = [];
    for (var i = start; i < end; i++) {
        result.push(i);
    }
    return result;
}

const closeShort = 'Close Short';
const closeLong = 'Close Long';

const closeTime = { 'CN': '15:00', 'US': '16:00' };
class BaseInfo extends Component {
    constructor(props) {
        super(props);

        window.webType === 'US' ? moment_timezone.tz.setDefault("America/New_York") : moment_timezone.tz.setDefault("Asia/Shanghai");

        this.user = {};
        this.state = {
            keywords: '',
            symbolItems: [],

            symbolCode: '',
            symbolName: '',

            symbolStatus: '',
            symbolMessage: '',

            direction: closeShort,

            tradeQuantity: 1000,

            tradeQuantityStatus: '',
            tradeQuantityMessage: '',

            startDate: moment().format(format),
            endDate: moment().format('YYYY-MM-DD') + ' ' + closeTime[window.webType],
            nextDate: '',

            tradeDateStatus: '',
            tradeDateMessage: '',

            latestPrice: 0,
            quoteChange: 0,

            endOpen: false,

            isVip: false,
            customEffectiveTime: false
        };
    }

    componentDidMount() {
        emitter.addListener('submitHawkeye', this.submitHawkeye);
    }

    componentWillMount() {
        //this.init();
    }

    init = () => {
        /**
        var tradeDateStatus = '';
        var tradeDateMessage = '';

        var oDate1 = new Date(startDate);
        var oDate2 = new Date(endDate);
        if (oDate1.getTime() > oDate2.getTime()) {
            tradeDateStatus = 'error';
            tradeDateMessage = '开始日期不能大于结束日期';
        }
        */
    }

    submitHawkeye = (lastTimes, permission) => {
        if (lastTimes !== undefined && permission !== undefined) {

            var endDate = moment().format('YYYY-MM-DD') + ' ' + closeTime[window.webType];
            if (this.state.nextDate !== '') {
                endDate = moment(this.state.nextDate).format('YYYY-MM-DD') + ' ' + closeTime[window.webType];
            }

            //submit success
            this.setState({
                symbolItems: [],

                symbolCode: '',
                symbolName: '',
                direction: closeShort,
                tradeQuantity: 1000,
                startDate: moment().format('YYYY-MM-DD HH:mm'),
                endDate: endDate,

                latestPrice: 0,
                quoteChange: 0,
            });
        }
    }

    componentWillUnmount() {
        emitter.removeListener('submitHawkeye', this.submitHawkeye);
    }

    componentWillReceiveProps(props) {
        var { status, nextDate, symbolItems } = props;

        switch (status) {
            case 'get_hawkeye_info_success':
                if (nextDate !== undefined) {
                    var endDate = moment(nextDate).format('YYYY-MM-DD') + ' ' + closeTime[window.webType];

                    var tradeDateStatus = '';
                    var tradeDateMessage = '';

                    var oDate1 = new Date(this.state.startDate);
                    var oDate2 = new Date(endDate);
                    if (oDate1.getTime() > oDate2.getTime()) {
                        tradeDateStatus = 'error';
                        tradeDateMessage = languageMessages['startDateGreaterThanEndDate'];
                    }
                    
                    this.setState({ endDate, nextDate, tradeDateStatus, tradeDateMessage });
                }
                break;
            case 'get_symbol_items_success':
                var symbolItems = symbolItems;
                if (symbolItems !== undefined && symbolItems.length > 0) {
                    this.setState({ symbolItems });
                } else {
                    if (this.state.keywords !== '') {
                        this.setState({ symbolItems: [{ symbol: '"' + this.state.keywords + '"' + languageMessages['no.matches.found'], symbolName: '' }] });
                    } else {
                        this.setState({
                            symbolItems: []
                        });
                    }
                }
                break;
        }
    }

    setBaseInfo() {
        this.props.hawkeyeBaseInfo({
            symbolCode: this.state.symbolCode,
            symbolName: this.state.symbolName,
            direction: this.state.direction,
            tradeQuantity: this.state.tradeQuantityStatus === 'error' ? '' : this.state.tradeQuantity,
            startDate: this.state.startDate,
            endDate: this.state.tradeDateStatus === 'error' ? '' : this.state.endDate,
            latestPrice: this.state.latestPrice,
            quoteChange: this.state.quoteChange,
        });
    }

    onChangeSymbol = (symbolCode) => {
        this.setState({ symbolCode });

        /**
        if (symbolCode !== '') {
            var tmpDataCode = this.state.symbolItems.filter(function (e) { return e.symbol.toLowerCase() === symbolCode.toLowerCase(); });
            
            if (tmpDataCode.length > 0) {
                console.log('tmpDataCode', tmpDataCode);
                symbolCode = tmpDataCode[0].symbol;
                var symbolName = tmpDataCode[0].stockName;
                var latestPrice = parseFloat(tmpDataCode[0].close).toFixed(2);
                var quoteChange = parseFloat(tmpDataCode[0].chg).toFixed(2);

                this.setState({ symbolCode, symbolName, latestPrice, quoteChange }, () => {
                    this.setBaseInfo();
                });
            }
        }
        */
    }

    // 选择标的
    onSelectSymbol = value => {
        if (value !== '') {
            if (value.search(languageMessages['no.matches.found']) === -1) {
                //languageMessages['no.matches.found']

                //var symbolItems = JSON.parse(sessionStorage.getItem("SymbolItemsCache") || '[]') || [];
                //var tmpDataCode = symbolItems.filter(function (e) { return e.stockCode.toLowerCase() === value.toLowerCase(); });
                var tmpDataCode = this.state.symbolItems.filter(function (e) { return e.symbol.toLowerCase() === value.toLowerCase(); });
                
                if (tmpDataCode.length > 0) {
                    var symbolCode = tmpDataCode[0].symbol;
                    var symbolName = tmpDataCode[0].symbolName;
                    var latestPrice = parseFloat(tmpDataCode[0].close).toFixed(2);
                    var quoteChange = parseFloat(tmpDataCode[0].chg).toFixed(2);

                    this.setState({ symbolCode, symbolName, latestPrice, quoteChange }, () => {
                        this.setBaseInfo();
                        /**
                        var user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
                        if (user.username === undefined) {
                            this.props.dispatch({ type: "action_non_user" });
                        } else {
                            this.props.dispatch({ type: "action_get_stock_tick", payload: { UserId: user.username, SymbolList: symbolCode } });
                        }
                        */
                    });
                }
            }
        }
    }

    onFocusSymbol = () => {
        // defaylt symbol items
    }

    onBlurSymbol = value => {
        if (value !== '') {
            var tmpDataCode = this.state.symbolItems.filter(function (e) { return e.symbol.toLowerCase() === value.toLowerCase(); });

            if (tmpDataCode.length === 0) {
                tmpDataCode = this.state.symbolItems.filter(function (e) { return e.symbol.substring(2, e.symbol.length) === value; });
            }

            if (tmpDataCode.length === 1) {
                var symbolCode = tmpDataCode[0].symbol;
                var symbolName = tmpDataCode[0].symbolName;
                var latestPrice = parseFloat(tmpDataCode[0].close).toFixed(2);
                var quoteChange = parseFloat(tmpDataCode[0].chg).toFixed(2);

                this.setState({ symbolCode, symbolName, latestPrice, quoteChange }, () => {
                    this.setBaseInfo();
                });
            } else {
                this.setState({
                    symbolItems: [],
                    symbolCode: '',
                    symbolName: '',
                    latestPrice: 0,
                    quoteChange: 0
                }, () => {
                    this.setBaseInfo();
                });
            }
        } else {
            this.setState({
                symbolItems: [],
                symbolCode: '',
                symbolName: '',
                latestPrice: 0,
                quoteChange: 0
            }, () => {
                this.setBaseInfo();
            });
        }
    }

    // 匹配标的
    handleSearch = value => {
        this.setState({ keywords: value }, () => {
            /**
            if (this.user.username === undefined) {
                this.props.dispatch({ type: "action_non_user" });
            } else {
                this.props.dispatch({ type: "action_get_symbol_items", payload: { UserId: this.user.username, keywords: value } });
            }
            */
            this.props.dispatch({ type: "action_get_symbol_items", payload: { UserId: this.user.username, keywords: value } });
        });
    };
    // 选择方向[买，卖]
    onChangeDirection = (e) => {
        var direction = e.target.value;

        this.setState({ direction }, () => {
            this.setBaseInfo();
        });
    }

    // 交易数量
    onChangeTradeQuantity = (e) => {
        var tradeQuantity = e.target.value;
        var tradeQuantityStatus = '';
        var tradeQuantityMessage = '';
        if (tradeQuantity !== '') {
            //判断是否为整数
            var pattern = /^\+?[1-9][0-9]*$/;
            if (!pattern.test(tradeQuantity)) {
                tradeQuantityStatus = 'error';
                tradeQuantityMessage = languageMessages['tradeQuantityFormatFail'];
            } else {
                if (tradeQuantity.length > 12) {
                    tradeQuantityStatus = 'error';
                    tradeQuantityMessage = languageMessages['tradeQuantityLengthFail'];
                }
            }
        } else {
            tradeQuantityStatus = 'error';
            tradeQuantityMessage = languageMessages['tradeQuantityNotEmpty'];
        }
        this.setState({ tradeQuantity, tradeQuantityStatus, tradeQuantityMessage }, () => {
            this.setBaseInfo();
        });
    }
    //开始日期
    onChangeStartDate = startDate => {
        startDate = moment(startDate).format(format);

        //判断小于结束时间
        /** 
        var oDate1 = new Date(startDate);
        var oDate2 = new Date(endDate);
        if (oDate1.getTime() < oDate2.getTime()) {
            
        }
        */

        this.setState({ startDate }, () => {
            this.setBaseInfo();
        });
    }

    startDisabledDate = current => {
        return current && (current < moment().startOf('day') || current >= moment().add(1, 'months'));
    }

    startDisabledTime = current => {
        var hours = 0;
        var minutes = 0;

        if (current && current > moment().startOf('day') && current < moment().endOf('day')) {
            var mm = moment().add(5, 'minutes');
            hours = mm.hour();
            var startHour = moment(current).hour();
            if (hours >= startHour) {
                minutes = mm.minute();
            }
        }

        return {
            disabledHours: () => range(0, hours),
            disabledMinutes: () => range(0, minutes),
        };
    }
    //结束日期
    onChangeEndDate = endDate => {
        endDate = moment(endDate).format(format);

        //判断大于开始时间
        /** 
        var oDate1 = new Date(startDate);
        var oDate2 = new Date(endDate);
        if (oDate1.getTime() < oDate2.getTime()) {
            
        }
        */
        
        this.setState({ endDate }, () => {
            this.setBaseInfo();
        });
    }

    endDisabledDate = current => {
        const { startDate } = this.state;
        if (!startDate) {
            return false;
        }

        return current && (current < moment(startDate) || current >= moment().add(1, 'months'));
    }

    endDisabledTime = current => {
        const { startDate } = this.state;
        if (!startDate) {
            return false;
        }

        var hours = 0;
        var minutes = 0;
        if (current && moment(current).format('YYYY-MM-DD') === moment(startDate).format('YYYY-MM-DD')) {
            hours = moment(startDate).hour();
            var endHour = moment(current).hour();

            if (hours > endHour) {
                minutes = 60;
            } else if (hours === endHour) {
                minutes = moment(startDate).add(5, 'minutes').minute();
            }
        } else if (moment(current) < moment(startDate)) {
            hours = 24;
            minutes = 60;
        }

        return {
            disabledHours: () => range(0, hours),
            disabledMinutes: () => range(0, minutes),
        };
    }

    handleStartOpenChange = open => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    };

    handleEndOpenChange = endOpen => {
        var tradeDateStatus = '';
        var tradeDateMessage = '';

        if (!endOpen) {
            //判断日期是否小于开始日期
            var oDate1 = new Date(this.state.startDate);
            var oDate2 = new Date(this.state.endDate);
            if (oDate1.getTime() > oDate2.getTime()) {
                tradeDateStatus = 'error';
                tradeDateMessage = languageMessages['startDateGreaterThanEndDate'];
            }
        }
        this.setState({ endOpen, tradeDateStatus, tradeDateMessage }, () => {
            this.setBaseInfo();
        });
    };

    setHawkeyeDate = e => {
        this.setState({ customEffectiveTime: e.target.checked });
    }

    onValueAddedServices = e => {
        e.preventDefault();

        history.push({ pathname: '/member', state: { memberMenuSelectKey: "2" } });
    }

    render() {
        var { symbolItems, symbolCode } = this.state;

        if (symbolCode.search(languageMessages['no.matches.found']) !== -1) {
            symbolCode = symbolCode.replace(languageMessages['no.matches.found'], '');
            symbolCode = symbolCode.substring(1, symbolCode.length - 1);
        }

        return (<Form labelAlign={'left'} layout="inline" className={window.webType === 'US' ? 'common-form-hawkeye hawkeye-us' : 'common-form-hawkeye'}>
            <Form.Item colon={false} label={languageMessages['symbolCode']} validateStatus={this.state.symbolStatus} help={this.state.symbolMessage} style={{ marginBottom: 0, marginRight: 32 }}>
                <AutoComplete value={symbolCode}
                    className="certain-category-search"
                    dropdownClassName="certain-category-search-dropdown"
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ width: 200 }}
                    size="small"
                    style={{ width: '200px' }}
                    dataSource={symbolItems.map(group => (
                        <Option key={group.symbol} value={group.symbol}>
                            {group.symbol}
                            <span className="certain-search-item-count">{group.symbolName}</span>
                        </Option>
                    ))}
                    onChange={this.onChangeSymbol}
                    onSearch={this.handleSearch}
                    onSelect={this.onSelectSymbol}
                    onFocus={this.onFocusSymbol}
                    onBlur={this.onBlurSymbol}
                    placeholder={languageMessages['pleaseEnterStockName-Symbol']}
                    optionLabelProp="value">
                    <Input suffix={<Icon type="search" className="certain-category-icon" />} />
                </AutoComplete>
                <div style={{ position: 'relative', top: '-5px', height: '20px', lineHeight: '20px', fontSize: '10px' }}>
                    {languageMessages['latest.price']}：<span style={{ color: this.state.quoteChange === 0 ? 'rgba(0, 0, 0, 0.25)' : 'rgba(0, 0, 0, 0.85)', marginRight: '10px' }}>{this.state.latestPrice}</span>
                    {languageMessages['quote.change']}：<span style={{ color: this.state.quoteChange === 0 ? 'rgba(0, 0, 0, 0.25)' : this.state.quoteChange >= 0 ? window.webType === 'US' ? 'green' : 'red' : window.webType === 'US' ? 'red' : 'green' }}>{this.state.quoteChange}%</span>
                </div>
            </Form.Item>
            <Form.Item colon={false} label={languageMessages['hawkeyeAction']} style={{ marginBottom: 0 }}>
                <Radio.Group value={this.state.direction} onChange={this.onChangeDirection}>
                    <Radio value={closeShort}>{languageMessages['closeShort']}</Radio>
                    <Radio value={closeLong}>{languageMessages['closeLong']}</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item colon={false} label={languageMessages['tradeQuantity']} validateStatus={this.state.tradeQuantityStatus} help={this.state.tradeQuantityMessage} style={{ marginBottom: 0, marginRight: 32 }}>
                <Input size="small" style={{ width: 115 }} placeholder={languageMessages['tradeQuantityNotEmpty']} value={this.state.tradeQuantity} onChange={this.onChangeTradeQuantity} />
            </Form.Item>
            <Form.Item colon={false} label={languageMessages['effectTime']} validateStatus={this.state.tradeDateStatus} help={this.state.tradeDateMessage}>
                <span style={{ display: 'inline-block' }}>
                    <DatePicker size="small" showTime={{ format: 'HH:mm' }}
                        allowClear={false}
                        placeholder={languageMessages['pleaseEnterStartDate']}
                        defaultValue={moment(this.state.startDate, format)}
                        //disabledDate={this.startDisabledDate}
                        //disabledTime={this.startDisabledTime}
                        format={format} style={{ width: '150px' }}
                        onChange={this.onChangeStartDate}
                        disabled={!this.state.customEffectiveTime}
                        onOpenChange={this.handleStartOpenChange}
                    />
                </span>
                <span style={{ display: 'inline-block', width: '24px', textAlign: 'center' }}>{languageMessages['intl.to']}</span>
                <span style={{ display: 'inline-block' }}>
                    <DatePicker size="small" showTime={{ format: 'HH:mm' }}
                        allowClear={false}
                        placeholder={languageMessages['pleaseEnterEndDate']}
                        value={moment(this.state.endDate, format)}
                        disabledDate={this.endDisabledDate}
                        disabledTime={this.endDisabledTime}
                        format={format} style={{ width: '150px' }}
                        onChange={this.onChangeEndDate}
                        disabled={this.state.startDate === '' || !this.state.customEffectiveTime}
                        open={this.state.endOpen}
                        onOpenChange={this.handleEndOpenChange}
                    />
                </span>
                <span style={{ display: 'inline-block', paddingLeft: 10 }}>
                    <Checkbox onChange={this.setHawkeyeDate} disabled={!this.props.isVip}>{languageMessages['customExpirationTime']}</Checkbox>
                    <a onClick={this.onValueAddedServices} style={{ fontSize: '10px' }}>{languageMessages['VIP.use-value.added.services']}</a>
                </span>
            </Form.Item>
        </Form>);
    }
}
            
const BaseInfoForm = Form.create({ name: 'Base_Info' })(BaseInfo);
export { BaseInfoForm as default }