import React, { Component } from "react";
import Search from "../search/search";
import { Tree } from 'antd';

import help_side_cn from './../help_side_cn';
import help_side_en from './../help_side_en';

const { TreeNode } = Tree;

class SideMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchData: [],
            index: 0,
            searchResult: [],
            searchStatus: false,
            data: window.webType === 'US' ? help_side_en : help_side_cn,
            treeData: [],
            treeResult: [],
            placeholder: '在帮助中搜索'
        };
    }

    filterData(list) {
        let i = 0
        let that = this;
        let { searchData } = this.state;
        let newList = Array.isArray(list) ? list : [list]

        i++

        newList.map((key, index) => {
            if (key.children) {
                return that.filterData(key.children);
            } else {
                let id = key["id"] || `1-${key.tit || key}`;
                return searchData.push({ id: `inner-${id}`, value: (key.title || (key.tit || key)) });
            }
        });

        return this.setState({
            searchData
        });
    }

    initData() {
        let list = [];
        Object.keys(this.state.data).map(item => {
            list.push(this.state.data[item]);
        });
        this.filterData(list);
    }

    searchPos(value) {
        if (!value) {
            this.setState({
                searchStatus: false
            })
            return;
        }

        let id = "";
        let st = 0;
        let res = [];
        let { searchResult } = this.state
        let { scrollContent } = this.props;
        let ele = document.getElementsByClassName("side-content")[0];

        searchResult.length = 0

        this.state.searchData.forEach(item => {
            if (item.value.toLowerCase().indexOf(value.toLowerCase()) !== -1) {

                id = item.id;
                res.push(id);
                st = document.getElementById(id) ? document.getElementById(id).offsetTop : 0;
                ele.scrollTop = st;

                searchResult.push({ id, value: item.value })


            }

            this.setState({
                searchStatus: false
            })
            return (id = "");

        });

        this.setState(
            {
                index: res.length ? res[res.length - 1].split("inner-")[1] : 0,
                searchResult
            },
            () => {
                this.props.setCurrentId(this.state.index);
                scrollContent && scrollContent(this.state.index);

                if (this.state.searchResult.length) {
                    this.setState({
                        searchStatus: true
                    })
                } else {
                    this.setState({
                        searchStatus: false
                    })
                }
            }
        );
    }

    handleClickScroll(e) {
        let {
            target,
            target: { id }
        } = e;
        let ele1 = document.getElementsByClassName("side-content")[0];
        let ele2 = document.getElementsByClassName("help-content")[0];
        let res = id.split('-')
        let contentId = res[0] + '-1-' + res[1]
        let resEle = document.getElementById(contentId)

        ele2.scrollTop = resEle.offsetTop;
        this.props.currentState && this.props.currentState(this.state.index);
        // this.searchPos(innerText);
    }

    onSelect(selectedKeys, event) {
        const { node: { props: { title } } } = event
        let arr = []

        let ele2 = document.getElementsByClassName("help-content")[0];
        let dom = ele2.getElementsByTagName('*')

        for (let i = 0; i < dom.length - 1; i++) {
            // attr = dom[i].getAttribute('data-cur-title')
            let node = dom[i]
            let text = dom[i].getAttribute('data-cur-title')
            let id = dom[i].id

            if (text) {
                arr.push({
                    id,
                    node,
                    text: text.toLocaleLowerCase()
                })
            }
        }
        //console.log(title, 'render node ')

        arr.forEach(arrItem => {
            //console.log(arrItem.text, 'render item text')

            if (arrItem.text.indexOf(title.toLocaleLowerCase()) > -1) {
                ele2.scrollTop = arrItem.node.offsetTop
            }
        })

    }

    initTree() {
        let { treeData } = this.state
        let ele2 = document.getElementsByClassName("side-content")[0];
        let dom = ele2.getElementsByTagName('*')

        for (let i = 0; i < dom.length - 1; i++) {
            let node = dom[i]
            let text = dom[i].getAttribute('data-cur-title')
            let id = dom[i].id

            if (text) {
                treeData.push({
                    id,
                    node,
                    text
                })
            }
        }

        this.setState({
            treeData
        })
    }

    renderSideContent() {
        let { index } = this.state;
        let { data } = this.state;

        return Object.keys(data).map((item, i) => (
            <div className="tree-title" key={item}>
                {data[item].title}
                {data[item].children.map(outer => (
                    <div className="tree-outer" key={outer.id}>
                        {outer.title}
                        <div className="tree-inner">
                            {outer.children.map(inner => {
                                return (
                                    <div
                                        key={inner.id}
                                        id={`inner-${inner.id}`}
                                        className="tree-inner-tit"
                                        onClick={this.handleClickScroll.bind(this)}
                                    >
                                        {
                                            <div
                                                id={`inner-${inner.title}`}
                                                className={`tree-inner-dt ${
                                                    inner.id == index ? "active" : ""
                                                    }`}
                                            >
                                                {inner.title}
                                            </div>
                                        }
                                        {Array.isArray(inner.children) ? (
                                            <div className="tree-inner-di">
                                                {inner.children.map(
                                                    (descs, i) =>
                                                        (descs.tit && !descs.hide) && (
                                                            <div
                                                                key={i}
                                                                id={`inner-${descs.tit}`}
                                                                className={`tree-inner-dit ${
                                                                    descs == index ? "active" : ""
                                                                    }`}
                                                            >
                                                                {descs.tit}
                                                            </div>
                                                        )
                                                )}
                                            </div>
                                        ) : (
                                                inner.children
                                            )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ))}
            </div>
        ));
    }

    renderSearchResult() {
        let { searchStatus, searchResult } = this.state

        if (!searchStatus) {
            return
        }

        return searchResult.map((resultItem, index) => {
            return (
                <div className={"search-result"}
                    onClick={(e) => this.props.scrollContent(e.target.dataset.pos)}
                    key={resultItem.id + index}
                    data-pos={resultItem.value}>{resultItem.value}</div>
            )
        })
    }

    renderTreeContent() {
        const { data } = this.state
        const od = Object.keys(data)
        return (<Tree onSelect={this.onSelect.bind(this)}>
            {
                od.map((tp, index) => {
                    const di = data[tp]
                    const fc = di.children
                    return (
                        <TreeNode title={di.title} key={`0-${index}`} id={`0-${index}`}>
                            {
                                fc.map((fi, fIndex) => (
                                    fi.title ?
                                        <TreeNode title={fi.title} key={`0-${index}-${fIndex}`} id={`0-${index}-${fIndex}`}>
                                            {
                                                fi.children.map((si, sIndex) => (
                                                    si.title ?
                                                        <TreeNode title={si.title} key={`0-${index}-${fIndex}-${sIndex}`} id={`0-${index}-${fIndex}-${sIndex}`}>
                                                            {
                                                                si.children ?
                                                                    Array.isArray(si.children) ?
                                                                        si.children.map(
                                                                            (fri, frIndex) => <TreeNode title={fri.tit || fri} key={`0-${index}-${fIndex}-${sIndex}-${frIndex}`} id={`0-${index}-${fIndex}-${sIndex}-${frIndex}`}></TreeNode>) :
                                                                        <TreeNode title={si.children} key={`0-${index}-${fIndex}-${sIndex}`} id={`0-${index}-${fIndex}-${sIndex}`}></TreeNode> :
                                                                    null
                                                            }
                                                        </TreeNode> :
                                                        null
                                                ))
                                            }

                                        </TreeNode>
                                        : null
                                ))
                            }
                        </TreeNode>
                        // <TreeNode title='88998'></TreeNode>
                    )
                })
            }
        </Tree>)
    }

    searchTree(value) {
        if (!value) {
            return
        }

        let { treeData, treeResult } = this.state

        treeData.forEach(tItem => {
            const { text, node, id } = tItem
            if (text.indexOf(value) > -1) {

                treeResult.push({
                    id,
                    text,
                    node
                })
            }
        })

        this.setState({
            treeResult
        }, () => {
        })
    }

    onChange() {
        this.setState({
            searchStatus: false
        })
    }

    onFocus() {
        this.setState({
            placeholder: ''
        })
    }

    onBlur() {
        this.setState({
            placeholder: '在帮助中搜索'
        })
    }

    componentDidMount() {
        this.initData();
        this.initTree()
    }

    render() {
        let { searchStatus, placeholder } = this.state
        return (
            <div className="side-menu">
                <Search search={this.searchPos.bind(this)} onChange={this.onChange.bind(this)} placeholder={placeholder} onFocus={this.onFocus.bind(this)} onBlur={this.onBlur.bind(this)} />
                <div className={'side-content'}>
                    {
                        searchStatus ?
                            this.renderSearchResult() :
                            this.renderTreeContent()
                    }
                </div>
                {/* <div className="side-content">
                    {
                        searchStatus ?
                            this.renderSearchResult() :
                            this.renderSideContent()
                    }
                </div> */}
            </div>
        );
    }
}

export default SideMenu;