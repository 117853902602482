import React, { Component } from 'react';
import { UserMenu, LoginRegisterButtons } from '../../components'
import { connect } from 'react-redux';
import history from '../../helpers/history';
import { Layout, Menu, Row, Col, Modal, Alert, message, notification, Button } from 'antd';
import emitter from '../Evt.js';

import WrappedLoginForm from '../../pannels/form/LoginForm';

import $ from 'jquery';
window.jQuery = $;
require('signalr');

const { Header } = Layout;
const SubMenu = Menu.SubMenu;

var languageMessages = window.appLocale.messages;

var hubConnection = null;
var hubProxy = null;
var isHubConnection = 0;

var testMode = false;

function strReplace(source, jsonObjcet) {
    for (var v in jsonObjcet) {
        source = source.replace(v, jsonObjcet[v]);
    }
    return source;
}

class HeaderPage extends Component {
    constructor(props) {
        super(props);

        var auth = JSON.parse(localStorage.getItem("auth") || '{}') || {};

        var current = sessionStorage.getItem("MainMenuSelectKey") || 'vernacular';
        
        this.state = {
            current: current,
            userId: '',
            photo: '',

            loggedIn: false,

            modalVisible: false,
            isGetCaptcha: true,
            count: 60,
            code: '',
            message: '',
            logining: false,

            alertVisible: false,
            hawkeye: {},

            orderVisible: false,
            order: {},

            ...auth
        };
    }

    componentDidMount() {
        emitter.addListener('callJump', (msg) => {
            this.setState({
                current: msg,
            });

            sessionStorage.setItem("MainMenuSelectKey", msg);
        });

        emitter.addListener('callIntradayData', (symbol, period) => {
            var user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
            if (user.username === undefined) {
                // 没有登录
            } else {
                this.SendIntradayDataToService(user.username, symbol, period);
            }
        });

        emitter.addListener('HawkeyeConfirm', (hawkeyeId, confirmResult) => {
            var user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
            if (user.username === undefined) {
                // 没有登录
            } else {
                var txt = confirmResult === 0 ? languageMessages['ClosePendingOrder'] : languageMessages['PendingOrderHawkeye'];
                this.SendHawkeyeConfirm(user.username, hawkeyeId, confirmResult, txt);
            }
        });
    }

    componentWillMount() {
        var userId = '';
        var photo = '';
        var loggedIn = false;
        var user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        if (user.username !== undefined) {
            userId = user.username;
            var timestamp = new Date().getTime();
            photo = user.photo ? user.photo + '?now=' + timestamp : '/static/photo/photo.png';
            loggedIn = true;

            if (isHubConnection !== 1) {
                if (!testMode) this.setupConnect();
            }
        }

        this.setState({
            userId: userId,
            photo: photo,
            loggedIn: loggedIn,
        });
    }

    componentWillUnmount() {
        if (hubConnection !== null) {
            hubConnection.stop();
        }
    }

    componentWillReceiveProps(props) {
        var { status, state, user, data } = props.member;
        var { count } = this.state;

        this.setState({
            code: '',
            message: '',
            logining: false
        }, () => {
            var failStatus = props.fail.status !== undefined ? props.fail.status : '';
            if (failStatus === 'non_user') {
                sessionStorage.removeItem('auth');
                this.setState({ modalVisible: true, loggedIn: false, userId: '', photo: '' });
            } else {
                switch (status) {
                    case 'Get_Captcha':
                        switch (state) {
                            case 0://失败
                                this.setState({
                                    code: 'ERROR',
                                    message: languageMessages['get.verification.code.failed']
                                });
                                break;
                            case 1://成功
                                const timer = setInterval(() => {
                                    this.setState({
                                        isGetCaptcha: false,
                                        count: (count--)
                                    }, () => {
                                        if (count === 0) {
                                            clearInterval(timer);
                                            this.setState({
                                                isGetCaptcha: true,
                                                count: 60
                                            })
                                        }
                                    })
                                }, 1000);
                                break;
                            case 2://手机未注册
                                this.setState({
                                    code: 'ERROR',
                                    message: languageMessages['phone.not.register']
                                });
                                break;
                            case 3://手机有误
                                this.setState({
                                    code: 'ERROR',
                                    message: languageMessages['phone.format.failed']
                                });
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'Register_Success':
                        if (!testMode) {
                            this.setupConnect();

                            var tn = sessionStorage.getItem("TriggerNumberCache");
                            if (tn > 0) this.openNotification(tn);
                        }
                        this.setState({ userId: user.username, photo: '/static/photo/photo.png', loggedIn: true });
                        break;
                    case 'Login_Success':
                        if (!testMode) {
                            this.setupConnect();

                            var tn = sessionStorage.getItem("TriggerNumberCache");
                            if (tn > 0) this.openNotification(tn);
                        }
                        var photo = user.photo ? user.photo + '?now=' + timestamp : '/static/photo/photo.png';
                        this.setState({ userId: user.username, photo: photo, loggedIn: true, modalVisible: false }, () => {
                            //var pathname = window.location.pathname;
                            //sessionStorage.setItem('pathname', pathname);
                            //history.replace("/blank");
                            emitter.emit('initPage');
                        });
                        break;
                    case 'update_user_success':
                        if (user.photo !== undefined) {
                            var photo = user.photo;
                            var timestamp = new Date().getTime();
                            photo = photo ? photo + '?now=' + timestamp : '/static/photo/photo.png';
                            this.setState({ photo: photo });
                        }
                        break;
                    case 'Logout_Success':
                        this.setState({ userId: '', photo: '', loggedIn: false }, () => {
                            // disconnection
                            if (hubConnection !== null) {
                                hubConnection.stop();
                            }
                        });
                        break;
                    case 'Logining':
                        this.setState({
                            logining: true,
                        });
                        break;
                    case 'Login_Failed':
                        switch (state) {
                            case 0:
                                this.setState({
                                    code: 'ERROR',
                                    message: languageMessages['login.exception']
                                });
                                break;
                            case 2:
                                this.setState({
                                    code: 'ERROR',
                                    message: languageMessages['account.not.register']
                                });
                                break;
                            case 3:
                                this.setState({
                                    code: 'ERROR',
                                    message: languageMessages['account.exception']
                                });
                                break;
                            case 4:
                                this.setState({
                                    code: 'ERROR',
                                    message: languageMessages['password.or.verification.code.match.error']
                                });
                                break;
                            case 5:
                                this.setState({
                                    code: 'ERROR',
                                    message: languageMessages['user.account.has.expired']
                                });
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'sendHubMessage':
                        this.SendMessageToService(data.service, data.userId, data.msg);
                        break;
                    default:
                        break;
                }
            }
        });
    }

    setupConnect() {
        var url = 'signalr';
        var user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        if (user.webSiteSync !== undefined) {
            url = user.webSiteSync + '/' + url;
        }

        hubConnection = $.hubConnection(url, { useDefaultPath: false, qs: { userId: user.username, password: user.password } });

        //hubConnection.logging = true;

        /**
        hubConnection.connectionSlow(function () {
            console.log('We are currently experiencing difficulties with the connection.')
        });

        hubConnection.starting(function () {
            //console.log('starting');
        });

        hubConnection.received(function (message) {
            //console.log('received', message);
        });
        
        hubConnection.reconnecting(function () {
            //console.log('reconnecting');
        });

        hubConnection.reconnected(function () {
            //console.log('reconnected');
        });
        */

        hubConnection.stateChanged(function (data) {
            isHubConnection = data.newState;
        });

        hubConnection.disconnected(() => {
            if (this.state.loggedIn) {
                setTimeout(function () {
                    hubConnection.start()
                        .done(function () { console.log('Connection started!'); })
                        .fail(function (error) { console.log('Could not connect', error); });
                }, 5000);
            }
        });

        hubConnection.error(function (error) {
            console.log('SignalR error', error);
        });

        hubProxy = hubConnection.createHubProxy('hubController');

        hubProxy.on('ReceiveSmartHawkeyeMessage', (items, lastTimes) => {
            emitter.emit('submitHawkeye', lastTimes, items);
        });
        hubProxy.on('ReceiveFilterSuccMessage', (userName, status) => {
            //message.info('ReceiveFilterSuccMessage');
            //console.log('ReceiveFilterSuccMessage', status);
        });
        hubProxy.on('ReceiveFilterFailMessage', (userName, desc) => {
            message.error(strReplace(languageMessages['hawkeyeCondFail'], { '{cont}': desc }));
            //console.log('盯盘条件有错误', desc);
        });
        hubProxy.on('ReceiveCancelHawkeyeMessage', (userName, hawkeyeId) => {
            //console.log('ReceiveCancelHawkeyeMessage', hawkeyeId);

            //转发消息
            //emitter.emit('callHawkeye', hawkeyeId,1);
        });
        hubProxy.on('ReceiveTriggerHawkeyeMessage', (hawkeye) => {
            var user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
            if (user.username === hawkeye.UserName) {
                if (this.state.current === 'hawkeyeList') {
                    emitter.emit('triggerHawkeye', hawkeye);
                }
                
                this.setState({ alertVisible: true, hawkeye });
            }
        });
        hubProxy.on('ReceiveOrderMessage', (userName, order) => {
            var user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
            if (user.username === userName) {
                if (this.state.current === 'hawkeyeList' && order.Id !== undefined) {
                    var hawkeyeIds = [order.Id || '0'];
                    emitter.emit('callHawkeye', hawkeyeIds, 1);
                }

                this.setState({ orderVisible: true, order });
            }
        });

        hubConnection.start()
            .done(function () { console.log('Connection started!'); })
            .fail(function (error) { console.log('Could not connect', error); });
    }

    SendIntradayDataToService(userId, symbol, period) {
        if (isHubConnection === 1) {
            hubProxy.invoke('SendIntradayData', userId, symbol, period).done((res) => {
                if (res['status'] !== undefined) {
                    var status = res['status'];
                    switch (status) {
                        case 0:
                            break;
                        case 1:
                            if (res['hd'] !== undefined && res["pr"] !== undefined) {
                                emitter.emit('callTickData', res['hd'], res["pr"]);
                            }
                            break;
                        case 2:
                            sessionStorage.removeItem('auth');
                            this.setState({ modalVisible: true, loggedIn: false, userId: '', photo: '' });
                            message.success(languageMessages['user.exception']);
                            break;
                        default:
                            break;
                    }
                }
            }).fail((error) => {
                //console.log('Invocation of HubMessage failed. Error', error);
                message.error(strReplace(languageMessages['sendFail'], { '{cont}': '' }), error);
            });
        } else {
            message.error(strReplace(languageMessages['sendFail'], { '{cont}': '' }));
        }
    }

    SendHawkeyeConfirm(userName, hawkeyeId, confirmResult, msg) {
        var { current } = this.state;
        if (isHubConnection === 1) {
            hubProxy.invoke('SendHawkeyeConfirm', userName, hawkeyeId, confirmResult).done(function (status) {
                var info = strReplace(languageMessages['sendFail'], { '{cont}': msg });

                switch (status) {
                    case 1:
                        info = languageMessages['applicationSubmitted'];
                        if (current === 'hawkeyeList') {
                            var hawkeyeIds = hawkeyeId.toString().split(',');
                            emitter.emit('ConfirmHawkeye', hawkeyeIds, confirmResult);
                        }
                        break;
                    case 2:
                        if (current === 'hawkeyeList') {
                            emitter.emit('ConfirmHawkeye', [], 0);
                        }
                        sessionStorage.removeItem('auth');
                        this.setState({ modalVisible: true, loggedIn: false, userId: '', photo: '' });
                        info = languageMessages['user.exception'];
                        break;
                    default:
                        if (current === 'hawkeyeList') {
                            emitter.emit('ConfirmHawkeye', [], 0);
                        }
                        break;
                }
                message.success(info);
            }).fail(function (error) {
                //console.log('Invocation of HubMessage failed. Error', error);
                message.error(strReplace(languageMessages['sendFail'], { '{cont}': msg }), error);
            });
        } else {
            if (current === 'hawkeyeList') {
                emitter.emit('ConfirmHawkeye', [], 0);
            }

            message.error(strReplace(languageMessages['sendFail'], { '{cont}': '' }));
        }
    }

    SendMessageToService(service, userId, msg) {
        var { current } = this.state;
        if (isHubConnection === 1) {
            hubProxy.invoke(service, userId, msg).done((status) => {
                switch (status) {
                    case 1:
                        if (service === 'SendHawkeyeCancel') {
                            if (current === 'hawkeyeList') {
                                var hawkeyeIds = msg.toString().split(',');
                                emitter.emit('callHawkeye', hawkeyeIds, 2);
                            }

                            message.success(languageMessages['stopHawkeyeMsgSendSuccess']);
                        } else {
                            emitter.emit('submitHawkeye');

                            var content = <span>{languageMessages['to view, please go']}"<a href="javascript:;" onClick={this.handleMyHawkeye}>{languageMessages['myHawkeye']}</a>"</span>;
                            this.timerModal(languageMessages['settingSuccess'], content);
                        }
                        break;
                    case 2:
                        if (service === 'SendHawkeyeCancel') {
                            if (current === 'hawkeyeList') {
                                emitter.emit('callHawkeye', [], 0);
                            }
                        } else {
                            emitter.emit('submitHawkeye');
                        }
                        sessionStorage.removeItem('auth');
                        this.setState({ modalVisible: true, loggedIn: false, userId: '', photo: '' });
                        message.success(languageMessages['user.exception']);
                        break;
                    default:
                        if (service === 'SendHawkeyeCancel') {
                            if (current === 'hawkeyeList') {
                                emitter.emit('callHawkeye', [], 0);
                            }
                        } else {
                            emitter.emit('submitHawkeye');
                        }

                        message.error(strReplace(languageMessages['sendFail'], { '{cont}': '' }));
                        break;
                }
            }).fail((error) => {
                //console.log('Invocation of HubMessage failed. Error', error);
                message.error(strReplace(languageMessages['sendFail'], { '{cont}': '' }), error);
            });
        } else {
            if (service === 'SendHawkeyeCancel') {
                if (current === 'hawkeyeList') {
                    emitter.emit('callHawkeye', [], 0);
                }
            } else {
                emitter.emit('submitHawkeye');
            }

            message.error(strReplace(languageMessages['sendFail'], { '{cont}': '' }));
        }
    }

    openNotification = (num) => {
        const key = `open${Date.now()}`;
        const btn = <span>
            <Button size="small" onClick={() => notification.close(key)}>{languageMessages['close']}</Button>
            <span style={{ padding: '0 5px' }}></span>
            <Button size="small" onClick={() => { this.handlePendingOrder(); notification.close(key); }}>{languageMessages['view']}</Button>
        </span>;
        
        var tmpTxt = strReplace(languageMessages['HawkeyeAlertRemind'], { '{cont}': '#' });
        var tmpTxts = tmpTxt.split("#");

        var description = [];
        description.push(tmpTxts[0]);
        description.push(<span key='1232' style={{ fontSize: '24px', color: 'red' }}>{num}</span>);
        description.push(tmpTxts[1]);
        notification.info({
            message: languageMessages['prompt'],
            description: description,//<FormattedMessage id="HawkeyeAlertRemind" values={{ cont: <span style={{ fontSize: '24px', color: 'red' }}>{num}</span> }} />,
            btn,
            key,
            duration: 0,
            onClose: () => {
                //console.log('onClose');
            },
        });
    };

    timerModal(title, content) {
        var secondsToGo = 5;
        const modal = Modal.success({
            title: title,
            content: content,
        });

        setTimeout(() => {
            modal.destroy();
        }, secondsToGo * 1000);
    }
    handlePendingOrder = () => {
        sessionStorage.setItem("hawkeyeListTabActiveKey", '0');
        this.jumpHawkeyeList();
    }
    handleMyHawkeye = () => {
        Modal.destroyAll();

        sessionStorage.setItem("hawkeyeListTabActiveKey", '1');
        this.jumpHawkeyeList();
    }

    handleViewHawkeyeAlert = () => {
        var { hawkeye, current } = this.state;
        if (current === 'hawkeyeList' && hawkeye.Id !== undefined) {
            this.setState({ alertVisible: false });
        } else {
            var tabActiveKey = hawkeye.ConfirmOrder === 1 ? '2' : '0';
            sessionStorage.setItem("hawkeyeListTabActiveKey", tabActiveKey);
            this.jumpHawkeyeList();
        }
    }

    handleCloseHawkeyeAlert = () => {
        this.setState({ alertVisible: false });
    }

    handleViewHawkeyeOrder = () => {
        var { order, current } = this.state;
        if (current === 'hawkeyeList' && order.Id !== undefined) {
            this.setState({ orderVisible: false });
        } else {
            sessionStorage.setItem("hawkeyeListTabActiveKey", '2');
            this.jumpHawkeyeList();
        }
    };

    handleCloseHawkeyeOrder = () => {
        this.setState({ orderVisible: false });
    };

    handleLoginEvent(data) {
        this.setState({ modalVisible: data.visible });
    }

    handleChange(data) {
        this.setState({ code: data.error });
    }

    jumpHawkeyeList() {
        sessionStorage.setItem("MainMenuSelectKey", 'hawkeyeList');
        this.setState({
            current: 'hawkeyeList',
            alertVisible: false,
            orderVisible: false,
        }, () => {
            history.push("/hawkeyeList");
        });
    }
    onLoginClick = () => {
        history.push("/login");
    }

    onRegisterClick = () => {
        this.setState({ modalVisible: false }, () => {
            history.push("/register");
        });
    }

    handleUserClick() {
        this.setState({ current: '' }, () => {
            sessionStorage.setItem("MainMenuSelectKey", 'member');
            history.push("/member");
        });
    }

    handleClick = e => {
        this.setState({
            current: e.key,
        });

        sessionStorage.setItem("MainMenuSelectKey", e.key);

        switch (e.key) {
            case "home":
                history.push("/");
                break;
            case "usStocks":
                window.open("http://us.quancept.com");
                break;
            case "cnStocks":
                window.open("http://www.quancept.cn");
                break;
            case "concept":
                history.push("/concept");
                break;
            case "vernacular":
                sessionStorage.removeItem("VernacularStrategyCache");
                history.push("/vernacular");
                break;
            case "strategy":
                history.push("/strategy");
                break;
            case "customStock":
                history.push("/customStock");
                break;
            case "option":
                history.push("/option");
                break;
            case "factor":
                history.push("/factor");
                break;
            case "download":
                history.push("/download");
                break;
            case "help":
                history.push("/help");
                break;
            case "video":
                history.push("/video");
                break;
            case "hawkeye":
                history.push("/hawkeye");
                break;
            case "hawkeyeList":
                history.push("/hawkeyeList");
                break;
            case "service":
                history.push("/service");
                break;
        }
    };

    setModalVisible() {
        this.setState({ modalVisible: false });
    }

    renderWebType() {
        if (window.webType === 'US') return '';//(<Menu.Item key="cnStocks">{languageMessages['intl.menu.cnStocks']}</Menu.Item>);
        else return (<Menu.Item key="usStocks">{languageMessages['intl.menu.usStocks']}</Menu.Item>);
    }

    renderOptionService() {
        if (window.webType === 'US') return '';
        else return (<SubMenu title={<span>{languageMessages['intl.menu.option']}</span>}>
                    <Menu.Item key="option">{languageMessages['optionStrategyScan']}</Menu.Item>
        </SubMenu>);
    }

    renderFactorService() {
        if (window.webType === 'US') return '';
        else return (<Menu.Item key="factor">{languageMessages['factorService']}</Menu.Item>);
    }

    renderHawkeyeAlert(hawkeyeAlert) {
        var className = '';
        if (hawkeyeAlert['RangePercent'] !== undefined) {
            if (hawkeyeAlert['RangePercent'] >= 0) {
                className = window.webType === 'US' ? 'common-green' : 'common-red';
            } else {
                className = window.webType === 'US' ? 'common-red' : 'common-green';
            }
        }

        return <div>
            <Row>
                <Col className="common-gutter-row" span={6}>{languageMessages['symbolCode']}：</Col>
                <Col span={16}>
                    <div className="common-gutter-row">{hawkeyeAlert['SymbolCode'] + ' ' + hawkeyeAlert['SymbolName']}</div>
                    <div className="common-gutter-row">
                        {languageMessages['latest.price']}：<span style={{ paddingRight: '20px' }}>{hawkeyeAlert['LatestPrice']}</span>
                        {languageMessages['quote.change']}：<span className={className}>{hawkeyeAlert['RangePercent']}%</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="common-gutter-row" span={6}>{languageMessages['triggerCondition']}：</Col>
                <Col className="common-gutter-row" span={16}>{hawkeyeAlert['TriggerCondition']}</Col>
            </Row>
            <Row>
                <Col className="common-gutter-row" span={6}>{languageMessages['tradeDirection']}：</Col>
                <Col className="common-gutter-row" span={16}>{hawkeyeAlert['TradeDirection'] === 'Close Short' ? languageMessages['closeShort'] : languageMessages['closeLong']}</Col>
            </Row>
            <Row>
                <Col className="common-gutter-row" span={6}>{languageMessages['tradeQuantity']}：</Col>
                <Col className="common-gutter-row" span={16}>{hawkeyeAlert['TradeQuantity']}</Col>
            </Row>
            <Row>
                <Col className="common-gutter-row" span={6}>{languageMessages['triggerDate']}：</Col>
                <Col className="common-gutter-row" span={16}>{hawkeyeAlert['TriggerDateTime']}</Col>
            </Row>
        </div>
    }

    renderHawkeyeOrder(hawkeyeOrder) {
        var className = '';
        if (hawkeyeOrder['RangePercent'] !== undefined) {
            if (hawkeyeOrder['RangePercent'] >= 0) {
                className = window.webType === 'US' ? 'common-green' : 'common-red';
            } else {
                className = window.webType === 'US' ? 'common-red' : 'common-green';
            }
        }

        return <div>
            <Row>
                <Col className="common-gutter-row" span={6}>{languageMessages['symbolCode']}：</Col>
                <Col span={16}>
                    <div className="common-gutter-row">{hawkeyeOrder['SymbolCode'] + ' ' + hawkeyeOrder['SymbolName']}</div>
                    <div className="common-gutter-row">
                        {languageMessages['latest.price']}：<span style={{ paddingRight: '20px' }}>{hawkeyeOrder['LatestPrice']}</span>
                        {languageMessages['quote.change']}：<span className={className}>{hawkeyeOrder['RangePercent']}%</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="common-gutter-row" span={6}>{languageMessages['triggerCondition']}：</Col>
                <Col className="common-gutter-row" span={16}>{hawkeyeOrder['TriggerCondition']}</Col>
            </Row>
            <Row>
                <Col className="common-gutter-row" span={6}>{languageMessages['tradeDirection']}：</Col>
                <Col className="common-gutter-row" span={16}>{hawkeyeOrder['Direction'] === 'Close Short' ? languageMessages['closeShort'] : languageMessages['closeLong']}</Col>
            </Row>
            <Row>
                <Col className="common-gutter-row" span={6}>{languageMessages['tradeQuantity']}：</Col>
                <Col className="common-gutter-row" span={16}>{hawkeyeOrder['Quantity']}</Col>
            </Row>
            <Row>
                <Col className="common-gutter-row" span={6}>{languageMessages['triggerDate']}：</Col>
                <Col className="common-gutter-row" span={16}>{hawkeyeOrder['OrderDateTime']}</Col>
            </Row>
        </div>
    }
    renderService() {
        return <Menu.Item key="service">{languageMessages['pricing']}</Menu.Item>;
    }
    renderVideo() {
        return <Menu.Item key="video">{languageMessages['videoTutorial']}</Menu.Item>;
    }
    renderHelp() {
        return <Menu.Item key="help">{languageMessages['intl.menu.help']}</Menu.Item>;
    }

    render() {
        if (this.state.current !== 'vernacular') {
            window.scrollTo(0, 0);
        }

        return (<Header className='common-header'>
            <Row>
                <Col xs={10} sm={5} style={{ zIndex: 1 }}>
                    <div className="logo" />
                    {window.webType === 'US' ? '' : <span className="common-tip">
                        {languageMessages['intl.menu.cnStocks']}
                    </span>
                    }
                </Col>
                <Col xs={4} sm={14}>
                    <Menu onClick={this.handleClick} selectedKeys={[this.state.current]} mode="horizontal">
                        
                        {this.renderWebType()}
                        <SubMenu title={<span>{languageMessages['intl.menu.vernacularStockPicking']}</span>}>
                            <Menu.Item key="vernacular">{languageMessages['intl.menu.newScan']}</Menu.Item>
                            <Menu.Item key="strategy">{languageMessages['intl.menu.myStragegy']}</Menu.Item>
                            <Menu.Item key="customStock">{languageMessages['intl.menu.customStock']}</Menu.Item>
                        </SubMenu>
                        <SubMenu title={languageMessages['intl.menu.hawkeye']}>
                            <Menu.Item key="hawkeye">{languageMessages['newHawkeye']}</Menu.Item>
                            <Menu.Item key="hawkeyeList">{languageMessages['myHawkeye']}</Menu.Item>
                        </SubMenu>
                        
                        {this.renderOptionService()}
                        {
                            //this.renderFactorService()
                        }
                        <Menu.Item key="download">{languageMessages['intl.menu.download']}</Menu.Item>
                        {
                            window.webType === 'US' ? this.renderService() : ''
                        }
                        {window.webType === 'US' ? '' : this.renderVideo()}
                        {window.webType === 'US' ? '' : this.renderHelp()}
                    </Menu>
                </Col>
                <Col xs={10} sm={5}>
                    {this.state.loggedIn ? (<UserMenu username={this.state.userId} photo={this.state.photo} userClick={this.handleUserClick.bind(this)} />) : (<LoginRegisterButtons onLoginAction={this.onLoginClick} onRegisterAction={this.onRegisterClick} />)}
                </Col>
            </Row>
            <Modal title={languageMessages['intelligentHawkeyeAlert']}
                visible={this.state.alertVisible}
                okText={languageMessages['view']}
                onOk={this.handleViewHawkeyeAlert}
                cancelText={languageMessages['close']}
                onCancel={this.handleCloseHawkeyeAlert}>
                {this.renderHawkeyeAlert(this.state.hawkeye)}
            </Modal>

            <Modal title={languageMessages['orderInfo']}
                visible={this.state.orderVisible}
                okText={languageMessages['view']}
                onOk={this.handleViewHawkeyeOrder}
                cancelText={languageMessages['close']}
                onCancel={this.handleCloseHawkeyeOrder}>
                {this.renderHawkeyeOrder(this.state.order)}
            </Modal>
            
            <Modal
                title={<div className='form-logo'></div>}
                className='dialog-common-box'
                style={{ top: 64 }}
                width={420}
                zIndex={1005}
                footer={null}
                visible={this.state.modalVisible}
                maskClosable={false}
                onCancel={() => this.setModalVisible()}>
                <div className='title'>
                    <Row>
                        <Col span={12} style={{ textAlign: 'right', padding: '10px' }}>
                        {languageMessages['intl.login']}
                        </Col>
                        <Col span={12} style={{ textAlign: 'left', padding: '10px' }}>
                            <a onClick={this.onRegisterClick}>{languageMessages['intl.register']}</a>
                        </Col>
                    </Row>
                </div>
                <WrappedLoginForm {...this.state} dispatch={this.props.dispatch} loginEvent={this.handleLoginEvent.bind(this)} handleChange={this.handleChange.bind(this)} />
                {this.state.code === 'ERROR' ? (<Alert message={this.state.message} type="error" />) : ''}
            </Modal>
        </Header>)
    }
}

function mapStateToProps(state) {
    return {
        member: state.member,
        fail: state.fail,
    };
}

const page = connect(mapStateToProps)(HeaderPage);
export { page as default }