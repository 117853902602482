import { put, call, takeLatest } from 'redux-saga/effects'
import { get, post } from '../../helpers/server'

function* getStockTick(action) {
    try {
        const resp = yield call(get, "/customStock/tick?u=" + action.payload.UserId + "&s=" + action.payload.SymbolList);
        if (resp && resp.status === 1) {
            yield put({ type: 'StockTick_success', symbolList: resp.symbolList });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'StockTick_fail', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "StockTick_fail", status: 0 });
    }
}

function* getHawkeyeInfo(action) {
    try {
        const resp = yield call(get, "/hawkeye/info?u=" + action.payload.UserId);
        if (resp && resp.status === 1) {
            yield put({ type: 'get_hawkeye_info_success', nd: resp.nd, p: resp.p, ls: resp.ls });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'get_hawkeye_info_fail', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "get_hawkeye_info_fail", status: 0 });
    }
}

function* getHawkeyeItems(action) {
    try {
        const resp = yield call(get, "/hawkeye/items?u=" + action.payload.UserId);
        if (resp && resp.status === 1) {
            yield put({ type: 'get_hawkeye_success', items: resp.items });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'get_hawkeye_fail', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "get_hawkeye_fail", status: 0 });
    }
}

function* closeHawkeye(action) {
    try {
        const resp = yield call(get, "/hawkeye/close?u=" + action.payload.UserId + "&hs=" + action.payload.hawkeyeId);
        if (resp && resp.status === 1) {
            yield put({ type: 'close_hawkeye_success', hawkeyeId: resp.hs });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'close_hawkeye_fail', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "close_hawkeye_fail", status: 0 });
    }
}

function* removeHawkeye(action) {
    try {
        const resp = yield call(get, "/hawkeye/remove?u=" + action.payload.UserId + "&hs=" + action.payload.hawkeyeId);
        if (resp && resp.status === 1) {
            yield put({ type: 'remove_hawkeye_success', hawkeyeId: resp.hs });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'remove_hawkeye_fail', status: resp.status });
        }
    } catch (e) {
        yield put({ type: "remove_hawkeye_fail", status: 0 });
    }
}

function* getHawkeyeBase(action) {
    yield put({ type: "get_hawkeye_base", data: action.payload.data });
}

function* hawkeyeChangeBatch(action) {
    try {
        var params = "?u=" + action.payload.UserId;
        params += "&kw=" + action.payload.keywords;
        params += "&ltK=" + action.payload.luisTabsKey;

        const resp = yield call(get, "/hawkeye/changeBatchLuString" + params);
        if (resp.status === 1) {
            yield put({ type: "hawkeye_change_batch_success", luString: resp.ls });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "hawkeye_change_batch_fail", status: resp.status });
        }
    } catch (e) {
        yield put({ type: "hawkeye_change_batch_fail", status: 0 });
    }
}

function* hawkeyeChangeCondition(action) {
    try {
        var params = "?u=" + action.payload.UserId;
        params += "&kw=" + action.payload.keywords;
        params += "&ltK=" + action.payload.luisTabsKey;

        const resp = yield call(get, "/hawkeye/lustring" + params);
        if (resp.status === 1) {
            yield put({ type: "hawkeye_change_condition_success", luString: resp.ls, sentenceFormula: resp.sf });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "hawkeye_change_condition_fail", status: resp.status });
        }
    } catch (e) {
        yield put({ type: "hawkeye_change_condition_fail", status: 0 });
    }
}

function* useFormulaHawkeye(action) {
    yield put({ type: "Use_formula_hawkeye", isLuisAnalysis: action.payload.isLuisAnalysis, formulaName: action.payload.formulaName, formulaString: action.payload.formulaString, vernacularKey: action.payload.vernacularKey });
}

function* hawkeye() {
    yield takeLatest('action_get_stock_tick', getStockTick);

    yield takeLatest('action_get_hawkeye_info', getHawkeyeInfo);
    yield takeLatest('action_get_hawkeye_items', getHawkeyeItems);
    yield takeLatest('action_close_hawkeye', closeHawkeye);
    yield takeLatest('action_remove_hawkeye', removeHawkeye);

    yield takeLatest('action_get_hawkeye_base', getHawkeyeBase);

    yield takeLatest('action_hawkeye_change_batch', hawkeyeChangeBatch);
    yield takeLatest('action_hawkeye_change_condition', hawkeyeChangeCondition);

    yield takeLatest('action_Use_formula_hawkeye', useFormulaHawkeye);
}

export default hawkeye;