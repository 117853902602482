import { put, call, takeLatest } from 'redux-saga/effects'
import { post, get } from '../../helpers/server'

function* getStrategy(action) {
    try {
        const resp = yield call(get, "/strategy/items?userName=" + action.payload.UserId);
        if (resp && resp.status === 1) {
            yield put({ type: 'strategy_success', items: resp.items });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'strategy_fail', status: resp.status });
        }
    } catch (e) {
        yield put({ type: 'strategy_fail', status: 0 });
    }
}

function* renameStrategy(action) {
    try {
        yield put({ type: "strategy_commit" });
        const resp = yield call(post, "/strategy/rename", action.payload);
        if (resp.status === 1) {
            yield put({ type: "rename_strategy_success" });
        } else {
            if (resp.status === 2) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "rename_strategy_fail", status: resp.status });
        }
    } catch (e) {
        yield put({ type: 'rename_strategy_fail', status: 0 });
    }
}

function* copyStrategy(action) {
    try {
        yield put({ type: "strategy_commit" });
        const resp = yield call(post, "/filter/saveFilter", action.payload);
        if (resp.status === 1) {
            yield put({ type: "copy_strategy_success", editStrategy: resp.editStrategy });
        } else {
            if (resp.status === 4) {
                yield put({ type: "non_user" });
            }
            yield put({ type: "copy_strategy_fail", status: resp.status });
        }
    } catch (e) {
        yield put({ type: 'copy_strategy_fail', status: 0 });
    }
}

function* removeStrategy(action) {
    try {
        const resp = yield call(get, "/strategy/remove?userName=" + action.payload.UserId + "&StrategyId=" + action.payload.strategyId + "&filterId=" + action.payload.filterId);
        if (resp && resp.status >= 1) {
            yield put({ type: 'remove_success', strategyId: action.payload.strategyId });
        } else {
            if (resp.status === -1) {
                yield put({ type: "non_user" });
            }
            yield put({ type: 'remove_fail', status: resp.status });
        }
    } catch (e) {
        yield put({ type: 'remove_fail', status: 0 });
    }
}

function* auth() {
    yield takeLatest('action_get_strategy', getStrategy);

    yield takeLatest('action_rename_strategy', renameStrategy);
    yield takeLatest('action_copy_strategy', copyStrategy);
    yield takeLatest('action_remove_strategy', removeStrategy);
}

export default auth;