import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Row, Col, Button } from 'antd';

const { Content } = Layout;

var languageMessages = window.appLocale.messages;

class DownloadPage extends Component {

    handleDownloadInSync = () => {
        //this.props.dispatch({ type: "action_download", payload: { files: 'Insync' } });
        window.location.href = '/api/download/insync';
    };

    handleDownloadCrystalTrade = () => {
        window.location.href = '/api/download/files';
    };

    handleDownloadAndroidApp = () => {
        window.location.href = '/api/download/app';
    }

    render() {
        return (<Content className='main-container'>
            <div className={window.webType === 'US' ? 'download-container-us' : 'download-container'}></div>
            <div className='common-download'>
                <div className='common-download-cont'>
                    <Row>
                        <Col span={14}>
                            <div style={{ border: '1px solid #e8e8e8', padding: '16px' }}>
                                <Row>
                                    <Col span={4} style={{ padding: '20px 0px' }}><img src="static/images/in.png" width="60" /></Col>
                                    <Col span={8} style={{ padding: '25px 16px', textAlign: 'left' }}>
                                        <b>InSync</b>
                                        <div><Button size='small' onClick={this.handleDownloadInSync}>{languageMessages['download.immediately']}</Button></div>
                                    </Col>
                                    <Col span={12}>
                                        <div style={{ textAlign: 'left' }}><b>{languageMessages['download.info.001']}：</b></div>
                                        <div style={{ textAlign: 'left' }}>
                                            {languageMessages['download.info.002']}<br />
                                            {languageMessages['download.info.003'] !== '' ? <span>{languageMessages['download.info.003']} <br /></span> : ''}
                                            {languageMessages['download.info.004'] !== '' ? <span>{languageMessages['download.info.004']} <br /></span> : ''}
                                            {languageMessages['download.info.005'] !== '' ? languageMessages['download.info.005'] : ''}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{ border: '1px solid #e8e8e8', padding: '16px', marginTop: '10px' }}>
                                <Row>
                                    <Col span={4} style={{ padding: '20px 0px' }}><img src="static/images/cry.png" width="60" /></Col>
                                    <Col span={8} style={{ padding: '25px 16px' }}>
                                        <b>CrystalTrade</b>
                                        <div><Button size='small' onClick={this.handleDownloadCrystalTrade}>{languageMessages['download.immediately']}</Button></div>
                                    </Col>
                                    <Col span={12}>
                                        <div style={{ textAlign: 'left' }}><b>{languageMessages['download.info.006']}：</b></div>
                                        <div style={{ textAlign: 'left' }}>
                                            {languageMessages['download.info.007']}<br />
                                            {languageMessages['download.info.008'] !== '' ? <span>{languageMessages['download.info.008']} <br /></span> : ''}
                                            {languageMessages['download.info.009'] !== '' ? <span>{languageMessages['download.info.009']} <br /></span> : ''}
                                            {languageMessages['download.info.010'] !== '' ? <span>{languageMessages['download.info.010']} <br /></span> : ''}
                                            {languageMessages['download.info.011'] !== '' ? <span>{languageMessages['download.info.011']} <br /></span> : ''}
                                            {languageMessages['download.info.012']}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col span={10} style={{ paddingLeft: '30px' }}>
                            <img src="static/images/cpe.png" width="276" />
                        </Col>
                    </Row>
                </div>
                {window.webType === 'US' ? '' : <div className='common-download-app'>
                    <div className='common-download-cont'>
                        <Row>
                            <Col span={9} style={{ marginTop: '30px' }}>
                                <img src="static/images/iphone.png" width="270" style={{ verticalAlign: 'middle' }} />
                            </Col>
                            <Col span={15}>
                                <div style={{ border: '1px solid #e8e8e8', margin: '50px 0px 0px 30px', padding: '16px' }}>
                                    <Row>
                                        <Col span={4}>
                                            <div style={{ padding: '10px 0' }}><img src="static/images/adn.png" width="60" /></div>
                                            <div style={{ padding: '10px 0' }}><img src="static/images/ios.png" width="60" /></div>
                                        </Col>
                                        <Col span={8} style={{ textAlign: 'left' }}>
                                            <div style={{ padding: '15px 10px' }}>
                                                <b>{languageMessages['android.version']}</b>
                                                <div><Button size='small' onClick={this.handleDownloadAndroidApp}>{languageMessages['download.immediately']}</Button></div>
                                            </div>
                                            <div style={{ padding: '15px 10px' }}>
                                                <b>{languageMessages['ios.version']}</b>
                                                <div><Button size='small' disabled={true}>{languageMessages['stay.tuned']}</Button></div>
                                            </div>
                                        </Col>
                                        <Col span={12} style={{ padding: '10px 0' }}>
                                            <div style={{ textAlign: 'left' }}><b>{languageMessages['download.info.08']}：</b></div>
                                            <div style={{ textAlign: 'left' }}>{languageMessages['download.info.09']}</div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>}
            </div>
        </Content>)
    }
}

function mapStateToProps(state) {
    return state.download;
}

const page = connect(mapStateToProps)(DownloadPage);
export { page as default }