import React, { Component } from 'react';
import { Form, Card, Button } from 'antd';

import { FormattedMessage, defineMessages } from 'react-intl';

const messages = defineMessages({
    youHaveRegisterSuccess: {
        id: 'intl.youHaveRegisterSuccess',
        defaultMessage: '恭喜您已注册成功！',
    },
    openQuantitativeInvestment: {
        id: 'intl.register.openQuantitativeInvestment',
        defaultMessage: '开启量化投资之旅',
    }
});

class RegisterSuccess extends Component {
    constructor(props) {
        super(props)
    }

    handleJump = () => {
        this.props.handleJumpHome();
    }

    render() {
        return (<Card style={{ width: 380 }}>
            <p style={{ color: 'green', fontSize: '18px', textAlign: 'center' }}><FormattedMessage {...messages.youHaveRegisterSuccess} /></p>
            <Button type="primary" onClick={this.handleJump} className='register-form-button'><FormattedMessage {...messages.openQuantitativeInvestment} /></Button>
            </Card>
        )
    }
}

const page = Form.create({ name: 'register_success' })(RegisterSuccess);
export { page as default };