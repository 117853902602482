import React, { Component } from 'react';
import history from '../../helpers/history';
import { Layout } from 'antd';

const { Content } = Layout;

class BlankPage extends Component {

    componentWillMount() {
        var pathname = sessionStorage.getItem('pathname');
        sessionStorage.removeItem('pathname');
        history.replace(pathname);
    }

    render() {
        return (<Content className='main-container'></Content>);
    }
}

export { BlankPage as default }