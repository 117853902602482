var phones = {
    'ar-DZ': /^(\+?213|0)(5|6|7)\d{8}$/,
    'ar-SY': /^(!?(\+?963)|0)?9\d{8}$/,
    'ar-SA': /^(!?(\+?966)|0)?5\d{8}$/,
    'en-US': /^(\+?1)?[2-9]\d{2}[2-9](?!11)\d{6}$/,
    'cs-CZ': /^(\+?420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
    'de-DE': /^(\+?49[ \.\-])?([\(]{1}[0-9]{1,6}[\)])?([0-9 \.\-\/]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$/,
    'da-DK': /^(\+?45)?(\d{8})$/,
    'el-GR': /^(\+?30)?(69\d{8})$/,
    'en-AU': /^(\+?61|0)4\d{8}$/,
    'en-GB': /^(\+?44|0)7\d{9}$/,
    'en-HK': /^(\+?852\-?)?[569]\d{3}\-?\d{4}$/,
    'en-IN': /^(\+?91|0)?[789]\d{9}$/,
    'en-NZ': /^(\+?64|0)2\d{7,9}$/,
    'en-ZA': /^(\+?27|0)\d{9}$/,
    'en-ZM': /^(\+?26)?09[567]\d{7}$/,
    'es-ES': /^(\+?34)?(6\d{1}|7[1234])\d{7}$/,
    'fi-FI': /^(\+?358|0)\s?(4(0|1|2|4|5)?|50)\s?(\d\s?){4,8}\d$/,
    'fr-FR': /^(\+?33|0)[67]\d{8}$/,
    'he-IL': /^(\+972|0)([23489]|5[0248]|77)[1-9]\d{6}/,
    'hu-HU': /^(\+?36)(20|30|70)\d{7}$/,
    'it-IT': /^(\+?39)?\s?3\d{2} ?\d{6,7}$/,
    'ja-JP': /^(\+?81|0)\d{1,4}[ \-]?\d{1,4}[ \-]?\d{4}$/,
    'ms-MY': /^(\+?6?01){1}(([145]{1}(\-|\s)?\d{7,8})|([236789]{1}(\s|\-)?\d{7}))$/,
    'nb-NO': /^(\+?47)?[49]\d{7}$/,
    'nl-BE': /^(\+?32|0)4?\d{8}$/,
    'nn-NO': /^(\+?47)?[49]\d{7}$/,
    'pl-PL': /^(\+?48)? ?[5-8]\d ?\d{3} ?\d{2} ?\d{2}$/,
    'pt-BR': /^(\+?55|0)\-?[1-9]{2}\-?[2-9]{1}\d{3,4}\-?\d{4}$/,
    'pt-PT': /^(\+?351)?9[1236]\d{7}$/,
    'ru-RU': /^(\+?7|8)?9\d{9}$/,
    'sr-RS': /^(\+3816|06)[- \d]{5,9}$/,
    'tr-TR': /^(\+?90|0)?5\d{9}$/,
    'vi-VN': /^(\+?84|0)?((1(2([0-9])|6([2-9])|88|99))|(9((?!5)[0-9])))([0-9]{7})$/,
    'zh-CN': /^(\+?0?86\-?)?1[345789]\d{9}$/,
    'zh-TW': /^(\+?886\-?|0)?9\d{8}$/
};

const CodeRegExp11 = [
    { "Code": "86", "RegExp": "^(\\+?0?86\\-?)?1[345789]\\d{9}$", "ab": "CN", "name": "中国" },
    { "Code": "1", "RegExp": "^(\\+?1)?[2-9]\\d{2}[2-9](?!11)\\d{6}$", "ab": "US", "name": "美国" },
    { "Code": "886", "RegExp": "^(\\+?886\\-?|0)?9\\d{8}$", "ab": "TW", "name": "台湾" },
    { "Code": "852", "RegExp": "^(\\+?852\\-?)?[569]\\d{3}\\-?\\d{4}$", "ab": "HK", "name": "香港" },
    { "Code": "60", "RegExp": "^(\\+?6?01){1}(([145]{1}(\\-|\\s)?\\d{7,8})|([236789]{1}(\\s|\\-)?\\d{7}))$", "ab": "MS", "name": "马来西亚" },
    { "Code": "63", "RegExp": "^(\\+?0?63\\-?)?\\d{10}$", "ab": "PH", "name": "菲律宾" },
    { "Code": "66", "RegExp": "^(\\+?0?66\\-?)?\\d{10}$", "ab": "TH", "name": "泰国" },
    { "Code": "65", "RegExp": "^(\\+?0?65\\-?)?\\d{10}$", "ab": "SG", "name": "新加坡" },
    { "Code": "213", "RegExp": "^(\\+?213|0)(5|6|7)\\d{8}$", "ab": "DZ", "name": "阿尔及利亚" },
    { "Code": "963", "RegExp": "^(!?(\\+?963)|0)?9\\d{8}$", "ab": "SY", "name": "叙利亚" },
    { "Code": "966", "RegExp": "^(!?(\\+?966)|0)?5\\d{8}$", "ab": "SA", "name": "沙特阿拉伯" },
    { "Code": "420", "RegExp": "^(\\+?420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$", "ab": "CZ", "name": "捷克" },
    { "Code": "49", "RegExp": "^(\\+?49[ \\.\\-])?([\\(]{1}[0-9]{1,6}[\\)])?([0-9 \\.\\-\\/]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$", "ab": "DE", "name": "德国" },
    { "Code": "45", "RegExp": "^(\\+?45)?(\\d{8})$", "ab": "DK", "name": "丹麦" },
    { "Code": "30", "RegExp": "^(\\+?30)?(69\\d{8})$", "ab": "GR", "name": "希腊" },
    { "Code": "61", "RegExp": "^(\\+?61|0)4\\d{8}$", "ab": "AU", "name": "澳大利亚" },
    { "Code": "44", "RegExp": "^(\\+?44|0)7\\d{9}$", "ab": "GB", "name": "英国" },
    { "Code": "1", "RegExp": "^(\\+?1)?[2-9]\\d{2}[2-9](?!11)\\d{6}$", "ab": "CA", "name": "加拿大" },
    { "Code": "91", "RegExp": "^(\\+?91|0)?[789]\\d{9}$", "ab": "IN", "name": "印度" },
    { "Code": "64", "RegExp": "^(\\+?64|0)2\\d{7,9}$", "ab": "NZ", "name": "新西兰" },
    { "Code": "27", "RegExp": "^(\\+?27|0)\\d{9}$", "ab": "ZA", "name": "南非" },
    { "Code": "260", "RegExp": "^(\\+?26)?09[567]\\d{7}$", "ab": "ZM", "name": "赞比亚" },
    { "Code": "34", "RegExp": "^(\\+?34)?(6\\d{1}|7[1234])\\d{7}$", "ab": "ES", "name": "西班牙" },
    { "Code": "358", "RegExp": "^(\\+?358|0)\\s?(4(0|1|2|4|5)?|50)\\s?(\\d\\s?){4,8}\\d$", "ab": "FI", "name": "芬兰" },
    { "Code": "33", "RegExp": "^(\\+?33|0)[67]\\d{8}$", "ab": "FR", "name": "法国" },
    { "Code": "972", "RegExp": "^(\\+972|0)([23489]|5[0248]|77)[1-9]\\d{6}", "ab": "IL", "name": "以色列" },
    { "Code": "36", "RegExp": "^(\\+?36)(20|30|70)\\d{7}$", "ab": "HU", "name": "匈牙利" },
    { "Code": "39", "RegExp": "^(\\+?39)?\\s?3\\d{2} ?\\d{6,7}$", "ab": "IT", "name": "意大利" },
    { "Code": "81", "RegExp": "^(\\+?81|0)\\d{1,4}[ \\-]?\\d{1,4}[ \\-]?\\d{4}$", "ab": "JP", "name": "日本" },
    { "Code": "47", "RegExp": "^(\\+?47)?[49]\\d{7}$", "ab": "NO", "name": "挪威" },
    { "Code": "32", "RegExp": "^(\\+?32|0)4?\\d{8}$", "ab": "BE", "name": "比利时" },
    { "Code": "48", "RegExp": "^(\\+?48)? ?[5-8]\\d ?\\d{3} ?\\d{2} ?\\d{2}$", "ab": "PL", "name": "波兰" },
    { "Code": "55", "RegExp": "^(\\+?55|0)\\-?[1-9]{2}\\-?[2-9]{1}\\d{3,4}\\-?\\d{4}$", "ab": "BR", "name": "巴西" },
    { "Code": "351", "RegExp": "^(\\+?351)?9[1236]\\d{7}$", "ab": "PT", "name": "葡萄牙" },
    { "Code": "7", "RegExp": "^(\\+?7|8)?9\\d{9}$", "ab": "RU", "name": "俄罗斯" },
    { "Code": "381", "RegExp": "^(\\+3816|06)[- \\d]{5,9}$", "ab": "RS", "name": "塞尔维亚" },
    { "Code": "90", "RegExp": "^(\\+?90|0)?5\\d{9}$", "ab": "TR", "name": "土耳其" },
    { "Code": "84", "RegExp": "^(\\+?84|0)?((1(2([0-9])|6([2-9])|88|99))|(9((?!5)[0-9])))([0-9]{7})$", "ab": "VN", "name": "越南" }
];

const CodeRegExp = [
    {
        pattern: '^(86){0,1}1[3,4,5,7,8,9]\\d{9}$',
        code: '86',
        text: '中国大陆',
        des: 'CN'
    },
    {
        pattern: '^(1){0,1}\\d{10,12}$',
        code: '1',
        text: '美国',
        des: 'US'
    },
    {
        pattern: '^(886){0,1}0{0,1}[6,7,9](?:\\d{7}|\\d{8}|\\d{10})$',
        code: '886',
        text: '中国台湾',
        des: 'TW'
    },
    {
        pattern: '^(852){0,1}0{0,1}[1,5,6,9](?:\\d{7}|\\d{8}|\\d{12})$',
        code: '852',
        text: '中国香港',
        des: 'HK'
    },
    {
        pattern: '^(853){0,1}6\\d{7}$',
        code: '853',
        text: '中国澳门',
        des: 'MO'
    },
    {
        pattern: '^(60){0,1}1\\d{8,9}$',
        code: '60',
        text: '马来西亚',
        des: 'MY'
    },
    {
        pattern: '^(65){0,1}[13689]\\d{6,7}$',
        code: '65',
        text: '新加坡',
        des: 'SG'
    },
    {
        pattern: '^(81){0,1}0{0,1}[7,8,9](?:\\d{8}|\\d{9})$',
        code: '81',
        text: '日本',
        des: 'JP'
    },
    {
        pattern: '^(82){0,1}0{0,1}[7,1](?:\\d{8}|\\d{9})$',
        code: '82',
        text: '韩国',
        des: 'KR'
    },
    {
        pattern: '^(1){0,1}\\d{10}$',
        code: '1',
        text: '加拿大',
        des: 'CA'
    },
    {
        pattern: '^(61){0,1}4\\d{8,9}$',
        code: '61',
        text: '澳大利亚',
        des: 'AU'
    },
    {
        pattern: '^(64){0,1}[278]\\d{7,9}$',
        code: '64',
        text: '新西兰',
        des: 'NZ'
    },
    {
        pattern: '^(355){0,1}\\d{6,15}$',
        code: '355',
        text: '阿尔希尼亚',
        des: 'AL'
    },
    {
        pattern: '^(54){0,1}\\d{6,12}$',
        code: '54',
        text: '阿根廷',
        des: 'AR'
    },
    {
        pattern: '^(971){0,1}\\d{6,12}$',
        code: '971',
        text: '阿联酋',
        des: 'AE'
    },
    {
        pattern: '^(968){0,1}\\d{6,15}$',
        code: '968',
        text: '阿曼',
        des: 'OM'
    },
    {
        pattern: '^(994){0,1}\\d{6,15}$',
        code: '994',
        text: '阿塞拜疆',
        des: 'AZ'
    },
    {
        pattern: '^(353){0,1}\\d{6,12}$',
        code: '353',
        text: '爱尔兰',
        des: 'IE'
    },
    {
        pattern: '^(20){0,1}\\d{6,12}$',
        code: '20',
        text: '埃及',
        des: 'EG'
    },
    {
        pattern: '^(372){0,1}\\d{6,12}$',
        code: '372',
        text: '爱沙尼亚',
        des: 'EE'
    },
    {
        pattern: '^(244){0,1}\\d{6,15}$',
        code: '244',
        text: '安哥拉',
        des: 'AO'
    },
    {
        pattern: '^(43){0,1}\\d{6,12}$',
        code: '43',
        text: '奥地利',
        des: 'AT'
    },
    {
        pattern: '^(675){0,1}\\d{6,15}$',
        code: '672',
        text: '巴布亚新几内亚',
        des: 'PG'
    },
    {
        pattern: '^(1242){0,1}\\d{6,12}$',
        code: '1242',
        text: '巴哈马',
        des: 'BS'
    },
    {
        pattern: '^(970){0,1}\\d{6,15}$',
        code: '970',
        text: '巴勒斯坦',
        des: 'PS'
    },
    {
        pattern: '^(973){0,1}\\d{6,15}$',
        code: '973',
        text: '巴林',
        des: 'BH'
    },
    {
        pattern: '^(507){0,1}\\d{6,12}$',
        code: '507',
        text: '巴拿马',
        des: 'PA'
    },
    {
        pattern: '^(55){0,1}\\d{6,12}$',
        code: '55',
        text: '巴西',
        des: 'BR'
    },
    {
        pattern: '^(375){0,1}\\d{6,12}$',
        code: '375',
        text: '白俄罗斯',
        des: 'BY'
    },
    {
        pattern: '^(359){0,1}\\d{6,12}$',
        code: '359',
        text: '保加利亚',
        des: 'BG'
    },
    {
        pattern: '^(229){0,1}\\d{6,15}$',
        code: '229',
        text: '贝宁',
        des: 'BJ'
    },
    {
        pattern: '^(32){0,1}\\d{6,12}$',
        code: '32',
        text: '比利时',
        des: 'BE'
    },
    {
        pattern: '^(51){0,1}\\d{6,12}$',
        code: '51',
        text: '秘鲁',
        des: 'PE'
    },
    {
        pattern: '^(48){0,1}\\d{6,12}$',
        code: '48',
        text: '波兰',
        des: 'PL'
    },
    {
        pattern: '^(591){0,1}\\d{6,15}$',
        code: '591',
        text: '玻利维亚',
        des: 'BO'
    },
    {
        pattern: '^(501){0,1}\\d{6,12}$',
        code: '501',
        text: '伯利兹',
        des: 'BZ'
    },
    {
        pattern: '^(226){0,1}\\d{6,15}$',
        code: '226',
        text: '布基纳法索',
        des: 'BF'
    },
    {
        pattern: '^(240){0,1}\\d{6,15}$',
        code: '240',
        text: '赤道几内亚',
        des: 'GQ'
    },
    {
        pattern: '^(45){0,1}\\d{6,12}$',
        code: '45',
        text: '丹麦',
        des: 'DK'
    },
    {
        pattern: '^(49){0,1}1(\\d{5,6}|\\d{9,12})$',
        code: '49',
        text: '德国',
        des: 'DE'
    },
    {
        pattern: '^(228){0,1}\\d{6,15}$',
        code: '228',
        text: '多哥',
        des: 'TG'
    },
    {
        pattern: '^(7){0,1}[13489]\\d{9,11}$',
        code: '7',
        text: '俄罗斯',
        des: 'RU'
    },
    {
        pattern: '^(33){0,1}(\\d{6}|\\d{8,9})$',
        code: '33',
        text: '法国',
        des: 'FR'
    },
    {
        pattern: '^(63){0,1}[24579](\\d{7,9}|\\d{12})$',
        code: '63',
        text: '菲律宾',
        des: 'PH'
    },
    {
        pattern: '^(358){0,1}\\d{6,12}$',
        code: '358',
        text: '芬兰',
        des: 'FI'
    },
    {
        pattern: '^(238){0,1}\\d{6,15}$',
        code: '238',
        text: '佛得角',
        des: 'CV'
    },
    {
        pattern: '^(220){0,1}\\d{6,15}$',
        code: '220',
        text: '冈比亚',
        des: 'GM'
    },
    {
        pattern: '^(1473){0,1}\\d{6,15}$',
        code: '1473',
        text: '格兰纳达',
        des: 'GD'
    },
    {
        pattern: '^(995){0,1}\\d{6,15}$',
        code: '995',
        text: '格鲁吉亚',
        des: 'GE'
    },
    {
        pattern: '^(57){0,1}\\d{6,12}$',
        code: '57',
        text: '哥伦比亚',
        des: 'CO'
    },
    {
        pattern: '^(506){0,1}\\d{6,15}$',
        code: '506',
        text: '哥斯达黎加',
        des: 'CR'
    },
    {
        pattern: '^(592){0,1}\\d{6,15}$',
        code: '592',
        text: '圭亚那',
        des: 'GY'
    },
    {
        pattern: '^(31){0,1}6\\d{8}$',
        code: '31',
        text: '荷兰',
        des: 'NL'
    },
    {
        pattern: '^(504){0,1}\\d{6,15}$',
        code: '504',
        text: '洪都拉斯',
        des: 'HN'
    },
    {
        pattern: '^(253){0,1}\\d{6,15}$',
        code: '253',
        text: '吉布提',
        des: 'DJ'
    },
    {
        pattern: '^(996){0,1}\\d{6,12}$',
        code: '996',
        text: '吉尔吉斯斯坦',
        des: 'KG'
    },
    {
        pattern: '^(224){0,1}\\d{6,15}$',
        code: '224',
        text: '几内亚',
        des: 'GN'
    },
    {
        pattern: '^(245){0,1}\\d{6,15}$',
        code: '245',
        text: '几内亚比绍',
        des: 'GW'
    },
    {
        pattern: '^(241){0,1}\\d{6,15}$',
        code: '241',
        text: '加蓬',
        des: 'GA'
    },
    {
        pattern: '^(855){0,1}\\d{6,12}$',
        code: '855',
        text: '柬埔寨',
        des: 'KH'
    },
    {
        pattern: '^(263){0,1}\\d{6,15}$',
        code: '263',
        text: '津巴布韦',
        des: 'ZW'
    },
    {
        pattern: '^(237){0,1}\\d{6,15}$',
        code: '237',
        text: '喀麦隆',
        des: 'CM'
    },
    {
        pattern: '^(974){0,1}\\d{6,12}$',
        code: '974',
        text: '卡塔尔',
        des: 'QA'
    },
    {
        pattern: '^(1345){0,1}\\d{6,15}$',
        code: '1345',
        text: '开曼群岛',
        des: 'KY'
    },
    {
        pattern: '^(385){0,1}\\d{6,15}$',
        code: '385',
        text: '克罗地亚',
        des: 'HR'
    },
    {
        pattern: '^(269){0,1}\\d{6,15}$',
        code: '269',
        text: '科摩罗',
        des: 'KM'
    },
    {
        pattern: '^(965){0,1}\\d{6,15}$',
        code: '965',
        text: '科威特',
        des: 'KW'
    },
    {
        pattern: '^(254){0,1}\\d{6,15}$',
        code: '254',
        text: '肯尼亚',
        des: 'KE'
    },
    {
        pattern: '^(371){0,1}\\d{6,15}$',
        code: '371',
        text: '拉脱维亚',
        des: 'LV'
    },
    {
        pattern: '^(266){0,1}\\d{6,15}$',
        code: '266',
        text: '莱索托',
        des: 'LS'
    },
    {
        pattern: '^(370){0,1}\\d{6,12}$',
        code: '370',
        text: '立陶宛',
        des: 'LT'
    },
    {
        pattern: '^(352){0,1}\\d{6,12}$',
        code: '352',
        text: '卢森堡',
        des: 'LU'
    },
    {
        pattern: '^(250){0,1}\\d{6,15}$',
        code: '250',
        text: '卢旺达',
        des: 'RW'
    },
    {
        pattern: '^(40){0,1}\\d{6,12}$',
        code: '40',
        text: '罗马尼亚',
        des: 'RO'
    },
    {
        pattern: '^(261){0,1}\\d{6,15}$',
        code: '261',
        text: '马达加斯加',
        des: 'MG'
    },
    {
        pattern: '^(960){0,1}\\d{6,12}$',
        code: '960',
        text: '马尔代夫',
        des: 'MV'
    },
    {
        pattern: '^(265){0,1}\\d{6,15}$',
        code: '265',
        text: '马拉维',
        des: 'MW'
    },
    {
        pattern: '^(223){0,1}\\d{6,15}$',
        code: '223',
        text: '马里',
        des: 'ML'
    },
    {
        pattern: '^(230){0,1}\\d{6,15}$',
        code: '230',
        text: '毛里求斯',
        des: 'MU'
    },
    {
        pattern: '^(222){0,1}\\d{6,15}$',
        code: '222',
        text: '毛里塔尼亚',
        des: 'MR'
    },
    {
        pattern: '^(976){0,1}\\d{6,12}$',
        code: '976',
        text: '蒙古',
        des: 'MN'
    },
    {
        pattern: '^(373){0,1}\\d{6,15}$',
        code: '373',
        text: '摩尔多瓦',
        des: 'MD'
    },
    {
        pattern: '^(212){0,1}\\d{6,12}$',
        code: '212',
        text: '摩洛哥',
        des: 'MA'
    },
    {
        pattern: '^(258){0,1}\\d{6,15}$',
        code: '258',
        text: '莫桑比克',
        des: 'MZ'
    },
    {
        pattern: '^(52){0,1}\\d{6,12}$',
        code: '52',
        text: '墨西哥',
        des: 'MX'
    },
    {
        pattern: '^(264){0,1}\\d{6,15}$',
        code: '264',
        text: '纳米比亚',
        des: 'NA'
    },
    {
        pattern: '^(27){0,1}\\d{6,12}$',
        code: '27',
        text: '南非',
        des: 'ZA'
    },
    {
        pattern: '^(505){0,1}\\d{6,15}$',
        code: '505',
        text: '尼加拉瓜',
        des: 'NI'
    },
    {
        pattern: '^(227){0,1}\\d{6,15}$',
        code: '227',
        text: '尼日尔',
        des: 'NE'
    },
    {
        pattern: '^(234){0,1}\\d{6,12}$',
        code: '234',
        text: '尼日利亚',
        des: 'NG'
    },
    {
        pattern: '^(47){0,1}\\d{6,12}$',
        code: '47',
        text: '挪威',
        des: 'NO'
    },
    {
        pattern: '^(351){0,1}\\d{6,12}$',
        code: '351',
        text: '葡萄牙',
        des: 'PT'
    },
    {
        pattern: '^(46){0,1}[124-7](\\d{8}|\\d{10}|\\d{12})$',
        code: '46',
        text: '瑞典',
        des: 'SE'
    },
    {
        pattern: '^(41){0,1}\\d{6,12}$',
        code: '41',
        text: '瑞士',
        des: 'CH'
    },
    {
        pattern: '^(503){0,1}\\d{6,15}$',
        code: '503',
        text: '萨尔瓦多',
        des: 'SV'
    },
    {
        pattern: '^(381){0,1}\\d{6,12}$',
        code: '381',
        text: '塞尔维亚',
        des: 'RS'
    },
    {
        pattern: '^(232){0,1}\\d{6,15}$',
        code: '232',
        text: '塞拉利昂',
        des: 'SL'
    },
    {
        pattern: '^(221){0,1}\\d{6,15}$',
        code: '221',
        text: '塞内加尔',
        des: 'SN'
    },
    {
        pattern: '^(357){0,1}\\d{6,15}$',
        code: '357',
        text: '塞浦路斯',
        des: 'CY'
    },
    {
        pattern: '^(248){0,1}\\d{6,15}$',
        code: '248',
        text: '塞舌尔',
        des: 'SC'
    },
    {
        pattern: '^(966){0,1}\\d{6,12}$',
        code: '996',
        text: '沙特阿拉伯',
        des: 'SA'
    },
    {
        pattern: '^(94){0,1}\\d{6,12}$',
        code: '94',
        text: '斯里兰卡',
        des: 'LK'
    },
    {
        pattern: '^(421){0,1}\\d{6,15}$',
        code: '421',
        text: '斯洛伐克',
        des: 'SK'
    },
    {
        pattern: '^(386){0,1}\\d{6,15}$',
        code: '386',
        text: '斯洛文尼亚',
        des: 'SI'
    },
    {
        pattern: '^(268){0,1}\\d{6,15}$',
        code: '268',
        text: '斯威士兰',
        des: 'SZ'
    },
    {
        pattern: '^(992){0,1}\\d{6,15}$',
        code: '992',
        text: '塔吉克斯坦',
        des: 'TJ'
    },
    {
        pattern: '^(66){0,1}[13456789]\\d{7,8}$',
        code: '66',
        text: '泰国',
        des: 'TH'
    },
    {
        pattern: '^(255){0,1}\\d{6,15}$',
        code: '255',
        text: '坦桑尼亚',
        des: 'TZ'
    },
    {
        pattern: '^(1868){0,1}\\d{6,15}$',
        code: '1868',
        text: '特立尼达和多巴哥',
        des: 'TT'
    },
    {
        pattern: '^(90){0,1}\\d{6,12}$',
        code: '90',
        text: '土耳其',
        des: 'TR'
    },
    {
        pattern: '^(993){0,1}\\d{6,15}$',
        code: '993',
        text: '土库曼斯坦',
        des: 'TM'
    },
    {
        pattern: '^(216){0,1}\\d{6,12}$',
        code: '216',
        text: '突尼斯',
        des: 'TN'
    },
    {
        pattern: '^(502){0,1}\\d{6,15}$',
        code: '502',
        text: '危地马拉',
        des: 'GT'
    },
    {
        pattern: '^(58){0,1}\\d{6,12}$',
        code: '58',
        text: '委内瑞拉',
        des: 'VE'
    },
    {
        pattern: '^(673){0,1}\\d{6,15}$',
        code: '673',
        text: '文莱',
        des: 'BN'
    },
    {
        pattern: '^(256){0,1}\\d{6,15}$',
        code: '256',
        text: '乌干达',
        des: 'UG'
    },
    {
        pattern: '^(380){0,1}[3-79]\\d{8,9}$',
        code: '380',
        text: '乌克兰',
        des: 'UA'
    },
    {
        pattern: '^(598){0,1}\\d{6,15}$',
        code: '598',
        text: '乌拉圭',
        des: 'UY'
    },
    {
        pattern: '^(998){0,1}\\d{6,15}$',
        code: '998',
        text: '乌兹别克斯坦',
        des: 'UZ'
    },
    {
        pattern: '^(34){0,1}\\d{6,12}$',
        code: '34',
        text: '西班牙',
        des: 'ES'
    },
    {
        pattern: '^(30){0,1}\\d{6,12}$',
        code: '30',
        text: '希腊',
        des: 'GR'
    },
    {
        pattern: '^(36){0,1}\\d{6,12}$',
        code: '36',
        text: '匈牙利',
        des: 'HU'
    },
    {
        pattern: '^(1876){0,1}\\d{6,15}$',
        code: '1876',
        text: '牙买加',
        des: 'JM'
    },
    {
        pattern: '^(967){0,1}\\d{6,15}$',
        code: '967',
        text: '也门',
        des: 'YE'
    },
    {
        pattern: '^(39){0,1}[37]\\d{8,11}$',
        code: '39',
        text: '意大利',
        des: 'IT'
    },
    {
        pattern: '^(972){0,1}\\d{6,12}$',
        code: '972',
        text: '以色列',
        des: 'IL'
    },
    {
        pattern: '^(91){0,1}\\d{6,12}$',
        code: '91',
        text: '印度',
        des: 'IN'
    },
    {
        pattern: '^(62){0,1}[2-9]\\d{7,11}$',
        code: '62',
        text: '印度尼西亚',
        des: 'ID'
    },
    {
        pattern: '^(44){0,1}[347-9](\\d{8,9}|\\d{11,12})$',
        code: '44',
        text: '英国',
        des: 'GB'
    },
    {
        pattern: '^(1284){0,1}\\d{6,12}$',
        code: '1284',
        text: '英属维尔京群岛',
        des: 'VG'
    },
    {
        pattern: '^(962){0,1}\\d{6,12}$',
        code: '962',
        text: '约旦',
        des: 'JO'
    },
    {
        pattern: '^(84){0,1}[1-9]\\d{6,9}$',
        code: '84',
        text: '越南',
        des: 'VN'
    },
    {
        pattern: '^(260){0,1}\\d{6,15}$',
        code: '260',
        text: '赞比亚',
        des: 'ZM'
    },
    {
        pattern: '^(235){0,1}\\d{6,15}$',
        code: '235',
        text: '乍得',
        des: 'TD'
    },
    {
        pattern: '^(56){0,1}\\d{6,12}$',
        code: '56',
        text: '智利',
        des: 'CL'
    }
];

export default CodeRegExp;