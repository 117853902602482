import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import history from '../../helpers/history';

import { Layout, Steps, Result, Select, Card, Divider, message, Button, Icon, Spin } from 'antd';
import WrappedEmailForm from './subscription/EmailForm';

import PaymentForm from './subscription/PaymentForm';
import emitter from '../Evt.js';

import './CardSectionStyles.css'

const { Content } = Layout;
const { Step } = Steps;
const { Option } = Select;

var languageMessages = window.appLocale.messages;
class BillingPage extends Component {
    constructor(props) {
        super(props);

        this.stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, { locale: window.webType === 'US' ? 'en' : 'auto' });

        this.state = {
            spinLoadding: true,
            
            currency: 'usd',

            account: '',
            accountOptions: [],

            stepStatus: 'wait', //wait process finish error
            stepIndex: 1,

            dataSource: [],
        }
    }
    
    componentDidMount() {
        emitter.addListener('initPage', () => {
            this.init();
        });
    }

    componentWillMount() {
        this.init();
    }

    init() {
        this.user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        
        if (history.location.state !== undefined) {
            let subscriptions = history.location.state.subscriptions;
            this.props.dispatch({ type: "action_get_stripe_key", payload: { u: this.user.username, t: subscriptions } });
        }
    }

    componentWillUnmount() {
        
    }

    componentWillReceiveProps(props) {
        var { status, stepStatus, stepIndex, customers } = props;

        switch (status) {
            case 'strioe_success':
                var account = '';
                var accountOptions = [];
                customers = customers !== undefined && customers !== null ? customers : [];
                if (customers.length > 0) {
                    account = customers[0];

                    accountOptions = customers.map(d => <Option key={d}>{d}</Option>);
                }

                this.setState({ account, accountOptions, spinLoadding: false });
                break;
            case 'strioe_failed':
                break;
            case 'create_subscription_success':
                this.setState({ stepStatus: 'finish', stepIndex: 3 });
                break;
            case 'create_subscription_failed':
                this.setState({ stepStatus: 'error', stepIndex: 3 });
                break;
            case 'retry_invoice_success':
                this.setState({ stepStatus: 'finish', stepIndex: 3 });
                break;
            case 'retry_invoice_failed':
                this.setState({ stepStatus: 'error', stepIndex: 3 });
                break;
            case 'pay_processing':
                this.setState({ stepStatus, stepIndex });
                break;
            case 'next_processing':
                this.setState({ stepStatus, stepIndex });
                break;
            case 'email_captcha_success':
                message.success("email captcha get success");
                break;
            case 'email_captcha_failed':
                message.error("email captcha get failed");
                break;
            case 'check_email_success':
                let { account, accountOptions } = this.state;

                customers = customers !== undefined && customers !== null ? customers : [];
                if (customers.length > 0) {
                    account = customers[customers.length - 1];

                    accountOptions = customers.map(d => <Option key={d}>{d}</Option>);
                }

                this.setState({ account, accountOptions });
                break;
            case 'check_email_failed':
                message.error("check email failed");
                break;
        }
    }

    add = (a, b) => {
        var r1, r2, m;
        try { r1 = a.toString().split(".")[1].length } catch (e) { r1 = 0 }
        try { r2 = b.toString().split(".")[1].length } catch (e) { r2 = 0 }
        m = Math.pow(10, Math.max(r1, r2));
        return (a * m + b * m) / m;
    }

    formatPrice(amount) {
        let price = amount;//(amount / 100).toFixed(2);
        let numberFormat = new Intl.NumberFormat(['en-US'], {
            style: 'currency',
            currency: this.state.currency,
            currencyDisplay: 'symbol',
        });
        return numberFormat.format(price);
    }

    handleBackHome = () => {
        history.replace("/");
    }

    handleResubscribe = () => {
        this.setState({ stepStatus: 'wait', stepIndex: 1, spinLoadding: true }, () => {
            this.init();
        });
    }

    handleChange = value => {
        this.setState({
            dataSource: !value || value.indexOf('@') >= 0 ? [] : [`${value}@gmail.com`, `${value}@163.com`, `${value}@qq.com`],
        });
    };

    handleChangeAccount = value => {
        this.setState({ account: value });
    }

    addAccount = () => {
        this.setState({ account: '' });
    }

    handleNext = () => {
        if (this.state.account !== '')
            this.props.dispatch({ type: "action_next_processing", payload: { stepStatus: 'wait', stepIndex: 2 } });
    }

    renderSuccess() {
        let total = 0;
        let subsriptionNo = '';
        let html = [];
        if (this.props.detailed !== undefined && this.props.detailed !== null) {
            let detailed = this.props.detailed;

            subsriptionNo = detailed['no'];

            let items = detailed['item'];
            for (let kk in items) {
                let amount = this.formatPrice(items[kk].n);
                html.push(<fieldset key={items[kk].i} className="with-state">
                    <label>
                        <span>{'Title'}</span>
                        <div className="field">{items[kk].t}</div>
                    </label>
                    <label>
                        <span>{'Months'}</span>
                        <div className="field">{this.props.months}</div>
                    </label>
                    <label>
                        <span>{'Amount'}</span>
                        <div className="field"><span>{amount}</span></div>
                    </label>
                    <label>
                        <span>{'Expired date'}</span>
                        <div className="field">{items[kk].e}</div>
                    </label>
                </fieldset>);
                
                total = this.add(total, items[kk].n);
            }
        }

        total = this.formatPrice(total);

        let status = 'error';
        let subject = 'Subscription service failed';
        let btn = [<Button key="resubscribe" onClick={this.handleResubscribe}>Resubscribe</Button>];
        if (this.props.status === 'create_subscription_success') {
            status = 'success';
            subject = 'Subscription service succeeded';
            btn = [<Button key="resubscribe" onClick={this.handleBackHome}>Back home</Button>];
        }

        return (<div className="common-payment-success">
            <Result status={status} title={subject} subTitle="" extra={btn}>
                <div className="common-payment-cont card-section">
                    <div><span className='result-title'>{'Subsription no：' + subsriptionNo}</span><span className='result-total'>{'Total：' + total}</span></div>
                    {html}
                </div>
            </Result>
        </div>);
    };

    renderForm() {
        if (this.state.stepIndex === 1) {
            let { account, accountOptions } = this.state;
            
            if (account !== '') {
                return <Layout className='common-main-content'>
                    <Card title="My account" bordered={false} className='common-card-container'>
                        <div>
                            <Select defaultValue={account}
                                style={{ width: 360 }}
                                placeholder="Select account"
                                onChange={this.handleChangeAccount}
                                dropdownRender={menu => (
                                    <div>
                                        {menu}
                                        <Divider style={{ margin: '4px 0' }} />
                                        <div
                                            style={{ padding: '4px 8px', cursor: 'pointer' }}
                                            onMouseDown={e => e.preventDefault()}
                                            onClick={this.addAccount}
                                        >
                                            <Icon type="plus" /> Add account
                                        </div>
                                    </div>
                                )}
                            >
                                {accountOptions}
                            </Select>
                            <Button icon="plus" size='small' onClick={this.addAccount} style={{ margin: '0 10px', height: 30, width: 36 }} />
                        </div>
                        <div style={{ margin: '20px 0' }}>
                            <Button type="primary" onClick={this.handleNext}> Next </Button>
                        </div>
                    </Card>
                </Layout>
            } else {
                return <Layout className='common-main-content'>
                    <Card title="My account" bordered={false} className='common-card-container'>
                        <WrappedEmailForm {...this.props} />
                    </Card>
                </Layout>
            }
        } else if (this.state.stepIndex === 2){
            return <Layout className='common-main-content'>
                <Elements stripe={this.stripePromise}>
                    <PaymentForm {...this.props} account={this.state.account} />
                </Elements>
            </Layout>
        }
    }

    render() {
        return (<Content className='main-container'>
            <Spin spinning={this.state.spinLoadding}>
                <div className='common-page-header'>
                    <Steps current={this.state.stepIndex} status={this.state.stepStatus}>
                        <Step title="Account information" />
                        <Step title="Billing information" />
                        <Step title="Payment and confirmation" />
                        <Step title="Finished" />
                    </Steps>
                </div>
                {this.state.stepIndex === 3 ? this.renderSuccess() : this.renderForm()}
            </Spin>
        </Content>)
    }
}

function mapStateToProps(state) {
    return state.charge;
}

const page = connect(mapStateToProps)(BillingPage);
export { page as default }