import { put, call, takeLatest } from 'redux-saga/effects'
import { post } from '../../helpers/server'

function* clearCache(action) {
    try {
        const resp = yield call(post, "/test/clear", action.payload);
        if (resp && resp.status === 1) {
            yield put({ type: 'clear_cache_success' });
        } else {
            yield put({ type: 'clear_cache_failed' });
        }
    } catch (e) {
        yield put({ type: 'clear_cache_failed' });
    }
}

function* auth() {
    yield takeLatest('action_clear_cache', clearCache);
    
}

export default auth;