import React, { Component } from 'react';
import { Select, Divider } from 'antd';

const Option = Select.Option;

class StockOrderPage extends Component {
    constructor(props) {
        super(props);

        this.languageMessages = window.appLocale.messages;

        this.defaultOrderType = [
            { key: '$LATEST_PRICE', value: this.languageMessages['latest.price'] },
            { key: '$RANGE_PERCENT', value: this.languageMessages['quote.change'] },
            { key: '$VOLUME_NUM', value: this.languageMessages['volume'] },
            { key: '$AMOUNT_NUM', value: this.languageMessages['turnover'] },
            { key: '$TURNOVER_RATE', value: this.languageMessages['turnover.rate'] },
            { key: '$HIST_VOLATILITY', value: this.languageMessages['volatility'] }
        ];
        
        this.state = {
            orderType: '$VOLUME_NUM', // 最新价、涨跌幅、成交量、成交额、换手率、波动率
            orderDirection: '0',

            orderTypeItem: [], // 排序列表

            isResetItem: true,
        };
    }

    componentWillMount() {
        var { orderType, orderDirection } = this.state;

        this.props.stockOrder({
            orderType: orderType,
            orderDirection: orderDirection
        });
    }

    componentWillReceiveProps(props) {
        var { filterString } = props;
        var { isResetItem } = this.state;

        if (!isResetItem) {
            this.setState({ isResetItem: true });
        } else {
            var customVariable = [];
            if (this.defaultOrderType.length > 0) {
                for (var k in this.defaultOrderType) {
                    customVariable.push({ key: this.defaultOrderType[k].key, value: this.defaultOrderType[k].value });
                }
            }
            
            filterString = filterString === undefined ? '' : '';
            
            var startPos = filterString.indexOf("$");
            while (startPos > -1) {
                var tmpString = '';
                var endPos = filterString.indexOf("$", startPos + 1);
                if (endPos > -1) {
                    tmpString = filterString.slice(startPos, endPos);
                } else {
                    tmpString = filterString.slice(startPos);
                }

                var isPos = false;
                var tmpPos = tmpString.indexOf("@");
                var splitPos = tmpString.indexOf(":");
                if (tmpPos > -1) {
                    if (tmpPos > splitPos && splitPos > -1) {
                        isPos = true;
                    }
                } else {
                    if (splitPos > -1) {
                        isPos = true;
                    }
                }

                if (isPos) {
                    tmpString = tmpString.slice(0, splitPos);
                    tmpString = tmpString.replace(/(^\s*)|(\s*$)/g, "");
                    customVariable.push({ key: tmpString, value: tmpString });
                }
                startPos = endPos;
            }

            var orderTypeItem = [];
            if (customVariable.length > 0) {
                for (var k in customVariable) {
                    orderTypeItem.push(<Option key={customVariable[k].key}>{customVariable[k].value}</Option>);
                }
            }
            this.setState({ orderTypeItem });
        }
    }

    handleOrderTypeChange = value => {
        var { orderDirection } = this.state;

        this.setState({
            orderType: value.key,
            isResetItem: false
        }, () => {
            this.props.stockOrder({
                orderType: value.key,
                orderDirection: orderDirection
            });
        });
    }

    handleOrderDirectionChange = value => {
        var { orderType } = this.state;

        this.setState({
            orderDirection: value.key,
            isResetItem: false
        }, () => {
            this.props.stockOrder({
                orderType: orderType,
                orderDirection: value.key
            });
        });
    }

    render() {
        return (<div>
            <div className='common-title' style={{ padding: '8px 0' }}><Divider type="vertical" />{this.languageMessages['sort.filter']}</div>
            <label>{this.languageMessages['press']}</label>
            <Select labelInValue size='small' disabled={this.props.isUseOrder}
                defaultValue={{ key: this.state.orderType }}
                style={{ width: 120, padding: '0 10px' }}
                onChange={this.handleOrderTypeChange}>
                {this.state.orderTypeItem}
            </Select>
            <label>{this.languageMessages['sort,filter']}</label>
            <Select labelInValue size='small' disabled={this.props.isUseOrder}
                defaultValue={{ key: this.state.orderDirection }}
                style={{ width: 80, padding: '0 10px' }}
                onChange={this.handleOrderDirectionChange}>
                <Option value="0">{this.languageMessages['before']}</Option>
                <Option value="1">{this.languageMessages['after']}</Option>
            </Select>
            <label>{this.languageMessages['ranking']}</label>
        </div>);
    }
}

export { StockOrderPage as default }