import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Select } from 'antd';
import history from '../../helpers/history'

import { FormattedMessage, defineMessages } from 'react-intl';
import CodeRegExp from './Countrys';

import './flags.css';

const { Option } = Select;

const messages = defineMessages({
    login: {
        id: 'intl.login',
        defaultMessage: '登录',
    },
    logining: {
        id: 'intl.logining',
        defaultMessage: '登录中',
    },
    phone: {
        id: 'intl.register.phone',
        defaultMessage: '手机号',
    },
    captcha: {
        id: 'intl.register.captcha',
        defaultMessage: '验证码',
    },
    getCaptcha: {
        id: 'intl.register.getCaptcha',
        defaultMessage: '获取验证码',
    },
    rememberMe: {
        id: 'intl.login.rememberMe',
        defaultMessage: '记住我',
    },
    forgotPassword: {
        id: 'intl.login.forgotPassword',
        defaultMessage: '忘记密码？',
    },
    verificationCodeLogin: {
        id: 'intl.login.verificationCodeLogin',
        defaultMessage: '手机验证码登录',
    },
    userNameLogin: {
        id: 'intl.login.userNameLogin',
        defaultMessage: '用户名登录',
    },

});

const USERNAME = 'USERNAME';
const CAPTCHA = 'CAPTCHA';

function hasErrors(loginMode, fieldsError) {
    if (loginMode === USERNAME) {
        return Object.keys(fieldsError).some(field => fieldsError[field] && ['phone', 'captcha'].indexOf(field) === -1);
    } else {
        return Object.keys(fieldsError).some(field => fieldsError[field] && ['username', 'password'].indexOf(field) === -1);
    }
}

class LoginForm extends Component {
    constructor(props) {
        super(props);

        this.languageMessages = window.appLocale.messages;

        this.state = {
            loginMode: USERNAME,
            phoneRegExp: '',
            phonePlaceholder: '',

            prefix: 'CN',
        }
    }

    componentDidMount() {
        this.props.form.validateFields();

        var tmpCodeRegExp = CodeRegExp.filter((e) => { return e.des === this.state.prefix });
        if (tmpCodeRegExp.length > 0) {
            var phonePlaceholder = this.languageMessages['phone.china.only'];
            if (window.webType !== 'CN') {
                phonePlaceholder = this.languageMessages['phone'];
            }

            var phoneRegExp = tmpCodeRegExp[0].pattern;
            this.setState({
                phoneRegExp,
                phonePlaceholder
            });
        }
    }

    toForgetPassword = e => {
        e.preventDefault();
        this.props.loginEvent({ visible: false });
        history.push("/forget");
    }
    
    switchLoginMode = e => {
        e.preventDefault();
        let { loginMode } = this.state;
        if (loginMode === USERNAME) {
            loginMode = CAPTCHA;
        } else {
            loginMode = USERNAME;
        }
        
        this.setState({ loginMode: loginMode }, () => {
            this.props.form.validateFields();
        });
    }

    getCaptcha = e => {
        e.preventDefault();
        let { isGetCaptcha } = this.props;
        this.props.form.validateFields(['phone'], (err, values) => {
            if (!err) {
                if (isGetCaptcha) {
                    var prefix = this.props.form.getFieldValue('prefix');

                    //发送手机验证码
                    this.props.dispatch({ type: "action_login_captcha", payload: { UserName: values.phone, Prefix: prefix } });
                }
            }
        });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { loginMode } = this.state;
                var requestData = { LoginMode: loginMode, Prefix: values.prefix, RememberMe: values.remember };
                if (loginMode === USERNAME) {
                    requestData.UserName = values.username;
                    requestData.Password = values.password;
                } else {
                    requestData.UserName = values.phone;
                    requestData.Captcha = values.captcha;
                }
                // login
                this.props.dispatch({ type: "action_login", payload: requestData });
            }
        });
    };

    handlePrefixSelector = value => {
        var tmpCodeRegExp = CodeRegExp.filter((e) => { return e.des === value });
        if (tmpCodeRegExp.length > 0) {
            var phoneRegExp = tmpCodeRegExp[0].pattern;
            this.setState({
                phoneRegExp,
                prefix: value
            }, () => {
                this.props.form.validateFields();
            });
        }

        this.props.handleChange({ error: '' });
    }

    handleChange = () => {
        this.props.handleChange({ error: '' });
    }

    renderLogins(username) {
        return null;
        /**
        getFieldDecorator('username', {
                initialValue: username,
                rules: [{ required: true, message: this.languageMessages['please.input.username/phone'] }],
            })(<Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={this.languageMessages['username/phone']} onChange={this.handleChange} />)


        getFieldDecorator('username', {
                        initialValue: username,
                        rules: [
                            { required: true, message: this.languageMessages['please.input.username/phone'] },
                            { pattern: this.state.phoneRegExp, message: this.languageMessages['phone.format.failed'] }
                        ],
                    })(<Input addonBefore={prefixSelector} style={{ width: '100%' }} placeholder={this.languageMessages['username/phone']} onChange={this.handleChange} />)
            */
    }

    render() {
        let { loginMode } = this.state;
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        
        let prefix = this.state.prefix;
        let prefixCode = '0';
        let username = this.props.username;
        if (username !== undefined && username !== '') {
            let users = username.split('-');
            if (users.length > 1) {
                prefixCode = users[0];
                username = users[1];
            }
        }

        var isFlag = false;
        var options = [];
        for (var ii in CodeRegExp) {
            if (prefixCode === CodeRegExp[ii].code) {
                if (!isFlag) {
                    prefix = CodeRegExp[ii].des;
                    isFlag = true;
                }
            }
            var className = 'flag flag-' + CodeRegExp[ii].des.toLowerCase();
            var title = <span className={className}></span>;
            var content = <span>{title} {' +' + CodeRegExp[ii].code}</span>;
            options.push(<Option key={ii} value={CodeRegExp[ii].des} label={content}>{content}</Option>);
        }
        /**
        if (window.webType === 'US') {
            
        } else {
            options.push(<Option key='0' value='CN' label='86'>+86</Option>);
        }
        */

        const prefixSelector = getFieldDecorator('prefix', { initialValue: prefix })(
            <Select style={{ width: 120 }}
                optionLabelProp='label'
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ zIndex: 1000, minWidth: 100 }}
                onChange={this.handlePrefixSelector}>
                {options}
            </Select>,
        );

        var usernameError = '';
        var passwordError = '';
        var phoneError = '';
        var captchaError = '';
        if (loginMode === USERNAME) {
            usernameError = isFieldTouched('username') && getFieldError('username');
            passwordError = isFieldTouched('password') && getFieldError('password');
        } else {
            phoneError = isFieldTouched('phone') && getFieldError('phone');
            captchaError = isFieldTouched('captcha') && getFieldError('captcha');
        }

        return (<Form onSubmit={this.handleSubmit} className="login-form">
                {loginMode === USERNAME ? (<div>
                <Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError || ''}>
                    {getFieldDecorator('username', {
                        initialValue: username,
                        rules: [
                            { required: true, message: this.languageMessages['please.input.username/phone'] },
                            { pattern: this.state.phoneRegExp, message: this.languageMessages['phone.format.failed'] }
                        ],
                    })(<Input addonBefore={prefixSelector} style={{ width: '100%' }} placeholder={this.languageMessages['username/phone']} onChange={this.handleChange} />)}
                    
                </Form.Item>
                <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
                    {getFieldDecorator('password', {
                        initialValue: this.props.password,
                        rules: [
                            { required: true, message: this.languageMessages['please.input.password'] }
                        ],
                    })(<Input.Password prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={this.languageMessages['account.password']} onChange={this.handleChange} />)}
                </Form.Item>
                </div>) : (<div>
                    <Form.Item validateStatus={phoneError ? 'error' : ''} help={phoneError || ''}>
                        {getFieldDecorator('phone', {
                            initialValue: username,
                            rules: [
                                { required: true, message: this.languageMessages['please.input.phone'] },
                                { pattern: this.state.phoneRegExp, message: this.languageMessages['phone.format.failed'] }
                            ],
                        })(<Input addonBefore={prefixSelector} style={{ width: '100%' }} placeholder={this.state.phonePlaceholder} onChange={this.handleChange} />)}
                </Form.Item>
                <Form.Item validateStatus={captchaError ? 'error' : ''} help={captchaError || ''}>
                    <Row gutter={8}>
                        <Col span={12}>
                            {getFieldDecorator('captcha', {
                                rules: [
                                    { required: true, message: this.languageMessages['please.input.captcha'] },
                                ],
                                })(<Input placeholder={this.languageMessages['phone.captcha']} onChange={this.handleChange} autoComplete={'off'} />)}
                        </Col>
                        <Col span={12}>
                            {this.props.isGetCaptcha ? (<Button onClick={this.getCaptcha}><FormattedMessage {...messages.getCaptcha} /></Button>) : (<Button disabled onClick={this.getCaptcha}>{this.props.count}s</Button>)}
                        </Col>
                    </Row>
                </Form.Item>
                </div>)}
                <Form.Item>
                    {getFieldDecorator('remember', { valuePropName: 'checked', initialValue: true, })(<Checkbox><FormattedMessage {...messages.rememberMe} /></Checkbox>)}
                    <Button size='small' htmlType="submit" className="login-form-button" loading={this.props.logining} disabled={this.props.logining ? this.props.logining : (hasErrors(loginMode, getFieldsError()))}>
                        {this.props.logining ? (<FormattedMessage {...messages.logining} />) : (<FormattedMessage {...messages.login} />)}
                    </Button>
                    <a href="javascript:;" onClick={this.switchLoginMode}>{loginMode === USERNAME ? (<FormattedMessage {...messages.verificationCodeLogin} />) : (<FormattedMessage {...messages.userNameLogin} />)}</a>
                    <a className="login-form-forgot" href="javascript:;" onClick={this.toForgetPassword}><FormattedMessage {...messages.forgotPassword} /></a>
                </Form.Item>
            </Form>
        )
    }
}

const WrappedLoginForm = Form.create({ name: 'login_form' })(LoginForm);
export { WrappedLoginForm as default }