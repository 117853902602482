import React, { Component } from 'react';
import { Avatar, Button } from 'antd';

var languageMessages = window.appLocale.messages;

class UserMenu extends Component {
    constructor(props) {
        super(props);
    }

    handleUserClick = () => {
        this.props.userClick();
    };

    render() {
        let username = this.props.username;
        let users = username.split('-');
        if (users.length > 1) {
            username = users[1];
        }

        return (<div className='user-menu' onClick={this.props.userClick}>
            <Avatar className='photo' size="small" src={this.props.photo} />
            {username.replace(/^(...).+(.)$/g,'$1***$2')}
        </div>)
    }
}

function LoginRegisterButtons(props) {
    return (
        <div className='user-menu'>
            <Button size='small' className='btn-dark' onClick={props.onLoginAction}>{languageMessages['intl.login']}</Button>
            <Button size='small' onClick={props.onRegisterAction}>{languageMessages['intl.register']}</Button>
        </div>
    )
}

export { UserMenu, LoginRegisterButtons }