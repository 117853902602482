import React, { Component } from 'react';
import { Row, Col, Radio, Select, Divider, Alert, Icon, Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';

const Option = Select.Option;
const RadioGroup = Radio.Group;

const MaxTagTextLength = 5;
const SelectWidth = 150;

var languageMessages = window.appLocale.messages;

const locale = window.appLocale.locale;
class USSymbolGroupPage extends Component {
    constructor(props) {
        super(props);

        this.html = null;

        this.industryTitle = [];
        this.industryData = [];
        
        this.customGroupsData = [];

        this.state = {
            isRefresh: false,
            stockPoolKey: 'STOCK',

            stockCount: 0,

            stock: '10000',
            industry: {},

            fund: '30000',

            customGroups: '-',

            maxTagPlaceholder: '...',

            isSymbolGroup: true,

            sectorIndustryBtn: 'sector-industry-btn',
            sectorIndustryIcon: 'plus',
            sectorIndustryContent: 'none',
            sectorClass: {},
            industryClass: {},
            industryItemClass: {},
            industrySelectClass: {},

            selectSectorId: 0,
            
            popsTop: 0,
            popsLeft: 0,
        };
    }

    componentDidUpdate() {
        var { isRefresh, stock, industry, fund, customGroups } = this.state;
        if (!isRefresh) {
            var { defaultSymbolGroups } = this.props;

                /**
                for (var kk in sectors) {
                    if (kk >= 100) continue;

                    var data = [];
                    data.push(<Option key={kk}>{languageMessages['select.all']}</Option>);
                    for (var key in sectors[kk].items) {
                        var subGroupName = locale.toLowerCase() === 'en-us' ? sectors[kk].items[key].en : sectors[kk].items[key].cn;

                        data.push(<Option key={key}>{subGroupName}</Option>);
                    }

                    this.industryTitle[kk] = locale.toLowerCase() === 'en-us' ? sectors[kk].en : sectors[kk].cn;
                    this.industryData[kk] = data;
                }
                */

            isRefresh = true;

            var customGroupsItems = JSON.parse(sessionStorage.getItem("CustomStockGroupsCache") || '[]') || [];
            if (customGroupsItems !== null) {
                //自定义自选股
                this.customGroupsData = [];
                if (customGroupsItems.length > 0) {
                    for (let key in customGroupsItems) {
                        if (customGroups === '-') {
                            customGroups = customGroupsItems[key].groupId.toString();
                        }
                        this.customGroupsData.push(<Option key={customGroupsItems[key].groupId}>{customGroupsItems[key].groupName}</Option>);
                    }
                }
            }

            var stockCount = defaultSymbolGroups.stockCount;
            var stockPoolKey = defaultSymbolGroups.stockPoolKey;

            var stockGroups = defaultSymbolGroups.stockGroups;
            if (stockGroups != null) {
                switch (stockPoolKey) {
                    case 'STOCK':
                        var sg = stockGroups.split(';');
                        if (sg.length > 0) {
                            for (var ks in sg) {
                                if (ks == 0) stock = sg[ks];
                                else {
                                    var sug = sg[ks].split(',');
                                    if (sug.length > 0) {
                                        var sh = 0;
                                        if (sug[0] < 100) {
                                            sh = sug[0];
                                        } else {
                                            if (sug[0] >= 1000) {
                                                sh = sug[0].substr(0, 2);
                                            } else {
                                                sh = sug[0].substr(0, 1);
                                            }
                                        }
                                        if(sh > 0) industry[sh] = sg[ks].split(',');
                                    }
                                }
                            }
                        }
                        break;
                    case 'FUND':
                        fund = stockGroups;
                        break;
                    case 'CUSTOM_GROUPS':
                        stockCount = 0;
                        customGroups = stockGroups;
                        if (customGroups !== '-') {
                            var tmpSymbol = customGroupsItems.filter(function (e) { return e.groupId == customGroups; });
                            if (tmpSymbol.length > 0) {
                                var ss = tmpSymbol[0].symbolList.replace(/(^\s*)|(\s*$)/g, "");
                                if (ss !== '') {
                                    var tmpCount = ss.split(',');
                                    stockCount = tmpCount.length;
                                }
                            }
                        }
                        break;
                    default:
                        break;
                }
            }

            if (isRefresh) {
                this.setState({
                    isRefresh,
                    stockPoolKey: stockPoolKey,

                    stockCount: stockCount,

                    stock: stock,
                    industry: industry,

                    fund: fund,

                    customGroups: customGroups
                });
            }
        }
    }

    componentWillReceiveProps(props) {
        var { stockPoolKey, stock, industry, fund, customGroups } = this.state;
        var { defaultSymbolGroups } = props;

        var stockCount = defaultSymbolGroups.stockCount;

        stockPoolKey = defaultSymbolGroups.stockPoolKey;

        var stockGroups = defaultSymbolGroups.stockGroups;
        if (stockGroups != null) {
            switch (stockPoolKey) {
                case 'STOCK':
                    var sg = stockGroups.split(';');
                    if (sg.length > 0) {
                        for (var ks in sg) {
                            if (ks == 0) stock = sg[ks];
                            else {
                                var sug = sg[ks].split(',');
                                if (sug.length > 0) {
                                    var sh = 0;
                                    if (sug[0] < 100) {
                                        sh = sug[0];
                                    } else {
                                        if (sug[0] >= 1000) {
                                            sh = sug[0].substr(0, 2);
                                        } else {
                                            sh = sug[0].substr(0, 1);
                                        }
                                    }
                                    industry[sh] = sg[ks].split(',');
                                }
                            }
                        }
                    }
                    break;
                case 'FUND':
                    fund = stockGroups;
                    break;
                case 'CUSTOM_GROUPS':
                    stockCount = 0;
                    customGroups = stockGroups;
                    if (customGroups !== '-') {
                        var customGroupsItems = JSON.parse(sessionStorage.getItem("CustomStockGroupsCache") || '[]') || [];
                        var tmpSymbol = customGroupsItems.filter(function (e) { return e.groupId == customGroups; });
                        if (tmpSymbol.length > 0) {
                            var ss = tmpSymbol[0].symbolList.replace(/(^\s*)|(\s*$)/g, "");
                            if (ss !== '') {
                                var tmpCount = ss.split(',');
                                stockCount = tmpCount.length;
                            }
                        }
                    }
                    break;
                default:
                    break;
            }
        }

        this.setState({
            stockPoolKey: stockPoolKey,

            stockCount: stockCount,

            stock: stock,
            industry: industry,

            fund: fund,

            customGroups: customGroups
        });
    }

    onSelectStockPool = e => {
        this.setStockGroup('stockPoolKey', e.target.value);

        this.setState({
            stockPoolKey: e.target.value,
        });
    };

    handleStockChange = value => {
        this.setStockGroup('stock', value.key);

        this.setState({
            stock: value.key,
        });
    }

    handleCustomGroupsChange = value => {
        this.setStockGroup('customGroups', value.key);

        this.setState({
            customGroups: value.key,
        });
    }

    setStockGroup(type, value) {
        var { stockPoolKey, stock, industry, fund, customGroups } = this.state;

        switch (type) {
            case 'stockPoolKey':
                stockPoolKey = value;
                break;
            case 'stock':
                stock = value;
                break;
            case 'industry':
                industry = value;
                break;
            case 'customGroups':
                customGroups = value;
                break;
            default:
                break;
        }
        
        var stockGroups = '';
        switch (stockPoolKey) {
            case 'STOCK':
                stockGroups = stock;
                
                for (var kk in industry) {
                    if (industry[kk].indexOf(kk) === -1) {
                        stockGroups += ';' + industry[kk].join(',');
                    }
                }
                break;
            case 'FUND':
                stockGroups = fund;
                break;
            case 'CUSTOM_GROUPS':
                stockGroups = customGroups;
                break;
            default:
                stockGroups = stock;
                break;
        }

        this.props.stockGroup({ stockPoolKey: stockPoolKey, stockGroups: stockGroups });
    }

    handleSectorMouseOver = (e) => {
        let sectorIndustryBtn = 'sector-industry-btn hover';
        let sectorIndustryIcon = 'minus';
        this.setState({ sectorIndustryBtn, sectorIndustryIcon, sectorIndustryContent: 'block' });
    }

    handleSectorMouseOut = (e) => {
        let sectorIndustryBtn = 'sector-industry-btn';
        let sectorIndustryIcon = 'plus';
        this.setState({ sectorIndustryBtn, sectorIndustryIcon, sectorIndustryContent: 'none' });
    }

    HandleMouseOver = (key) => {
        var industry = document.getElementById('industry-' + key);
        if (industry) {

            var h1 = key * 32;

            var h2 = industry.offsetHeight;
            if (h1 > h2) {
                industry.style.top = h1 + 'px';
            }

            let { sectorClass, industryClass } = this.state;
            sectorClass[key] = 'hover';
            industryClass[key] = 'block';
            this.setState({ sectorClass, industryClass });
        }
    }

    HandleMouseOut = (key) => {
        let { sectorClass, industryClass } = this.state;
        sectorClass[key] = '';
        industryClass[key] = 'none';
        this.setState({ sectorClass, industryClass });
    }

    HandleIndustryMouseOver = (industryId) => {
        let { industryItemClass, industrySelectClass } = this.state;

        if (industrySelectClass[industryId] === undefined || industrySelectClass[industryId] === '') {
            industryItemClass[industryId] = 'hover';
        }

        this.setState({ industryItemClass })
    }

    HandleIndustryMouseOut = (industryId) => {
        let { industryItemClass, industrySelectClass } = this.state;

        if (industrySelectClass[industryId] === undefined || industrySelectClass[industryId] === '') {
            industryItemClass[industryId] = '';
        }

        this.setState({ industryItemClass })
    }

    HandleIndustryClick = (sectorId, industryId) => {
        let { industry, industryItemClass, industrySelectClass } = this.state;

        if (industrySelectClass[industryId] === undefined || industrySelectClass[industryId] === '') {
            industrySelectClass[industryId] = 'checked';

            if (industry[sectorId] !== undefined) {
                let industryItems = industry[sectorId];
                industryItems.push(industryId);
                industry[sectorId] = industryItems
            } else {
                industry[sectorId] = [industryId]
            }
            
        } else {
            industrySelectClass[industryId] = '';

            if (industry[sectorId] !== undefined) {
                let industryItems = industry[sectorId];
                if (industryItems.length > 0) {
                    let index = industryItems.indexOf(industryId);
                    if (index > -1) {
                        industryItems.splice(index, 1);
                        if (industryItems.length > 0) {
                            industry[sectorId] = industryItems;
                        } else {
                            delete industry[sectorId];
                        }
                    }
                }
            }
        }

        this.setStockGroup('industry', industry);

        this.setState({ industry, industryItemClass })
    }

    renderSectorIndustry() {
        var { defaultSymbolGroups } = this.props;
        var sectors = defaultSymbolGroups.sectors;

        if (sectors !== undefined && sectors !== null) {
            return <ul className="sector-industry-content" style={{ display: this.state.sectorIndustryContent }}>
                {
                    Object.keys(sectors).map((kk, index) => {
                        let items = sectors[kk];
                        let industryClass = this.state.industryClass[index] === undefined ? 'none' : this.state.industryClass[index];
                        let isCheck = this.state.industry[kk] === undefined ? false : true;
                        return <li key={kk} onMouseOver={() => this.HandleMouseOver(index)} onMouseOut={() => this.HandleMouseOut(index)}>
                            <div className={this.state.sectorClass[index]}><Checkbox checked={isCheck} /><span className='title'>{locale.toLowerCase() === 'en-us' ? items.en : items.cn}</span></div>
                            <div className='industry-content' style={{ display: industryClass }} id={'industry-' + index}><dl>
                                {Object.keys(items.items).map(k => {
                                    let industrySelectClass = this.state.industrySelectClass[k] === undefined ? '' : this.state.industrySelectClass[k];
                                    let industryItemClass = this.state.industryItemClass[k] === undefined ? '' : this.state.industryItemClass[k];
                                    return <dd key={k} className={industrySelectClass + ' ' + industryItemClass}
                                        onClick={() => this.HandleIndustryClick(kk, k)}
                                        onMouseOver={() => this.HandleIndustryMouseOver(k)}
                                        onMouseOut={() => this.HandleIndustryMouseOut(k)}>{locale.toLowerCase() === 'en-us' ? items.items[k].en : items.items[k].cn}</dd>
                                })}
                            </dl></div>
                        </li>
                    })
                }
            </ul>;
        } else {
            return '';
        }
    }

    renderSymbolGroup() {
        return (<div className='common-industry'>
            <label className='common-label'>{languageMessages['index.component']}</label>
            <Select labelInValue
                size='small'
                defaultValue={{ key: this.state.stock }}
                dropdownMatchSelectWidth={false}
                style={{ minWidth: SelectWidth }}
                onChange={this.handleStockChange}>
                <Option key='10000'>{languageMessages['all.stocks']}</Option>
                <Option key='10301'>{languageMessages['d-jones.index']}</Option>
                <Option key='10202'>{languageMessages['nasdaq.100']}</Option>
                <Option key='10401'>{languageMessages['sp.500']}</Option>
                <Option key='10501'>{languageMessages['russell.2000']}</Option>
            </Select>
            <div className='sector-industry-container' onMouseOver={this.handleSectorMouseOver} onMouseOut={this.handleSectorMouseOut}>
                <span className={this.state.sectorIndustryBtn}>
                    <Icon type={this.state.sectorIndustryIcon} /><label>{languageMessages['Sector']}</label>
                </span>
                {this.renderSectorIndustry()}
            </div>
        </div>)
    }

    //自选
    renderCustomGroupsPanel() {
        return (<div style={{ padding: '10px 0' }}>
            <div>
                <label>{languageMessages['optional.stock.pool']}</label>
                <Select labelInValue size='small'
                    defaultValue={{ key: this.state.customGroups }}
                    style={{ width: 180, padding: '0 10px' }}
                    onChange={this.handleCustomGroupsChange}>
                    {this.customGroupsData}
                </Select>
            </div>
        </div>);
    }

    toggleLuisStockPicker = () => {
        var { isSymbolGroup } = this.state;
        isSymbolGroup = isSymbolGroup ? false : true;
        this.setState({ isSymbolGroup });
    }

    renderPoolCount(stockPoolKey, stockCount) {
        if (stockPoolKey === 'STOCK') {
            return <div className='common-title'>
                <span style={{ cursor: 'pointer' }} onClick={this.toggleLuisStockPicker}>
                    <Divider type="vertical" />
                    {languageMessages['stock.pool']}
                    <Icon type={this.state.isSymbolGroup ? 'up' : 'down'} style={{ padding: '0 5px' }} />
                </span>
                <span style={{ fontSize: '14px', padding: '0 10px' }}>
                    (<span style={{ color: 'red' }}><FormattedMessage id="stock.pool.count" values={{ num: stockCount || 0 }} /></span>)
                </span>
            </div>
        } else if (stockPoolKey === 'FUND') {
            return <div className='common-title'>
                <span style={{ cursor: 'pointer' }} onClick={this.toggleLuisStockPicker}>
                    <Divider type="vertical" />
                    {languageMessages['stock.pool']}
                    <Icon type={this.state.isSymbolGroup ? 'up' : 'down'} style={{ padding: '0 5px' }} />
                </span>
                <span style={{ fontSize: '14px', padding: '0 10px' }}>
                    (<span style={{ color: 'red' }}><FormattedMessage id="fund.pool.count" values={{ num: stockCount || 0 }} /></span>)
                </span>
            </div>
        } else {
            return <div className='common-title'>
                <span style={{ cursor: 'pointer' }} onClick={this.toggleLuisStockPicker}>
                    <Divider type="vertical" />
                    {languageMessages['stock.pool']}
                    <Icon type={this.state.isSymbolGroup ? 'up' : 'down'} style={{ padding: '0 5px' }} />
                </span>
                <span style={{ fontSize: '14px', padding: '0 10px' }}>
                    (<span style={{ color: 'red' }}><FormattedMessage id="stock.pool.count" values={{ num: stockCount || 0 }} /></span>)
                </span>
            </div>
        }
    }

    render() {
        var { stockPoolKey, stockCount, isSymbolGroup } = this.state;
        return (<div>
            {this.renderPoolCount(stockPoolKey, stockCount)}
            <div style={{ display: isSymbolGroup ? '' : 'none' }}>
            <RadioGroup onChange={this.onSelectStockPool} value={stockPoolKey}>
                <Radio value={'STOCK'}>{languageMessages['stock']}</Radio>
                <Radio value={'FUND'}>{languageMessages['fund']}</Radio>
                <Radio value={'CUSTOM_GROUPS'}>{languageMessages['optional.stock.pool']}</Radio>
            </RadioGroup>
            {
                stockPoolKey === 'STOCK' ? this.renderSymbolGroup() :
                stockPoolKey === 'CUSTOM_GROUPS' ? this.renderCustomGroupsPanel() : ''
            }
            {this.props.error === 'STOCK_GROUP_ERROR' ? (<Alert message={languageMessages['please.select.stock.pool']} type="error" />) : ''}
            </div>
        </div>)
    }
}

export { USSymbolGroupPage as default }