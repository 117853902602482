import React, { Component } from 'react';
import { Layout, Tree, Tabs, Input, Button, message } from 'antd';

import RotationSentence from './RotationSentence';

import '../../../highlight-within-textarea.css';
import $ from 'jquery';
window.jQuery = $;
require('highlight-within-textarea');

const { Sider } = Layout;

const { TabPane } = Tabs;
const { TreeNode } = Tree;

const TextArea = Input.TextArea;

var languageMessages = window.appLocale.messages;

/**
function getRandomArrayElements(arr, count) {
    var shuffled = arr.slice(0), i = arr.length, min = i - count, temp, index;
    while (i-- > min) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
    }
    return shuffled.slice(min);
}
*/
function uuid() {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";

    var uuid = s.join("");
    return uuid;
}

var specialSymbol = '▲';
var placeholder = '#val';
//解析条件字符串
function ParseString(strings) {
    var condParams = {};

    var numLen = 0; //替换数字的长度
    var index = -1;
    //var sentenceTemplate = strings.replace(/\d+/g, function () {
    strings = strings.replace(/\s/g, '');
    var sentenceTemplate = strings.replace(/(\.)?(\:)?(\d+)(\.)?(\:)?(\d+)?/g, function () {
        if (index === -1) {
            index = arguments[7];
        } else {
            // 上一次长度
            var prevNum = condParams[index];
            numLen += prevNum.length;
            index = arguments[7] - numLen;
        }
        //console.log('arguments', arguments);
        condParams[index] = arguments[0];

        return placeholder + Object.keys(condParams).length;
    });

    return { sentenceTemplate, condParams };
}

let timer = null;

const locale = window.appLocale.locale;
class VernacularHawkeye extends Component {
    constructor(props) {
        super(props);

        this.analysisFormula = []; // 分析公式列表 增量

        this.placeholderTimer = null;
        this.state = {
            luString: [],//JSON.parse(sessionStorage.getItem("LuisStringCache") || '[]') || [],
            checkedKeys: [],

            strategyData1: [],
            strategyData3: [],
            strategyData0: [],

            formulaName: '',
            formulaString: '',

            isLuisAnalysis: false,
            isPresetFormula: false,

            prevName: '', // 输入框的前一个条件
            useFormula: '',

            condParams: {},

            // current tab page
            luisTabsKey: sessionStorage.getItem("luisHawkeyeTabsKey") || '1',
            luisTabsKeys: { '1': [], '3': [], '0': [] },

            isSelectCondition: false,

            rotation: 0,
        }
    }

    iTimer = () => {
        this.placeholderTimer = setInterval(() => {
            let sentences = window.webType === 'US' ? RotationSentence.en : RotationSentence.cn;
            let { rotation } = this.state;
            rotation += 1;
            rotation = rotation >= sentences.length ? 0 : rotation;
            this.setState({ rotation });
        }, 5000);
    };

    componentDidMount() {
        $('.luis-highlight-textarea').highlightWithinTextarea({
            highlight: /\d+(?:\.\d+)?/g
        });

        setTimeout(this.iTimer, 0);
    }

    componentDidUpdate() {
        if (this.state.isSelectCondition) {
            const { inputFormulaName } = this.refs;
            inputFormulaName.focus();
            //textAreaRef.setSelectionRange(0, textAreaRef.value.length);
        }
    }

    componentWillUnmount() {
        clearInterval(this.placeholderTimer);
    }

    aryConcat(data01, data02, isSplice = true) {
        let obj = {};
        let arr = data01.concat(data02);

        var _this = this;
        let newArr = arr.reduce(function (pre, cur) {
            let tmpTis = _this.checkMatchFormula(cur.title);
            if (!obj.hasOwnProperty(tmpTis)) {
                pre.push(cur);
            }
            obj[tmpTis] = true;

            return pre;
        }, []);

        if (isSplice) newArr.splice(10);

        return newArr;
    }

    aryDeduplication(data01, data02, ins) {
        var obj = {};
        data01.forEach(item => {
            Object.keys(item).forEach(subItem => {
                obj.hasOwnProperty(subItem) ? obj[subItem].push(item[subItem]) : obj[subItem] = [item[subItem]]
            })
        })

        let tmpCurrLuString = data02.filter(function (v) { return v.c === ins && obj['key'].indexOf(v.id) !== -1 });
        data02 = data02.filter(function (v) { return v.c !== ins });

        return data02.concat(tmpCurrLuString);
    }

    componentWillReceiveProps(props) {
        var { status, luString, formulaString, sentenceFormula } = props;

        switch (status) {
            case 'get_hawkeye_info_success':
                var strategyData1 = [];
                var strategyData3 = [];
                var strategyData0 = [];
                for (var key in luString) {
                    //var lustringTitle = locale === 'en-US' ? luString[key].us !== '' ? luString[key].us : luString[key].cn : luString[key].cn;
                    var lustringTitle = locale.toLowerCase() === 'en-us' ? luString[key].us : luString[key].cn;
                    if (lustringTitle === '') continue;
                    var sData = { title: lustringTitle, key: luString[key].id };

                    if (luString[key].c === '1')
                        strategyData1.push(sData);
                    if (luString[key].c === '3')
                        strategyData3.push(sData);
                    if (luString[key].c === '0')
                        strategyData0.push(sData);
                }

                var { luisTabsKeys, luisTabsKey } = this.state;

                luisTabsKeys[luisTabsKey] = [];
                this.setState({ strategyData1, strategyData3, strategyData0, luString, checkedKeys: [], luisTabsKeys });
                break;
            case 'hawkeye_change_batch_success':
                var { strategyData1, strategyData3, strategyData0, luisTabsKeys, luisTabsKey } = this.state;

                var currLuString = this.state.luString.filter(function (e) { return e.c !== luisTabsKey; });
                currLuString = currLuString.concat(luString);
                var strategyData = [];
                for (var key in luString) {
                    var lustringTitle = locale.toLowerCase() === 'en-us' ? luString[key].us : luString[key].cn;
                    if (lustringTitle === '') continue;
                    strategyData.push({ title: lustringTitle, key: luString[key].id });
                }
                if (luisTabsKey === '1') strategyData1 = strategyData;
                if (luisTabsKey === '3') strategyData3 = strategyData;
                if (luisTabsKey === '0') strategyData0 = strategyData;
                
                luisTabsKeys[luisTabsKey] = [];
                this.setState({ strategyData1, strategyData3, strategyData0, luString: currLuString, checkedKeys: [], luisTabsKeys });
                break;
            case 'hawkeye_change_condition_success':
                var { strategyData1, strategyData3, strategyData0, prevName, formulaName, isPresetFormula, luisTabsKeys, luisTabsKey, checkedKeys } = this.state;

                luisTabsKeys = { '1': [], '3': [], '0': [] };

                let isLoadLuItems = this.checkFormulaName(prevName, formulaName);

                prevName = '';

                var ids = 0;
                var formulaString = '';
                var useFormula = '';
                var isPresetFormula = false;
                if (sentenceFormula != null) {
                    var resultFormula = this.setUseFormula(sentenceFormula);
                    formulaString = resultFormula['formulaString'];
                    useFormula = resultFormula['useFormula'];
                    prevName = resultFormula['prevName'];
                    isPresetFormula = useFormula.length > 0 ? true : false;

                    ids = sentenceFormula.id;

                    //this.analysisFormula.push({ name: this.state.formulaName, formula: formulaString });
                }

                var tmpIndex = '-1';

                /**
                var strategyData = [];
                for (var key in luString) {
                    var lustringTitle = locale.toLowerCase() === 'en-us' ? luString[key].us : luString[key].cn;
                    if (lustringTitle === '') continue;
                    if (luString[key].c === luisTabsKey) {
                        if (ids === luString[key].id && formulaString === luString[key].f) {
                            tmpIndex = luisTabsKey;
                        }
                        strategyData.push({ title: lustringTitle, key: luString[key].id });
                    }
                }
                */

                let category = null;

                var tmpStrategyData1 = [];
                var tmpStrategyData3 = [];
                var tmpStrategyData0 = [];
                for (var key in luString) {
                    if (category === null) {
                        if (luString[key].c !== '2') category = luString[key].c;
                    }
                    var lustringTitle = locale.toLowerCase() === 'en-us' ? luString[key].us : luString[key].cn;
                    if (lustringTitle === '') continue;
                    if (luString[key].c === '1') {
                        if (ids === luString[key].id && formulaString === luString[key].f) {
                            tmpIndex = '1';
                        }
                        tmpStrategyData1.push({ title: lustringTitle, key: luString[key].id });
                    }
                    if (luString[key].c === '3') {
                        if (ids === luString[key].id && formulaString === luString[key].f) {
                            tmpIndex = '3';
                        }
                        tmpStrategyData3.push({ title: lustringTitle, key: luString[key].id });
                    }
                    if (luString[key].c === '0') {
                        if (ids === luString[key].id && formulaString === luString[key].f) {
                            tmpIndex = '0';
                        }
                        tmpStrategyData0.push({ title: lustringTitle, key: luString[key].id });
                    }
                }

                var currLuString = this.state.luString;
                if (isLoadLuItems) {
                    currLuString = luString.concat(currLuString);

                    if (tmpStrategyData1.length > 0) {
                        strategyData1 = this.aryConcat(tmpStrategyData1, strategyData1);

                        currLuString = this.aryDeduplication(strategyData1, currLuString, '1');
                    }
                    if (tmpStrategyData3.length > 0) {
                        strategyData3 = this.aryConcat(tmpStrategyData3, strategyData3);

                        currLuString = this.aryDeduplication(strategyData3, currLuString, '3');
                    }
                    if (tmpStrategyData0.length > 0) {
                        strategyData0 = this.aryConcat(tmpStrategyData0, strategyData0);

                        currLuString = this.aryDeduplication(strategyData0, currLuString, '0');
                    }

                    checkedKeys = [];
                    if (tmpIndex !== '-1') {
                        checkedKeys.push(ids.toString());
                        luisTabsKeys[tmpIndex] = checkedKeys;
                    }
                }

                if (category !== null) {
                    luisTabsKey = category;
                } else {
                    if (sentenceFormula !== null && sentenceFormula.c !== null) {
                        luisTabsKey = sentenceFormula.c;
                    }
                }

                this.setState({ strategyData1, strategyData3, strategyData0, luString: currLuString, formulaString, useFormula, prevName, checkedKeys, luisTabsKeys, luisTabsKey, condParams: {}, isPresetFormula });
                break;
            case 'analysis_success':
                if (this.state.isLuisAnalysis) {
                    this.analysisFormula.push({ name: this.state.formulaName, formula: formulaString });

                    var useFormula = this.replaceHtml(formulaString);
                    this.setState({ formulaString, useFormula, isLuisAnalysis: false, isPresetFormula: true });
                }
                break;
            case 'analysis_fail':
                this.setState({ isLuisAnalysis: false });
                break;
            default:
                break;
        }
    }

    // 换一批
    onChangeBatch = e => {
        e.preventDefault();

        var user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};

        var { formulaName, luisTabsKey } = this.state;

        var condParams = {};
        if (formulaName != '') {
            var formulaResult = ParseString(formulaName, placeholder);
            condParams = formulaResult['condParams'];
        }

        this.setState({ formulaName, condParams, checkedKeys: [] }, () => {
            this.props.dispatch({ type: "action_hawkeye_change_batch", payload: { UserId: user.username, keywords: formulaName, luisTabsKey: luisTabsKey } });
        });
    }

    // select condition
    onLuisSelect = keys => {
        var { checkedKeys, luString, luisTabsKeys, luisTabsKey } = this.state;
        checkedKeys = keys;

        luisTabsKeys = { '1': [], '3': [], '0': [] };
        luisTabsKeys[luisTabsKey] = checkedKeys;

        var tmpluisString = luString.filter(function (e) { return e.id == checkedKeys; });
        if (tmpluisString.length > 0) {
            var formulaName = locale.toLowerCase() === 'en-us' ? tmpluisString[0].us : tmpluisString[0].cn;
            var formulaString = tmpluisString[0].f;
            var useFormula = this.replaceHtml(formulaString);

            var isPresetFormula = useFormula.length > 0 ? true : false;
            
            this.setState({
                checkedKeys,
                luisTabsKeys,
                formulaName,
                formulaString,
                useFormula,
                prevName: formulaName,
                isPresetFormula: isPresetFormula,
                isSelectCondition: true
            }, () => {
                if (formulaString === '') this.requestSentenceFormula(formulaName, 0);
            });
        }
    }

    // select condition
    onLuisCheck = keys => {
        var { checkedKeys, luString, luisTabsKeys, luisTabsKey } = this.state;

        if (checkedKeys.length > 0) {
            var dd = [];
            keys.forEach(function (o) {
                var tmp = checkedKeys.indexOf(o) !== -1 ? null : o;
                if (tmp !== null) dd.push(tmp);
            });
            checkedKeys = dd;
        } else {
            if (keys.length > 0) checkedKeys.push(keys[0]);
        }

        if (checkedKeys.length > 0) {
            luisTabsKeys = { '1': [], '3': [], '0': [] };
            luisTabsKeys[luisTabsKey] = checkedKeys;

            var tmpluisString = luString.filter(function (e) { return e.id == checkedKeys; });
            if (tmpluisString.length > 0) {
                var lustringTitle = locale.toLowerCase() === 'en-us' ? tmpluisString[0].us : tmpluisString[0].cn;

                var formulaName = lustringTitle;
                var formulaString = tmpluisString[0].f;
                var useFormula = this.replaceHtml(formulaString);

                var isPresetFormula = useFormula.length > 0 ? true : false;
                
                this.setState({
                    checkedKeys,
                    luisTabsKeys,
                    formulaName,
                    formulaString,
                    useFormula,
                    prevName: formulaName,
                    isPresetFormula: isPresetFormula,
                    isSelectCondition: true
                }, () => {
                    if (formulaString === '') this.requestSentenceFormula(formulaName, 0);
                });
            }
        }
    };

    onFocusCondition = () => {
        this.setState({ isSelectCondition: false });
    }

    Trim(str, is_global) {
        var result;
        result = str.replace(/(^\s+)|dao(\s+$)/g, "");
        if (is_global.toLowerCase() == "g") {
            result = result.replace(/\s/g, "");
        }
        return result;
    }

    checkMatchFormula(value) {
        if (value === '') return '';

        let tmpName = value.replace(/(\r|\n)/g, "");
        tmpName = tmpName.replace(/(^\s*)|(\s*$)/g, "");
        tmpName = tmpName.replace(/\s+/g, ' ');
        return tmpName;
    }

    checkFormulaName(prevName, formulaName) {
        if (formulaName === '') return true;

        let tmpName1 = this.checkMatchFormula(prevName);
        let tmpName2 = this.checkMatchFormula(formulaName);

        return tmpName1 === tmpName2 ? false : true;
    }

    // 条件句子改变 生成模板
    onChangeCondition = e => {
        var formulaName = e.target.value;

        this.requestSentenceFormula(formulaName, 500);
    }

    requestSentenceFormula(formulaName, interval) {
        var formulaString = '';

        if (formulaName !== '' && this.analysisFormula.length > 0) {
            var tmpFormulaString = this.analysisFormula.filter(function (e) { return e.name.toUpperCase() === formulaName.toUpperCase(); });
            if (tmpFormulaString.length > 0) {
                formulaString = tmpFormulaString[0].formula;
                var useFormula = this.replaceHtml(formulaString);

                var { luisTabsKeys, luisTabsKey } = this.state;
                luisTabsKeys[luisTabsKey] = [];
                this.setState({
                    checkedKeys: [],
                    luisTabsKeys,
                    formulaName,
                    formulaString,
                    useFormula: useFormula,
                    prevName: formulaName,
                    isPresetFormula: true,
                });
            }
        }

        if (formulaString === '') {
            var condParams = {};
            if (formulaName != '') {
                var formulaResult = ParseString(formulaName, placeholder);
                condParams = formulaResult['condParams'];
                /**
                if (Object.keys(condParams).length > 0) {
                    sentenceTemplate = formulaResult['sentenceTemplate'];
                    sentenceTemplate = sentenceTemplate.replace(/#/g, '%23');
                    //sentenceTemplate = encodeURIComponent(sentenceTemplate);
                }
                */
            }

            var { luisTabsKeys, luisTabsKey, prevName, checkedKeys } = this.state;
            
            let isLoadLuItems = this.checkFormulaName(prevName, formulaName);
            if (isLoadLuItems) {
                checkedKeys = [];
            }
            luisTabsKeys[luisTabsKey] = checkedKeys;

            this.setState({ formulaName, condParams, checkedKeys, luisTabsKeys }, () => {
                var user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};

                clearTimeout(timer);

                let _this = this;
                timer = setTimeout(function () {
                    if (isLoadLuItems) {
                        if (user.username === undefined) user.username = uuid();
                        _this.props.dispatch({ type: "action_hawkeye_change_condition", payload: { UserId: user.username, keywords: formulaName, luisTabsKey: luisTabsKey } });
                    }
                }, interval);
            });
        }
    }

    setUseFormula(sentenceFormula) {
        var { prevName, formulaName, formulaString, condParams } = this.state;

        var sentenceTemplatString = locale.toLowerCase() === 'en-us' ? sentenceFormula.tus : sentenceFormula.tcn;
        var formulaTemplateString = locale.toLowerCase() === 'en-us' ? sentenceFormula.ftus : sentenceFormula.ftcn;

        var status = sentenceFormula.status === undefined || sentenceFormula.status === null ? '111' : sentenceFormula.status;

        var useFormula = '';
        if (Object.keys(condParams).length > 0) {
            let tmpCondParams = {};

            if (sentenceTemplatString != null && sentenceTemplatString !== '') {
                sentenceTemplatString = sentenceTemplatString.replace(/%23val/g, '#val');

                var matchString = [];
                sentenceTemplatString.replace(/#val\d+?/g, function () {
                    matchString.push(arguments[0]);
                });

                let number = 0;
                let index = -1;
                let numLen = 0;
                let tmpSentenceTemplatString = sentenceTemplatString.replace(/\s/g, '');
                tmpSentenceTemplatString = tmpSentenceTemplatString.replace(/\d+?/g, '');
                tmpSentenceTemplatString.replace(/#val?/g, function () {
                    if (index === -1) {
                        index = arguments[1];
                    } else {
                        // 上一次长度
                        //var prevNum = tmpCondParams[index];
                        numLen += 4;
                        index = arguments[1] - numLen;
                    }
                    tmpCondParams[index] = matchString[number];
                    number++;
                });
            }

            //替换参数
            if (formulaTemplateString != null && formulaTemplateString !== '') {
                if (Object.keys(tmpCondParams).length > 0) {
                    var CompareString = {};
                    //var compareString01 = prevName.replace(/\d+/g, '');
                    //var compareString02 = formulaName.replace(/\d+/g, '');

                    let compareString01 = prevName.replace(/\s/g, '');
                    let compareString02 = formulaName.replace(/\s/g, '');
                    compareString01 = compareString01.replace(/(\.)?(\:)?(\d+)(\.)?(\:)?(\d+)?/g, '');
                    compareString02 = compareString02.replace(/(\.)?(\:)?(\d+)(\.)?(\:)?(\d+)?/g, '');
                    if (compareString01 === compareString02) {
                        var prevResult = ParseString(prevName, placeholder);
                        var prevParams = prevResult['condParams'];
                        //console.log('condParams', condParams);
                        //console.log('prevParams', prevParams);
                        //console.log('prevResult[\'sentenceTemplate\']', prevResult['sentenceTemplate']);

                        for (var ii in condParams) {
                            if (prevParams[ii] === undefined) {
                                CompareString[ii] = condParams[ii];
                            } else {
                                if (condParams[ii] !== prevParams[ii]) {
                                    CompareString[ii] = condParams[ii];
                                }
                            }
                        }
                    }

                    useFormula = formulaTemplateString;

                    for (var i in condParams) {
                        if (tmpCondParams[i] === undefined) continue;
                        formulaTemplateString = formulaTemplateString.replace(new RegExp(tmpCondParams[i], 'g'), condParams[i]);

                        var tmpString = condParams[i];
                        if (CompareString[i] !== undefined) {
                            tmpString = specialSymbol + condParams[i] + specialSymbol;
                        }
                        useFormula = useFormula.replace(new RegExp(tmpCondParams[i], 'g'), tmpString);
                    }

                    formulaString = formulaTemplateString;

                    if (formulaString.indexOf(placeholder) !== -1 || useFormula.indexOf(placeholder) !== -1) {
                        formulaString = '';
                        //formulaString.replace(/#val\d+?/g, '');
                        //useFormula = '';

                        //useFormula = useFormula.replace(/#val\d+?/g, specialSymbol + '?' + specialSymbol);
                        useFormula = useFormula.replace(/#val\d+?/g, '?');
                    }
                } else {
                    if (formulaTemplateString.indexOf(placeholder) === -1) {

                        let tmpFormual01 = {};
                        let tmpFormual02 = {};

                        let compareString01 = formulaString.replace(/(\.)?(\:)?(\d+)(\.)?(\:)?(\d+)?/g, function () {
                            tmpFormual01[arguments[7]] = arguments[0];
                        });
                        let compareString02 = sentenceFormula.f.replace(/(\.)?(\:)?(\d+)(\.)?(\:)?(\d+)?/g, function () {
                            tmpFormual02[arguments[7]] = arguments[0];
                        });

                        if (compareString01 === compareString02) {
                            useFormula = compareString02;

                            for (var ii in tmpFormual02) {
                                let tmpString = tmpFormual02[ii];

                                if (tmpString !== tmpFormual01[ii]) {
                                    tmpString = specialSymbol + tmpString + specialSymbol;
                                }

                                useFormula = useFormula.replace('undefined', tmpString);
                            }
                        }
                        formulaString = sentenceFormula.f;
                    } else {
                        formulaString = '';
                    }
                }
            } else {
                if (formulaString !== '') {
                    let tmpFormual01 = {};
                    let tmpFormual02 = {};

                    let compareString01 = formulaString.replace(/(\.)?(\:)?(\d+)(\.)?(\:)?(\d+)?/g, function () {
                        tmpFormual01[arguments[7]] = arguments[0];
                    });
                    let compareString02 = sentenceFormula.f.replace(/(\.)?(\:)?(\d+)(\.)?(\:)?(\d+)?/g, function () {
                        tmpFormual02[arguments[7]] = arguments[0];
                    });

                    if (compareString01 === compareString02) {
                        useFormula = compareString02;

                        for (var ii in tmpFormual02) {
                            let tmpString = tmpFormual02[ii];

                            if (tmpString !== tmpFormual01[ii]) {
                                tmpString = specialSymbol + tmpString + specialSymbol;
                            }

                            useFormula = useFormula.replace('undefined', tmpString);
                        }
                    }
                }

                formulaString = sentenceFormula.f;
            }
        } else {
            if (formulaTemplateString !== null) {
                if (formulaTemplateString.indexOf(placeholder) === -1) {
                    formulaString = sentenceFormula.f;
                } else {
                    formulaString = '';
                }
            } else {
                formulaString = sentenceFormula.f;
            }
        }

        if (useFormula !== '') useFormula = this.replaceHtml(useFormula);
        else useFormula = this.replaceHtml(formulaString);

        formulaString = status === '111' || status === '0' ? formulaString : '';

        return { formulaString, useFormula, prevName: formulaName };
    }

    // 格式化公式 使高亮显示
    replaceHtml(useFormula) {
        if (useFormula === null || useFormula === undefined || useFormula === '') return '';

        var useFormulas = [];
        var items = useFormula.split('\n');
        for (var item in items) {
            if (useFormulas.length > 0) useFormulas.push(<br key={item} />);
            var tmpString = items[item];

            var tmpStrings = tmpString.split(specialSymbol);
            for (var ii in tmpStrings) {
                if (ii > 0 && ii % 2 == 1) {
                    if (tmpStrings[ii] === '?') {
                        useFormulas.push(<span key={'Highlight-' + item + '-' + ii} className='common-red'>{tmpStrings[ii]}</span>);
                    } else {
                        useFormulas.push(<span key={'Highlight-' + item + '-' + ii} className='common-bg'>{tmpStrings[ii]}</span>);
                    }
                } else {
                    useFormulas.push(tmpStrings[ii]);
                }
            }
        }

        return useFormulas;
    }
    // 分析条件
    onIntelligentAnalysis = () => {
        var { formulaName } = this.state;

        this.setState({ isLuisAnalysis: true, formulaString: '' }, () => {
            this.props.LuisAnalysis({
                isLuisAnalysis: true
            });

            var user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
            if (user.username === undefined) {
                this.props.dispatch({ type: "action_non_user" });
            } else {
                this.props.dispatch({ type: "action_luis_analysis", payload: { UserName: user.username, RequestString: formulaName } });
            }
        });
    }

    onUseFormula = () => {
        let { count } = this.props;
        if (count >= 5) {
            message.warning(languageMessages['hawkeyeUpperLimit'], 5);
            return;
        }
        var { formulaName, formulaString, isLuisAnalysis, luisTabsKeys, luisTabsKey } = this.state;

        luisTabsKeys[luisTabsKey] = [];
        
        this.setState({
            formulaName: '',
            formulaString: '',
            useFormula: '',
            checkedKeys: [],
            luisTabsKeys,
            isPresetFormula: false,
            isSelectCondition: true
        }, () => {
            this.props.dispatch({ type: "action_Use_formula_hawkeye", payload: { isLuisAnalysis: isLuisAnalysis, formulaName: formulaName, formulaString: formulaString, vernacularKey: new Date().getTime().toString() } });
        });
    }

    onChangeTabs = luisTabsKey => {
        var { luisTabsKeys } = this.state;
        sessionStorage.setItem("luisHawkeyeTabsKey", luisTabsKey);
        this.setState({ luisTabsKey, checkedKeys: luisTabsKeys[luisTabsKey] });
    }

    renderTreeNodes = data =>
        data.map(item => {
            if (item.children) {
                return (
                    <TreeNode title={item.title} key={item.key} dataRef={item}>
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode {...item} />;
        });

    renderSearchState(strategyData1, strategyData3, strategyData0) {
        var { luisTabsKey } = this.state;

        var isEmpty = true;
        var txt = "";
        switch (luisTabsKey) {
            case "1":
                if (strategyData1.length > 0) {
                    isEmpty = false;
                } else {
                    txt = languageMessages['not.match.mete.price.statements'];
                }
                break;
            case "3":
                if (strategyData3.length > 0) {
                    isEmpty = false;
                } else {
                    txt = languageMessages['not.match.technically.sentences'];
                }
                break;
            case "0":
                if (strategyData0.length > 0) {
                    isEmpty = false;
                } else {
                    txt = languageMessages['not.match.other.statements'];
                }
                break;
        }

        if (isEmpty) {
            return <div style={{ textAlign: 'center', padding: '20px 0', color: 'rgba(0, 0, 0, 0.25)' }}>{txt}</div>
        } else {
            return <div className='luis-condition-change'>
                <Button shape="round" size='small' onClick={this.onChangeBatch} disabled={this.props.isFilterScan || this.state.isLuisAnalysis}>
                    {languageMessages['change.batch']}
                </Button>
            </div>
        }
    }

    renderIntelligentAnalysis(formulaName) {
        return '';
        /**
        if (locale.toLowerCase() === 'en-us') return '';
        else return <Button size='small' onClick={this.onIntelligentAnalysis} loading={this.props.status === 'analysis_commit'} disabled={this.props.status === 'analysis_commit' || formulaName === '' || this.props.isFilterScan || this.state.isPresetFormula}>
            {this.props.status === 'analysis_commit' ? languageMessages['parsing'] : languageMessages['intelligent.analysis']}
        </Button>
        */
    }

    render() {
        var { strategyData1, strategyData3, strategyData0, formulaName, formulaString, useFormula, rotation } = this.state;

        let textareaPlaceholder = languageMessages['vernacular.left.prompt'];

        let statement = languageMessages['input.hawkeye.statement'];
        if (window.webType === 'US') {
            let aryStatement = [];
            let tmpStatement = statement.replace('Highlighted', ',');
            let tmpStatements = tmpStatement.split(',');
            for (let i = 0; i <= tmpStatements.length; i++) {
                if (i === 1) {
                    aryStatement.push(<span key={'statement' + i} className='common-bg'>Highlighted</span>)
                }
                aryStatement.push(tmpStatements[i]);
            }

            statement = aryStatement;

            var val = 'type your complete idea, e.g. "#val".';
            textareaPlaceholder = 'Type key word, e.g. "Bollinger band", then select from ideas in left panel, or';
            textareaPlaceholder += String.fromCharCode(10);
            textareaPlaceholder += val.replace('#val', RotationSentence.en[rotation]);
            textareaPlaceholder += String.fromCharCode(10);
            textareaPlaceholder += 'Monitor your stock. Have fun learning.';
        }
        /**else {
            var val = 'type your complete idea, e.g. "#val".';

            textareaPlaceholder = '变量格式：';
            textareaPlaceholder += String.fromCharCode(10);
            textareaPlaceholder += val.replace('#val', RotationSentence.cn[rotation]);
            textareaPlaceholder += String.fromCharCode(10);
            textareaPlaceholder += '变量格式';
        }*/

        return (<Layout className={window.webType === 'US' ? 'luis hawkeye-luis hawkeye-luis-us' : 'luis hawkeye-luis'}>
                <Sider width={360}>
                    <Tabs size="small" activeKey={this.state.luisTabsKey}
                        animated={false}
                        onChange={this.onChangeTabs}>
                        <TabPane tab={languageMessages['mete.price']} key='1'>
                            <Tree checkable onCheck={this.onLuisCheck} onSelect={this.onLuisSelect} selectedKeys={this.state.luisTabsKeys['1']} checkedKeys={this.state.luisTabsKeys['1']}>{this.renderTreeNodes(strategyData1)}</Tree>
                        </TabPane>
                        <TabPane tab={languageMessages['technically']} key='3'>
                            <Tree checkable onCheck={this.onLuisCheck} onSelect={this.onLuisSelect} selectedKeys={this.state.luisTabsKeys['3']} checkedKeys={this.state.luisTabsKeys['3']}>{this.renderTreeNodes(strategyData3)}</Tree>
                        </TabPane>
                        <TabPane tab={languageMessages['other']} key='0'>
                            <Tree checkable onCheck={this.onLuisCheck} onSelect={this.onLuisSelect} selectedKeys={this.state.luisTabsKeys['0']} checkedKeys={this.state.luisTabsKeys['0']}>{this.renderTreeNodes(strategyData0)}</Tree>
                        </TabPane>
                    </Tabs>
                    {this.renderSearchState(strategyData1, strategyData3, strategyData0)}
                </Sider>
                <Layout className='luis-content'>
                <div style={{ padding: '10px 5px', marginBottom: 5 }}>{statement}</div>
                <TextArea ref="inputFormulaName" className="luis-highlight-textarea" placeholder={textareaPlaceholder} rows={4} onChange={this.onChangeCondition} onFocus={this.onFocusCondition} value={formulaName} />

                    <div style={{ padding: '0px 0' }}>
                    {this.renderIntelligentAnalysis(formulaName)}
                    </div>

                    <div style={{ padding: '10px 5px', marginBottom: 5 }}>{languageMessages['correspond.formula']}</div>
                    <div className='common-edit'>{useFormula}</div>
                    <div style={{ padding: '10px 0' }}>
                    <Button size='small' onClick={this.onUseFormula} disabled={formulaString === '' || this.props.isFilterScan || this.state.isLuisAnalysis}>{languageMessages['Add to conditions']}</Button>
                    </div>
                </Layout>
            </Layout>);
    }
}

export { VernacularHawkeye as default }