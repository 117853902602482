import React, { Component } from 'react';
import { Row, Col, Tooltip, Divider } from 'antd';

var languageMessages = window.appLocale.messages;

const locale = window.appLocale.locale;
class StockFormulaPage extends Component {
    render() {
        var { conceptId } = this.props;

        // 策略库解释
        this.conceptExplanations = JSON.parse(sessionStorage.getItem("ConceptExplanationsCache") || '[]') || [];

        // 策略库
        this.concepts = JSON.parse(sessionStorage.getItem("ConceptsCache") || '{}') || {};

        var conceptStrategy = [];
        for (var kk in this.concepts) {
            conceptStrategy = this.concepts[kk].filter((e) => e.key == conceptId);
            if (conceptStrategy.length > 0) break;
        }

        var comment = '';
        var html = [];
        if (conceptStrategy.length > 0) {
            var filterString = [];
            if (conceptStrategy[0].filterString !== '') {
                var explanations = conceptStrategy[0].filterString.split(',');
                if (explanations.length > 0) {
                    for (var num in explanations) {
                        filterString.push(this.conceptExplanations[explanations[num]]);
                    }
                }
            }

            comment = conceptStrategy[0].comment;

            var index = 0;
            for (var kk in filterString) {
                var filterStringTitle = locale.toLowerCase() === 'en-us' ? filterString[kk].us : filterString[kk].cn;

                if (filterStringTitle !== '') {
                    var text = <span>{filterStringTitle}</span>;
                    html.push(<Tooltip overlayClassName='common-explanation' key={index} placement="bottom" title={text}><span className='common-formula common-formula-explanation'>{filterString[kk].formula}</span></Tooltip>);
                } else {
                    html.push(<Tooltip overlayClassName='common-explanation' key={index} placement="bottom"><span className='common-formula'>{filterString[kk].formula}</span></Tooltip>);
                    if (filterString[kk].formula === ';') html.push(<br key={'br_'+index} />);
                }
                index++;
            }
        }
        
        return (<Row gutter={1}>
            <Col span={14}>
                <div className='common-title'><Divider type="vertical" />{languageMessages['strategy.formula']}</div>
                <div className='common-edit'>{html}</div>
            </Col>
            <Col span={10}>
                <div className='common-title'>{languageMessages['comment']}</div>
                <div className='common-edit'>{comment}</div>
            </Col>
        </Row>);
    }
}

export { StockFormulaPage as default }