export default function member(state = {}, action) {
    switch (action.type) {
        case "get_captcha":
            return { ...state, status: 'Get_Captcha', state: action.status };

        case "login_commit":
            return { ...state, status: "Logining" };
        case "login_success":
            return { ...state, status: "Login_Success", user: action.user, token: action.token };
        case "login_failed":
            return { ...state, status: "Login_Failed", state: action.status };

        case "register_commit":
            return { ...state, status: "Registered" };
        case "register_success":
            return { ...state, status: "Register_Success", user: action.user, token: action.token };
        case "register_failed":
            return { ...state, status: "Register_Failed", state: action.status };

        case "forget_commit":
            return { ...state, status: "ResetPassword" };
        case "forget_success":
            return { ...state, status: "Forget_Success" };
        case "forget_failed":
            return { ...state, status: "Forget_Failed", state: action.status };

        case "get_permission_success":
            return { ...state, status: 'get_permission_success', permission: action.permission, isVip: action.isVip };
        case "get_permission_failed":
            return { ...state, status: 'get_permission_failed', state: action.status };

        case "payment_commit":
            return { ...state, status: 'payment_commit' };
        case "payment_success":
            return { ...state, status: 'payment_success', permission: action.permission };
        case "payment_failed":
            return { ...state, status: 'payment_failed', state: action.status, permission: action.permission };

        case "update_user_success":
            return { ...state, status: 'update_user_success', user: action.user };
        case "update_user_failed":
            return { ...state, status: 'update_user_failed', state: action.status };

        case "get_account_success":
            return { ...state, status: 'get_account_success', accounts: action.accounts };
        case "get_account_failed":
            return { ...state, status: 'get_account_failed', state: action.status };

        case "get_subscription_success":
            return { ...state, status: 'get_subscription_success', subscriptions: action.sc };
        case "get_subscription_failed":
            return { ...state, status: 'get_subscription_failed', state: action.status };

        case "cancel_subscription_success":
            return { ...state, status: 'cancel_subscription_success', id: action.i };
        case "cancel_subscription_failed":
            return { ...state, status: 'cancel_subscription_failed', state: action.status };

        case "get_message_success":
            return { ...state, status: 'get_message_success', messages: action.messages };
        case "get_message_failed":
            return { ...state, status: 'get_message_failed', state: action.status };

        case "send_message_success":
            return { ...state, status: 'send_message_success' };
        case "send_message_failed":
            return { ...state, status: 'send_message_failed', state: action.status };

        case "updatePassword_commit":
            return { ...state, status: 'updatePassword_commit' };
        case "update_password_success":
            return { ...state, status: 'update_password_success', user: action.user };
        case "update_password_failed":
            return { ...state, status: 'update_password_failed', state: action.status };
            
        case "get_phone_captcha":
            return { ...state, status: 'get_phone_captcha', state: action.status };

        case "bindPhone_commit":
            return { ...state, status: 'bindPhone_commit' };
        case "bind_phone_success":
            return { ...state, status: 'bind_phone_success', user: action.user };
        case "bind_phone_failed":
            return { ...state, status: 'bind_phone_failed', state: action.status };

        case "get_coupon_success":
            return { ...state, status: 'get_coupon_success', coupon: action.coupon };
        case "get_coupon_failed":
            return { ...state, status: 'get_coupon_failed', state: action.status };

        case "logout_success":
            return { ...state, status: "Logout_Success" };
        case "logout_failed":
            return { ...state, status: "Logout_Failed" };

        case "sendHubMessage":
            return { ...state, status: "sendHubMessage", data: action.data };

        default:
            return state;
    }
}