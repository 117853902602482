import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Card, Alert, Row, Col } from 'antd';
import WrappedForgetForm from '../../pannels/form/ForgetForm';
import ForgetSuccess from '../../pannels/form/ForgetSuccess';
import { FormattedMessage, defineMessages } from 'react-intl';
import history from '../../helpers/history';

const { Content } = Layout;

const messages = defineMessages({
    resetPassword: {
        id: 'intl.resetPassword',
        defaultMessage: '重置密码',
    },
    freeRegister: {
        id: 'intl.register.free',
        defaultMessage: '免费注册',
    },
    getVerificationCodeFailed: {
        id: 'get.verification.code.failed',
        defaultMessage: '获取验证码失败',
    },
    phoneNotRegister: {
        id: 'phone.not.register',
        defaultMessage: '手机未注册',
    },
    phoneFormatFailed: {
        id: 'phone.format.failed',
        defaultMessage: '手机号格式错误',
    },
    resetPasswordSuccess: {
        id: 'reset.password.success',
        defaultMessage: '重置密码成功',
    },
    resetPasswordException: {
        id: 'reset.password.exception',
        defaultMessage: '重置密码异常',
    },
    accountNotExist: {
        id: 'account.not.exist',
        defaultMessage: '账号不存在',
    },
    accountException: {
        id: 'account.exception',
        defaultMessage: '账号异常',
    },
    verificationCodeMatchingError: {
        id: 'verification.code.matching.error',
        defaultMessage: '验证码匹配错误',
    },
    resetPasswordFailed: {
        id: 'reset.password.failed',
        defaultMessage: '重置密码失败',
    }
});

class ForgetPage extends Component {
    constructor(props) {
        super(props);
        let auth = JSON.parse(localStorage.getItem("auth") || '{}') || {};

        this.state = {
            isGetCaptcha: true,
            count: 60,
            code: '',
            message: '',
            resetPassword: false,
            ...auth
        }
    }

    componentWillReceiveProps(props) {
        let { status, state } = props;
        let { count } = this.state;

        this.setState({
            code: '',
            message: '',
            resetPassword: false
        });
        
        switch (status) {
            case 'Get_Captcha':
                switch (state) {
                    case 0://失败
                        this.setState({
                            code: 'ERROR',
                            message: <FormattedMessage {...messages.getVerificationCodeFailed} />
                        });
                        break;
                    case 1://成功
                        const timer = setInterval(() => {
                            this.setState({
                                isGetCaptcha: false,
                                count: (count--)
                            }, () => {
                                if (count === 0) {
                                    clearInterval(timer);
                                    this.setState({
                                        isGetCaptcha: true,
                                        count: 60
                                    })
                                }
                            })
                        }, 1000);
                        break;
                    case 2://手机未注册
                        this.setState({
                            code: 'ERROR',
                            message: <FormattedMessage {...messages.phoneNotRegister} />
                        });
                        break;
                    case 3://手机有误
                        this.setState({
                            code: 'ERROR',
                            message: <FormattedMessage {...messages.phoneFormatFailed} />
                        });
                        break;
                }
                break;
            case 'ResetPassword':
                this.setState({
                    resetPassword: true,
                });
                break;
            case 'Forget_Success':
                this.setState({
                    code: 'SUCCESS',
                    message: <FormattedMessage {...messages.resetPasswordSuccess} />
                });
                break;
            case 'Forget_Failed':
                switch (state) {
                    case 0:
                        this.setState({
                            code: 'ERROR',
                            message: <FormattedMessage {...messages.resetPasswordException} />
                        });
                        break;
                    case 2:
                        this.setState({
                            code: 'ERROR',
                            message: <FormattedMessage {...messages.accountNotExist} />
                        });
                        break;
                    case 3:
                        this.setState({
                            code: 'ERROR',
                            message: <FormattedMessage {...messages.accountException} />
                        });
                        break;
                    case 4:
                        this.setState({
                            code: 'ERROR',
                            message: <FormattedMessage {...messages.verificationCodeMatchingError} />
                        });
                        break;
                    case 5:
                        this.setState({
                            code: 'ERROR',
                            message: <FormattedMessage {...messages.resetPasswordFailed} />
                        });
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
    }

    toRegister = e => {
        e.preventDefault();
        history.push("/register");
    }

    handleChange(data) {
        this.setState({ code: data.error });
    }

    render() {
        return (<Content className='main-container total-content'>
            <div className="row-container" style={{ justifyContent: "center", padding: "36px 0", marginBottom: '30px' }}>
                {this.state.code === 'SUCCESS'?(<ForgetSuccess />) : (
                <Card className='common-box'
                        cover={<div className='form-logo'></div>}>
                    <div className='title'>
                        <Row>
                            <Col span={12} style={{ textAlign: 'right', padding: '10px' }}>
                                <FormattedMessage {...messages.resetPassword} />
                            </Col>
                            <Col span={12} style={{ textAlign: 'left', padding: '10px' }}>
                                <a onClick={this.toRegister}><FormattedMessage {...messages.freeRegister} /></a>
                            </Col>
                        </Row>
                    </div>
                    <WrappedForgetForm {...this.state} dispatch={this.props.dispatch} handleChange={this.handleChange.bind(this)} />
                    {this.state.code === 'ERROR' ? (<Alert message={this.state.message} type="error" />) : ''}
                </Card>
                )}
            </div>
        </Content>
        )
    }
}

function mapStateToProps(state) {
    return state.member ? state.member : {}
}

const ConnectedForgetPage = connect(mapStateToProps)(ForgetPage);
export { ConnectedForgetPage as default }