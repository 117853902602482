import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../helpers/history';
import { Layout, Button, Radio, PageHeader, Tabs, Row, Col, Modal, Divider, Spin, message  } from 'antd';
import moment from 'moment';

import emitter from '../Evt.js';

import BaseInfoForm from './components/BaseInfo';
import VernacularHawkeye from './components/VernacularHawkeye';
import HawkeyeCondition from './components/HawkeyeCondition';
import HawkeyeKLine from './components/HawkeyeKLine';

import TooltipPage from '../../pannels/account/TooltipPage';

const { Content } = Layout;
const { TabPane } = Tabs;

var languageMessages = window.appLocale.messages;

function strReplace(source, jsonObjcet) {
    for (var v in jsonObjcet) {
        source = source.replace(v, jsonObjcet[v]);
    }
    return source;
}

const closeShort = 'Close Short';
const closeLong = 'Close Long';

const closeTime = { 'CN': '15:00', 'US': '16:00' };
var placeOrderType = [languageMessages['confirmOrderManually'], languageMessages['systemAutoOrders']];
var submitHawkeyeBtnTitle = [languageMessages['goHawkeye'], languageMessages['PaySingle']];
class HawkeyePage extends Component {
    constructor(props) {
        super(props);

        this.user = {};
        this.state = {
            hawkeyeType: sessionStorage.getItem("hawkeyeType") || '0',
            hawkeyeCondType: sessionStorage.getItem("hawkeyeCondType") || '00',

            symbolItems: [],

            symbolCode: '',
            symbolName: '',
            direction: closeShort,
            tradeQuantity: 1000,
            startDate: moment().format('YYYY-MM-DD HH:mm'),
            endDate: moment().format('YYYY-MM-DD') + ' ' + closeTime[window.webType],

            confirmOrder: '0',//sessionStorage.getItem("confirmOrder") || '0',

            conditions: [],

            VernacularConditions: [],

            PriceRiseTo: '',
            PriceFellTo: '',
            
            ReboundRange01: '',
            ReboundRange02: '',
            Fallback01: '',
            Fallback02: '',

            DailyIncreaseReached: '5.00',
            DailyDeclineReached: '5.00',
            PriceRise: '20',
            PriceFalling: '20',
            StockPriceWearOn01: 'D',
            StockPriceWearOn02: '20',
            StockPriceFellBelow01: 'D',
            StockPriceFellBelow02: '20',
            Increase01: '1',
            Increase02: '0.50',
            Decline01: '1',
            Decline02: '0.50',

            DailyTurnoverRate: '8.00',
            KDJGoldFork: 'D',
            KDJDeadFork: 'D',
            MACDGoldFork: 'D',
            MACDDeadFork: 'D',
            PriceWearBollinger01: 'D',
            PriceWearBollinger02: 'up',
            PriceDownBollinger01: 'D',
            PriceDownBollinger02: 'down',

            //20200605
            BuyOneHigherThan: '', //买一价高于
            SellPriceBelow: '', //卖一价低于

            ReachHigh: '3.00', //高开达到
            GapReachHigh: '3.00', //跳空高开达到

            Volume01: '5', //成交量
            Volume02: '3.0',
            VolumeRatio: '3.00', //量比
            DailyAmplitude: '5.00', //日振幅
            
            AnyOnlineWear01: '60', //任意均线上穿
            AnyOnlineWear02: '10',
            AnyOnlineWear03: '60',
            AnyOnlineWear04: '20',
            AnyMovingAverage01: '60', //任意均线下穿
            AnyMovingAverage02: '10',
            AnyMovingAverage03: '60',
            AnyMovingAverage04: '20',

            IsSmartHawkeye: false,
            smartHawkeye: {},

            latestPrice: 0,
            quoteChange: 0,

            lastTimes: 0,

            filterError: '',

            modalVisible: false,

            spinLoadding: false,

            isLuisAnalysis: false,
            isFilterScan: false
        };
    }

    componentDidMount() {
        emitter.addListener('initPage', this.init);
        emitter.addListener('submitHawkeye', this.submitHawkeye);
    }

    componentWillMount() {
        this.init();
    }

    init = () => {
        this.user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        /**
        if (user.username === undefined) {
            this.setState({ spinLoadding: false }, () => {
                this.props.dispatch({ type: "action_non_user" });
            });
        } else {
            this.props.dispatch({ type: "action_get_hawkeye_info", payload: { UserId: user.username } });
        }
        */
        this.props.dispatch({ type: "action_get_hawkeye_info", payload: { UserId: this.user.username } });
    }

    submitHawkeye = (lastTimes, permission) => {
        var { spinLoadding } = this.state;

        if (lastTimes !== undefined && permission !== undefined) {
            if (this.user.permission !== undefined) {
                this.user.permission = permission;
                sessionStorage.setItem("auth", JSON.stringify(this.user));
            }

            var nodes = document.querySelectorAll('.common-tag');
            for (var i = 0; i < nodes.length; i++) {
                if (nodes[i].classList.contains("checked")) {
                    nodes[i].classList.remove("checked");
                }
            }

            var endDate = moment().format('YYYY-MM-DD') + ' ' + closeTime[window.webType];
            if (this.props.nextDate !== '') {
                endDate = moment(this.props.nextDate).format('YYYY-MM-DD') + ' ' + closeTime[window.webType];
            }

            //submit success
            this.setState({
                hawkeyeType: sessionStorage.getItem("hawkeyeType") || '0',

                symbolItems: [],

                symbolCode: '',
                symbolName: '',
                direction: closeShort,
                tradeQuantity: 1000,
                startDate: moment().format('YYYY-MM-DD HH:mm'),
                endDate: endDate,

                confirmOrder: '0',//sessionStorage.getItem("confirmOrder") || '0',

                conditions: [],

                VernacularConditions: [],

                IsSmartHawkeye: false,
                smartHawkeye: {},

                lastTimes,
                isVip: false,

                spinLoadding: false,

                isLuisAnalysis: false,
                isFilterScan: false
            });
        } else {
            if (spinLoadding) this.setState({ spinLoadding: false });
        }
    }

    componentWillUnmount() {
        emitter.removeListener('initPage', this.init);
        emitter.removeListener('submitHawkeye', this.submitHawkeye);
    }

    setUserPermission(permissions) {
        var lastTimes = 0;
        for (var key in permissions) {
            var permission = permissions[key];
            switch (permission.type) {
                case 'h':
                    lastTimes += permission.totalCount - permission.usageCount;
                    break;
                case 'vh':
                    lastTimes += permission.totalCount - permission.usageCount;
                    break;
                default:
                    lastTimes += 0;
                    break;
            }
        }

        let isVip = false;
        var upso = permissions.filter(function (e) { return e.type === 'vs' || e.type === 'vo' });
        if (upso.length > 0) {
            isVip = true;
        }

        this.setState({ lastTimes, isVip });
    }

    componentWillReceiveProps(props) {
        var { status, state, nextDate, symbolList, symbolItems,
            permission,
            vernacularKey, formulaName, formulaString } = props;

        switch (status) {
            case 'get_hawkeye_info_success':
                if (this.user.permission !== undefined) {
                    this.user.permission = permission;
                    sessionStorage.setItem("auth", JSON.stringify(this.user));
                    this.setUserPermission(this.user.permission);
                }

                var endDate = moment().format('YYYY-MM-DD') + ' ' + closeTime[window.webType];
                if (nextDate !== undefined) {
                    endDate = moment(nextDate).format('YYYY-MM-DD') + ' ' + closeTime[window.webType];
                }

                this.setState({ endDate, spinLoadding: false });
                break;
            case 'get_hawkeye_info_fail':
                if (this.user.permission !== undefined) {
                    this.setUserPermission(this.user.permission);
                }
                var error = languageMessages['get.data.failed'];
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = languageMessages['user.exception'];
                        break;
                    default:
                        break;
                }
                message.error(error);
                this.setState({ spinLoadding: false });
                break;
            case 'StockTick_success':
                if (symbolList.length > 0) {
                    var latestPrice = parseFloat(symbolList[0].close).toFixed(2);
                    var quoteChange = parseFloat(symbolList[0].chg).toFixed(2);

                    this.setState({ latestPrice, quoteChange });
                }
                break;
            case 'StockTick_fail':
                var error = languageMessages['get.data.failed'];
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = languageMessages['user.exception'];
                        break;
                    default:
                        break;
                }
                message.error(error);
                break;
            case 'get_symbol_items_success':
                this.setState({ symbolItems });
                break;
            case 'get_symbol_items_fail':
                this.setState({
                    symbolItems: [],
                });
                break;
            case 'get_hawkeye_base':
                var data = props.data;

                var { symbolCode, hawkeyeType, conditions } = this.state;
                if (hawkeyeType === '3' && symbolCode !== data.symbolCode) {
                    // request tick data
                    //emitter.emit('callIntradayData', data.symbolCode, '1440');
                }

                if (data.direction === closeShort) {
                    conditions = conditions.filter(function (item) { return item.name !== 'ReboundRange' });
                }
                if (data.direction === closeLong) {
                    conditions = conditions.filter(function (item) { return item.name !== 'Fallback' });
                }

                var symbolCode = '';
                var symbolName = '';
                if (symbolItems !== undefined && symbolItems.length > 0) {
                    var tmpDataCode = symbolItems.filter(function (e) { return e.symbol.toLowerCase() === data.symbolCode.toLowerCase(); });
                    if (tmpDataCode.length > 0) {
                        symbolCode = data.symbolCode;
                        symbolName = data.symbolName;
                    }
                }
                this.setState({
                    symbolCode: symbolCode,
                    symbolName: symbolName,
                    direction: data.direction,
                    tradeQuantity: data.tradeQuantity,
                    startDate: data.startDate,
                    endDate: data.endDate,
                    latestPrice: data.latestPrice,
                    quoteChange: data.quoteChange,
                    conditions
                }, () => {
                    //判断是否可以提交盯盘
                    this.checkIsSmartHawkeye();
                });
                break;
            case 'Use_formula_hawkeye':
                let { VernacularConditions } = this.state;

                let tmpVernacularConditions = VernacularConditions.filter(function (item) { return item.name === formulaName });
                if (tmpVernacularConditions.length > 0) {
                    message.info(languageMessages['hawkeyeConditionAlreadyExists'], 5);
                } else {
                    let content = this.renderVernacularHawkeye(vernacularKey, formulaName, formulaString);
                    VernacularConditions.push({ key: vernacularKey, name: formulaName, formula: formulaString, content: content });

                    this.setState({ VernacularConditions }, () => {
                        this.checkIsSmartHawkeye();
                    });
                }
                break;
            default:
                break;
        }
    }

    handleLuisAnalysis(data) {
        this.setState({
            isLuisAnalysis: data.isLuisAnalysis
        });
    }

    handleBaseInfo(data) {
        this.props.dispatch({ type: "action_get_hawkeye_base", payload: { data: data } });
    }

    hawkeyeConditions(data) {
        var PriceRiseTo = '',
            PriceFellTo = '',

            ReboundRange01 = '',
            ReboundRange02 = '',
            Fallback01 = '',
            Fallback02 = '',

            DailyIncreaseReached = '5.00',
            DailyDeclineReached = '5.00',
            PriceRise = '20',
            PriceFalling = '20',
            StockPriceWearOn01 = 'D',
            StockPriceWearOn02 = '20',
            StockPriceFellBelow01 = 'D',
            StockPriceFellBelow02 = '20',
            Increase01 = '1',
            Increase02 = '0.50',
            Decline01 = '1',
            Decline02 = '0.50',

            DailyTurnoverRate = '8.00',
            KDJGoldFork = 'D',
            KDJDeadFork = 'D',
            MACDGoldFork = 'D',
            MACDDeadFork = 'D',

            PriceWearBollinger01 = 'D',
            PriceWearBollinger02 = 'up',
            PriceDownBollinger01 = 'D',
            PriceDownBollinger02 = 'down',

            //20200605
            BuyOneHigherThan = '', //买一价高于
            SellPriceBelow = '', //卖一价低于

            ReachHigh = '3.00', //高开达到
            GapReachHigh = '3.00', //跳空高开达到

            Volume01 = '5', //成交量
            Volume02 = '3.0',
            VolumeRatio = '3.00', //量比
            DailyAmplitude = '5.00', //日振幅

            AnyOnlineWear01 = '60', //任意均线上穿
            AnyOnlineWear02 = '10',
            AnyOnlineWear03 = '60',
            AnyOnlineWear04 = '20',
            AnyMovingAverage01 = '60', //任意均线下穿
            AnyMovingAverage02 = '10',
            AnyMovingAverage03 = '60',
            AnyMovingAverage04 = '20';
            
        if (data.conditions.length > 0) {
            for (var i in data.conditions) {
                var item = data.conditions[i].name;
                switch (item) {
                    case 'PriceRiseTo':
                        PriceRiseTo = this.state.PriceRiseTo;
                        break;
                    case 'PriceFellTo':
                        PriceFellTo = this.state.PriceFellTo;
                        break;
                    
                    case 'ReboundRange':
                        ReboundRange01 = this.state.ReboundRange01;
                        ReboundRange02 = this.state.ReboundRange02;
                        break;
                    case 'Fallback':
                        Fallback01 = this.state.Fallback01;
                        Fallback02 = this.state.Fallback02;
                        break;

                    case 'DailyIncreaseReached':
                        DailyIncreaseReached = this.state.DailyIncreaseReached;
                        break;
                    case 'DailyDeclineReached':
                        DailyDeclineReached = this.state.DailyDeclineReached;
                        break;
                    case 'PriceRise':
                        PriceRise = this.state.PriceRise;
                        break;
                    case 'PriceFalling':
                        PriceFalling = this.state.PriceFalling;
                        break;
                    case 'StockPriceWearOn':
                        StockPriceWearOn01 = this.state.StockPriceWearOn01;
                        StockPriceWearOn02 = this.state.StockPriceWearOn02;
                        break;
                    case 'StockPriceFellBelow':
                        StockPriceFellBelow01 = this.state.StockPriceFellBelow01;
                        StockPriceFellBelow02 = this.state.StockPriceFellBelow02;
                        break;
                    case 'Increase':
                        Increase01 = this.state.Increase01;
                        Increase02 = this.state.Increase02;
                        break;
                    case 'Decline':
                        Decline01 = this.state.Decline01;
                        Decline02 = this.state.Decline02;
                        break;

                    case 'DailyTurnoverRate':
                        DailyTurnoverRate = this.state.DailyTurnoverRate;
                        break;
                    case 'KDJGoldFork':
                        KDJGoldFork = this.state.KDJGoldFork;
                        break;
                    case 'KDJDeadFork':
                        KDJDeadFork = this.state.KDJDeadFork;
                        break;
                    case 'MACDGoldFork':
                        MACDGoldFork = this.state.MACDGoldFork;
                        break;
                    case 'MACDDeadFork':
                        MACDDeadFork = this.state.MACDDeadFork;
                        break;
                    case 'PriceWearBollinger':
                        PriceWearBollinger01 = this.state.PriceWearBollinger01;
                        PriceWearBollinger02 = this.state.PriceWearBollinger02;
                        break;
                    case 'PriceDownBollinger':
                        PriceDownBollinger01 = this.state.PriceDownBollinger01;
                        PriceDownBollinger02 = this.state.PriceDownBollinger02;
                        break;
                    //20200605
                    case 'BuyOneHigherThan': //买一价高于
                        BuyOneHigherThan = this.state.BuyOneHigherThan;
                        break;
                    case 'SellPriceBelow': //卖一价低于
                        SellPriceBelow = this.state.SellPriceBelow;
                        break;
                    case 'ReachHigh': //高开达到
                        ReachHigh = this.state.ReachHigh;
                        break;
                    case 'GapReachHigh': //跳空高开达到
                        GapReachHigh = this.state.GapReachHigh;
                        break;
                    case 'Volume': //成交量
                        Volume01 = this.state.Volume01;
                        Volume02 = this.state.Volume02;
                        break;
                    case 'VolumeRatio': //量比
                        VolumeRatio = this.state.VolumeRatio;
                        break;
                    case 'DailyAmplitude': //日振幅
                        DailyAmplitude = this.state.DailyAmplitude;
                        break;
                    case 'AnyOnlineWear': //任意均线上穿
                        AnyOnlineWear01 = this.state.AnyOnlineWear01;
                        AnyOnlineWear02 = this.state.AnyOnlineWear02;
                        AnyOnlineWear03 = this.state.AnyOnlineWear03;
                        AnyOnlineWear04 = this.state.AnyOnlineWear04;
                        break;
                    case 'AnyMovingAverage': //任意均线下穿
                        AnyMovingAverage01 = this.state.AnyMovingAverage01;
                        AnyMovingAverage02 = this.state.AnyMovingAverage02;
                        AnyMovingAverage03 = this.state.AnyMovingAverage03;
                        AnyMovingAverage04 = this.state.AnyMovingAverage04;
                        break;
                    default:
                        break;
                }
            }
        }

        this.setState({
            conditions: data.conditions,

            VernacularConditions: data.VernacularConditions,

            PriceRiseTo,
            PriceFellTo,
            
            ReboundRange01,
            ReboundRange02,
            Fallback01,
            Fallback02,

            DailyIncreaseReached,
            DailyDeclineReached,
            PriceRise,
            PriceFalling,
            StockPriceWearOn01,
            StockPriceWearOn02,
            StockPriceFellBelow01,
            StockPriceFellBelow02,
            Increase01,
            Increase02,
            Decline01,
            Decline02,

            DailyTurnoverRate,
            KDJGoldFork,
            KDJDeadFork,
            MACDGoldFork,
            MACDDeadFork,

            PriceWearBollinger01,
            PriceWearBollinger02,
            PriceDownBollinger01,
            PriceDownBollinger02,

            //20200605
            BuyOneHigherThan, //买一价高于
            SellPriceBelow, //卖一价低于

            ReachHigh, //高开达到
            GapReachHigh, //跳空高开达到

            Volume01, //成交量
            Volume02,
            VolumeRatio, //量比
            DailyAmplitude, //日振幅

            AnyOnlineWear01, //任意均线上穿
            AnyOnlineWear02,
            AnyOnlineWear03,
            AnyOnlineWear04,
            AnyMovingAverage01, //任意均线下穿
            AnyMovingAverage02,
            AnyMovingAverage03,
            AnyMovingAverage04
        }, () => {
            var nodes = document.querySelectorAll('.common-tag');
            for (var i = 0; i < nodes.length; i++) {
                var dd = nodes[i].getAttribute('data-value');
                if (data.conditions.findIndex((n) => n.name === dd) > -1) {
                    if (!nodes[i].classList.contains("checked")) {
                        nodes[i].classList.add("checked");
                    }
                } else {
                    if (nodes[i].classList.contains("checked")) {
                        nodes[i].classList.remove("checked");
                    }
                }
            }
            this.checkIsSmartHawkeye();
        });
    }

    hawkeyeConditionParams(data) {
        this.setState({
            PriceRiseTo: data.PriceRiseTo,
            PriceFellTo: data.PriceFellTo,
            
            ReboundRange01: data.ReboundRange01,
            ReboundRange02: data.ReboundRange02,
            Fallback01: data.Fallback01,
            Fallback02: data.Fallback02,

            DailyIncreaseReached: data.DailyIncreaseReached,
            DailyDeclineReached: data.DailyDeclineReached,
            PriceRise: data.PriceRise,
            PriceFalling: data.PriceFalling,
            StockPriceWearOn01: data.StockPriceWearOn01,
            StockPriceWearOn02: data.StockPriceWearOn02,
            StockPriceFellBelow01: data.StockPriceFellBelow01,
            StockPriceFellBelow02: data.StockPriceFellBelow02,
            Increase01: data.Increase01,
            Increase02: data.Increase02,
            Decline01: data.Decline01,
            Decline02: data.Decline02,

            DailyTurnoverRate: data.DailyTurnoverRate,
            KDJGoldFork: data.KDJGoldFork,
            KDJDeadFork: data.KDJDeadFork,
            MACDGoldFork: data.MACDGoldFork,
            MACDDeadFork: data.MACDDeadFork,

            PriceWearBollinger01: data.PriceWearBollinger01,
            PriceWearBollinger02: data.PriceWearBollinger02,
            PriceDownBollinger01: data.PriceDownBollinger01,
            PriceDownBollinger02: data.PriceDownBollinger02,

            //20200605
            BuyOneHigherThan: data.BuyOneHigherThan, //买一价高于
            SellPriceBelow: data.SellPriceBelow, //卖一价低于

            ReachHigh: data.ReachHigh, //高开达到
            GapReachHigh: data.GapReachHigh, //跳空高开达到

            Volume01: data.Volume01, //成交量
            Volume02: data.Volume02,
            VolumeRatio: data.VolumeRatio, //量比
            DailyAmplitude: data.DailyAmplitude, //日振幅

            AnyOnlineWear01: data.AnyOnlineWear01, //任意均线上穿
            AnyOnlineWear02: data.AnyOnlineWear02,
            AnyOnlineWear03: data.AnyOnlineWear03,
            AnyOnlineWear04: data.AnyOnlineWear04,
            AnyMovingAverage01: data.AnyMovingAverage01, //任意均线下穿
            AnyMovingAverage02: data.AnyMovingAverage02,
            AnyMovingAverage03: data.AnyMovingAverage03,
            AnyMovingAverage04: data.AnyMovingAverage04
        }, () => {
            this.checkIsSmartHawkeye();
        });
    }

    handleMyHawkeye = e => {
        e.preventDefault();

        emitter.emit('callJump', 'hawkeyeList');

        history.push("/hawkeyeList");
    };

    onChangeTabs = key => {
        var hawkeyeType = key;

        sessionStorage.setItem("hawkeyeType", hawkeyeType);

        this.setState({ hawkeyeType }, () => {
            this.checkIsSmartHawkeye();
        });
    }

    onChangeCondTabs = key => {
        var hawkeyeCondType = key;

        sessionStorage.setItem("hawkeyeCondType", hawkeyeCondType);

        this.setState({ hawkeyeCondType }, () => {
            
        });
    }

    onSelectKLineCondition = e => {
        if (e.target.classList.contains("disabled")) return false;

        if (e.target.classList.contains("checked")) {
            e.target.classList.remove("checked");
        } else {
            e.target.classList.add("checked");
        }
    }

    onSelectCondition = e => {
        var { conditions, VernacularConditions, hawkeyeType, direction } = this.state;

        try {
            var tags = e.currentTarget.getAttribute('data-value');
            if (tags === 'ReboundRange' && direction === closeShort) return;
            if (tags === 'Fallback' && direction === closeLong) return;

            var tmpConditions = ['PriceRiseTo', 'PriceFellTo', 'ReboundRange', 'Fallback'];
            if (hawkeyeType === '1' && conditions.length > 0) {
                tmpConditions = tmpConditions.filter(function (i) { return i !== tags; });
                conditions = conditions.filter(function (i) { return tmpConditions.indexOf(i.name) === -1; });
                var nodes = e.target.parentNode.childNodes;
                for (var i = 0; i < nodes.length; i++) {
                    var dd = nodes[i].getAttribute('data-value');
                    if (tags !== dd) {
                        if (nodes[i].classList.contains("checked")) {
                            nodes[i].classList.remove("checked");
                        }
                    }
                }
            }
            
            if (e.target.classList.contains("checked")) {
                if (conditions.length <= 0) return;

                e.target.classList.remove("checked");

                conditions = conditions.filter(function (item) { return item.name !== tags });
            } else {
                let count = VernacularConditions.length + conditions.length;
                if (count >= 5) {
                    message.warning(languageMessages['hawkeyeUpperLimit'], 5);
                    return;
                }

                e.target.classList.add("checked");

                conditions.push({ key: new Date().getTime().toString(), name: tags });
            }

            this.setState({ conditions }, () => {
                this.checkIsSmartHawkeye();
            });
        } catch (e) { }
    }

    checkIsSmartHawkeye() {
        var { conditions, VernacularConditions } = this.state;

        var IsBaseHawkeye = false;
        if (this.state.symbolCode !== ''
            && this.state.direction !== ''
            && this.state.tradeQuantity !== ''
            && this.state.startDate !== ''
            && this.state.endDate !== '') {
            IsBaseHawkeye = true;
        }

        var IsSmartHawkeye = false;
        if (IsBaseHawkeye) {
            if (VernacularConditions.length > 0) { //白话盯盘
                IsSmartHawkeye = true;
            }

            if (conditions.length > 0) {
                IsSmartHawkeye = true;
                for (var i in conditions) {
                    var item = conditions[i].name;
                    switch (item) {
                        case 'PriceRiseTo':
                            if (this.state.PriceRiseTo === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        case 'PriceFellTo':
                            if (this.state.PriceFellTo === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                            
                        case 'ReboundRange':
                            if (this.state.ReboundRange01 === '' || this.state.ReboundRange02 === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        case 'Fallback':
                            if (this.state.Fallback01 === '' || this.state.Fallback02 === '') {
                                IsSmartHawkeye = false;
                            }
                            break;

                        case 'DailyIncreaseReached':
                            if (this.state.DailyIncreaseReached === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        case 'DailyDeclineReached':
                            if (this.state.DailyDeclineReached === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        case 'PriceRise':
                            if (this.state.PriceRise === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        case 'PriceFalling':
                            if (this.state.PriceFalling === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        case 'StockPriceWearOn':
                            if (this.state.StockPriceWearOn01 === '' || this.state.StockPriceWearOn02 === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        case 'StockPriceFellBelow':
                            if (this.state.StockPriceFellBelow01 === '' || this.state.StockPriceFellBelow02 === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        case 'Increase':
                            if (this.state.Increase01 === '' || this.state.Increase02 === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        case 'Decline':
                            if (this.state.Decline01 === '' || this.state.Decline02 === '') {
                                IsSmartHawkeye = false;
                            }
                            break;

                        case 'DailyTurnoverRate':
                            if (this.state.DailyTurnoverRate === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        case 'KDJGoldFork':
                            if (this.state.KDJGoldFork === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        case 'KDJDeadFork':
                            if (this.state.KDJDeadFork === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        case 'MACDGoldFork':
                            if (this.state.MACDGoldFork === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        case 'MACDDeadFork':
                            if (this.state.MACDDeadFork === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        case 'PriceWearBollinger':
                            if (this.state.PriceWearBollinger01 === '' || this.state.PriceWearBollinger02 === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        case 'PriceDownBollinger':
                            if (this.state.PriceDownBollinger01 === '' || this.state.PriceDownBollinger02 === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        //20200605
                        case 'BuyOneHigherThan': //买一价高于
                            if (this.state.BuyOneHigherThan === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        case 'SellPriceBelow': //卖一价低于
                            if (this.state.SellPriceBelow === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        case 'ReachHigh': //高开达到
                            if (this.state.ReachHigh === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        case 'GapReachHigh': //跳空高开达到
                            if (this.state.GapReachHigh === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        case 'Volume': //成交量
                            if (this.state.Volume01 === '' || this.state.Volume02 === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        case 'VolumeRatio': //量比
                            if (this.state.VolumeRatio === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        case 'DailyAmplitude': //日振幅
                            if (this.state.DailyAmplitude === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        case 'AnyOnlineWear': //任意均线上穿
                            if (this.state.AnyOnlineWear01 === '' || this.state.AnyOnlineWear02 === '' || this.state.AnyOnlineWear03 === '' || this.state.AnyOnlineWear04 === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        case 'AnyMovingAverage': //任意均线下穿
                            if (this.state.AnyMovingAverage01 === '' || this.state.AnyMovingAverage02 === '' || this.state.AnyMovingAverage03 === '' || this.state.AnyMovingAverage04 === '') {
                                IsSmartHawkeye = false;
                            }
                            break;
                        default:
                            break;
                    }
                }
            }
        }
        this.setState({ IsSmartHawkeye });
    }

    onSmartHawkeye = e => {
        e.preventDefault();

        var { hawkeyeType, IsSmartHawkeye, conditions, VernacularConditions } = this.state;
        if (IsSmartHawkeye) {
            var smartHawkeye = {
                HawkeyeType: this.state.hawkeyeType,
                SymbolName: this.state.symbolName,
                SymbolCode: this.state.symbolCode,
                Direction: this.state.direction,
                TradeQuantity: this.state.tradeQuantity,

                StartDate: this.state.startDate,
                EndDate: this.state.endDate,

                ConfirmOrder: this.state.confirmOrder,

                HawkeyeCondition: ''
            };

            switch (hawkeyeType) {
                case '0': // 白话盯盘
                case '1': // 价格条件盯盘
                case '2':
                    var selectCondition = {};
                    for (var i in conditions) {
                        var item = conditions[i].name;
                        switch (item) {
                            case 'PriceRiseTo':
                                smartHawkeye['PriceRiseTo'] = this.state.PriceRiseTo;
                                
                                selectCondition['PriceRiseTo'] = strReplace(languageMessages['PriceRiseToInfo'], { '{num}': smartHawkeye['PriceRiseTo'] });
                                break;
                            case 'PriceFellTo':
                                smartHawkeye['PriceFellTo'] = this.state.PriceFellTo;

                                selectCondition['PriceFellTo'] = strReplace(languageMessages['PriceFellToInfo'], { '{num}': smartHawkeye['PriceFellTo'] });
                                break;
                            case 'ReboundRange':
                                smartHawkeye['ReboundRange01'] = this.state.ReboundRange01;
                                smartHawkeye['ReboundRange02'] = this.state.ReboundRange02;
                                
                                selectCondition['ReboundRange'] = strReplace(languageMessages['ReboundRangeInfo'], { '{num1}': smartHawkeye['ReboundRange01'], '{num2}': smartHawkeye['ReboundRange02'] });
                                break;
                            case 'Fallback':
                                smartHawkeye['Fallback01'] = this.state.Fallback01;
                                smartHawkeye['Fallback02'] = this.state.Fallback02;
                                
                                selectCondition['Fallback'] = strReplace(languageMessages['FallbackInfo'], { '{num1}': smartHawkeye['Fallback01'], '{num2}': smartHawkeye['Fallback02'] });
                                break;
                            case 'DailyIncreaseReached':
                                smartHawkeye['DailyIncreaseReached'] = this.state.DailyIncreaseReached;
                                
                                selectCondition['DailyIncreaseReached'] = strReplace(languageMessages['DailyIncreaseReachedInfo'], { '{num}': smartHawkeye['DailyIncreaseReached'] });
                                break;
                            case 'DailyDeclineReached':
                                smartHawkeye['DailyDeclineReached'] = this.state.DailyDeclineReached;
                                
                                selectCondition['DailyDeclineReached'] = strReplace(languageMessages['DailyDeclineReachedInfo'], { '{num}': smartHawkeye['DailyDeclineReached'] });
                                break;
                            case 'PriceRise':
                                smartHawkeye['PriceRise'] = this.state.PriceRise;

                                var intro = languageMessages['priceUpWoundHistoryHigh'];
                                if (smartHawkeye['PriceRise'] !== "0") {
                                    intro = strReplace(languageMessages['priceUpWoundInfo'], { '{num}': smartHawkeye['PriceRise'] });
                                }

                                selectCondition['PriceRise'] = intro;
                                break;
                            case 'PriceFalling':
                                smartHawkeye['PriceFalling'] = this.state.PriceFalling;

                                var intro = languageMessages['priceDownWoundHistoryLow'];
                                if (smartHawkeye['PriceFalling'] !== "0") {
                                    intro = strReplace(languageMessages['priceDownWoundInfo'], { '{num}': smartHawkeye['PriceFalling'] });
                                }

                                selectCondition['PriceFalling'] = intro;
                                break;
                            case 'StockPriceWearOn':
                                smartHawkeye['StockPriceWearOn01'] = this.state.StockPriceWearOn01;
                                smartHawkeye['StockPriceWearOn02'] = this.state.StockPriceWearOn02;

                                var intro = languageMessages['dailyLine'];
                                if (smartHawkeye['StockPriceWearOn01'] !== "D") {
                                    intro = strReplace(languageMessages['numMinLine'], { '{num}': smartHawkeye['StockPriceWearOn01'] });
                                }

                                selectCondition['StockPriceWearOn'] = strReplace(languageMessages['StockPriceWearOnInfo'], { '{num}': intro, '{avg}': smartHawkeye['StockPriceWearOn02'] });
                                break;
                            case 'StockPriceFellBelow':
                                smartHawkeye['StockPriceFellBelow01'] = this.state.StockPriceFellBelow01;
                                smartHawkeye['StockPriceFellBelow02'] = this.state.StockPriceFellBelow02;

                                var intro = languageMessages['dailyLine'];
                                if (smartHawkeye['StockPriceFellBelow01'] !== "D") {
                                    intro = strReplace(languageMessages['numMinLine'], { '{num}': smartHawkeye['StockPriceWearOn01'] });
                                }

                                selectCondition['StockPriceFellBelow'] = strReplace(languageMessages['StockPriceFellBelowInfo'], { '{num}': intro, '{avg}': smartHawkeye['StockPriceFellBelow02'] });
                                break;
                            case 'Increase':
                                smartHawkeye['Increase01'] = this.state.Increase01;
                                smartHawkeye['Increase02'] = this.state.Increase02;
                                
                                selectCondition['Increase'] = strReplace(languageMessages['IncreaseInfo'], { '{num1}': smartHawkeye['Increase01'], '{num2}': smartHawkeye['Increase02'] });
                                break;
                            case 'Decline':
                                smartHawkeye['Decline01'] = this.state.Decline01;
                                smartHawkeye['Decline02'] = this.state.Decline02;
                                
                                selectCondition['Decline'] = strReplace(languageMessages['DeclineInfo'], { '{num1}': smartHawkeye['Decline01'], '{num2}': smartHawkeye['Decline02'] });
                                break;
                            case 'DailyTurnoverRate':
                                smartHawkeye['DailyTurnoverRate'] = this.state.DailyTurnoverRate;
                                
                                selectCondition['DailyTurnoverRate'] = languageMessages['DailyTurnoverRate'] + ' ' + smartHawkeye['DailyTurnoverRate'] + "%";
                                break;
                            case 'KDJGoldFork':
                                smartHawkeye['KDJGoldFork'] = this.state.KDJGoldFork;

                                var intro = languageMessages['dailyLine'];
                                if (smartHawkeye['KDJGoldFork'] !== "D") {
                                    intro = strReplace(languageMessages['numMinLine'], { '{num}': smartHawkeye['KDJGoldFork'] });
                                }
                                
                                selectCondition['KDJGoldFork'] = intro + languageMessages['KDJGoldFork'];
                                break;
                            case 'KDJDeadFork':
                                smartHawkeye['KDJDeadFork'] = this.state.KDJDeadFork;

                                var intro = languageMessages['dailyLine'];
                                if (smartHawkeye['KDJDeadFork'] !== "D") {
                                    intro = strReplace(languageMessages['numMinLine'], { '{num}': smartHawkeye['KDJDeadFork'] });
                                }
                                
                                selectCondition['KDJDeadFork'] = intro + languageMessages['KDJDeadFork'];
                                break;
                            case 'MACDGoldFork':
                                smartHawkeye['MACDGoldFork'] = this.state.MACDGoldFork;

                                var intro = languageMessages['dailyLine'];
                                if (smartHawkeye['MACDGoldFork'] !== "D") {
                                    intro = strReplace(languageMessages['numMinLine'], { '{num}': smartHawkeye['MACDGoldFork'] });
                                }
                                
                                selectCondition['MACDGoldFork'] = intro + languageMessages['MACDGoldFork'];
                                break;
                            case 'MACDDeadFork':
                                smartHawkeye['MACDDeadFork'] = this.state.MACDDeadFork;

                                var intro = languageMessages['dailyLine'];
                                if (smartHawkeye['MACDDeadFork'] !== "D") {
                                    intro = strReplace(languageMessages['numMinLine'], { '{num}': smartHawkeye['MACDDeadFork'] });
                                }
                                
                                selectCondition['MACDDeadFork'] = intro + languageMessages['MACDDeadFork'];
                                break;
                            case 'PriceWearBollinger':
                                smartHawkeye['PriceWearBollinger01'] = this.state.PriceWearBollinger01;
                                smartHawkeye['PriceWearBollinger02'] = this.state.PriceWearBollinger02;

                                var line = languageMessages['dailyLine'];
                                if (smartHawkeye['PriceWearBollinger01'] !== "D") {
                                    line = strReplace(languageMessages['numMinLine'], { '{num}': smartHawkeye['PriceWearBollinger01'] });
                                }

                                var intro = languageMessages['OnRail'];
                                switch (smartHawkeye['PriceWearBollinger02']) {
                                    case "up":
                                        intro = languageMessages['OnRail'];
                                        break;
                                    case "mid":
                                        intro = languageMessages['MidRail'];
                                        break;
                                    case "down":
                                        intro = languageMessages['LowRail'];
                                        break;
                                }

                                selectCondition['PriceWearBollinger'] = strReplace(languageMessages['PricesGoThroughBollingerInfo'], { '{num1}': line, '{num2}': intro });
                                break;
                            case 'PriceDownBollinger':
                                smartHawkeye['PriceDownBollinger01'] = this.state.PriceDownBollinger01;
                                smartHawkeye['PriceDownBollinger02'] = this.state.PriceDownBollinger02;

                                var line = languageMessages['dailyLine'];
                                if (smartHawkeye['PriceDownBollinger01'] !== "D") {
                                    line = strReplace(languageMessages['numMinLine'], { '{num}': smartHawkeye['PriceDownBollinger01'] });
                                }

                                var intro = languageMessages['OnRail'];
                                switch (smartHawkeye['PriceDownBollinger02']) {
                                    case "up":
                                        intro = languageMessages['OnRail'];
                                        break;
                                    case "mid":
                                        intro = languageMessages['MidRail'];
                                        break;
                                    case "down":
                                        intro = languageMessages['LowRail'];
                                        break;
                                }
                                
                                selectCondition['PriceDownBollinger'] = strReplace(languageMessages['PriceDownBollingerInfo'], { '{num1}': line, '{num2}': intro });
                                break;
                            //20200605
                            case 'BuyOneHigherThan': //买一价高于
                                smartHawkeye['BuyOneHigherThan'] = this.state.BuyOneHigherThan;

                                selectCondition['BuyOneHigherThan'] = languageMessages['BuyOneHigherThan'] + ' ' + smartHawkeye['BuyOneHigherThan'] + languageMessages['yuan'];
                                break;
                            case 'SellPriceBelow': //卖一价低于
                                smartHawkeye['SellPriceBelow'] = this.state.SellPriceBelow;

                                selectCondition['SellPriceBelow'] = languageMessages['SellPriceBelow'] + ' ' + smartHawkeye['SellPriceBelow'] + languageMessages['yuan'];
                                break;
                            case 'ReachHigh': //高开达到
                                smartHawkeye['ReachHigh'] = this.state.ReachHigh;

                                selectCondition['ReachHigh'] = languageMessages['ReachHigh'] + ' ' + smartHawkeye['ReachHigh'] + "%";
                                break;
                            case 'GapReachHigh': //跳空高开达到
                                smartHawkeye['GapReachHigh'] = this.state.GapReachHigh;

                                selectCondition['GapReachHigh'] = languageMessages['GapReachHigh'] + ' ' + smartHawkeye['GapReachHigh'] + "%";
                                break;
                            case 'Volume': //成交量
                                smartHawkeye['Volume01'] = this.state.Volume01;
                                smartHawkeye['Volume02'] = this.state.Volume02;

                                selectCondition['Volume'] = strReplace(languageMessages['VolumeAveragePastDays'], { '{num1}': smartHawkeye['Volume01'], '{num2}': smartHawkeye['Volume02'] });
                                break;
                            case 'VolumeRatio': //量比
                                smartHawkeye['VolumeRatio'] = this.state.VolumeRatio;

                                selectCondition['VolumeRatio'] = languageMessages['VolumeRatioThan'] + ' ' + smartHawkeye['VolumeRatio'];
                                break;
                            case 'DailyAmplitude': //日振幅
                                smartHawkeye['DailyAmplitude'] = this.state.DailyAmplitude;

                                selectCondition['DailyAmplitude'] = languageMessages['DailyAmplitude'] + ' ' + smartHawkeye['DailyAmplitude'] + "%";
                                break;
                            case 'AnyOnlineWear': //任意均线上穿
                                smartHawkeye['AnyOnlineWear01'] = this.state.AnyOnlineWear01;
                                smartHawkeye['AnyOnlineWear02'] = this.state.AnyOnlineWear02;
                                smartHawkeye['AnyOnlineWear03'] = this.state.AnyOnlineWear03;
                                smartHawkeye['AnyOnlineWear04'] = this.state.AnyOnlineWear04;

                                var line01 = languageMessages['dailyLine'];
                                if (smartHawkeye['AnyOnlineWear01'] !== "D") {
                                    line01 = strReplace(languageMessages['numMinLine'], { '{num}': smartHawkeye['AnyOnlineWear01'] });
                                }
                                var line02 = languageMessages['dailyLine'];
                                if (smartHawkeye['AnyOnlineWear03'] !== "D") {
                                    line02 = strReplace(languageMessages['numMinLine'], { '{num}': smartHawkeye['AnyOnlineWear03'] });
                                }

                                selectCondition['AnyOnlineWear'] = strReplace(languageMessages['AnyMovingAverageInfo'], { '{num1}': line01, '{avg1}': smartHawkeye['AnyOnlineWear02'], '{num2}': line02, '{avg2}': smartHawkeye['AnyOnlineWear04'] });
                                break;
                            case 'AnyMovingAverage': //任意均线下穿
                                smartHawkeye['AnyMovingAverage01'] = this.state.AnyMovingAverage01;
                                smartHawkeye['AnyMovingAverage02'] = this.state.AnyMovingAverage02;
                                smartHawkeye['AnyMovingAverage03'] = this.state.AnyMovingAverage03;
                                smartHawkeye['AnyMovingAverage04'] = this.state.AnyMovingAverage04;

                                var line01 = languageMessages['dailyLine'];
                                if (smartHawkeye['AnyMovingAverage01'] !== "D") {
                                    line01 = strReplace(languageMessages['numMinLine'], { '{num}': smartHawkeye['AnyMovingAverage01'] });
                                }
                                var line02 = languageMessages['dailyLine'];
                                if (smartHawkeye['AnyMovingAverage03'] !== "D") {
                                    line02 = strReplace(languageMessages['numMinLine'], { '{num}': smartHawkeye['AnyMovingAverage03'] });
                                }

                                selectCondition['AnyMovingAverage'] = strReplace(languageMessages['AnyMovingAverageInfo'], { '{num1}': line01, '{avg1}': smartHawkeye['AnyMovingAverage02'], '{num2}': line02, '{avg2}': smartHawkeye['AnyMovingAverage04'] });
                                break;
                            default:
                                /**
                                
                                */
                                break;
                        }
                    }

                    
                    //var selectCondition = {};
                    let tmpVernacularConditions = [];
                    for (var i in VernacularConditions) {
                        var item = VernacularConditions[i];

                        tmpVernacularConditions.push({ key: item.key, formula: item.formula });

                        selectCondition[item.key] = item.name;
                    }
                    smartHawkeye['VernacularConditions'] = tmpVernacularConditions;
                    smartHawkeye['HawkeyeCondition'] = JSON.stringify(selectCondition);


                    //smartHawkeye['HawkeyeCondition'] = JSON.stringify(selectCondition);
                    break;
                case '3': // k线盯盘

                    break;
                default:
                    break;
            }
            
            this.setState({ smartHawkeye, modalVisible: true });
        }
    }

    onValueAddedServices = e => {
        e.preventDefault();
        Modal.destroyAll();
        
        history.push({ pathname: '/member', state: { memberMenuSelectKey: "2" } });
    }

    // 提交盯盘
    onSubmitHawkeye = () => {
        var { lastTimes } = this.state;

        if (this.user.username === undefined) {
            this.setState({
                modalVisible: false,
                spinLoadding: true,
            }, () => {
                this.props.dispatch({ type: "action_non_user" });
            });
        } else {
            if (lastTimes <= 0) {
                var content = <span>{languageMessages['today.hawkeye.end']}，{languageMessages['please go']}<a onClick={this.onValueAddedServices}>{languageMessages['value-added.services']}</a></span>;
                Modal.info({
                    title: languageMessages['prompt'],
                    content: content
                });
                return;
            }
            this.setState({
                modalVisible: false,
                spinLoadding: true,
            }, () => {
                this.props.dispatch({ type: "action_send_hub_message", payload: { service: 'SendHawkeye', userId: this.user.username, msg: this.state.smartHawkeye } });
            });
        }
    }

    handleHawkeyeCancel = () => {
        this.setState({
            modalVisible: false,
        });
    };

    changeConfirmOrder = (e) => {
        //sessionStorage.setItem("confirmOrder", e.target.value);
        this.setState({ confirmOrder: e.target.value });
    }

    renderSelectCondition() {
        var hawkeyeCondition = this.state.smartHawkeye['HawkeyeCondition'] === undefined ? {} : JSON.parse(this.state.smartHawkeye['HawkeyeCondition'] || '{}') || {};

        var html = [];
        for (var item in hawkeyeCondition) {
            html.push(<div className="common-gutter-row" key={item}>{hawkeyeCondition[item]}</div>);
        }
        
        return <Col span={16}>{html}</Col>;
    }

    renderKLineHawkeye() {
        return <TabPane tab={languageMessages['kLineHawkeye']} key="3">
            <div className={this.state.direction === closeLong ? 'common-k-line' : 'common-k-line disabled'} onClick={this.onSelectKLineCondition}>{languageMessages['closeShortHawkeye']}</div>
            <div className={this.state.direction === closeShort ? 'common-k-line' : 'common-k-line disabled'} onClick={this.onSelectKLineCondition}>{languageMessages['closeLongHawkeye']}</div>
        </TabPane>;
    }

    onClickClose = e => {
        let { VernacularConditions } = this.state;

        let tags = e.currentTarget.getAttribute('data-value');
        VernacularConditions = VernacularConditions.filter(function (item) { return item.key !== tags });

        this.setState({ VernacularConditions }, () => {
            this.checkIsSmartHawkeye();
        });
    }

    renderVernacularHawkeye(key, text, content) {
        var useFormulas = [];
        var items = content.split('\n');
        for (var item in items) {
            if (useFormulas.length > 0) useFormulas.push(<br key={item} />);
            var tmpString = items[item];
            useFormulas.push(tmpString);
        }

        return <Row key={key} className='common-hawkeye-cell'>
            <Col span={8} className='common-hawkeye-condition'>{text}</Col>
            <Col span={13} className='common-hawkeye-condition'>{useFormulas}</Col>
            <Col span={3}><Button size='small' shape="circle" icon="close" onClick={this.onClickClose} data-value={key} /></Col>
        </Row>
    }

    render() {
        let count = this.state.VernacularConditions.length + this.state.conditions.length;

        return (<Content className='main-container total-content'>
            <Spin spinning={this.state.spinLoadding}>
            <div className='common-page-header'>
                <PageHeader title={languageMessages['newHawkeye']}
                    extra={[
                        <Button key="1" size='small' onClick={this.handleMyHawkeye}>{languageMessages['myHawkeye']}</Button>
                    ]} />
            </div>
            <Modal title={languageMessages['intelligentHawkeyeConfirm']}
                visible={this.state.modalVisible}
                onOk={this.onSubmitHawkeye}
                onCancel={this.handleHawkeyeCancel}>
                <Row>
                    <Col className="common-gutter-row" span={6}>{languageMessages['symbolCode']}：</Col><Col className="common-gutter-row" span={16}>{this.state.symbolCode} {this.state.symbolName}</Col>
                    <Col className="common-gutter-row" span={6}>{languageMessages['tradeDirection']}：</Col><Col className="common-gutter-row" span={16}>{this.state.direction === closeShort ? languageMessages['closeShort'] : languageMessages['closeLong']}</Col>
                    <Col className="common-gutter-row" span={6}>{languageMessages['tradeQuantity']}：</Col><Col className="common-gutter-row" span={16}>{this.state.tradeQuantity}</Col>
                    <Col className="common-gutter-row" span={6}>{languageMessages['hawkeyeStragegy']}：</Col>
                    {this.renderSelectCondition()}
                </Row>
            </Modal>
            <Content style={{ padding: '0px 50px', marginBottom: '50px' }}>
                <div className='common-title'><Divider type="vertical" />{languageMessages['basic.info']}</div>
                <div style={{ padding: '10px 0' }}>
                    <BaseInfoForm dispatch={this.props.dispatch} isVip={this.state.isVip} status={this.props.status} nextDate={this.props.nextDate} symbolItems={this.state.symbolItems} hawkeyeBaseInfo={this.handleBaseInfo.bind(this)} />
                </div>
                <div className={this.state.hawkeyeType === '0' ? 'common-hawkeye-page-00' : 'common-hawkeye-page-01' }>
                    <div className='common-title'><Divider type="vertical" />{languageMessages['hawkeyeCondition']}</div>
                    <div className="card-container common-tabs">
                        <Tabs type="card"
                            activeKey={this.state.hawkeyeType}
                            className='common-hawkeye-tabs'
                            onChange={this.onChangeTabs}>
                            <TabPane tab={languageMessages['vernacularHawkeye']} key="0">
                                <VernacularHawkeye {...this.props} isFilterScan={this.state.isFilterScan} LuisAnalysis={this.handleLuisAnalysis.bind(this)} count={count} />
                            </TabPane>
                            <TabPane tab={languageMessages['priceHawkeye']} key="1">
                                <div className='common-tag' onClick={this.onSelectCondition} data-value='PriceRiseTo'>{languageMessages['PriceRiseTo']}</div>
                                <div className='common-tag' onClick={this.onSelectCondition} data-value='PriceFellTo'>{languageMessages['PriceFellTo']}</div>
                                    
                                <div className={this.state.direction === closeLong ? 'common-tag' : 'common-tag disabled'} onClick={this.onSelectCondition} data-value='ReboundRange'>{languageMessages['ReboundRange']}</div>
                                <div className={this.state.direction === closeShort ? 'common-tag' : 'common-tag disabled'} onClick={this.onSelectCondition} data-value='Fallback'>{languageMessages['Fallback']}</div>
                            </TabPane>
                            <TabPane tab={languageMessages['condHawkeye']} key="2">
                                <div className='card-container common-tabs-sub-content'>
                                    <Tabs type="card"
                                        activeKey={this.state.hawkeyeCondType}
                                        className='common-hawkeye-tabs'
                                        onChange={this.onChangeCondTabs}>
                                        <TabPane tab={languageMessages['mete.price']} key="00">
                                            <div className={window.webType === 'US' ? 'common-tag common-tag-us' : 'common-tag'} onClick={this.onSelectCondition} data-value='DailyIncreaseReached'>{languageMessages['DailyIncreaseReached']}</div>
                                            <div className={window.webType === 'US' ? 'common-tag common-tag-us' : 'common-tag'} onClick={this.onSelectCondition} data-value='DailyDeclineReached'>{languageMessages['DailyDeclineReached']}</div>
                                            <div className={window.webType === 'US' ? 'common-tag common-tag-us' : 'common-tag'} onClick={this.onSelectCondition} data-value='Increase'>{languageMessages['Increase']}</div>
                                            <div className={window.webType === 'US' ? 'common-tag common-tag-us' : 'common-tag'} onClick={this.onSelectCondition} data-value='Decline'>{languageMessages['Decline']}</div>
                                            <div className={window.webType === 'US' ? 'common-tag common-tag-us' : 'common-tag'} onClick={this.onSelectCondition} data-value='BuyOneHigherThan'>{languageMessages['BuyOneHigherThan']}</div>
                                            <div className={window.webType === 'US' ? 'common-tag common-tag-us' : 'common-tag'} onClick={this.onSelectCondition} data-value='SellPriceBelow'>{languageMessages['SellPriceBelow']}</div>
                                            <div className={window.webType === 'US' ? 'common-tag common-tag-us' : 'common-tag'} onClick={this.onSelectCondition} data-value='PriceRise'>{languageMessages['PriceRise']}</div>
                                            <div className={window.webType === 'US' ? 'common-tag common-tag-us' : 'common-tag'} onClick={this.onSelectCondition} data-value='PriceFalling'>{languageMessages['PriceFalling']}</div>
                                            <div className={window.webType === 'US' ? 'common-tag common-tag-us' : 'common-tag'} onClick={this.onSelectCondition} data-value='ReachHigh'>{languageMessages['ReachHigh']}</div>
                                            <div className={window.webType === 'US' ? 'common-tag common-tag-us' : 'common-tag'} onClick={this.onSelectCondition} data-value='GapReachHigh'>{languageMessages['GapReachHigh']}</div>
                                            <div className={window.webType === 'US' ? 'common-tag common-tag-us' : 'common-tag'} onClick={this.onSelectCondition} data-value='Volume'>{languageMessages['volume']}</div>
                                            <div className={window.webType === 'US' ? 'common-tag common-tag-us' : 'common-tag'} onClick={this.onSelectCondition} data-value='VolumeRatio'>{languageMessages['VolumeRatio']}</div>
                                            <div className={window.webType === 'US' ? 'common-tag common-tag-us' : 'common-tag'} onClick={this.onSelectCondition} data-value='DailyAmplitude'>{languageMessages['DailyAmplitude']}</div>
                                            <div className={window.webType === 'US' ? 'common-tag common-tag-us' : 'common-tag'} onClick={this.onSelectCondition} data-value='DailyTurnoverRate'>{languageMessages['DailyTurnoverRate']}</div>
                                        </TabPane>
                                        <TabPane tab={languageMessages['technically']} key="01">
                                            <div className={window.webType === 'US' ? 'common-tag common-tag-us' : 'common-tag'} onClick={this.onSelectCondition} data-value='StockPriceWearOn'>{languageMessages['StockPriceWearOn']}</div>
                                            <div className={window.webType === 'US' ? 'common-tag common-tag-us' : 'common-tag'} onClick={this.onSelectCondition} data-value='StockPriceFellBelow'>{languageMessages['StockPriceFellBelow']}</div>
                                            <div className={window.webType === 'US' ? 'common-tag common-tag-us' : 'common-tag'} onClick={this.onSelectCondition} data-value='AnyOnlineWear'>{languageMessages['AnyOnlineWear']}</div>
                                            <div className={window.webType === 'US' ? 'common-tag common-tag-us' : 'common-tag'} onClick={this.onSelectCondition} data-value='AnyMovingAverage'>{languageMessages['AnyMovingAverage']}</div>
                                            <div className={window.webType === 'US' ? 'common-tag common-tag-us' : 'common-tag'} onClick={this.onSelectCondition} data-value='KDJGoldFork'>{languageMessages['KDJGoldFork']}</div>
                                            <div className={window.webType === 'US' ? 'common-tag common-tag-us' : 'common-tag'} onClick={this.onSelectCondition} data-value='KDJDeadFork'>{languageMessages['KDJDeadFork']}</div>
                                            <div className={window.webType === 'US' ? 'common-tag common-tag-us' : 'common-tag'} onClick={this.onSelectCondition} data-value='MACDGoldFork'>{languageMessages['MACDGoldFork']}</div>
                                            <div className={window.webType === 'US' ? 'common-tag common-tag-us' : 'common-tag'} onClick={this.onSelectCondition} data-value='MACDDeadFork'>{languageMessages['MACDDeadFork']}</div>
                                            <div className={window.webType === 'US' ? 'common-tag common-tag-us' : 'common-tag'} onClick={this.onSelectCondition} data-value='PriceWearBollinger'>{languageMessages['PricesGoThroughBollinger']}</div>
                                            <div className={window.webType === 'US' ? 'common-tag common-tag-us' : 'common-tag'} onClick={this.onSelectCondition} data-value='PriceDownBollinger'>{languageMessages['PriceDownBollinger']}</div>
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
                <div className={this.state.hawkeyeType === '0' ? 'common-hawkeye-page-02' : 'common-hawkeye-page-03'}>
                    <div className='common-title'><Divider type="vertical" />{languageMessages['selCondTrigger']}</div>
                    <div className='common-hawkeye-content'>
                        {<HawkeyeCondition hidden={this.state.hawkeyeType !== '3' ? 'block' : 'none'}
                            conditions={this.state.conditions}
                            VernacularConditions={this.state.VernacularConditions}
                            hawkeyeConditions={this.hawkeyeConditions.bind(this)}
                            hawkeyeConditionParams={this.hawkeyeConditionParams.bind(this)} />}

                        {<HawkeyeKLine hidden={this.state.hawkeyeType === '3' ? 'block' : 'none'} />}
                    </div>
                </div>
                <div style={{ clear: 'both' }}></div>
                <div className='common-title'><Divider type="vertical" />{languageMessages['hawkeyeMode']}</div>
                <Radio.Group value={this.state.confirmOrder} onChange={this.changeConfirmOrder}>
                    <Radio value="0">{languageMessages['intelligentHawkeye']}</Radio>
                    <Radio value="1">{languageMessages['smartPay']}</Radio>
                </Radio.Group>
                <div style={{ padding: '10px 0' }}>
                    <p>{languageMessages['hawkeye.mode']}<span className='common-red'>{placeOrderType[this.state.confirmOrder]}</span></p>
                    <Button size='small' loading={false} disabled={!this.state.IsSmartHawkeye} onClick={this.onSmartHawkeye}>
                        {submitHawkeyeBtnTitle[this.state.confirmOrder]}
                    </Button>
                    <TooltipPage lastTimes={this.state.lastTimes} filterError={this.state.filterError} />
                </div>
            </Content>
        </Spin>
    </Content>)
    }
}

function mapStateToProps(state) {
    return state.hawkeye;
}

const page = connect(mapStateToProps)(HawkeyePage);
export { page as default }