export default function customStock(state = {}, action) {
    switch (action.type) {
        case "get_download":
            return { ...state, status: 'get_download' };

        

        default:
            return state;
    }
}