import React from "react";
import "./search.less";

let res = "";

function onChange(e) {
  let {
    target: { value }
  } = e;

  res = value;
}

function handleEnter(event, func) {
  let keynum = event.keyCode ? event.keyCode : event.which;

  if (keynum == "13") {
    func && func(res);
  }
}

export default props => {
  let { search } = props;

  return (
    <div className="search-wrapper">
      <div className="search-title">搜索</div>
      <div className="search-inp">
        <input
          type="text"
          placeholder="在帮助中搜索"
          onChange={e => onChange(e)}
          onKeyPress={e => handleEnter(e, search)}
        />
        <img src="/static/images/search.png" onClick={() => search(res)} />
      </div>
    </div>
  );
};
