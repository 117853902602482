import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Table, Upload, Button, Icon, Modal, PageHeader, Progress, message } from 'antd';
import history from '../../helpers/history';
import emitter from '../Evt.js';

var languageMessages = window.appLocale.messages;

const FactorStatus = [
    languageMessages['start.generating'],
    languageMessages['generated.file.in'],
    languageMessages['build.failed'],
    languageMessages['not.analyzed'],
    languageMessages['start.analysis'],
    languageMessages['analysis.failure'],
    languageMessages['analyzing'],
    languageMessages['analysis.completed']
]

const { Content } = Layout;
const confirm = Modal.confirm;

class FactorIndex extends Component {
    constructor(props) {
        super(props);

        this.columns = [{
            title: languageMessages['factor.file.name'],
            dataIndex: 'fileName',
            key: 'fileName'
        }, {
            title: languageMessages['last.update.date'],
            dataIndex: 'update',
            key: 'update'
        }, {
            title: languageMessages['type'],
            dataIndex: 'type',
            key: 'type',
            render: type => (
                <span>
                    {type ? languageMessages['upload'] : languageMessages['generate']}
                </span>
            )
        },{
            title: languageMessages['status'],
            dataIndex: 'status',
            key: 'status',
            render: status => (
                <span>
                    {FactorStatus[status]}
                </span>
            )
        }, {
            title: languageMessages['control'],
            dataIndex: 'control',
            render: (text, record) => {
                const { analyzeKey } = this.state;
                const analyzer = this.isAnalyzer(record);
                return (<div>
                    {record.status === 2 ? (<span style={{ color: '#ccc' }}><a onClick={() => this.onRemove(record.key)}>{languageMessages['remove']}</a></span>) :
                     record.status === 5 ? (<span style={{ color: '#ccc' }}><a onClick={() => this.onRemove(record.key)}>{languageMessages['remove']}</a></span>) :
                     record.status === 7 ? (<span><a onClick={() => this.onClickViewResult(record.taskId)}>{languageMessages['view.analysis.results']}</a> | <a onClick={() => this.onRemove(record.key)}>{languageMessages['remove']}</a></span>) : 
                     (record.status === 0 || record.status === 1) ? (<span style={{ color: '#ccc' }}>{languageMessages['build.please.wait']}</span>) :
                     analyzer ? (<span>{record.description}</span>) : (<span><a disabled={analyzeKey !== 0} onClick={() => this.StartAnalyzer(record.key)}>{languageMessages['start.analysis']}</a> | <a onClick={() => this.onRemove(record.key)}>{languageMessages['remove']}</a></span>)
                    }
                </div>)
            },
        }];

        this.queryAnalyzeTimer = null;

        this.user = {};
        this.state = {
            initLoadding: true,
            dataSource: [],

            aLastTimes: 0,

            analyzeKey: 0,
            
            removeKey: 0,

            fileList: [],
        };
    }

    isAnalyzer = record => record.key === this.state.analyzeKey;

    componentDidMount() {
        emitter.addListener('initPage', () => {
            this.init();
        });
    }

    componentWillMount() {
        this.init();
    }

    init() {
        this.user = JSON.parse(sessionStorage.getItem("auth") || '{}') || {};
        /**
        if (user.username === undefined) {
            this.setState({ initLoadding: false }, () => {
                this.props.dispatch({ type: "action_non_user" });
            });
        } else {
            if (user.permission !== undefined) {
                this.setUserPermission(user.permission);
            }
            this.props.dispatch({ type: "action_get_factor_files", payload: { UserId: user.username } });
        }
        */
        if (this.user.permission !== undefined) {
            this.setUserPermission(this.user.permission);
        }
        this.props.dispatch({ type: "action_get_factor_files", payload: { UserId: this.user.username } });
    }

    setUserPermission(permissions) {
        var aLastTimes = 0;
        for (var key in permissions) {
            var permission = permissions[key];
            switch (permission.type) {
                case 'a':
                    aLastTimes += permission.totalCount - permission.usageCount;
                    break;
            }
        }
        this.setState({ aLastTimes });
    }

    componentDidUpdate() {
        const { dataSource, analyzeKey } = this.state;

        var analyzeTaskId = 0;
        var analyzeStatus = '';
        var factorAnalyze = JSON.parse(sessionStorage.getItem("FactorAnalyzeCache") || '{}') || {};
        if (factorAnalyze.taskId !== undefined && factorAnalyze.status !== undefined) {
            analyzeTaskId = factorAnalyze.taskId;
            analyzeStatus = factorAnalyze.status;
        } else {
            analyzeTaskId = this.props.analyzeTaskId;
            analyzeStatus = this.props.analyzeStatus;
        }
        
        if (!this.queryAnalyzeTimer && analyzeStatus === "working") {
            if (analyzeTaskId > 0 && analyzeKey == 0) {
                var tmpFactorFile = dataSource.filter(function (e) { return e.taskId == analyzeTaskId; });
                if (tmpFactorFile.length > 0) {
                    this.setState({
                        analyzeKey: tmpFactorFile[0].key,
                    });
                }
            }
            this.queryAnalyzeTimer = setInterval(this.queryAnalyzeStatus, 10000)
        }
    }

    componentWillReceiveProps(props) {
        let { status, state, factorFiles, factorFile, analyzeTaskId, analyzeState, analyzeStage, permission, lastTimes } = props
        let { dataSource, removeKey, analyzeKey } = this.state;

        switch (status) {
            case 'get_factor_files_success':
                dataSource = [];
                if (factorFiles != null) {
                    var factorAnalyze = JSON.parse(sessionStorage.getItem("FactorAnalyzeCache") || '{}') || {};
                    var taskId = 0;
                    if (factorAnalyze.taskId !== undefined && factorAnalyze.percent !== undefined) {
                        taskId = factorAnalyze.taskId;
                        percent = factorAnalyze.percent;
                    }
                    for (var i in factorFiles) {
                        var description = '';
                        if (taskId > 0 && taskId === factorFiles[i].taskId) {
                            description = <Progress size="small" percent={percent} />
                        }
                        dataSource.push({
                            key: factorFiles[i].id,
                            fileName: factorFiles[i].fileName,
                            update: factorFiles[i].updated,
                            taskId: factorFiles[i].taskId,
                            type: factorFiles[i].type,
                            status: factorFiles[i].status,
                            description: description,
                        });
                    }
                }
                
                this.setState({ dataSource, initLoadding: false });
                break;
            case 'get_factor_files_failed':
                var error = languageMessages['get.data.failed'];
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = languageMessages['user.exception'];
                        break;
                    default:
                        break;
                }
                message.error(error);
                this.setState({ dataSource: [], initLoadding: false });
                break;
            case 'factor_analyze_commit':
                var index = dataSource.findIndex(item => item.key === analyzeKey);
                if (index > -1) {
                    dataSource[index].description = languageMessages['is.submitting'];
                    this.setState({ dataSource });
                }
                break;
            case 'factor_analyze_ready':
                var index = dataSource.findIndex(item => item.key === analyzeKey);
                if (index > -1) {
                    dataSource[index].taskId = analyzeTaskId;
                    dataSource[index].status = analyzeState;
                    dataSource[index].description = languageMessages['prepare.analysis'];
                    this.setState({ dataSource });
                }

                sessionStorage.setItem("FactorAnalyzeCache", JSON.stringify({
                    status: 'working',
                    taskId: analyzeTaskId,
                    percent: 0,
                }));
                break;
            case 'factor_analyze_working':
                let percent = 0
                if (analyzeStage && analyzeStage.length === 2) {
                    percent = parseInt(analyzeStage[0] / analyzeStage[1] * 100)
                }
                
                var index = dataSource.findIndex(item => item.key === analyzeKey);
                if (index > -1) {
                    dataSource[index].status = analyzeState;
                    dataSource[index].description = <Progress size="small" percent={percent} />;

                    sessionStorage.setItem("FactorAnalyzeCache", JSON.stringify({
                        status: 'working',
                        taskId: dataSource[index].taskId,
                        percent: percent,
                    }));

                    this.setState({ dataSource });
                }
                break;
            case 'factor_analyze_finished':
                if (this.queryAnalyzeTimer) {
                    clearInterval(this.queryAnalyzeTimer)
                    this.queryAnalyzeTimer = null;
                }

                var index = dataSource.findIndex(item => item.key === analyzeKey);
                if (index > -1) {
                    dataSource[index].status = analyzeState;
                    dataSource[index].description = languageMessages['analysis.completed'];
                }
                this.setState({ dataSource, analyzeKey: 0, aLastTimes: lastTimes });
                
                this.user.permission = permission;
                sessionStorage.setItem("auth", JSON.stringify(this.user));
                this.setUserPermission(this.user.permission);

                sessionStorage.removeItem("FactorAnalyzeCache");
                break;
            case 'factor_analyze_error':
                if (this.queryAnalyzeTimer) {
                    clearInterval(this.queryAnalyzeTimer)
                    this.queryAnalyzeTimer = null;
                }

                var error = languageMessages['analysis.failure'];
                switch (state) {
                    case 0:
                        error = languageMessages['analysis.of.anomalies'];
                        break;
                    case 2:
                        error = languageMessages['analysis.failure'];
                        break;
                    case 3:
                        error = languageMessages['error.analyzing.data'];
                        break;
                    case 4:
                        error = languageMessages['user.exception'];
                        break;
                    case 5:
                        error = languageMessages['analysis.points.end'];
                        break;
                    case 6:
                        error = languageMessages['analysis.server.not.responding'];
                        break;
                    case 7:
                        error = languageMessages['no.querying.analysis.tasks'];
                        break;
                    default:
                        break;
                }

                var index = dataSource.findIndex(item => item.key === analyzeKey);
                if (index > -1) {
                    dataSource[index].status = analyzeState;
                    dataSource[index].description = languageMessages['analysis.failure'];
                    this.setState({ dataSource, analyzeKey: 0 });
                }

                sessionStorage.removeItem("FactorAnalyzeCache");
                message.error(error);
                break;
            case 'upload_file':
                var dd = {
                        key: factorFile.id,
                        fileName: factorFile.fileName,
                        update: factorFile.updated,
                        taskId: factorFile.taskId,
                        type: factorFile.type,
                        status: factorFile.status,
                        description: '',
                    }
                if (dataSource.length > 0) {
                    dataSource.unshift(dd);
                } else {
                    dataSource.push(dd);
                }

                this.setState({ dataSource, initLoadding: false });
                break;
            case 'upload_file_error':
                this.setState({ initLoadding: false });
                message.error(languageMessages['upload.failed']);
                break;
            case 'remove_file':
                if (removeKey > 0) {
                    dataSource = dataSource.filter(item => item.key !== removeKey);
                    this.setState({ dataSource, removeKey: 0 });
                }
                break;
            case 'remove_file_error':
                var error = languageMessages['remove.factor.file.failed'];
                switch (state) {
                    case 0:
                        break;
                    case 2:
                        error = languageMessages['user.exception'];
                        break;
                    default:
                        break;
                }
                message.error(error);
                break;
            default:
                break;
        }
    }

    componentWillUnmount() {
        if (this.queryAnalyzeTimer) {
            clearInterval(this.queryAnalyzeTimer);
            this.queryAnalyzeTimer = null;
        }
    }

    onValueAddedServices = e => {
        e.preventDefault();
        Modal.destroyAll();
        history.push({ pathname: '/member', state: { memberMenuSelectKey: "2" } });
    }

    onClickViewResult = (key) => {
        if (key != 0) {
            sessionStorage.setItem("resultTaskId", key);
            history.replace("/factorAnalyzer")
        }
    }

    StartAnalyzer = (key) => {
        let { aLastTimes } = this.state;
        
        if (this.user.username === undefined) {
            this.props.dispatch({ type: "action_non_user" });
        } else {
            var _this = this;
            confirm({
                title: languageMessages['analyze.this.factor.file'],
                content: <span> {languageMessages['remaining.times']}：{aLastTimes}{languageMessages['times']}，<a onClick={this.onValueAddedServices}>{languageMessages['value-added.services']}</a> </span>,
                okText: languageMessages['analysis'],
                okType: 'danger',
                okButtonProps: {
                    disabled: aLastTimes > 0 ? false : true,
                    //size: 'small',
                },
                cancelText: languageMessages['cancel'],
                cancelButtonProps: {
                    //size: 'small',
                },
                onOk() {
                    _this.setState({ analyzeKey: key }, () => {
                        _this.props.dispatch({ type: "action_start_analyze", payload: { UserName: _this.user.username, FileId: key } });
                    });
                },
                onCancel() {
                },
            });
        }
    }

    queryAnalyzeStatus = () => {
        if (this.queryAnalyzeTimer) {
            var analyzeTaskId = 0;
            var factorAnalyze = JSON.parse(sessionStorage.getItem("FactorAnalyzeCache") || '{}') || {};
            if (factorAnalyze.taskId !== undefined) {
                analyzeTaskId = factorAnalyze.taskId;
            } else {
                analyzeTaskId = this.props.analyzeTaskId;
            }

            if (analyzeTaskId > 0) {
                if (this.user.username === undefined) {
                    this.props.dispatch({ type: "action_non_user" });
                } else {
                    this.props.dispatch({ type: "action_analyze_query", payload: { UserName: this.user.username, TaskId: analyzeTaskId } });
                }
            }
        }
    }

    onRemove = (key) => {
        if (this.user.username === undefined) {
            this.props.dispatch({ type: "action_non_user" });
        } else {
            var _this = this;
            confirm({
                title: languageMessages['delete.this.factor.file'],
                content: '',
                okText: languageMessages['yes'],
                okType: 'danger',
                cancelText: languageMessages['no'],
                onOk() {
                    _this.setState({ removeKey: key }, () => {
                        _this.props.dispatch({ type: "action_remove_file", payload: { UserName: _this.user.username, FileName: key } });
                    });
                },
                onCancel() {
                },
            });
        }
    }

    beforeUpload = (file) => {
        if (this.user.username === undefined) {
            this.props.dispatch({ type: "action_non_user" });
            return false;
        } else {
            const isCSV = file.type.match("application/vnd.ms-excel");
            if (!isCSV) {
                message.error(languageMessages['upload.factor.format']);
            }
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                message.error(languageMessages['upload.factor.size']);
            }
            return isCSV && isLt20M;
        }
    }

    handleUploadChange = (info) => {
        if (this.user.username === undefined) {
            this.props.dispatch({ type: "action_non_user" });
        } else {
            let fileList = info.fileList;
        
            if (info.file.status !== undefined) {
                this.setState({ fileList });
            }
        
            if (info.file.status === 'done') {
                //文件转移
                this.setState({ fileList: [], initLoadding: true }, () => {
                    this.props.dispatch({ type: "action_upload_migrate", payload: { UserName: this.user.username, FileName: info.file.response.fileName } });
                });
            } else if (info.file.status === 'error') {
                this.setState({ fileList: [] });
            }
        }
    }

    onBack = e => {
        e.preventDefault();
        history.goBack();
    }
    
    render() {
        const { dataSource, initLoadding } = this.state

        return (<Content className='main-container'>
            <div className='common-page-header'>
                <PageHeader onBack={this.onBack} backIcon={(<Icon type="left" />)} title={(<span style={{ cursor: 'pointer' }} onClick={this.onBack}>{languageMessages['my.factor.file']}</span>)} />
            </div>

            <Layout className='cont-content'>
                <div className="table-operations" style={{ margin: '16px 0px' }}>
                    <div style={{ float: "left" }}>
                        <Upload name='file' action="/api/file/factors/"
                            beforeUpload={this.beforeUpload}
                            onChange={this.handleUploadChange}
                            fileList={this.state.fileList}>
                            <Button size='small'><Icon type="upload" /> {languageMessages['upload.factor.file']} </Button>
                        </Upload>
                    </div>
                </div>
                <Table bordered size='small' loading={initLoadding} dataSource={dataSource} columns={this.columns} />
            </Layout>
        </Content>);
    }
}

function mapStateToProps(state) {
    return state.factor;
}

const page = connect(mapStateToProps)(FactorIndex);
export default page